import Button from "components/common/Button";
import CustomTable from "components/common/CustomTable";
import Dropdown from "components/common/Dropdown";
import TextInput from "components/common/InputField/TextInput";
import ModalContainer from "components/common/ModalContainer";
import PageTitleSection from "components/common/PageTitleSection";
import VendorAvatar from "components/common/VendorAvatar";
import { truncate } from "lodash";
import React, { useEffect, useState } from "react";
import ReactCountryFlag from "react-country-flag";
import { useNavigate, useSearchParams } from "react-router-dom";

const ExpandedDetails = () => {
    const navigate = useNavigate();
    const [param] = useSearchParams();
    const currTab = param.get("currTab");

    const [activeTab, setActiveTab] = useState<number>(0);
    const [customerModal, setCustomerModal] = useState<boolean>(false);
    const tabs = ["Ongoing orders", "Sales by region", "Top selling products"];

    const regionTableHeader = [
        { title: "States", widthClass: "w-[16.7%]" },
        { title: "Channel", widthClass: "w-[16.7%]" },
        { title: "Sale volume", widthClass: "w-[16.6%]" },
        { title: "Av. Order status", widthClass: "w-[16.6%]" },
        { title: "Customer count", widthClass: "w-[11.3%]" },
        { title: "Total revenue", widthClass: "w-[16.7%]" },
        { title: "", widthClass: "w-[5%]" },
    ];

    const regionTableBody = [1, 2, 3, 4, 5, 6]?.map((po, idx) => [
        {
            content: (
                <div
                    className={`py-3 pr-3 flex font-rocGroteskMedium items-center h-full border-slate-100 text-sm text-slate-800 `}
                >
                    <span>New york</span>
                </div>
            ),
            cellClickAction: () => {
                // navigate("/dashboard/inventory/products/1");
            },
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-800 `}
                >
                    <div className="flex items-center space-x-2">
                        <VendorAvatar
                            imageSrc={""}
                            size={20}
                            name={"Intercom"}
                            containerClassname="!rounded-md"
                        />
                        <div>
                            <span className="block text-sm">
                                {truncate("Quickbooks", {
                                    length: 15,
                                })}
                            </span>
                        </div>
                    </div>
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-800 `}
                >
                    <span>500 unit</span>
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-800 `}
                >
                    <span>$1500</span>
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-800 `}
                >
                    <div className="flex items-center space-x-3">
                        <div className="flex items-center space-x-1">
                            <i className="ri-user-line text-sm mt-[-3px]"></i>
                            <span>75</span>
                        </div>

                        <span className="text-slate-400">|</span>

                        <i
                            onClick={() => setCustomerModal(true)}
                            className="ri-arrow-right-up-line text-base mt-[-3px] text-p-50 cursor-pointer"
                        ></i>
                    </div>
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-800 `}
                >
                    <span>$1500</span>
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-800 `}
                >
                    <div className="flex items-center space-x-2">
                        <Dropdown
                            dropdown={<i className="ri-more-2-fill text-lg text-slate-500"></i>}
                            dropdownClassName={"!w-full !mb-10 !-mt-7"}
                            dropdownContainerClasses={`shadow-cardShadow-2 border-0 rounded !max-h-fit left-[-140px] top-[56px]`}
                            dropdownItemsClasses={``}
                            name={"faq-dropdown"}
                            dropdownOptions={[]}
                        />
                    </div>
                </div>
            ),
        },
    ]);

    const tableHeader = [
        { title: "Order ID", widthClass: "w-[13.5%]" },
        { title: "Channel", widthClass: "w-[13.5%]" },
        { title: "Total revenue", widthClass: "w-[13.5%]" },
        { title: "Qty", widthClass: "w-[13.5%]" },
        { title: "Order status", widthClass: "w-[13.5%]" },
        { title: "Lead time", widthClass: "w-[13.5%]" },
        { title: "3PL", widthClass: "w-[13.5%]" },
        { title: "", widthClass: "w-[5%]" },
    ];

    const tableBody = [1, 2, 3, 4, 5, 6]?.map((po, idx) => [
        {
            content: (
                <div
                    className={`py-3 pr-3 flex font-rocGroteskMedium items-center h-full border-slate-100 text-sm text-slate-800 `}
                >
                    <span>ORD 00{idx + 1}</span>
                </div>
            ),
            cellClickAction: () => {
                // navigate("/dashboard/inventory/products/1");
            },
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-800 `}
                >
                    <div className="flex items-center space-x-2">
                        <VendorAvatar
                            imageSrc={""}
                            size={20}
                            name={"Intercom"}
                            containerClassname="!rounded-md"
                        />
                        <div>
                            <span className="block text-sm">
                                {truncate("Quickbooks", {
                                    length: 15,
                                })}
                            </span>
                        </div>
                    </div>
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-800 `}
                >
                    <span>$1500</span>
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-800 `}
                >
                    <span>200 pcs</span>
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-800 `}
                >
                    <div className="w-fit flex items-center text-sm py-1 px-2.5 space-x-2 bg-[#16C6A41A]">
                        <span className="h-2 w-2 rounded-full bg-g-50"></span>
                        <span>Approved</span>
                    </div>
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-800 `}
                >
                    <span>7 days</span>
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-800 `}
                >
                    <span>Amazon</span>
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-800 `}
                >
                    <div className="flex items-center space-x-2">
                        <Dropdown
                            dropdown={<i className="ri-more-2-fill text-lg text-slate-500"></i>}
                            dropdownClassName={"!w-full !mb-10 !-mt-7"}
                            dropdownContainerClasses={`shadow-cardShadow-2 border-0 rounded !max-h-fit left-[-140px] top-[56px]`}
                            dropdownItemsClasses={``}
                            name={"faq-dropdown"}
                            dropdownOptions={[]}
                        />
                    </div>
                </div>
            ),
        },
    ]);

    const topSellingTableHeader = [
        { title: "Product name", widthClass: "w-[19%]" },
        { title: "Prod. SKU", widthClass: "w-[19%]" },
        { title: "Sale volume", widthClass: "w-[18.9%]" },
        { title: "Total revenue", widthClass: "w-[18.9%]" },
        { title: "Av. Order value", widthClass: "w-[18.9%]" },
        { title: "", widthClass: "w-[5%]" },
    ];

    const topSellingTableBody = [1, 2, 3, 4, 5, 6]?.map((po, idx) => [
        {
            content: (
                <div
                    className={`py-3 pr-3 flex font-rocGroteskMedium items-center h-full border-slate-100 text-sm text-slate-800 `}
                >
                    <span>Turkey</span>
                </div>
            ),
            cellClickAction: () => {
                // navigate("/dashboard/inventory/products/1");
            },
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-800 `}
                >
                    <span>TU12890</span>
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-800 `}
                >
                    <span>500 unit</span>
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-800 `}
                >
                    <span>$1500</span>
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-800 `}
                >
                    <span>$1500</span>
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-800 `}
                >
                    <div className="flex items-center space-x-2">
                        <Dropdown
                            dropdown={<i className="ri-more-2-fill text-lg text-slate-500"></i>}
                            dropdownClassName={"!w-full !mb-10 !-mt-7"}
                            dropdownContainerClasses={`shadow-cardShadow-2 border-0 rounded !max-h-fit left-[-140px] top-[56px]`}
                            dropdownItemsClasses={``}
                            name={"faq-dropdown"}
                            dropdownOptions={[]}
                        />
                    </div>
                </div>
            ),
        },
    ]);

    const displayActiveTab = () => {
        switch (activeTab) {
            case 0:
                return (
                    <div>
                        <div className="flex items-center justify-between space-x-2 mb-6">
                            <div className="w-[16.5%]">
                                <Dropdown
                                    name="filter"
                                    value={""}
                                    dropdownTriggerClassName={" "}
                                    handleChange={(name, value) => console.log(value)}
                                    dropdown={
                                        <div className="bg-white flex items-center justify-between h-10 gap-2 py-2.5 px-3 shadow-boxShadow-2 border border-tradeally-neutral-20 !rounded">
                                            <div className="flex items-center space-x-2">
                                                <i className="ri-file-line text-xl"></i>
                                                <span className="text-[13px] whitespace-nowrap font-rocGroteskMedium">
                                                    Sales (US)
                                                </span>
                                            </div>

                                            <div className="flex items-center space-x-2">
                                                {/* {Boolean(stockCategory) && (
                                            <i
                                                onClick={(e) => {
                                                    e?.stopPropagation();
                                                    setStockCategory("");
                                                }}
                                                className="ri-close-circle-fill text-sm text-slate-500"
                                            ></i>
                                        )} */}
                                                <i className="ri-arrow-down-s-line text-lg text-gm-50"></i>
                                            </div>
                                        </div>
                                    }
                                    dropdownClassName={"!w-full !mb-10 !-mt-7"}
                                    dropdownContainerClasses={`shadow-cardShadow-4 !mt-0  rounded !max-h-fit
                                         !p-0 !top-[105%]`}
                                    dropdownItemsClasses="!bg-transparent border-b-[1px] border-b-slate-100"
                                    dropdownOptions={[
                                        {
                                            label: "Sales (US)",
                                            value: "sales (us)",
                                        },
                                    ]}
                                />
                            </div>

                            <div className="w-[50.2%] flex items-center space-x-4">
                                <TextInput
                                    name={"search"}
                                    value={""}
                                    type={"text"}
                                    inputPlaceholder={"Search supplier, date, status"}
                                    inputClassName={"!h-[38px] pl-[0px] !bg-white text-sm !mb-0"}
                                    // onChange={handleSearch}
                                    // onInput={handleDebouncedChange}
                                    leftIcon={
                                        <i className="ri-search-line before:content-['\f0d1] text-slate-400 mt-[2px]"></i>
                                    }
                                    inputContainerClassname={
                                        "!rounded-[4px]  max-sm:!w-full !pl-[40px] !h-[40px] !bg-white shadow-[0px_1px_2px_0px_#5B687126] !border !border-n-20"
                                    }
                                />

                                <Button
                                    btnText="Settings"
                                    btnClassname="!bg-white shadow-[0px_1px_2px_0px_#5B687126] !border !border-tradeally-neutral-20 !w-fit !h-10 !text-[13px]"
                                    icon={<i className="ri-settings-5-line text-base"></i>}
                                />

                                <Button
                                    btnClassname="!px-4 !py-2 !h-10 !w-fit whitespace-nowrap"
                                    btnText="Create sales order"
                                    icon={<i className="ri-add-circle-line"></i>}
                                    onClick={() => navigate("/dashboard/order/create")}
                                />
                            </div>
                        </div>

                        <div>
                            <CustomTable
                                tableBody={tableBody}
                                tableHeader={tableHeader}
                                isScrollable={false}
                                isCellBordered={false}
                                isCellSelectable={false}
                                isAllSelectable={false}
                                headerContainerClass="!bg-slate-50"
                                headerItemClass="font-rocGroteskMedium "
                                tableClass="mb-6"
                            />
                        </div>
                    </div>
                );

            case 1: {
                return (
                    <div>
                        <div className="flex items-center justify-between space-x-2 mb-6">
                            <div className="w-[16.5%]">
                                <Dropdown
                                    name="filter"
                                    value={""}
                                    dropdownTriggerClassName={" "}
                                    handleChange={(name, value) => console.log(value)}
                                    dropdown={
                                        <div className="bg-white flex items-center justify-between h-10 gap-2 py-2.5 px-3 shadow-boxShadow-2 border border-tradeally-neutral-20 !rounded">
                                            <div className="flex items-center space-x-2">
                                                <i className="ri-file-line text-xl"></i>
                                                <span className="text-[13px] whitespace-nowrap font-rocGroteskMedium">
                                                    Approved
                                                </span>
                                            </div>

                                            <div className="flex items-center space-x-2">
                                                {/* {Boolean(stockCategory) && (
                                            <i
                                                onClick={(e) => {
                                                    e?.stopPropagation();
                                                    setStockCategory("");
                                                }}
                                                className="ri-close-circle-fill text-sm text-slate-500"
                                            ></i>
                                        )} */}
                                                <i className="ri-arrow-down-s-line text-lg text-gm-50"></i>
                                            </div>
                                        </div>
                                    }
                                    dropdownClassName={"!w-full !mb-10 !-mt-7"}
                                    dropdownContainerClasses={`shadow-cardShadow-4 !mt-0 rounded !max-h-fit
                                         !p-0 !top-[105%]`}
                                    dropdownItemsClasses="!bg-transparent border-b-[1px] border-b-slate-100"
                                    dropdownOptions={[
                                        {
                                            label: "Approved",
                                            value: "approved",
                                        },
                                    ]}
                                />
                            </div>

                            <div className="w-[50.2%] flex items-center space-x-4">
                                <TextInput
                                    name={"search"}
                                    value={""}
                                    type={"text"}
                                    inputPlaceholder={"Search supplier, date, status"}
                                    inputClassName={"!h-[38px] pl-[0px] !bg-white text-sm !mb-0"}
                                    // onChange={handleSearch}
                                    // onInput={handleDebouncedChange}
                                    leftIcon={
                                        <i className="ri-search-line before:content-['\f0d1] text-slate-400 mt-[2px]"></i>
                                    }
                                    inputContainerClassname={
                                        "!rounded-[4px]  max-sm:!w-full !pl-[40px] !h-[40px] !bg-white shadow-[0px_1px_2px_0px_#5B687126] !border !border-n-20"
                                    }
                                />

                                <Button
                                    btnText="Settings"
                                    btnClassname="!bg-white shadow-[0px_1px_2px_0px_#5B687126] !border !border-tradeally-neutral-20 !w-fit !h-10 !text-[13px]"
                                    icon={<i className="ri-settings-5-line text-base"></i>}
                                />

                                <Button
                                    btnClassname="!px-4 !py-2 !h-10 !w-fit whitespace-nowrap"
                                    btnText="Create sales order"
                                    icon={<i className="ri-add-circle-line"></i>}
                                    onClick={() => navigate("/dashboard/order/create")}
                                />
                            </div>
                        </div>

                        <div>
                            <CustomTable
                                tableBody={regionTableBody}
                                tableHeader={regionTableHeader}
                                isScrollable={false}
                                isCellBordered={false}
                                isCellSelectable={false}
                                isAllSelectable={false}
                                headerContainerClass="!bg-slate-50"
                                headerItemClass="font-rocGroteskMedium "
                                tableClass="mb-6"
                            />
                        </div>
                    </div>
                );
            }

            case 2:
                return (
                    <div>
                        <div className="flex items-center justify-between space-x-2 mb-6">
                            <div className="w-[16.5%]">
                                <Dropdown
                                    name="filter"
                                    value={""}
                                    dropdownTriggerClassName={" "}
                                    handleChange={(name, value) => console.log(value)}
                                    dropdown={
                                        <div className="bg-white flex items-center justify-between h-10 gap-2 py-2.5 px-3 shadow-boxShadow-2 border border-tradeally-neutral-20 !rounded">
                                            <div className="flex items-center space-x-2">
                                                <i className="ri-file-line text-xl"></i>
                                                <span className="text-[13px] whitespace-nowrap font-rocGroteskMedium">
                                                    All product
                                                </span>
                                            </div>

                                            <div className="flex items-center space-x-2">
                                                {/* {Boolean(stockCategory) && (
                                          <i
                                              onClick={(e) => {
                                                  e?.stopPropagation();
                                                  setStockCategory("");
                                              }}
                                              className="ri-close-circle-fill text-sm text-slate-500"
                                          ></i>
                                      )} */}
                                                <i className="ri-arrow-down-s-line text-lg text-gm-50"></i>
                                            </div>
                                        </div>
                                    }
                                    dropdownClassName={"!w-full !mb-10 !-mt-7"}
                                    dropdownContainerClasses={`shadow-cardShadow-4 !mt-0 rounded !max-h-fit
                                       !p-0 !top-[105%]`}
                                    dropdownItemsClasses="!bg-transparent border-b-[1px] border-b-slate-100"
                                    dropdownOptions={[
                                        {
                                            label: "All product",
                                            value: "all product",
                                        },
                                    ]}
                                />
                            </div>

                            <div className="w-[50.2%] flex items-center space-x-4">
                                <TextInput
                                    name={"search"}
                                    value={""}
                                    type={"text"}
                                    inputPlaceholder={"Search supplier, date, status"}
                                    inputClassName={"!h-[38px] pl-[0px] !bg-white text-sm !mb-0"}
                                    // onChange={handleSearch}
                                    // onInput={handleDebouncedChange}
                                    leftIcon={
                                        <i className="ri-search-line before:content-['\f0d1] text-slate-400 mt-[2px]"></i>
                                    }
                                    inputContainerClassname={
                                        "!rounded-[4px]  max-sm:!w-full !pl-[40px] !h-[40px] !bg-white shadow-[0px_1px_2px_0px_#5B687126] !border !border-n-20"
                                    }
                                />

                                <Button
                                    btnText="Settings"
                                    btnClassname="!bg-white shadow-[0px_1px_2px_0px_#5B687126] !border !border-tradeally-neutral-20 !w-fit !h-10 !text-[13px]"
                                    icon={<i className="ri-settings-5-line text-base"></i>}
                                />

                                <Button
                                    btnClassname="!px-4 !py-2 !h-10 !w-fit whitespace-nowrap"
                                    btnText="Create sales order"
                                    icon={<i className="ri-add-circle-line"></i>}
                                    onClick={() => navigate("/dashboard/order/create")}
                                />
                            </div>
                        </div>

                        <div>
                            <CustomTable
                                tableBody={topSellingTableBody}
                                tableHeader={topSellingTableHeader}
                                isScrollable={false}
                                isCellBordered={false}
                                isCellSelectable={false}
                                isAllSelectable={false}
                                headerContainerClass="!bg-slate-50"
                                headerItemClass="font-rocGroteskMedium "
                                tableClass="mb-6"
                            />
                        </div>
                    </div>
                );

            default:
                break;
        }
    };

    useEffect(() => {
        if (currTab) {
            setActiveTab(Number(currTab));
        }
    }, [currTab]);

    return (
        <>
            <div className="p-4 border border-slate-100 rounded-[8px] bg-white">
                <div className="p-4">
                    <div
                        onClick={() => navigate(`/dashboard/order`)}
                        className="flex items-center space-x-3 w-fit cursor-pointer"
                    >
                        <i className="ri-arrow-left-line"></i>
                        <span className="text-sm font-rocGroteskMedium underline">Back</span>
                    </div>
                </div>

                <div className="p-4">
                    <div className="flex items-center justify-between mb-6">
                        <div className="w-fit bg-slate-50 rounded p-1 flex items-center space-x-2.5 ">
                            {tabs.map((tab, index) => (
                                <div
                                    key={index}
                                    className={`px-4 py-[7px] cursor-pointer ${
                                        activeTab === index &&
                                        "bg-white rounded shadow-[0px_4px_10px_0px_#0000000D]"
                                    }`}
                                    onClick={() => {
                                        setActiveTab(index);
                                        navigate(
                                            `/dashboard/order/dashboard-details?currTab=${index}`
                                        );
                                    }}
                                >
                                    <span className="text-sm font-rocGroteskMedium">{tab}</span>
                                </div>
                            ))}
                        </div>
                    </div>

                    <div>{displayActiveTab()}</div>
                </div>
            </div>

            <ModalContainer
                open={customerModal}
                showCloseIcon={false}
                modalClassName={"py-10 px-10 max-sm:p-5"}
                tailwindClassName="w-[45.1%] max-lg:w-[95%]"
                closeModal={() => {
                    setCustomerModal(false);
                }}
            >
                <div className="bg-white p-6 rounded">
                    <div className="flex items-center justify-between mb-6">
                        <span className="text-xl font-rocGroteskMedium block">
                            Customer details
                        </span>

                        <div className="flex items-center space-x-3">
                            <div className="text-right">
                                <span className="block text-base font-rocGroteskMedium mb-1">
                                    All States in the United states
                                </span>
                                <span className="block text-base font-rocGroteskMedium text-slate-500">
                                    New York
                                </span>
                            </div>

                            <ReactCountryFlag
                                countryCode={"US"}
                                svg
                                cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
                                cdnSuffix="svg"
                                style={{
                                    width: "40px",
                                    height: "40px",
                                    borderRadius: "50%",
                                }}
                                title={"US"}
                            />
                        </div>
                    </div>

                    <div className="space-y-2.5 max-h-[450px] overflow-y-auto">
                        {[1, 2, 3, 4, 5, 6].map((item, index) => (
                            <div key={index} className="rounded p-4 border border-slate-200">
                                <div className="flex items-center space-x-2 mb-1.5">
                                    <div className="flex items-center space-x-2">
                                        <VendorAvatar name="john snow" imageSrc="" size={24} />
                                        <span className="text-sm font-rocGroteskMedium">
                                            John snow
                                        </span>
                                    </div>
                                    <span className=" text-slate-200">|</span>
                                    <span className="text-sm  font-rocGroteskMedium text-slate-500">
                                        augustineasiuwhu@synthally.com
                                    </span>
                                </div>

                                <div className="flex items-center space-x-2">
                                    <span className="text-sm font-rocGroteskMedium text-slate-500">
                                        No. of orders : 10
                                    </span>
                                    <span className=" text-slate-200">|</span>
                                    <span className="text-sm font-rocGroteskMedium text-slate-500">
                                        (+234) 8000000000
                                    </span>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </ModalContainer>
        </>
    );
};

export default ExpandedDetails;
