export const saveToStorage = (
    name: string,
    data: any[] | Record<string, any> | string,
    shouldRemember?: boolean
) => {
    if (shouldRemember) {
        removeFromStorage(name);
        localStorage.setItem(name, JSON.stringify(data));
    } else {
        sessionStorage.setItem(name, JSON.stringify(data));
    }
};

export const getFromStorage = (name: string) => {
    return JSON.parse(
        (localStorage.getItem(name) as string) || (sessionStorage.getItem(name) as string)
    );
};

export const removeFromStorage = (name: string) => {
    localStorage.removeItem(name);
    sessionStorage.removeItem(name);
};
