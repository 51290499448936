import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../storeHooks";
import { trackTraceActions } from "redux/Ldb/actions";
import { debounce, uniqBy } from "lodash";
import useInfiniteScroll from "../useInfinteScroll";

const useGetTrackTraceFinishedGoods = () => {
    const dispatch = useAppDispatch();
    const limit = 10;
    const [search, setSearch] = useState<string>("");
    const [debouncedSearch, setDebouncedSearch] = useState<string>("");
    const [finishedGoods, setFinishedGoods] = useState<{ [key: string]: any }[]>([]);
    const [sortValues, setSortValues] = useState<any>({});
    const [warehouseId, setWarehouseId] = useState<string>("");
    const [dateRange, setDateRange] = useState<string>("");

    const [pagination, setPagination] = useState<{ currentPage: string; noOfPages: string }>({
        currentPage: "1",
        noOfPages: "",
    });

    const { updatedPage, isLoadingMore, setLoadMore, setUpdatedPage } = useInfiniteScroll({
        current: pagination.currentPage,
        number_of_pages: pagination.noOfPages,
    });

    const { fetchingFinishedGoods, fetchFinishedGoodsSuccess, fetchFinishedGoodsFailure } =
        useAppSelector((state) => state.trackTrace);

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(e.target.value);
    };

    const handleDebouncedChange = debounce((e) => {
        setDebouncedSearch(e.target.value);
    }, 500);
    useEffect(() => {
        if (Boolean(fetchFinishedGoodsSuccess)) {
            setFinishedGoods((prev) =>
                updatedPage > 1
                    ? uniqBy([...prev, ...fetchFinishedGoodsSuccess?.products], "_id")
                    : [...fetchFinishedGoodsSuccess?.products]
            );
            setPagination({
                currentPage: fetchFinishedGoodsSuccess.pagination?.current,
                noOfPages: fetchFinishedGoodsSuccess.pagination?.number_of_pages,
            });
            setLoadMore(false);
        }
    }, [fetchFinishedGoodsSuccess]);
    useEffect(() => {
        dispatch(
            trackTraceActions.getFinishedGoods(
                // updatedLimit,
                limit,
                updatedPage,
                debouncedSearch,
                warehouseId,
                dateRange,
                sortValues
            )
        );
    }, [dispatch, updatedPage, debouncedSearch, warehouseId, dateRange, sortValues]);

    useEffect(() => {
        if (debouncedSearch || warehouseId || dateRange || sortValues) {
            setUpdatedPage(1);
        }
    }, [debouncedSearch, warehouseId, dateRange, sortValues]);
    return {
        data: finishedGoods,
        isFetching: fetchingFinishedGoods,
        error: fetchFinishedGoodsFailure,
        isLoadingMore,
        handleSearch,
        handleDebouncedChange,
        setDateRange,
        pagination,
        search,
        setPagination,
        setSortValues,
        setWarehouseId,
        warehouseId,
        dateRange,
        setSearch,
        setDebouncedSearch,
        debouncedSearch,
    };
};

export default useGetTrackTraceFinishedGoods;
