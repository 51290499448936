import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../storeHooks";
import { workforceActions } from "../../redux/Ldb/actions/workforceActions";
import { useParams } from "react-router-dom";

const useGetSingleAiTeammate = () => {
    const dispatch = useAppDispatch();
    const { aiTeammateId } = useParams();

    const { fetchingSingleAiTeammate, fetchSingleAiTeammateSuccess, fetchSingleAiTeammateFailure } =
        useAppSelector((state) => state.workforce);

    const [aiTeammateDetails, setAiTeammateDetails] = useState<{ [key: string]: any }>({});

    useEffect(() => {
        if (aiTeammateId) {
            dispatch(workforceActions.getSingleAiTeammate(aiTeammateId));

            return () => {
                dispatch(workforceActions.resetGetSingleAiTeammateSuccess());
            };
        }
    }, [dispatch, aiTeammateId]);

    useEffect(() => {
        if (Boolean(fetchSingleAiTeammateSuccess)) {
            setAiTeammateDetails(fetchSingleAiTeammateSuccess);
        }
    }, [fetchSingleAiTeammateSuccess]);

    return {
        isFetching: fetchingSingleAiTeammate,
        error: fetchSingleAiTeammateFailure,
        data: aiTeammateDetails,
        setAiTeammateDetails,
    };
};

export default useGetSingleAiTeammate;
