export const workforceTypes = {
    FETCH_AI_AGENTS_REQUEST: "FETCH_AI_AGENTS_REQUEST",
    FETCH_AI_AGENTS_SUCCESS: "FETCH_AI_AGENTS_SUCCESS",
    FETCH_AI_AGENTS_FAILURE: "FETCH_AI_AGENTS_FAILURE",
    FETCH_AI_TEAMMATES_REQUEST: "FETCH_AI_TEAMMATES_REQUEST",
    FETCH_AI_TEAMMATES_SUCCESS: "FETCH_AI_TEAMMATES_SUCCESS",
    FETCH_AI_TEAMMATES_FAILURE: "FETCH_AI_TEAMMATES_FAILURE",
    FETCH_SINGLE_AI_TEAMMATE_REQUEST: "FETCH_SINGLE_AI_TEAMMATE_REQUEST",
    FETCH_SINGLE_AI_TEAMMATE_SUCCESS: "FETCH_SINGLE_AI_TEAMMATE_SUCCESS",
    FETCH_SINGLE_AI_TEAMMATE_FAILURE: "FETCH_SINGLE_AI_TEAMMATE_FAILURE",
    CREATE_AI_TEAMMATE_REQUEST: "CREATE_AI_TEAMMATE_REQUEST",
    CREATE_AI_TEAMMATE_SUCCESS: "CREATE_AI_TEAMMATE_SUCCESS",
    CREATE_AI_TEAMMATE_FAILURE: "CREATE_AI_TEAMMATE_FAILURE",
    UPDATE_AI_TEAMMATE_REQUEST: "UPDATE_AI_TEAMMATE_REQUEST",
    UPDATE_AI_TEAMMATE_SUCCESS: "UPDATE_AI_TEAMMATE_SUCCESS",
    UPDATE_AI_TEAMMATE_FAILURE: "UPDATE_AI_TEAMMATE_FAILURE",
    FETCH_TASK_LIST_REQUEST: "FETCH_TASK_LIST_REQUEST",
    FETCH_TASK_LIST_SUCCESS: "FETCH_TASK_LIST_SUCCESS",
    FETCH_TASK_LIST_FAILURE: "FETCH_TASK_LIST_FAILURE",
    FETCH_SINGLE_TASK_REQUEST: "FETCH_SINGLE_TASK_REQUEST",
    FETCH_SINGLE_TASK_SUCCESS: "FETCH_SINGLE_TASK_SUCCESS",
    FETCH_SINGLE_TASK_FAILURE: "FETCH_SINGLE_TASK_FAILURE",
    UPDATE_TASK_SALES_ORDER_REQUEST: "UPDATE_TASK_SALES_ORDER_REQUEST",
    UPDATE_TASK_SALES_ORDER_SUCCESS: "UPDATE_TASK_SALES_ORDER_SUCCESS",
    UPDATE_TASK_SALES_ORDER_FAILURE: "UPDATE_TASK_SALES_ORDER_FAILURE",
    APPROVE_TASK_STEP_REQUEST: "APPROVE_TASK_STEP_REQUEST",
    APPROVE_TASK_STEP_SUCCESS: "APPROVE_TASK_STEP_SUCCESS",
    APPROVE_TASK_STEP_FAILURE: "APPROVE_TASK_STEP_FAILURE",
    RETRY_TASK_STEP_REQUEST: "RETRY_TASK_STEP_REQUEST",
    RETRY_TASK_STEP_SUCCESS: "RETRY_TASK_STEP_SUCCESS",
    RETRY_TASK_STEP_FAILURE: "RETRY_TASK_STEP_FAILURE",
    GIVE_TASK_STEP_FEEDBACK_REQUEST: "GIVE_TASK_STEP_FEEDBACK_REQUEST",
    GIVE_TASK_STEP_FEEDBACK_SUCCESS: "GIVE_TASK_STEP_FEEDBACK_SUCCESS",
    GIVE_TASK_STEP_FEEDBACK_FAILURE: "GIVE_TASK_STEP_FEEDBACK_FAILURE",
    FETCH_TASK_ANALYTICS_REQUEST: "FETCH_TASK_ANALYTICS_REQUEST",
    FETCH_TASK_ANALYTICS_SUCCESS: "FETCH_TASK_ANALYTICS_SUCCESS",
    FETCH_TASK_ANALYTICS_FAILURE: "FETCH_TASK_ANALYTICS_FAILURE",
};
