import React, { useEffect } from "react";
import * as echarts from "echarts/core";
import {
    TitleComponent,
    ToolboxComponent,
    TooltipComponent,
    VisualMapComponent,
    GeoComponent,
} from "echarts/components";
import { MapChart } from "echarts/charts";
import { CanvasRenderer } from "echarts/renderers";

echarts.use([
    TitleComponent,
    ToolboxComponent,
    TooltipComponent,
    VisualMapComponent,
    GeoComponent,
    MapChart,
    CanvasRenderer,
]);

type mapProp = {
    setLocation?: any;
    setValue?: any;
    setModalPosition?: any;
    setSearchModal?: any;
    locationSearch?: string;
    mapData?: any;
    setFullState?: any;
};

const UsMap = ({
    setLocation,
    setModalPosition,
    setSearchModal,
    locationSearch,
    setValue,
    mapData,
    setFullState,
}: mapProp) => {
    const handleMouseMove = (e) => {
        setModalPosition({ x: e.clientX, y: e.clientY });
    };
    
    useEffect(() => {
        const chartDom = document.getElementById("usMap");
        const myChart = echarts.init(chartDom);
        myChart.showLoading();
        fetch("https://s3-us-west-2.amazonaws.com/s.cdpn.io/95368/USA_geo.json")
            .then((res) => res.json())
            .then((data) => {
                myChart.hideLoading();
                echarts.registerMap("USA", data, {
                    Alaska: {
                        left: -131,
                        top: 25,
                        width: 15,
                    },
                    Hawaii: {
                        left: -110,
                        top: 28,
                        width: 5,
                    },
                    "Puerto Rico": {
                        left: -76,
                        top: 26,
                        width: 2,
                    },
                });

                const worldChartOption = {
                    title: {
                        show: false,
                    },
                    tooltip: {
                        trigger: "item",
                        showDelay: 0,
                        transitionDuration: 0.2,
                        formatter: function (params) {
                            console.log("");
                        },
                    },

                    visualMap: {
                        left: "right",
                        min: 500000,
                        max: 38000000,
                        inRange: {
                            color: ["#FFB902"],
                        },
                        text: ["High", "Low"],
                        calculable: true,
                        show: false,
                    },
                    color: ["#FFB902"],
                    toolbox: {
                        show: false,
                        //orient: 'vertical',
                        left: "left",
                        top: "top",
                        feature: {
                            dataView: { readOnly: false },
                            restore: {},
                            saveAsImage: {},
                        },
                    },
                    series: [
                        {
                            name: "Supply chain tracking",
                            type: "map",
                            roam: false,
                            map: "USA",
                            label: {
                                show: true,
                                // position: "inside",
                                // position: ["50%", "10%"],
                                // verticalAlign: "bottom",
                                //     formatter: "{b}",
                                formatter: function (params) {
                                    return `${params.data?.stateCode || ""}`;
                                },
                            },
                            emphasis: {
                                label: {
                                    show: true,
                                },
                                itemStyle: {
                                    areaColor: "#D0F4ED",
                                },
                            },
                            itemStyle: {
                                areaColor: "white",
                            },
                            select: {
                                label: {
                                    show: false,
                                },
                            },
                            data: mapData,
                        },
                    ],
                };

                myChart.setOption(worldChartOption);

                myChart.on("mousemove", function (params) {
                    if (params.value) {
                        const dataItem = params.data as { cityCode: string }; // Type assertion
                        setLocation(dataItem?.cityCode);
                        setSearchModal(true);
                        setFullState(params?.name);
                        setValue(params.value);
                    } else {
                        setSearchModal(false);
                    }
                });
                myChart.getZr().on("mousemove", function (event) {
                    setSearchModal("");
                });
            })
            .catch((err) => {
                console.log("err", err);
            });
    }, []);

    return <div onMouseMove={handleMouseMove} id="usMap" className="w-full h-[400px]"></div>;
};

export default UsMap;
