import { vendorTypes } from "redux/Ldb/types";

const initialState = {
    joiningWaitList: false,
    joinWaitListSuccess: null,
    joinWaitListFailure: null,
    fetchingLdbVendors: false,
    fetchedLdbVendorsSuccess: null,
    fetchedLdbVendorsFailure: null,
    fetchingSingleVendor: false,
    fetchedSingleVendorSuccess: null,
    fetchedSingleVendorFailure: null,
    authenticatingGmail: false,
    authenticateGmailSuccess: null,
    authenticateGmailFailure: null,
    authenticatingGmailSettings: false,
    authenticateGmailSettingsSuccess: null,
    authenticateGmailSettingsFailure: null,
    connectingGoogleWorkspace: false,
    connectGoogleWorkspaceSuccess: null,
    connectGoogleWorkspaceFailure: null,
    connectingGoogleWorkspaceSettings: false,
    connectGoogleWorkspaceSettingsSuccess: null,
    connectGoogleWorkspaceSettingsFailure: null,
    fetchingAllChannels: false,
    fetchedAllChannelsSuccess: null,
    fetchedAllChannelsFailure: null,
    fetchingVendorsByOrderType: false,
    fetchedVendorsByOrderTypeSuccess: null,
    fetchedVendorsByOrderTypeFailure: null,
    fetchingOnboardedVendors: false,
    fetchedOnboardedVendorsSuccess: null,
    fetchedOnboardedVendorsFailure: null,
    fetchingSuggestedVendors: false,
    fetchedSuggestedVendorsSuccess: null,
    fetchedSuggestedVendorsFailure: null,
    authenticatingMicrosoft: false,
    authenticateMicrosoftSuccess: null,
    authenticateMicrosoftFailure: null,
    authenticatingMicrosoftSettings: false,
    authenticateMicrosoftSettingsSuccess: null,
    authenticateMicrosoftSettingsFailure: null,
    connectingMicrosoftWorkspace: false,
    connectMicrosoftWorkspaceSuccess: null,
    connectMicrosoftWorkspaceFailure: null,
    connectingMicrosoftWorkspaceSettings: false,
    connectMicrosoftWorkspaceSettingsSuccess: null,
    connectMicrosoftWorkspaceSettingsFailure: null,
    fetchingVendorPo: false,
    fetchVendorPoSuccess: null,
    fetchVendorPoFailure: null,
    manageConnection: false,
    manageConnectionSuccess: null,
    manageConnectionFailure: null,
    fetchingVendorActivities: false,
    fetchVendorActivitiesSuccess: null,
    fetchVendorActivitiesFailure: null,

    fetchingVendorByCategory: false,
    fetchVendorByCategorySuccess: null,
    fetchVendorByCategoryFailure: null,
    addingVendor: false,
    addVendorSuccess: null,
    addVendorFailure: null,
    updatingVendor: false,
    updateVendorSuccess: null,
    updateVendorFailure: null,
    fetchingLdbVendor: false,
    fetchLdbVendorSuccess: null,
    fetchLdbVendorFailure: null,
    fetchingSupplierAnalytics: false,
    fetchSupplierAnalyticsSuccess: null,
    fetchSupplierAnalyticsFailure: null,
    fetchingSupplierProducts: false,
    fetchSupplierProductsSuccess: null,
    fetchSupplierProductsFailure: null,
    fetchingSupplierOngoingPo: false,
    fetchSupplierOngoingPoSuccess: null,
    fetchSupplierOngoingPoFailure: null,
    fetchingSupplierQuotes: false,
    fetchSupplierQuotesSuccess: null,
    fetchSupplierQuotesFailure: null,
    fetchingSupplierDetails: false,
    fetchSupplierDetailsSuccess: null,
    fetchSupplierDetailsFailure: null,
    deletingSupplier: false,
    deleteSupplierSuccess: null,
    deleteSupplierFailure: null,
    fetchingSupplierReturnedGoods: false,
    fetchSupplierReturnedGoodsSuccess: null,
    fetchSupplierReturnedGoodsFailure: null,
    fetchingSupplierProductHistory: false,
    fetchSupplierProductHistorySuccess: null,
    fetchSupplierProductHistoryFailure: null,
};

export const vendor = (state = initialState, action: { type: string; payload: any }) => {
    switch (action.type) {
        case vendorTypes.FETCH_VENDOR_ACTIVITIES_REQUEST:
            return {
                ...state,
                fetchingVendorActivities: true,
                fetchVendorActivitiesSuccess: null,
                fetchVendorActivitiesFailure: null,
            };
        case vendorTypes.FETCH_VENDOR_ACTIVITIES_SUCCESS:
            return {
                ...state,
                fetchingVendorActivities: false,
                fetchVendorActivitiesSuccess: action.payload,
                fetchVendorActivitiesFailure: null,
            };
        case vendorTypes.FETCH_VENDOR_ACTIVITIES_FAILURE:
            return {
                ...state,
                fetchingVendorActivities: false,
                fetchVendorActivitiesSuccess: null,
                fetchVendorActivitiesFailure: action.payload,
            };

        case vendorTypes.FETCH_VENDOR_PO_REQUEST:
            return {
                ...state,
                fetchingVendorPo: true,
                fetchVendorPoSuccess: null,
                fetchVendorPoFailure: null,
            };
        case vendorTypes.FETCH_VENDOR_PO_SUCCESS:
            return {
                ...state,
                fetchingVendorPo: false,
                fetchVendorPoSuccess: action.payload,
                fetchVendorPoFailure: null,
            };
        case vendorTypes.FETCH_VENDOR_PO_FAILURE:
            return {
                ...state,
                fetchingVendorPo: false,
                fetchVendorPoSuccess: null,
                fetchVendorPoFailure: action.payload,
            };

        case vendorTypes.JOIN_WAITLIST_REQUEST:
            return {
                ...state,
                joiningWaitList: true,
                joinWaitListSuccess: null,
                joinWaitListFailure: null,
            };
        case vendorTypes.JOIN_WAITLIST_SUCCESS:
            return {
                ...state,
                joiningWaitList: false,
                joinWaitListSuccess: action.payload,
                joinWaitListFailure: null,
            };
        case vendorTypes.JOIN_WAITLIST_FAILURE:
            return {
                ...state,
                joiningWaitList: false,
                joinWaitListSuccess: null,
                joinWaitListFailure: action.payload,
            };

        case vendorTypes.FETCH_LDB_VENDORS_REQUEST:
            return {
                ...state,
                fetchingLdbVendors: true,
                fetchedLdbVendorsSuccess: null,
                fetchedLdbVendorsFailure: null,
            };
        case vendorTypes.FETCH_LDB_VENDORS_SUCCESS:
            return {
                ...state,
                fetchingLdbVendors: false,
                fetchedLdbVendorsSuccess: action.payload,
                fetchedLdbVendorsFailure: null,
            };
        case vendorTypes.FETCH_LDB_VENDORS_FAILURE:
            return {
                ...state,
                fetchingLdbVendors: false,
                fetchedLdbVendorsSuccess: null,
                fetchedLdbVendorsFailure: action.payload,
            };

        case vendorTypes.FETCH_SINGLE_VENDOR_REQUEST:
            return {
                ...state,
                fetchingSingleVendor: true,
                fetchedSingleVendorSuccess: null,
                fetchedSingleVendorFailure: null,
            };
        case vendorTypes.FETCH_SINGLE_VENDOR_SUCCESS:
            return {
                ...state,
                fetchingSingleVendor: false,
                fetchedSingleVendorSuccess: action.payload,
                fetchedSingleVendorFailure: null,
            };
        case vendorTypes.FETCH_SINGLE_VENDOR_FAILURE:
            return {
                ...state,
                fetchingSingleVendor: false,
                fetchedSingleVendorSuccess: null,
                fetchedSingleVendorFailure: action.payload,
            };

        case vendorTypes.AUTHENTICATE_GMAIL_REQUEST:
            return {
                ...state,
                authenticatingGmail: true,
                authenticateGmailSuccess: null,
                authenticateGmailFailure: null,
            };
        case vendorTypes.AUTHENTICATE_GMAIL_SUCCESS:
            return {
                ...state,
                authenticatingGmail: false,
                authenticateGmailSuccess: action.payload,
                authenticateGmailFailure: null,
            };
        case vendorTypes.AUTHENTICATE_GMAIL_FAILURE:
            return {
                ...state,
                authenticatingGmail: false,
                authenticateGmailSuccess: null,
                authenticateGmailFailure: action.payload,
            };

        case vendorTypes.AUTHENTICATE_GMAIL_SETTINGS_REQUEST:
            return {
                ...state,
                authenticatingGmailSettings: true,
                authenticateGmailSettingsSuccess: null,
                authenticateGmailSettingsFailure: null,
            };
        case vendorTypes.AUTHENTICATE_GMAIL_SETTINGS_SUCCESS:
            return {
                ...state,
                authenticatingGmailSettings: false,
                authenticateGmailSettingsSuccess: action.payload,
                authenticateGmailSettingsFailure: null,
            };
        case vendorTypes.AUTHENTICATE_GMAIL_SETTINGS_FAILURE:
            return {
                ...state,
                authenticatingGmailSettings: false,
                authenticateGmailSettingsSuccess: null,
                authenticateGmailSettingsFailure: action.payload,
            };

        case vendorTypes.CONNECT_GOOGLE_WORKSPACE_REQUEST:
            return {
                ...state,
                connectingGoogleWorkspace: true,
                connectGoogleWorkspaceSuccess: null,
                connectGoogleWorkspaceFailure: null,
            };
        case vendorTypes.CONNECT_GOOGLE_WORKSPACE_SUCCESS:
            return {
                ...state,
                connectingGoogleWorkspace: false,
                connectGoogleWorkspaceSuccess: action.payload,
                connectGoogleWorkspaceFailure: null,
            };
        case vendorTypes.CONNECT_GOOGLE_WORKSPACE_FAILURE:
            return {
                ...state,
                connectingGoogleWorkspace: false,
                connectGoogleWorkspaceSuccess: null,
                connectGoogleWorkspaceFailure: action.payload,
            };

        case vendorTypes.CONNECT_GOOGLE_WORKSPACE_SETTINGS_REQUEST:
            return {
                ...state,
                connectingGoogleWorkspaceSettings: true,
                connectGoogleWorkspaceSettingsSuccess: null,
                connectGoogleWorkspaceSettingsFailure: null,
            };
        case vendorTypes.CONNECT_GOOGLE_WORKSPACE_SETTINGS_SUCCESS:
            return {
                ...state,
                connectingGoogleWorkspaceSettings: false,
                connectGoogleWorkspaceSettingsSuccess: action.payload,
                connectGoogleWorkspaceSettingsFailure: null,
            };
        case vendorTypes.CONNECT_GOOGLE_WORKSPACE_SETTINGS_FAILURE:
            return {
                ...state,
                connectingGoogleWorkspaceSettings: false,
                connectGoogleWorkspaceSettingsSuccess: null,
                connectGoogleWorkspaceSettingsFailure: action.payload,
            };

        case vendorTypes.FETCH_ALL_CHANNELS_REQUEST:
            return {
                ...state,
                fetchingAllChannels: true,
                fetchedAllChannelsSuccess: null,
                fetchedAllChannelsFailure: null,
            };
        case vendorTypes.FETCH_ALL_CHANNELS_SUCCESS:
            return {
                ...state,
                fetchingAllChannels: false,
                fetchedAllChannelsSuccess: action.payload,
                fetchedAllChannelsFailure: null,
            };
        case vendorTypes.FETCH_ALL_CHANNELS_FAILURE:
            return {
                ...state,
                fetchingAllChannels: false,
                fetchedAllChannelsSuccess: null,
                fetchedAllChannelsFailure: action.payload,
            };

        case vendorTypes.FETCH_VENDORS_BY_ORDER_TYPE_REQUEST:
            return {
                ...state,
                fetchingVendorsByOrderType: true,
                fetchedVendorsByOrderTypeSuccess: null,
                fetchedVendorsByOrderTypeFailure: null,
            };
        case vendorTypes.FETCH_VENDORS_BY_ORDER_TYPE_SUCCESS:
            return {
                ...state,
                fetchingVendorsByOrderType: false,
                fetchedVendorsByOrderTypeSuccess: action.payload,
                fetchedVendorsByOrderTypeFailure: null,
            };
        case vendorTypes.FETCH_VENDORS_BY_ORDER_TYPE_FAILURE:
            return {
                ...state,
                fetchingVendorsByOrderType: false,
                fetchedVendorsByOrderTypeSuccess: null,
                fetchedVendorsByOrderTypeFailure: action.payload,
            };

        case vendorTypes.FETCH_ONBOARDED_VENDORS_REQUEST:
            return {
                ...state,
                fetchingOnboardedVendors: true,
                fetchedOnboardedVendorsSuccess: null,
                fetchedOnboardedVendorsFailure: null,
            };
        case vendorTypes.FETCH_ONBOARDED_VENDORS_SUCCESS:
            return {
                ...state,
                fetchingOnboardedVendors: false,
                fetchedOnboardedVendorsSuccess: action.payload,
                fetchedOnboardedVendorsFailure: null,
            };
        case vendorTypes.FETCH_ONBOARDED_VENDORS_FAILURE:
            return {
                ...state,
                fetchingOnboardedVendors: false,
                fetchedOnboardedVendorsSuccess: null,
                fetchedOnboardedVendorsFailure: action.payload,
            };

        case vendorTypes.FETCH_SUGGESTED_VENDORS_REQUEST:
            return {
                ...state,
                fetchingSuggestedVendors: true,
                fetchedSuggestedVendorsSuccess: null,
                fetchedSuggestedVendorsFailure: null,
            };
        case vendorTypes.FETCH_SUGGESTED_VENDORS_SUCCESS:
            return {
                ...state,
                fetchingSuggestedVendors: false,
                fetchedSuggestedVendorsSuccess: action.payload,
                fetchedSuggestedVendorsFailure: null,
            };
        case vendorTypes.FETCH_SUGGESTED_VENDORS_FAILURE:
            return {
                ...state,
                fetchingSuggestedVendors: false,
                fetchedSuggestedVendorsSuccess: null,
                fetchedSuggestedVendorsFailure: action.payload,
            };

        case vendorTypes.AUTHENTICATE_MICROSOFT_REQUEST:
            return {
                ...state,
                authenticatingMicrosoft: true,
                authenticateMicrosoftSuccess: null,
                authenticateMicrosoftFailure: null,
            };
        case vendorTypes.AUTHENTICATE_MICROSOFT_SUCCESS:
            return {
                ...state,
                authenticatingMicrosoft: false,
                authenticateMicrosoftSuccess: action.payload,
                authenticateMicrosoftFailure: null,
            };
        case vendorTypes.AUTHENTICATE_MICROSOFT_FAILURE:
            return {
                ...state,
                authenticatingMicrosoft: false,
                authenticateMicrosoftSuccess: null,
                authenticateMicrosoftFailure: action.payload,
            };

        case vendorTypes.AUTHENTICATE_MICROSOFT_SETTINGS_REQUEST:
            return {
                ...state,
                authenticatingMicrosoftSettings: true,
                authenticateMicrosoftSettingsSuccess: null,
                authenticateMicrosoftSettingsFailure: null,
            };
        case vendorTypes.AUTHENTICATE_MICROSOFT_SETTINGS_SUCCESS:
            return {
                ...state,
                authenticatingMicrosoftSettings: false,
                authenticateMicrosoftSettingsSuccess: action.payload,
                authenticateMicrosoftSettingsFailure: null,
            };
        case vendorTypes.AUTHENTICATE_MICROSOFT_SETTINGS_FAILURE:
            return {
                ...state,
                authenticatingMicrosoftSettings: false,
                authenticateMicrosoftSettingsSuccess: null,
                authenticateMicrosoftSettingsFailure: action.payload,
            };
        case vendorTypes.CONNECT_MICROSOFT_WORKSPACE_REQUEST:
            return {
                ...state,
                connectingMicrosoftWorkspace: true,
                connectMicrosoftWorkspaceSuccess: null,
                connectMicrosoftWorkspaceFailure: null,
            };
        case vendorTypes.CONNECT_MICROSOFT_WORKSPACE_SUCCESS:
            return {
                ...state,
                connectingMicrosoftWorkspace: false,
                connectMicrosoftWorkspaceSuccess: action.payload,
                connectMicrosoftWorkspaceFailure: null,
            };
        case vendorTypes.CONNECT_MICROSOFT_WORKSPACE_FAILURE:
            return {
                ...state,
                connectingMicrosoftWorkspace: false,
                connectMicrosoftWorkspaceSuccess: null,
                connectMicrosoftWorkspaceFailure: action.payload,
            };
        case vendorTypes.CONNECT_MICROSOFT_WORKSPACE_SETTINGS_REQUEST:
            return {
                ...state,
                connectingMicrosoftWorkspaceSettings: true,
                connectMicrosoftWorkspaceSettingsSuccess: null,
                connectMicrosoftWorkspaceSettingsFailure: null,
            };
        case vendorTypes.CONNECT_MICROSOFT_WORKSPACE_SETTINGS_SUCCESS:
            return {
                ...state,
                connectingMicrosoftWorkspaceSettings: false,
                connectMicrosoftWorkspaceSettingsSuccess: action.payload,
                connectMicrosoftWorkspaceSettingsFailure: null,
            };
        case vendorTypes.CONNECT_MICROSOFT_WORKSPACE_SETTINGS_FAILURE:
            return {
                ...state,
                connectingMicrosoftWorkspaceSettings: false,
                connectMicrosoftWorkspaceSettingsSuccess: null,
                connectMicrosoftWorkspaceSettingsFailure: action.payload,
            };
        case vendorTypes.MANAGE_CONNECTION_REQUEST:
            return {
                ...state,
                manageConnection: true,
                manageConnectionSuccess: null,
                manageConnectionFailure: null,
            };
        case vendorTypes.MANAGE_CONNECTION_SUCCESS:
            return {
                ...state,
                manageConnection: false,
                manageConnectionSuccess: action.payload,
                manageConnectionFailure: null,
            };
        case vendorTypes.MANAGE_CONNECTION_FAILURE:
            return {
                ...state,
                manageConnection: false,
                manageConnectionSuccess: null,
                manageConnectionFailure: action.payload,
            };
        case vendorTypes.FETCH_VENDOR_BY_CATEGORY_REQUEST:
            return {
                ...state,
                fetchingVendorByCategory: true,
                fetchVendorByCategorySuccess: null,
                fetchVendorByCategoryFailure: null,
            };
        case vendorTypes.FETCH_VENDOR_BY_CATEGORY_SUCCESS:
            return {
                ...state,
                fetchingVendorByCategory: false,
                fetchVendorByCategorySuccess: action.payload,
                fetchVendorByCategoryFailure: null,
            };
        case vendorTypes.FETCH_VENDOR_BY_CATEGORY_FAILURE:
            return {
                ...state,
                fetchingVendorByCategory: false,
                fetchVendorByCategorySuccess: null,
                fetchVendorByCategoryFailure: action.payload,
            };
        case vendorTypes.ADD_VENDOR_REQUEST:
            return {
                ...state,
                addingVendor: true,
                addVendorSuccess: null,
                addVendorFailure: null,
            };
        case vendorTypes.ADD_VENDOR_SUCCESS:
            return {
                ...state,
                addingVendor: false,
                addVendorSuccess: action.payload,
                addVendorFailure: null,
            };
        case vendorTypes.ADD_VENDOR_FAILURE:
            return {
                ...state,
                addingVendor: false,
                addVendorSuccess: null,
                addVendorFailure: action.payload,
            };
        case vendorTypes.UPDATE_VENDOR_REQUEST:
            return {
                ...state,
                updatingVendor: true,
                updateVendorSuccess: null,
                updateVendorFailure: null,
            };
        case vendorTypes.UPDATE_VENDOR_SUCCESS:
            return {
                ...state,
                updatingVendor: false,
                updateVendorSuccess: action.payload,
                updateVendorFailure: null,
            };
        case vendorTypes.UPDATE_VENDOR_FAILURE:
            return {
                ...state,
                updatingVendor: false,
                updateVendorSuccess: null,
                updateVendorFailure: action.payload,
            };
        case vendorTypes.FETCH_VENDORS_REQUEST:
            return {
                ...state,
                fetchingLdbVendor: true,
                fetchLdbVendorSuccess: null,
                fetchLdbVendorFailure: null,
            };
        case vendorTypes.FETCH_VENDORS_SUCCESS:
            return {
                ...state,
                fetchingLdbVendor: false,
                fetchLdbVendorSuccess: action.payload,
                fetchLdbVendorFailure: null,
            };
        case vendorTypes.FETCH_VENDORS_FAILURE:
            return {
                ...state,
                fetchingLdbVendor: false,
                fetchLdbVendorSuccess: null,
                fetchLdbVendorFailure: action.payload,
            };
        case vendorTypes.FETCH_SUPPLIER_ANALYTICS_REQUEST:
            return {
                ...state,
                fetchingSupplierAnalytics: true,
                fetchSupplierAnalyticsSuccess: null,
                fetchSupplierAnalyticsFailure: null,
            };
        case vendorTypes.FETCH_SUPPLIER_ANALYTICS_SUCCESS:
            return {
                ...state,
                fetchingSupplierAnalytics: false,
                fetchSupplierAnalyticsSuccess: action.payload,
                fetchSupplierAnalyticsFailure: null,
            };
        case vendorTypes.FETCH_SUPPLIER_ANALYTICS_FAILURE:
            return {
                ...state,
                fetchingSupplierAnalytics: false,
                fetchSupplierAnalyticsSuccess: null,
                fetchSupplierAnalyticsFailure: action.payload,
            };
        case vendorTypes.FETCH_SUPPLIER_PRODUCTS_REQUEST:
            return {
                ...state,
                fetchingSupplierProducts: true,
                fetchSupplierProductsSuccess: null,
                fetchSupplierProductsFailure: null,
            };
        case vendorTypes.FETCH_SUPPLIER_PRODUCTS_SUCCESS:
            return {
                ...state,
                fetchingSupplierProducts: false,
                fetchSupplierProductsSuccess: action.payload,
                fetchSupplierProductsFailure: null,
            };
        case vendorTypes.FETCH_SUPPLIER_PRODUCTS_FAILURE:
            return {
                ...state,
                fetchingSupplierProducts: false,
                fetchSupplierProductsSuccess: null,
                fetchSupplierProductsFailure: action.payload,
            };
        case vendorTypes.FETCH_SUPPLIER_ONGOING_PO_REQUEST:
            return {
                ...state,
                fetchingSupplierOngoingPo: true,
                fetchSupplierOngoingPoSuccess: null,
                fetchSupplierOngoingPoFailure: null,
            };
        case vendorTypes.FETCH_SUPPLIER_ONGOING_PO_SUCCESS:
            return {
                ...state,
                fetchingSupplierOngoingPo: false,
                fetchSupplierOngoingPoSuccess: action.payload,
                fetchSupplierOngoingPoFailure: null,
            };
        case vendorTypes.FETCH_SUPPLIER_ONGOING_PO_FAILURE:
            return {
                ...state,
                fetchingSupplierQuotes: false,
                fetchSupplierQuotesSuccess: null,
                fetchSupplierQuotesFailure: action.payload,
            };
        case vendorTypes.FETCH_SUPPLIER_QUOTES_REQUEST:
            return {
                ...state,
                fetchingSupplierQuotes: true,
                fetchSupplierQuotesSuccess: null,
                fetchSupplierQuotesFailure: null,
            };
        case vendorTypes.FETCH_SUPPLIER_QUOTES_SUCCESS:
            return {
                ...state,
                fetchingSupplierQuotes: false,
                fetchSupplierQuotesSuccess: action.payload,
                fetchSupplierQuotesFailure: null,
            };
        case vendorTypes.FETCH_SUPPLIER_QUOTES_FAILURE:
            return {
                ...state,
                fetchingSupplierQuotes: false,
                fetchSupplierQuotesSuccess: null,
                fetchSupplierQuotesFailure: action.payload,
            };
        case vendorTypes.FETCH_SUPPLIER_DETAILS_REQUEST:
            return {
                ...state,
                fetchingSupplierDetails: true,
                fetchSupplierDetailsSuccess: null,
                fetchSupplierDetailsFailure: null,
            };
        case vendorTypes.FETCH_SUPPLIER_DETAILS_SUCCESS:
            return {
                ...state,
                fetchingSupplierDetails: false,
                fetchSupplierDetailsSuccess: action.payload,
                fetchSupplierDetailsFailure: null,
            };
        case vendorTypes.FETCH_SUPPLIER_DETAILS_FAILURE:
            return {
                ...state,
                fetchingSupplierDetails: false,
                fetchSupplierDetailsSuccess: null,
                fetchSupplierDetailsFailure: action.payload,
            };
        case vendorTypes.DELETE_SUPPLIER_REQUEST:
            return {
                ...state,
                deletingSupplier: true,
                deleteSupplierSuccess: null,
                deleteSupplierFailure: null,
            };
        case vendorTypes.DELETE_SUPPLIER_SUCCESS:
            return {
                ...state,
                deletingSupplier: false,
                deleteSupplierSuccess: action.payload,
                deleteSupplierFailure: null,
            };
        case vendorTypes.DELETE_SUPPLIER_FAILURE:
            return {
                ...state,
                deletingSupplier: false,
                deleteSupplierSuccess: null,
                deleteSupplierFailure: action.payload,
            };
        case vendorTypes.FETCH_SUPPLIER_RETURNED_GOODS_REQUEST:
            return {
                ...state,
                fetchingSupplierReturnedGoods: true,
                fetchSupplierReturnedGoodsSuccess: null,
                fetchSupplierReturnedGoodsFailure: null,
            };
        case vendorTypes.FETCH_SUPPLIER_RETURNED_GOODS_SUCCESS:
            return {
                ...state,
                fetchingSupplierReturnedGoods: false,
                fetchSupplierReturnedGoodsSuccess: action.payload,
                fetchSupplierReturnedGoodsFailure: null,
            };
        case vendorTypes.FETCH_SUPPLIER_RETURNED_GOODS_FAILURE:
            return {
                ...state,
                fetchingSupplierReturnedGoods: false,
                fetchSupplierReturnedGoodsSuccess: null,
                fetchSupplierReturnedGoodsFailure: action.payload,
            };
        case vendorTypes.FETCH_SUPPLIER_PRODUCT_HISTORY_REQUEST:
            return {
                ...state,
                fetchingSupplierProductHistory: true,
                fetchSupplierProductHistorySuccess: null,
                fetchSupplierProductHistoryFailure: null,
            };
        case vendorTypes.FETCH_SUPPLIER_PRODUCT_HISTORY_SUCCESS:
            return {
                ...state,
                fetchingSupplierProductHistory: false,
                fetchSupplierProductHistorySuccess: action.payload,
                fetchSupplierProductHistoryFailure: null,
            };
        case vendorTypes.FETCH_SUPPLIER_PRODUCT_HISTORY_FAILURE:
            return {
                ...state,
                fetchingSupplierProductHistory: false,
                fetchSupplierProductHistorySuccess: null,
                fetchSupplierProductHistoryFailure: action.payload,
            };
        default:
            return state;
    }
};
