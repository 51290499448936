import { salesTypes } from "../types";

const initialState = {
    fetchingSalesOrderList: false,
    fetchSalesOrderListSuccess: null,
    fetchSalesOrderListFailure: null,
    creatingSalesOrder: false,
    createSalesOrderSuccess: null,
    createSalesOrderFailure: null,
    fetchingSingleSalesOrder: false,
    fetchSingleSalesOrderSuccess: null,
    fetchSingleSalesOrderFailure: null,
    fetchingSalesDashboardStats: false,
    fetchSalesDashboardStatsSuccess: null,
    fetchSalesDashboardStatsFailure: null,
    fetchingSalesTopAndLeastProducts: false,
    fetchSalesTopAndLeastProductsSuccess: null,
    fetchSalesTopAndLeastProductsFailure: null,
    fetchingSalesVelocityStat: false,
    fetchSalesVelocityStatSuccess: null,
    fetchSalesVelocityStatFailure: null,
    fetchingSalesChannelStat: false,
    fetchSalesChannelStatSuccess: null,
    fetchSalesChannelStatFailure: null,
    fetchingCustomerList: false,
    fetchCustomerListSuccess: null,
    fetchCustomerListFailure: null,
    fetchingSalesTopAndLeastRegions: false,
    fetchSalesTopAndLeastRegionsSuccess: null,
    fetchSalesTopAndLeastRegionsFailure: null,
    creatingSalesShipment: false,
    createSalesShipmentSuccess: null,
    createSalesShipmentFailure: null,
};

export const sales = (state = initialState, action: { type: string; payload: any }) => {
    switch (action.type) {
        case salesTypes.FETCH_SALES_TOP_AND_LEAST_REGIONS_REQUEST:
            return {
                ...state,
                fetchingSalesTopAndLeastRegions: true,
                fetchSalesTopAndLeastRegionsSuccess: null,
                fetchSalesTopAndLeastRegionsFailure: null,
            };
        case salesTypes.FETCH_SALES_TOP_AND_LEAST_REGIONS_SUCCESS:
            return {
                ...state,
                fetchingSalesTopAndLeastRegions: false,
                fetchSalesTopAndLeastRegionsSuccess: action.payload,
                fetchSalesTopAndLeastRegionsFailure: null,
            };
        case salesTypes.FETCH_SALES_TOP_AND_LEAST_REGIONS_FAILURE:
            return {
                ...state,
                fetchingSalesTopAndLeastRegions: false,
                fetchSalesTopAndLeastRegionsSuccess: null,
                fetchSalesTopAndLeastRegionsFailure: action.payload,
            };

        case salesTypes.FETCH_CUSTOMER_LIST_REQUEST:
            return {
                ...state,
                fetchingCustomerList: true,
                fetchCustomerListSuccess: null,
                fetchCustomerListFailure: null,
            };
        case salesTypes.FETCH_CUSTOMER_LIST_SUCCESS:
            return {
                ...state,
                fetchingCustomerList: false,
                fetchCustomerListSuccess: action.payload,
                fetchCustomerListFailure: null,
            };
        case salesTypes.FETCH_CUSTOMER_LIST_FAILURE:
            return {
                ...state,
                fetchingCustomerList: false,
                fetchCustomerListSuccess: null,
                fetchCustomerListFailure: action.payload,
            };

        case salesTypes.FETCH_SALES_CHANNEL_STAT_REQUEST:
            return {
                ...state,
                fetchingSalesChannelStat: true,
                fetchSalesChannelStatSuccess: null,
                fetchSalesChannelStatFailure: null,
            };
        case salesTypes.FETCH_SALES_CHANNEL_STAT_SUCCESS:
            return {
                ...state,
                fetchingSalesChannelStat: false,
                fetchSalesChannelStatSuccess: action.payload,
                fetchSalesChannelStatFailure: null,
            };
        case salesTypes.FETCH_SALES_CHANNEL_STAT_FAILURE:
            return {
                ...state,
                fetchingSalesChannelStat: false,
                fetchSalesChannelStatSuccess: null,
                fetchSalesChannelStatFailure: action.payload,
            };

        case salesTypes.FETCH_SALES_VELOCITY_STAT_REQUEST:
            return {
                ...state,
                fetchingSalesVelocityStat: true,
                fetchSalesVelocityStatSuccess: null,
                fetchSalesVelocityStatFailure: null,
            };
        case salesTypes.FETCH_SALES_VELOCITY_STAT_SUCCESS:
            return {
                ...state,
                fetchingSalesVelocityStat: false,
                fetchSalesVelocityStatSuccess: action.payload,
                fetchSalesVelocityStatFailure: null,
            };
        case salesTypes.FETCH_SALES_VELOCITY_STAT_FAILURE:
            return {
                ...state,
                fetchingSalesVelocityStat: false,
                fetchSalesVelocityStatSuccess: null,
                fetchSalesVelocityStatFailure: action.payload,
            };

        case salesTypes.FETCH_SALES_TOP_AND_LEAST_PRODUCTS_REQUEST:
            return {
                ...state,
                fetchingSalesTopAndLeastProducts: true,
                fetchSalesTopAndLeastProductsSuccess: null,
                fetchSalesTopAndLeastProductsFailure: null,
            };
        case salesTypes.FETCH_SALES_TOP_AND_LEAST_PRODUCTS_SUCCESS:
            return {
                ...state,
                fetchingSalesTopAndLeastProducts: false,
                fetchSalesTopAndLeastProductsSuccess: action.payload,
                fetchSalesTopAndLeastProductsFailure: null,
            };
        case salesTypes.FETCH_SALES_TOP_AND_LEAST_PRODUCTS_FAILURE:
            return {
                ...state,
                fetchingSalesTopAndLeastProducts: false,
                fetchSalesTopAndLeastProductsSuccess: null,
                fetchSalesTopAndLeastProductsFailure: action.payload,
            };

        case salesTypes.FETCH_SALES_DASHBOARD_STATS_REQUEST:
            return {
                ...state,
                fetchingSalesDashboardStats: true,
                fetchSalesDashboardStatsSuccess: null,
                fetchSalesDashboardStatsFailure: null,
            };
        case salesTypes.FETCH_SALES_DASHBOARD_STATS_SUCCESS:
            return {
                ...state,
                fetchingSalesDashboardStats: false,
                fetchSalesDashboardStatsSuccess: action.payload,
                fetchSalesDashboardStatsFailure: null,
            };
        case salesTypes.FETCH_SALES_DASHBOARD_STATS_FAILURE:
            return {
                ...state,
                fetchingSalesDashboardStats: false,
                fetchSalesDashboardStatsSuccess: null,
                fetchSalesDashboardStatsFailure: action.payload,
            };

        case salesTypes.FETCH_SALES_ORDER_LIST_REQUEST:
            return {
                ...state,
                fetchingSalesOrderList: true,
                fetchSalesOrderListSuccess: null,
                fetchSalesOrderListFailure: null,
            };
        case salesTypes.FETCH_SALES_ORDER_LIST_SUCCESS:
            return {
                ...state,
                fetchingSalesOrderList: false,
                fetchSalesOrderListSuccess: action.payload,
                fetchSalesOrderListFailure: null,
            };
        case salesTypes.FETCH_SALES_ORDER_LIST_FAILURE:
            return {
                ...state,
                fetchingSalesOrderList: false,
                fetchSalesOrderListSuccess: null,
                fetchSalesOrderListFailure: action.payload,
            };

        case salesTypes.CREATE_SALES_ORDER_REQUEST:
            return {
                ...state,
                creatingSalesOrder: true,
                createSalesOrderSuccess: null,
                createSalesOrderFailure: null,
            };
        case salesTypes.CREATE_SALES_ORDER_SUCCESS:
            return {
                ...state,
                creatingSalesOrder: false,
                createSalesOrderSuccess: action.payload,
                createSalesOrderFailure: null,
            };
        case salesTypes.CREATE_SALES_ORDER_FAILURE:
            return {
                ...state,
                creatingSalesOrder: false,
                createSalesOrderSuccess: null,
                createSalesOrderFailure: action.payload,
            };

        case salesTypes.FETCH_SINGLE_SALES_ORDER_REQUEST:
            return {
                ...state,
                fetchingSingleSalesOrder: true,
                fetchSingleSalesOrderSuccess: null,
                fetchSingleSalesOrderFailure: null,
            };
        case salesTypes.FETCH_SINGLE_SALES_ORDER_SUCCESS:
            return {
                ...state,
                fetchingSingleSalesOrder: false,
                fetchSingleSalesOrderSuccess: action.payload,
                fetchSingleSalesOrderFailure: null,
            };
        case salesTypes.FETCH_SINGLE_SALES_ORDER_FAILURE:
            return {
                ...state,
                fetchingSingleSalesOrder: false,
                fetchSingleSalesOrderSuccess: null,
                fetchSingleSalesOrderFailure: action.payload,
            };
        case salesTypes.CREATE_SALES_SHIPMENT_REQUEST:
            return {
                ...state,
                creatingSalesShipment: true,
                createSalesShipmentSuccess: null,
                createSalesShipmentFailure: null,
            };
        case salesTypes.CREATE_SALES_SHIPMENT_SUCCESS:
            return {
                ...state,
                creatingSalesShipment: false,
                createSalesShipmentSuccess: action.payload,
                createSalesShipmentFailure: null,
            };
        case salesTypes.CREATE_SALES_SHIPMENT_FAILURE:
            return {
                ...state,
                creatingSalesShipment: false,
                createSalesShipmentSuccess: null,
                createSalesShipmentFailure: action.payload,
            };

        default:
            return state;
    }
};
