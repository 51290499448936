import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import useInfiniteScroll from "hooks/useInfinteScroll";
import { debounce, uniqBy } from "lodash";
import React, { useEffect, useState } from "react";
import { productionActions } from "redux/Ldb/actions";

const useGetProductionPlanList = () => {
    const dispatch = useAppDispatch();
    const [search, setSearch] = useState<string>("");
    const [assigneeId, setAssigneeId] = useState<string>("");
    const [id, setId] = useState<string>("");
    const [status, setStatus] = useState<string>("");
    const [debouncedSearch, setDebouncedSearch] = useState<string>("");
    const [productionPlanList, setProductionPlanList] = useState<{ [key: string]: any }[]>([]);

    const [pagination, setPagination] = useState<{ currentPage: string; noOfPages: string }>({
        currentPage: "1",
        noOfPages: "",
    });

    const { fetchingProductionPlanList, fetchedProductionPlanListSuccess } = useAppSelector(
        (state) => state.production
    );

    const { updatedLimit, isLoadingMore, setLoadMore, updatedPage, setUpdatedPage } =
        useInfiniteScroll(
            { current: pagination.currentPage, number_of_pages: pagination.noOfPages },
            10
        );

    useEffect(() => {
        setUpdatedPage(1);
    }, [debouncedSearch, status, assigneeId]);

    const handleDebouncedChange = debounce((e: React.ChangeEvent<HTMLInputElement>) => {
        setUpdatedPage(1);
        setDebouncedSearch(e.target.value);
    }, 1000);

    const handleSearch = (e) => {
        setSearch(e?.target?.value);
    };

    const handleClearStatus = () => {
        setStatus("");
    };

    const handleStatusChange = (e) => {
        setStatus(e.target.value);
    };

    const handleAssigneeIdChange = (e) => {
        setAssigneeId(e.target.value);
        setId(e.target.value?.split("-")[0]);
    };

    useEffect(() => {
        dispatch(
            productionActions.fetchProductionPlanList(10, updatedPage, debouncedSearch, status, id)
        );
    }, [dispatch, debouncedSearch, updatedPage, status, assigneeId]);

    useEffect(() => {
        if (Boolean(fetchedProductionPlanListSuccess)) {
            setProductionPlanList(fetchedProductionPlanListSuccess?.plans);
        }
    }, [fetchedProductionPlanListSuccess]);

    useEffect(() => {
        if (Boolean(fetchedProductionPlanListSuccess)) {
            setProductionPlanList((prev) =>
                updatedPage === 1
                    ? uniqBy([...fetchedProductionPlanListSuccess?.plans], "_id")
                    : uniqBy([...prev, ...fetchedProductionPlanListSuccess?.plans], "_id")
            );
            setPagination({
                currentPage: fetchedProductionPlanListSuccess.pagination?.current,
                noOfPages: fetchedProductionPlanListSuccess.pagination?.number_of_pages,
            });
            setLoadMore(false);
            // dispatch(productionActions.resetProductionPlanList());
        }
    }, [
        dispatch,
        fetchedProductionPlanListSuccess,
        updatedPage,
        debouncedSearch,
        status,
        assigneeId,
    ]);

    return {
        data: productionPlanList,
        isLoading: fetchingProductionPlanList,
        isLoadingMore,
        pagination,
        debouncedSearch,
        setDebouncedSearch,
        search,
        setSearch,
        handleSearch,
        handleDebouncedChange,
        handleStatusChange,
        handleAssigneeIdChange,
        assigneeId,
        setAssigneeId,
        status,
        setStatus,
        handleClearStatus,
    };
};

export default useGetProductionPlanList;
