import React, { useEffect, useState } from "react";
import TextInput from "./InputField/TextInput";
import TextareaInput from "./InputField/TextareaInput";
import Button from "./Button";
import SelectInput from "./InputField/SelectInput";
import DateInput from "./DateInput";
import { isFileSizeLessThanOrEqualTo } from "helpers/compareFileSizeInMb";
import toast from "react-hot-toast";
import CustomToast from "./CustomToast";
import { displayFileIcon } from "helpers";
import { getFileTypeFromUrl } from "helpers/getFileTypeFromUrl";
import Loader from "./Loader";
import countries from "variables/countries";
import ReactCountryFlag from "react-country-flag";
import { Link } from "react-router-dom";

type customFieldsProps = {
    displayCustomField?: any;
    fieldType: string;
    handleCustomFieldData?: any;
    singleValueField?: any;
    fieldName?: string;
    checkboxOption?: any;
    multipleValuesField?: any;
    handleSelect?: any;
    addMoreOption?: () => void;
    handleDelete?: any;
    required?: boolean;
    className?: string;
    customAttachments?: any;
    uploadingCustomAttachment?: boolean;
    customDate?: string | Date;
    onChangeCustomDate?: (value?: string | Date) => void;
    handleCustomAttachmentUpload?: (fileName?: string, file?: File) => void;
    openModal?: boolean;
    recurringSetting?: boolean;
    deleteCustomAttachment?: (id: string) => void;
    deletingImage?: boolean;
};

type fileName = string;
type file = File;

const CustomFields = ({
    fieldType,
    displayCustomField,
    handleCustomFieldData,
    singleValueField,
    multipleValuesField,
    addMoreOption,
    handleSelect,
    handleDelete,
    className,
    customAttachments,
    uploadingCustomAttachment,
    customDate,
    onChangeCustomDate,
    handleCustomAttachmentUpload,
    openModal,
    recurringSetting,
    deleteCustomAttachment,
    deletingImage,
}: customFieldsProps) => {
    const [showDate, setShowDate] = useState<boolean>(false);
    const [mapSelect, setMapSelect] = useState<any>([]);

    useEffect(() => {
        if (multipleValuesField?.value?.length > 0) {
            setMapSelect(multipleValuesField?.value);
        }
    }, [multipleValuesField]);
    return (
        <div>
            <div className="">
                {fieldType === "text" ? (
                    <div className={`mb-4 ${className}`}>
                        {openModal && recurringSetting && (
                            <TextInput
                                value={
                                    displayCustomField?.placeholder || singleValueField?.placeholder
                                }
                                name="placeholder"
                                type={"text"}
                                onChange={handleCustomFieldData}
                                placeholder={
                                    displayCustomField?.placeholderValue || "Enter placeholder text"
                                }
                                inputContainerClassname={`${
                                    singleValueField?.placeholder &&
                                    "!border-g-60 !border-[0.5px] !border-b-[1px] "
                                }  !mb-2`}
                                required={true}
                            />
                        )}

                        <TextInput
                            value={
                                displayCustomField?.value ||
                                displayCustomField?.placeholder ||
                                singleValueField?.text
                            }
                            name="text"
                            type={"text"}
                            onChange={handleCustomFieldData}
                            placeholder={
                                displayCustomField?.name ||
                                displayCustomField?.placeholderValue ||
                                "Enter text"
                            }
                            inputContainerClassname={`${
                                singleValueField?.text &&
                                "!border-g-60 !border-[0.5px] !border-b-[1px]"
                            }  `}
                            disabled={!openModal}
                            required={true}
                        />
                    </div>
                ) : fieldType === "multiline" ? (
                    <div className={`mb-4 ${className}`}>
                        {openModal && recurringSetting && (
                            <TextInput
                                value={
                                    displayCustomField?.placeholder || singleValueField?.placeholder
                                }
                                name="placeholder"
                                type={"text"}
                                onChange={handleCustomFieldData}
                                placeholder={displayCustomField?.name || "Enter placeholder text"}
                                inputContainerClassname={`${
                                    singleValueField?.placeholder &&
                                    "!border-g-60 !border-[0.5px] !border-b-[1px] "
                                }  !mb-2`}
                                disabled={!openModal}
                                required={true}
                            />
                        )}
                        <TextareaInput
                            name="multiline"
                            value={
                                displayCustomField?.value ||
                                displayCustomField?.placeholder ||
                                singleValueField?.multiline
                            }
                            onChange={handleCustomFieldData}
                            inputContainerClassname={`${
                                singleValueField?.multiline &&
                                "!border-g-60 !border-[0.5px] !border-b-[1px]"
                            }  `}
                            placeholder={displayCustomField?.name || "Text"}
                            disabled={!openModal}
                            containerClassname=""
                            required
                        />
                    </div>
                ) : fieldType === "checkbox" ? (
                    <div className={`flex flex-col gap-4 mb-4 ${className}`}>
                        {displayCustomField?.value?.length > 0 ? (
                            <div className="">
                                <p className="">{displayCustomField?.name}</p>

                                <div className="flex flex-col justify-center w-full  gap-3">
                                    {displayCustomField?.value?.map((values, idx) => {
                                        return (
                                            <div
                                                className="border border-slate-200 rounded px-3 gap-3 py-3 flex items-center"
                                                key={idx}
                                            >
                                                <i
                                                    className={`${
                                                        values?.selected
                                                            ? "ri-checkbox-fill "
                                                            : "ri-checkbox-blank-line text-slate-400"
                                                    }   text-xl rounded text-[#16c745]`}
                                                ></i>

                                                <input
                                                    value={values?.value || ""}
                                                    name="value"
                                                    type={"text"}
                                                    disabled={!openModal}
                                                    className={`outline-none flex-1 !font-rocGroteskMedium text-slate-500 text-sm bg-transparent `}
                                                    required
                                                />
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        ) : (
                            <>
                                {mapSelect?.length > 0 &&
                                    mapSelect?.map((item, idx) => {
                                        return (
                                            <div className="" key={idx}>
                                                <div className="flex items-center w-full border border-slate-200 rounded gap-3 py-3 px-3">
                                                    <i
                                                        className={`${
                                                            item?.selected
                                                                ? "ri-checkbox-line"
                                                                : "ri-checkbox-blank-line"
                                                        }  text-slate-400 text-xl cursor-pointer rounded`}
                                                        onClick={() => handleSelect("check", idx)}
                                                    ></i>

                                                    <input
                                                        value={item?.value || ""}
                                                        name="value"
                                                        type={"text"}
                                                        placeholder={`Option ${idx + 1}`}
                                                        onChange={(e) =>
                                                            handleSelect(
                                                                "input",
                                                                idx,
                                                                e.target.value
                                                            )
                                                        }
                                                        className={`outline-none flex-1 !font-rocGroteskMedium text-slate-500 text-sm 
                                           
                                                `}
                                                        required
                                                    />
                                                    {idx !== 0 && (
                                                        <i
                                                            className="ri-delete-bin-6-line cursor-pointer text-slate-400 text-xl"
                                                            onClick={() =>
                                                                !deletingImage && handleDelete(idx)
                                                            }
                                                        ></i>
                                                    )}
                                                </div>
                                            </div>
                                        );
                                    })}

                                <Button
                                    btnText="Add more option"
                                    btnClassname="!border-none !w-fit !bg-transparent !px-0 !py-0"
                                    btnTextClassName="!underline"
                                    icon=<i className="ri-add-line"></i>
                                    onClick={addMoreOption}
                                />
                            </>
                        )}
                    </div>
                ) : fieldType === "number" ? (
                    <div className={`mb-4 ${className}`}>
                        <TextInput
                            name="number"
                            value={displayCustomField?.value || singleValueField?.number}
                            onChange={handleCustomFieldData}
                            type={"number"}
                            placeholder={displayCustomField?.name || "Enter number"}
                            inputContainerClassname={`${
                                displayCustomField?.value ||
                                (singleValueField?.number &&
                                    "!border-g-60 !border-[0.5px] !border-b-[1px]")
                            }`}
                            disabled={!openModal}
                            required
                        />
                    </div>
                ) : fieldType === "currency" ? (
                    <div className={`mb-4 ${className}`}>
                        <div className="flex gap-1">
                            <div className="w-[160px]">
                                <SelectInput
                                    value={
                                        displayCustomField?.currency || singleValueField?.currency
                                    }
                                    name="currency"
                                    placeholder="Currency"
                                    handleChange={(name, value) => {
                                        handleCustomFieldData({ target: { name, value } });
                                    }}
                                    className={`${
                                        displayCustomField?.currency ||
                                        (singleValueField?.currency &&
                                            "!border-g-60 !border-[0.5px] !border-b-[1px]")
                                    }`}
                                    isRequired={true}
                                    clearValue
                                    disabled={!openModal}
                                    dropdownOptions={[
                                        {
                                            label: "GBP (£)",
                                            value: "GBP",
                                        },
                                        {
                                            label: "USD ($)",
                                            value: "USD",
                                        },
                                        {
                                            label: "EUR (€)",
                                            value: "EUR",
                                        },
                                        {
                                            label: "NGN (₦)",
                                            value: "NGN",
                                        },
                                    ]}
                                />
                            </div>

                            <TextInput
                                value={displayCustomField?.amount || singleValueField?.amount || ""}
                                name={"amount"}
                                type={"number"}
                                onChange={handleCustomFieldData}
                                placeholder={displayCustomField?.name || "Amount"}
                                inputContainerClassname={`${
                                    singleValueField?.amount &&
                                    "!border-g-60 !border-[0.5px] !border-b-[1px]"
                                }`}
                                required
                                disabled={!openModal}
                            />
                        </div>
                    </div>
                ) : fieldType === "phone" ? (
                    <div className={`mb-4 ${className}`}>
                        <div className="flex gap-1">
                            <div className="w-[160px]">
                                <SelectInput
                                    value={
                                        displayCustomField?.mobileExtension ||
                                        singleValueField?.mobileExtension
                                    }
                                    name="mobileExtension"
                                    placeholder="Ext"
                                    handleChange={(name, value) => {
                                        handleCustomFieldData({ target: { name, value } });
                                    }}
                                    className={`${
                                        displayCustomField?.mobileExtension ||
                                        (singleValueField?.mobileExtension &&
                                            "!border-g-60 !border-[0.5px] !border-b-[1px]")
                                    }`}
                                    isRequired={true}
                                    clearValue
                                    disabled={!openModal}
                                    dropdownOptions={countries
                                        .filter((data) => Boolean(data?.codes[0]))
                                        .sort((p1, p2) =>
                                            p1.codes[0] > p2.codes[0]
                                                ? 1
                                                : p1.codes[0] < p2.codes[0]
                                                ? -1
                                                : 0
                                        )
                                        .map((item) => ({
                                            label: item.codes[0]?.replace(" ", ""),
                                            value: item.codes[0]?.replace(" ", ""),
                                            icon: (
                                                <ReactCountryFlag
                                                    countryCode={item?.abbreviation}
                                                    svg
                                                    cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
                                                    cdnSuffix="svg"
                                                    style={{
                                                        width: "20px",
                                                        height: "20px",
                                                        borderRadius: "50%",
                                                        marginTop: "-4px",
                                                    }}
                                                />
                                            ),
                                        }))}
                                />
                            </div>

                            <TextInput
                                value={displayCustomField?.mobile || singleValueField?.mobile || ""}
                                name={"mobile"}
                                type={"number"}
                                onChange={handleCustomFieldData}
                                placeholder={displayCustomField?.name || "Phone number"}
                                inputContainerClassname={`${
                                    displayCustomField?.mobile ||
                                    (singleValueField?.mobile &&
                                        "!border-g-60 !border-[0.5px] !border-b-[1px]")
                                }`}
                                disabled={!openModal}
                                required
                            />
                        </div>
                    </div>
                ) : fieldType === "dropDown" ? (
                    <div className={`flex flex-col gap-4 mb-4  ${className}`}>
                        {displayCustomField?.value?.length > 0 ? (
                            <SelectInput
                                value={displayCustomField?.value[0]?.value}
                                placeholder={displayCustomField.name}
                                dropdownOptions={displayCustomField.value?.map((item, idx) => ({
                                    label: item?.value,
                                    value: item?.value,
                                }))}
                            />
                        ) : (
                            <>
                                {mapSelect?.length > 0 &&
                                    mapSelect?.map((item, idx) => {
                                        return (
                                            <div className="" key={idx}>
                                                <div className="flex items-center w-full border border-slate-200 rounded px-[15px] gap-3 py-3">
                                                    <div className="h-5 w-5 rounded-full bg-slate-200 flex items-center justify-center">
                                                        <i className="ri-arrow-down-s-line text-slate-400 text-xl cursor-pointer rounded"></i>
                                                    </div>

                                                    <input
                                                        value={item?.value || ""}
                                                        name="value"
                                                        type={"text"}
                                                        placeholder={`Option ${idx + 1}`}
                                                        onChange={(e) =>
                                                            handleSelect(
                                                                "input",
                                                                idx,
                                                                e.target.value
                                                            )
                                                        }
                                                        className={`outline-none flex-1 !font-rocGroteskMedium text-slate-500 text-sm `}
                                                        required
                                                    />
                                                    {idx !== 0 && (
                                                        <i
                                                            className="ri-delete-bin-6-line cursor-pointer text-slate-400 text-xl"
                                                            onClick={() => handleDelete(idx)}
                                                        ></i>
                                                    )}
                                                </div>
                                            </div>
                                        );
                                    })}
                                <Button
                                    btnText="Add more option"
                                    btnClassname="!border-none !w-fit !bg-transparent !px-0 !py-0"
                                    btnTextClassName="!underline"
                                    icon=<i className="ri-add-line"></i>
                                    onClick={addMoreOption}
                                />
                            </>
                        )}
                    </div>
                ) : fieldType === "radio" ? (
                    <div className={`flex flex-col justify-center w-full  gap-3 ${className}`}>
                        {displayCustomField?.value?.length > 0 ? (
                            <div className="">
                                <p className="">{displayCustomField?.name}</p>
                                <div className="flex flex-col w-full gap-3 pb-3">
                                    {displayCustomField?.value?.map((values, idx) => {
                                        return (
                                            <div
                                                className="border border-slate-200 rounded px-3 gap-3 py-3 flex items-center"
                                                key={idx}
                                            >
                                                <i
                                                    className={`${
                                                        values?.selected
                                                            ? "ri-radio-button-line"
                                                            : "ri-circle-line"
                                                    }  text-slate-400 text-xl rounded`}
                                                ></i>

                                                <input
                                                    value={values?.value || ""}
                                                    name="value"
                                                    type={"text"}
                                                    disabled={!openModal}
                                                    className={`outline-none flex-1 !font-rocGroteskMedium text-slate-500 text-sm bg-transparent`}
                                                    required
                                                />
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        ) : (
                            <>
                                {mapSelect?.length > 0 &&
                                    mapSelect?.map((item, idx) => {
                                        return (
                                            <div className="" key={idx}>
                                                <div className="flex items-center w-full border border-slate-200 rounded px-[15px] gap-3 py-3">
                                                    <i
                                                        className={`${
                                                            item?.selected
                                                                ? "ri-radio-button-line"
                                                                : "ri-circle-line"
                                                        }  text-slate-400 text-xl cursor-pointer rounded`}
                                                        onClick={() => handleSelect("radio", idx)}
                                                    ></i>

                                                    <input
                                                        value={item?.value || ""}
                                                        name="value"
                                                        type={"text"}
                                                        placeholder={`Option ${idx + 1}`}
                                                        onChange={(e) =>
                                                            handleSelect(
                                                                "input",
                                                                idx,
                                                                e.target.value
                                                            )
                                                        }
                                                        className={`outline-none flex-1 !font-rocGroteskMedium text-slate-500 text-sm `}
                                                        required
                                                    />
                                                    {idx !== 0 && (
                                                        <i
                                                            className="ri-delete-bin-6-line cursor-pointer text-slate-400 text-xl"
                                                            onClick={() => handleDelete(idx)}
                                                        ></i>
                                                    )}
                                                </div>
                                            </div>
                                        );
                                    })}
                                <Button
                                    btnText="Add more option"
                                    btnClassname="!border-none !w-fit !bg-transparent !px-0 !py-0"
                                    btnTextClassName="!underline"
                                    icon=<i className="ri-add-line"></i>
                                    onClick={addMoreOption}
                                />
                            </>
                        )}
                    </div>
                ) : fieldType === "date" ? (
                    <div className={`mb-4 ${className}`}>
                        <DateInput
                            label={`${displayCustomField?.name || "DD-MM-YYYY"} `}
                            value={displayCustomField?.value || singleValueField?.value}
                            onChange={(newDate) => onChangeCustomDate(newDate as Date)}
                            showCalendar={showDate}
                            onCalendarToggle={setShowDate}
                            // disabled={!openModal}
                            disabledShowCalendar={!openModal}
                            borderContainerClassname="!border-none"
                            borderClassname="!border-slate-200 !border !p-3 !rounded-md"
                        />
                    </div>
                ) : fieldType === "file" ? (
                    displayCustomField?.name ? (
                        <div className="mt-4 ">
                            <p className="">{displayCustomField?.name}</p>
                            {displayCustomField?.value?.length > 0 &&
                                displayCustomField?.value?.map((item, idx) => {
                                    return (
                                        <div
                                            className="flex items-center justify-between p-3 rounded-md border border-slate-200 mb-4"
                                            key={idx}
                                        >
                                            <div className="flex items-center gap-2">
                                                {displayFileIcon(
                                                    getFileTypeFromUrl(item?.path),
                                                    false
                                                )}
                                                <div className="font-rocGroteskMedium">
                                                    <p className="text-sm">{item?.filename}</p>
                                                    <p className="text-xs text-slate-500"></p>
                                                </div>
                                            </div>

                                            <div className="w-fit">
                                                <Link to={item?.path} target="_blank">
                                                    <Button
                                                        btnClassname="!bg-n-20 !py-[6px]"
                                                        btnText="View file"
                                                    />
                                                </Link>
                                            </div>
                                        </div>
                                    );
                                })}
                        </div>
                    ) : customAttachments?.value?.length > 0 ? (
                        <div className="mt-4 ">
                            {customAttachments?.value?.map((item, idx) => {
                                return (
                                    <div
                                        className="flex items-center justify-between p-3 rounded-md border border-slate-200 mb-4"
                                        key={idx}
                                    >
                                        <div className="flex items-center gap-2">
                                            {displayFileIcon(getFileTypeFromUrl(item?.path), false)}
                                            <div className="font-rocGroteskMedium">
                                                <p className="text-sm">{item?.filename}</p>
                                                <p className="text-xs text-slate-500"></p>
                                            </div>
                                        </div>
                                        <i
                                            className="ri-delete-bin-line text-xl text-r-50 cursor-pointer"
                                            onClick={() => deleteCustomAttachment(idx)}
                                        ></i>

                                        {}
                                    </div>
                                );
                            })}

                            <label
                                className={`flex items-center gap-4 py-3 px-6 rounded border border-dashed border-slate-200  ${
                                    !uploadingCustomAttachment && "cursor-pointer"
                                }`}
                                htmlFor="customAttachment"
                            >
                                <i className="ri-file-add-line text-2xl text-carribean-green"></i>
                                {uploadingCustomAttachment ? (
                                    <div className="">
                                        <Loader size={4} />
                                    </div>
                                ) : (
                                    <p className="text-sm underline text-carribean-green font-rocGroteskMedium">
                                        Click to upload document
                                    </p>
                                )}
                            </label>

                            <input
                                type="file"
                                accept=".pdf,.doc,.docx,.txt, image/*"
                                className={`top-0 w-full h-full outline-none opacity-0 ${
                                    !uploadingCustomAttachment && "cursor-pointer"
                                }`}
                                id="customAttachment"
                                disabled={uploadingCustomAttachment}
                                required={customAttachments?.value === 0}
                                onChange={(evt) => {
                                    if (!isFileSizeLessThanOrEqualTo(evt.target.files?.[0], 25)) {
                                        toast.custom((t) => (
                                            <CustomToast
                                                t={t}
                                                message={"File too large"}
                                                type={"error"}
                                            />
                                        ));
                                        return;
                                    }

                                    if (evt.target.files?.[0]) {
                                        handleCustomAttachmentUpload(
                                            evt.target.files?.[0].name as fileName,
                                            evt.target.files?.[0] as file
                                        );
                                    }
                                }}
                            />
                        </div>
                    ) : (
                        <div className={`mb-4 ${className}`}>
                            <div className="relative">
                                <div
                                    className="flex flex-col items-center cursor-pointer justify-center mb-4 py-8 text-center 
                                    text-sm rounded border border-dashed border-slate-200"
                                >
                                    <i className="ri-upload-cloud-2-line text-slate-500 text-4xl mb-4"></i>
                                    <p className="mb-1 font-rocGroteskMedium">
                                        <span className="underline">Click to upload document</span>
                                    </p>
                                    <p className="text-tradeally-neutral-200  font-rocGroteskMedium">
                                        File size limit: 25MB
                                    </p>
                                    <Button
                                        btnText={"Browse Files"}
                                        btnClassname={
                                            "!py-2 !px-4 !w-fit !border-n-40 !bg-transparent !border !rounded !mt-4"
                                        }
                                        isLoading={uploadingCustomAttachment}
                                    />
                                </div>
                                <input
                                    type="file"
                                    accept=".pdf,.doc,.docx,.txt, image/*"
                                    className={`${
                                        !uploadingCustomAttachment &&
                                        "absolute top-0 cursor-pointer"
                                    }  w-full h-full outline-none opacity-0 `}
                                    id="customAttachment"
                                    required
                                    onChange={(evt) => {
                                        if (
                                            !isFileSizeLessThanOrEqualTo(evt.target.files?.[0], 25)
                                        ) {
                                            toast.custom((t) => (
                                                <CustomToast
                                                    t={t}
                                                    message={"File too large"}
                                                    type={"error"}
                                                />
                                            ));
                                            return;
                                        }

                                        if (evt.target.files?.[0]) {
                                            handleCustomAttachmentUpload(
                                                evt.target.files?.[0].name as fileName,
                                                evt.target.files?.[0] as file
                                            );
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    )
                ) : (
                    ""
                )}
            </div>
        </div>
    );
};

export default CustomFields;
