import { useEffect, useState } from "react";
import { salesActions } from "redux/Ldb/actions";
import { useAppDispatch, useAppSelector } from "../storeHooks";
import { getCurrentStartAndEndDate } from "helpers/getCurrentStartAndEndDate";

const useGetSalesVelocityStats = (selectedProduct?: string) => {
    const dispatch = useAppDispatch();
    const {
        fetchingSalesVelocityStat,
        fetchSalesVelocityStatSuccess,
        fetchSalesVelocityStatFailure,
    } = useAppSelector((state) => state.sales);

    const [salesVelocityData, setSalesVelocityData] = useState<{ [key: string]: any }[]>([]);
    const [startEndDate, setStartEndDate] = useState<{ [key: string]: any }>(
        getCurrentStartAndEndDate("monthly")
    );
    const [groupBy, setGroupBy] = useState<"warehouse" | "channel">("warehouse");

    const handleDurationChange = (value: string) => {
        const startAndEndDate = getCurrentStartAndEndDate(value);
        setStartEndDate(startAndEndDate);
    };

    const handleGroupByChange = (value: "warehouse" | "channel") => {
        setGroupBy(value);
    };

    useEffect(() => {
        dispatch(
            salesActions.getSalesVelocityStat(
                startEndDate?.start,
                startEndDate?.end,
                groupBy,
                selectedProduct
            )
        );

        return () => {
            dispatch(salesActions.resetGetSalesVelocityStatSuccess());
        };
    }, [dispatch, startEndDate?.start, startEndDate?.end, groupBy, selectedProduct]);

    useEffect(() => {
        if (Boolean(fetchSalesVelocityStatSuccess)) {
            setSalesVelocityData(fetchSalesVelocityStatSuccess?.salesVelocity);
        }
    }, [dispatch, fetchSalesVelocityStatSuccess]);

    return {
        isFetching: fetchingSalesVelocityStat,
        error: fetchSalesVelocityStatFailure,
        data: salesVelocityData,
        setSalesVelocityData,
        handleDurationChange,
        handleGroupByChange,
        groupBy,
    };
};

export default useGetSalesVelocityStats;
