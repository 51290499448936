import React from "react";
import CustomToast from "../../../components/common/CustomToast";
import { authTypes } from "redux//Ldb/types";
import { toast } from "react-hot-toast";
import { authService } from "services/Ldb";
import { getFromStorage } from "helpers";
import { Dispatch } from "redux";

export const authActions = {
    register,
    verifyByMail,
    resendVerifyCode,
    loginUser,
    logOut,
    forgotPassword,
    resetPassword,
    changeVerificationEmail,
    updateToken,
    getLdbProfile,
    updateLdbProfile,
    resetProfileUpdate,
    addTeamMember,
    getTeamMembers,
    resetAddTeamMemberSuccess,
    addVendor,
    resetAddVendorSuccess,
    deleteTeamMember,
    updateTeamMember,
    resetDeleteTeamMemberSuccess,
    resetUpdateTeamMemberSuccess,
    changePassword,
    resetChangePasswordSuccess,
    updateUserProfile,
    resetUserUpdate,
    deleteLdbProfile,
    getRoles,
    createRoles,
    resetCreateRoles,
};

function register(reqObj: Record<string, any> | Array<Record<string, any>>) {
    return (dispatch: Dispatch) => {
        dispatch(request(authTypes.REGISTER_USER_REQUEST));
        authService.register(reqObj).then(
            (res) => {
                dispatch(success(authTypes.REGISTER_USER_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(authTypes.REGISTER_USER_FAILURE, error?.message));
                }
            }
        );
    };
}

function verifyByMail(code: string, token: string, remember?: boolean) {
    return (dispatch: Dispatch) => {
        dispatch(request(authTypes.VERIFY_USER_REQUEST));
        authService.verifyByMail({ code }).then(
            (res) => {
                const user = {
                    ...res.data,
                    verifyComplete: false,
                };
                authService.saveAccount(user, remember as boolean);
                dispatch(success(authTypes.VERIFY_USER_SUCCESS, user));
            },
            (error) => {
                if (error.message) {
                    // toast.custom((t) => <CustomToast t={t} message={error.message} type="error" />);
                    dispatch(failure(authTypes.VERIFY_USER_FAILURE, error.message));
                }
            }
        );
    };
}

function resendVerifyCode(showMessage: boolean = true, token: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(authTypes.RESEND_VERIFY_CODE_REQUEST));
        authService.resendVerifyCode(token).then(
            (res) => {
                showMessage &&
                    toast.custom((t) => (
                        <CustomToast
                            t={t}
                            message={"Verify code resent to your mail."}
                            type="success"
                        />
                    ));
                dispatch(success(authTypes.RESEND_VERIFY_CODE_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(authTypes.RESEND_VERIFY_CODE_FAILURE, error?.message));
                }
            }
        );
    };
}

function loginUser(loginObj: Record<string, any> | Array<Record<string, any>>, remember?: boolean) {
    return (dispatch: Dispatch) => {
        dispatch(request(authTypes.USER_LOGIN_REQUEST));
        authService.loginUser(loginObj).then(
            (res) => {
                const user = {
                    ...res?.data.data,
                    verifyComplete: true,
                };
                const storedUser = getFromStorage("ally-user");
                if (storedUser) {
                    storedUser.token = user.token;
                    authService.saveAccount(storedUser, remember as boolean);
                }
                authService.saveAccount(user, remember as boolean);
                dispatch(success(authTypes.USER_LOGIN_SUCCESS, user));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(authTypes.USER_LOGIN_FAILURE, error?.message));
                }
            }
        );
    };
}

function getLdbProfile(token?: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(authTypes.FETCH_USER_PROFILE_REQUEST));

        authService.getLdbProfile(token as string).then(
            (res) => {
                dispatch(success(authTypes.FETCH_USER_PROFILE_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    // toast.custom((t) => (
                    //     <CustomToast t={t} message={error?.message} type="error" />
                    // ));
                    dispatch(failure(authTypes.FETCH_USER_PROFILE_FAILURE, error?.message));
                }
            }
        );
    };
}

function updateLdbProfile(data: Record<string, any> | Array<Record<string, any>>, token: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(authTypes.UPDATE_LDB_PROFILE_REQUEST));
        authService.updateLdbProfile(data, token).then(
            (res) => {
                dispatch(success(authTypes.UPDATE_LDB_PROFILE_SUCCESS, res?.data?.user));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(authTypes.UPDATE_LDB_PROFILE_FAILURE, error?.message));
                }
            }
        );
    };
}

function updateUserProfile(data: Record<string, any> | Array<Record<string, any>>, token: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(authTypes.UPDATE_USER_PROFILE_REQUEST));
        authService.updateUserProfile(data, token).then(
            (res) => {
                dispatch(success(authTypes.UPDATE_USER_PROFILE_SUCCESS, res?.data?.user));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(authTypes.UPDATE_USER_PROFILE_FAILURE, error?.message));
                }
            }
        );
    };
}

function deleteLdbProfile(token: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(authTypes.DELETE_LDB_PROFILE_REQUEST));

        authService.deleteLdbProfile(token).then(
            (res) => {
                dispatch(success(authTypes.DELETE_LDB_PROFILE_SUCCESS, res?.data));
                toast.custom((t) => (
                    <CustomToast t={t} message={"Account deleted"} type="success" />
                ));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(authTypes.DELETE_LDB_PROFILE_FAILURE, error?.message));
                }
            }
        );
    };
}
function forgotPassword(reqObj: Record<string, any> | Array<Record<string, any>>) {
    return (dispatch: Dispatch) => {
        dispatch(request(authTypes.FORGOT_PASSWORD_REQUEST));
        authService.forgetPassword(reqObj).then(
            (res) => {
                toast.custom((t) => <CustomToast t={t} message={res?.message} type="success" />);
                dispatch(success(authTypes.FORGOT_PASSWORD_SUCCESS, res?.success));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(authTypes.FORGOT_PASSWORD_FAILURE, error?.message));
                }
            }
        );
    };
}

function resetPassword(reqObj: Record<string, any> | Array<Record<string, any>>, token: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(authTypes.RESET_PASSWORD_REQUEST));
        authService.resetPassword(reqObj, token).then(
            (res) => {
                toast.custom((t) => <CustomToast t={t} message={res.message} type="success" />);
                dispatch(success(authTypes.RESET_PASSWORD_SUCCESS, res.success));
                window.location.href = "/login";
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => <CustomToast t={t} message={error.message} type="error" />);
                    dispatch(failure(authTypes.RESET_PASSWORD_FAILURE, error.message));
                }
            }
        );
    };
}
function changePassword(reqObj: Record<string, any> | Array<Record<string, any>>, token: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(authTypes.CHANGE_PASSWORD_REQUEST));
        authService.changePassword(reqObj, token).then(
            (res) => {
                toast.custom((t) => <CustomToast t={t} message={res.message} type="success" />);
                dispatch(success(authTypes.CHANGE_PASSWORD_SUCCESS, res.success));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => <CustomToast t={t} message={error.message} type="error" />);
                    dispatch(failure(authTypes.CHANGE_PASSWORD_FAILURE, error.message));
                }
            }
        );
    };
}
function changeVerificationEmail(
    reqObj: Record<string, any> | Array<Record<string, any>>,
    token: string
) {
    return (dispatch: Dispatch) => {
        dispatch(request(authTypes.CHANGE_SIGNUP_EMAIL_REQUEST));
        authService.changeVerificationEmail(reqObj, token).then(
            (res) => {
                toast.custom((t) => (
                    <CustomToast t={t} message={"Email updated," + res?.message} type="success" />
                ));
                dispatch(success(authTypes.CHANGE_SIGNUP_EMAIL_SUCCESS, res?.success));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => <CustomToast t={t} message={error.message} type="error" />);
                    dispatch(failure(authTypes.CHANGE_SIGNUP_EMAIL_FAILURE, error.message));
                }
            }
        );
    };
}

function addTeamMember(reqObj: Record<string, any> | Array<Record<string, any>>, token?: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(authTypes.ADD_TEAM_MEMBER_REQUEST));
        authService.addTeamMember(reqObj, token as string).then(
            (res) => {
                toast.custom((t) => (
                    <CustomToast t={t} message={"Team member added"} type="success" />
                ));
                dispatch(success(authTypes.ADD_TEAM_MEMBER_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => <CustomToast t={t} message={error.message} type="error" />);
                    dispatch(failure(authTypes.ADD_TEAM_MEMBER_FAILURE, error.message));
                }
            }
        );
    };
}
function updateTeamMember(reqObj: Record<string, any> | Array<Record<string, any>>, token: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(authTypes.UPDATE_TEAM_MEMBER_REQUEST));

        authService.updateTeamMember(reqObj, token).then(
            (res) => {
                dispatch(success(authTypes.UPDATE_TEAM_MEMBER_SUCCESS, res?.data));
                toast.custom((t) => (
                    <CustomToast t={t} message={"Team member updated"} type="success" />
                ));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(authTypes.UPDATE_TEAM_MEMBER_FAILURE, error?.message));
                }
            }
        );
    };
}

function deleteTeamMember(teamId: string, token: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(authTypes.DELETE_TEAM_MEMBER_REQUEST));

        authService.deleteTeamMember(teamId, token).then(
            (res) => {
                dispatch(success(authTypes.DELETE_TEAM_MEMBER_SUCCESS, res?.data));
                toast.custom((t) => (
                    <CustomToast t={t} message={"Team member deleted"} type="success" />
                ));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(authTypes.DELETE_TEAM_MEMBER_FAILURE, error?.message));
                }
            }
        );
    };
}

function getTeamMembers(teamLimit?: number | string, search?: string, page?: number) {
    return (dispatch: Dispatch) => {
        dispatch(request(authTypes.FETCH_TEAM_MEMBER_REQUEST));

        authService.fetchTeamMembers(teamLimit as string, search as string, page).then(
            (res) => {
                dispatch(success(authTypes.FETCH_TEAM_MEMBER_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => <CustomToast t={t} message={error.message} type="error" />);
                    dispatch(failure(authTypes.FETCH_TEAM_MEMBER_FAILURE, error.message));
                }
            }
        );
    };
}

function getRoles(limit?: string | number, token?: string, search?: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(authTypes.FETCH_ROLES_REQUEST));

        authService.getRoles(limit, token, search).then(
            (res) => {
                dispatch(success(authTypes.FETCH_ROLES_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => <CustomToast t={t} message={error.message} type="error" />);
                    dispatch(failure(authTypes.FETCH_ROLES_FAILURE, error.message));
                }
            }
        );
    };
}

function createRoles(body: Record<string, any> | Array<Record<string, any>>, token?: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(authTypes.CREATE_ROLES_REQUEST));

        authService.createRoles(body, token).then(
            (res) => {
                dispatch(success(authTypes.CREATE_ROLES_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => <CustomToast t={t} message={error.message} type="error" />);
                    dispatch(failure(authTypes.CREATE_ROLES_FAILURE, error.message));
                }
            }
        );
    };
}

function addVendor(reqObj: Record<string, any> | Array<Record<string, any>>) {
    return (dispatch: Dispatch) => {
        dispatch(request(authTypes.ADD_VENDOR_REQUEST));
        authService.addVendor(reqObj).then(
            (res) => {
                toast.custom((t) => (
                    <CustomToast t={t} message={"Invitation sent"} type="success" />
                ));
                dispatch(success(authTypes.ADD_VENDOR_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => <CustomToast t={t} message={error.message} type="error" />);
                    dispatch(failure(authTypes.ADD_VENDOR_FAILURE, error.message));
                }
            }
        );
    };
}

function resetProfileUpdate() {
    return (dispatch: Dispatch) => {
        dispatch(success(authTypes.UPDATE_LDB_PROFILE_SUCCESS, null));
    };
}
function resetCreateRoles() {
    return (dispatch: Dispatch) => {
        dispatch(success(authTypes.CREATE_ROLES_SUCCESS, null));
    };
}
function resetUserUpdate() {
    return (dispatch: Dispatch) => {
        dispatch(success(authTypes.UPDATE_USER_PROFILE_SUCCESS, null));
    };
}
function resetAddTeamMemberSuccess() {
    return (dispatch: Dispatch) => {
        dispatch(success(authTypes.ADD_TEAM_MEMBER_SUCCESS, null));
    };
}

function resetChangePasswordSuccess() {
    return (dispatch: Dispatch) => {
        dispatch(success(authTypes.CHANGE_PASSWORD_SUCCESS, null));
    };
}
function resetDeleteTeamMemberSuccess() {
    return (dispatch: Dispatch) => {
        dispatch(success(authTypes.DELETE_TEAM_MEMBER_SUCCESS, null));
    };
}

function resetUpdateTeamMemberSuccess() {
    return (dispatch: Dispatch) => {
        dispatch(success(authTypes.UPDATE_TEAM_MEMBER_SUCCESS, null));
    };
}

function resetAddVendorSuccess() {
    return (dispatch: Dispatch) => {
        dispatch(success(authTypes.ADD_VENDOR_SUCCESS, null));
    };
}

function updateToken(gFF: Record<string, any>) {
    authService.saveAccount(gFF);
    return request(authTypes.LOG_OUT);
}

function logOut() {
    authService.logout();
    return request(authTypes.LOG_OUT);
}

function request(type: string) {
    return { type: type };
}
function success(type: string, data?: any) {
    return { type: type, payload: data };
}
function failure(type: string, error?: any) {
    return { type: type, payload: error ?? "" };
}
