import config from "config/config";
import { authService } from "./authService";

export const salesService = {
    fetchSalesOrderList,
    createSalesOrder,
    fetchSingleSalesOrder,
    fetchSalesDashboardStats,
    fetchSalesTopAndLeast,
    fetchSalesVelocityStats,
    fetchSalesChannelStats,
    fetchCustomerList,
    createSalesShipment
};

async function fetchSalesOrderList(
    page?: string | number,
    status?: string,
    search?: string,
    warehouseId?: string,
    startDate?: string,
    endDate?: string
) {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/sales/list/sales-order?page=${page}${
            status ? `&status=${status}` : ""
        }${search ? `&search=${search}` : ""}${warehouseId ? `&warehouseId=${warehouseId}` : ""}${
            startDate && endDate ? `&startDate=${startDate}&endDate=${endDate}` : ""
        }`,
        requestOptions
    );

    return res;
}

async function createSalesOrder(data: Record<string, any> | Array<Record<string, any>>) {
    const requestOptions = {
        method: "POST",
        body: JSON.stringify(data),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/sales/sales-order`,
        requestOptions
    );

    return res;
}

async function fetchSingleSalesOrder(orderId: string) {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/sales/sales-order/${orderId}`,
        requestOptions
    );

    return res;
}

async function fetchSalesDashboardStats(startDate?: string, endDate?: string) {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/sales/dashboard/stats${
            startDate && endDate ? `?startDate=${startDate}&endDate=${endDate}` : ""
        }`,
        requestOptions
    );

    return res;
}

async function fetchSalesTopAndLeast(startDate?: string, endDate?: string) {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/sales/dashboard/top-and-least-selling${
            startDate && endDate ? `?startDate=${startDate}&endDate=${endDate}` : ""
        }`,
        requestOptions
    );

    return res;
}

async function fetchSalesVelocityStats(
    startDate?: string,
    endDate?: string,
    groupBy?: "warehouse" | "channel",
    inventoryId?: string
) {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/sales/dashboard/sales-velocity?${
            groupBy ? `&groupBy=${groupBy}` : ""
        }${inventoryId ? `&inventoryId=${inventoryId}` : ""}${
            startDate && endDate ? `&startDate=${startDate}&endDate=${endDate}` : ""
        }`,
        requestOptions
    );

    return res;
}

async function fetchSalesChannelStats(startDate?: string, endDate?: string) {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/sales/dashboard/channel-stats?${
            startDate && endDate ? `&startDate=${startDate}&endDate=${endDate}` : ""
        }`,
        requestOptions
    );

    return res;
}

async function fetchCustomerList(page?: string | number, limit?: string | number, search?: string) {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/sales/list-customers?page=${page}${
            limit ? `&limit=${limit}` : ""
        }${search ? `&search=${search}` : ""}`,
        requestOptions
    );

    return res;
}

async function createSalesShipment(data: Record<string, any> | Array<Record<string, any>>) {
    const requestOptions = {
        method: "POST",
        body: JSON.stringify(data),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/sales/sps-order-shipment`,
        requestOptions
    );

    return res;
}
