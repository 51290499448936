import {taskTypes} from "redux/Ldb/types";

const initialState = {
    createTask: false,
    createTaskSuccess: null,
    createTaskFailure: null,
    updateTask: false,
    updateTaskSuccess: null,
    updateTaskFailure: null,
    fetchingTaskList: false,
    fetchTaskListSuccess: null,
    fetchTaskListFailure: null,
    fetchingSingleTask: false,
    fetchingSingleTaskSuccess: null,
    fetchingSingleTaskFailure: null,
    fetchingAllTaskList: false,
    fetchAllTaskSuccess: null,
    fetchAllTaskFailure: null,
    fetchingPendingTaskList: false,
    fetchPendingTaskSuccess: null,
    fetchPendingTaskFailure: null,
    fetchingCompletedTaskList: false,
    fetchCompletedTaskSuccess: null,
    fetchCompletedTaskFailure: null,
    fetchingOverdueTaskList: false,
    fetchOverdueTaskSuccess: null,
    fetchOverdueTaskFailure: null,
    fetchingActiveTaskList: false,
    fetchActiveTaskSuccess: null,
    fetchActiveTaskFailure: null,
    fetchingMyTaskList: false,
    fetchMyTaskSuccess: null,
    fetchMyTaskFailure: null,



    fetchAllTaskTwoSuccess: null,
    fetchAllTaskTwoFailure: null,
    fetchingPendingTaskTwoList: false,
    fetchPendingTaskTwoSuccess: null,
    fetchPendingTaskTwoFailure: null,
    fetchingCompletedTaskTwoList: false,
    fetchCompletedTaskTwoSuccess: null,
    fetchCompletedTaskTwoFailure: null,
    fetchingOverdueTaskTwoList: false,
    fetchOverdueTaskTwoSuccess: null,
    fetchOverdueTaskTwoFailure: null,
    fetchingActiveTaskTwoList: false,
    fetchActiveTaskTwoSuccess: null,
    fetchActiveTaskTwoFailure: null,
    fetchingMyTaskTwoList: false,
    fetchMyTaskTwoSuccess: null,
    fetchMyTaskTwoFailure: null,
};

export const task = (state = initialState, action: {type: string, payload: any}) => {
    switch (action.type) {
        case taskTypes.CREATE_TASK_REQUEST:
            return {
                ...state,
                createTask: true,
                createTaskSuccess: null,
                createTaskFailure: null,
            };
        case taskTypes.CREATE_TASK_SUCCESS:
            return {
                ...state,
                createTask: false,
                createTaskSuccess: action.payload,
                createTaskFailure: null,
            };
        case taskTypes.CREATE_TASK_FAILURE:
            return {
                ...state,
                createTask: false,
                createTaskSuccess: null,
                createTaskFailure: action.payload,
            };

        case taskTypes.UPDATE_TASK_REQUEST:
            return {
                ...state,
                updateTask: true,
                updateTaskSuccess: null,
                updateTaskFailure: null,
            };
        case taskTypes.UPDATE_TASK_SUCCESS:
            return {
                ...state,
                updateTask: false,
                updateTaskSuccess: action.payload,
                updateTaskFailure: null,
            };
        case taskTypes.UPDATE_TASK_FAILURE:
            return {
                ...state,
                updateTask: false,
                updateTaskSuccess: null,
                updateTaskFailure: action.payload,
            };
        case taskTypes.FETCH_TASK_LIST_REQUEST:
            return {
                ...state,
                fetchingTaskList: true,
                fetchTaskListSuccess: null,
                fetchTaskListFailure: null,
            };
        case taskTypes.FETCH_TASK_LIST_SUCCESS:
            return {
                ...state,
                fetchingTaskList: false,
                fetchTaskListSuccess: action.payload,
                fetchTaskListFailure: null,
            };
        case taskTypes.FETCH_TASK_LIST_FAILURE:
            return {
                ...state,
                fetchingTaskList: false,
                fetchTaskListSuccess: null,
                fetchTaskListFailure: action.payload,
            };
        case taskTypes.FETCH_SINGLE_TASK_REQUEST:
            return {
                ...state,
                fetchingSingleTask: true,
                fetchingSingleTaskSuccess: null,
                fetchingSingleTaskFailure: null,
            };
        case taskTypes.FETCH_SINGLE_TASK_SUCCESS:
            return {
                ...state,
                fetchingSingleTask: false,
                fetchingSingleTaskSuccess: action.payload,
                fetchingSingleTaskFailure: null,
            };
        case taskTypes.FETCH_SINGLE_TASK_FAILURE:
            return {
                ...state,
                fetchingSingleTask: false,
                fetchingSingleTaskSuccess: null,
                fetchingSingleTaskFailure: action.payload,
            };
        case taskTypes.FETCH__PENDING_TASK_LIST_REQUEST:
            return {
                ...state,
                fetchingPendingTaskList: true,
                fetchPendingTaskSuccess: null,
                fetchPendingTaskFailure: null,
            };
        case taskTypes.FETCH__PENDING_TASK_LIST_SUCCESS:
            return {
                ...state,
                fetchingPendingTaskList: false,
                fetchPendingTaskSuccess: action.payload,
                fetchPendingTaskFailure: null,
            };
        case taskTypes.FETCH__PENDING_TASK_LIST_FAILURE:
            return {
                ...state,
                fetchingPendingTaskList: false,
                fetchPendingTaskSuccess: null,
                fetchPendingTaskFailure: action.payload,
            };
        case taskTypes.FETCH__COMPLETED_TASK_LIST_REQUEST:
            return {
                ...state,
                fetchingCompletedTaskList: true,
                fetchCompletedTaskSuccess: null,
                fetchCompletedTaskFailure: null,
            };
        case taskTypes.FETCH__COMPLETED_TASK_LIST_SUCCESS:
            return {
                ...state,
                fetchingCompletedTaskList: false,
                fetchCompletedTaskSuccess: action.payload,
                fetchCompletedTaskFailure: null,
            };
        case taskTypes.FETCH__COMPLETED_TASK_LIST_FAILURE:
            return {
                ...state,
                fetchingCompletedTaskList: false,
                fetchCompletedTaskSuccess: null,
                fetchCompletedTaskFailure: action.payload,
            };
        case taskTypes.FETCH__OVERDUE_TASK_LIST_REQUEST:
            return {
                ...state,
                fetchingOverdueTaskList: true,
                fetchOverdueTaskSuccess: null,
                fetchOverdueTaskFailure: null,
            };
        case taskTypes.FETCH__OVERDUE_TASK_LIST_SUCCESS:
            return {
                ...state,
                fetchingOverdueTaskList: false,
                fetchOverdueTaskSuccess: action.payload,
                fetchOverdueTaskFailure: null,
            };
        case taskTypes.FETCH__OVERDUE_TASK_LIST_FAILURE:
            return {
                ...state,
                fetchingOverdueTaskList: false,
                fetchOverdueTaskSuccess: null,
                fetchOverdueTaskFailure: action.payload,
            };
        case taskTypes.FETCH__ACTIVE_TASK_LIST_REQUEST:
            return {
                ...state,
                fetchingActiveTaskList: true,
                fetchActiveTaskSuccess: null,
                fetchActiveTaskFailure: null,
            };
        case taskTypes.FETCH__ACTIVE_TASK_LIST_SUCCESS:
            return {
                ...state,
                fetchingActiveTaskList: false,
                fetchActiveTaskSuccess: action.payload,
                fetchActiveTaskFailure: null,
            };
        case taskTypes.FETCH__ACTIVE_TASK_LIST_FAILURE:
            return {
                ...state,
                fetchingActiveTaskList: false,
                fetchActiveTaskSuccess: null,
                fetchActiveTaskFailure: action.payload,
            };
        case taskTypes.FETCH__ALL_TASK_LIST_REQUEST:
            return {
                ...state,
                fetchingAllTaskList: true,
                fetchAllTaskSuccess: null,
                fetchAllTaskFailure: null,
            };
        case taskTypes.FETCH__ALL_TASK_LIST_SUCCESS:
            return {
                ...state,
                fetchingAllTaskList: false,
                fetchAllTaskSuccess: action.payload,
                fetchAllTaskFailure: null,
            };
        case taskTypes.FETCH__ALL_TASK_LIST_FAILURE:
            return {
                ...state,
                fetchingAllTaskList: false,
                fetchAllTaskSuccess: null,
                fetchAllTaskFailure: action.payload,
            };

        case taskTypes.FETCH__MY_TASK_LIST_REQUEST:
            return {
                ...state,
                fetchingMyTaskList: true,
                fetchMyTaskSuccess: null,
                fetchMyTaskFailure: null,
            };
        case taskTypes.FETCH__MY_TASK_LIST_SUCCESS:
            return {
                ...state,
                fetchingMyTaskList: false,
                fetchMyTaskSuccess: action.payload,
                fetchMyTaskFailure: null,
            };
        case taskTypes.FETCH__MY_TASK_LIST_FAILURE:
            return {
                ...state,
                fetchingMyTaskList: false,
                fetchMyTaskSuccess: null,
                fetchMyTaskFailure: action.payload,
            };

            case taskTypes.FETCH__PENDING_TASK_TWO_LIST_REQUEST:
                return {
                    ...state,
                    fetchingPendingTaskTwoList: true,
                    fetchPendingTaskTwoSuccess: null,
                    fetchPendingTaskTwoFailure: null,
                };
            case taskTypes.FETCH__PENDING_TASK_TWO_LIST_SUCCESS:
                return {
                    ...state,
                    fetchingPendingTaskTwoList: false,
                    fetchPendingTaskTwoSuccess: action.payload,
                    fetchPendingTaskTwoFailure: null,
                };
            case taskTypes.FETCH__PENDING_TASK_TWO_LIST_FAILURE:
                return {
                    ...state,
                    fetchingPendingTaskTwoList: false,
                    fetchPendingTaskTwoSuccess: null,
                    fetchPendingTaskTwoFailure: action.payload,
                };
            case taskTypes.FETCH__COMPLETED_TASK_TWO_LIST_REQUEST:
                return {
                    ...state,
                    fetchingCompletedTaskTwoList: true,
                    fetchCompletedTaskTwoSuccess: null,
                    fetchCompletedTaskTwoFailure: null,
                };
            case taskTypes.FETCH__COMPLETED_TASK_TWO_LIST_SUCCESS:
                return {
                    ...state,
                    fetchingCompletedTaskTwoList: false,
                    fetchCompletedTaskTwoSuccess: action.payload,
                    fetchCompletedTaskTwoFailure: null,
                };
            case taskTypes.FETCH__COMPLETED_TASK_TWO_LIST_FAILURE:
                return {
                    ...state,
                    fetchingCompletedTaskTwoList: false,
                    fetchCompletedTaskTwoSuccess: null,
                    fetchCompletedTaskTwoFailure: action.payload,
                };
            case taskTypes.FETCH__OVERDUE_TASK_TWO_LIST_REQUEST:
                return {
                    ...state,
                    fetchingOverdueTaskTwoList: true,
                    fetchOverdueTaskTwoSuccess: null,
                    fetchOverdueTaskTwoFailure: null,
                };
            case taskTypes.FETCH__OVERDUE_TASK_TWO_LIST_SUCCESS:
                return {
                    ...state,
                    fetchingOverdueTaskTwoList: false,
                    fetchOverdueTaskTwoSuccess: action.payload,
                    fetchOverdueTaskTwoFailure: null,
                };
            case taskTypes.FETCH__OVERDUE_TASK_TWO_LIST_FAILURE:
                return {
                    ...state,
                    fetchingOverdueTaskTwoList: false,
                    fetchOverdueTaskTwoSuccess: null,
                    fetchOverdueTaskTwoFailure: action.payload,
                };
            case taskTypes.FETCH__ACTIVE_TASK_TWO_LIST_REQUEST:
                return {
                    ...state,
                    fetchingActiveTaskTwoList: true,
                    fetchActiveTaskTwoSuccess: null,
                    fetchActiveTaskTwoFailure: null,
                };
            case taskTypes.FETCH__ACTIVE_TASK_TWO_LIST_SUCCESS:
                return {
                    ...state,
                    fetchingActiveTaskTwoList: false,
                    fetchActiveTaskTwoSuccess: action.payload,
                    fetchActiveTaskTwoFailure: null,
                };
            case taskTypes.FETCH__ACTIVE_TASK_TWO_LIST_FAILURE:
                return {
                    ...state,
                    fetchingActiveTaskTwoList: false,
                    fetchActiveTaskTwoSuccess: null,
                    fetchActiveTaskTwoFailure: action.payload,
                };
            case taskTypes.FETCH__ALL_TASK_TWO_LIST_REQUEST:
                return {
                    ...state,
                    fetchingAllTaskTwoList: true,
                    fetchAllTaskTwoSuccess: null,
                    fetchAllTaskTwoFailure: null,
                };
            case taskTypes.FETCH__ALL_TASK_TWO_LIST_SUCCESS:
                return {
                    ...state,
                    fetchingAllTaskTwoList: false,
                    fetchAllTaskTwoSuccess: action.payload,
                    fetchAllTaskTwoFailure: null,
                };
            case taskTypes.FETCH__ALL_TASK_TWO_LIST_FAILURE:
                return {
                    ...state,
                    fetchingAllTaskTwoList: false,
                    fetchAllTaskTwoSuccess: null,
                    fetchAllTaskTwoFailure: action.payload,
                };
    
            case taskTypes.FETCH__MY_TASK_TWO_LIST_REQUEST:
                return {
                    ...state,
                    fetchingMyTaskTwoList: true,
                    fetchMyTaskTwoSuccess: null,
                    fetchMyTaskTwoFailure: null,
                };
            case taskTypes.FETCH__MY_TASK_TWO_LIST_SUCCESS:
                return {
                    ...state,
                    fetchingMyTaskTwoList: false,
                    fetchMyTaskTwoSuccess: action.payload,
                    fetchMyTaskTwoFailure: null,
                };
            case taskTypes.FETCH__MY_TASK_TWO_LIST_FAILURE:
                return {
                    ...state,
                    fetchingMyTaskTwoList: false,
                    fetchMyTaskTwoSuccess: null,
                    fetchMyTaskTwoFailure: action.payload,
                };


        default:
            return state;
    }
};
