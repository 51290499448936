import { customFieldTypes } from "../types";

const initialState = {
    creatingCustomField: false,
    createCustomFieldSuccess: null,
    createCustomFieldFailure: null,
    updatingCustomField: false,
    updateCustomFieldSuccess: null,
    updateCustomFieldFailure: null,
    deletingCustomField: false,
    deleteCustomFieldSuccess: null,
    deleteCustomFieldFailure: null,
    fetchingCustomField: false,
    fetchCustomFieldSuccess: null,
    fetchCustomFieldFailure: null,
};

export const customField = (state = initialState, action: { type: string; payload: any }) => {
    switch (action.type) {
        case customFieldTypes.CREATE_CUSTOM_FIELD_REQUEST:
            return {
                ...state,
                creatingCustomField: true,
                createCustomFieldSuccess: null,
                createCustomFieldFailure: null,
            };
        case customFieldTypes.CREATE_CUSTOM_FIELD_SUCCESS:
            return {
                ...state,
                creatingCustomField: false,
                createCustomFieldSuccess: action.payload,
                createCustomFieldFailure: null,
            };
        case customFieldTypes.CREATE_CUSTOM_FIELD_FAILURE:
            return {
                ...state,
                creatingCustomField: false,
                createConversationSuccess: null,
                createConversationFailure: action.payload,
            };

        case customFieldTypes.UPDATE_CUSTOM_FIELD_REQUEST:
            return {
                ...state,
                updatingCustomField: true,
                updateCustomFieldSuccess: null,
                updateCustomFieldFailure: null,
            };
        case customFieldTypes.UPDATE_CUSTOM_FIELD_SUCCESS:
            return {
                ...state,
                updatingCustomField: false,
                updateCustomFieldSuccess: action.payload,
                updateCustomFieldFailure: null,
            };
        case customFieldTypes.UPDATE_CUSTOM_FIELD_FAILURE:
            return {
                ...state,
                updatingCustomField: false,
                updateCustomFieldSuccess: null,
                updateCustomFieldFailure: action.payload,
            };

        case customFieldTypes.DELETE_CUSTOM_FIELD_REQUEST:
            return {
                ...state,
                deletingCustomField: true,
                deleteCustomFieldSuccess: null,
                deleteCustomFieldFailure: null,
            };
        case customFieldTypes.DELETE_CUSTOM_FIELD_SUCCESS:
            return {
                ...state,
                deletingCustomField: false,
                deleteCustomFieldSuccess: action.payload,
                deleteCustomFieldFailure: null,
            };
        case customFieldTypes.DELETE_CUSTOM_FIELD_FAILURE:
            return {
                ...state,
                deletingCustomField: false,
                deleteCustomFieldSuccess: null,
                deleteCustomFieldFailure: action.payload,
            };

        case customFieldTypes.FETCH_CUSTOM_FIELD_REQUEST:
            return {
                ...state,
                fetchingCustomField: true,
                fetchCustomFieldSuccess: null,
                fetchCustomFieldFailure: null,
            };
        case customFieldTypes.FETCH_CUSTOM_FIELD_SUCCESS:
            return {
                ...state,
                fetchingCustomField: false,
                fetchCustomFieldSuccess: action.payload,
                fetchCustomFieldFailure: null,
            };
        case customFieldTypes.FETCH_CUSTOM_FIELD_FAILURE:
            return {
                ...state,
                fetchingCustomField: false,
                fetchCustomFieldSuccess: null,
                fetchCustomFieldFailure: action.payload,
            };
        default:
            return state;
    }
};
