import React, { useEffect, useState } from "react";
import StocksEntry from "./StockEntry/StockEntryList";
import StockAdjustment from "./StockAdjustment/StockAdjusmentList";
import Dropdown from "components/common/Dropdown";
import { useNavigate, useSearchParams } from "react-router-dom";
import Button from "components/common/Button";
import StockTransferList from "./StockTransfer/StockTransferList";
import TextInput from "components/common/InputField/TextInput";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import { debounce, truncate } from "lodash";
import { inventoryActions } from "redux/Ldb/actions";
import { productCategories } from "variables/productCategories";

const StockControl = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [params] = useSearchParams();
    const currTab = params.get("currTab");
    const stockTab = params.get("stockTab");
    const [activeTab, setActiveTab] = useState(0);
    const stockControlTabs = [
        { id: 0, name: "Stock Entry" },
        { id: 1, name: "Stock Adjustment" },
        { id: 2, name: "Stock Transfer" },
    ];

    const [search, setSearch] = useState<string>("");
    const [debouncedSearch, setDebouncedSearch] = useState<string>("");

    const [stockCategory, setStockCategory] = useState<string>();
    const [warehouseData, setWarehouseData] = useState<{ [key: string]: any }[]>([]);
    const [debouncedWarehouseSearch, setDebouncedWarehouseSearch] = useState<string>("");
    const [location, setLocation] = useState("");
    const [warehouse, setWarehouse] = useState<string>("");
    const handleDebouncedChange = debounce((e) => {
        setDebouncedSearch(e.target.value);
    }, 500);

    const { fetchedWarehousesSuccess } = useAppSelector((state) => state.inventory);

    useEffect(() => {
        if (stockTab) {
            setActiveTab(+stockTab);
        } else {
            setActiveTab(0);
        }
    }, [stockTab]);

    useEffect(() => {
        if (Boolean(fetchedWarehousesSuccess)) {
            setWarehouseData(fetchedWarehousesSuccess?.warehouses?.data);
        }
    }, [fetchedWarehousesSuccess]);

    useEffect(() => {
        dispatch(inventoryActions.fetchWarehouses(50, debouncedWarehouseSearch));
    }, [dispatch, debouncedWarehouseSearch]);

    const handleWarehouseDebouncedChange = debounce((e) => {
        setDebouncedWarehouseSearch(e.target.value);
    }, 500);

    useEffect(() => {
        dispatch(inventoryActions?.fetchSuppliedStockListAllData());
    }, [dispatch]);

    return (
        <div>
            <div className="flex justify-between max-lg:flex-col max-lg:space-y-2 mx-lg:justify-start">
                <div className="bg-slate-50 !w-fit p-1 flex gap-2 font-rocGroteskMedium font-sm mb-8 max-lg:mb-0">
                    {stockControlTabs?.map((tab) => {
                        return (
                            <button
                                className={`w-auto  py-[7px] px-4  text-sm ${
                                    activeTab === tab?.id &&
                                    "!bg-white rounded-[6px] shadow-buttonShadow text-slate-900"
                                } text-slate-500 `}
                                onClick={() => {
                                    setActiveTab(tab?.id);
                                    dispatch(inventoryActions.resetfetchStockList());
                                    dispatch(inventoryActions.resetFetchStockAdjustmentList());
                                    dispatch(inventoryActions.resetFetchStockTransferList());

                                    navigate(
                                        `/dashboard/inventory?currTab=${currTab}&stockTab=${tab?.id}`,
                                        {
                                            replace: true,
                                        }
                                    );
                                }}
                            >
                                {tab?.name}
                            </button>
                        );
                    })}
                </div>

                <div className="flex max-sm:flex-col">
                    <div className="relative max-lg:w-[84%] max-sm:w-full ">
                        <div className="absolute right-0 max-lg:relative bg-[#fcfcfc] pl-2 max-lg:pl-0">
                            <div className="flex gap-2 pb-[2px] max-sm:flex-col">
                                <TextInput
                                    name="search"
                                    type="text"
                                    value={search}
                                    onChange={(e) => setSearch(e.target.value)}
                                    onInput={handleDebouncedChange}
                                    inputPlaceholder="Search by product ID, name"
                                    inputClassName={
                                        "!h-[40px] pl-[0px] !mb-0 !font-rocGroteskMedium !text-slate-400"
                                    }
                                    leftIcon={
                                        <i className="ri-search-line before:content-['\f0d1] text-slate-400 mt-[2px]"></i>
                                    }
                                    inputContainerClassname={
                                        "!rounded-[4px] !w-[300px] !h-[40px] !pl-[40px] !bg-white shadow-boxShadow-2 !border !border-n-20"
                                    }
                                />

                                <Dropdown
                                    name="stockCategory"
                                    value={""}
                                    dropdownTriggerClassName={" "}
                                    handleChange={(name, value) => setStockCategory(value)}
                                    dropdown={
                                        <div className="bg-white flex justify-between items-center h-10 space-x-2 py-2.5 px-3 shadow-boxShadow-2 border border-tradeally-neutral-20 !rounded">
                                            <span className="text-[13px] whitespace-nowrap font-rocGroteskMedium max-sm:hidden">
                                                {truncate(stockCategory, { length: 12 }) ||
                                                    "Category"}
                                            </span>
                                            <span className="text-[13px] whitespace-nowrap font-rocGroteskMedium hidden max-sm:block">
                                                {truncate(stockCategory, { length: 25 }) ||
                                                    "Category"}
                                            </span>
                                            <div className="flex items-center space-x-2">
                                                {Boolean(stockCategory) && (
                                                    <i
                                                        onClick={(e) => {
                                                            e?.stopPropagation();
                                                            setStockCategory("");
                                                        }}
                                                        className="ri-close-circle-fill text-sm text-slate-500"
                                                    ></i>
                                                )}
                                                <i className="ri-arrow-down-s-line text-lg text-gm-50"></i>
                                            </div>
                                        </div>
                                    }
                                    dropdownClassName={"!w-full !mb-10 !-mt-7"}
                                    dropdownContainerClasses={`shadow-cardShadow-4 !right-0 !mt-0  !w-[159px] max-sm:!w-full max-sm:z-[9999] rounded !max-h-fit
                                                 !p-0 !top-[105%]`}
                                    dropdownItemsClasses="!bg-transparent border-b-[1px] border-b-slate-100"
                                    dropdownOptions={productCategories?.map((category) => {
                                        return {
                                            label: category?.label,
                                            value: category?.value,
                                        };
                                    })}
                                />

                                <Dropdown
                                    name="location"
                                    value={""}
                                    dropdownTriggerClassName={" "}
                                    handleChange={(name, value) => {
                                        setLocation(value?.split("-")?.[1]);
                                        setWarehouse(value?.split("-")?.[0]);
                                    }}
                                    dropdown={
                                        <div className="bg-white flex justify-between items-center h-10 space-x-2 py-2.5 px-3 shadow-boxShadow-2 border border-tradeally-neutral-20 !rounded">
                                            <div className="flex items-center space-x-2">
                                                <i className="ri-map-pin-line text-lg text-gm-50"></i>

                                                <span className="text-[13px] whitespace-nowrap font-rocGroteskMedium max-sm:hidden">
                                                    {truncate(warehouse, { length: 12 }) ||
                                                        "Outlets"}
                                                </span>
                                                <span className="text-[13px] whitespace-nowrap font-rocGroteskMedium hidden max-sm:block">
                                                    {truncate(warehouse, { length: 25 }) ||
                                                        "Outlets"}
                                                </span>
                                            </div>

                                            {Boolean(warehouse) && (
                                                <i
                                                    onClick={(e) => {
                                                        e?.stopPropagation();
                                                        setLocation("");
                                                        setWarehouse("");
                                                    }}
                                                    className="ri-close-circle-fill text-sm text-slate-500"
                                                ></i>
                                            )}
                                        </div>
                                    }
                                    // wholeContainerClass="max-sm:w-full"
                                    dropdownClassName={"!w-full !mb-10 !-mt-7"}
                                    dropdownContainerClasses={`shadow-cardShadow-4 left-[-60px] max-sm:left-0 max-sm:z-[9999] !mt-0 !w-[159px] max-sm:!w-full rounded !p-0 !top-[105%] !max-h-[290px] overflow-y-auto`}
                                    dropdownItemsClasses="!bg-transparent border-b-[1px] border-b-slate-100"
                                    dropdownOptions={warehouseData?.map((warehouse) => ({
                                        label: warehouse?.warehouseName,
                                        value: warehouse?.warehouseName + "-" + warehouse?._id,
                                    }))}
                                />
                                {/* <Dropdown
                                    name="location"
                                    value={""}
                                    dropdownTriggerClassName={" "}
                                    handleChange={(name, value) => {
                                        setLocation(value?.split("-")?.[1]);
                                        setWarehouse(value?.split("-")?.[0]);
                                    }}
                                    dropdown={
                                        <div className="relative">
                                            <i className="ri-map-pin-line text-lg text-gm-50 absolute left-[12px] top-[6px]"></i>
                                            <TextInput
                                                value={truncate(warehouse, { length: 13 })}
                                                type={"text"}
                                                onChange={(e) => setWarehouse(e.target.value)}
                                                onInput={(e) => handleWarehouseDebouncedChange(e)}
                                                inputPlaceholder={"Outlets"}
                                                required={false}
                                                inputClassName="!w-[80px] !h-10 !border-0 placeholder:!font-rocGroteskMedium placeholder:!text-gm-50 placeholder:!text-[13px] !font-rocGroteskMedium !text-[13px]"
                                                inputContainerClassname={"!border-0 !h-10"}
                                                containerClassname="bg-white flex items-center h-10 gap-2 py-2.5 shadow-boxShadow-2 border border-tradeally-neutral-20 rounded pl-6"
                                            />
                                            {Boolean(location) && (
                                                <i
                                                    onClick={(e) => {
                                                        e?.stopPropagation();
                                                        setLocation("");
                                                        setWarehouse("");
                                                    }}
                                                    className="ri-close-circle-fill text-sm text-slate-500 absolute right-[30px] top-[10px]"
                                                ></i>
                                            )}
                                        </div>
                                    }
                                    dropdownClassName={"!w-full !mb-10 !-mt-7"}
                                    dropdownContainerClasses={`shadow-cardShadow-4 left-[-60px] !mt-0 !w-[159px] rounded !p-0 !top-[105%] !max-h-[290px] overflow-y-auto`}
                                    dropdownItemsClasses="!bg-transparent border-b-[1px] border-b-slate-100"
                                    dropdownOptions={warehouseData?.map((warehouse) => ({
                                        label: warehouse?.warehouseName,
                                        value: warehouse?.warehouseName + "-" + warehouse?._id,
                                    }))}
                                /> */}
                                {/* <Button
                                    btnText="Settings"
                                    btnClassname="!py-2  !text-black !text-[13px] !w-auto
                                     !bg-transparent !shadow-boxShadow-2 border border-tradeally-neutral-20"
                                    onClick={
                                        () => console.log("")
                                        navigate(
                                            "/dashboard/inventory/stock-control/stock-adjustment"
                                        )
                                    }
                                    icon=<i className="ri-settings-5-line"></i>
                                /> */}
                            </div>
                        </div>
                    </div>

                    <div className="pl-2 max-sm:pl-0 max-sm:my-2">
                        {activeTab === 0 ? (
                            <Dropdown
                                dropdown={
                                    <div
                                        className="!bg-y-10
                                             !py-[10px] !px-4  !text-gun-metal !text-[13px] !w-auto rounded"
                                    >
                                        <p
                                            className={`max-sm:text-center font-rocGroteskMedium text-[13px]`}
                                        >
                                            Add Stock
                                        </p>
                                    </div>
                                }
                                dropdownItemsClasses={`!px-0 !py-0`}
                                dropdownClassName={"!w-full !mb-10 !-mt-7"}
                                dropdownContainerClasses={`!shadow-[0px_4px_6px_-2px_rgba(16,24,40,0.03)] !border-[#F1F5F9] !right-0  rounded !max-h-fit !w-auto max-sm:z-[9999]`}
                                dropdownOptions={[
                                    {
                                        label: (
                                            <div
                                                className=" text-gm-50 font-rocGroteskMedium text-sm flex justify-start
                                                 !w-[200px] px-4 py-[10px] gap-4 items-center"
                                            >
                                                <i className="ri-shopping-cart-line text-[#000] text-base"></i>
                                                Add manually
                                            </div>
                                        ),
                                        value: "",
                                        action: () =>
                                            navigate(
                                                `/dashboard/inventory/stock-control/stock-entry?channel=offline_purchase&currTab=${currTab}`
                                            ),
                                    },
                                    {
                                        label: (
                                            <div
                                                className=" text-gm-50 font-rocGroteskMedium text-sm flex justify-start
                                                 !w-[200px] px-4 py-[10px] gap-4 items-center"
                                            >
                                                <i className="ri-box-3-line text-[#000] text-base"></i>
                                                Shipments
                                            </div>
                                        ),
                                        value: "",
                                        action: () =>
                                            navigate(
                                                `/dashboard/inventory/stock-control/stock-entry?channel=shipment&currTab=${currTab}`
                                            ),
                                    },
                                    // {
                                    //     label: (
                                    //         <div
                                    //             className=" text-gm-50 font-rocGroteskMedium text-sm flex justify-start
                                    //  !w-[268px] px-4 py-[10px] gap-4 items-center"
                                    //         >
                                    //             <i className="ri-sparkling-fill text-[#000] text-base"></i>
                                    //             Import an excel file with AI
                                    //         </div>
                                    //     ),
                                    //     value: "",
                                    //     action: () =>
                                    //         navigate(
                                    //             `/dashboard/inventory/stock-control/stock-entry?channel=excel`
                                    //         ),
                                    // },
                                ]}
                            />
                        ) : activeTab === 1 ? (
                            <Button
                                btnText="New adjustment"
                                btnClassname="!py-[10px] max-lg:whitespace-nowrap !text-black !text-[13px] !w-auto !bg-[#ffb903] max-sm:!w-full"
                                onClick={() =>
                                    navigate(
                                        `/dashboard/inventory/stock-control/stock-adjustment?currTab=${currTab}`
                                    )
                                }
                            />
                        ) : (
                            <Button
                                btnText="Transfer Stock"
                                btnClassname="!py-[10px] max-lg:whitespace-nowrap !text-black !text-[13px] !w-auto !bg-[#ffb903] max-sm:!w-full"
                                onClick={() =>
                                    navigate(
                                        `/dashboard/inventory/stock-control/stock-transfer?currTab=${currTab}`
                                    )
                                }
                            />
                        )}
                    </div>
                </div>
            </div>

            <div className="max-lg:mt-2">
                {activeTab === 0 ? (
                    <StocksEntry
                        debouncedSearch={debouncedSearch}
                        warehouse={warehouse}
                        warehouseId={location}
                        stockCategory={stockCategory}
                    />
                ) : activeTab === 1 ? (
                    <StockAdjustment
                        debouncedSearch={debouncedSearch}
                        warehouse={warehouse}
                        warehouseId={location}
                        stockCategory={stockCategory}
                    />
                ) : activeTab === 2 ? (
                    <StockTransferList
                        debouncedSearch={debouncedSearch}
                        warehouse={warehouse}
                        warehouseId={location}
                        stockCategory={stockCategory}
                    />
                ) : (
                    ""
                )}
            </div>
        </div>
    );
};

export default StockControl;
