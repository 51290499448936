import config from "config/config";

export const getLatAndLng = async (res) => {
    
    const apiKey = config?.GOOGLE_API_KEY;
    let result = [];
    await Promise.all(
        res?.map(async ({ country, state, value }) => {
            const address = `${state}, ${country}`;
            const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
                address
            )}&key=${apiKey}`;
            try {
                const response = await fetch(apiUrl);
                const data = await response.json();
                if (data.status === "OK" && data.results.length > 0) {
                    const location = data.results[0].geometry.location;
                    result.push({
                        lat: +location.lat,
                        lng: +location.lng,
                        name: state || country,
                        value,
                    });
                } else {
                    console.error("Unable to geocode address:", address);
                }
            } catch (error) {
                console.error("Error fetching geocoding data:", error);
            }
        })
    );
    return result;
};

// export const getFullStateWithCity = async (data) => {
//     const apiKey = config?.GOOGLE_API_KEY;
//     let result = [];
//     await Promise.all(
//         data?.map(async ({ country, stateCode, value }) => {
//             const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${country}&components=administrative_area:${stateCode}&key=${apiKey}`;
//             try {
//                 const response = await fetch(apiUrl);
//                 const data = await response.json();
//                 if (data.status === "OK" && data.results.length > 0) {
//                     const stateName = data.results[0].address_components.find((component) =>
//                         component.types.includes("administrative_area_level_1")
//                     ).long_name;
//                     result.push({
//                         name: stateName,
//                         value,
//                         country,
//                         stateCode,
//                     });
//                 } else {
//                     // result = await getFullStateWithCity(data);
//                 }
//             } catch (error) {
//                 // result = await getFullStateWithCity(data);
//             }
//         })
//     );
//     return result;
// };


export const getFullState = async (data) => {
    const apiKey = config?.GOOGLE_API_KEY;
    let result = [];
    await Promise.all(
        data?.map(async ({ country, stateCode, value }) => {
            const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
                stateCode
            )}&key=${apiKey}`;

           

            try {
                const response = await fetch(apiUrl);
                const data = await response.json();

                if (data.status === "OK") {

                    const addressComponents = data.results[0].address_components;
                    const stateInfo = addressComponents.find((component) =>
                        component.types.includes("administrative_area_level_1")
                    );
                    result.push({
                        name: stateInfo.long_name,
                        value,
                        country,
                        stateCode: stateInfo.short_name,
                        cityCode: stateCode,
                    });
                } else {
                    console.log("error");
                }
            } catch (error) {
                console.log("error", error);
            }
        })
    );
    return result;
};
