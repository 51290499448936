import Button from "components/common/Button";
import CustomCalendar from "components/common/CalendarComp.js/Calendar";
import CustomTable from "components/common/CustomTable";
import SelectInput from "components/common/InputField/SelectInput";
import TextInput from "components/common/InputField/TextInput";
import TextareaInput from "components/common/InputField/TextareaInput";
import Loader from "components/common/Loader";
import VendorAvatar from "components/common/VendorAvatar";
import { displayFileIcon } from "helpers";
import { getFileTypeFromUrl } from "helpers/getFileTypeFromUrl";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import { debounce } from "lodash";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { authActions } from "redux/Ldb/actions/authActions";

type fromWarehouseProps = {
    productData: { [key: string]: any };
    handleProductDataChange: (
        e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement> | any,
        idx: number
    ) => void;
    handleDateChange: (
        e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
        idx: number
    ) => void;
    handleDeleteProduct: (idx: number) => void;
    transferData: { [key: string]: any };
    handleTransferData: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement> | any) => void;
    warehouse: any;
    handleAddProduct: any;
    handleChangeWarehouse: any;
    destinationWarehouse: any;
    warehousesData: any;
    productList: any;
    uploadingAttachment: boolean;
    handleUpload: any;
    attachment: any;
    deleteAttachment: any;
};
const FromWarehouse = ({
    productData,
    handleProductDataChange,
    handleDateChange,
    handleDeleteProduct,
    transferData,
    handleTransferData,
    warehouse,
    handleAddProduct,
    handleChangeWarehouse,
    destinationWarehouse,
    warehousesData,
    productList,
    uploadingAttachment,
    handleUpload,
    attachment,
    deleteAttachment,
}: fromWarehouseProps) => {
    const dispatch = useAppDispatch();
    const customRef = useRef(null);
    const [teamMembers, setTeamMembers] = useState<any>();
    const [debounceTeamSearch, setDebounceTeamSearch] = useState<string>("");
    const [showIndex, setShowIndex] = useState<number>();
    const { fetchingWarehouses, fetchingStockList } = useAppSelector((state) => state?.inventory);

    const { fetchedTeamMemberSuccess, fetchingTeamMember } = useAppSelector((state) => state.auth);

    useEffect(() => {
        if (Boolean(fetchedTeamMemberSuccess)) {
            setTeamMembers(fetchedTeamMemberSuccess?.teamMembers);
        }
    }, [fetchedTeamMemberSuccess]);

    useEffect(() => {
        dispatch(authActions.getTeamMembers(debounceTeamSearch));
    }, [dispatch, debounceTeamSearch]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (customRef.current && !customRef.current.contains(event.target)) {
                setShowIndex(-1);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [customRef]);

    const tableHeader = [
        { title: "No", widthClass: "w-[7%] !font-rocGroteskMedium " },
        { title: "Product name", widthClass: "w-[40%] !font-rocGroteskMedium" },
        { title: "Date schedule", widthClass: "w-[14%] !font-rocGroteskMedium" },
        { title: "Qty", widthClass: "w-[8.3%] !font-rocGroteskMedium" },
        { title: "Cost price (USD)", widthClass: "w-[13%] !font-rocGroteskMedium" },
        { title: "Total cost (USD)", widthClass: "w-[12.7%] !font-rocGroteskMedium" },
        { title: "", widthClass: "w-[4.6%] !font-rocGroteskMedium" },
    ];

    const tableBody = productData?.map((item, idx) => {
        const res = item?.quantity * item?.movementCost?.amount;

        return [
            {
                content: (
                    <div
                        className={`w-full flex items-center gap-2 border-slate-100 text-sm text-slate-700 `}
                    >
                        {idx + 1}
                    </div>
                ),
            },
            {
                content: (
                    <div className={`w-full  border-slate-100 text-sm text-slate-700 `}>
                        <SelectInput
                            value={item?.inventoryId || ""}
                            name="product"
                            inputPlaceholder={"Select"}
                            handleChange={(name, value) =>
                                handleProductDataChange({ target: { name, value } }, idx)
                            }
                            isRequired={true}
                            className="!border-0 !rounded-none"
                            inputContainerClassName="!mb-0"
                            searchLoading={fetchingStockList}
                            clearValue={true}
                            dropdownOptions={productList?.map((item) => ({
                                label: (
                                    <div className="flex gap-3 items-center">
                                        <div className="flex gap flex-col">
                                            <p className="font-rocGroteskMedium">
                                                {item?.inventory?.productName}
                                            </p>
                                        </div>
                                    </div>
                                ),
                                icon: (
                                    <div className="">
                                        {item?.inventory?.productImageDetails?.productAvatar ? (
                                            <div className=" ">
                                                <img
                                                    src={
                                                        item?.inventory?.productImageDetails
                                                            ?.productAvatar
                                                    }
                                                    className="h-8 w-8 rounded"
                                                />
                                            </div>
                                        ) : (
                                            <div className="w-8 h-8 rounded flex items-center justify-center bg-slate-200 ">
                                                <i className="ri-box-2-fill text-lg text-slate-500"></i>
                                            </div>
                                        )}
                                    </div>
                                ),
                                subText: <p className="">{item?.batchId}</p>,
                                value: `${item?.inventoryId}split${item?.batchId}`,
                            }))}
                        />
                    </div>
                ),
                tableDataDivClass: "!pl-0",
            },
            {
                content: (
                    <div className={`w-full border-slate-100 text-sm text-slate-700 `}>
                        <div className="relative">
                            <div
                                onClick={(e) => {
                                    setShowIndex(idx);
                                }}
                                className="w-full"
                            >
                                <TextInput
                                    value={
                                        item?.movementDate
                                            ? moment(item?.movementDate).format("MMM DD, YYYY")
                                            : ""
                                    }
                                    type={"text"}
                                    placeholder={"Select"}
                                    required={true}
                                    inputContainerClassname="!border-0 cursor-pointer !bg-transparent"
                                    rightIcon={
                                        <i className="ri-calendar-line text-gm-45 text-base cursor-pointer "></i>
                                    }
                                    inputClassName={`!cursor-pointer`}
                                />
                            </div>

                            {showIndex === idx && (
                                <div
                                    className="w-auto  z-[100] absolute  -right-[150px] mt-3 px-[28px] pt-8 pb-8 bg-white
                                           rounded-2xl shadow-[0px_0px_4px_rgba(0,0,0,0.04),0px_4px_32px_rgba(0,0,0,0.06)]"
                                    ref={customRef}
                                >
                                    <CustomCalendar
                                        showDoubleView={false}
                                        selectRange={false}
                                        value={item?.movementDate}
                                        onChange={(value) => handleDateChange(idx, value)}
                                    />
                                    <div className="flex justify-end">
                                        <Button
                                            btnText={"Close"}
                                            btnClassname={"!w-fit !py-2"}
                                            onClick={() => setShowIndex(-1)}
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                ),
                tableDataDivClass: "!pl-0",
            },

            {
                content: (
                    <div className={`w-full border-slate-100 text-sm text-slate-700 `}>
                        <TextInput
                            value={item?.quantity ?? ""}
                            name={"quantity"}
                            type={"number"}
                            required={true}
                            onChange={(e) => handleProductDataChange(e, idx)}
                            inputPlaceholder="0"
                            containerClassname=""
                            inputContainerClassname={"!border-0 !rounded-none "}
                            inputClassName="w-full "
                        />
                    </div>
                ),
                tableDataDivClass: "!pl-0",
            },
            {
                content: (
                    <div className={`w-full border-slate-100 text-sm text-slate-700 `}>
                        <TextInput
                            value={item?.movementCost?.amount ?? ""}
                            name={"amount"}
                            type={"number"}
                            required={true}
                            onChange={(e) => handleProductDataChange(e, idx)}
                            inputPlaceholder="0.00"
                            containerClassname=""
                            inputContainerClassname={"!border-0 !rounded-none "}
                            inputClassName="w-full "
                        />
                    </div>
                ),
                tableDataDivClass: "!pl-0",
            },
            {
                content: (
                    <div className={`w-full border-slate-100 text-sm text-slate-700 `}>
                        <TextInput
                            value={res ? res?.toLocaleString() : ""}
                            type={"text"}
                            inputPlaceholder="0.00"
                            containerClassname=""
                            inputContainerClassname={"!border-0 !rounded-none !bg-transparent"}
                            inputClassName="w-full "
                            disabled
                        />
                    </div>
                ),
                tableDataDivClass: "!pl-0",
            },
            {
                content: (
                    <div className={`w-full border-slate-100 text-sm text-slate-700 `}>
                        <i
                            className={`ri-delete-bin-line ${
                                productData?.length === 1 && "cursor-default"
                            }`}
                            onClick={() => handleDeleteProduct(idx)}
                        ></i>
                    </div>
                ),
            },
        ];
    });

    return (
        <>
            <div>
                <div className="mb-6 w-[65%]">
                    {/* <label className="text-base font-rocGroteskMedium">Transfer details</label> */}

                    <div className="mt-4">
                        <div className="flex items-center gap-4 ">
                            <div className="w-full">
                                <p className="text-base font-rocGroteskMedium mb-4">From</p>
                                <SelectInput
                                    value={warehouse?.warehouseFromId ?? ""}
                                    name="warehouseFromId"
                                    placeholder={"Source warehouse"}
                                    handleChange={(name, value) => {
                                        handleChangeWarehouse({ target: { name, value } });
                                    }}
                                    searchLoading={fetchingWarehouses}
                                    isRequired={true}
                                    dropdownOptions={warehousesData?.map((item) => ({
                                        label: item?.warehouseName,
                                        value: item?._id,
                                    }))}
                                    className="overflow-y-scroll"
                                    clearValue={true}
                                />
                            </div>
                            {/* <i className="ri-arrow-right-line text-slate-500"></i> */}

                            <div className="w-full">
                                <p className="text-base font-rocGroteskMedium mb-4">To</p>

                                <SelectInput
                                    value={warehouse?.warehouseToId ?? ""}
                                    name="warehouseToId"
                                    placeholder={"Destination warehouse"}
                                    handleChange={(name, value) => {
                                        handleChangeWarehouse({ target: { name, value } });
                                    }}
                                    isRequired={true}
                                    searchLoading={fetchingWarehouses}
                                    dropdownOptions={destinationWarehouse?.map((item) => ({
                                        label: item?.warehouseName,
                                        value: item?._id,
                                    }))}
                                    clearValue={true}
                                />
                            </div>
                        </div>

                        <div className="flex items-center gap-4 mt-4">
                            <SelectInput
                                value={warehouse?.contactPersonFromId ?? ""}
                                name="contactPersonFromId"
                                placeholder={"Contact person"}
                                handleChange={(name, value) => {
                                    handleChangeWarehouse({ target: { name, value } });
                                }}
                                isRequired={true}
                                handleInputChange={debounce((evt) => {
                                    setDebounceTeamSearch(evt.target.value);
                                }, 800)}
                                searchLoading={fetchingTeamMember}
                                dropdownOptions={teamMembers?.map((item) => ({
                                    label: (
                                        <div className="flex gap-2">
                                            <VendorAvatar
                                                name={item?.fullName || item?.email}
                                                imageSrc={item?.avatar}
                                                size={24}
                                            />
                                            {item?.fullName || item?.email}
                                        </div>
                                    ),
                                    value: item?._id,
                                }))}
                                clearValue={true}
                            />

                            <SelectInput
                                value={warehouse?.contactPersonToId ?? ""}
                                name="contactPersonToId"
                                placeholder={"Contact person"}
                                handleChange={(name, value) => {
                                    handleChangeWarehouse({ target: { name, value } });
                                }}
                                isRequired={true}
                                handleInputChange={debounce((evt) => {
                                    setDebounceTeamSearch(evt.target.value);
                                }, 800)}
                                searchLoading={fetchingTeamMember}
                                clearValue={true}
                                dropdownOptions={teamMembers?.map((item) => ({
                                    label: (
                                        <div className="flex gap-2">
                                            <VendorAvatar
                                                name={item?.fullName || item?.email}
                                                imageSrc={item?.avatar}
                                                size={24}
                                            />
                                            {item?.fullName || item?.email}
                                        </div>
                                    ),
                                    value: item?._id,
                                }))}
                            />
                        </div>
                    </div>
                </div>

                <div className="mb-6">
                    <label className="text-base font-rocGroteskMedium">Products</label>
                    <div className="mt-4">
                        <CustomTable
                            tableBody={tableBody}
                            tableHeader={tableHeader}
                            isAllSelectable={true}
                            isCellSelectable
                            headerContainerClass=""
                            isScrollable={false}
                            isCellBordered={true}
                            bodyItemClass={"hover:!border-[1.5px] hover:!border-gm-50 "}
                        />
                    </div>
                    <div className="flex items-center gap-3 mt-4">
                        <Button
                            btnText="Add more products"
                            btnClassname="text-[13px] !px-4 !py-2 !bg-n-20 !w-fit"
                            onClick={handleAddProduct}
                        />
                    </div>
                </div>

                <div className="my-4">
                    <label className="text-base font-rocGroteskMedium">Attachments</label>
                    <input
                        type="file"
                        className="hidden"
                        id="attachment"
                        accept="image*, .pdf, .xlsx"
                        disabled={uploadingAttachment}
                        onChange={(e) => {
                            if (e.target.files?.[0]) {
                                handleUpload(
                                    e.target.files?.[0]?.name as string,
                                    e.target.files?.[0]?.size as number
                                );
                            }
                        }}
                    />
                    <div className="mt-4 ">
                        {attachment?.map((item, idx) => {
                            return (
                                <div className="flex items-center justify-between p-3 rounded-md border border-slate-200 mb-4">
                                    <div className="flex items-center gap-2">
                                        {displayFileIcon(getFileTypeFromUrl(item?.url), false)}
                                        <div className="font-rocGroteskMedium">
                                            <p className="text-sm">{item?.name}</p>
                                            <p className="text-xs text-slate-500">
                                                {/* {item?.size} */}
                                            </p>
                                        </div>
                                    </div>
                                    <i
                                        className="ri-delete-bin-line text-xl text-r-50 cursor-pointer"
                                        onClick={() => deleteAttachment(idx)}
                                    ></i>
                                </div>
                            );
                        })}

                        <label
                            className={`flex items-center gap-4 py-3 px-6 rounded border border-dashed border-slate-200  ${
                                !uploadingAttachment && "cursor-pointer"
                            }`}
                            htmlFor="attachment"
                        >
                            <i className="ri-file-add-line text-2xl text-carribean-green"></i>
                            {uploadingAttachment ? (
                                <div className="">
                                    <Loader size={4} />
                                </div>
                            ) : (
                                <p className="text-sm underline text-carribean-green font-rocGroteskMedium">
                                    Click to upload document
                                </p>
                            )}
                        </label>
                    </div>
                </div>

                <div className="w-[65%]">
                    <label className="text-base font-rocGroteskMedium">Notes</label>
                    <div className="mt-4">
                        <TextareaInput
                            name={"notes"}
                            value={transferData?.note}
                            onChange={handleTransferData}
                            rows={5}
                            placeholder={"Add note"}
                            containerClassname=""
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default FromWarehouse;
