import React from "react";

const NoMatchPage = () => {
    return (
        <div className="h-screen flex flex-col items-center justify-center">
            <p className="font-rocGroteskMedium text-2xl mb-5">404</p>
            <p className="font-rocGroteskMedium text-lg mb-5">PAGE NOT FOUND</p>
        </div>
    );
};

export default NoMatchPage;
