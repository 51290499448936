import React from "react";
import Dropdown from "./Dropdown";
import {displayFileIcon} from "helpers";
import {getFileTypeFromUrl} from "helpers/getFileTypeFromUrl";

type DocumentCardProps = {
    name: string;
    imgSrc?: string;
    date?: string;
    dropDownClass?: string;
    dropdownOptions?: Array<Record<string, any>>;
    fileUrl?: string;
};

const DocumentCard = ({
    name,
    imgSrc,
    date,
    dropDownClass,
    dropdownOptions,
    fileUrl,
}: DocumentCardProps) => {
    return (
        <div className="py-3 p-6 flex items-center justify-between border-b border-slate-100">
            <div className="flex items-center gap-3">
                {displayFileIcon(getFileTypeFromUrl(fileUrl as string), true)}
                <p className="text-sm font-rocGroteskMedium text-slate-700">{name}</p>
            </div>
            <div className="flex items-center gap-3">
                {date && <p className="text-sm font-rocGroteskMedium text-slate-500">{date}</p>}
                <Dropdown
                    dropdown={
                        <i className="ri-more-2-fill before:content-['\ef76'] text-slate-500"></i>
                    }
                    dropdownClassName={"!w-full !mb-10 !-mt-7"}
                    dropdownContainerClasses={`shadow-cardShadow-2 ${dropDownClass}  border-0 rounded !max-h-fit`}
                    dropdownItemsClasses={``}
                    name={"faq-dropdown"}
                    dropdownOptions={dropdownOptions}
                />
            </div>
        </div>
    );
};

export default DocumentCard;
