import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "./storeHooks";
import { trackTraceActions, vendorActions } from "redux/Ldb/actions";
import { useParams } from "react-router-dom";

const useGetAllChannel = () => {
    const dispatch = useAppDispatch();
    const [channels, setChannels] = useState<{ [key: string]: any }>([]);

    const { fetchingAllChannels, fetchedAllChannelsSuccess, fetchedAllChannelsFailure } =
        useAppSelector((state) => state.vendor);

    useEffect(() => {
        if (Boolean(fetchedAllChannelsSuccess)) {
            setChannels(fetchedAllChannelsSuccess?.channel);
        }
    }, [fetchedAllChannelsSuccess]);

    useEffect(() => {
        dispatch(vendorActions?.getAllChannels());
    }, [dispatch]);

    return {
        data: channels,
        isFetching: fetchingAllChannels,
        error: fetchedAllChannelsFailure,
    };
};

export default useGetAllChannel;
