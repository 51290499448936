import React from "react";
import CustomToast from "components/common/CustomToast";
import { orderTypes } from "redux//Ldb/types";
import { toast } from "react-hot-toast";
import { orderService } from "services/Ldb";
import { Dispatch } from "redux";

export const orderActions = {
    getRfqs,
    getOrders,
    getSingleRfq,
    getSingleOrder,
    getQuotes,
    getSingleQuotes,
    getSingleQuoteResponses,
    acceptQuote,
    resetAcceptQuoteSuccess,
    updateOrder,
    resetUpdateOrderSuccess,
    resetSendPurchaseOrderSuccess,
    updateRfq,
    resetUpdateRfqSuccess,
    sendRfqs,
    resetCreatePurchaseOrderSuccess,
    resetSendRfqsSuccess,
    deleteRfq,
    resetDeleteRfqSuccess,
    createPurchaseOrder,
    updatePurchaseOrder,
    getPurchaseOrder,
    getSinglePurchaseOrder,
    sendPurchaseOrder,
    getApprovedResponses,
    getSingleQuoteResponse,
    resetUpdatePurchaseOrderSuccess,
    getPONoPaginate,
    approveRejectPO,
    resetApproveRejectPOSuccess,
    getPOSettings,
    updatePOSettings,
    getPendingPurchaseOrder,
    getRejectedPurchaseOrder,
    getApprovedPurchaseOrder,
    getTotalPurchaseOrder,
    getQuotesHistory,
    getPurchaseRequest,
    getPOStatistics,
    getTotalPurchaseRequest,
    resetPurchaseOrderNotRequest,
    resetPurchaseOrderRequest,
};

function getRfqs(
    rfqLimit?: string | number,
    search?: string,
    vendor?: string,
    orderId?: string,
    amount?: string
) {
    return (dispatch: Dispatch) => {
        dispatch(request(orderTypes.FETCH_RFQS_REQUEST));

        orderService.getRfqs(rfqLimit, search, vendor, orderId, amount).then(
            (res) => {
                dispatch(success(orderTypes.FETCH_RFQS_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(orderTypes.FETCH_RFQS_FAILURE, error?.message));
                }
            }
        );
    };
}

function getOrders(orderLimit: string | number) {
    return (dispatch: Dispatch) => {
        dispatch(request(orderTypes.FETCH_ORDERS_REQUEST));

        orderService.getOrders(orderLimit).then(
            (res) => {
                dispatch(success(orderTypes.FETCH_ORDERS_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(orderTypes.FETCH_ORDERS_FAILURE, error?.message));
                }
            }
        );
    };
}
function getPOSettings() {
    return (dispatch: Dispatch) => {
        dispatch(request(orderTypes.FETCH_PO_SETTINGS_REQUEST));

        orderService.getPOSettings().then(
            (res) => {
                dispatch(success(orderTypes.FETCH_PO_SETTINGS_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(orderTypes.FETCH_PO_SETTINGS_FAILURE, error?.message));
                }
            }
        );
    };
}

function updatePOSettings(data: Record<string, any> | Array<Record<string, any>>) {
    return (dispatch: Dispatch) => {
        dispatch(request(orderTypes.UPDATE_PO_SETTINGS_REQUEST));

        orderService.updatePOSettings(data).then(
            (res) => {
                dispatch(success(orderTypes.UPDATE_PO_SETTINGS_SUCCESS, res?.data));
                toast.custom((t) => (
                    <CustomToast t={t} message={"Updated successfully"} type="success" />
                ));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(orderTypes.UPDATE_PO_SETTINGS_FAILURE, error?.message));
                }
            }
        );
    };
}

function getSingleRfq(rfqId: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(orderTypes.FETCH_SINGLE_RFQ_REQUEST));

        orderService.getSingleRfq(rfqId).then(
            (res) => {
                dispatch(success(orderTypes.FETCH_SINGLE_RFQ_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(orderTypes.FETCH_SINGLE_RFQ_FAILURE, error?.message));
                }
            }
        );
    };
}

function getSingleOrder(orderId: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(orderTypes.FETCH_SINGLE_ORDER_REQUEST));

        orderService.getSingleOrder(orderId).then(
            (res) => {
                dispatch(success(orderTypes.FETCH_SINGLE_ORDER_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(orderTypes.FETCH_SINGLE_ORDER_FAILURE, error?.message));
                }
            }
        );
    };
}

function getQuotes(
    quoteLimit?: string | number,
    search?: string,
    vendor?: string,
    quoteId?: string,
    amount?: string | number
) {
    return (dispatch: Dispatch) => {
        dispatch(request(orderTypes.FETCH_QUOTES_REQUEST));

        orderService.getQuotes(quoteLimit, search, vendor, quoteId, amount).then(
            (res) => {
                dispatch(success(orderTypes.FETCH_QUOTES_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => <CustomToast t={t} message={error.message} type="error" />);
                    dispatch(failure(orderTypes.FETCH_QUOTES_FAILURE, error.message));
                }
            }
        );
    };
}

function updateOrder(orderId: string, data: Record<string, any> | Array<Record<string, any>>) {
    return (dispatch: Dispatch) => {
        dispatch(request(orderTypes.UPDATE_ORDER_REQUEST));

        orderService.updateOrder(orderId, data).then(
            (res) => {
                dispatch(success(orderTypes.UPDATE_ORDER_SUCCESS, res?.data));
                toast.custom((t) => (
                    <CustomToast t={t} message={"Updated successfully"} type="success" />
                ));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(orderTypes.UPDATE_ORDER_FAILURE, error?.message));
                }
            }
        );
    };
}

function getSingleQuotes(quoteId: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(orderTypes.FETCH_SINGLE_QUOTES_REQUEST));

        orderService.getSingleQuotes(quoteId).then(
            (res) => {
                dispatch(success(orderTypes.FETCH_SINGLE_QUOTES_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => <CustomToast t={t} message={error.message} type="error" />);
                    dispatch(failure(orderTypes.FETCH_SINGLE_QUOTES_FAILURE, error.message));
                }
            }
        );
    };
}
function updateRfq(rfqId: string, data: Record<string, any> | Array<Record<string, any>>) {
    return (dispatch: Dispatch) => {
        dispatch(request(orderTypes.UPDATE_RFQ_REQUEST));

        orderService.updateRfq(rfqId, data).then(
            (res) => {
                dispatch(success(orderTypes.UPDATE_RFQ_SUCCESS, res?.data));
                toast.custom((t) => (
                    <CustomToast t={t} message={"Updated successfully"} type="success" />
                ));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(orderTypes.UPDATE_RFQ_FAILURE, error?.message));
                }
            }
        );
    };
}

function getApprovedResponses(limit?: string | number, search?: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(orderTypes.FETCH_APPROVED_RESPONSE_REQUEST));

        orderService.getApprovedResponses(limit, search).then(
            (res) => {
                dispatch(success(orderTypes.FETCH_APPROVED_RESPONSE_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => <CustomToast t={t} message={error.message} type="error" />);
                    dispatch(failure(orderTypes.FETCH_APPROVED_RESPONSE_FAILURE, error.message));
                }
            }
        );
    };
}

function getQuotesHistory(id: string, limit?: string | number,page?:number, search?: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(orderTypes.FETCH_QUOTES_HISTORY_REQUEST));

        orderService.getQuotesHistory(id, limit, page, search).then(
            (res) => {
                dispatch(success(orderTypes.FETCH_QUOTES_HISTORY_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => <CustomToast t={t} message={error.message} type="error" />);
                    dispatch(failure(orderTypes.FETCH_QUOTES_HISTORY_FAILURE, error.message));
                }
            }
        );
    };
}
function getSingleQuoteResponses(quoteId: string, responseLimit?: string | number) {
    return (dispatch: Dispatch) => {
        dispatch(request(orderTypes.FETCH_SINGLE_QUOTE_RESPONSES_REQUEST));

        orderService.getSingleQuoteResponses(quoteId, responseLimit).then(
            (res) => {
                dispatch(success(orderTypes.FETCH_SINGLE_QUOTE_RESPONSES_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => <CustomToast t={t} message={error.message} type="error" />);
                    dispatch(
                        failure(orderTypes.FETCH_SINGLE_QUOTE_RESPONSES_FAILURE, error.message)
                    );
                }
            }
        );
    };
}

function sendRfqs(data: Record<string, any> | Array<Record<string, any>>) {
    return (dispatch: Dispatch) => {
        dispatch(request(orderTypes.SEND_RFQS_REQUEST));

        orderService.sendRfqs(data).then(
            (res) => {
                dispatch(success(orderTypes.SEND_RFQS_SUCCESS, res?.data));
                toast.custom((t) => <CustomToast t={t} message={"RFQs sent"} type="success" />);
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(orderTypes.SEND_RFQS_FAILURE, error?.message));
                }
            }
        );
    };
}

function acceptQuote(reqObj: Record<string, any> | Array<Record<string, any>>) {
    return (dispatch: Dispatch) => {
        dispatch(request(orderTypes.ACCEPT_QUOTE_REQUEST));

        orderService.acceptQuote(reqObj).then(
            (res) => {
                dispatch(success(orderTypes.ACCEPT_QUOTE_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => <CustomToast t={t} message={error.message} type="error" />);
                    dispatch(failure(orderTypes.ACCEPT_QUOTE_FAILURE, error.message));
                }
            }
        );
    };
}

function deleteRfq(rfqId: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(orderTypes.DELETE_RFQ_REQUEST));

        orderService.deleteRfq(rfqId).then(
            (res) => {
                dispatch(success(orderTypes.DELETE_RFQ_SUCCESS, res?.data));
                toast.custom((t) => <CustomToast t={t} message={"RFQ deleted"} type="success" />);
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(orderTypes.DELETE_RFQ_FAILURE, error?.message));
                }
            }
        );
    };
}

function createPurchaseOrder(reqObj: Record<string, any> | Array<Record<string, any>>) {
    return (dispatch: Dispatch) => {
        dispatch(request(orderTypes.CREATE_PURCHASE_ORDER_REQUEST));

        orderService.createPurchaseOrder(reqObj).then(
            (res) => {
                dispatch(success(orderTypes.CREATE_PURCHASE_ORDER_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(orderTypes.CREATE_PURCHASE_ORDER_FAILURE, error?.message));
                }
            }
        );
    };
}

function updatePurchaseOrder(poId: string, data: Record<string, any> | Array<Record<string, any>>) {
    return (dispatch: Dispatch) => {
        dispatch(request(orderTypes.UPDATE_PURCHASE_ORDER_REQUEST));

        orderService.updatePurchaseOrder(poId, data).then(
            (res) => {
                dispatch(success(orderTypes.UPDATE_PURCHASE_ORDER_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(orderTypes.UPDATE_PURCHASE_ORDER_FAILURE, error?.message));
                }
            }
        );
    };
}

function getPurchaseRequest(
    poLimit?: string | number,
    page?: number,
    search?: string,
    taId?: string,
    approvalStatus?: string
) {
    return (dispatch: Dispatch) => {
        dispatch(request(orderTypes.FETCH_PURCHASE_ORDER_REQUEST));

        orderService.getPurchaseRequest(poLimit, page, search, taId, approvalStatus).then(
            (res) => {
                dispatch(success(orderTypes.FETCH_PURCHASE_ORDER_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => <CustomToast t={t} message={error.message} type="error" />);
                    dispatch(failure(orderTypes.FETCH_PURCHASE_ORDER_FAILURE, error.message));
                }
            }
        );
    };
}

function getTotalPurchaseRequest(
    poLimit?: string | number,
    page?: number,
    search?: string,
    taId?: string,
    approvalStatus?: string
) {
    return (dispatch: Dispatch) => {
        dispatch(request(orderTypes.FETCH_TOTAL_PURCHASE_REQUEST));

        orderService.getPurchaseRequest(poLimit, page, search, taId, approvalStatus).then(
            (res) => {
                dispatch(success(orderTypes.FETCH_TOTAL_PURCHASE_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => <CustomToast t={t} message={error.message} type="error" />);
                    dispatch(failure(orderTypes.FETCH_TOTAL_PURCHASE_FAILURE, error.message));
                }
            }
        );
    };
}

function getPurchaseOrder(
    poLimit?: string | number,
    page?: number,
    search?: string,
    taId?: string,
    status?: string
) {
    return (dispatch: Dispatch) => {
        dispatch(request(orderTypes.FETCH_PURCHASE_ORDER_NOT_REQUEST));

        orderService.getPurchaseOrder(poLimit, page, search, taId, status).then(
            (res) => {
                dispatch(success(orderTypes.FETCH_PURCHASE_ORDER_NOT_REQUEST_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => <CustomToast t={t} message={error.message} type="error" />);
                    dispatch(
                        failure(orderTypes.FETCH_PURCHASE_ORDER_NOT_REQUEST_FAILURE, error.message)
                    );
                }
            }
        );
    };
}

function getPendingPurchaseOrder(
    poLimit?: string | number,
    page?: number,
    search?: string,
    taId?: string,
    approvalStatus?: string
) {
    return (dispatch: Dispatch) => {
        dispatch(request(orderTypes.FETCH_PENDING_PURCHASE_ORDER_REQUEST));

        orderService.getPurchaseRequest(poLimit, page, search, taId, approvalStatus).then(
            (res) => {
                dispatch(success(orderTypes.FETCH_PENDING_PURCHASE_ORDER_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => <CustomToast t={t} message={error.message} type="error" />);
                    dispatch(
                        failure(orderTypes.FETCH_PENDING_PURCHASE_ORDER_FAILURE, error.message)
                    );
                }
            }
        );
    };
}

function getRejectedPurchaseOrder(
    poLimit?: string | number,
    page?: number,
    search?: string,
    taId?: string,
    approvalStatus?: string
) {
    return (dispatch: Dispatch) => {
        dispatch(request(orderTypes.FETCH_REJECTED_PURCHASE_ORDER_REQUEST));

        orderService.getPurchaseRequest(poLimit, page, search, taId, approvalStatus).then(
            (res) => {
                dispatch(success(orderTypes.FETCH_REJECTED_PURCHASE_ORDER_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => <CustomToast t={t} message={error.message} type="error" />);
                    dispatch(
                        failure(orderTypes.FETCH_REJECTED_PURCHASE_ORDER_FAILURE, error.message)
                    );
                }
            }
        );
    };
}

function getApprovedPurchaseOrder(
    poLimit?: string | number,
    page?: number,
    search?: string,
    taId?: string,
    approvalStatus?: string
) {
    return (dispatch: Dispatch) => {
        dispatch(request(orderTypes.FETCH_APPROVED_PURCHASE_ORDER_REQUEST));

        orderService.getPurchaseRequest(poLimit, page, search, taId, approvalStatus).then(
            (res) => {
                dispatch(success(orderTypes.FETCH_APPROVED_PURCHASE_ORDER_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => <CustomToast t={t} message={error.message} type="error" />);
                    dispatch(
                        failure(orderTypes.FETCH_APPROVED_PURCHASE_ORDER_FAILURE, error.message)
                    );
                }
            }
        );
    };
}

function getTotalPurchaseOrder() {
    return (dispatch: Dispatch) => {
        dispatch(request(orderTypes.FETCH_TOTAL_PURCHASE_ORDER_REQUEST));

        orderService.getPurchaseRequest().then(
            (res) => {
                dispatch(success(orderTypes.FETCH_TOTAL_PURCHASE_ORDER_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => <CustomToast t={t} message={error.message} type="error" />);
                    dispatch(failure(orderTypes.FETCH_TOTAL_PURCHASE_ORDER_FAILURE, error.message));
                }
            }
        );
    };
}

function getPOStatistics() {
    return (dispatch: Dispatch) => {
        dispatch(request(orderTypes.FETCH_PURCHASE_ORDER_STATISTICS_REQUEST));

        orderService.getPOStatistics().then(
            (res) => {
                dispatch(success(orderTypes.FETCH_PURCHASE_ORDER_STATISTICS_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => <CustomToast t={t} message={error.message} type="error" />);
                    dispatch(
                        failure(orderTypes.FETCH_PURCHASE_ORDER_STATISTICS_FAILURE, error.message)
                    );
                }
            }
        );
    };
}

function getSinglePurchaseOrder(orderId: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(orderTypes.FETCH_SINGLE_PURCHASE_ORDER_REQUEST));

        orderService.getSinglePurchaseOrder(orderId).then(
            (res) => {
                dispatch(success(orderTypes.FETCH_SINGLE_PURCHASE_ORDER_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(orderTypes.FETCH_SINGLE_PURCHASE_ORDER_FAILURE, error?.message)
                    );
                }
            }
        );
    };
}

function sendPurchaseOrder(taId: string, body: Record<string, any> | Array<Record<string, any>>) {
    return (dispatch: Dispatch) => {
        dispatch(request(orderTypes.SEND_PURCHASE_ORDER_REQUEST));

        orderService.sendPurchaseOrder(taId, body).then(
            (res) => {
                dispatch(success(orderTypes.SEND_PURCHASE_ORDER_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => <CustomToast t={t} message={error.message} type="error" />);
                    dispatch(failure(orderTypes.SEND_PURCHASE_ORDER_FAILURE, error.message));
                }
            }
        );
    };
}

function getSingleQuoteResponse(quoteId: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(orderTypes.FETCH_SINGLE_RESPONSE_REQUEST));

        orderService.getSingleQuoteResponse(quoteId).then(
            (res) => {
                dispatch(success(orderTypes.FETCH_SINGLE_RESPONSE_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => <CustomToast t={t} message={error.message} type="error" />);
                    dispatch(failure(orderTypes.FETCH_SINGLE_RESPONSE_FAILURE, error.message));
                }
            }
        );
    };
}

function getPONoPaginate(status) {
    return async (dispatch) => {
        dispatch(request(orderTypes.FETCH_PO_NO_PAGINATE_REQUEST));

        try {
            const res = await orderService.getPONoPaginate(status);
            dispatch(success(orderTypes.FETCH_PO_NO_PAGINATE_SUCCESS, res?.data));
            return res;
        } catch (error) {
            if (error.message) {
                toast.custom((t) => <CustomToast t={t} message={error.message} type="error" />);
                dispatch(failure(orderTypes.FETCH_PO_NO_PAGINATE_FAILURE, error.message));
            }
            throw error;
        }
    };
}

function approveRejectPO(data) {
    return (dispatch) => {
        dispatch(request(orderTypes.APPROVE_REJECT_PO_REQUEST));

        orderService.approveRejectPO(data).then(
            (res) => {
                dispatch(success(orderTypes.APPROVE_REJECT_PO_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => <CustomToast t={t} message={error.message} type="error" />);
                    dispatch(failure(orderTypes.APPROVE_REJECT_PO_FAILURE, error.message));
                }
            }
        );
    };
}

function resetApproveRejectPOSuccess() {
    return (dispatch) => {
        dispatch(success(orderTypes.APPROVE_REJECT_PO_SUCCESS, null));
    };
}

function resetAcceptQuoteSuccess() {
    return (dispatch: Dispatch) => {
        dispatch(success(orderTypes.ACCEPT_QUOTE_SUCCESS, null));
    };
}

function resetUpdateOrderSuccess() {
    return (dispatch: Dispatch) => {
        dispatch(success(orderTypes.UPDATE_ORDER_SUCCESS, null));
    };
}

function resetCreatePurchaseOrderSuccess() {
    return (dispatch: Dispatch) => {
        dispatch(success(orderTypes.CREATE_PURCHASE_ORDER_SUCCESS, null));
    };
}
function resetPurchaseOrderNotRequest() {
    return (dispatch: Dispatch) => {
        dispatch(success(orderTypes.FETCH_PURCHASE_ORDER_NOT_REQUEST_SUCCESS, null));
    };
}
function resetPurchaseOrderRequest() {
    return (dispatch: Dispatch) => {
        dispatch(success(orderTypes.FETCH_PURCHASE_ORDER_SUCCESS, null));
    };
}
function resetUpdatePurchaseOrderSuccess() {
    return (dispatch: Dispatch) => {
        dispatch(success(orderTypes.UPDATE_PURCHASE_ORDER_SUCCESS, null));
    };
}
function resetSendPurchaseOrderSuccess() {
    return (dispatch: Dispatch) => {
        dispatch(success(orderTypes.SEND_PURCHASE_ORDER_SUCCESS, null));
    };
}

function resetUpdateRfqSuccess() {
    return (dispatch: Dispatch) => {
        dispatch(success(orderTypes.UPDATE_RFQ_SUCCESS, null));
    };
}

function resetSendRfqsSuccess() {
    return (dispatch: Dispatch) => {
        dispatch(success(orderTypes.SEND_RFQS_SUCCESS, null));
    };
}

function resetDeleteRfqSuccess() {
    return (dispatch: Dispatch) => {
        dispatch(success(orderTypes.DELETE_RFQ_SUCCESS, null));
    };
}

function request(type: string) {
    return { type: type };
}
function success(type: string, data?: any) {
    return { type: type, payload: data };
}
function failure(type: string, error?: any) {
    return { type: type, payload: error ?? "" };
}
