import { useEffect, useState } from "react";
import { inventoryActions } from "redux/Ldb/actions";
import { useAppDispatch, useAppSelector } from "./storeHooks";
import { useParams } from "react-router-dom";
import useInfiniteScroll from "./useInfinteScroll";
import { uniqBy } from "lodash";

const useGetSingleProductStockLevels = (
    warehouseId?: string,
    setDeleteWarehouseShow?: React.Dispatch<React.SetStateAction<boolean>>
) => {
    const dispatch = useAppDispatch();
    const { productId } = useParams();
    const {
        fetchingSingleProductStockLevels,
        fetchedSingleProductStockLevelsSuccess,
        fetchedSingleProductStockLevelsFailure,
        deleteWarehouse,
        deleteWarehouseSuccess,
    } = useAppSelector((state) => state.inventory);

    const [stockLevelData, setStockLevelData] = useState<{ [key: string]: any }[]>([]);
    const [pagination, setPagination] = useState<{ currentPage: string; noOfPages: string }>({
        currentPage: "1",
        noOfPages: "",
    });
    const { updatedLimit, isLoadingMore, setLoadMore, updatedPage, setUpdatedPage } =
        useInfiniteScroll(
            { current: pagination.currentPage, number_of_pages: pagination.noOfPages },
            20
        );

    const deleteProductWarehouse = () => {
        dispatch(inventoryActions.deleteWarehouse(warehouseId));
    };

    useEffect(() => {
        if (Boolean(deleteWarehouseSuccess)) {
            dispatch(inventoryActions.resetDeleteWarehouse());
            // dispatch(inventoryActions.fetchWarehouseList(12));
            setDeleteWarehouseShow?.(false);
        }
    }, [dispatch, deleteWarehouseSuccess]);

    useEffect(() => {
        dispatch(
            inventoryActions.fetchSingleProductStockLevel(
                productId,
                "",
                "",
                warehouseId,
                15,
                updatedPage
            )
        );

        return () => {
            dispatch(inventoryActions.resetFetchSingleProductStockLevels());
        };
    }, [dispatch, productId, warehouseId, updatedPage, deleteWarehouseSuccess]);

    useEffect(() => {
        if (Boolean(fetchedSingleProductStockLevelsSuccess)) {
            setStockLevelData((prev) =>
                updatedPage === 1
                    ? uniqBy([...fetchedSingleProductStockLevelsSuccess?.stocks], "_id")
                    : uniqBy([...prev, ...fetchedSingleProductStockLevelsSuccess?.stocks], "_id")
            );
            setPagination({
                currentPage: fetchedSingleProductStockLevelsSuccess.pagination?.current,
                noOfPages: fetchedSingleProductStockLevelsSuccess.pagination?.number_of_pages,
            });
            setLoadMore(false);
        }
    }, [fetchedSingleProductStockLevelsSuccess, updatedPage]);

    return {
        isFetching: fetchingSingleProductStockLevels,
        error: fetchedSingleProductStockLevelsFailure,
        data: stockLevelData,
        pagination,
        isLoadingMore,
        deleteWarehouseFunc: deleteProductWarehouse,
        isDeletingWarehouse: deleteWarehouse,
        setUpdatedPage,
    };
};

export default useGetSingleProductStockLevels;
