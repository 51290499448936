import { dashboardTypes } from "../types/dashboardTypes";

const initialState = {
    fetchingDashboardGWM: false,
    fetchedDashboardGWMSuccess: null,
    fetchedDashboardGWMFailure: null,
    fetchingSalesGWM: false,
    fetchedSalesGWMSuccess: null,
    fetchedSalesGWMFailure: null,
    fetchingSalesChannel: false,
    fetchedSalesChannelSuccess: null,
    fetchedSalesChannelFailure: null,
    fetchingDashboardAnalytics: false,
    fetchedDashboardAnalyticsSuccess: null,
    fetchedDashboardAnalyticsFailure: null,
    fetchingDashboardBusinessStats: false,
    fetchedDashboardBusinessStatsSuccess: null,
    fetchedDashboardBusinessStatsFailure: null,
    fetchingDashboardTopPerforming: false,
    fetchedDashboardTopPerformingSuccess: null,
    fetchedDashboardTopPerformingFailure: null,
    fetchingDashboardLeastPerforming: false,
    fetchedDashboardLeastPerformingSuccess: null,
    fetchedDashboardLeastPerformingFailure: null,
    fetchingTopOnHandProduct: false,
    fetchedTopOnHandProductSuccess: null,
    fetchedTopOnHandProductFailure: null,
    fetchingProductProfitMargin: false,
    fetchedProductProfitMarginSuccess: null,
    fetchedProductProfitMarginFailure: null,
    fetchingTopOnHandProductList: false,
    fetchedTopOnHandProductListSuccess: null,
    fetchedTopOnHandProductListFailure: null,
    fetchingProductProfitList: false,
    fetchedProductProfitListSuccess: null,
    fetchedProductProfitListFailure: null,
    fetchingDashboardConfigList: false,
    fetchedDashboardConfigListSuccess: null,
    fetchedDashboardConfigListFailure: null,
    addingForecastConfig: false,
    addForecastConfigSuccess: null,
    addForecastConfigFailure: null,
};

export const dashboard = (state = initialState, action: { type: string; payload: any }) => {
    switch (action.type) {
        case dashboardTypes.ADD_FORECAST_CONFIG_REQUEST:
            return {
                ...state,
                addingForecastConfig: true,
                addForecastConfigSuccess: null,
                addForecastConfigFailure: null,
            };
        case dashboardTypes.ADD_FORECAST_CONFIG_SUCCESS:
            return {
                ...state,
                addingForecastConfig: false,
                addForecastConfigSuccess: action.payload,
                addForecastConfigFailure: null,
            };
        case dashboardTypes.ADD_FORECAST_CONFIG_FAILURE:
            return {
                ...state,
                addingForecastConfig: false,
                addForecastConfigSuccess: null,
                addForecastConfigFailure: action.payload,
            };

        case dashboardTypes.FETCH_DASHBOARD_CONFIG_LIST_REQUEST:
            return {
                ...state,
                fetchingDashboardConfigList: true,
                fetchedDashboardConfigListSuccess: null,
                fetchedDashboardConfigListFailure: null,
            };
        case dashboardTypes.FETCH_DASHBOARD_CONFIG_LIST_SUCCESS:
            return {
                ...state,
                fetchingDashboardConfigList: false,
                fetchedDashboardConfigListSuccess: action.payload,
                fetchedDashboardConfigListFailure: null,
            };
        case dashboardTypes.FETCH_DASHBOARD_CONFIG_LIST_FAILURE:
            return {
                ...state,
                fetchingDashboardConfigList: false,
                fetchedDashboardConfigListSuccess: null,
                fetchedDashboardConfigListFailure: action.payload,
            };

        case dashboardTypes.FETCH_DASHBOARD_GMW_REQUEST:
            return {
                ...state,
                fetchingDashboardGWM: true,
                fetchedDashboardGWMSuccess: null,
                fetchedDashboardGWMFailure: null,
            };
        case dashboardTypes.FETCH_DASHBOARD_GMW_SUCCESS:
            return {
                ...state,
                fetchingDashboardGWM: false,
                fetchedDashboardGWMSuccess: action.payload,
                fetchedDashboardGWMFailure: null,
            };
        case dashboardTypes.FETCH_DASHBOARD_GMW_FAILURE:
            return {
                ...state,
                fetchingDashboardGWM: false,
                fetchedDashboardGWMSuccess: null,
                fetchedDashboardGWMFailure: action.payload,
            };

        case dashboardTypes.FETCH_SALES_GMW_REQUEST:
            return {
                ...state,
                fetchingSalesGWM: true,
                fetchedSalesGWMSuccess: null,
                fetchedSalesGWMFailure: null,
            };
        case dashboardTypes.FETCH_SALES_GMW_SUCCESS:
            return {
                ...state,
                fetchingSalesGWM: false,
                fetchedSalesGWMSuccess: action.payload,
                fetchedSalesGWMFailure: null,
            };
        case dashboardTypes.FETCH_SALES_GMW_FAILURE:
            return {
                ...state,
                fetchingSalesGWM: false,
                fetchedSalesGWMSuccess: null,
                fetchedSalesGWMFailure: action.payload,
            };

        case dashboardTypes.FETCH_SALES_CHANNEL_REQUEST:
            return {
                ...state,
                fetchingSalesChannel: true,
                fetchedSalesChannelSuccess: null,
                fetchedSalesChannelFailure: null,
            };
        case dashboardTypes.FETCH_SALES_CHANNEL_SUCCESS:
            return {
                ...state,
                fetchingSalesChannel: false,
                fetchedSalesChannelSuccess: action.payload,
                fetchedSalesChannelFailure: null,
            };
        case dashboardTypes.FETCH_SALES_CHANNEL_FAILURE:
            return {
                ...state,
                fetchingSalesChannel: false,
                fetchedSalesChannelSuccess: null,
                fetchedSalesChannelFailure: action.payload,
            };

        case dashboardTypes.FETCH_DASHBOARD_ANALYTICS_REQUEST:
            return {
                ...state,
                fetchingDashboardAnalytics: true,
                fetchedDashboardAnalyticsSuccess: null,
                fetchedDashboardAnalyticsFailure: null,
            };
        case dashboardTypes.FETCH_DASHBOARD_ANALYTICS_SUCCESS:
            return {
                ...state,
                fetchingDashboardAnalytics: false,
                fetchedDashboardAnalyticsSuccess: action.payload,
                fetchedDashboardAnalyticsFailure: null,
            };
        case dashboardTypes.FETCH_DASHBOARD_ANALYTICS_FAILURE:
            return {
                ...state,
                fetchingDashboardAnalytics: false,
                fetchedDashboardAnalyticsSuccess: null,
                fetchedDashboardAnalyticsFailure: action.payload,
            };
        case dashboardTypes.FETCH_DASHBOARD_BUSINESS_STATS_REQUEST:
            return {
                ...state,
                fetchingDashboardBusinessStats: true,
                fetchedDashboardBusinessStatsSuccess: null,
                fetchedDashboardBusinessStatsFailure: null,
            };
        case dashboardTypes.FETCH_DASHBOARD_BUSINESS_STATS_SUCCESS:
            return {
                ...state,
                fetchingDashboardBusinessStats: false,
                fetchedDashboardBusinessStatsSuccess: action.payload,
                fetchedDashboardBusinessStatsFailure: null,
            };
        case dashboardTypes.FETCH_DASHBOARD_BUSINESS_STATS_FAILURE:
            return {
                ...state,
                fetchingDashboardBusinessStats: false,
                fetchedDashboardBusinessStatsSuccess: null,
                fetchedDashboardBusinessStatsFailure: action.payload,
            };
        case dashboardTypes.FETCH_DASHBOARD_TOP_PERFORMING_REQUEST:
            return {
                ...state,
                fetchingDashboardTopPerforming: true,
                fetchedDashboardTopPerformingSuccess: null,
                fetchedDashboardTopPerformingFailure: null,
            };
        case dashboardTypes.FETCH_DASHBOARD_TOP_PERFORMING_SUCCESS:
            return {
                ...state,
                fetchingDashboardTopPerforming: false,
                fetchedDashboardTopPerformingSuccess: action.payload,
                fetchedDashboardTopPerformingFailure: null,
            };
        case dashboardTypes.FETCH_DASHBOARD_TOP_PERFORMING_FAILURE:
            return {
                ...state,
                fetchingDashboardTopPerforming: false,
                fetchedDashboardTopPerformingSuccess: null,
                fetchedDashboardTopPerformingFailure: action.payload,
            };
        case dashboardTypes.FETCH_DASHBOARD_LEAST_PERFORMING_REQUEST:
            return {
                ...state,
                fetchingDashboardLeastPerforming: true,
                fetchedDashboardLeastPerformingSuccess: null,
                fetchedDashboardLeastPerformingFailure: null,
            };
        case dashboardTypes.FETCH_DASHBOARD_LEAST_PERFORMING_SUCCESS:
            return {
                ...state,
                fetchingDashboardLeastPerforming: false,
                fetchedDashboardLeastPerformingSuccess: action.payload,
                fetchedDashboardLeastPerformingFailure: null,
            };
        case dashboardTypes.FETCH_DASHBOARD_LEAST_PERFORMING_FAILURE:
            return {
                ...state,
                fetchingDashboardLeastPerforming: false,
                fetchedDashboardLeastPerformingSuccess: null,
                fetchedDashboardLeastPerformingFailure: action.payload,
            };

        case dashboardTypes.FETCH_DASHBOARD_TOP_ON_HAND_PRODUCT_REQUEST:
            return {
                ...state,
                fetchingTopOnHandProduct: true,
                fetchedTopOnHandProductSuccess: null,
                fetchedTopOnHandProductFailure: null,
            };
        case dashboardTypes.FETCH_DASHBOARD_TOP_ON_HAND_PRODUCT_SUCCESS:
            return {
                ...state,
                fetchingTopOnHandProduct: false,
                fetchedTopOnHandProductSuccess: action.payload,
                fetchedTopOnHandProductFailure: null,
            };
        case dashboardTypes.FETCH_DASHBOARD_TOP_ON_HAND_PRODUCT_FAILURE:
            return {
                ...state,
                fetchingTopOnHandProduct: false,
                fetchedTopOnHandProductSuccess: null,
                fetchedTopOnHandProductFailure: action.payload,
            };
        case dashboardTypes.FETCH_DASHBOARD_PRODUCT_PROFIT_MARGIN_REQUEST:
            return {
                ...state,
                fetchingProductProfitMargin: true,
                fetchedProductProfitMarginSuccess: null,
                fetchedProductProfitMarginFailure: null,
            };
        case dashboardTypes.FETCH_DASHBOARD_PRODUCT_PROFIT_MARGIN_SUCCESS:
            return {
                ...state,
                fetchingProductProfitMargin: false,
                fetchedProductProfitMarginSuccess: action.payload,
                fetchedProductProfitMarginFailure: null,
            };
        case dashboardTypes.FETCH_DASHBOARD_PRODUCT_PROFIT_MARGIN_FAILURE:
            return {
                ...state,
                fetchingProductProfitMargin: false,
                fetchedProductProfitMarginSuccess: null,
                fetchedProductProfitMarginFailure: action.payload,
            };
        case dashboardTypes.FETCH_DASHBOARD_TOP_ON_HAND_PRODUCT_LIST_REQUEST:
            return {
                ...state,
                fetchingTopOnHandProductList: true,
                fetchedTopOnHandProductListSuccess: null,
                fetchedTopOnHandProductListFailure: null,
            };
        case dashboardTypes.FETCH_DASHBOARD_TOP_ON_HAND_PRODUCT_LIST_SUCCESS:
            return {
                ...state,
                fetchingTopOnHandProductList: false,
                fetchedTopOnHandProductListSuccess: action.payload,
                fetchedTopOnHandProductListFailure: null,
            };
        case dashboardTypes.FETCH_DASHBOARD_TOP_ON_HAND_PRODUCT_LIST_FAILURE:
            return {
                ...state,
                fetchingTopOnHandProductList: false,
                fetchedTopOnHandProductListSuccess: null,
                fetchedTopOnHandProductListFailure: action.payload,
            };
        case dashboardTypes.FETCH_DASHBOARD_PRODUCT_PROFIT_LIST_REQUEST:
            return {
                ...state,

                fetchingProductProfitList: true,
                fetchedProductProfitListSuccess: null,
                fetchedProductProfitListFailure: null,
            };
        case dashboardTypes.FETCH_DASHBOARD_PRODUCT_PROFIT_LIST_SUCCESS:
            return {
                ...state,
                fetchingProductProfitList: false,
                fetchedProductProfitListSuccess: action.payload,
                fetchedProductProfitListFailure: null,
            };
        case dashboardTypes.FETCH_DASHBOARD_PRODUCT_PROFIT_LIST_FAILURE:
            return {
                ...state,
                fetchingProductProfitList: false,
                fetchedProductProfitListSuccess: null,
                fetchedProductProfitListFailure: action.payload,
            };

        default:
            return state;
    }
};
