import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import store from "./redux/store";
import { Provider } from "react-redux";
import * as Sentry from "@sentry/react";

// Sentry.init({
//     dsn: "https://82a1badc0f3d7423ed72895a2d5aeee9@o4505398718693376.ingest.us.sentry.io/4507225878757376",
//     integrations: [
//         Sentry.browserTracingIntegration(),
//         Sentry.replayIntegration({
//             // Additional SDK configuration goes in here, for example:
//             maskAllText: true,
//             blockAllMedia: true,
//         }),
//     ],
//     // Performance Monitoring
//     tracesSampleRate: 1.0, //  Capture 100% of the transactions
//     // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//     tracePropagationTargets: [
//         "localhost",
//         /^https:\/\/staging\.heysynth\.com\//,
//         /^https:\/\/beta\.heysynth\.com\//,
//         /^https:\/\/heysynth\.com\//,
//     ],

//     // Session Replay
//     replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//     replaysOnErrorSampleRate: 1.0,
//     // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// });
const rootElement = document.getElementById("root");

if (rootElement) {
    const root = ReactDOM.createRoot(rootElement);
    root.render(
        // <React.StrictMode>
        <Provider store={store}>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </Provider>
        // </React.StrictMode>
    );
} else {
    console.error("Root element not found");
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
