import { businessAnalyticsType } from "redux/Ldb/types";

const initialState = {
    gettingPurchaseOrderSummary: false,
    getPurchaseOrderSummarySuccess: null,
    getPurchaseOrderSummaryFailure: null,
    gettingPurchaseOrder: false,
    getPurchaseOrderSuccess: null,
    getPurchaseOrderFailure: null,
    gettingSpendAnalytics: false,
    getSpendAnalyticsSuccess: null,
    getSpendAnalyticsFailure: null,
    fetchingProcurementAnalytics: false,
    fetchedProcurementAnalyticsSuccess: null,
    fetchedProcurementAnalyticsFailure: null,
    fetchingProcurementAnalyticsGraph: false,
    fetchedProcurementAnalyticsGraphSuccess: null,
    fetchedProcurementAnalyticsGraphFailure: null,
    fetchingProcurementAnalyticsInsight: false,
    fetchedProcurementAnalyticsInsightSuccess: null,
    fetchedProcurementAnalyticsInsightFailure: null,
    fetchingSpendAnalysisCatAndSup: false,
    fetchedSpendAnalysisCatAndSupRequest: null,
    fetchedSpendAnalysisCatAndSupSuccess: null
};

export const businessAnalytics = (state = initialState, action: {type: string, payload: any}) => {
    switch (action.type) {
        case businessAnalyticsType.GET_PURCHASE_ORDER_SUMMARY_REQUEST:
            return {
                ...state,
                gettingPurchaseOrderSummary: true,
                getPurchaseOrderSuccess: null,
                getPurchaseOrderFailure: null,
            };
        case businessAnalyticsType.GET_PURCHASE_ORDER_SUMMARY_SUCCESS:
            return {
                ...state,
                gettingPurchaseOrderSummary: false,
                getPurchaseOrderSummarySuccess: action.payload,
                getPurchaseOrderSummaryFailure: null,
            };
        case businessAnalyticsType.GET_PURCHASE_ORDER_SUMMARY_FAILURE:
            return {
                ...state,
                gettingPurchaseOrderSummary: false,
                getPurchaseOrderSummarySuccess: null,
                getPurchaseOrderSummaryFailure: action.payload,
            };

        case businessAnalyticsType.GET_PURCHASE_REQUEST:
            return {
                ...state,
                gettingPurchaseOrder: true,
                getPurchaseOrderSuccess: null,
                getPurchaseOrderFailure: null,
            };
        case businessAnalyticsType.GET_PURCHASE_SUCCESS:
            return {
                ...state,
                gettingPurchaseOrder: false,
                getPurchaseOrderSuccess: action.payload,
                getPurchaseOrderFailure: null,
            };
        case businessAnalyticsType.GET_PURCHASE_FAILURE:
            return {
                ...state,
                gettingPurchaseOrder: false,
                getPurchaseOrderSuccess: null,
                getPurchaseOrderFailure: action.payload,
            };

        case businessAnalyticsType.GET_SPEND_ANALYTICS_REQUEST:
            return {
                ...state,
                gettingSpendAnalytics: true,
                getSpendAnalyticsSuccess: null,
                getSpendAnalyticsFailure: null,
            };
        case businessAnalyticsType.GET_SPEND_ANALYTICS_SUCCESS:
            return {
                ...state,
                gettingSpendAnalytics: false,
                getSpendAnalyticsSuccess: action.payload,
                getSpendAnalyticsFailure: null,
            };
        case businessAnalyticsType.GET_SPEND_ANALYTICS_FAILURE:
            return {
                ...state,
                getSpendAnalytics: false,
                getSpendAnalyticsSuccess: null,
                getSpendAnalyticsFailure: action.payload,
            };
        case businessAnalyticsType.FETCH_ANALYTICS_PROCUREMENT_REQUEST:
            return {
                ...state,
                fetchingProcurementAnalytics: true,
                fetchedProcurementAnalyticsSuccess: null,
                fetchedProcurementAnalyticsFailure: null,
            };
        case businessAnalyticsType.FETCH_ANALYTICS_PROCUREMENT_SUCCESS:
            return {
                ...state,
                fetchingProcurementAnalytics: false,
                fetchedProcurementAnalyticsSuccess: action.payload,
                fetchedProcurementAnalyticsFailure: null,
            };
        case businessAnalyticsType.FETCH_ANALYTICS_PROCUREMENT_FAILURE:
            return {
                ...state,
                fetchingProcurementAnalytics: false,
                fetchedProcurementAnalyticsSuccess: null,
                fetchedProcurementAnalyticsFailure: action.payload,
            };

        case businessAnalyticsType.FETCH_ANALYTICS_PROCUREMENT_GRAPH_REQUEST:
            return {
                ...state,
                fetchingProcurementAnalyticsGraph: true,
                fetchedProcurementAnalyticsGraphSuccess: null,
                fetchedProcurementAnalyticsGraphFailure: null,
            };
        case businessAnalyticsType.FETCH_ANALYTICS_PROCUREMENT_GRAPH_SUCCESS:
            return {
                ...state,
                fetchingProcurementAnalyticsGraph: false,
                fetchedProcurementAnalyticsGraphSuccess: action.payload,
                fetchedProcurementAnalyticsGraphFailure: null,
            };
        case businessAnalyticsType.FETCH_ANALYTICS_PROCUREMENT_GRAPH_FAILURE:
            return {
                ...state,
                fetchingProcurementAnalyticsGraph: false,
                fetchedProcurementAnalyticsGraphSuccess: null,
                fetchedProcurementAnalyticsGraphFailure: action.payload,
            };

        case businessAnalyticsType.FETCH_ANALYTICS_PROCUREMENT_INSIGHT_REQUEST:
            return {
                ...state,
                fetchingProcurementAnalyticsInsight: true,
                fetchedProcurementAnalyticsInsightSuccess: null,
                fetchedProcurementAnalyticsInsightFailure: null,
            };
        case businessAnalyticsType.FETCH_ANALYTICS_PROCUREMENT_INSIGHT_SUCCESS:
            return {
                ...state,
                fetchingProcurementAnalyticsInsight: false,
                fetchedProcurementAnalyticsInsightSuccess: action.payload,
                fetchedProcurementAnalyticsInsightFailure: null,
            };
        case businessAnalyticsType.FETCH_ANALYTICS_PROCUREMENT_INSIGHT_FAILURE:
            return {
                ...state,
                fetchingProcurementAnalyticsInsight: false,
                fetchedProcurementAnalyticsInsightSuccess: null,
                fetchedProcurementAnalyticsInsightFailure: action.payload,
            };
        case businessAnalyticsType.FETCH_SPEND_ANALYSIS_CATEGORY_AND_SUPPLIER_REQUEST:
            return {
                ...state,
                fetchingSpendAnalysisCatAndSup: true,
                fetchedSpendAnalysisCatAndSupSuccess: null,
                fetchedSpendAnalysisCatAndSupFailure: null,
            };
        case businessAnalyticsType.FETCH_SPEND_ANALYSIS_CATEGORY_AND_SUPPLIER_SUCCESS:
            return {
                ...state,
                fetchingSpendAnalysisCatAndSup: false,
                fetchedSpendAnalysisCatAndSupSuccess: action.payload,
                fetchedSpendAnalysisCatAndSupFailure: null,
            };
        case businessAnalyticsType.FETCH_SPEND_ANALYSIS_CATEGORY_AND_SUPPLIER_FAILURE:
            return {
                ...state,
                fetchingSpendAnalysisCatAndSup: false,
                fetchedSpendAnalysisCatAndSupSuccess: null,
                fetchedSpendAnalysisCatAndSupFailure: action.payload,
            };
        default:
            return state;
    }
};
