/* eslint-disable */
import React, { useCallback, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import { inventoryActions } from "redux/Ldb/actions";
import { onScroll } from "helpers";
import moment from "moment";
import Loader from "components/common/Loader";
import VendorAvatar from "components/common/VendorAvatar";
import Button from "components/common/Button";
import { useNavigate, useSearchParams } from "react-router-dom";
import { truncate, uniqBy } from "lodash";
import CustomTable from "components/common/CustomTable";
import CustomMobileTable from "components/common/CustomMobileTable";
import { Tooltip } from "react-tooltip";

type StockProps = {
    debouncedSearch?: string;
    warehouse?: string;
    warehouseId?: string;
    stockCategory?: string;
};

const StockAdjustmentList = ({
    debouncedSearch,
    warehouse,
    warehouseId,
    stockCategory,
}: StockProps) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [param] = useSearchParams();
    const currTab = param.get("currTab");
    const limit = 10;
    const [page, setPage] = useState<number>(1);
    const [isScrolled, setIsScrolled] = useState<boolean>(false);
    const [stockAdjustList, setStockAdjustList] = useState([]);
    const [loadMore, setLoadMore] = useState<boolean>(false);
    const [paginate, setPaginate] = useState<{
        current: number | string;
        number_of_pages: number | string;
    }>({
        current: "",
        number_of_pages: "",
    });

    const { fetchingStockAdjustmentList, fetchedStockAdjustmentSuccess } = useAppSelector(
        (state) => state.inventory
    );

    useEffect(() => {
        if (Boolean(fetchedStockAdjustmentSuccess)) {
            setStockAdjustList((prev) =>
                page > 1
                    ? uniqBy([...prev, ...fetchedStockAdjustmentSuccess?.stock], "_id")
                    : [...fetchedStockAdjustmentSuccess?.stock]
            );
            setPaginate({
                current: fetchedStockAdjustmentSuccess?.pagination?.current,
                number_of_pages: fetchedStockAdjustmentSuccess?.pagination?.number_of_pages,
            });
        }
    }, [fetchedStockAdjustmentSuccess]);

    useEffect(() => {
        dispatch(
            inventoryActions.fetchStockAdjustmentList(
                limit,
                page,
                debouncedSearch,
                warehouseId,
                stockCategory
            )
        );
    }, [dispatch, page, debouncedSearch, warehouseId, stockCategory]);

    useEffect(() => {
        if (debouncedSearch || warehouse || stockCategory) {
            setPage(1);
        }
    }, [debouncedSearch, warehouse, stockCategory]);
    const scrollTable = document.getElementById("tableContent");

    const onTableScroll = useCallback(
        () =>
            onScroll(
                scrollTable,
                paginate?.current as number,
                paginate?.number_of_pages as number,
                () => {
                    setPage(() => page + 1);
                    setLoadMore(true);
                }
            ),
        [scrollTable, paginate]
    );
    useEffect(() => {
        scrollTable?.addEventListener("scroll", onTableScroll);
        return () => {
            scrollTable?.removeEventListener("scroll", onTableScroll);
        };
    }, [scrollTable, onTableScroll]);

    const handleScroll = (e) => {
        if (e.target.scrollLeft > 0) {
            setIsScrolled(true);
        } else {
            setIsScrolled(false);
        }
    };

    const tableHeader = [
        {
            title: "Adjustment",
            widthClass: "!border-b !border-slate-200 w-[150px]",
            itemClass: "",
        },
        {
            title: "Product name",
            widthClass: "!border-b !border-slate-200 w-[200px]",
            itemClass: "",
        },
        {
            title: "Warehouse",
            widthClass: "!border-b !border-slate-200 w-[202px]",
            itemClass: "",
        },
        {
            title: "Adjusted by",
            widthClass: "!border-b !border-slate-200 w-[185px]",
            itemClass: "",
        },
        {
            title: "Reason",
            widthClass: "!border-b !border-slate-200 w-[120px]",
            itemClass: "",
        },

        // {
        //     title: "Approved by",
        //     widthClass: "!border-b !border-slate-200 w-[185px]",
        //     itemClass: "",
        // },
    ];

    const tableBody =
        stockAdjustList?.length > 0
            ? stockAdjustList?.map((item, idx) => [
                  {
                      content: (
                          <div className="py-4" key={idx}>
                              <p className="text-[#142837]"> {item?.reference}</p>
                              <p className="text-[#64748B] text-sm">
                                  {item?.movementDate
                                      ? moment(item?.movementDate).format("MMM DD, YYYY • h:mm A")
                                      : "N/A"}
                              </p>
                          </div>
                      ),
                      widthClass: "!border-b !border-slate-200",
                      cellClickAction: () =>
                          navigate(
                              `/dashboard/inventory/stock-control/stock-adjustment/${item?._id}?currTab=${currTab}`
                          ),
                  },
                  {
                      content: (
                          <div className="py-4 flex items-center gap-3">
                              <div className="flex gap-3 items-center">
                                  <div className="w-8 h-8">
                                      <img
                                          src={item?.inventory?.productImageDetails?.productAvatar}
                                          alt=""
                                          className=""
                                      />
                                  </div>

                                  <div>
                                      <a data-tooltip-id={`product-column-${idx}`}>
                                          <p className="text-[#142837]">
                                              {truncate(item?.inventory?.productName, {
                                                  length: 18,
                                              })}
                                          </p>
                                          <p className="text-[#64748B]">
                                              {truncate(item?.batchId, { length: 18 })}
                                          </p>
                                      </a>

                                      <Tooltip
                                          id={`product-column-${idx}`}
                                          place={"bottom"}
                                          style={{ width: "180px", zIndex: 9999 }}
                                      >
                                          <p className="">{item?.inventory?.productName}</p>
                                          <p className="">{item?.batchId}</p>
                                      </Tooltip>
                                  </div>
                              </div>
                          </div>
                      ),
                      widthClass: "!border-b !border-slate-200",
                  },
                  {
                      content: (
                          <div className="py-4 flex items-center gap-2">
                              <p className="text-[#0F172A]">
                                  {truncate(item?.warehouse[0]?.warehouseName, { length: 22 })}
                              </p>
                          </div>
                      ),
                      widthClass: "!border-b !border-slate-200",
                  },
                  {
                      content: (
                          <div className="py-4 flex items-center gap-3">
                              {item?.creator[0]?.profile?.avatar && (
                                  <VendorAvatar
                                      imageSrc={item?.creator[0]?.profile?.avatar}
                                      name={item?.creator[0]?.profile?.businessName}
                                      size={32}
                                  />
                              )}

                              {item?.creator[0]?.fullName}
                          </div>
                      ),
                      widthClass: "!border-b !border-slate-200",
                  },

                  {
                      content: (
                          <div className="py-4">
                              <p className="capitalize">{item?.movementCategory}</p>
                          </div>
                      ),
                      widthClass: "!border-b !border-slate-200",
                  },
                  //   {
                  //       content: (
                  //           <div className="py-[18px]">
                  //               <p className="">

                  //               </p>
                  //           </div>
                  //       ),
                  //       widthClass: "!border-b !border-slate-200",
                  //   },

                  //   {
                  //       content: (
                  //           <div className="py-[18px] pr-4">
                  //               <p className="">{truncate(item?.notes, { length: 40 }) || "N/A"}</p>
                  //           </div>
                  //       ),
                  //       widthClass: "!border-b !border-slate-200",
                  //   },
              ])
            : [];

    const mobileTableBody = stockAdjustList?.map((item) => {
        return {
            cellClickAction: () =>
                navigate(
                    `/dashboard/inventory/stock-control/stock-adjustment/${item?._id}?currTab=${currTab}`
                ),
            topString: item?._id,
            avatar: item?.inventory?.productImageDetails?.productAvatar ? (
                <div className="w-8 h-8">
                    <img
                        src={item?.inventory?.productImageDetails?.productAvatar}
                        alt=""
                        className="object-contain h-full w-full"
                    />
                </div>
            ) : (
                <div className="w-8 h-8 rounded flex items-center justify-center bg-slate-200 ">
                    <i className="ri-box-2-fill text-lg text-slate-500"></i>
                </div>
            ),
            topContent: (
                <div>
                    <div className="">
                        <p className="text-sm font-rocGroteskMedium"> {item?.inventory?.sku}</p>
                        <p className="text-sm font-rocGroteskMedium text-slate-500">
                            {truncate(item?.inventory?.productName, {
                                length: 25,
                            })}
                        </p>
                    </div>
                </div>
            ),
            bottomContent: (
                <div className="flex items-center text-sm font-rocGroteskMedium space-x-1 mt-1">
                    <div
                        className={`py-3 pr-3 font-rocGroteskMedium border-slate-200 h-[21px] border rounded px-2 text-xs text-slate-500 flex items-center justify-center`}
                    >
                        <span>{truncate(item?.warehouse[0]?.warehouseName, { length: 10 })}</span>
                    </div>

                    <div
                        className={`py-3 pr-3 font-rocGroteskMedium border-slate-200 h-[21px] border rounded px-2 text-xs text-slate-500 flex items-center justify-center`}
                    >
                        <div className="py-4 flex items-center gap-3">
                            {item?.creator[0]?.profile?.avatar && (
                                <VendorAvatar
                                    imageSrc={item?.creator[0]?.profile?.avatar}
                                    name={item?.creator[0]?.profile?.businessName}
                                    size={16}
                                />
                            )}

                            {truncate(item?.creator[0]?.fullName, { length: 10 })}
                        </div>
                    </div>

                    <div
                        className={`py-3 pr-3 font-rocGroteskMedium border-slate-200 h-[21px] border rounded px-2 text-xs text-slate-500 flex items-center justify-center`}
                    >
                        <span className="capitalize">
                            {truncate(item?.movementCategory, { length: 10 })}
                        </span>
                    </div>
                </div>
            ),
        };
    });

    return (
        <>
            {fetchingStockAdjustmentList && stockAdjustList?.length === 0 ? (
                <div className={`w-full min-h-[30vh]  flex items-center  justify-center`}>
                    <Loader color={"gm-50"} size={8} />
                </div>
            ) : (
                <div>
                    {stockAdjustList?.length > 0 ? (
                        <div>
                            <div
                                className="overflow-x-scroll max-lg:hidden"
                                onScroll={handleScroll}
                            >
                                <CustomTable
                                    tableBody={tableBody}
                                    tableHeader={tableHeader}
                                    isScrollable={false}
                                    // isScrolled={isScrolled}
                                    isCellBordered={false}
                                    isCellSelectable={false}
                                    isAllSelectable={false}
                                    headerItemClass="font-rocGroteskMedium !text-slate-700 !py-[11px] !text-xs"
                                    headerContainerClass="!bg-slate-50"
                                    tableClass=" w-full !font-rocGroteskMedium cursor-pointer"
                                    tbodyClass="!text-sm !text-slate-900"
                                    bodyItemClass="hover:!bg-slate-50"
                                />
                            </div>

                            <div className="hidden max-lg:block">
                                <CustomMobileTable data={mobileTableBody} />
                            </div>

                            {loadMore && fetchingStockAdjustmentList && (
                                <div className="flex my-4 justify-center">
                                    <Loader color="gm-25" size={4} />
                                </div>
                            )}
                            {paginate?.current === paginate?.number_of_pages && (
                                <div className="flex my-4 text-slate-500  justify-center text-sm font-rocGroteskMedium">
                                    End of list
                                </div>
                            )}
                        </div>
                    ) : (
                        <div className=" min-h-[30vh] my text-slate-500  flex items-center  justify-center text-sm font-rocGroteskMedium">
                            {debouncedSearch || stockCategory || warehouse ? (
                                <p className="">"No data matches your search query"</p>
                            ) : (
                                <div className="w-full flex justify-center">
                                    <div
                                        className="flex mt-[85px] p-10 rounded-lg border border-slate-100 text-slate-500
                        items-start gap-6 font-rocGroteskMedium max-w-[635px] shadow-tableNoDataShadow"
                                    >
                                        <img
                                            src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1705927221/bar-chart-fill_v4lcot.svg"
                                            alt="no data "
                                            className=""
                                        />
                                        <div className="">
                                            <p className="text-black text-base  pb-[9px]">
                                                No stock adjustments have been made.
                                            </p>
                                            <p className="text-sm text-slate-500 pb-6">
                                                Keep your inventory precise by recording stock
                                                adjustments when necessary. Begin a new adjustment
                                                to ensure accurate tracking.
                                            </p>
                                            <Button
                                                btnText="Adjust your stock"
                                                btnClassname="!py-[10px]  !text-black !text-[13px] !w-auto !bg-[#ffb903]"
                                                onClick={() =>
                                                    navigate(
                                                        "/dashboard/inventory/stock-control/stock-adjustment"
                                                    )
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            )}
        </>
    );
};

export default StockAdjustmentList;
