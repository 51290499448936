import { useEffect, useState } from "react";
import { salesActions } from "redux/Ldb/actions";
import { useAppDispatch, useAppSelector } from "../storeHooks";
import { useParams } from "react-router-dom";
import { debounce } from "lodash";

const useGetCustomerList = () => {
    const dispatch = useAppDispatch();
    const { fetchingCustomerList, fetchCustomerListSuccess, fetchCustomerListFailure } =
        useAppSelector((state) => state.sales);

    const [customerData, setCustomerData] = useState<{ [key: string]: any }[]>([]);
    const [debounceSearch, setDebounceSearch] = useState<string>("");

    const handleDebounceSearch = debounce((e) => {
        setDebounceSearch(e.target.value);
    }, 800);

    useEffect(() => {
        dispatch(salesActions.getCustomerList(1, 20, debounceSearch));

        return () => {
            dispatch(salesActions.resetGetCustomerListSuccess());
        };
    }, [dispatch, debounceSearch]);

    useEffect(() => {
        if (Boolean(fetchCustomerListSuccess)) {
            setCustomerData(fetchCustomerListSuccess?.customers);
        }
    }, [dispatch, fetchCustomerListSuccess]);

    return {
        isFetching: fetchingCustomerList,
        error: fetchCustomerListFailure,
        data: customerData,
        setCustomerData,
        handleDebounceSearch,
    };
};

export default useGetCustomerList;
