import React, { useEffect, useState } from "react";
import PreviewSku from "./Steps/PreviewSku";
import CreateSku from "./Steps/CreateSku";
import useGetProductList from "hooks/inventoryHooks/useGetProductList";
import useGetMappedSkuProductList from "hooks/inventoryHooks/useGetMappedSkuProductList";

const SkuCreation = () => {
    const [activeStep, setActiveStep] = useState<number>(1);
    const [selectedProducts, setSelectedProducts] = useState<{ [key: string]: any }[]>([]);
    const [skuNumber, setSkuNumber] = useState<string>("");
    const [masterChannel, setMasterChannel] = useState<string>("");
    const [masterSkuId, setMasterSkuId] = useState<string>("");
    const [disabledChannels, setDisabledChannels] = useState([]);

    const {
        data,
        isFetching,
        pagination,
        total,
        handleFilterChange,
        setTracking,
        search,
        handleSearch,
        handleDebouncedChange,
        isFetchingSecondList,
        productSearchList,
        handleDebouncedChangeTwo,
        isLoadingMore,
    } = useGetMappedSkuProductList(masterChannel);

    useEffect(() => {
        // handleFilterChange(`Finished Goods`);
        setTracking(false);
    }, []);

    const displayActiveStep = () => {
        switch (activeStep) {
            case 1:
                return (
                    <CreateSku
                        step={activeStep}
                        setStep={setActiveStep}
                        selectedProducts={selectedProducts}
                        setSelectedProducts={setSelectedProducts}
                        setSkuNumber={setSkuNumber}
                        skuNumber={skuNumber}
                        data={data}
                        isFetching={isFetching}
                        total={total}
                        pagination={pagination}
                        disabledChannels={disabledChannels}
                        setDisabledChannels={setDisabledChannels}
                        search={search}
                        handleSearch={handleSearch}
                        handleDebouncedChange={handleDebouncedChange}
                        masterSkuList={productSearchList}
                        handleDebouncedChangeTwo={handleDebouncedChangeTwo}
                        isFetchingMasterSkuList={isFetchingSecondList}
                        setMasterChannel={setMasterChannel}
                        setMasterSkuId={setMasterSkuId}
                        isLoadingMore={isLoadingMore}
                    />
                );

            case 2:
                return (
                    <PreviewSku
                        step={activeStep}
                        setStep={setActiveStep}
                        selectedProducts={selectedProducts}
                        skuNumber={skuNumber}
                        setSelectedProducts={setSelectedProducts}
                        masterSkuId={masterSkuId}
                    />
                );

            default:
                return (
                    <CreateSku
                        step={activeStep}
                        setStep={setActiveStep}
                        selectedProducts={selectedProducts}
                        setSelectedProducts={setSelectedProducts}
                        setSkuNumber={setSkuNumber}
                        skuNumber={skuNumber}
                        data={data}
                        isFetching={isFetching}
                        total={total}
                        pagination={pagination}
                        disabledChannels={disabledChannels}
                        setDisabledChannels={setDisabledChannels}
                        masterSkuList={productSearchList}
                        handleDebouncedChangeTwo={handleDebouncedChangeTwo}
                        isFetchingMasterSkuList={isFetchingSecondList}
                        setMasterChannel={setMasterChannel}
                        setMasterSkuId={setMasterSkuId}
                        isLoadingMore={isLoadingMore}
                    />
                );
        }
    };

    return <>{displayActiveStep()}</>;
};

export default SkuCreation;
