import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { productionActions } from "redux/Ldb/actions";

const useGetSingleBOM = () => {
    const { id } = useParams();
    const dispatch = useAppDispatch();
    const [debouncedSearch, setDebouncedSearch] = useState<string>("");
    const [bomDetails, setBomDetails] = useState<{ [key: string]: any }>({});

    const { fetchingSingleBOM, fetchedSingleBOMFailure, fetchedSingleBOMSuccess } = useAppSelector(
        (state) => state.production
    );

    useEffect(() => {
        if (Boolean(fetchedSingleBOMSuccess)) {
            setBomDetails(fetchedSingleBOMSuccess?.product);
        }
    }, [fetchedSingleBOMSuccess]);

    useEffect(() => {
        if (id) {
            dispatch(productionActions.getSingleBOM(id));
        }
    }, [dispatch, id]);

    return {
        data: bomDetails,
        isLoading: fetchingSingleBOM,
    };
};

export default useGetSingleBOM;
