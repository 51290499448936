export const customFieldTypes = {
    CREATE_CUSTOM_FIELD_REQUEST: "CREATE_CUSTOM_FIELD_REQUEST",
    CREATE_CUSTOM_FIELD_SUCCESS: "CREATE_CUSTOM_FIELD_SUCCESS",
    CREATE_CUSTOM_FIELD_FAILURE: "CREATE_CUSTOM_FIELD_FAILURE",
    FETCH_CUSTOM_FIELD_REQUEST: "FETCH_CUSTOM_FIELD_REQUEST",
    FETCH_CUSTOM_FIELD_SUCCESS: "FETCH_CUSTOM_FIELD_SUCCESS",
    FETCH_CUSTOM_FIELD_FAILURE: "FETCH_CUSTOM_FIELD_FAILURE",
    DELETE_CUSTOM_FIELD_REQUEST: "DELETE_CUSTOM_FIELD_REQUEST",
    DELETE_CUSTOM_FIELD_SUCCESS: "DELETE_CUSTOM_FIELD_SUCCESS",
    DELETE_CUSTOM_FIELD_FAILURE: "DELETE_CUSTOM_FIELD_FAILURE",
    UPDATE_CUSTOM_FIELD_REQUEST: "UPDATE_CUSTOM_FIELD_REQUEST",
    UPDATE_CUSTOM_FIELD_SUCCESS: "UPDATE_CUSTOM_FIELD_SUCCESS",
    UPDATE_CUSTOM_FIELD_FAILURE: "UPDATE_CUSTOM_FIELD_FAILURE",
};
