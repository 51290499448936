import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { inventoryActions } from "redux/Ldb/actions";
import { useAppDispatch, useAppSelector } from "../storeHooks";

const useGetProductDetails = () => {
    const { productId } = useParams();
    const dispatch = useAppDispatch();
    const [productDetails, setProductDetails] = useState<{ [key: string]: any }>({});

    const {
        fetchingSingleInventory,
        fetchedSingleInventorySuccess,
        fetchedSingleInventoryFailure,
    } = useAppSelector((state) => state.inventory);

    useEffect(() => {
        if (Boolean(fetchedSingleInventorySuccess)) {
            setProductDetails(fetchedSingleInventorySuccess?.inventory);
        }
    }, [fetchedSingleInventorySuccess]);
    useEffect(() => {
        if (productId) {
            dispatch(inventoryActions.fetchSingleInventory(productId));
        }
    }, [dispatch, productId]);
    return {
        isFetching: fetchingSingleInventory,
        error: fetchedSingleInventoryFailure,
        data: productDetails,
    };
};

export default useGetProductDetails;
