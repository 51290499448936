import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../storeHooks";
import { trackTraceActions } from "redux/Ldb/actions";
import { useParams } from "react-router-dom";
import useInfiniteScroll from "hooks/useInfinteScroll";

const useGetTrackTraceBatchCustomers = () => {
    const { id } = useParams();
    const dispatch = useAppDispatch();
    const [batchCustomers, setBatchCustomers] = useState<{ [key: string]: any }>([]);
    const [state, setState] = useState<string>("");
    const [pagination, setPagination] = useState<{ currentPage: string; noOfPages: string }>({
        currentPage: "1",
        noOfPages: "",
    });

    const { updatedLimit, isLoadingMore, setLoadMore } = useInfiniteScroll(
        { current: pagination.currentPage, number_of_pages: pagination.noOfPages },
        10
    );

    const { fetchingBatchCustomers, fetchBatchCustomersSuccess, fetchBatchCustomersFailure } =
        useAppSelector((state) => state.trackTrace);

    const handleStateChange = (value) => {
        setState(value);
    };

    useEffect(() => {
        if (Boolean(fetchBatchCustomersSuccess)) {
            setBatchCustomers(fetchBatchCustomersSuccess?.customers);
            setPagination({
                currentPage: fetchBatchCustomersSuccess.pagination?.current,
                noOfPages: fetchBatchCustomersSuccess.pagination?.number_of_pages,
            });
            setLoadMore(false);
        }
    }, [fetchBatchCustomersSuccess]);

    useEffect(() => {
        if (id && state) {
            dispatch(trackTraceActions.getBatchCustomers(state, id, updatedLimit));
        }
    }, [dispatch, id, state, updatedLimit]);

    return {
        data: batchCustomers,
        isFetching: fetchingBatchCustomers,
        error: fetchBatchCustomersFailure,
        handleStateChange,
        state,
        isLoadingMore
    };
};

export default useGetTrackTraceBatchCustomers;
