import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../storeHooks";
import { workforceActions } from "../../redux/Ldb/actions/workforceActions";
import useInfiniteScroll from "hooks/useInfinteScroll";
import { debounce } from "lodash";

const useGetAiTeammates = () => {
    const dispatch = useAppDispatch();

    const [search, setSearch] = useState("");
    const [debouncedSearch, setDebouncedSearch] = useState("");
    const [aiTeammates, setAiTeammates] = useState<{ [key: string]: any }[]>([]);
    const [pagination, setPagination] = useState<{ currentPage: string; noOfPages: string }>({
        currentPage: "1",
        noOfPages: "",
    });
    const [selectedTeammate, setSelectedTeammate] = useState<{ name?: string; value: string }>({
        name: "",
        value: "",
    });

    const { fetchingAiTeammates, fetchAiTeammatesSuccess, fetchAiTeammatesFailure } =
        useAppSelector((state) => state.workforce);

    const { updatedLimit, isLoadingMore, setLoadMore, updatedPage, setUpdatedPage } =
        useInfiniteScroll(
            {
                current: pagination.currentPage,
                number_of_pages: pagination.noOfPages,
            },
            14
        );

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(e.target.value);
    };

    const handleDebouncedChange = debounce((e: React.ChangeEvent<HTMLInputElement>) => {
        setUpdatedPage(1);
        setDebouncedSearch(e.target.value);
    }, 800);

    const handleAssignedToFilterChange = (name: string, value: string) => {
        setSelectedTeammate({ name, value });
    };

    const handleClearAssignedToFilter = () => {
        setSelectedTeammate({ name: "", value: "" });
    };

    useEffect(() => {
        dispatch(workforceActions.getAiTeammates(updatedPage, debouncedSearch));
    }, [dispatch, updatedPage, debouncedSearch]);

    useEffect(() => {
        if (Boolean(fetchAiTeammatesSuccess)) {
            // setAiTeammates(fetchAiTeammatesSuccess?.agents);
            setAiTeammates((prev) =>
                updatedPage === 1
                    ? [...fetchAiTeammatesSuccess?.aiAgent]
                    : [...prev, ...fetchAiTeammatesSuccess?.aiAgent]
            );
            setPagination({
                currentPage: fetchAiTeammatesSuccess.pagination?.current,
                noOfPages: fetchAiTeammatesSuccess.pagination?.number_of_pages,
            });

            setLoadMore(false);
        }
    }, [fetchAiTeammatesSuccess, updatedPage]);

    return {
        isFetching: fetchingAiTeammates,
        error: fetchAiTeammatesFailure,
        data: aiTeammates,
        setAiTeammates,
        search,
        handleSearch,
        debouncedSearch,
        handleDebouncedChange,
        pagination,
        isLoadingMore,
        updatedPage,
        selectedTeammate,
        handleAssignedToFilterChange,
        handleClearAssignedToFilter,
    };
};

export default useGetAiTeammates;
