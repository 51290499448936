import Button from 'components/common/Button';
import React from 'react'

const ExcelFile = () => {
  return (
      <div className="pb-10 w-[65%]">
          <p className="pb-4 font-rocGroteskMedium text-gun-metal text-base">Upload Excel file</p>
          <div className="w-full rounded border-[#BFCDE0] border-dashed border ">
              <div className="py-8 font-rocGroteskMedium flex justify-center flex-col items-center">
                  <i className="ri-upload-cloud-2-line mb-4 text-4xl  text-slate-500 "></i>
                  <p className="text-[#142837] mb-1 text-sm ">Drag your file here to upload</p>
                  <p className="text-[#6B778C] text-sm mb-4">XLSX | File size limit: 25MB</p>
                  <Button
                      btnText="Browse files"
                      btnClassname="!bg-white !py-2 !px-4 border border-[#DFE1E6] !text-[#142837] !text-[13px] !w-auto"
                  />
              </div>
          </div>

          <div className="gap-2 flex !w-auto justify-start mt-6 ">
              <Button
                  btnText="Upload file"
                  btnClassname="!py-2  !text-[#142837] !text-[13px] !w-auto !font-rocGroteskMedium"
              />
              <Button
                  btnText="Download sample template"
                  btnClassname="!bg-transparent !py-2  !text-[#142837] !text-[13px] !w-auto !font-rocGroteskMedium !border !border-n-40"
              />
          </div>
      </div>
  );
}

export default ExcelFile
