import ActivityCard from "components/common/ActivityCard";
import moment from "moment";
import React from "react";

const ShipmentActivityTab = ({
    activities,
    className = "px-6",
    showNoContent,
    subContentClasName,
    subContent = "July 2023",
    contentClass,
}) => {
    return activities && activities?.length > 0 ? (
        <div>
            <div className="flex mt-5 w-full items-center gap-2 mb-2">
                <p
                    className={`text-sm font-rocGroteskMedium whitespace-nowrap  ${subContentClasName} text-slate-500`}
                >
                    {subContent}
                </p>
                <hr className="w-[90%] text-slate-100" />
            </div>
            <div className={`overflow-auto ${contentClass}`}>
                {activities?.map((activiy, idx) => (
                    <div key={idx} className={`py-6 ${className}`}>
                        <div>
                            <ActivityCard
                                activityTitle={
                                    activiy?.statusType?.toLowerCase() === "container"
                                        ? "Container " +
                                          activiy?.containerEventDetails?.event?.[0]?.toLowerCase() +
                                          activiy?.containerEventDetails?.event?.slice(1) +
                                          " at " +
                                          activiy?.containerEventDetails?.location
                                        : !activiy?.containerEventDetails
                                        ? activiy?.message
                                        : activiy?.containerEventDetails?.event
                                }
                                datetime={moment(activiy?.containerEventDetails?.time).format(
                                    "MMM DD, YYYY LT"
                                )}
                                containerNo={activiy?.containerNumber}
                                className={"!py-1"}
                                icon={
                                    activiy?.statusType?.toLowerCase() === "container" ? (
                                        <i className="ri-box-2-fill text-slate-500"></i>
                                    ) : activiy?.delayed ? (
                                        <i className="ri-water-flash-fill text-[#E5B906]"></i>
                                    ) : (
                                        <i className="ri-flag-fill before:content-['\ed3a'] text-slate-500"></i>
                                    )
                                }
                                iconContainerClass={`${
                                    activiy?.delayed && "text-[#E5B906] opacity-15"
                                }`}
                            />
                            {/* <ActivityCard
                            activityTitle={"You have a new message"}
                            datetime={"Apr 17, 2023 at 2:01 AM EDT"}
                            icon={
                                <i className="ri-flag-fill before:content-['\ed3a'] text-slate-500"></i>
                            }
                        /> */}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    ) : (
        <div className={`mt-[111px] flex justify-center`}>
            <div className="text-center w-[35%]">
                <i className="ri-flag-line text-5xl text-slate-500 "></i>
                <p className="text-base font-rocGroteskMedium mb-1 mt-4">No Activities</p>
                <p className="text-sm font-rocGroteskRegular text-slate-500">
                    It seems like there are no activities at this time.
                </p>
            </div>
        </div>
    );
};

export default ShipmentActivityTab;
