import React from "react";
import CustomTable from "../../../common/CustomTable";
import VendorAvatar from "components/common/VendorAvatar";
import Dropdown from "components/common/Dropdown";
import { useNavigate, useSearchParams } from "react-router-dom";
import useGetBOMList from "hooks/ProductionHooks/useGetBOMList";
import { truncate } from "lodash";
import { formatMoney, getCurrencyFromCurrencyCode } from "helpers";
import CustomTableLoader from "components/common/CustomTableLoader";
import EmptyStateComp from "components/common/EmptyStateComp";
import Loader from "components/common/Loader";
import moment from "moment";

type BOMProps = {
    debouncedSearch: string;
    search: string;
};
const BillOfMaterial = ({ debouncedSearch, search }: BOMProps) => {
    const navigate = useNavigate();
    const [params] = useSearchParams();
    const currTab = params.get("currTab");
    const { data, isLoading, isLoadingMore, pagination } = useGetBOMList(debouncedSearch);

    const BOMTableHeader = [
        { title: "BOM name", widthClass: "w-[148px]  !bg-slate-50 " },
        { title: "Product", widthClass: "w-[250px]   !bg-slate-50 " },
        { title: "Raw Materials", widthClass: "w-[137px]  !bg-slate-50 " },
        { title: "Quantity", widthClass: "w-[120px]  !bg-slate-50 " },
        { title: "Scrap", widthClass: "w-[80px]   !bg-slate-50 " },
        { title: "Total Quantity", widthClass: "w-[130px]  !bg-slate-50 " },
        { title: "UOM", widthClass: "w-[84px]  !bg-slate-50 " },
        { title: "Unit Cost", widthClass: "w-[99px]  !bg-slate-50 " },
        { title: "Total Cost", widthClass: "w-[107px]  !bg-slate-50 " },
        { title: "Date Created", widthClass: "w-[130px]  !bg-slate-50 " },
        { title: "Last Updated", widthClass: "w-[130px]  !bg-slate-50 " },
        { title: "", widthClass: "w-[40px]  !bg-slate-50 " },
    ];

    const BOMTableBody = data?.map((product, idx) => {
        const quantity = product?.billOfMaterialIds?.reduce(
            (sum, pro) => sum + (pro?.quantity || 0),
            0
        );
        const scrap = product?.billOfMaterialIds?.reduce((sum, pro) => sum + (pro?.scrap || 0), 0);
        const totalQuantity = Math.round(quantity + (scrap / 100) * quantity);
        const totalCost = product?.unitCost?.amount * totalQuantity;

        return [
            {
                content: (
                    <div className={`w-full  px-4 `}>
                        <p className="text-g-75 text-sm">{product?.bomName ?? "N/A"}</p>
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                widthClass: "!border-slate-200",
                cellClickAction: () => {
                    navigate(`/dashboard/production/bom/${product?._id}?currTab=${currTab}`);
                },
            },
            {
                content: (
                    <div className={`w-full  `}>
                        <div className="flex items-center gap-2 py-2 px-4">
                            {!product ? (
                                <VendorAvatar
                                    imageSrc={
                                        product?.productImageDetails?.productAvatar ||
                                        product?.productImageDetails?.productImages?.[0]
                                    }
                                    size={32}
                                    name={product?.productName}
                                    containerClassname="!rounded-md"
                                />
                            ) : (
                                <div className="w-8 h-8 rounded shrink-0 flex items-center justify-center bg-slate-200 ">
                                    <i className="ri-box-2-fill text-lg text-slate-500"></i>
                                </div>
                            )}

                            <div className="text-sm">
                                <p className="text-g-75">
                                    {" "}
                                    {truncate(product?.productName, { length: 20 })}
                                </p>
                                <p className="text-[#64748B]">{product?.sku}</p>
                            </div>
                        </div>
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                widthClass: "!border-slate-200",
            },
            {
                content: (
                    <div className={`w-full px-4 py-2`}>
                        <p className="text-g-75 text-sm">{product?.billOfMaterialIds?.length}</p>
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                widthClass: "!border-slate-200",
            },

            {
                content: (
                    <div className={`w-full px-4 py-2`}>
                        <p className="text-g-75 text-sm">
                            {quantity?.toLocaleString()}{" "}
                            {quantity ? product?.productStockDetails?.unitOfMeasurement : ""}
                        </p>
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                widthClass: "!border-slate-200",
            },

            {
                content: (
                    <div className={`w-full px-4 py-2`}>
                        <p className="text-g-75 text-sm">
                            {scrap}
                            {scrap && "%"}
                        </p>
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                widthClass: "!border-slate-200",
            },
            {
                content: (
                    <div className={`w-full px-4 py-2`}>
                        <p className="text-g-75 text-sm">
                            {quantity && scrap ? totalQuantity : "N/A"}{" "}
                            {quantity && scrap
                                ? product?.productStockDetails?.unitOfMeasurement
                                : ""}
                        </p>
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                widthClass: "!border-slate-200",
            },
            {
                content: (
                    <div className={`w-full px-4 py-2`}>
                        <p className="text-g-75 text-sm">
                            {product?.productStockDetails?.unitOfMeasurement}
                        </p>
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                widthClass: "!border-slate-200",
            },
            {
                content: (
                    <div className={`w-full px-4 py-2`}>
                        <p className="text-g-75 text-sm">
                            {product?.unitCost?.amount !== null &&
                                getCurrencyFromCurrencyCode(product?.unitCost?.currency)}
                            {product?.unitCost?.amount ?? "N/A"}
                        </p>
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                widthClass: "!border-slate-200",
            },
            {
                content: (
                    <div className={`w-full px-4 py-2`}>
                        <p className="text-g-75 text-sm">
                            {product?.unitCost?.amount !== null &&
                                getCurrencyFromCurrencyCode(product?.unitCost?.currency)}{" "}
                            {product?.unitCost?.amount !== null
                                ? formatMoney()?.format(totalCost)
                                : "N/A"}
                        </p>
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                widthClass: "!border-slate-200",
            },
            {
                content: (
                    <div className={`w-full px-4 py-2`}>
                        <p className="text-g-75 text-sm">
                            {product?.bomCreatedAt
                                ? moment(product?.bomCreatedAt)?.format("MMM DD, YYYY")
                                : "N/A"}
                        </p>
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                widthClass: "!border-slate-200",
            },
            {
                content: (
                    <div className={`w-full px-4 py-2`}>
                        <p className="text-g-75 text-sm">
                            {product?.bomModifiedAt
                                ? moment(product?.bomModifiedAt)?.format("MMM DD, YYYY")
                                : "N/A"}
                        </p>
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                widthClass: "!border-slate-200",
            },
            {
                content: (
                    <div className={`w-full px-4 py-2`} onClick={(e) => e.stopPropagation()}>
                        <Dropdown
                            dropdown={<i className="ri-more-2-fill text-lg text-slate-500"></i>}
                            dropdownClassName={"!w-full !mb-10 !-mt-7"}
                            dropdownContainerClasses={`shadow-cardShadow-2 border-0 rounded !max-h-fit left-[-100px] `}
                            dropdownItemsClasses={``}
                            name={"faq-dropdown"}
                            dropdownOptions={[
                                {
                                    label: "Edit BOM",
                                    value: "Edit BOM",
                                    action: () => {
                                        navigate(
                                            `/dashboard/production/edit-bom/${product?._id}?currTab=${currTab}`
                                        );
                                    },
                                    labelClassName: "!text-gm-50 !text-sm",
                                },
                                {
                                    label: "Duplicate BOM",
                                    value: "Duplicate BOM",
                                    action: () => {},
                                    labelClassName: "!text-gm-50 !text-sm",
                                },
                                {
                                    label: "Delete DOM",
                                    value: "Delete DOM",
                                    action: () => {},
                                    labelClassName: "!text-[#E4281D] !text-sm",
                                },
                            ]}
                        />
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                widthClass: "!border-slate-200",
            },
        ];
    });

    return (
        <div className="font-rocGroteskMedium">
            {isLoading && !search ? (
                <div className="overflow-x-hidden">
                    <CustomTableLoader tableHeader={BOMTableHeader} />
                </div>
            ) : data?.length >= 1 ? (
                <div>
                    <CustomTable
                        tableBody={BOMTableBody}
                        tableHeader={BOMTableHeader}
                        isInifiniteScroll={true}
                        isCellSelectable={false}
                        isAllSelectable={false}
                        stickyHeader={true}
                        headerContainerClass="!bg-[#F8FAFC] !border-slate-200"
                        headerItemClass="font-rocGroteskMedium !py-[11px] !px-4 !text-slate-700"
                        tableClass="mb-12 !border-collapse"
                        isScrollable={true}
                    />

                    {isLoadingMore && (
                        <div className="flex justify-center my-4">
                            <Loader color="gm-25" size={4} />
                        </div>
                    )}

                    {pagination?.currentPage === pagination?.noOfPages && (
                        <div className="flex my-4 justify-center text-slate-500 text-sm font-rocGroteskMedium">
                            End of list
                        </div>
                    )}
                </div>
            ) : (
                <div className="border border-slate-200 rounded-lg py-8">
                    <EmptyStateComp
                        title="No items in your Bill of Materials"
                        description="Your Bill of Materials is empty. Add items to track your components and materials efficiently."
                        icon={<i className="ri-file-list-2-line text text-[40px]"></i>}
                        hasButton={true}
                        btnText="Create bill of material"
                        btnAction={() => {
                            navigate(`/dashboard/production/create-bom?currTab=${currTab}`);
                        }}
                        hasButton2={true}
                        btn2Text="Import BOM from file"
                        btn2Action={() => {}}
                        btn2Classname="!bg-n-20"
                        contentContainerClass="flex flex-col items-center max-w-[537px] !bg-white"
                        descriptionClass="flex flex-col items-center"
                        className=""
                    />
                </div>
            )}
        </div>
    );
};

export default BillOfMaterial;
