import Checkbox from "components/common/InputField/Checkbox";
import SelectInput from "components/common/InputField/SelectInput";
import TextInput from "components/common/InputField/TextInput";
import ToggleInput from "components/common/InputField/ToggleInput";
import React from "react";

type AiAgentActivitiesProps = {
    structure: { [key: string]: any }[];
    handleAiStepsChange?: (idx: number, name: string, value: any) => void;
};

const AiAgentActivities = ({ structure, handleAiStepsChange }: AiAgentActivitiesProps) => {
    return (
        <div>
            {structure?.map((step, idx) => {
                return (
                    <div
                        key={step?.title + String(idx)}
                        className={`font-rocGroteskMedium ${
                            structure?.length - 1 !== idx && "pb-6 border-b border-slate-200"
                        } ${idx > 0 && "pt-6"}`}
                    >
                        <div className="flex items-center justify-between mb-1 text-sm">
                            <span>{step?.title}</span>
                            {step?.approvalPossible && (
                                <div className="text-sm flex items-center space-x-1">
                                    <span>Turn {step?.shouldApprove ? "off" : "on"} approval</span>
                                    <ToggleInput
                                        checked={step?.shouldApprove}
                                        name={"shouldApprove"}
                                        onChange={(e) => {
                                            handleAiStepsChange(
                                                idx,
                                                e.target.name,
                                                e.target.checked
                                            );
                                        }}
                                    />
                                </div>
                            )}
                        </div>
                        <p className="text-sm text-slate-500 w-[75%] mb-3">{step?.description}</p>

                        {step?.repeatable && (
                            <div>
                                <div>
                                    <div className="flex items-center space-x-3 mb-4">
                                        <Checkbox
                                            name="shouldRepeat"
                                            isChecked={step?.shouldRepeat}
                                            required
                                            onChange={(evt) => {
                                                handleAiStepsChange(
                                                    idx,
                                                    evt.target.name,
                                                    evt.target.checked
                                                );
                                            }}
                                            className={"!h-4 !w-4 !rounded-[4px] checked:!bg-y-10 "}
                                        />
                                        <span className={`text-sm font-rocGroteskMedium`}>
                                            This tasks is a repeat one
                                        </span>
                                    </div>
                                </div>

                                {step?.shouldRepeat && (
                                    <div className="flex items-center space-x-4">
                                        <div className="w-1/2">
                                            <SelectInput
                                                value={step?.frequency}
                                                name="frequency"
                                                placeholder={"Frequency"}
                                                handleChange={(name, value) =>
                                                    handleAiStepsChange(idx, name, value)
                                                }
                                                isRequired={true}
                                                dropdownOptions={[
                                                    {
                                                        label: "Weekly",
                                                        value: "weekly",
                                                    },
                                                ]}
                                            />
                                        </div>

                                        <div className="w-1/2">
                                            <TextInput
                                                value={step?.dateTime}
                                                name={"dateTime"}
                                                type={"datetime-local"}
                                                required={true}
                                                onChange={(e) => {
                                                    handleAiStepsChange(
                                                        idx,
                                                        e.target.name,
                                                        e.target.value
                                                    );
                                                }}
                                                inputPlaceholder={"Monday | 8:00am"}
                                                containerClassname=""
                                            />
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                );
            })}
        </div>
    );
};

export default AiAgentActivities;
