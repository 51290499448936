export const tableOfContent = [
    {
        title: "Acceptance of Terms",
        subtext:
            "By accessing or using the SynthAlly platform, including the website and mobile applications, you agree to comply with and be bound by these Terms and Conditions. If you do not agree with any part of these terms, you should not use SynthAlly.",
    },
    {
        title: "User Eligibility",
        subtext:
            "You must be at least 18 years old and have the legal capacity to enter into agreements to use SynthAlly. By using SynthAlly, you represent and warrant that you meet these eligibility requirements.",
    },
    {
        title: "Use of SynthAlly",
        subtext:
            "SynthAlly is a platform that connects vendors and Cross Border Trade Businesses. Users of SynthAlly may include vendors, supply chain service providers, and other related professionals.\n\n\nYou agree to use SynthAlly in compliance with all applicable laws and regulations and in accordance with these Terms and Conditions.",
    },
    {
        title: "User Accounts",
        subtext:
            "To access certain features of SynthAlly, you may need to create a user account. You are responsible for maintaining the confidentiality of your account information, including your username and password.\n\n\nYou agree to provide accurate and up-to-date information during the registration process and to update your account information promptly if it changes.",
    },
    {
        title: "Payment and Billing",
        subtext:
            "Certain services on SynthAlly may be subject to fees. You agree to pay all fees associated with your use of such services.\n\n\nPayment processing may be provided by third-party payment processors. You agree to comply with their terms and conditions regarding payments.",
    },
    {
        title: "Content and Intellectual Property",
        subtext:
            "SynthAlly may contain content, including text, images, logos, and trademarks, that is protected by intellectual property rights. You may not use, reproduce, or distribute such content without proper authorization.\n\n\nUsers retain ownership of their content uploaded to SynthAlly but grant SynthAlly  license to use, display, and distribute such content on the platform.",
    },
    {
        title: "Privacy",
        subtext:
            "Your use of SynthAlly is subject to our Privacy Policy, which governs the collection, use, and sharing of your personal information.",
    },
    {
        title: "Termination",
        subtext:
            "SynthAlly reserves the right to suspend or terminate your access to the platform at its discretion, with or without cause, and with or without notice.",
    },
    {
        title: "Disclaimers",
        subtext: `SynthAlly is provided "as is" and "as available." We make no warranties or representations about the accuracy or completeness of the platform's content or the content of any sites linked to SynthAlly.`,
    },
    {
        title: "Limitation of Liability",
        subtext:
            "To the fullest extent permitted by law, SynthAlly and its affiliates shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly.",
    },
    {
        title: "Governing Law",
        subtext:
            "These Terms and Conditions are governed by and construed in accordance with the laws of [Jurisdiction], without regard to its conflict of law principles.",
    },
    {
        title: "Rights",
        subtext:
            "SynthAlly reserves the right to update or modify these Terms and Conditions at any time. Any changes will be effective immediately upon posting.",
    },
    {
        title: "Contact Information",
        subtext: `<p>For questions or concerns about these Terms and Conditions, please contact us at <a href="mailto:hello@SynthAlly.com" style="color: #FD700B; text-decoration:underline;">hello@SynthAlly.com.</a></p>`,
    },
];
