export const businessAnalyticsType = {
    GET_PURCHASE_ORDER_SUMMARY_REQUEST: "GET_PURCHASE_ORDER_SUMMARY_REQUEST",
    GET_PURCHASE_ORDER_SUMMARY_SUCCESS: "GET_PURCHASE_ORDER_SUMMARY_SUCCESS",
    GET_PURCHASE_ORDER_SUMMARY_FAILURE: "GET_PURCHASE_ORDER_SUMMARY_FAILURE",
    GET_PURCHASE_REQUEST: "GET_PURCHASE_REQUEST",
    GET_PURCHASE_SUCCESS: "GET_PURCHASE_SUCCESS",
    GET_PURCHASE_FAILURE: "GET_PURCHASE_FAILURE",

    GET_SPEND_ANALYTICS_REQUEST: "GET_SPEND_ANALYTICS_REQUEST",
    GET_SPEND_ANALYTICS_SUCCESS: "GET_SPEND_ANALYTICS_SUCCESS",
    GET_SPEND_ANALYTICS_FAILURE: "GET_SPEND_ANALYTICS_FAILURE",
    FETCH_ANALYTICS_PROCUREMENT_REQUEST: "FETCH_ANALYTICS_PROCUREMENT_REQUEST",
    FETCH_ANALYTICS_PROCUREMENT_SUCCESS: "FETCH_ANALYTICS_PROCUREMENT_SUCCESS",
    FETCH_ANALYTICS_PROCUREMENT_FAILURE: "FETCH_ANALYTICS_PROCUREMENT_FAILURE",
    FETCH_ANALYTICS_PROCUREMENT_INSIGHT_REQUEST: "FETCH_ANALYTICS_PROCUREMENT_INSIGHT_REQUEST",
    FETCH_ANALYTICS_PROCUREMENT_INSIGHT_SUCCESS: "FETCH_ANALYTICS_PROCUREMENT_INSIGHT_SUCCESS",
    FETCH_ANALYTICS_PROCUREMENT_INSIGHT_FAILURE: "FETCH_ANALYTICS_PROCUREMENT_INSIGHT_FAILURE",
    FETCH_ANALYTICS_PROCUREMENT_GRAPH_REQUEST: "FETCH_ANALYTICS_PROCUREMENT_GRAPH_REQUEST",
    FETCH_ANALYTICS_PROCUREMENT_GRAPH_SUCCESS: "FETCH_ANALYTICS_PROCUREMENT_GRAPH_SUCCESS",
    FETCH_ANALYTICS_PROCUREMENT_GRAPH_FAILURE: "FETCH_ANALYTICS_PROCUREMENT_GRAPH_FAILURE",
    FETCH_SPEND_ANALYSIS_CATEGORY_AND_SUPPLIER_REQUEST: "FETCH_SPEND_ANALYSIS_CATEGORY_AND_SUPPLIER_REQUEST",
    FETCH_SPEND_ANALYSIS_CATEGORY_AND_SUPPLIER_SUCCESS: "FETCH_SPEND_ANALYSIS_CATEGORY_AND_SUPPLIER_SUCCESS",
    FETCH_SPEND_ANALYSIS_CATEGORY_AND_SUPPLIER_FAILURE: "FETCH_SPEND_ANALYSIS_CATEGORY_AND_SUPPLIER_FAILURE",
};