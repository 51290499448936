import React, { useEffect, useRef, useState } from "react";
import { adjustHeight } from "variables";
import ForecastLineChart from "./ForecastLineChart";
import Button from "./Button";
import TextInput from "./InputField/TextInput";
import VendorSelectInput from "./InputField/VendorSelectInput";
import { truncate } from "lodash";
import VendorAvatar from "./VendorAvatar";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import { chatActions } from "redux/Ldb/actions";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ThreeDots } from "react-loader-spinner";
import TypingEffect from "./TypingEffect";
import useGetUserProfile from "hooks/useGetUserProfile";
import moment from "moment";
import { convertSeconds, parseConvertedSeconds, parseLeadTime } from "helpers/convertSeconds";
import { getCurrencyFromCurrencyCode } from "helpers";
import toast from "react-hot-toast";
import CustomToast from "./CustomToast";
import CustomTable from "./CustomTable";
import CustomTooltip from "./CustomTooltip";
import { Tooltip } from "react-tooltip";
import Checkbox from "./InputField/Checkbox";

type AIChatCompProps = {
    isExpanded?: boolean;
    chats: { [key: string]: any }[];
    setChats: React.Dispatch<
        React.SetStateAction<
            {
                [key: string]: any;
            }[]
        >
    >;
    chatId: string;
    setChatId: React.Dispatch<React.SetStateAction<string>>;
    message: string;
    setMessage: React.Dispatch<React.SetStateAction<string>>;
    handleSendMessage: (message: string, conversationId?: string) => void;
    editedValues?: { stockNeeds?: string; vendors?: string[] }[];
    setEditedValues?: React.Dispatch<
        React.SetStateAction<
            {
                stockNeeds?: string;
                vendors?: string[];
            }[]
        >
    >;
    handleItemChange?: (idx: number, name: string, value: string | string[]) => void;
    handleSelectAll?: (chat: { [key: string]: any }) => void;
    handleSelectItem?: (item: { [key: string]: any }, idx: number) => void;
    selectedProducts?: any[];
    setShowAIAgent?: React.Dispatch<React.SetStateAction<boolean>>;
    changedSupplier?: any[];
    setChangedSupplier?: React.Dispatch<React.SetStateAction<any[]>>;
};

const AIChatComp = ({
    isExpanded,
    chats,
    setChats,
    chatId,
    setChatId,
    message,
    setMessage,
    handleSendMessage,
    editedValues,
    setEditedValues,
    handleItemChange,
    handleSelectAll,
    handleSelectItem,
    selectedProducts,
    setShowAIAgent,
    changedSupplier,
    setChangedSupplier,
}: AIChatCompProps) => {
    const [isScrolled, setIsScrolled] = useState(false);

    const typingSpeed = 25; // think of this as delay i.e how long it would take to type, so lower is faster vice versa
    const lastMessageRef = useRef(null);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [param] = useSearchParams();
    const conversationId = param.get("conversationId");

    const [profile] = useGetUserProfile();
    const { createConversationSuccess } = useAppSelector((state) => state.chat);
    const [showIdx, setShowIdx] = useState<number>(0);

    const aiSuggestions = [
        {
            icon: (
                <div className="w-[52px] h-[52px] rounded-full bg-[#FFB90226] flex shrink-0 items-center justify-center">
                    <i className="ri-information-line text-[32px] text-y-10"></i>
                </div>
            ),
            title: "Show my replenishment suggestions",
            action: () => {
                handleSendMessage("Show my replenishment suggestions", chatId);
            },
        },
        {
            icon: (
                <div className="w-[52px] h-[52px] rounded-full bg-slate-100 flex shrink-0 items-center justify-center">
                    <i className="ri-file-text-line text-[32px] text-p-50"></i>
                </div>
            ),
            title: "Create purchase orders for me.",
            action: () => {
                handleSendMessage("Create purchase orders for me.", chatId);
            },
        },
    ];

    const handleScroll = (e) => {
        if (e.target.scrollLeft > 0) {
            setIsScrolled(true);
        } else {
            setIsScrolled(false);
        }
    };

    const nameArr: string[] = profile?.profile?.businessName
        ? profile?.profile?.businessName?.split(" ")
        : [];

    const initials = nameArr
        ?.map((name: string) => name[0]?.toUpperCase())
        ?.join("")
        ?.slice(0, 3);

    const showSuggestions =
        chats?.slice(-1)?.[0]?.conversationType?.toLowerCase() === "simulate_demand";

    const handleChatChange = (value: string) => {
        setMessage(value);
    };

    const displayMessage = (chat) => {
        if (chat?.loading) {
            return (
                <div>
                    <ThreeDots
                        height="40"
                        width="60"
                        radius="9"
                        color="#64748B33"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        visible={true}
                    />
                </div>
            );
        }
        if (chat?.conversationType?.toLowerCase() === "simulate_demand") {
            return (
                <div>
                    <div>
                        {chat?._id === chats?.[chats?.length - 1]?._id ? (
                            <p
                                className={`text-sm font-rocGroteskMedium text-gm-50 whitespace-pre-wrap`}
                            >
                                <TypingEffect typingSpeed={typingSpeed} text={chat?.message} />
                            </p>
                        ) : (
                            <p
                                className={`text-sm font-rocGroteskMedium text-gm-50 whitespace-pre-wrap`}
                            >
                                {chat?.message}
                            </p>
                        )}
                    </div>

                    <div
                        className={`ml-[-42px] transition-[opacity] ease-in-out delay-150 duration-300 `}
                    >
                        <ForecastLineChart graphData={chat?.data} isExpanded={isExpanded} />
                    </div>

                    {showSuggestions && (
                        <div className="ml-[-42px]">
                            <h6 className="text-sm font-rocGroteskMedium text-slate-600 mb-3">
                                Suggested action prompts
                            </h6>

                            <div className="flex gap-[14px] justify-start mb-3 overflow-x-auto flex-wrap">
                                {aiSuggestions.map((suggestion, index) => {
                                    return (
                                        <div
                                            key={index}
                                            onClick={() => suggestion?.action()}
                                            className="flex shrink-0 items-center space-x-2.5 py-4 px-2.5 cursor-pointer bg-white w-full lg:w-[268px] h-[82px] border border-slate-100 rounded-lg shadow-[-1px_6px_15px_0px_#00000005]"
                                        >
                                            {suggestion.icon}
                                            <p className="text-sm font-rocGroteskMedium text-slate-700">
                                                {suggestion.title}
                                            </p>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    )}
                </div>
            );
        }

        if (chat?.conversationType?.toLowerCase() === "show_replenishment") {
            const tableHeader = [
                {
                    title: "Product",
                    widthClass: isExpanded ? "w-[20%]" : "w-[190px]",
                    onSelectAll: () => {
                        if (!Boolean(chat?._id === chats?.[chats?.length - 1]?._id)) return;
                        handleSelectAll(chat);
                    },
                    checkAllIsDisabled: !Boolean(chat?._id === chats?.[chats?.length - 1]?._id),
                    isAllChecked: selectedProducts.length === chat?.data?.replenishments?.length,
                },
                {
                    title: (
                        <div className="flex space-x-1.5 items-center ">
                            <span>Supplier</span>
                            <div>
                                <a id="supplier-column">
                                    <i className="ri-information-fill text-slate-500 text-base"></i>
                                </a>

                                <Tooltip
                                    anchorSelect="#supplier-column"
                                    place={"top"}
                                    content="This column is editable. Click on the cell to select a supplier."
                                    style={{ width: "180px", zIndex: 9999 }}
                                />
                            </div>
                        </div>
                    ),
                    widthClass: isExpanded ? "w-[20%]" : "w-[191px]",
                },
                {
                    title: "Supplier lead time",
                    widthClass: isExpanded ? "w-[14%]" : "w-[134px]",
                },
                {
                    title: "Current inventory",
                    widthClass: isExpanded ? "w-[14%]" : "w-[133px]",
                },
                {
                    title: (
                        <div className="flex space-x-1.5 items-center ">
                            <span>Stock needs</span>
                            <div>
                                <a id="stock-needs-column">
                                    <i className="ri-information-fill text-slate-500 text-base"></i>
                                </a>
                                <Tooltip
                                    anchorSelect="#stock-needs-column"
                                    place={"top"}
                                    content="This column is editable. Click on the cell to edit the stock needs."
                                    style={{ width: "180px", zIndex: 9999 }}
                                />
                            </div>
                        </div>
                    ),
                    widthClass: isExpanded ? "w-[12%]" : "w-[106px]",
                },
                {
                    title: "Unit cost",
                    widthClass: isExpanded ? "w-[10.4%]" : "w-[100px]",
                },
                {
                    title: "Total cost",
                    widthClass: isExpanded ? "w-[11.3%]" : "w-[109px]",
                },
            ];

            const tableBody = chat?.data?.replenishments?.map((data, idx) => {
                const fractionalDay = data?.leadTime;
                const secondsInDay = 24 * 60 * 60;
                const totalSeconds = fractionalDay * secondsInDay;

                return [
                    {
                        content: (
                            <div
                                className={`w-full border-slate-100 text-sm text-slate-700 py-2.5`}
                            >
                                <div>
                                    <span className="block text-[13px] text-slate-600">
                                        {truncate(data?.inventory?.productName, {
                                            length: 20,
                                        })}
                                    </span>
                                    <span className="text-[10px] font-rocGroteskMedium text-slate-500">
                                        SKU: {data?.inventory?.sku}
                                    </span>
                                </div>
                            </div>
                        ),
                        tableDataDivClass: "",
                        isItemChecked:
                            selectedProducts.find(
                                (product) => product.productId === data?.inventory?._id
                            ) || false,

                        checkItemIsDisabled: !Boolean(
                            chat?._id === chats?.[chats?.length - 1]?._id
                        ),
                        onCheckBoxClick: () => {
                            if (!Boolean(chat?._id === chats?.[chats?.length - 1]?._id)) return;
                            handleSelectItem(data, idx);
                        },
                    },
                    {
                        content: (
                            <div
                                className={`w-full h-full border-slate-100 text-sm text-slate-700 py-2.5`}
                            >
                                <VendorSelectInput
                                    value={
                                        editedValues?.[idx]?.vendors?.length > 0
                                            ? editedValues?.[idx]?.vendors?.join(", ")
                                            : data?.bestSupplierId
                                    }
                                    name="vendors"
                                    placeholder={"Select vendor"}
                                    isRequired={true}
                                    disabled={
                                        !Boolean(chat?._id === chats?.[chats?.length - 1]?._id)
                                    }
                                    className="!border-0"
                                    handleChange={(name, value, valuesArray) => {
                                        handleItemChange(idx, name, valuesArray);
                                    }}
                                    onSelect={(value) => {
                                        const changedSupplierCopy = [...changedSupplier];
                                        changedSupplierCopy[idx] = value;
                                        setChangedSupplier(changedSupplierCopy);
                                    }}
                                    dropdownOptionsProp={null}
                                    multipleSelect={false}
                                />
                            </div>
                        ),
                        tableDataDivClass: "!pl-0",
                        widthClass: `w-[20%] absolute`,
                    },
                    {
                        content: (
                            <div
                                className={`w-full flex items-center h-full border-slate-100 text-sm text-slate-700 py-2.5`}
                            >
                                <span className="text-[13px] font-rocGroteskMedium">
                                    {data?.leadTime === 0 ? 0 : parseLeadTime(totalSeconds)}
                                </span>
                            </div>
                        ),
                        // widthClass: `w-[36.3%] absolute`,
                        tableDataDivClass: "",
                    },
                    {
                        content: (
                            <div
                                className={`w-full flex items-center h-full border-slate-100 text-sm text-slate-700 py-2.5`}
                            >
                                <span className="text-[13px] font-rocGroteskMedium">
                                    {!data?.inventory?.productStockDetails?.stockLevel
                                        ? 0
                                        : data?.inventory?.productStockDetails?.stockLevel?.toLocaleString()}
                                </span>
                            </div>
                        ),
                        // widthClass: `w-[36.3%] absolute`,
                        tableDataDivClass: "",
                    },
                    {
                        content: (
                            <div
                                className={`w-full flex items-center h-full border-slate-100 text-sm text-slate-700 py-2.5`}
                            >
                                {/* <span className="text-[13px] font-rocGroteskMedium">5,000</span> */}
                                <TextInput
                                    value={
                                        editedValues?.[idx]?.stockNeeds ||
                                        Number(data?.qtyToBuy)?.toFixed(0)
                                    }
                                    name="stockNeeds"
                                    type={"text"}
                                    required={true}
                                    onChange={(e) =>
                                        handleItemChange(idx, e.target.name, e.target.value)
                                    }
                                    inputPlaceholder={"Stock needs"}
                                    disabled={
                                        !Boolean(chat?._id === chats?.[chats?.length - 1]?._id)
                                    }
                                    containerClassname=""
                                    inputContainerClassname={"!border-0 !rounded-none"}
                                    inputClassName="w-full text-r-55 !text-[13px]"
                                />
                            </div>
                        ),
                        // widthClass: `w-[36.3%] absolute`,
                        tableDataDivClass: "!pl-0",
                    },
                    {
                        content: (
                            <div
                                className={`w-full flex items-center h-full border-slate-100 text-sm text-slate-700 py-2.5`}
                            >
                                <span className="text-[13px] font-rocGroteskMedium">
                                    {getCurrencyFromCurrencyCode(
                                        data?.inventory?.unitCost?.currency
                                    )}
                                    {data?.inventory?.unitCost?.amount}
                                </span>
                            </div>
                        ),
                        // widthClass: `w-[36.3%] absolute`,
                        tableDataDivClass: "",
                    },
                    {
                        content: (
                            <div
                                className={`w-full flex items-center h-full border-slate-100 text-sm text-slate-700 py-2.5`}
                            >
                                {Number(
                                    editedValues?.[idx]?.stockNeeds ||
                                        Number(data?.qtyToBuy)?.toFixed(0)
                                ) * data?.inventory?.unitCost?.amount ? (
                                    <span className="text-[13px] font-rocGroteskMedium">
                                        {getCurrencyFromCurrencyCode(
                                            data?.inventory?.unitCost?.currency
                                        )}
                                        {Number(
                                            editedValues?.[idx]?.stockNeeds ||
                                                Number(data?.qtyToBuy)?.toFixed(0)
                                        ) * data?.inventory?.unitCost?.amount}
                                    </span>
                                ) : (
                                    "---"
                                )}
                            </div>
                        ),
                        // widthClass: `w-[36.3%] absolute`,
                        tableDataDivClass: "",
                    },
                ];
            });

            const mobileTableBody = chat?.data?.replenishments?.map((data, idx) => {
                const fractionalDay = data?.leadTime;
                const secondsInDay = 24 * 60 * 60;
                const totalSeconds = fractionalDay * secondsInDay;

                return (
                    <div
                        key={idx}
                        className="border-slate-200 border rounded py-4 px-2.5 font-rocGroteskMedium"
                    >
                        <div className=" flex justify-between items-center">
                            <Checkbox
                                name="select"
                                isChecked={
                                    selectedProducts.find(
                                        (product) => product.productId === data?.inventory?._id
                                    ) || false
                                }
                                disabled={!Boolean(chat?._id === chats?.[chats?.length - 1]?._id)}
                                onChange={() => {
                                    if (!Boolean(chat?._id === chats?.[chats?.length - 1]?._id))
                                        return;
                                    handleSelectItem(data, idx);
                                }}
                                className={"!h-4 !w-4 !rounded-[4px] mr-4"}
                            />

                            <div
                                className={`w-full border-slate-100 text-sm text-slate-700 py-2.5`}
                            >
                                <div>
                                    <span className="block text-[13px] text-slate-600">
                                        {truncate(data?.inventory?.productName, {
                                            length: 20,
                                        })}
                                    </span>
                                    <span className="text-[10px] font-rocGroteskMedium text-slate-500">
                                        SKU: {data?.inventory?.sku}
                                    </span>
                                </div>
                            </div>

                            <i
                                className={`${
                                    showIdx === idx
                                        ? "ri-arrow-down-s-line text-[#16C6A4]"
                                        : "ri-arrow-right-s-line text-[#042821]"
                                } text-2xl `}
                                onClick={() => {
                                    if (idx === showIdx) {
                                        setShowIdx(-1);
                                    } else {
                                        setShowIdx(idx);
                                    }
                                }}
                            ></i>
                        </div>
                        {/* tableDataDivClass: "",
                        isItemChecked:
                            selectedProducts.find(
                                (product) => product.productId === data?.inventory?._id
                            ) || false,

                        checkItemIsDisabled: !Boolean(
                            chat?._id === chats?.[chats?.length - 1]?._id
                        ),
                        onCheckBoxClick: () => {
                            if (!Boolean(chat?._id === chats?.[chats?.length - 1]?._id)) return;
                            handleSelectItem(data, idx);
                        }, */}

                        {showIdx === idx && (
                            <div className="space-y-[10px] border-t border-t-slate-200 text-[13px] text-slate-700 !font-rocGroteskMedium">
                                <div className="flex justify-between items-center pt-2.5">
                                    <p className="flex-1">Supplier</p>
                                    <div className="flex-1">
                                        <VendorSelectInput
                                            value={
                                                editedValues?.[idx]?.vendors?.length > 0
                                                    ? editedValues?.[idx]?.vendors?.join(", ")
                                                    : data?.bestSupplierId
                                            }
                                            name="vendors"
                                            placeholder={"Select vendor"}
                                            isRequired={true}
                                            disabled={
                                                !Boolean(
                                                    chat?._id === chats?.[chats?.length - 1]?._id
                                                )
                                            }
                                            className="!border-0 !h-auto"
                                            handleChange={(name, value, valuesArray) => {
                                                handleItemChange(idx, name, valuesArray);
                                            }}
                                            onSelect={(value) => {
                                                const changedSupplierCopy = [...changedSupplier];
                                                changedSupplierCopy[idx] = value;
                                                setChangedSupplier(changedSupplierCopy);
                                            }}
                                            dropdownOptionsProp={null}
                                            multipleSelect={false}
                                        />
                                    </div>
                                </div>
                                <div className="flex justify-between">
                                    <p className="">Lead time</p>
                                    <p className="">
                                        {data?.leadTime === 0 ? 0 : parseLeadTime(totalSeconds)}
                                    </p>
                                </div>
                                <div className="flex justify-between">
                                    <p className="">Current inventory</p>
                                    <p className="text-[13px] font-rocGroteskMedium">
                                        {!data?.inventory?.productStockDetails?.stockLevel
                                            ? 0
                                            : data?.inventory?.productStockDetails?.stockLevel?.toLocaleString()}
                                    </p>
                                </div>
                                <div className="flex justify-between items-center">
                                    <p className="flex-1">Stock needs</p>
                                    <div className="flex-1 !text-right">
                                        <TextInput
                                            value={
                                                editedValues?.[idx]?.stockNeeds ||
                                                Number(data?.qtyToBuy)?.toFixed(0)
                                            }
                                            name="stockNeeds"
                                            type={"text"}
                                            required={true}
                                            onChange={(e) =>
                                                handleItemChange(idx, e.target.name, e.target.value)
                                            }
                                            inputPlaceholder={"Stock needs"}
                                            disabled={
                                                !Boolean(
                                                    chat?._id === chats?.[chats?.length - 1]?._id
                                                )
                                            }
                                            containerClassname=""
                                            inputContainerClassname={
                                                "!border-0 !rounded-none !h-auto"
                                            }
                                            inputClassName="!w-fit text-r-55 !text-[13px] !text-right !p-[4px] !h-auto "
                                        />
                                    </div>
                                </div>
                                <div className="flex justify-between">
                                    <p className="">Unit cost</p>
                                    <p className="text-[13px] font-rocGroteskMedium">
                                        {getCurrencyFromCurrencyCode(
                                            data?.inventory?.unitCost?.currency
                                        )}
                                        {data?.inventory?.unitCost?.amount}
                                    </p>
                                </div>
                                <div className="flex justify-between">
                                    <p className="">Total cost</p>
                                    {Number(
                                        editedValues?.[idx]?.stockNeeds ||
                                            Number(data?.qtyToBuy)?.toFixed(0)
                                    ) * data?.inventory?.unitCost?.amount ? (
                                        <span className="text-[13px] font-rocGroteskMedium">
                                            {getCurrencyFromCurrencyCode(
                                                data?.inventory?.unitCost?.currency
                                            )}
                                            {Number(
                                                editedValues?.[idx]?.stockNeeds ||
                                                    Number(data?.qtyToBuy)?.toFixed(0)
                                            ) * data?.inventory?.unitCost?.amount}
                                        </span>
                                    ) : (
                                        "---"
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                );
            });

            return (
                <div>
                    <div>
                        {chat?._id === chats?.[chats?.length - 1]?._id ? (
                            <p
                                className={`text-sm font-rocGroteskMedium text-gm-50 whitespace-pre-wrap`}
                            >
                                <TypingEffect typingSpeed={typingSpeed} text={chat?.message} />
                            </p>
                        ) : (
                            <p
                                className={`text-sm font-rocGroteskMedium text-gm-50 whitespace-pre-wrap`}
                            >
                                {chat?.message}
                            </p>
                        )}
                    </div>

                    <div className="mt-4 ml-[-42px]">
                        <div className="flex justify-between items-center mb-3">
                            <h6 className="text-sm font-rocGroteskMedium text-slate-600 ">
                                Suggested replenishments
                            </h6>

                            <Button
                                btnText={
                                    isExpanded
                                        ? `Create purchase orders (${selectedProducts?.length})`
                                        : `Create POs (${selectedProducts?.length})`
                                }
                                // isLoading={updatingChatData}
                                type={"button"}
                                btnClassname={"!w-fit py-2 px-4 !h-[35px] !text-[13px]"}
                                disabled={!Boolean(chat?._id === chats?.[chats?.length - 1]?._id)}
                                onClick={() => {
                                    if (!Boolean(chat?._id === chats?.[chats?.length - 1]?._id))
                                        return;

                                    if (selectedProducts?.length > 0) {
                                        handleSendMessage(
                                            "Create purchase order(s) for the selected item(s)",
                                            chatId
                                        );
                                    } else {
                                        toast.custom((t) => (
                                            <CustomToast
                                                t={t}
                                                message={
                                                    "Please select at least one item to create PO"
                                                }
                                                type={"error"}
                                            />
                                        ));
                                    }
                                }}
                            />
                        </div>
                        <div
                            className="overflow-x-scroll mb-[-290px] pb-[290px] max-lg:hidden"
                            onScroll={handleScroll}
                        >
                            <CustomTable
                                tableBody={tableBody}
                                tableHeader={tableHeader}
                                isAllSelectable={true}
                                headerContainerClass="!bg-slate-100"
                                tableClass="w-full"
                                isScrollable={true}
                                isScrolled={isScrolled}
                                isCellBordered={true}
                            />
                        </div>
                        <div className="lg:hidden px-6 space-y-2.5">{mobileTableBody}</div>
                    </div>
                </div>
            );
        }

        if (chat?.conversationType?.toLowerCase() === "create_po_from_demand") {
            return (
                <div>
                    <div>
                        {chat?._id === chats?.[chats?.length - 1]?._id ? (
                            <p
                                className={`text-sm font-rocGroteskMedium text-gm-50 whitespace-pre-wrap`}
                            >
                                <TypingEffect typingSpeed={typingSpeed} text={chat?.message} />
                            </p>
                        ) : (
                            <p
                                className={`text-sm font-rocGroteskMedium text-gm-50 whitespace-pre-wrap`}
                            >
                                {chat?.message}
                            </p>
                        )}
                    </div>

                    <div className="space-y-[14px] mt-5 ml-[-42px]">
                        {chat?.data?.purchaseOrders?.map((po, idx) => {
                            return (
                                <div
                                    onClick={() => navigate(`/dashboard/purchase/${po?._id}`)}
                                    key={po?._id}
                                    className="flex items-center space-x-2.5 p-2.5 cursor-pointer bg-[#E0E0FA66] w-full h-[72px] border border-p-55 rounded-lg shadow-[-1px_6px_15px_0px_#00000005]"
                                >
                                    <div className="w-[52px] h-[52px] rounded-full bg-slate-100 flex items-center justify-center">
                                        <i className="ri-file-text-line text-[32px] text-p-50"></i>
                                    </div>
                                    <div>
                                        <span className="block text-sm font-rocGroteskMedium text-slate-700">
                                            PO - {po?.taId}
                                        </span>
                                        <span className="block text-xs text-slate-500">
                                            Click to view
                                        </span>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            );
        }

        return chat?._id === chats?.[chats?.length - 1]?._id ? (
            <p className={`text-sm font-rocGroteskMedium text-gm-50 whitespace-pre-wrap`}>
                <TypingEffect typingSpeed={typingSpeed} text={chat?.message} />
            </p>
        ) : (
            <p className={`text-sm font-rocGroteskMedium text-gm-50 whitespace-pre-wrap`}>
                {chat?.message}
            </p>
        );
    };

    useEffect(() => {
        if (Boolean(createConversationSuccess)) {
            setChatId(createConversationSuccess?.conversation?._id);
            // handleSendMessage(message, createConversationSuccess?.conversation?._id);
            setShowAIAgent?.(true);
            navigate(
                `${window.location?.pathname}?conversationId=${createConversationSuccess?.conversation?._id}`,
                { replace: true }
            );
            dispatch(chatActions.resetCreateConversationSuccess());
        }
    }, [dispatch, navigate, createConversationSuccess]);

    useEffect(() => {
        if (lastMessageRef.current) {
            lastMessageRef.current.scrollIntoView({ behavior: "smooth" });
        }

        if (chats?.slice(-1)?.[0]?.conversationType?.toLowerCase() === "show_replenishment") {
            const parsedEditableValues = chats
                ?.slice(-1)?.[0]
                ?.data?.replenishments?.map((item) => {
                    return {
                        stockNeeds: item?.qtyToBuy ? item?.qtyToBuy?.toFixed(0) : 0,
                        vendors: [item?.bestSupplierId],
                    };
                });

            setEditedValues(parsedEditableValues);
        }
    }, [chats?.slice(-1)?.[0]?._id]);

    return (
        <div className="border border-slate-100 box-border pb-[120px] rounded-b-[12px] relative h-[calc(100vh-200px)] lg:h-[calc(100vh-172px)]">
            <div className={`px-4 pt-[18px] pb-[130px] h-[64vh] overflow-y-auto`}>
                {chats?.map((chat, idx) => {
                    return chat?.senderType?.toLowerCase() === "ldb" ? (
                        <div
                            key={chat?._id + idx}
                            className="py-4 px-2.5 grid grid-cols-[32px_auto] items-start space-x-3 bg-white border-b border-slate-100"
                        >
                            {Boolean(profile?.profile?.avatar) ? (
                                <img
                                    src={profile?.profile?.avatar}
                                    alt="user avatar"
                                    className=" w-8 h-8 rounded"
                                />
                            ) : (
                                <div className="w-8 h-8 p-2 rounded bg-slate-100 flex items-center justify-center">
                                    <p className="text-xs font-rocGroteskMedium text-gm-50">
                                        {initials}
                                    </p>
                                </div>
                            )}

                            <div>
                                <p className="flex text-sm font-rocGroteskMedium items-center mb-1">
                                    <span className="capitalize">
                                        {profile?.profile?.businessName}
                                    </span>
                                    <span className="mx-1">|</span>
                                    <span className="text-slate-500">
                                        {moment(chat?.createdAt).fromNow(true)} ago
                                    </span>{" "}
                                </p>
                                <p className="text-sm font-rocGroteskMedium text-slate-600 whitespace-pre-wrap">
                                    {chat?.message}
                                </p>
                            </div>

                            {idx === chats.length - 1 && <div ref={lastMessageRef}></div>}
                        </div>
                    ) : (
                        <div
                            key={chat?._id + idx}
                            className="bg-slate-50 border-b border-slate-100 py-4 px-2.5"
                        >
                            <div className=" flex items-start space-x-2.5 ">
                                <div className="w-8 h-8 p-2 rounded bg-gradient-to-tr from-[#56B648] from-0% to-[#FFB902] to-100% flex items-center justify-center">
                                    <i className="ri-robot-line text-white"></i>
                                </div>

                                <div className={`${isExpanded ? "w-full" : "w-[91%]"}`}>
                                    {displayMessage(chat)}
                                </div>
                                {idx === chats.length - 1 && <div ref={lastMessageRef}></div>}
                            </div>
                        </div>
                    );
                })}
            </div>

            <div className="flex flex-col justify-center items-center w-full p-4 absolute bottom-0 bg-white z-[9999]">
                <div className="flex items-center justify-between w-full bg-g-75 rounded-[20px] px-5">
                    <div className="flex items-center space-x-3 w-full">
                        <div className="relative">
                            <i
                                onClick={() => {
                                    // setAttachDocModal(true);
                                }}
                                className="ri-attachment-2 cursor-pointer text-white text-xl"
                            ></i>
                        </div>

                        <textarea
                            onKeyUp={(e) => {
                                adjustHeight(e.target);
                            }}
                            onKeyDown={(e) => {
                                if (e.key?.toLowerCase() === "enter" && !e.shiftKey) {
                                    e.preventDefault();

                                    if (!chats?.slice(-1)?.[0]?.loading) {
                                        if (
                                            chats
                                                ?.slice(-1)?.[0]
                                                ?.conversationType?.toLowerCase() ===
                                            "show_replenishment"
                                        ) {
                                            if (selectedProducts?.length > 0) {
                                                handleSendMessage(
                                                    "Create purchase order(s) for the selected item(s)",
                                                    chatId
                                                );
                                            } else {
                                                toast.custom((t) => (
                                                    <CustomToast
                                                        t={t}
                                                        message={
                                                            "Please select at least one item to create PO"
                                                        }
                                                        type={"error"}
                                                    />
                                                ));
                                            }
                                            return;
                                        }

                                        if (message?.trim() !== "") {
                                            handleSendMessage(message, chatId);
                                        }
                                    }
                                }
                            }}
                            id="chatInput"
                            placeholder={
                                chats?.length > 0
                                    ? "Message Synth"
                                    : "Type 'Hey Synth' to get started."
                            }
                            name="chatInput"
                            value={message}
                            // disabled={chats?.slice(-1)?.[0]?.loading}
                            onChange={(evt) => {
                                handleChatChange(evt.target.value);
                            }}
                            autoFocus
                            rows={1}
                            className="border-0 w-full overflow-y-auto resize-none min-h-[56px] max-h-[150px] text-sm text-white placeholder:text-sm placeholder:text-white bg-transparent pt-[17.5px] pr-10 outline-none focus:border-0 focus:ring-0 focus-visible:ring-0 dark:bg-transparent"
                        />
                    </div>

                    <div
                        onClick={() => {
                            if (
                                chats?.slice(-1)?.[0]?.conversationType?.toLowerCase() ===
                                "show_replenishment"
                            ) {
                                if (selectedProducts?.length > 0) {
                                    handleSendMessage(
                                        "Create purchase order(s) for the selected item(s)",
                                        chatId
                                    );
                                } else {
                                    toast.custom((t) => (
                                        <CustomToast
                                            t={t}
                                            message={"Please select at least one item to create PO"}
                                            type={"error"}
                                        />
                                    ));
                                }
                                return;
                            }

                            if (message?.trim() !== "") {
                                handleSendMessage(message, chatId);
                            }
                        }}
                    >
                        <i className="ri-send-plane-2-fill cursor-pointer bg-clip-text text-transparent text-xl bg-white"></i>
                    </div>
                </div>
                <span className="text-center text-xs text-slate-500 py-4">
                    As a model still in training, Synth AI can still make some errors.{" "}
                </span>
            </div>
        </div>
    );
};

export default AIChatComp;
