import React from "react";
import CustomToast from "components/common/CustomToast";
import { toast } from "react-hot-toast";
import { inventoryTypes } from "redux/Ldb/types";
import { inventoryService } from "services/Ldb";
import { Dispatch } from "redux";

export const inventoryActions = {
    addSingleProduct,
    addMultipleProducts,
    resetAddSingleProductSuccess,
    fetchStockList,
    fetchSingleInventory,
    addProductToStockCount,
    fetchWarehouseList,
    createWarehouse,
    updateWarehouse,
    fetchSearchInventoryListing,
    fetchSearchProductListing,
    fetchSingleWarehouseList,
    deleteWarehouse,
    resetDeleteWarehouse,
    resetCreateWarehouse,
    resetUpdateWarehouse,
    fetchListStockWarehouse,
    resetCreateWarehouseSuccess,
    resetAddMultipleProductsSuccess,
    fetchWarehouses,
    fetchInventoryList,
    resetAddProductToStockCount,
    fetchTransferStocks,
    transferStocks,
    resetTransferringStocks,
    transferStockFromWarehouse,
    resetResetTransferStockFromWarehouseSuccess,
    fetchSingleInventorySuppliers,
    fetchInventoryHistory,
    fetchSingleInventoryPO,
    fetchInventoryWarehouse,
    fetchInventoryOrders,
    updateSingleProduct,
    resetUpdateSingleProductSuccess,
    fetchSingleStockWarehouseList,
    fetchSingleStock,
    updateStock,
    resetUpdateStock,
    fetchSingleTransferStock,
    resetAddNewStockAdjustment,
    fetchSingleStockAdjusment,
    fetchStockAdjustmentList,
    addNewStockAdjustment,
    fetchInventorySettings,
    addInventorySettings,
    updateInventorySettings,
    addProductVendor,
    resetAddProductVendorSuccess,
    addMultipleProductToStockCount,
    resetAddMultipleProductToStockCount,
    addSuppliedStock,
    updateSuppliedStock,
    fetchSingleSuppliedStock,
    fetchSuppliedStockList,
    resetAddSuppliedStock,
    resetUpdateSuppliedStock,
    fetchSuppliedStockListAllData,
    importSyncedProducts,

    // ============================
    fetchArchiveProducts,
    resetFetchArchiveProductsSuccess,
    fetchTopPerformingProducts,
    fetchLeastPerformingProducts,
    fetchLocationStockLevels,
    fetchProductSuppliers,
    fetchInventoryGrowthRate,
    fetchGMWValues,
    fetchSingleProductGMW,
    fetchSingleProductStockLevel,
    fetchSingleProductBom,
    resetFetchSingleProductBom,
    fetchSalesOrders,
    fetchProductsAnalytics,
    fetchEvents,
    fetchInventoryIncidents,
    fetchSingleInventoryIncidents,
    fetchWarehouseSearchList,
    fetchInventoryIncidentStocking,
    addIncidentComment,
    fetchInventoryIncidentComments,
    resetInventoryIncidentComment,
    fetchSalesBreakdown,
    fetchInventorySearchList,
    fetchExpirationTrackingList,
    resetFetchExpirationTrackingList,
    fetchReplishmentStockList,
    closeInventoryIncident,
    recentInventoryIncident,
    resetGetRecentIncident,
    validateBatchId,
    addForecastEvent,
    resetAddForecastEvent,
    updateForecastEvent,
    resetUpdateForecastEvent,
    deleteForecastEvent,
    resetDeleteForecastEvent,
    resetfetchStockList,
    resetFetchStockAdjustmentList,
    resetFetchStockTransferList,
    resetFetchInventoryList,
    resetFetchSalesOrders,
    resetFetchSingleProductStockLevels,
    resetFetchInventoryWarehouse,
    resetFetchProductSuppliers,
    resetFetchEvents,
    resetFetchInventoryIncidents,
    resetFetchWarehouseList,
    resetFetchInventoryIncidentComments,
    resetFetchInventoryIncidentStocking,
    fetchInventoryIncidentOverstocking,
    resetFetchInventoryIncidentOverstock,
    fetchOngoingSyncConnections,
    resetImportSyncedProducts,
    createSingleBundle,
    resetCreateSingleBundle,
    fetchListBundle,
    resetFetchListBundle,
    fetchSingleBundle,
    createMultipleBundles,
    resetCreateMultipleBundles,
    updateSingleBundle,
    resetUpdateSingleBundle,
    setArchiveStatus,
    resetSetArchiveStatusSuccess,
    getWarehouseStats,
    resetGetWarehouseStatsSuccess,
    fetchInventoryTotalStockValue,
    fetchInventoryInStockValue,
    fetchInventoryReStockValue,
    fetchInventoryOutStockValue,
    fetchInventoryAvgCostValue,
    editBundleData,
    resetEditBundle,
    resetfetchInventoryOutStockValue,
    resetfetchInventoryTotalStockValue,
    resetfetchInventoryInStockValue,
    resetfetchInventoryReStockValue,
    resetfetchInventoryAvgCostValue,
    fetchMappedSkuList,
    resetFetchMappedSkuListSuccess,
    fetchSingleMappedSku,
    createSkuMapping,
    updateSkuMapping,
    resetCreateSkuMappingSuccess,
    resetUpdateSkuMappingSuccess,
    fetchMappedSkuProductList,
    fetchMappedSkuProductSearchList,
    resetFetchMappedSkuProductList,
    exportInStockList,
    resetExportStockInList,
    exportOutStockList,
    resetExportStockOutList,
    exportInventoryList,
    resetExportInventoryListSuccess,
    exportAvgCostPerSkuList,
    resetExportAvgCostPerSkuListSuccess,
    createSingleBundleFromProduct,
    resetCreateSingleBundleFromProductSuccess,
};

// start synth 2.0 inventory actions
function fetchTopPerformingProducts(dateRange?: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(inventoryTypes.FETCH_TOP_PERFORMING_PRODUCTS_REQUEST));

        inventoryService.fetchTopPerformingProducts(dateRange).then(
            (res) => {
                dispatch(success(inventoryTypes.FETCH_TOP_PERFORMING_PRODUCTS_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(
                            inventoryTypes.FETCH_TOP_PERFORMING_PRODUCTS_FAILURE,
                            error?.message
                        )
                    );
                }
            }
        );
    };
}

function fetchSingleBundle(bundleId?: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(inventoryTypes.FETCH_BUNDLE_DETAILS_REQUEST));

        inventoryService.fetchSingleBundle(bundleId).then(
            (res) => {
                dispatch(success(inventoryTypes.FETCH_BUNDLE_DETAILS_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(inventoryTypes.FETCH_BUNDLE_DETAILS_FAILURE, error?.message));
                }
            }
        );
    };
}

function exportInStockList() {
    return (dispatch: Dispatch) => {
        dispatch(request(inventoryTypes.EXPORT_IN_STOCK_LIST_REQUEST));

        inventoryService.exportInStockList().then(
            (res) => {
                dispatch(success(inventoryTypes.EXPORT_IN_STOCK_LIST_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(inventoryTypes.EXPORT_IN_STOCK_LIST_FAILURE, error?.message));
                }
            }
        );
    };
}

function exportOutStockList() {
    return (dispatch: Dispatch) => {
        dispatch(request(inventoryTypes.EXPORT_OUT_STOCK_LIST_REQUEST));

        inventoryService.exportOutStockList().then(
            (res) => {
                dispatch(success(inventoryTypes.EXPORT_OUT_STOCK_LIST_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(inventoryTypes.EXPORT_OUT_STOCK_LIST_FAILURE, error?.message));
                }
            }
        );
    };
}

function exportInventoryList() {
    return (dispatch: Dispatch) => {
        dispatch(request(inventoryTypes.EXPORT_INVENTORY_LIST_REQUEST));

        inventoryService.exportInventoryList().then(
            (res) => {
                dispatch(success(inventoryTypes.EXPORT_INVENTORY_LIST_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(inventoryTypes.EXPORT_INVENTORY_LIST_FAILURE, error?.message));
                }
            }
        );
    };
}

function exportAvgCostPerSkuList() {
    return (dispatch: Dispatch) => {
        dispatch(request(inventoryTypes.EXPORT_AVG_COST_PER_SKU_REQUEST));

        inventoryService.exportAvgCostPerSku().then(
            (res) => {
                dispatch(success(inventoryTypes.EXPORT_AVG_COST_PER_SKU_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(inventoryTypes.EXPORT_AVG_COST_PER_SKU_FAILURE, error?.message)
                    );
                }
            }
        );
    };
}

function fetchInventoryIncidents(
    warehouseId?: string,
    limit?: number,
    page?: number,
    incidentType?: string,
    inventoryId?: string
) {
    return (dispatch: Dispatch) => {
        dispatch(request(inventoryTypes.FETCH_INVENTORY_INCIDENTS_REQUEST));

        inventoryService
            .fetchInventoryIncidents(warehouseId, limit, page, incidentType, inventoryId)
            .then(
                (res) => {
                    dispatch(success(inventoryTypes.FETCH_INVENTORY_INCIDENTS_SUCCESS, res?.data));
                },
                (error) => {
                    if (error.message) {
                        toast.custom((t) => (
                            <CustomToast t={t} message={error?.message} type="error" />
                        ));
                        dispatch(
                            failure(
                                inventoryTypes.FETCH_INVENTORY_INCIDENTS_FAILURE,
                                error?.message
                            )
                        );
                    }
                }
            );
    };
}

function fetchSingleInventoryIncidents(
    incidentId?: string,
    limit?: number,
    page?: number | string
) {
    return (dispatch: Dispatch) => {
        dispatch(request(inventoryTypes.FETCH_SINGLE_INVENTORY_INCIDENT_REQUEST));

        inventoryService.fetchSingleInventoryIncidents(incidentId, limit, page).then(
            (res) => {
                dispatch(
                    success(inventoryTypes.FETCH_SINGLE_INVENTORY_INCIDENT_SUCCESS, res?.data)
                );
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(
                            inventoryTypes.FETCH_SINGLE_INVENTORY_INCIDENT_FAILURE,
                            error?.message
                        )
                    );
                }
            }
        );
    };
}

function fetchInventoryIncidentComments(
    incidentId?: string,
    limit?: number,
    page?: number | string
) {
    return (dispatch: Dispatch) => {
        dispatch(request(inventoryTypes.FETCH_INVENTORY_INCIDENT_COMMENT_REQUEST));

        inventoryService.fetchIncidentComment(incidentId, limit, page).then(
            (res) => {
                dispatch(
                    success(inventoryTypes.FETCH_INVENTORY_INCIDENT_COMMENT_SUCCESS, res?.data)
                );
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(
                            inventoryTypes.FETCH_INVENTORY_INCIDENT_COMMENT_FAILURE,
                            error?.message
                        )
                    );
                }
            }
        );
    };
}

function fetchInventoryIncidentStocking(
    warehouseId?: string,
    limit?: number,
    page?: number,
    incidentType?: string,
    inventoryId?: string
) {
    return (dispatch: Dispatch) => {
        dispatch(request(inventoryTypes.FETCH_INVENTORY_INCIDENTS_STOCKING_REQUEST));

        inventoryService
            .fetchInventoryIncidents(warehouseId, limit, page, incidentType, inventoryId)
            .then(
                (res) => {
                    dispatch(
                        success(
                            inventoryTypes.FETCH_INVENTORY_INCIDENTS_STOCKING_SUCCESS,
                            res?.data
                        )
                    );
                },
                (error) => {
                    if (error.message) {
                        toast.custom((t) => (
                            <CustomToast t={t} message={error?.message} type="error" />
                        ));
                        dispatch(
                            failure(
                                inventoryTypes.FETCH_INVENTORY_INCIDENTS_STOCKING_FAILURE,
                                error?.message
                            )
                        );
                    }
                }
            );
    };
}

function fetchInventoryIncidentOverstocking(
    warehouseId?: string,
    limit?: number,
    page?: number,
    incidentType?: string,
    inventoryId?: string
) {
    return (dispatch: Dispatch) => {
        dispatch(request(inventoryTypes.FETCH_INVENTORY_INCIDENTS_OVER_STOCK_REQUEST));

        inventoryService
            .fetchInventoryIncidents(warehouseId, limit, page, incidentType, inventoryId)
            .then(
                (res) => {
                    dispatch(
                        success(
                            inventoryTypes.FETCH_INVENTORY_INCIDENTS_OVER_STOCK_SUCCESS,
                            res?.data
                        )
                    );
                },
                (error) => {
                    if (error.message) {
                        toast.custom((t) => (
                            <CustomToast t={t} message={error?.message} type="error" />
                        ));
                        dispatch(
                            failure(
                                inventoryTypes.FETCH_INVENTORY_INCIDENTS_OVER_STOCK_FAILURE,
                                error?.message
                            )
                        );
                    }
                }
            );
    };
}

function fetchLeastPerformingProducts(dateRange?: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(inventoryTypes.FETCH_LEAST_PERFORMING_PRODUCTS_REQUEST));

        inventoryService.fetchLeastPerformingProducts(dateRange).then(
            (res) => {
                dispatch(
                    success(inventoryTypes.FETCH_LEAST_PERFORMING_PRODUCTS_SUCCESS, res?.data)
                );
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(
                            inventoryTypes.FETCH_LEAST_PERFORMING_PRODUCTS_FAILURE,
                            error?.message
                        )
                    );
                }
            }
        );
    };
}

function fetchLocationStockLevels(dateRange?: string, inventoryId?: string, warehouseId?: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(inventoryTypes.FETCH_LOCATION_STOCK_LEVELS_REQUEST));

        inventoryService.fetchLocationStockLevels(dateRange, inventoryId, warehouseId).then(
            (res) => {
                dispatch(success(inventoryTypes.FETCH_LOCATION_STOCK_LEVELS_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(inventoryTypes.FETCH_LOCATION_STOCK_LEVELS_FAILURE, error?.message)
                    );
                }
            }
        );
    };
}

function fetchProductSuppliers(
    limit: number,
    page: number,
    productId: string,
    risk?: string,
    search?: string
) {
    return (dispatch: Dispatch) => {
        dispatch(request(inventoryTypes.FETCH_PRODUCT_SUPPLIERS_REQUEST));

        inventoryService.fetchProductSuppliers(limit, page, productId, risk, search).then(
            (res) => {
                dispatch(success(inventoryTypes.FETCH_PRODUCT_SUPPLIERS_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(inventoryTypes.FETCH_PRODUCT_SUPPLIERS_FAILURE, error?.message)
                    );
                }
            }
        );
    };
}

function fetchListBundle(limit: number, page: number, search?: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(inventoryTypes.FETCH_LIST_BUNDLE_REQUEST));

        inventoryService.fetchListBundle(limit, page, search).then(
            (res) => {
                dispatch(success(inventoryTypes.FETCH_LIST_BUNDLE_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(inventoryTypes.FETCH_LIST_BUNDLE_FAILURE, error?.message));
                }
            }
        );
    };
}

function fetchInventoryGrowthRate(dateRange: string, inventoryId?: string, warehouseId?: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(inventoryTypes.FETCH_INVENTORY_GROWTH_RATE_REQUEST));

        inventoryService.fetchInventoryGrowthRate(dateRange, inventoryId, warehouseId).then(
            (res) => {
                dispatch(success(inventoryTypes.FETCH_INVENTORY_GROWTH_RATE_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(inventoryTypes.FETCH_INVENTORY_GROWTH_RATE_FAILURE, error?.message)
                    );
                }
            }
        );
    };
}

function fetchGMWValues(dateRange: string, inventoryId?: string, warehouseId?: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(inventoryTypes.FETCH_GMW_VALUES_REQUEST));

        inventoryService.fetchGMWValues(dateRange, inventoryId, warehouseId).then(
            (res) => {
                dispatch(success(inventoryTypes.FETCH_GMW_VALUES_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(inventoryTypes.FETCH_GMW_VALUES_FAILURE, error?.message));
                }
            }
        );
    };
}

function fetchSingleProductGMW(productId: string, dateRange?: string, warehouseId?: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(inventoryTypes.FETCH_SINGLE_PRODUCT_GMW_REQUEST));

        inventoryService.fetchSingleProductGMWValues(productId, dateRange, warehouseId).then(
            (res) => {
                dispatch(success(inventoryTypes.FETCH_SINGLE_PRODUCT_GMW_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(inventoryTypes.FETCH_SINGLE_PRODUCT_GMW_FAILURE, error?.message)
                    );
                }
            }
        );
    };
}

function fetchSingleProductStockLevel(
    productId: string,
    dateRange?: string,
    inventoryId?: string,
    warehouseId?: string,
    limit?: number,
    page?: number
) {
    return (dispatch: Dispatch) => {
        dispatch(request(inventoryTypes.FETCH_SINGLE_PRODUCT_STOCK_LEVELS_REQUEST));

        inventoryService
            .fetchSingleProductStockLevels(
                productId,
                dateRange,
                inventoryId,
                warehouseId,
                limit,
                page
            )
            .then(
                (res) => {
                    dispatch(
                        success(inventoryTypes.FETCH_SINGLE_PRODUCT_STOCK_LEVELS_SUCCESS, res?.data)
                    );
                },
                (error) => {
                    if (error.message) {
                        toast.custom((t) => (
                            <CustomToast t={t} message={error?.message} type="error" />
                        ));
                        dispatch(
                            failure(
                                inventoryTypes.FETCH_SINGLE_PRODUCT_STOCK_LEVELS_FAILURE,
                                error?.message
                            )
                        );
                    }
                }
            );
    };
}

function fetchSingleProductBom(
    productId: string,
    dateRange?: string,
    inventoryId?: string,
    warehouseId?: string
) {
    return (dispatch: Dispatch) => {
        dispatch(request(inventoryTypes.FETCH_SINGLE_PRODUCT_BOM_REQUEST));

        inventoryService.fetchSingleProductBom(productId, dateRange, inventoryId, warehouseId).then(
            (res) => {
                dispatch(success(inventoryTypes.FETCH_SINGLE_PRODUCT_BOM_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(inventoryTypes.FETCH_SINGLE_PRODUCT_BOM_FAILURE, error?.message)
                    );
                }
            }
        );
    };
}

function fetchSalesOrders(
    dateRange?: string,
    limit?: number,
    page?: number,
    warehouseId?: string,
    inventoryId?: string
) {
    return (dispatch: Dispatch) => {
        dispatch(request(inventoryTypes.FETCH_SALES_ORDERS_REQUEST));

        inventoryService.fetchSalesOrders(dateRange, limit, page, warehouseId, inventoryId).then(
            (res) => {
                dispatch(success(inventoryTypes.FETCH_SALES_ORDERS_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(inventoryTypes.FETCH_SALES_ORDERS_FAILURE, error?.message));
                }
            }
        );
    };
}

function fetchProductsAnalytics() {
    return (dispatch: Dispatch) => {
        dispatch(request(inventoryTypes.FETCH_PRODUCTS_ANALYTICS_REQUEST));

        inventoryService.fetchProductsAnalytics().then(
            (res) => {
                dispatch(success(inventoryTypes.FETCH_PRODUCTS_ANALYTICS_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(inventoryTypes.FETCH_PRODUCTS_ANALYTICS_FAILURE, error?.message)
                    );
                }
            }
        );
    };
}
function fetchInventoryTotalStockValue(
    warehouseId?: string,
    channel?: string,
    limit?: number,
    page?: number,
    startDate?: string,
    endDate?: string,
    search?: string,
    sortParams?: any
) {
    return (dispatch: Dispatch) => {
        dispatch(request(inventoryTypes.FETCH_TOTAL_STOCK_VALUE_REQUEST));

        inventoryService
            .fetchTotalStockValueTable(
                warehouseId,
                channel,
                limit,
                page,
                startDate,
                endDate,
                search,
                sortParams
            )
            .then(
                (res) => {
                    dispatch(success(inventoryTypes.FETCH_TOTAL_STOCK_VALUE_SUCCESS, res?.data));
                },
                (error) => {
                    if (error.message) {
                        // toast.custom((t) => (
                        //     <CustomToast t={t} message={error?.message} type="error" />
                        // ));
                        dispatch(
                            failure(inventoryTypes.FETCH_TOTAL_STOCK_VALUE_FAILURE, error?.message)
                        );
                    }
                }
            );
    };
}

function fetchInventoryInStockValue(
    warehouseId?: string,
    channel?: string,
    limit?: number,
    page?: number,
    startDate?: string,
    endDate?: string,
    search?: string,
    sortParams?: any
) {
    return (dispatch: Dispatch) => {
        dispatch(request(inventoryTypes.FETCH_IN_STOCK_VALUE_REQUEST));

        inventoryService
            .fetchInStockValueTable(
                warehouseId,
                channel,
                limit,
                page,
                startDate,
                endDate,
                search,
                sortParams
            )
            .then(
                (res) => {
                    dispatch(success(inventoryTypes.FETCH_IN_STOCK_VALUE_SUCCESS, res?.data));
                },
                (error) => {
                    if (error.message) {
                        // toast.custom((t) => (
                        //     <CustomToast t={t} message={error?.message} type="error" />
                        // ));
                        dispatch(
                            failure(inventoryTypes.FETCH_IN_STOCK_VALUE_FAILURE, error?.message)
                        );
                    }
                }
            );
    };
}

function fetchInventoryReStockValue(
    warehouseId?: string,
    channel?: string,
    limit?: number,
    page?: number,
    startDate?: string,
    endDate?: string,
    search?: string,
    sortParams?: any
) {
    return (dispatch: Dispatch) => {
        dispatch(request(inventoryTypes.FETCH_RE_STOCK_VALUE_REQUEST));

        inventoryService
            .fetchReStockValueTable(
                warehouseId,
                channel,
                limit,
                page,
                startDate,
                endDate,
                search,
                sortParams
            )
            .then(
                (res) => {
                    dispatch(success(inventoryTypes.FETCH_RE_STOCK_VALUE_SUCCESS, res?.data));
                },
                (error) => {
                    if (error.message) {
                        // toast.custom((t) => (
                        //     <CustomToast t={t} message={error?.message} type="error" />
                        // ));
                        dispatch(
                            failure(inventoryTypes.FETCH_RE_STOCK_VALUE_FAILURE, error?.message)
                        );
                    }
                }
            );
    };
}

function fetchInventoryOutStockValue(
    warehouseId?: string,
    channel?: string,
    limit?: number,
    page?: number,
    startDate?: string,
    endDate?: string,
    search?: string,
    sortParams?: any
) {
    return (dispatch: Dispatch) => {
        dispatch(request(inventoryTypes.FETCH_OUT_STOCK_VALUE_REQUEST));

        inventoryService
            .fetchOutStockValueTable(
                warehouseId,
                channel,
                limit,
                page,
                startDate,
                endDate,
                search,
                sortParams
            )
            .then(
                (res) => {
                    dispatch(success(inventoryTypes.FETCH_OUT_STOCK_VALUE_SUCCESS, res?.data));
                },
                (error) => {
                    if (error.message) {
                        // toast.custom((t) => (
                        //     <CustomToast t={t} message={error?.message} type="error" />
                        // ));
                        dispatch(
                            failure(inventoryTypes.FETCH_OUT_STOCK_VALUE_FAILURE, error?.message)
                        );
                    }
                }
            );
    };
}

function fetchInventoryAvgCostValue(
    warehouseId?: string,
    channel?: string,
    limit?: number,
    page?: number,
    startDate?: string,
    endDate?: string,
    search?: string,
    sortParams?: any
) {
    return (dispatch: Dispatch) => {
        dispatch(request(inventoryTypes.FETCH_AVG_COST_VALUE_REQUEST));

        inventoryService
            .fetchAvgCostValueTable(
                warehouseId,
                channel,
                limit,
                page,
                startDate,
                endDate,
                search,
                sortParams
            )
            .then(
                (res) => {
                    dispatch(success(inventoryTypes.FETCH_AVG_COST_VALUE_SUCCESS, res?.data));
                },
                (error) => {
                    if (error.message) {
                        // toast.custom((t) => (
                        //     <CustomToast t={t} message={error?.message} type="error" />
                        // ));
                        dispatch(
                            failure(inventoryTypes.FETCH_AVG_COST_VALUE_FAILURE, error?.message)
                        );
                    }
                }
            );
    };
}

function fetchEvents(
    inventoryId?: string,
    search?: string,
    limit?: number,
    page?: number,
    eventCategory?: string,
    eventImpact?: string
) {
    return (dispatch: Dispatch) => {
        dispatch(request(inventoryTypes.FETCH_EVENTS_REQUEST));

        inventoryService
            .fetchEvents(inventoryId, search, limit, page, eventCategory, eventImpact)
            .then(
                (res) => {
                    dispatch(success(inventoryTypes.FETCH_EVENTS_SUCCESS, res?.data));
                },
                (error) => {
                    if (error.message) {
                        // toast.custom((t) => (
                        //     <CustomToast t={t} message={error?.message} type="error" />
                        // ));
                        dispatch(failure(inventoryTypes.FETCH_EVENTS_FAILURE, error?.message));
                    }
                }
            );
    };
}

function addIncidentComment(data: Record<string, any> | Record<string, any>[]) {
    return (dispatch: Dispatch) => {
        dispatch(request(inventoryTypes.ADD_INVENTORY_INCIDENT_COMMENT_REQUEST));
        inventoryService.addIncidentComment(data).then(
            (res) => {
                dispatch(success(inventoryTypes.ADD_INVENTORY_INCIDENT_COMMENT_SUCCESS, res?.data));
                toast.custom((t) => (
                    <CustomToast t={t} message={"Comment added successfully"} type="success" />
                ));
            },
            (error) => {
                if (error?.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(
                            inventoryTypes.ADD_INVENTORY_INCIDENT_COMMENT_FAILURE,
                            error?.message
                        )
                    );
                }
            }
        );
    };
}

function addForecastEvent(data: Record<string, any> | Record<string, any>[]) {
    return (dispatch: Dispatch) => {
        dispatch(request(inventoryTypes.ADD_FORECAST_EVENT_REQUEST));

        inventoryService.addForecastEvent(data).then(
            (res) => {
                dispatch(success(inventoryTypes.ADD_FORECAST_EVENT_SUCCESS, { ...res?.data }));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));

                    dispatch(failure(inventoryTypes.ADD_FORECAST_EVENT_FAILURE, error?.message));
                }
            }
        );
    };
}

function updateForecastEvent(data: Record<string, any> | Record<string, any>[], eventId: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(inventoryTypes.UPDATE_FORECAST_EVENT_REQUEST));

        inventoryService.updateForecastEvent(data, eventId).then(
            (res) => {
                dispatch(success(inventoryTypes.UPDATE_FORECAST_EVENT_SUCCESS, res?.data || null));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));

                    dispatch(failure(inventoryTypes.UPDATE_FORECAST_EVENT_FAILURE, error?.message));
                }
            }
        );
    };
}

function deleteForecastEvent(eventId: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(inventoryTypes.DELETE_FORECAST_EVENT_REQUEST));

        inventoryService.deleteForecastEvent(eventId).then(
            (res) => {
                dispatch(
                    success(inventoryTypes.DELETE_FORECAST_EVENT_SUCCESS, res?.success || null)
                );
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));

                    dispatch(failure(inventoryTypes.DELETE_FORECAST_EVENT_FAILURE, error?.message));
                }
            }
        );
    };
}

function fetchSalesBreakdown(warehouseId: string, inventoryId: string, dateRange: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(inventoryTypes.FETCH_SALES_BREAKDOWN_REQUEST));

        inventoryService.fetchSalesBreakdown(warehouseId, inventoryId, dateRange).then(
            (res) => {
                dispatch(success(inventoryTypes.FETCH_SALES_BREAKDOWN_SUCCESS, { ...res?.data }));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));

                    dispatch(failure(inventoryTypes.FETCH_SALES_BREAKDOWN_FAILURE, error?.message));
                }
            }
        );
    };
}

function fetchExpirationTrackingList(
    search?: string,
    warehouseId?: string,
    limit?: number | string,
    page?: number | string
) {
    return (dispatch: Dispatch) => {
        dispatch(request(inventoryTypes.FETCH_EXPIRATION_LIST_REQUEST));

        inventoryService.fetchExpirationList(search, warehouseId, limit, page).then(
            (res) => {
                dispatch(success(inventoryTypes.FETCH_EXPIRATION_LIST_SUCCESS, { ...res?.data }));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));

                    dispatch(failure(inventoryTypes.FETCH_EXPIRATION_LIST_FAILURE, error?.message));
                }
            }
        );
    };
}

function fetchReplishmentStockList(incidentId: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(inventoryTypes.FETCH_STOCK_REPLISHMENT_LIST_REQUEST));

        inventoryService.fetchReplishmentStockList(incidentId).then(
            (res) => {
                dispatch(success(inventoryTypes.FETCH_STOCK_REPLISHMENT_LIST_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(inventoryTypes.FETCH_STOCK_REPLISHMENT_LIST_FAILURE, error?.message)
                    );
                }
            }
        );
    };
}

function closeInventoryIncident(incidentId: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(inventoryTypes.CLOSE_INVENTORY_INCIDENT_REQUEST));

        inventoryService.closeInventoryIncident(incidentId).then(
            (res) => {
                dispatch(success(inventoryTypes.CLOSE_INVENTORY_INCIDENT_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(inventoryTypes.CLOSE_INVENTORY_INCIDENT_FAILURE, error?.message)
                    );
                }
            }
        );
    };
}

function recentInventoryIncident(incidentId: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(inventoryTypes.RECENT_INVENTORY_INCIDENT_REQUEST));

        inventoryService.recentInventoryIncident(incidentId).then(
            (res) => {
                dispatch(success(inventoryTypes.RECENT_INVENTORY_INCIDENT_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(inventoryTypes.RECENT_INVENTORY_INCIDENT_FAILURE, error?.message)
                    );
                }
            }
        );
    };
}
// end synth 2.0 inventory actions

function addSingleProduct(data: Record<string, any> | Record<string, any>[]) {
    return (dispatch: Dispatch) => {
        dispatch(request(inventoryTypes.ADD_SINGLE_PRODUCT_REQUEST));
        inventoryService.addSingleProduct(data).then(
            (res) => {
                dispatch(success(inventoryTypes.ADD_SINGLE_PRODUCT_SUCCESS, res?.data));
                toast.custom((t) => (
                    <CustomToast t={t} message={"Inventory entry saved"} type="success" />
                ));
            },
            (error) => {
                if (error?.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(inventoryTypes.ADD_SINGLE_PRODUCT_FAILURE, error?.message));
                }
            }
        );
    };
}

function updateSingleProduct(data, productId) {
    return (dispatch: Dispatch) => {
        dispatch(request(inventoryTypes.UPDATE_SINGLE_PRODUCT_REQUEST));

        inventoryService.updateSingleProduct(data, productId).then(
            (res) => {
                dispatch(success(inventoryTypes.UPDATE_SINGLE_PRODUCT_SUCCESS, res?.data));
                toast.custom((t) => (
                    <CustomToast t={t} message={"Update successful"} type="success" />
                ));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(inventoryTypes.UPDATE_SINGLE_PRODUCT_FAILURE, error?.message));
                }
            }
        );
    };
}

function addMultipleProducts(data: Record<string, any> | Record<string, any>[]) {
    return (dispatch: Dispatch) => {
        dispatch(request(inventoryTypes.ADD_MULTIPLE_PRODUCTS_REQUEST));
        inventoryService.addMultipleProducts(data).then(
            (res) => {
                dispatch(success(inventoryTypes.ADD_MULTIPLE_PRODUCTS_SUCCESS, res?.data));
                toast.custom((t) => (
                    <CustomToast t={t} message={"Inventory entries saved"} type="success" />
                ));
            },
            (error) => {
                if (error?.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(inventoryTypes.ADD_MULTIPLE_PRODUCTS_FAILURE, error?.message));
                }
            }
        );
    };
}

function transferStockFromWarehouse(data: Record<string, any> | Record<string, any>[]) {
    return (dispatch: Dispatch) => {
        dispatch(request(inventoryTypes.TRANSFER_STOCK_FROM_WAREHOUSE_REQUEST));
        inventoryService.transferStockFromWarehouse(data).then(
            (res) => {
                dispatch(success(inventoryTypes.TRANSFER_STOCK_FROM_WAREHOUSE_SUCCESS, res?.data));
            },
            (error) => {
                if (error?.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(
                            inventoryTypes.TRANSFER_STOCK_FROM_WAREHOUSE_FAILURE,
                            error?.message
                        )
                    );
                }
            }
        );
    };
}

function fetchStockList(
    limit?: number,
    search?: string,
    warehouseId?: string,
    category?: string,
    page?: number
) {
    return (dispatch: Dispatch) => {
        dispatch(request(inventoryTypes.FETCH_STOCK_LIST_REQUEST));

        inventoryService.getStockList(limit, search, warehouseId, category, page).then(
            (res) => {
                dispatch(success(inventoryTypes.FETCH_STOCK_LIST_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(inventoryTypes.FETCH_STOCK_LIST_FAILURE, error?.message));
                }
            }
        );
    };
}

function fetchSingleInventory(id: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(inventoryTypes.FETCH_SINGLE_INVENTORY_REQUEST));

        inventoryService.getSingleInventory(id).then(
            (res) => {
                dispatch(success(inventoryTypes.FETCH_SINGLE_INVENTORY_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(inventoryTypes.FETCH_SINGLE_INVENTORY_FAILURE, error?.message)
                    );
                }
            }
        );
    };
}

function fetchInventorySettings(id: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(inventoryTypes.FETCH_INVENTORY_SETTINGS_REQUEST));

        inventoryService.fetchInventorySettings(id).then(
            (res) => {
                dispatch(success(inventoryTypes.FETCH_INVENTORY_SETTINGS_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    if (error.message !== "Stock Setting not found") {
                        toast.custom((t) => (
                            <CustomToast t={t} message={error?.message} type="error" />
                        ));
                    }
                    dispatch(
                        failure(inventoryTypes.FETCH_INVENTORY_SETTINGS_FAILURE, error?.message)
                    );
                }
            }
        );
    };
}

function addInventorySettings(data) {
    return (dispatch: Dispatch) => {
        dispatch(request(inventoryTypes.ADD_INVENTORY_SETTINGS_REQUEST));

        inventoryService.addInventorySettings(data).then(
            (res) => {
                dispatch(success(inventoryTypes.ADD_INVENTORY_SETTINGS_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(inventoryTypes.ADD_INVENTORY_SETTINGS_FAILURE, error?.message)
                    );
                }
            }
        );
    };
}

function updateInventorySettings(data, id) {
    return (dispatch: Dispatch) => {
        dispatch(request(inventoryTypes.UPDATE_INVENTORY_SETTINGS_REQUEST));

        inventoryService.updateInventorySettings(data, id).then(
            (res) => {
                dispatch(success(inventoryTypes.UPDATE_INVENTORY_SETTINGS_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(inventoryTypes.UPDATE_INVENTORY_SETTINGS_FAILURE, error?.message)
                    );
                }
            }
        );
    };
}

function addProductToStockCount(data) {
    return (dispatch: Dispatch) => {
        dispatch(request(inventoryTypes.ADD_PRODUCT_TO_STOCK_COUNT_REQUEST));

        inventoryService.addProductToStockCount(data).then(
            (res) => {
                dispatch(success(inventoryTypes.ADD_PRODUCT_TO_STOCK_COUNT_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(inventoryTypes.ADD_PRODUCT_TO_STOCK_COUNT_FAILURE, error?.message)
                    );
                }
            }
        );
    };
}

function addMultipleProductToStockCount(data) {
    return (dispatch: Dispatch) => {
        dispatch(request(inventoryTypes.ADD_MULTIPLE_PRODUCT_TO_STOCK_COUNT_REQUEST));

        inventoryService.addMultipleProductToStockCount(data).then(
            (res) => {
                dispatch(
                    success(inventoryTypes.ADD_MULTIPLE_PRODUCT_TO_STOCK_COUNT_SUCCESS, res?.data)
                );
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(
                            inventoryTypes.ADD_MULTIPLE_PRODUCT_TO_STOCK_COUNT_FAILURE,
                            error?.message
                        )
                    );
                }
            }
        );
    };
}

function fetchWarehouseList(limit: number, page?: number | string, search?: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(inventoryTypes.FETCH_WAREHOUSE_LIST_REQUEST));

        inventoryService.fetchWarehouseList(limit, page, search).then(
            (res) => {
                dispatch(success(inventoryTypes.FETCH_WAREHOUSE_LIST_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(inventoryTypes.FETCH_WAREHOUSE_LIST_FAILURE, error?.message));
                }
            }
        );
    };
}

function fetchWarehouseSearchList(limit: number, search?: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(inventoryTypes.FETCH_WAREHOUSE_SEARCH_LIST_REQUEST));

        inventoryService.fetchWarehouseList(limit, "", search).then(
            (res) => {
                dispatch(success(inventoryTypes.FETCH_WAREHOUSE_SEARCH_LIST_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(inventoryTypes.FETCH_WAREHOUSE_SEARCH_LIST_FAILURE, error?.message)
                    );
                }
            }
        );
    };
}

function fetchWarehouses(limit?: number, search?: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(inventoryTypes.FETCH_WAREHOUSES_REQUEST));
        inventoryService.getWarehouses(limit, search).then(
            (res) => {
                dispatch(success(inventoryTypes.FETCH_WAREHOUSES_SUCCESS, res?.data));
            },
            (error) => {
                if (error?.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(inventoryTypes.FETCH_WAREHOUSES_FAILURE, error?.message));
                }
            }
        );
    };
}

function createWarehouse(data) {
    return (dispatch: Dispatch) => {
        dispatch(request(inventoryTypes.CREATE_WAREHOUSE_REQUEST));

        inventoryService.createWarehouse(data).then(
            (res) => {
                dispatch(success(inventoryTypes.CREATE_WAREHOUSE_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(inventoryTypes.CREATE_WAREHOUSE_FAILURE, error?.message));
                }
            }
        );
    };
}

function createSkuMapping(data) {
    return (dispatch: Dispatch) => {
        dispatch(request(inventoryTypes.CREATE_SKU_MAPPING_REQUEST));

        inventoryService.createSkuMapping(data).then(
            (res) => {
                dispatch(success(inventoryTypes.CREATE_SKU_MAPPING_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(inventoryTypes.CREATE_SKU_MAPPING_FAILURE, error?.message));
                }
            }
        );
    };
}

function updateSkuMapping(id, data) {
    return (dispatch: Dispatch) => {
        dispatch(request(inventoryTypes.UPDATE_SKU_MAPPING_REQUEST));

        inventoryService.updateSkuMapping(id, data).then(
            (res) => {
                dispatch(success(inventoryTypes.UPDATE_SKU_MAPPING_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(inventoryTypes.UPDATE_SKU_MAPPING_FAILURE, error?.message));
                }
            }
        );
    };
}

function createSingleBundle(data) {
    return (dispatch: Dispatch) => {
        dispatch(request(inventoryTypes.CREATE_SINGLE_BUNDLE_REQUEST));

        inventoryService.createSingleBundle(data).then(
            (res) => {
                dispatch(success(inventoryTypes.CREATE_SINGLE_BUNDLE_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(inventoryTypes.CREATE_SINGLE_BUNDLE_FAILURE, error?.message));
                }
            }
        );
    };
}

function createSingleBundleFromProduct(data, inventoryId: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(inventoryTypes.CREATE_SINGLE_BUNDLE_FROM_PRODUCT_REQUEST));

        inventoryService.createSingleBundleFromProduct(data, inventoryId).then(
            (res) => {
                dispatch(
                    success(inventoryTypes.CREATE_SINGLE_BUNDLE_FROM_PRODUCT_SUCCESS, res?.data)
                );
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(
                            inventoryTypes.CREATE_SINGLE_BUNDLE_FROM_PRODUCT_FAILURE,
                            error?.message
                        )
                    );
                }
            }
        );
    };
}

function updateSingleBundle(
    data: Record<string, any> | Array<Record<string, any>>,
    bundleId: string
) {
    return (dispatch: Dispatch) => {
        dispatch(request(inventoryTypes.UPDATE_SINGLE_BUNDLE_REQUEST));

        inventoryService.updateSingleBundle(data, bundleId).then(
            (res) => {
                dispatch(success(inventoryTypes.UPDATE_SINGLE_BUNDLE_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(inventoryTypes.UPDATE_SINGLE_BUNDLE_FAILURE, error?.message));
                }
            }
        );
    };
}

function fetchInventoryList(
    limit?: number,
    page?: number,
    search?: string,
    category?: string,
    channel?: string,
    tracking?: boolean,
    type?: 1 | -1
) {
    return (dispatch: Dispatch) => {
        dispatch(request(inventoryTypes.FETCH_INVENTORY_LIST_REQUEST));
        inventoryService
            .getInventoryList(limit, page, search, category, channel, tracking, type)
            .then(
                (res) => {
                    dispatch(success(inventoryTypes.FETCH_INVENTORY_LIST_SUCCESS, res?.data));
                },
                (error) => {
                    if (error?.message) {
                        toast.custom((t) => (
                            <CustomToast t={t} message={error?.message} type="error" />
                        ));
                        dispatch(
                            failure(inventoryTypes.FETCH_INVENTORY_LIST_FAILURE, error?.message)
                        );
                    }
                }
            );
    };
}

function fetchInventorySearchList(limit?: number, search?: string, category?: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(inventoryTypes.FETCH_INVENTORY_SEARCH_LIST_REQUEST));
        inventoryService.getInventoryList(limit, 1, search, category).then(
            (res) => {
                dispatch(success(inventoryTypes.FETCH_INVENTORY_SEARCH_LIST_SUCCESS, res?.data));
            },
            (error) => {
                if (error?.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(inventoryTypes.FETCH_INVENTORY_SEARCH_LIST_FAILURE, error?.message)
                    );
                }
            }
        );
    };
}

function updateWarehouse(id, data) {
    return (dispatch: Dispatch) => {
        dispatch(request(inventoryTypes.UPDATE_WAREHOUSE_REQUEST));

        inventoryService.updateWarehouse(id, data).then(
            (res) => {
                dispatch(success(inventoryTypes.UPDATE_WAREHOUSE_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(inventoryTypes.UPDATE_WAREHOUSE_FAILURE, error?.message));
                }
            }
        );
    };
}

function deleteWarehouse(id) {
    return (dispatch: Dispatch) => {
        dispatch(request(inventoryTypes.DELETE_WAREHOUSE_REQUEST));

        inventoryService.deleteWarehouse(id).then(
            (res) => {
                dispatch(success(inventoryTypes.DELETE_WAREHOUSE_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(inventoryTypes.DELETE_WAREHOUSE_FAILURE, error?.message));
                }
            }
        );
    };
}

function fetchSearchInventoryListing(
    limit: number,
    search?: string,
    location?: string,
    category?: string
) {
    return (dispatch: Dispatch) => {
        dispatch(request(inventoryTypes.FETCH_SEARCH_INVENTORY_LISTING_REQUEST));

        inventoryService.fetchSearchInventoryListing(limit, search, location, category).then(
            (res) => {
                dispatch(success(inventoryTypes.FETCH_SEARCH_INVENTORY_LISTING_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(
                            inventoryTypes.FETCH_SEARCH_INVENTORY_LISTING_FAILURE,
                            error?.message
                        )
                    );
                }
            }
        );
    };
}
function fetchSearchProductListing(
    limit: number,
    search?: string,
    data?: Record<string, any> | Array<Record<string, any>>
) {
    return (dispatch: Dispatch) => {
        dispatch(request(inventoryTypes.FETCH_SEARCH_PRODUCT_LISTING_REQUEST));

        inventoryService.fetchSearchProductListing(limit, search, data).then(
            (res) => {
                dispatch(success(inventoryTypes.FETCH_SEARCH_PRODUCT_LISTING_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(inventoryTypes.FETCH_SEARCH_PRODUCT_LISTING_FAILURE, error?.message)
                    );
                }
            }
        );
    };
}
function fetchSingleWarehouseList(
    id: string,
    limit?: number,
    search?: string,
    channel?: string,
    startDate?: Date,
    endDate?: Date
) {
    return (dispatch: Dispatch) => {
        dispatch(request(inventoryTypes.FETCH_SINGLE_WAREHOUSE_LIST_REQUEST));

        inventoryService
            .fetchSingleWarehouseList(id, limit, search, channel, startDate, endDate)
            .then(
                (res) => {
                    dispatch(
                        success(inventoryTypes.FETCH_SINGLE_WAREHOUSE_LIST_SUCCESS, res?.data)
                    );
                },
                (error) => {
                    if (error.message) {
                        toast.custom((t) => (
                            <CustomToast t={t} message={error?.message} type="error" />
                        ));
                        dispatch(
                            failure(
                                inventoryTypes.FETCH_SINGLE_WAREHOUSE_LIST_FAILURE,
                                error?.message
                            )
                        );
                    }
                }
            );
    };
}

function fetchSingleStockWarehouseList(id: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(inventoryTypes.FETCH_SINGLE_STOCK_WAREHOUSE_REQUEST));

        inventoryService.fetchSingleStockWarehouse(id).then(
            (res) => {
                dispatch(success(inventoryTypes.FETCH_SINGLE_STOCK_WAREHOUSE_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(inventoryTypes.FETCH_SINGLE_STOCK_WAREHOUSE_FAILURE, error?.message)
                    );
                }
            }
        );
    };
}

function fetchListStockWarehouse(
    id: string,
    limit: number,
    inventoryId?: string,
    search?: string,
    filter?: string,
    isExpired?: boolean
) {
    return (dispatch: Dispatch) => {
        dispatch(request(inventoryTypes.FETCH_LIST_STOCK_WAREHOUSE_REQUEST));

        inventoryService
            .fetchListStockWarehouse(id, inventoryId, limit, search, filter, isExpired)
            .then(
                (res) => {
                    dispatch(success(inventoryTypes.FETCH_LIST_STOCK_WAREHOUSE_SUCCESS, res?.data));
                },
                (error) => {
                    if (error.message) {
                        toast.custom((t) => (
                            <CustomToast t={t} message={error?.message} type="error" />
                        ));
                        dispatch(
                            failure(
                                inventoryTypes.FETCH_LIST_STOCK_WAREHOUSE_FAILURE,
                                error?.message
                            )
                        );
                    }
                }
            );
    };
}

function fetchSingleInventorySuppliers(id: string, limit: number, search?: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(inventoryTypes.FETCH_SINGLE_INVENTORY_SUPPLIERS_REQUEST));

        inventoryService.fetchSingleInventorySuppliers(id, limit, search).then(
            (res) => {
                dispatch(
                    success(inventoryTypes.FETCH_SINGLE_INVENTORY_SUPPLIERS_SUCCESS, res?.data)
                );
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(
                            inventoryTypes.FETCH_SINGLE_INVENTORY_SUPPLIERS_FAILURE,
                            error?.message
                        )
                    );
                }
            }
        );
    };
}
function fetchInventoryHistory(id: string, limit: number, filter?: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(inventoryTypes.FETCH_INVENTORY_HISTORY_REQUEST));

        inventoryService.fetchInventoryHistory(id, limit, filter).then(
            (res) => {
                dispatch(success(inventoryTypes.FETCH_INVENTORY_HISTORY_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(inventoryTypes.FETCH_INVENTORY_HISTORY_FAILURE, error?.message)
                    );
                }
            }
        );
    };
}

function fetchSingleInventoryPO(id: string, limit: number, filter?: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(inventoryTypes.FETCH_SINGLE_INVENTORY_PURCHASE_ORDER_REQUEST));

        inventoryService.fetchSingleInventoryPO(id, limit, filter).then(
            (res) => {
                dispatch(
                    success(inventoryTypes.FETCH_SINGLE_INVENTORY_PURCHASE_ORDER_SUCCESS, res?.data)
                );
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(
                            inventoryTypes.FETCH_SINGLE_INVENTORY_PURCHASE_ORDER_FAILURE,
                            error?.message
                        )
                    );
                }
            }
        );
    };
}

function fetchInventoryWarehouse(id: string, limit: number, search?: string, page?: number) {
    return (dispatch: Dispatch) => {
        dispatch(request(inventoryTypes.FETCH_INVENTORY_WAREHOUSE_REQUEST));

        inventoryService.fetchInventoryWarehouse(id, limit, search, page).then(
            (res) => {
                dispatch(success(inventoryTypes.FETCH_INVENTORY_WAREHOUSE_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(inventoryTypes.FETCH_INVENTORY_WAREHOUSE_FAILURE, error?.message)
                    );
                }
            }
        );
    };
}

function fetchInventoryOrders(id: string, limit: number, search?: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(inventoryTypes.FETCH_INVENTORY_ORDERS_REQUEST));

        inventoryService.fetchInventoryOrders(id, limit, search).then(
            (res) => {
                dispatch(success(inventoryTypes.FETCH_INVENTORY_ORDERS_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(inventoryTypes.FETCH_INVENTORY_ORDERS_FAILURE, error?.message)
                    );
                }
            }
        );
    };
}

function updateStock(id: string, data) {
    return (dispatch: Dispatch) => {
        dispatch(request(inventoryTypes.UPDATE_STOCK_REQUEST));

        inventoryService.editStock(id, data).then(
            (res) => {
                dispatch(success(inventoryTypes.UPDATE_STOCK_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(inventoryTypes.UPDATE_STOCK_FAILURE, error?.message));
                }
            }
        );
    };
}

function fetchSingleStock(id: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(inventoryTypes.FETCH_SINGLE_STOCK_REQUEST));

        inventoryService.fetchSingleStock(id).then(
            (res) => {
                dispatch(success(inventoryTypes.FETCH_SINGLE_STOCK_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(inventoryTypes.FETCH_SINGLE_STOCK_FAILURE, error?.message));
                }
            }
        );
    };
}
function fetchSingleTransferStock(id: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(inventoryTypes.FETCH_SINGLE_TRANSFER_STOCK_REQUEST));

        inventoryService.fetchSingleTransferStock(id).then(
            (res) => {
                dispatch(success(inventoryTypes.FETCH_SINGLE_TRANSFER_STOCK_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(inventoryTypes.FETCH_SINGLE_TRANSFER_STOCK_FAILURE, error?.message)
                    );
                }
            }
        );
    };
}

function fetchTransferStocks(
    limit: number,
    page: number,
    search?: string,
    warehouseId?: string,
    category?: string
) {
    return (dispatch: Dispatch) => {
        dispatch(request(inventoryTypes.FETCH_TRANSFER_STOCKS_REQUEST));
        inventoryService.getTransferStocks(limit, page, search, warehouseId, category).then(
            (res) => {
                dispatch(success(inventoryTypes.FETCH_TRANSFER_STOCKS_SUCCESS, res?.data));
            },
            (error) => {
                if (error?.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(inventoryTypes.FETCH_TRANSFER_STOCKS_FAILURE, error?.message));
                }
            }
        );
    };
}

function transferStocks(data) {
    return (dispatch: Dispatch) => {
        dispatch(request(inventoryTypes.TRANSFER_STOCKS_REQUEST));
        inventoryService.transferStocks(data).then(
            (res) => {
                dispatch(success(inventoryTypes.TRANSFER_STOCKS_SUCCESS, res?.data));
            },
            (error) => {
                if (error?.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(inventoryTypes.TRANSFER_STOCKS_FAILURE, error?.message));
                }
            }
        );
    };
}
function addNewStockAdjustment(data) {
    return (dispatch: Dispatch) => {
        dispatch(request(inventoryTypes.ADD_NEW_STOCK_ADJUSTMENT_REQUEST));
        inventoryService.addNewStockAdjustment(data).then(
            (res) => {
                dispatch(success(inventoryTypes.ADD_NEW_STOCK_ADJUSTMENT_SUCCESS, res?.data));
            },
            (error) => {
                if (error?.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(inventoryTypes.ADD_NEW_STOCK_ADJUSTMENT_FAILURE, error?.message)
                    );
                }
            }
        );
    };
}
function addProductVendor(data) {
    return (dispatch: Dispatch) => {
        dispatch(request(inventoryTypes.ADD_PRODUCT_VENDOR_REQUEST));
        inventoryService.addProductVendor(data).then(
            (res) => {
                dispatch(success(inventoryTypes.ADD_PRODUCT_VENDOR_SUCCESS, res?.data));
            },
            (error) => {
                if (error?.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(inventoryTypes.ADD_PRODUCT_VENDOR_FAILURE, error?.message));
                }
            }
        );
    };
}
function fetchStockAdjustmentList(
    limit: number,
    page: number,
    search?: string,
    warehouseId?: string,
    category?: string
) {
    return (dispatch: Dispatch) => {
        dispatch(request(inventoryTypes.FETCH_STOCK_ADJUSTMENT_LIST_REQUEST));
        inventoryService.fetchStockAdjustment(limit, page, search, warehouseId, category).then(
            (res) => {
                dispatch(success(inventoryTypes.FETCH_STOCK_ADJUSTMENT_LIST_SUCCESS, res?.data));
            },
            (error) => {
                if (error?.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(inventoryTypes.FETCH_STOCK_ADJUSTMENT_LIST_FAILURE, error?.message)
                    );
                }
            }
        );
    };
}
function fetchSingleStockAdjusment(id: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(inventoryTypes.FETCH_SINGLE_STOCK_ADJUSTMENT_REQUEST));
        inventoryService.fetchSingleStockAdjustment(id).then(
            (res) => {
                dispatch(success(inventoryTypes.FETCH_SINGLE_STOCK_ADJUSTMENT_SUCCESS, res?.data));
            },
            (error) => {
                if (error?.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(
                            inventoryTypes.FETCH_SINGLE_STOCK_ADJUSTMENT_FAILURE,
                            error?.message
                        )
                    );
                }
            }
        );
    };
}

function addSuppliedStock(data: Record<string, any> | Record<string, any>[]) {
    return (dispatch: Dispatch) => {
        dispatch(request(inventoryTypes.ADD_SUPPLIED_STOCK_REQUEST));
        inventoryService.addSuppliedStock(data).then(
            (res) => {
                dispatch(success(inventoryTypes.ADD_SUPPLIED_STOCK_SUCCESS, res?.data));
            },
            (error) => {
                if (error?.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(inventoryTypes.ADD_SUPPLIED_STOCK_FAILURE, error?.message));
                }
            }
        );
    };
}

function updateSuppliedStock(id: string, data: Record<string, any> | Record<string, any>[]) {
    return (dispatch: Dispatch) => {
        dispatch(request(inventoryTypes.UPDATE_SUPPLIED_STOCK_REQUEST));
        inventoryService.updateSuppliedStock(id, data).then(
            (res) => {
                dispatch(success(inventoryTypes.UPDATE_SUPPLIED_STOCK_SUCCESS, res?.data));
            },
            (error) => {
                if (error?.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(inventoryTypes.UPDATE_SUPPLIED_STOCK_FAILURE, error?.message));
                }
            }
        );
    };
}

function fetchSuppliedStockList(limit, search?: string, warehouseId?: string, category?: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(inventoryTypes.FETCH_SUPPLIED_STOCK_LIST_REQUEST));
        inventoryService.fetchSuppliedStockList(limit, search, warehouseId, category).then(
            (res) => {
                dispatch(success(inventoryTypes.FETCH_SUPPLIED_STOCK_LIST_SUCCESS, res?.data));
            },
            (error) => {
                if (error?.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(inventoryTypes.FETCH_SUPPLIED_STOCK_LIST_FAILURE, error?.message)
                    );
                }
            }
        );
    };
}

function fetchSuppliedStockListAllData(
    limit?: number,
    search?: string,
    warehouseId?: string,
    category?: string,
    page?: number
) {
    return (dispatch: Dispatch) => {
        dispatch(request(inventoryTypes.FETCH_SUPPLIED_STOCK_LIST_ALL_DATA_REQUEST));

        inventoryService
            .fetchSuppliedStockListAllData(limit, search, warehouseId, category, page)
            .then(
                (res) => {
                    dispatch(
                        success(
                            inventoryTypes.FETCH_SUPPLIED_STOCK_LIST_ALL_DATA_SUCCESS,
                            res?.data
                        )
                    );
                },
                (error) => {
                    if (error.message) {
                        toast.custom((t) => (
                            <CustomToast t={t} message={error?.message} type="error" />
                        ));
                        dispatch(
                            failure(
                                inventoryTypes.FETCH_SUPPLIED_STOCK_LIST_ALL_DATA_FAILURE,
                                error?.message
                            )
                        );
                    }
                }
            );
    };
}

function fetchSingleSuppliedStock(id) {
    return (dispatch: Dispatch) => {
        dispatch(request(inventoryTypes.FETCH_SINGLE_SUPPLIED_STOCK_REQUEST));
        inventoryService.fetchSingleSuppliedStock(id).then(
            (res) => {
                dispatch(success(inventoryTypes.FETCH_SINGLE_SUPPLIED_STOCK_SUCCESS, res?.data));
            },
            (error) => {
                if (error?.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(inventoryTypes.FETCH_SINGLE_SUPPLIED_STOCK_FAILURE, error?.message)
                    );
                }
            }
        );
    };
}

function importSyncedProducts(data) {
    return (dispatch: Dispatch) => {
        dispatch(request(inventoryTypes.IMPORT_SYNCED_PRODUCT_REQUEST));

        inventoryService.importSyncedProducts(data).then(
            (res) => {
                dispatch(success(inventoryTypes.IMPORT_SYNCED_PRODUCT_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(inventoryTypes.IMPORT_SYNCED_PRODUCT_FAILURE, error?.message));
                }
            }
        );
    };
}

function setArchiveStatus(data) {
    return (dispatch: Dispatch) => {
        dispatch(request(inventoryTypes.SET_ARCHIVE_STATUS_REQUEST));

        inventoryService.setArchiveStatus(data).then(
            (res) => {
                dispatch(success(inventoryTypes.SET_ARCHIVE_STATUS_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(inventoryTypes.SET_ARCHIVE_STATUS_FAILURE, error?.message));
                }
            }
        );
    };
}

function fetchArchiveProducts(limit?: number, search?: string, page?: number) {
    return (dispatch: Dispatch) => {
        dispatch(request(inventoryTypes.FETCH_ARCHIVE_PRODUCTS_REQUEST));

        inventoryService.fetchArchivedProducts(limit, page, search).then(
            (res) => {
                dispatch(success(inventoryTypes.FETCH_ARCHIVE_PRODUCTS_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(inventoryTypes.FETCH_ARCHIVE_PRODUCTS_FAILURE, error?.message)
                    );
                }
            }
        );
    };
}

function fetchOngoingSyncConnections() {
    return (dispatch: Dispatch) => {
        dispatch(request(inventoryTypes.FETCH_ONGOING_SYNC_CONNECTIONS_REQUEST));

        inventoryService.listOngoingSyncConnections().then(
            (res) => {
                dispatch(success(inventoryTypes.FETCH_ONGOING_SYNC_CONNECTIONS_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    // toast.custom((t) => (
                    //     <CustomToast t={t} message={error?.message} type="error" />
                    // ));
                    dispatch(
                        failure(
                            inventoryTypes.FETCH_ONGOING_SYNC_CONNECTIONS_FAILURE,
                            error?.message
                        )
                    );
                }
            }
        );
    };
}

function createMultipleBundles(data: Record<string, any> | Record<string, any>[]) {
    return (dispatch: Dispatch) => {
        dispatch(request(inventoryTypes.CREATE_MULTIPLE_BUNDLES_REQUEST));

        inventoryService.createMultipleBundles(data).then(
            (res) => {
                dispatch(success(inventoryTypes.CREATE_MULTIPLE_BUNDLES_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(inventoryTypes.CREATE_MULTIPLE_BUNDLES_FAILURE, error?.message)
                    );
                }
            }
        );
    };
}

function validateBatchId(id: string) {
    return async (dispatch) => {
        dispatch(request(inventoryTypes.VALIDATE_BATCHID_REQUEST));

        try {
            const res = await inventoryService.validateBatchId(id);
            dispatch(success(inventoryTypes.VALIDATE_BATCHID_SUCCESS, res?.data));
            return res;
        } catch (error) {
            if (error.message) {
                toast.custom((t) => <CustomToast t={t} message={error.message} type="error" />);
                dispatch(failure(inventoryTypes.VALIDATE_BATCHID_FAILURE, error.message));
            }
            throw error;
        }
    };
}

function getWarehouseStats(warehouseId: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(inventoryTypes.FETCH_WAREHOUSE_STATS_REQUEST));

        inventoryService.getWarehouseStats(warehouseId).then(
            (res) => {
                dispatch(success(inventoryTypes.FETCH_WAREHOUSE_STATS_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(inventoryTypes.FETCH_WAREHOUSE_STATS_FAILURE, error?.message));
                }
            }
        );
    };
}

function editBundleData(data) {
    return (dispatch: Dispatch) => {
        dispatch(request(inventoryTypes.EDIT_BUNDLE_REQUEST));

        inventoryService.editBundleDetails(data).then(
            (res) => {
                dispatch(success(inventoryTypes.EDIT_BUNDLE_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(inventoryTypes.EDIT_BUNDLE_FAILURE, error?.message));
                }
            }
        );
    };
}

function fetchMappedSkuList(page?: number, search?: string, warehouseId?: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(inventoryTypes.FETCH_MAPPED_SKU_LIST_REQUEST));

        inventoryService.getMappedSkuList(page, search, warehouseId).then(
            (res) => {
                dispatch(success(inventoryTypes.FETCH_MAPPED_SKU_LIST_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(inventoryTypes.FETCH_MAPPED_SKU_LIST_FAILURE, error?.message));
                }
            }
        );
    };
}

function fetchSingleMappedSku(productId: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(inventoryTypes.FETCH_SINGLE_MAPPED_SKU_REQUEST));

        inventoryService.getSingleMappedSku(productId).then(
            (res) => {
                dispatch(success(inventoryTypes.FETCH_SINGLE_MAPPED_SKU_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(inventoryTypes.FETCH_SINGLE_MAPPED_SKU_FAILURE, error?.message)
                    );
                }
            }
        );
    };
}

function fetchMappedSkuProductList(
    channel?: string,
    limit?: number,
    page?: number,
    search?: string,
    category?: string,

    tracking?: boolean,
    type?: 1 | -1
) {
    return (dispatch: Dispatch) => {
        dispatch(request(inventoryTypes.FETCH_SKU_MAPPING_PRODUCT_LIST_REQUEST));
        inventoryService
            .getMappedSkuProductList(channel, limit, page, search, category, tracking, type)
            .then(
                (res) => {
                    dispatch(
                        success(inventoryTypes.FETCH_SKU_MAPPING_PRODUCT_LIST_SUCCESS, res?.data)
                    );
                },
                (error) => {
                    if (error?.message) {
                        toast.custom((t) => (
                            <CustomToast t={t} message={error?.message} type="error" />
                        ));
                        dispatch(
                            failure(
                                inventoryTypes.FETCH_SKU_MAPPING_PRODUCT_LIST_FAILURE,
                                error?.message
                            )
                        );
                    }
                }
            );
    };
}

function fetchMappedSkuProductSearchList(limit?: number, search?: string, category?: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(inventoryTypes.FETCH_SKU_MAPPING_PRODUCT_SEARCH_LIST_REQUEST));
        inventoryService.getMappedSkuProductList("", limit, 1, search, category).then(
            (res) => {
                dispatch(
                    success(inventoryTypes.FETCH_SKU_MAPPING_PRODUCT_SEARCH_LIST_SUCCESS, res?.data)
                );
            },
            (error) => {
                if (error?.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(
                            inventoryTypes.FETCH_SKU_MAPPING_PRODUCT_SEARCH_LIST_FAILURE,
                            error?.message
                        )
                    );
                }
            }
        );
    };
}

function resetCreateSingleBundleFromProductSuccess() {
    return (dispatch: Dispatch) => {
        dispatch(success(inventoryTypes.CREATE_SINGLE_BUNDLE_FROM_PRODUCT_SUCCESS, null));
    };
}

function resetExportInventoryListSuccess() {
    return (dispatch: Dispatch) => {
        dispatch(success(inventoryTypes.EXPORT_INVENTORY_LIST_SUCCESS, null));
    };
}

function resetExportAvgCostPerSkuListSuccess() {
    return (dispatch: Dispatch) => {
        dispatch(success(inventoryTypes.EXPORT_AVG_COST_PER_SKU_SUCCESS, null));
    };
}

function resetExportStockInList() {
    return (dispatch: Dispatch) => {
        dispatch(success(inventoryTypes.EXPORT_IN_STOCK_LIST_SUCCESS, null));
    };
}

function resetExportStockOutList() {
    return (dispatch: Dispatch) => {
        dispatch(success(inventoryTypes.EXPORT_OUT_STOCK_LIST_SUCCESS, null));
    };
}

function resetFetchMappedSkuProductList() {
    return (dispatch: Dispatch) => {
        dispatch(success(inventoryTypes.FETCH_SKU_MAPPING_PRODUCT_LIST_SUCCESS, null));
    };
}

function resetCreateSkuMappingSuccess() {
    return (dispatch: Dispatch) => {
        dispatch(success(inventoryTypes.CREATE_SKU_MAPPING_SUCCESS, null));
    };
}
function resetUpdateSkuMappingSuccess() {
    return (dispatch: Dispatch) => {
        dispatch(success(inventoryTypes.UPDATE_SKU_MAPPING_SUCCESS, null));
    };
}
function resetFetchMappedSkuListSuccess() {
    return (dispatch: Dispatch) => {
        dispatch(success(inventoryTypes.FETCH_MAPPED_SKU_LIST_SUCCESS, null));
    };
}

function resetGetWarehouseStatsSuccess() {
    return (dispatch: Dispatch) => {
        dispatch(success(inventoryTypes.FETCH_WAREHOUSE_STATS_SUCCESS, null));
    };
}

function resetFetchExpirationTrackingList() {
    return (dispatch: Dispatch) => {
        dispatch(success(inventoryTypes.FETCH_EXPIRATION_LIST_SUCCESS, null));
    };
}

function resetFetchArchiveProductsSuccess() {
    return (dispatch: Dispatch) => {
        dispatch(success(inventoryTypes.FETCH_ARCHIVE_PRODUCTS_SUCCESS, null));
    };
}

function resetSetArchiveStatusSuccess() {
    return (dispatch: Dispatch) => {
        dispatch(success(inventoryTypes.SET_ARCHIVE_STATUS_SUCCESS, null));
    };
}

function resetFetchSingleProductBom() {
    return (dispatch: Dispatch) => {
        dispatch(success(inventoryTypes.FETCH_SINGLE_PRODUCT_BOM_SUCCESS, null));
    };
}

function resetUpdateSingleBundle() {
    return (dispatch: Dispatch) => {
        dispatch(success(inventoryTypes.UPDATE_SINGLE_BUNDLE_SUCCESS, null));
    };
}

function resetCreateSingleBundle() {
    return (dispatch: Dispatch) => {
        dispatch(success(inventoryTypes.CREATE_SINGLE_BUNDLE_SUCCESS, null));
    };
}

function resetCreateMultipleBundles() {
    return (dispatch: Dispatch) => {
        dispatch(success(inventoryTypes.CREATE_MULTIPLE_BUNDLES_SUCCESS, null));
    };
}

function resetImportSyncedProducts() {
    return (dispatch: Dispatch) => {
        dispatch(success(inventoryTypes.IMPORT_SYNCED_PRODUCT_SUCCESS, null));
    };
}

function resetFetchInventoryIncidentComments() {
    return (dispatch: Dispatch) => {
        dispatch(success(inventoryTypes.FETCH_INVENTORY_INCIDENT_COMMENT_SUCCESS, null));
    };
}

function resetFetchWarehouseList() {
    return (dispatch: Dispatch) => {
        dispatch(success(inventoryTypes.FETCH_WAREHOUSE_LIST_SUCCESS, null));
    };
}

function resetFetchListBundle() {
    return (dispatch: Dispatch) => {
        dispatch(success(inventoryTypes.FETCH_LIST_BUNDLE_SUCCESS, null));
    };
}

function resetFetchInventoryIncidents() {
    return (dispatch: Dispatch) => {
        dispatch(success(inventoryTypes.FETCH_INVENTORY_INCIDENTS_SUCCESS, null));
    };
}
function resetFetchInventoryIncidentStocking() {
    return (dispatch: Dispatch) => {
        dispatch(success(inventoryTypes.FETCH_INVENTORY_INCIDENTS_STOCKING_SUCCESS, null));
    };
}

function resetFetchInventoryIncidentOverstock() {
    return (dispatch: Dispatch) => {
        dispatch(success(inventoryTypes.FETCH_INVENTORY_INCIDENTS_OVER_STOCK_SUCCESS, null));
    };
}

function resetFetchEvents() {
    return (dispatch: Dispatch) => {
        dispatch(success(inventoryTypes.FETCH_EVENTS_SUCCESS, null));
    };
}

function resetFetchProductSuppliers() {
    return (dispatch: Dispatch) => {
        dispatch(success(inventoryTypes.FETCH_PRODUCT_SUPPLIERS_SUCCESS, null));
    };
}

function resetFetchInventoryWarehouse() {
    return (dispatch: Dispatch) => {
        dispatch(success(inventoryTypes.FETCH_INVENTORY_WAREHOUSE_SUCCESS, null));
    };
}

function resetFetchSingleProductStockLevels() {
    return (dispatch: Dispatch) => {
        dispatch(success(inventoryTypes.FETCH_SINGLE_PRODUCT_STOCK_LEVELS_SUCCESS, null));
    };
}

function resetFetchSalesOrders() {
    return (dispatch: Dispatch) => {
        dispatch(success(inventoryTypes.FETCH_SALES_ORDERS_SUCCESS, null));
    };
}

function resetFetchInventoryList() {
    return (dispatch: Dispatch) => {
        dispatch(success(inventoryTypes.FETCH_INVENTORY_LIST_SUCCESS, null));
    };
}

function resetUpdateForecastEvent() {
    return (dispatch: Dispatch) => {
        dispatch(success(inventoryTypes.UPDATE_FORECAST_EVENT_SUCCESS, null));
    };
}

function resetDeleteForecastEvent() {
    return (dispatch: Dispatch) => {
        dispatch(success(inventoryTypes.DELETE_FORECAST_EVENT_SUCCESS, null));
    };
}

function resetAddForecastEvent() {
    return (dispatch: Dispatch) => {
        dispatch(success(inventoryTypes.ADD_FORECAST_EVENT_SUCCESS, null));
    };
}

function resetInventoryIncidentComment() {
    return (dispatch: Dispatch) => {
        dispatch(success(inventoryTypes.ADD_INVENTORY_INCIDENT_COMMENT_SUCCESS, null));
    };
}
function resetDeleteWarehouse() {
    return (dispatch: Dispatch) => {
        dispatch(success(inventoryTypes.DELETE_WAREHOUSE_SUCCESS, null));
    };
}
function resetCreateWarehouse() {
    return (dispatch: Dispatch) => {
        dispatch(success(inventoryTypes.CREATE_WAREHOUSE_SUCCESS, null));
    };
}
function resetUpdateWarehouse() {
    return (dispatch: Dispatch) => {
        dispatch(success(inventoryTypes.UPDATE_WAREHOUSE_SUCCESS, null));
    };
}
function resetUpdateStock() {
    return (dispatch: Dispatch) => {
        dispatch(success(inventoryTypes.UPDATE_STOCK_SUCCESS, null));
    };
}

function resetResetTransferStockFromWarehouseSuccess() {
    return (dispatch: Dispatch) => {
        dispatch(success(inventoryTypes.TRANSFER_STOCK_FROM_WAREHOUSE_SUCCESS, null));
    };
}

function resetAddMultipleProductsSuccess() {
    return (dispatch: Dispatch) => {
        dispatch(success(inventoryTypes.ADD_MULTIPLE_PRODUCTS_SUCCESS, null));
    };
}

function resetCreateWarehouseSuccess() {
    return (dispatch: Dispatch) => {
        dispatch(success(inventoryTypes.CREATE_WAREHOUSE_SUCCESS, null));
    };
}
function resetGetRecentIncident() {
    return (dispatch: Dispatch) => {
        dispatch(success(inventoryTypes.RECENT_INVENTORY_INCIDENT_SUCCESS, null));
    };
}
function resetAddProductToStockCount() {
    return (dispatch: Dispatch) => {
        dispatch(success(inventoryTypes.ADD_PRODUCT_TO_STOCK_COUNT_SUCCESS, null));
    };
}

function resetAddMultipleProductToStockCount() {
    return (dispatch: Dispatch) => {
        dispatch(success(inventoryTypes.ADD_MULTIPLE_PRODUCT_TO_STOCK_COUNT_SUCCESS, null));
    };
}

function resetAddProductVendorSuccess() {
    return (dispatch: Dispatch) => {
        dispatch(success(inventoryTypes.ADD_PRODUCT_VENDOR_SUCCESS, null));
    };
}
function resetAddSingleProductSuccess() {
    return (dispatch: Dispatch) => {
        dispatch(success(inventoryTypes.ADD_SINGLE_PRODUCT_SUCCESS, null));
    };
}

function resetUpdateSingleProductSuccess() {
    return (dispatch: Dispatch) => {
        dispatch(success(inventoryTypes.UPDATE_SINGLE_PRODUCT_SUCCESS, null));
    };
}

function resetTransferringStocks() {
    return (dispatch: Dispatch) => {
        dispatch(success(inventoryTypes.TRANSFER_STOCKS_SUCCESS, null));
    };
}
function resetAddNewStockAdjustment() {
    return (dispatch: Dispatch) => {
        dispatch(success(inventoryTypes.ADD_NEW_STOCK_ADJUSTMENT_SUCCESS, null));
    };
}
function resetAddSuppliedStock() {
    return (dispatch: Dispatch) => {
        dispatch(success(inventoryTypes.ADD_SUPPLIED_STOCK_SUCCESS, null));
    };
}
function resetUpdateSuppliedStock() {
    return (dispatch: Dispatch) => {
        dispatch(success(inventoryTypes.UPDATE_SUPPLIED_STOCK_SUCCESS, null));
    };
}

function resetfetchStockList() {
    return (dispatch: Dispatch) => {
        dispatch(success(inventoryTypes.FETCH_STOCK_LIST_SUCCESS, null));
    };
}
function resetfetchInventoryOutStockValue() {
    return (dispatch: Dispatch) => {
        dispatch(success(inventoryTypes.FETCH_OUT_STOCK_VALUE_SUCCESS, null));
    };
}
function resetfetchInventoryTotalStockValue() {
    return (dispatch: Dispatch) => {
        dispatch(success(inventoryTypes.FETCH_TOTAL_STOCK_VALUE_SUCCESS, null));
    };
}
function resetfetchInventoryInStockValue() {
    return (dispatch: Dispatch) => {
        dispatch(success(inventoryTypes.FETCH_IN_STOCK_VALUE_SUCCESS, null));
    };
}
function resetfetchInventoryReStockValue() {
    return (dispatch: Dispatch) => {
        dispatch(success(inventoryTypes.FETCH_RE_STOCK_VALUE_SUCCESS, null));
    };
}
function resetfetchInventoryAvgCostValue() {
    return (dispatch: Dispatch) => {
        dispatch(success(inventoryTypes.FETCH_AVG_COST_VALUE_SUCCESS, null));
    };
}

function resetFetchStockAdjustmentList() {
    return (dispatch: Dispatch) => {
        dispatch(success(inventoryTypes.FETCH_STOCK_ADJUSTMENT_LIST_SUCCESS, null));
    };
}
function resetFetchStockTransferList() {
    return (dispatch: Dispatch) => {
        dispatch(success(inventoryTypes.FETCH_TRANSFER_STOCKS_SUCCESS, null));
    };
}
function resetEditBundle() {
    return (dispatch: Dispatch) => {
        dispatch(success(inventoryTypes.EDIT_BUNDLE_SUCCESS, null));
    };
}
function request(type: string) {
    return { type: type };
}
function success(type: string, data?: any) {
    return { type: type, payload: data };
}
function failure(type: string, error?: any) {
    return { type: type, payload: error ?? "" };
}
