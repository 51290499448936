import { useEffect, useState } from "react";
import { salesActions } from "redux/Ldb/actions";
import { useAppDispatch, useAppSelector } from "../storeHooks";
import { useParams } from "react-router-dom";

const useGetSingleSalesOrder = () => {
    const dispatch = useAppDispatch();
    const { fetchingSingleSalesOrder, fetchSingleSalesOrderSuccess, fetchSingleSalesOrderFailure } =
        useAppSelector((state) => state.sales);

    const [salesOrderData, setSalesOrderData] = useState<{ [key: string]: any }>({});
    const { orderId } = useParams();

    useEffect(() => {
        dispatch(salesActions.getSingleSalesOrder(orderId));

        return () => {
            dispatch(salesActions.resetGetSingleSalesOrderSuccess());
        };
    }, [dispatch, orderId]);

    useEffect(() => {
        if (Boolean(fetchSingleSalesOrderSuccess)) {
            setSalesOrderData(fetchSingleSalesOrderSuccess?.salesOrder);
        }
    }, [dispatch, fetchSingleSalesOrderSuccess]);

    return {
        isFetching: fetchingSingleSalesOrder,
        error: fetchSingleSalesOrderFailure,
        data: salesOrderData,
        setSalesOrderData,
    };
};

export default useGetSingleSalesOrder;
