import { useEffect, useState } from "react";
import { inventoryActions } from "redux/Ldb/actions";
import { useAppDispatch, useAppSelector } from "../storeHooks";
import { useParams } from "react-router-dom";
import useInfiniteScroll from "../useInfinteScroll";

const useGetSingleInventoryIncidents = (incidentId?: string) => {
    const dispatch = useAppDispatch();
    const { productId } = useParams();
    const {
        fetchingSingleInventoryIncident,
        fetchedSingleInventoryIncidentSuccess,
        fetchedSingleInventoryIncidentFailure,
    } = useAppSelector((state) => state.inventory);

    const [incidentData, setIncidentData] = useState<any>([]);
    const [pagination, setPagination] = useState<{ currentPage: string; noOfPages: string }>({
        currentPage: "1",
        noOfPages: "",
    });
    const { updatedLimit, isLoadingMore, setLoadMore, updatedPage } = useInfiniteScroll(
        { current: pagination.currentPage, number_of_pages: pagination.noOfPages },
        20
    );

    useEffect(() => {
        if (incidentId) {
            dispatch(inventoryActions.fetchSingleInventoryIncidents(incidentId, 10, updatedPage));
        }
    }, [dispatch, incidentId, updatedPage]);

    useEffect(() => {
        if (Boolean(fetchedSingleInventoryIncidentSuccess)) {
            setIncidentData(fetchedSingleInventoryIncidentSuccess);
            setPagination({
                currentPage: fetchedSingleInventoryIncidentSuccess.pagination?.current,
                noOfPages: fetchedSingleInventoryIncidentSuccess.pagination?.number_of_pages,
            });
            setLoadMore(false);
        }
    }, [fetchedSingleInventoryIncidentSuccess]);

    return {
        isFetching: fetchingSingleInventoryIncident,
        error: fetchedSingleInventoryIncidentFailure,
        data: incidentData,
        pagination,
        isLoadingMore,
    };
};

export default useGetSingleInventoryIncidents;
