import Dropdown from "components/common/Dropdown";
import PageTitleSection from "components/common/PageTitleSection";
import TabsContainer from "components/common/TabsContainer";
import React, { useEffect, useRef, useState } from "react";
import ProductionPlan from "components/views/Synth2.0/Production/ProductionPlan";
import BillOfMaterial from "components/views/Synth2.0/Production/BillOfMaterial";
import Button from "components/common/Button";
import { useNavigate, useSearchParams } from "react-router-dom";
import TextInput from "components/common/InputField/TextInput";
import { debounce } from "lodash";
import useGetProductionPlanList from "hooks/ProductionHooks/useGetProductionPlanList";
import { authActions } from "redux/Ldb/actions";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import CustomCalendar from "components/common/CalendarComp.js/Calendar";
import useGetProductionAnalytics from "hooks/ProductionHooks/useGetProductionAnalytics";
import moment from "moment";
import { formatMoney } from "helpers";

const Production = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [param] = useSearchParams();
    const currTab = param.get("currTab");

    const customRef = useRef(null);

    const [teamMembers, setTeamMembers] = useState<{ [key: string]: any }[]>([]);
    const [showCustomDate, setShowCustomDate] = useState<boolean>(false);

    const [activeTab, setActiveTab] = useState(0);
    const tabs = ["Production Plan", "Bill of Materials", "Production Order", "Reports"];

    const [customValue, customOnChange] = useState("");
    const [durationFilter, setDurationFilter] = useState<string>("This month");

    const { fetchingTeamMember, fetchedTeamMemberSuccess } = useAppSelector((state) => state.auth);

    const { analytics, isLoading, dates, setDates } = useGetProductionAnalytics();

    const displayActiveTab = () => {
        switch (activeTab) {
            case 0:
                return (
                    <ProductionPlan
                        search={search}
                        setSearch={setSearch}
                        setStatus={setStatus}
                        setAssigneeId={setAssigneeId}
                    />
                );
            case 1:
                return <BillOfMaterial debouncedSearch={debouncedSearch} search={search} />;
            default:
            // return <ProductionPlan/>
        }
    };

    const {
        debouncedSearch,
        setDebouncedSearch,
        search,
        setSearch,
        handleSearch,
        handleDebouncedChange,
        handleStatusChange,
        handleAssigneeIdChange,
        assigneeId,
        setAssigneeId,
        status,
        setStatus,
        handleClearStatus,
    } = useGetProductionPlanList();

    useEffect(() => {
        setActiveTab(Number(currTab));
    }, [currTab]);

    useEffect(() => {
        dispatch(authActions.getTeamMembers(40));
    }, [dispatch]);

    useEffect(() => {
        if (Boolean(fetchedTeamMemberSuccess)) {
            setTeamMembers(fetchedTeamMemberSuccess.teamMembers);
        }
    }, [fetchedTeamMemberSuccess]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (customRef.current && !customRef.current.contains(event.target)) {
                setShowCustomDate(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [customRef]);

    useEffect(() => {
        if (customValue[0] !== customValue[1])
            setDates({
                startDate: moment(customValue[0])?.format("YYYY-MM-DD"),
                endDate: moment(customValue[1])?.format("YYYY-MM-DD"),
            });
    }, [customValue]);

    const productionData = {
        cost: 12000,
        defect: 62,
        standards: 34,
        waste: 5,
    };

    useEffect(() => {
        setSearch("");
        setStatus("");
        setAssigneeId("");
    }, [activeTab]);
    return (
        <div className="p-4 border border-slate-200 rounded-[8px] bg-white">
            <div className="mb-8">
                <PageTitleSection
                    leftComp={
                        <div className="flex items-center space-x-3">
                            <h3 className="text-xl font-rocGroteskMedium">Production</h3>
                        </div>
                    }
                    rightComp={
                        <div className="flex gap-3">
                            <div className="shadow-buttonShadow2 flex items-center space-x-1.5 bg-white border border-slate-200 rounded py-[11px] px-3 ">
                                <i className="ri-signal-tower-fill text-base text-[#15803D] "></i>
                                <span className="text-[13px] text-[#15803D] font-rocGroteskMedium">
                                    Real-time tracking of inventory levels.
                                </span>
                            </div>
                            <div className="relative">
                                <Dropdown
                                    handleChange={(name, value) => {
                                        setDurationFilter(value);
                                    }}
                                    dropdown={
                                        <div className="flex items-center justify-between rounded-[6px] shadow-buttonShadow2 py-2.5 px-3 !border !border-slate-100 gap-2">
                                            <i className="ri-calendar-line text-sm"></i>
                                            <div
                                                className={`text-g-75 font-rocGroteskMedium text-[13px] inline-block `}
                                            >
                                                {dates?.startDate && dates?.endDate
                                                    ? `${moment(dates.startDate).format(
                                                          "MMM DD, YY"
                                                      )} - ${moment(dates.endDate).format(
                                                          "MMM DD, YY"
                                                      )}`
                                                    : durationFilter}
                                            </div>
                                            <i className="ri-arrow-down-s-line   text-[#142837]"></i>
                                        </div>
                                    }
                                    dropdownItemsClasses="!px-0 !py-0"
                                    dropdownClassName="!w-full !mb-10 !-mt-7"
                                    dropdownContainerClasses={`shadow-cardShadow-4 border border-slate-100 right-0 rounded-[6px] !max-h-fit !w-auto`}
                                    name="valueRange"
                                    dropdownOptions={[
                                        {
                                            label: (
                                                <div
                                                    className={`text-g-75 font-rocGroteskMedium text-[13px] flex justify-between
                                                    border-b border-b-slate-100 !w-[120px] px-4 py-[10px]`}
                                                >
                                                    This month
                                                </div>
                                            ),
                                            value: "This month",
                                            action: () => {
                                                setDates({
                                                    startDate: "",
                                                    endDate: "",
                                                });
                                            },
                                        },
                                        {
                                            label: (
                                                <div
                                                    className={`text-g-75 font-rocGroteskMedium text-[13px] flex justify-between
                                                    border-b border-b-slate-100 !w-[120px] px-4 py-[10px]`}
                                                >
                                                    Custom
                                                </div>
                                            ),
                                            value: "Custom",
                                            action: () => {
                                                setShowCustomDate(true);
                                            },
                                        },
                                    ]}
                                />

                                <div className="relative">
                                    {showCustomDate && (
                                        <div
                                            ref={customRef}
                                            className="w-[630px]  z-[10000] absolute  right-0 top-16 px-[28px] pt-8 pb-8 bg-white
                                                    rounded-2xl shadow-[0px_0px_4px_rgba(0,0,0,0.04),0px_4px_32px_rgba(0,0,0,0.06)]"
                                        >
                                            <CustomCalendar
                                                showDoubleView={true}
                                                selectRange={true}
                                                value={customValue}
                                                onChange={customOnChange}
                                            />
                                            <div className="flex justify-end">
                                                <Button
                                                    btnText={"Close"}
                                                    btnClassname={"!w-fit !py-2"}
                                                    onClick={() => setShowCustomDate(false)}
                                                />
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    }
                />
            </div>
            <div className="grid grid-cols-4 gap-3 mb-8">
                <div className="p-4 border border-slate-200 rounded-lg min-h-[127px] shadow-boxShadow-12">
                    <p className="block text-base text-g-75 2xl font-rocGroteskMedium mb-2">
                        Total production cost
                    </p>
                    <p className="text-2xl">
                        {analytics?.totalCost?.value && "$"}
                        {`${
                            analytics?.totalCost?.value !== undefined
                                ? formatMoney()?.format(analytics?.totalCost?.value)
                                : "--"
                        }`}
                    </p>

                    <div className="flex gap-2 items-center font-rocGroteskMedium  mt-1">
                        <div
                            className={`${
                                analytics?.totalCost?.percentIncrease >= 0
                                    ? "bg-[#F0FDF4] text-[#22C55E]"
                                    : "bg-[#FEF2F2] text-[#DC2626]"
                            }  rounded-[3px] py-0.5 px-1.5 text-sm`}
                        >
                            <i
                                className={`${
                                    analytics?.totalCost?.percentIncrease >= 0
                                        ? "ri-arrow-up-line"
                                        : "ri-arrow-down-line"
                                }`}
                            ></i>
                            <span className="">
                                {analytics?.totalCost?.percentIncrease !== undefined
                                    ? `${Math.round(Math.abs(analytics?.totalCost?.percentIncrease))}%`
                                    : "-"}
                            </span>
                        </div>
                        {!dates?.startDate && !dates?.endState && (
                            <p className="text-sm text-slate-500">since last month</p>
                        )}
                    </div>
                </div>
                <div className="p-4 border border-slate-200 rounded-lg min-h-[127px] shadow-boxShadow-12">
                    <p className="block text-base text-g-75 2xl font-rocGroteskMedium mb-2">
                        Number of defects
                    </p>
                    <p className="text-2xl">{`${"--"}`}</p>
                    {/* {productionData?.defect && (
                        <div className="flex gap-2 items-center font-rocGroteskMedium  mt-1">
                            <div className="bg-[#F0FDF4] text-[#22C55E] rounded-[3px] py-0.5 px-1.5 text-sm">
                                <i className="ri-arrow-up-line"> </i>
                                <span className="">14%</span>
                            </div>
                            <p className="text-sm text-slate-500">since last month</p>
                        </div>
                    )} */}
                </div>{" "}
                <div className="p-4 border border-slate-200 rounded-lg min-h-[127px] shadow-boxShadow-12">
                    <p className="block text-base text-g-75 2xl font-rocGroteskMedium mb-2">
                        Products meeting quality standards
                    </p>
                    <p className="text-2xl">{`${"--"}`}</p>
                    {/* {productionData?.standards && (
                        <div className="flex gap-2 items-center font-rocGroteskMedium  mt-1">
                            <div className="bg-[#F0FDF4] text-[#22C55E] rounded-[3px] py-0.5 px-1.5 text-sm">
                                <i className="ri-arrow-up-line"> </i>
                                <span className="">14%</span>
                            </div>
                            <p className="text-sm text-slate-500">since last month</p>
                        </div>
                    )} */}
                </div>
                <div className="p-4 border border-slate-200 rounded-lg min-h-[127px] shadow-boxShadow-12">
                    <p className="block text-base text-g-75 2xl font-rocGroteskMedium mb-3">
                        Scrap and waste rates
                    </p>
                    <p className="text-2xl">{`${
                        analytics?.totalScrap !== undefined ? analytics?.totalScrap?.value : "--"
                    }`}</p>

                    <div className="flex gap-2 items-center font-rocGroteskMedium  mt-1">
                        <div
                            className={`${
                                analytics?.totalScrap?.percentIncrease >= 0
                                    ? "bg-[#F0FDF4] text-[#22C55E]"
                                    : "bg-[#FEF2F2] text-[#DC2626]"
                            }  rounded-[3px] py-0.5 px-1.5 text-sm`}
                        >
                            <i
                                className={`${
                                    analytics?.totalScrap?.percentIncrease >= 0
                                        ? "ri-arrow-up-line"
                                        : "ri-arrow-down-line"
                                }`}
                            ></i>
                            <span className="">
                                {analytics?.totalScrap?.percentIncrease !== undefined ?  `${Math.round(Math.abs(analytics?.totalScrap?.percentIncrease))}%` : "--"}
                            </span>
                        </div>
                        {!dates?.startDate && !dates?.endState && (
                            <p className="text-sm text-slate-500">since last month</p>
                        )}
                    </div>
                </div>
            </div>

            <div className="relative">
                <TabsContainer
                    tabs={tabs}
                    activeTab={activeTab}
                    setActiveTab={(idx) => {
                        setActiveTab(idx);
                        navigate(`/dashboard/production?currTab=${idx}`, { replace: true });
                    }}
                    component={displayActiveTab()}
                    className="!px-0"
                    showButtonClassName
                    itemClassName="!pb-2 !whitespace-nowrap !border-y-10"
                    borderLineClase={"!text-slate-100 w-[37%] max-lg:w-full mb-6"}
                    showButton={
                        <div className="flex gap-2 ">
                            <TextInput
                                name="search"
                                type="text"
                                value={search}
                                onChange={(e) => handleSearch(e)}
                                onInput={handleDebouncedChange}
                                inputPlaceholder={
                                    activeTab === 0
                                        ? "Search production plan"
                                        : activeTab === 1
                                        ? "Search BOM"
                                        : "Search"
                                }
                                inputClassName={
                                    "!h-[40px] pl-[0px] !mb-0 !font-rocGroteskMedium !text-slate-400"
                                }
                                leftIcon={
                                    <i className="ri-search-line before:content-['\f0d1] text-slate-200 mt-[2px]"></i>
                                }
                                inputContainerClassname={
                                    "!rounded-[4px]  !h-[40px] !pl-[20px] !bg-white !border !border-slate-200"
                                }
                            />
                            {activeTab !== 1 && (
                                <>
                                    <Dropdown
                                        name="filter"
                                        value={""}
                                        dropdownTriggerClassName={" "}
                                        handleChange={(name, value) => {
                                            handleStatusChange({ target: { name, value } });
                                        }}
                                        dropdown={
                                            <div className="bg-white flex items-center max-sm:justify-between h-10 gap-2 py-2.5 px-3 shadow-boxShadow-2 border border-slate-200 !rounded">
                                                <span className="text-[13px] whitespace-nowrap font-rocGroteskMedium">
                                                    {status ? status : "Status"}
                                                </span>
                                                {status && (
                                                    <i
                                                        onClick={handleClearStatus}
                                                        className="ri-close-circle-line text-slate-500"
                                                    ></i>
                                                )}
                                                <div className="flex items-center space-x-2">
                                                    <i className="ri-arrow-down-s-line text-lg text-gm-50"></i>
                                                </div>
                                            </div>
                                        }
                                        dropdownClassName={"!w-full !mb-10 !-mt-7"}
                                        dropdownContainerClasses={`shadow-cardShadow-4  !mt-0  !w-[159px] max-sm:!w-full  rounded !max-h-fit
                                                 !p-0 !top-[105%]`}
                                        wholeContainerClass={`max-sm:!w-full`}
                                        dropdownItemsClasses="!bg-transparent border-b-[1px] border-b-slate-100"
                                        dropdownOptions={[
                                            {
                                                label: "Pending",
                                                value: "pending",
                                            },
                                            {
                                                label: "Fulfilled",
                                                value: "fulfilled",
                                            },
                                        ]}
                                    />

                                    <Dropdown
                                        name="filter"
                                        value={""}
                                        dropdownTriggerClassName={" "}
                                        handleChange={(name, value) => {
                                            handleAssigneeIdChange({ target: { name, value } });
                                        }}
                                        dropdown={
                                            <div className="bg-white flex items-center max-sm:justify-between h-10 gap-2 py-2.5 px-3 shadow-boxShadow-2 border border-slate-200 !rounded">
                                                <span className="text-[13px] whitespace-nowrap font-rocGroteskMedium">
                                                    {assigneeId?.split("-")[1]
                                                        ? assigneeId?.split("-")[1]
                                                        : "Assigned to"}
                                                </span>

                                                {assigneeId?.split("-")[1] && (
                                                    <i
                                                        onClick={() => setAssigneeId("")}
                                                        className="ri-close-circle-line text-slate-500"
                                                    ></i>
                                                )}

                                                <div className="flex items-center space-x-2">
                                                    <i className="ri-arrow-down-s-line text-lg text-gm-50"></i>
                                                </div>
                                            </div>
                                        }
                                        dropdownClassName={"!w-full !mb-10 !-mt-7"}
                                        dropdownContainerClasses={`shadow-cardShadow-4  !mt-0  !w-[159px] max-sm:!w-full  rounded !max-h-fit
                                                 !p-0 !top-[105%]`}
                                        wholeContainerClass={`max-sm:!w-full`}
                                        dropdownItemsClasses="!bg-transparent border-b-[1px] border-b-slate-100"
                                        dropdownOptions={teamMembers?.map((member) => ({
                                            label: member?.fullName || member?.email,
                                            value: member?._id + "-" + member?.email,
                                        }))}
                                    />
                                </>
                            )}
                            <Button
                                btnText={"Settings"}
                                type={"button"}
                                btnClassname="!text-[13px] !bg-white !py-2 !px-4 !w-fit !whitespace-nowrap border border-slate-200"
                                onClick={() => navigate("")}
                                icon={<i className="ri-settings-2-line"></i>}
                            />
                            <Button
                                btnText={`${
                                    activeTab === 0
                                        ? "Create production plan"
                                        : activeTab === 1
                                        ? "Create bill of material"
                                        : ""
                                } `}
                                type={"button"}
                                btnClassname="!py-2 !px-4 !w-fit !whitespace-nowrap max-sm:w-full"
                                onClick={() =>
                                    activeTab === 0
                                        ? navigate("/dashboard/production/create-plan?planTab=1")
                                        : activeTab === 1
                                        ? navigate(
                                              `/dashboard/production/create-bom?currTab=${currTab}`
                                          )
                                        : ""
                                }
                            />
                        </div>
                    }
                />
            </div>
        </div>
    );
};

export default Production;
