import {authTypes} from "redux/Ldb/types";

const initialState = {
    registeringUser: false,
    registerUserSuccess: null,
    registerUserFailure: null,
    verifyingUser: false,
    verifyUserSuccess: null,
    verifyUserFailure: null,
    resendingVerificationCode: false,
    resendVerificationCodeSuccess: null,
    resendVerificationCodeFailure: null,
    userLoggingIn: false,
    userLoggedInSuccess: null,
    userLoggedInFailure: null,
    forgotPasswordRequest: false,
    forgotPasswordSuccess: null,
    forgotPasswordFailure: null,
    resettingPassword: false,
    resetPasswordSuccess: null,
    resetPasswordFailure: null,
    changingSignupEmail: false,
    changeSignupEmailSuccess: null,
    changeSignupEmailFailure: null,
    changingPassword: false,
    changePasswordSuccess: null,
    changePasswordFailure: null,
    fetchUserProfileRequest: false,
    fetchUserProfileSuccess: null,
    fetchUserProfileFailure: null,
    updateLdbProfileRequest: false,
    updateLdbProfileSuccess: null,
    updateLdbProfileFailure: null,
    updateUserProfileRequest: false,
    updateUserProfileSuccess: null,
    updateUserProfileFailure: null,
    addingTeamMember: false,
    addTeamMemberSuccess: null,
    addTeamMemberFailure: null,
    fetchingTeamMember: false,
    fetchedTeamMemberSuccess: null,
    fetchedTeamMemberFailure: null,
    deleteTeamMember: false,
    deleteTeamMemberSuccess: null,
    deleteTeamMemberFailure: null,
    deleteLdbProfile: false,
    deleteLdbProfileSuccess: null,
    deleteLdbProfileFailure: null,
    updateTeamMember: false,
    updateTeamMemberSuccess: null,
    updateTeamMemberFailure: null,
    addingVendor: false,
    addVendorSuccess: null,
    addVendorFailure: null,
    fetchingRoles: false,
    fetchedRolesSuccess: null,
    fetchedRolesFailure: null,
    createRoles: false,
    createRolesSuccess: null,
    createRolesFailure: null,
};

export const auth = (state = initialState, action: {type: string, payload: any}) => {
    switch (action.type) {
        case authTypes.REGISTER_USER_REQUEST:
            return {
                ...state,
                registeringUser: true,
                registerUserSuccess: null,
                registerUserFailure: null,
            };
        case authTypes.REGISTER_USER_SUCCESS:
            return {
                ...state,
                registeringUser: false,
                registerUserSuccess: action.payload,
                registerUserFailure: null,
            };
        case authTypes.REGISTER_USER_FAILURE:
            return {
                ...state,
                registeringUser: false,
                registerUserSuccess: null,
                registerUserFailure: action.payload,
            };

        case authTypes.VERIFY_USER_REQUEST:
            return {
                ...state,
                verifyingUser: true,
                verifyUserSuccess: null,
                verifyUserFailure: null,
            };
        case authTypes.VERIFY_USER_SUCCESS:
            return {
                ...state,
                verifyingUser: false,
                verifyUserSuccess: action.payload,
                verifyUserFailure: null,
            };
        case authTypes.VERIFY_USER_FAILURE:
            return {
                ...state,
                verifyingUser: false,
                verifyUserSuccess: null,
                verifyUserFailure: action.payload,
            };

        case authTypes.RESEND_VERIFY_CODE_REQUEST:
            return {
                ...state,
                resendingVerificationCode: true,
                resendVerificationCodeSuccess: null,
                resendVerificationCodeFailure: null,
            };
        case authTypes.RESEND_VERIFY_CODE_SUCCESS:
            return {
                ...state,
                resendingVerificationCode: false,
                resendVerificationCodeSuccess: action.payload,
                resendVerificationCodeFailure: null,
            };
        case authTypes.RESEND_VERIFY_CODE_FAILURE:
            return {
                ...state,
                resendingVerificationCode: false,
                resendVerificationCodeSuccess: null,
                resendVerificationCodeFailure: action.payload,
            };

        case authTypes.USER_LOGIN_REQUEST:
            return {
                ...state,
                userLoggingIn: true,
                userLoggedInSuccess: null,
                userLoggedInFailure: null,
            };
        case authTypes.USER_LOGIN_SUCCESS:
            return {
                ...state,
                userLoggingIn: false,
                userLoggedInSuccess: action.payload,
                userLoggedInFailure: null,
            };
        case authTypes.USER_LOGIN_FAILURE:
            return {
                ...state,
                userLoggingIn: false,
                userLoggedInSuccess: null,
                userLoggedInFailure: action.payload,
            };

        case authTypes.LOG_OUT:
            return {
                ...state,
                userLoggedInSuccess: null,
                fetchUserProfileSuccess: null,
            };

        case authTypes.FORGOT_PASSWORD_REQUEST:
            return {
                ...state,
                forgotPasswordRequest: true,
                forgotPasswordSuccess: null,
                forgotPasswordFailure: null,
            };
        case authTypes.FORGOT_PASSWORD_SUCCESS:
            return {
                ...state,
                forgotPasswordRequest: false,
                forgotPasswordSuccess: action.payload,
                forgotPasswordFailure: null,
            };
        case authTypes.FORGOT_PASSWORD_FAILURE:
            return {
                ...state,
                forgotPasswordRequest: false,
                forgotPasswordSuccess: null,
                forgotPasswordFailure: action.payload,
            };

        case authTypes.RESET_PASSWORD_REQUEST:
            return {
                ...state,
                resettingPassword: true,
                resetPasswordSuccess: null,
                resetPasswordFailure: null,
            };
        case authTypes.RESET_PASSWORD_SUCCESS:
            return {
                ...state,
                resettingPassword: false,
                resetPasswordSuccess: action.payload,
                resetPasswordFailure: null,
            };
        case authTypes.RESET_PASSWORD_FAILURE:
            return {
                ...state,
                resettingPassword: false,
                resetPasswordSuccess: null,
                resetPasswordFailure: action.payload,
            };

        case authTypes.CHANGE_SIGNUP_EMAIL_REQUEST:
            return {
                ...state,
                changingSignupEmail: true,
                changeSignupEmailSuccess: null,
                changeSignupEmailFailure: null,
            };
        case authTypes.CHANGE_SIGNUP_EMAIL_SUCCESS:
            return {
                ...state,
                changingSignupEmail: false,
                changeSignupEmailSuccess: action.payload,
                changeSignupEmailFailure: null,
            };
        case authTypes.CHANGE_SIGNUP_EMAIL_FAILURE:
            return {
                ...state,
                changingSignupEmail: false,
                changeSignupEmailSuccess: null,
                changeSignupEmailFailure: action.payload,
            };
        case authTypes.CHANGE_PASSWORD_REQUEST:
            return {
                ...state,
                changingPassword: true,
                changePasswordSuccess: null,
                changePasswordFailure: null,
            };
        case authTypes.CHANGE_PASSWORD_SUCCESS:
            return {
                ...state,
                changingPassword: false,
                changePasswordSuccess: action.payload,
                changePasswordFailure: null,
            };
        case authTypes.CHANGE_PASSWORD_FAILURE:
            return {
                ...state,
                changingPassword: false,
                changePasswordSuccess: null,
                changePasswordFailure: action.payload,
            };

        case authTypes.FETCH_USER_PROFILE_REQUEST:
            return {
                ...state,
                fetchUserProfileRequest: true,
                fetchUserProfileSuccess: null,
                fetchUserProfileFailure: null,
            };
        case authTypes.FETCH_USER_PROFILE_SUCCESS:
            return {
                ...state,
                fetchUserProfileRequest: false,
                fetchUserProfileSuccess: action.payload,
                fetchUserProfileFailure: null,
            };
        case authTypes.FETCH_USER_PROFILE_FAILURE:
            return {
                ...state,
                fetchUserProfileRequest: false,
                fetchUserProfileSuccess: null,
                fetchUserProfileFailure: action.payload,
            };

        case authTypes.UPDATE_LDB_PROFILE_REQUEST:
            return {
                ...state,
                updateLdbProfileRequest: true,
                updateLdbProfileSuccess: null,
                updateLdbProfileFailure: null,
            };
        case authTypes.UPDATE_LDB_PROFILE_SUCCESS:
            return {
                ...state,
                updateLdbProfileRequest: false,
                updateLdbProfileSuccess: action.payload,
                updateLdbProfileFailure: null,
            };
        case authTypes.UPDATE_LDB_PROFILE_FAILURE:
            return {
                ...state,
                updateLdbProfileRequest: false,
                updateLdbProfileSuccess: null,
                updateLdbProfileFailure: action.payload,
            };

        case authTypes.UPDATE_USER_PROFILE_REQUEST:
            return {
                ...state,
                updateUserProfileRequest: true,
                updateUserProfileSuccess: null,
                updateUserProfileFailure: null,
            };
        case authTypes.UPDATE_USER_PROFILE_SUCCESS:
            return {
                ...state,
                updateUserProfileRequest: false,
                updateUserProfileSuccess: action.payload,
                updateUserProfileFailure: null,
            };
        case authTypes.UPDATE_USER_PROFILE_FAILURE:
            return {
                ...state,
                updateUserProfileRequest: false,
                updateUserProfileSuccess: null,
                updateUserProfileFailure: action.payload,
            };

        case authTypes.DELETE_LDB_PROFILE_REQUEST:
            return {
                ...state,
                deleteLdbProfile: true,
                deleteLdbProfileSuccess: null,
                deleteLdbProfileFailure: null,
            };
        case authTypes.DELETE_LDB_PROFILE_SUCCESS:
            return {
                ...state,
                deleteLdbProfile: false,
                deleteLdbProfileSuccess: action.payload,
                deleteLdbProfileFailure: null,
            };
        case authTypes.DELETE_LDB_PROFILE_FAILURE:
            return {
                ...state,
                deleteLdbProfile: false,
                deleteLdbProfileSuccess: null,
                deleteLdbProfileFailure: action.payload,
            };
        case authTypes.ADD_TEAM_MEMBER_REQUEST:
            return {
                ...state,
                addingTeamMember: true,
                addTeamMemberSuccess: null,
                addTeamMemberFailure: null,
            };
        case authTypes.ADD_TEAM_MEMBER_SUCCESS:
            return {
                ...state,
                addingTeamMember: false,
                addTeamMemberSuccess: action.payload,
                addTeamMemberFailure: null,
            };
        case authTypes.ADD_TEAM_MEMBER_FAILURE:
            return {
                ...state,
                addingTeamMember: false,
                addTeamMemberSuccess: null,
                addTeamMemberFailure: action.payload,
            };
        case authTypes.FETCH_TEAM_MEMBER_REQUEST:
            return {
                ...state,
                fetchingTeamMember: true,
                fetchedTeamMemberSuccess: null,
                fetchedTeamMemberFailure: null,
            };
        case authTypes.FETCH_TEAM_MEMBER_SUCCESS:
            return {
                ...state,
                fetchingTeamMember: false,
                fetchedTeamMemberSuccess: action.payload,
                fetchedTeamMemberFailure: null,
            };
        case authTypes.FETCH_TEAM_MEMBER_FAILURE:
            return {
                ...state,
                fetchingTeamMember: false,
                fetchedTeamMemberSuccess: null,
                fetchedTeamMemberFailure: action.payload,
            };

        case authTypes.DELETE_TEAM_MEMBER_REQUEST:
            return {
                ...state,
                deleteTeamMember: true,
                deleteTeamMemberSuccess: null,
                deleteTeamMemberFailure: null,
            };
        case authTypes.DELETE_TEAM_MEMBER_SUCCESS:
            return {
                ...state,
                deleteTeamMember: false,
                deleteTeamMemberSuccess: action.payload,
                deleteTeamMemberFailure: null,
            };
        case authTypes.DELETE_TEAM_MEMBER_FAILURE:
            return {
                ...state,
                deleteTeamMember: false,
                deleteTeamMemberSuccess: null,
                deleteTeamMemberFailure: action.payload,
            };
        case authTypes.UPDATE_TEAM_MEMBER_REQUEST:
            return {
                ...state,
                updateTeamMember: true,
                updateTeamMemberSuccess: null,
                updateTeamMemberFailure: null,
            };
        case authTypes.UPDATE_TEAM_MEMBER_SUCCESS:
            return {
                ...state,
                updateTeamMember: false,
                updateTeamMemberSuccess: action.payload,
                updateTeamMemberFailure: null,
            };
        case authTypes.UPDATE_TEAM_MEMBER_FAILURE:
            return {
                ...state,
                updateTeamMember: false,
                updateTeamMemberSuccess: null,
                updateTeamMemberFailure: action.payload,
            };

        case authTypes.ADD_VENDOR_REQUEST:
            return {
                ...state,
                addingVendor: true,
                addVendorSuccess: null,
                addVendorFailure: null,
            };
        case authTypes.ADD_VENDOR_SUCCESS:
            return {
                ...state,
                addingVendor: false,
                addVendorSuccess: action.payload,
                addVendorFailure: null,
            };
        case authTypes.ADD_VENDOR_FAILURE:
            return {
                ...state,
                addingVendor: false,
                addVendorSuccess: null,
                addVendorFailure: action.payload,
            };
        case authTypes.FETCH_ROLES_REQUEST:
            return {
                ...state,
                fetchingRoles: true,
                fetchedRolesSuccess: null,
                fetchedRolesFailure: null,
            };
        case authTypes.FETCH_ROLES_SUCCESS:
            return {
                ...state,
                fetchingRoles: false,
                fetchedRolesSuccess: action.payload,
                fetchedRolesFailure: null,
            };
        case authTypes.FETCH_ROLES_FAILURE:
            return {
                ...state,
                fetchingRoles: false,
                fetchedRolesSuccess: null,
                fetchedRolesFailure: action.payload,
            };
        case authTypes.CREATE_ROLES_REQUEST:
            return {
                ...state,
                createRoles: true,
                createRolesSuccess: null,
                createRolesFailure: null,
            };
        case authTypes.CREATE_ROLES_SUCCESS:
            return {
                ...state,
                createRoles: false,
                createRolesSuccess: action.payload,
                createRolesFailure: null,
            };
        case authTypes.CREATE_ROLES_FAILURE:
            return {
                ...state,
                createRoles: false,
                createRolesSuccess: null,
                createRolesFailure: action.payload,
            };
        default:
            return state;
    }
};
