import { useEffect, useMemo, useState } from "react";
import { inventoryActions } from "redux/Ldb/actions";
import { useAppDispatch, useAppSelector } from "../storeHooks";
import { debounce, uniqBy } from "lodash";
import useInfiniteScroll from "../useInfinteScroll";

const useGetInventoryStockOut = (
    inventoryId?: string,
    scrollContent?: HTMLElement,
    defaultIncidentType?: boolean
) => {
    const dispatch = useAppDispatch();
    const {
        fetchingInventoryIncidentOverstock,
        fetchedInventoryIncidentOverstockSuccess,
        fetchedInventoryIncidentOverstockFailure
    } = useAppSelector((state) => state.inventory);

    const [incidentList, seIncidentList] = useState<{ [key: string]: any }[]>([]);
    const [pagination, setPagination] = useState<{ currentPage: string; noOfPages: string }>({
        currentPage: "1",
        noOfPages: "",
    });
    const [warehouseId, setWarehouseId] = useState<string>("");
    const [incidentType, setIncidentType] = useState<string>("");

    const { isLoadingMore, setLoadMore, updatedPage, setUpdatedPage } = useInfiniteScroll(
        {
            current: pagination.currentPage,
            number_of_pages: pagination.noOfPages,
        },
        10,
        scrollContent
    );

    const handleIncidentTypeChange = (value: string) => {
        setUpdatedPage(1);
        setIncidentType(value);
    };

    const handleClearFilter = () => {
        setUpdatedPage(1);
        setIncidentType("");
    };

    const handleWarehouseChange = (value: string) => {
        setUpdatedPage(1);
        setWarehouseId(value);
    };
    useEffect(() => {
        dispatch(
            inventoryActions.fetchInventoryIncidentOverstocking(
                warehouseId,
                10,
                updatedPage,
                "stockout",
                inventoryId
            )
        );

        return () => {
            dispatch(inventoryActions.resetFetchInventoryIncidentOverstock());
        };
    }, [dispatch, warehouseId, incidentType, updatedPage, inventoryId]);

    useEffect(() => {
        if (Boolean(fetchedInventoryIncidentOverstockSuccess)) {
            seIncidentList((prev) =>
                warehouseId || updatedPage === 1
                    ? uniqBy([...fetchedInventoryIncidentOverstockSuccess?.incidents], "_id")
                    : uniqBy([...prev, ...fetchedInventoryIncidentOverstockSuccess?.incidents], "_id")
            );
            setPagination({
                currentPage: fetchedInventoryIncidentOverstockSuccess.pagination?.current,
                noOfPages: fetchedInventoryIncidentOverstockSuccess.pagination?.number_of_pages,
            });
            setLoadMore(false);
        }
    }, [
        fetchedInventoryIncidentOverstockSuccess,
        warehouseId,
        defaultIncidentType,
        incidentType,
        updatedPage,
    ]);

    return {
        isFetching: fetchingInventoryIncidentOverstock,
        error: fetchedInventoryIncidentOverstockFailure,
        data: incidentList,
        seIncidentList,
        pagination,
        setPagination,
        warehouseId,
        incidentType,
        handleIncidentTypeChange,
        handleClearFilter,
        handleWarehouseChange,
        isLoadingMore,
    };
};

export default useGetInventoryStockOut;
