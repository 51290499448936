import { useEffect, useMemo, useState } from "react";
import { inventoryActions } from "redux/Ldb/actions";
import { useAppDispatch, useAppSelector } from "../storeHooks";
import { debounce, set, sortBy, uniqBy } from "lodash";
import useInfiniteScroll from "../useInfinteScroll";
import { useSearchParams } from "react-router-dom";

const useGetMappedSkuProductList = (channel?: string, scrollContent?: HTMLElement) => {
    const dispatch = useAppDispatch();
    const [searchParams, setSearchParams] = useSearchParams();
    const searchQuery = searchParams.get("search");
    const categoryQuery = searchParams.get("category");
    const channelQuery = searchParams.get("channel");

    const {
        fetchingMappedSkuProductList,
        fetchedMappedSkuProductListSuccess,
        fetchedMappedSkuProductListFailure,
        fetchingMappedSkuProductSearchList,
        fetchedMappedSkuProductSearchListSuccess,
        fetchedMappedSkuProductSearchListFailure,
    } = useAppSelector((state) => state.inventory);

    const [productList, setProductList] = useState<{ [key: string]: any }[]>([]);
    const [total, setTotal] = useState<number>(0);
    const [refetch, setRefetch] = useState<boolean>(false);
    const [productSearchList, setProductSearchList] = useState<{ [key: string]: any }[]>([]);
    const [pagination, setPagination] = useState<{ currentPage: string; noOfPages: string }>({
        currentPage: "1",
        noOfPages: "",
    });
    const [debouncedSearch, setDebouncedSearch] = useState<string>("");
    const [tracking, setTracking] = useState<boolean>(true);
    const [debouncedSearchTwo, setDebouncedSearchTwo] = useState<string>("");
    const [search, setSearch] = useState<string>(searchQuery || "");
    const [searchTwo, setSearchTwo] = useState<string>("");
    const [productCategory, setProductCategory] = useState<string>(categoryQuery || "");
    // const [channel, setChannel] = useState<string>(channelQuery || "");
    const [rawProductData, setRawData] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState<{ name?: string; value: string }>({
        name: "",
        value: "",
    });

    const { updatedLimit, isLoadingMore, setLoadMore, updatedPage, setUpdatedPage } =
        useInfiniteScroll(
            { current: pagination.currentPage, number_of_pages: pagination.noOfPages },
            10,
            scrollContent
        );

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        setUpdatedPage(1);
        setSearch(e.target.value);
    };

    const handleSearchTwo = (e: React.ChangeEvent<HTMLInputElement>) => {
        setUpdatedPage(1);
        setSearchTwo(e.target.value);
    };

    const handleDebouncedChange = debounce((e: React.ChangeEvent<HTMLInputElement>) => {
        setDebouncedSearch(e.target.value);
        setSearchParams({
            search: e.target.value,
            category: productCategory,
            channel: channel,
        });
    }, 1000);

    const handleDebouncedChangeTwo = debounce((e: React.ChangeEvent<HTMLInputElement>) => {
        setDebouncedSearchTwo(e.target.value);
    }, 1000);

    const handleFilterChange = (value: string) => {
        setUpdatedPage(1);
        setProductList([]);
        dispatch(
            inventoryActions.fetchMappedSkuProductList(
                channel,
                10,
                1,
                debouncedSearch,
                value,

                tracking
            )
        );
        setProductCategory(value);
        setSearchParams({ search: search, category: value, channel: channel });
    };

    const handleClearFilter = () => {
        setUpdatedPage(1);
        setProductList([]);
        dispatch(
            inventoryActions.fetchMappedSkuProductList(
                channel,
                10,
                1,
                debouncedSearch,
                "",

                tracking
            )
        );
        setProductCategory("");
        setSearchParams({ search: search, category: "", channel: channel });
    };

    const handleFilterChangeTwo = (name: string, value: string) => {
        setSelectedProduct({ name, value });
    };

    const handleClearFilterTwo = () => {
        setSelectedProduct({ name: "", value: "" });
    };

    const handleSortByStockoutDate = (type: 1 | -1) => {
        setUpdatedPage(1);
        setProductList([]);
        dispatch(
            inventoryActions.fetchMappedSkuProductList(
                channel,
                10,
                1,
                debouncedSearch,
                productCategory,

                tracking,
                type
            )
        );
    };

    useEffect(() => {
        dispatch(
            inventoryActions.fetchMappedSkuProductList(
                channelQuery || channel,
                10,
                updatedPage,
                searchQuery || debouncedSearch,
                categoryQuery || productCategory,

                tracking
            )
        );

        return () => {
            dispatch(inventoryActions.resetFetchMappedSkuProductList());
        };
    }, [
        dispatch,
        debouncedSearch,
        updatedPage,
        productCategory,
        channel,
        tracking,
        refetch,
        channelQuery,
        searchQuery,
        categoryQuery,
    ]);

    useEffect(() => {
        dispatch(inventoryActions.fetchMappedSkuProductSearchList(10, debouncedSearchTwo));
    }, [dispatch, debouncedSearchTwo]);

    useEffect(() => {
        if (Boolean(fetchedMappedSkuProductListSuccess)) {
            setProductList((prev) =>
                updatedPage === 1
                    ? uniqBy([...fetchedMappedSkuProductListSuccess?.inventory], "_id")
                    : uniqBy([...prev, ...fetchedMappedSkuProductListSuccess?.inventory], "_id")
            );

            setPagination({
                currentPage: fetchedMappedSkuProductListSuccess.pagination?.current,
                noOfPages: fetchedMappedSkuProductListSuccess.pagination?.number_of_pages,
            });
            setTotal(fetchedMappedSkuProductListSuccess?.total);
            setRawData(fetchedMappedSkuProductListSuccess.inventory?.data);
            setLoadMore(false);
            dispatch(inventoryActions.resetFetchMappedSkuProductList());
        }
    }, [
        dispatch,
        fetchedMappedSkuProductListSuccess,
        updatedPage,
        debouncedSearch,
        productCategory,
    ]);

    useEffect(() => {
        if (Boolean(fetchedMappedSkuProductSearchListSuccess)) {
            setProductSearchList(fetchedMappedSkuProductSearchListSuccess.inventory);
        }
    }, [fetchedMappedSkuProductSearchListSuccess]);

    return {
        isFetchingSecondList: fetchingMappedSkuProductSearchList,
        isFetching: fetchingMappedSkuProductList,
        error: fetchedMappedSkuProductListFailure,
        data: productList,
        rawData: rawProductData || [],
        productSearchList,
        setProductList,
        setUpdatedPage,
        pagination,
        setPagination,
        debouncedSearch,
        search,
        setSearch,
        setDebouncedSearch,
        searchTwo,
        setSearchTwo,
        setDebouncedSearchTwo,
        productCategory,
        handleDebouncedChange,
        handleDebouncedChangeTwo,
        handleSearch,
        handleSearchTwo,
        handleFilterChange,
        handleFilterChangeTwo,
        handleClearFilter,
        handleClearFilterTwo,
        selectedProduct,
        isLoadingMore,
        updatedPage,
        channel,
        total,

        handleSortByStockoutDate,
        setTracking,
        setRefetch,
    };
};

export default useGetMappedSkuProductList;
