export const convertSeconds = (seconds: number) => {
    const days = Math.floor(seconds / 86400);
    const hours = Math.floor((seconds % 86400) / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secondsRemaining = Math.ceil(seconds % 60);

    return {
        days,
        hours,
        minutes,
        secondsRemaining,
    };
};

export const parseConvertedSeconds = (data: {
    days: number;
    hours: number;
    minutes: number;
    secondsRemaining: number;
}) => {
    return `${Boolean(data?.days) ? data?.days + " day(s)" : ""} ${
        Boolean(data?.hours) ? data?.hours + " hr(s)" : ""
    } ${Boolean(data?.minutes) ? data?.minutes + " min(s)" : ""} ${
        Boolean(data?.secondsRemaining) ? data?.secondsRemaining + " s" : ""
    }`;
};

export const parseLeadTime = (seconds: number) => {
    // const secondsInDay = 24 * 60 * 60;
    // const secondsInWeek = 7 * secondsInDay;
    // const secondsInMonth = 4 * secondsInWeek;

    // if (seconds < secondsInDay) {
    //     return "1 day";
    // }

    // let days = seconds / secondsInDay;

    // if (days >= 28) {
    //     let months = Math.floor(days / 28); // Approximating a month as 4 weeks
    //     return `${months} month${months > 1 ? "s" : ""}`;
    // } else if (days >= 7) {
    //     let weeks = Math.floor(days / 7);
    //     return `${weeks} week${weeks > 1 ? "s" : ""}`;
    // } else {
    //     return `${Math.ceil(days)} day${Math.ceil(days) > 1 ? "s" : ""}`;
    // }

    const secondsInMinute = 60;
    const secondsInHour = 60 * secondsInMinute;
    const secondsInDay = 24 * secondsInHour;
    const secondsInWeek = 7 * secondsInDay;
    const secondsInMonth = 4 * secondsInWeek; // Approximating a month as 4 weeks

    let remainingSeconds = seconds;

    // Calculate the number of months
    const months = Math.floor(remainingSeconds / secondsInMonth);
    remainingSeconds %= secondsInMonth;

    // Calculate the number of weeks
    const weeks = Math.floor(remainingSeconds / secondsInWeek);
    remainingSeconds %= secondsInWeek;

    // Calculate the number of days
    const days = Math.ceil(remainingSeconds / secondsInDay);

    // Construct the result string
    let result = "";
    if (months > 0) {
        result += `${months} month${months > 1 ? "s" : ""}`;
    }
    if (weeks > 0) {
        if (result) result += ", ";
        result += `${weeks} week${weeks > 1 ? "s" : ""}`;
    }
    if (days > 0) {
        if (result) result += " and ";
        result += `${days} day${days > 1 ? "s" : ""}`;
    }

    // Ensure at least "1 day" is returned for very small times
    if (!result) {
        result = "1 day";
    }

    return result;
};
