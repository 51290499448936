import {formatMoney, getCurrencyFromCurrencyCode} from "helpers";
import React from "react";

type JobsTabProps = {
    jobs: {[key: string]: any}[];
    showNoContent?: boolean;
    contentClass?: string;
};

const JobsTab = ({jobs, showNoContent, contentClass}: JobsTabProps) => {
    return jobs && jobs?.length > 0 ? (
        <div className="">
            {!showNoContent && (
                <div className="flex justify-between items-center mb-4 px-6 pt-6">
                    <p className="text-base text-slate-700 font-rocGroteskMedium">Jobs</p>
                </div>
            )}

            <div className={`px-6 flex flex-col gap-3 ${contentClass} overflow-auto`}>
                {jobs?.map((job, idx: number) => (
                    <div
                        key={job?.id + idx}
                        className="w-full border border-slate-100 rounded-md p-3 flex items-center justify-between"
                    >
                        <div className="flex items-center gap-3">
                            <i className="ri-box-3-line text-2xl"></i>
                            <div>
                                <p className="text-sm font-rocGroteskMedium">{job?.name}</p>
                                <p className="text-xs font-rocGroteskMedium text-slate-500">
                                    {getCurrencyFromCurrencyCode(job?.currency)}
                                    {formatMoney()?.format(job?.amount)}
                                </p>
                            </div>
                        </div>
                        <div className="flex items-center">
                            <span
                                className={`material-icons text-xs ${
                                    job?.status?.toLowerCase() === "pending" && "text-[#F59E0B]"
                                } ${job?.status?.toLowerCase() === "accepted" && "text-g-50"}  ${
                                    job?.status?.toLowerCase() === "rejected" && "text-[#FF3347]"
                                } mt-[-2px] mr-2`}
                            >
                                fiber_manual_record
                            </span>
                            <span className="capitalize text-sm text-slate-700 font-rocGroteskMedium">
                                {job?.status}
                            </span>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    ) : (
        <div className={`mt-[111px] flex justify-center`}>
            <div className="text-center w-[35%]">
                <i className="ri-box-3-line text-5xl text-slate-500"></i>
                <p className="text-base font-rocGroteskMedium mb-1 mt-4">No Job found</p>
                <p className="text-sm font-rocGroteskRegular text-slate-500">
                    This vendor hasn't done any job for you
                </p>
            </div>
        </div>
    );
};

export default JobsTab;
