import { useEffect, useState } from "react";
import { inventoryActions } from "redux/Ldb/actions";
import { useAppDispatch, useAppSelector } from "../storeHooks";
import useInfiniteScroll from "../useInfinteScroll";
import { firebaseService } from "services/firebaseService";
import { uniqBy } from "lodash";
import { useParams } from "react-router-dom";

const useGetWarehouseStats = () => {
    const dispatch = useAppDispatch();
    const { id } = useParams();
    const [warehouseStats, setWarehouseStats] = useState<{ [key: string]: any }>({});

    const { fetchingWarehouseStats, fetchedWarehouseStatsSuccess, fetchedWarehouseStatsFailure } =
        useAppSelector((state) => state.inventory);

    useEffect(() => {
        dispatch(inventoryActions.getWarehouseStats(id));

        return () => {
            dispatch(inventoryActions.resetGetWarehouseStatsSuccess());
        };
    }, [dispatch, id]);

    useEffect(() => {
        if (Boolean(fetchedWarehouseStatsSuccess)) {
            // console.log("fetchedWarehouseStatsSuccess", fetchedWarehouseStatsSuccess);
            setWarehouseStats(fetchedWarehouseStatsSuccess);
        }
    }, [fetchedWarehouseStatsSuccess]);

    return {
        isFetching: fetchingWarehouseStats,
        error: fetchedWarehouseStatsFailure,
        data: warehouseStats,
        setWarehouseStats,
    };
};

export default useGetWarehouseStats;
