import React, { useEffect, useRef, useState } from "react";
import Loader from "./Loader";

type DropdownProps = {
    id?: string;
    label?: string;
    handleChange?: (name: string, value: string) => void;
    name?: string;
    value?: any;
    disabled?: boolean;
    placeholder?: string;
    dropdownOptions?: {
        customChild?: JSX.Element;
        value?: string;
        action?: () => void;
        disabled?: boolean;
        label?: string | JSX.Element;
        subText?: string | JSX.Element;
        icon?: JSX.Element;
        rightIcon?: JSX.Element;
        labelClassName?: string;
    }[];
    dropdown?: React.ReactNode;
    dropdownTriggerClassName?: string;
    dropdownContainerClasses?: string;
    dropdownItemsClasses?: string;
    className?: string;
    dropdownClassName?: string;
    wholeContainerClass?: string;
    search?: React.ReactNode;
    clearSearchString?: () => void;
    leaveOpen?: boolean;
    loader?: boolean;
    endOfList?: boolean;
    isLoading?: boolean;
};

const Dropdown = ({
    id,
    label,
    handleChange,
    name,
    value,
    disabled,
    placeholder,
    dropdownOptions,
    dropdown,
    dropdownTriggerClassName,
    dropdownContainerClasses,
    dropdownItemsClasses,
    className,
    dropdownClassName,
    search,
    leaveOpen,
    wholeContainerClass,
    loader,
    endOfList,
    clearSearchString,
    isLoading,
}: DropdownProps) => {
    const [open, setOpen] = useState(false);
    const selectedOption = dropdownOptions?.find((item) => item.value === value);
    const ref = useRef<HTMLDivElement>(null);

    const handleSelect = (value: string) => {
        handleChange?.(name as string, value);
        if (!leaveOpen) {
            setOpen(false);
        }
    };
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (ref.current && !ref.current.contains(event.target as Node) && open) {
                setOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref, open]);

    return (
        <div
            className={`relative ${wholeContainerClass}`}
            // id={id}
        >
            {dropdown ? (
                <div
                    onClick={() => (disabled ? null : setOpen(!open))}
                    className={`w-full  ${dropdownTriggerClassName} ${
                        disabled ? "cursor-default" : "cursor-pointer"
                    }`}
                >
                    {dropdown}
                </div>
            ) : (
                <div
                    onClick={() => (disabled ? null : setOpen(!open))}
                    className={`flex gap-2.5 justify-between cursor-pointer items-center border border-gray-200 py-3 px-4 outline-0 w-full
                     h-[50px] text-sm font-rocGroteskMedium bg-tradeally-neutral-20 ${className} `}
                >
                    <p className="text-tradeally-neutral-200 ">
                        {Boolean(value) ? (
                            <span className="flex items-center">
                                {selectedOption?.icon && (
                                    <> {selectedOption?.icon}&nbsp;&nbsp;&nbsp; </>
                                )}
                                {selectedOption?.label}
                            </span>
                        ) : (
                            placeholder
                        )}
                    </p>
                    {!search && <span className="material-icons">arrow_drop_down</span>}
                </div>
            )}

            {open &&
                (dropdownOptions && dropdownOptions?.length > 0 ? (
                    <div
                        id="dropdownContent"
                        ref={ref}
                        className={`${
                            dropdown ? "min-w-max" : ""
                        } py-2 bg-white absolute top-[101%]  w-[100px] h-auto max-h-64 z-[1200] overflow-auto border ${dropdownContainerClasses} `}
                    >
                        <div className="relative">
                            {search && (
                                <div className="sticky top-[0px] bg-white">
                                    <div className="p-[5px]">{search}</div>
                                </div>
                            )}
                            {dropdownOptions.map((option, idx) => {
                                return option.customChild ? (
                                    <div key={idx} onClick={() => setOpen(false)}>
                                        {option.customChild}
                                    </div>
                                ) : (
                                    <div
                                        key={option.value}
                                        onClick={(e) => {
                                            if (option?.action && !option?.disabled) {
                                                option.action?.();
                                            }
                                            if (!option?.disabled) {
                                                handleSelect(option?.value as string);
                                            }
                                        }}
                                        className={`flex items-center px-4 justify-between hover:bg-tradeally-neutral-20 ${
                                            selectedOption?.value === option?.value &&
                                            "bg-tradeally-neutral-20"
                                        } ${
                                            option?.disabled && "opacity-40"
                                        } cursor-pointer py-3 ${dropdownItemsClasses}`}
                                    >
                                        <p
                                            className={`text-sm flex gap-3  items-center font-rocGroteskMedium text-inherit`}
                                        >
                                            {option?.icon && <span>{option?.icon}</span>}{" "}
                                            <div>
                                                <span
                                                    className={`block text-inherit ${`${option?.labelClassName}`}`}
                                                >
                                                    {option?.label}
                                                </span>
                                                {option?.subText && (
                                                    <span className="block text-xs text-tradeally-neutral-200">
                                                        {option?.subText}
                                                    </span>
                                                )}
                                            </div>
                                            {option?.rightIcon && <span>{option?.rightIcon}</span>}{" "}
                                        </p>
                                        {selectedOption?.value === option?.value && (
                                            <span className="material-icons text-tradeally-black text-base">
                                                done
                                            </span>
                                        )}
                                    </div>
                                );
                            })}
                            {isLoading && (
                                <p className="text-sm font-rocGroteskMedium">Loading...</p>
                            )}
                            {(loader || endOfList) && (
                                <div className="">
                                    {loader && !endOfList && (
                                        <div className="flex justify-center my-4">
                                            <Loader color="gm-25" size={4} />
                                        </div>
                                    )}
                                    {endOfList && (
                                        <div className="flex my-4 justify-center text-slate-500 text-sm font-rocGroteskMedium">
                                            End of list
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                ) : (
                    <div
                        ref={ref}
                        className={`${
                            search ? "mt-3" : "w-full"
                        } py-2 bg-white absolute top-[101%] h-auto max-h-64 z-20 overflow-auto border ${dropdownClassName}`}
                        id="custom-dropdown"
                    >
                        {search && <div className="px-[5px] pb-[5px]">{search}</div>}

                        <div className="flex px-4 justify-between hover:bg-tradeally-neutral-20 cursor-pointer">
                            <p className="text-sm flex gap-3 py-3 items-center font-rocGroteskMedium">
                                <span>No Data</span>
                            </p>
                        </div>
                    </div>
                ))}
        </div>
    );
};

export default Dropdown;
