import Button from "components/common/Button";
import TextInput from "components/common/InputField/TextInput";
import Navbar from "components/common/Navbar/Navbar";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import {authActions} from "redux/Ldb/actions";
import {useAppDispatch, useAppSelector} from "../../../hooks/storeHooks";

const ChangeVerificationEmail = () => {
    const [email, setEmail] = useState("");
    const {token} = useParams();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const {changingSignupEmail, changeSignupEmailSuccess} = useAppSelector((state) => state.auth);

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(authActions.changeVerificationEmail({email}, token));
    };

    useEffect(() => {
        if (Boolean(changeSignupEmailSuccess)) {
            setEmail("");
            navigate(`/registration-success/${token}`);
        }
    }, [changeSignupEmailSuccess]);

    return (
        <div className="w-full h-screen bg-body-1">
            <Navbar />
            <div className="h-[calc(100vh-84px)] w-full flex items-center justify-center">
                <div className="w-[32%] max-lg:w-[90%] bg-white py-14 px-12 flex flex-col items-center rounded-[20px] shadow-cardShadow">
                    <div className="text-center w-full mb-[45px]">
                        <p className=" text-[22px] font-rocGroteskMedium text-g-75">
                            Change email address
                        </p>
                    </div>
                    <form onSubmit={handleSubmit} className="w-full">
                        <TextInput
                            name={"email"}
                            type={"email"}
                            value={email}
                            onChange={(evt) => setEmail(evt.target.value)}
                            placeholder={"Enter work email"}
                            required={true}
                            containerClassname="mb-10"
                        />
                        <div className="w-full mt-5 space-y-3">
                            <Button
                                btnText={"Resend link"}
                                isLoading={changingSignupEmail}
                                type={"submit"}
                                btnClassname={"!py-3  !font-rocGroteskMedium"}
                            />
                            <Button
                                btnClassname={
                                    "!bg-white !py-3 !text-gm-50 border !border-n-20 !font-rocGroteskMedium"
                                }
                                btnText={"Go back"}
                                onClick={() => navigate(-1)}
                                type={"button"}
                            />
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default ChangeVerificationEmail;
