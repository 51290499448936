import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import { dashboardActions } from "redux/Ldb/actions";

const useGetDashboardTopOnHandProduct = (fetchData: boolean) => {
    const dispatch = useAppDispatch();
    const [topOnHandProduct, setTopOnHandProductData] = useState<{ [key: string]: any }>([]);

    const {
        fetchingTopOnHandProduct,
        fetchedTopOnHandProductSuccess,
        fetchedTopOnHandProductFailure,
    } = useAppSelector((state) => state.dashboard);

    useEffect(() => {
        if (Boolean(fetchedTopOnHandProductSuccess)) {
            setTopOnHandProductData(fetchedTopOnHandProductSuccess?.products);
        }
    }, [fetchedTopOnHandProductSuccess]);

    useEffect(() => {
        if (fetchData) {
            dispatch(dashboardActions?.getTopOnHandProduct());
        }
    }, [fetchData]);

    return {
        data: topOnHandProduct,
        isFetching: fetchingTopOnHandProduct,
        error: fetchedTopOnHandProductFailure,
    };
};

export default useGetDashboardTopOnHandProduct;
