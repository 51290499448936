import BreadCrumb from "components/common/BreadCrumb";
import CustomTable from "components/common/CustomTable";
import AnalyticsSummaryCard from "components/views/Ldb/Dashboard/Vendors/VendorAnalytics/AnalyticsSummaryCard";
import React from "react";

const VendorAnalytics = () => {
    const tableHeader = [
        {title: "Vendor name"},
        {title: "On-time delivery rate"},
        {title: "Average lead time"},
        {title: "Average response time"},
        {title: "Average rating"},
        {title: "Overall score"},
    ];

    const tableBody = [
        [
            {
                content: (
                    <div
                        className={`py-[18px] pr-3 border-slate-100 h-full text-xs  text-slate-700 `}
                    >
                        Freight Forwarder
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`py-[18px] pr-3 flex items-center h-full  text-xs text-slate-700 `}
                    >
                        80.0%
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`py-[18px] pr-3 flex items-center h-full  text-xs text-slate-700 `}
                    >
                        1 hr 10 mins
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`py-[18px] pr-3 flex items-center h-full  text-xs text-slate-700 `}
                    >
                        30 mins
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`py-[18px] pr-3 flex items-center h-full border-slate-100 text-sm text-slate-700 `}
                    >
                        <div className="flex items-center gap-2">
                            <i className="ri-star-fill text-star-gold"></i>
                            <i className="ri-star-fill text-star-gold"></i>
                            <i className="ri-star-fill text-star-gold"></i>
                            <i className="ri-star-line text-slate-300"></i>
                            <i className="ri-star-line text-slate-300"></i>
                        </div>
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`py-[18px] pr-3 flex items-center h-full  text-xs text-slate-700 `}
                    >
                        80.0%
                    </div>
                ),
            },
        ],
        [
            {
                content: (
                    <div
                        className={`py-[18px] pr-3 border-slate-100 h-full text-xs  text-slate-700 `}
                    >
                        Freight Forwarder
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`py-[18px] pr-3 flex items-center h-full  text-xs text-slate-700 `}
                    >
                        80.0%
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`py-[18px] pr-3 flex items-center h-full  text-xs text-slate-700 `}
                    >
                        1 hr 10 mins
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`py-[18px] pr-3 flex items-center h-full  text-xs text-slate-700 `}
                    >
                        30 mins
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`py-[18px] pr-3 flex items-center h-full border-slate-100 text-sm text-slate-700 `}
                    >
                        <div className="flex items-center gap-2">
                            <i className="ri-star-fill text-star-gold"></i>
                            <i className="ri-star-fill text-star-gold"></i>
                            <i className="ri-star-fill text-star-gold"></i>
                            <i className="ri-star-line text-slate-300"></i>
                            <i className="ri-star-line text-slate-300"></i>
                        </div>
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`py-[18px] pr-3 flex items-center h-full  text-xs text-slate-700 `}
                    >
                        80.0%
                    </div>
                ),
            },
        ],
        [
            {
                content: (
                    <div
                        className={`py-[18px] pr-3 border-slate-100 h-full text-xs  text-slate-700 `}
                    >
                        Freight Forwarder
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`py-[18px] pr-3 flex items-center h-full  text-xs text-slate-700 `}
                    >
                        80.0%
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`py-[18px] pr-3 flex items-center h-full  text-xs text-slate-700 `}
                    >
                        1 hr 10 mins
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`py-[18px] pr-3 flex items-center h-full  text-xs text-slate-700 `}
                    >
                        30 mins
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`py-[18px] pr-3 flex items-center h-full border-slate-100 text-sm text-slate-700 `}
                    >
                        <div className="flex items-center gap-2">
                            <i className="ri-star-fill text-star-gold"></i>
                            <i className="ri-star-fill text-star-gold"></i>
                            <i className="ri-star-fill text-star-gold"></i>
                            <i className="ri-star-line text-slate-300"></i>
                            <i className="ri-star-line text-slate-300"></i>
                        </div>
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`py-[18px] pr-3 flex items-center h-full  text-xs text-slate-700 `}
                    >
                        80.0%
                    </div>
                ),
            },
        ],
        [
            {
                content: (
                    <div
                        className={`py-[18px] pr-3 border-slate-100 h-full text-xs  text-slate-700 `}
                    >
                        Freight Forwarder
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`py-[18px] pr-3 flex items-center h-full  text-xs text-slate-700 `}
                    >
                        80.0%
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`py-[18px] pr-3 flex items-center h-full  text-xs text-slate-700 `}
                    >
                        1 hr 10 mins
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`py-[18px] pr-3 flex items-center h-full  text-xs text-slate-700 `}
                    >
                        30 mins
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`py-[18px] pr-3 flex items-center h-full border-slate-100 text-sm text-slate-700 `}
                    >
                        <div className="flex items-center gap-2">
                            <i className="ri-star-fill text-star-gold"></i>
                            <i className="ri-star-fill text-star-gold"></i>
                            <i className="ri-star-fill text-star-gold"></i>
                            <i className="ri-star-line text-slate-300"></i>
                            <i className="ri-star-line text-slate-300"></i>
                        </div>
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`py-[18px] pr-3 flex items-center h-full  text-xs text-slate-700 `}
                    >
                        80.0%
                    </div>
                ),
            },
        ],
        [
            {
                content: (
                    <div
                        className={`py-[18px] pr-3 border-slate-100 h-full text-xs  text-slate-700 `}
                    >
                        Freight Forwarder
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`py-[18px] pr-3 flex items-center h-full  text-xs text-slate-700 `}
                    >
                        80.0%
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`py-[18px] pr-3 flex items-center h-full  text-xs text-slate-700 `}
                    >
                        1 hr 10 mins
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`py-[18px] pr-3 flex items-center h-full  text-xs text-slate-700 `}
                    >
                        30 mins
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`py-[18px] pr-3 flex items-center h-full border-slate-100 text-sm text-slate-700 `}
                    >
                        <div className="flex items-center gap-2">
                            <i className="ri-star-fill text-star-gold"></i>
                            <i className="ri-star-fill text-star-gold"></i>
                            <i className="ri-star-fill text-star-gold"></i>
                            <i className="ri-star-line text-slate-300"></i>
                            <i className="ri-star-line text-slate-300"></i>
                        </div>
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`py-[18px] pr-3 flex items-center h-full  text-xs text-slate-700 `}
                    >
                        80.0%
                    </div>
                ),
            },
        ],
    ];

    return (
        <div>
            <div className="mb-[58px]">
                <BreadCrumb
                    parentCrumb={"Vendor Management"}
                    parentUrl={"/dashboard/vendors"}
                    childrenCrumb={[
                        {title: "Vendor analytics", path: "/dashboard/vendors/analytics"},
                    ]}
                    size="large"
                />
            </div>
            <div className="flex justify-between mb-[54px]">
                <AnalyticsSummaryCard
                    title={"Overall vendor performance"}
                    value={"80"}
                    isPercentageValue
                    up={false}
                    percentageChange={"24"}
                />
                <AnalyticsSummaryCard
                    title={"On-time delivery rate"}
                    value={"80"}
                    isPercentageValue
                    up={true}
                    percentageChange={"4"}
                />
                <AnalyticsSummaryCard
                    title={"Average fulfillment time"}
                    value={"2 hr 30 mins"}
                    up={false}
                    percentageChange={"24"}
                />
                <AnalyticsSummaryCard
                    title={"Average vendor response time"}
                    value={"1 hr 05 mins"}
                    up={false}
                    percentageChange={"24"}
                />
            </div>

            <div className="max-w-[calc(100vw-307px)] bg-white pb-3 rounded-[5px] shadow-boxShadow">
                <div className="flex items-center justify-between py-6 px-4">
                    <p className="text-lg text-slate-950 font-rocGroteskMedium">
                        Your top 10 vendors
                    </p>
                </div>

                <div className="w-full px-2">
                    <CustomTable
                        tableBody={tableBody}
                        tableHeader={tableHeader}
                        headerContainerClass={`!bg-slate-100`}
                        headerItemClass={`!text-sm !text-slate-700`}
                        bodyItemClass={`even:bg-slate-50 odd:bg-n-50`}
                        isAllSelectable={true}
                        isScrollable={true}
                        isCellBordered={true}
                    />
                </div>
            </div>
        </div>
    );
};

export default VendorAnalytics;
