import Button from "components/common/Button";
import Dropdown from "components/common/Dropdown";
import TextInput from "components/common/InputField/TextInput";
import PageTitleSection from "components/common/PageTitleSection";
import TabsContainer from "components/common/TabsContainer";
import WorkforceAnalyticsCard from "components/common/WorkforceAnalyticsCard";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ListView from "./ListView";
import GridView from "./GridView";
import useGetTaskList from "hooks/workforceHooks/useGetTaskList";
import useGetTaskAnalytics from "hooks/workforceHooks/useGetTaskAnalytics";
import Skeleton from "react-loading-skeleton";
import { formatMoney, getFromStorage } from "helpers";
import useGetAiAgents from "hooks/workforceHooks/useGetAiAgents";
import { camelCaseToRegularCase } from "helpers/camelCaseToRegularCase";
import { orderBy, truncate } from "lodash";
import useGetAiTeammates from "hooks/workforceHooks/useGetAiTeammates";
import { useChannel } from "ably/react";
import useGetUserProfile from "hooks/useGetUserProfile";

const TaskManager = () => {
    const tabs = ["All tasks"];
    const [activeTab, setActiveTab] = useState(0);
    const [selectedView, setSelectedView] = useState("grid");
    const [activeTasks, setActiveTasks] = useState([]);
    const [pendingTasks, setPendingTasks] = useState([]);
    const [completedTasks, setCompletedTasks] = useState([]);

    const profile = getFromStorage("ally-user");

    const navigate = useNavigate();
    const { data: taskAnalytics, isFetching: isFetchingAnalytics } = useGetTaskAnalytics();

    const { data: aiAgents, isFetching: isFetchingAiAgent } = useGetAiAgents();
    const {
        isFetching: fetchingAiTeammates,
        data: aiTeammates,
        search: searchTeammate,
        handleSearch: handleTeammateSearch,
        handleDebouncedChange: handleTeammateDebouncedChange,
        selectedTeammate,
        handleAssignedToFilterChange,
        handleClearAssignedToFilter,
    } = useGetAiTeammates();
    const {
        data: tasks,
        search,
        handleSearch,
        handleDebouncedChange,
        handleTaskStatusChange,
        handleClearTaskStatusFilter,
        handleTaskTypeChange,
        handleClearTaskTypeFilter,
        taskStatus,
        taskType,
        pagination,
        setUpdatedPage,
        setLoadMore,
    } = useGetTaskList(selectedTeammate?.value);

    const {} = useChannel(
        `agent_tasks_${profile?.user?.parentId || profile?.user?._id}`,
        (taskData) => {
            const isTask = taskData?.name?.split("_")?.length === 2;

            if (isTask) {
                let activeTasksCopy = [...activeTasks];
                let pendingTasksCopy = [...pendingTasks];
                let completedTasksCopy = [...completedTasks];

                const activeTaskIndex = activeTasksCopy?.findIndex(
                    (task) => task?._id === taskData?.data?.task?._id
                );
                const pendingTaskIndex = pendingTasksCopy?.findIndex(
                    (task) => task?._id === taskData?.data?.task?._id
                );
                const completedTaskIndex = pendingTasksCopy?.findIndex(
                    (task) => task?._id === taskData?.data?.task?._id
                );

                const moveToPending =
                    taskData?.data?.task?.status?.toLowerCase() === "pending" ||
                    taskData?.data?.task?.status?.toLowerCase() === "attention required" ||
                    taskData?.data?.task?.status?.toLowerCase() === "failed";
                const moveToActive = taskData?.data?.task?.status?.toLowerCase() === "in progress";
                const moveToCompleted = taskData?.data?.task?.status?.toLowerCase() === "completed";

                if (activeTaskIndex !== -1) {
                    activeTasksCopy?.splice(activeTaskIndex, 1);
                }

                if (pendingTaskIndex !== -1) {
                    pendingTasksCopy?.splice(pendingTaskIndex, 1);
                }

                if (completedTaskIndex !== -1) {
                    completedTasksCopy?.splice(completedTaskIndex, 1);
                }

                if (moveToPending) {
                    pendingTasksCopy = [taskData?.data?.task, ...pendingTasksCopy];
                }
                if (moveToActive) {
                    activeTasksCopy = [taskData?.data?.task, ...activeTasksCopy];
                }
                if (moveToCompleted) {
                    completedTasksCopy = [taskData?.data?.task, ...completedTasksCopy];
                }

                setPendingTasks(orderBy(pendingTasksCopy, "createdAt", ["desc"]));
                setActiveTasks(orderBy(activeTasksCopy, "createdAt", ["desc"]));
                setCompletedTasks(orderBy(completedTasksCopy, "createdAt", ["desc"]));
            }
        }
    );

    const displayActiveTab = () => {
        switch (activeTab) {
            case 0:
                return (
                    <div>
                        {selectedView === "grid" ? (
                            <GridView
                                activeTasks={activeTasks}
                                pendingTasks={pendingTasks}
                                completedTasks={completedTasks}
                                pagination={pagination}
                                setUpdatedPage={setUpdatedPage}
                                setLoadMore={setLoadMore}
                            />
                        ) : (
                            <ListView data={tasks} pagination={pagination} />
                        )}
                    </div>
                );

            default:
                break;
        }
    };

    useEffect(() => {
        setActiveTasks(orderBy(tasks?.activeTasks, "createdAt", ["desc"]));
        setPendingTasks(orderBy(tasks?.pendingTasks, "createdAt", ["desc"]));
        setCompletedTasks(orderBy(tasks?.completedTasks, "createdAt", ["desc"]));
    }, [tasks]);

    return (
        <div>
            <div className="p-4 border border-slate-100 rounded-[8px] bg-white">
                <div className="mb-6">
                    <PageTitleSection
                        leftComp={
                            <div className="flex items-center space-x-3">
                                <h3 className="text-xl font-rocGroteskMedium">Task manager</h3>
                            </div>
                        }
                    />
                </div>

                {isFetchingAnalytics ? (
                    <div className="grid grid-cols-4 items-center space-x-3 w-full mb-8">
                        {[1, 2, 3, 4]?.map((_, idx) => (
                            <Skeleton
                                key={idx}
                                width={"100%"}
                                height={127}
                                highlightColor="#E2E8F0"
                                borderRadius={8}
                                baseColor="#F1F5F9"
                                className="custom-skeleton"
                            />
                        ))}
                    </div>
                ) : (
                    <div className="grid grid-cols-3 items-center space-x-3 w-full mb-8">
                        <WorkforceAnalyticsCard
                            title="All tasks"
                            value={formatMoney().format(taskAnalytics?.allTasks?.current)}
                            bottomComp={
                                Boolean(taskAnalytics?.allTasks?.percentage) && (
                                    <div className="flex items-center space-x-2">
                                        <div
                                            className={`${
                                                String(
                                                    taskAnalytics?.allTasks?.percentage
                                                )?.startsWith("-")
                                                    ? "text-r-55 bg-r-25"
                                                    : "bg-[#F0FDF4] text-[#22C55E]"
                                            } text-sm px-1.5 py-[2px] flex items-center space-x-1 rounded-[3px] w-fit h-6`}
                                        >
                                            <i className="ri-arrow-up-line mt-[-2px] "></i>
                                            <span>
                                                {taskAnalytics?.allTasks?.percentage?.toFixed(0)}%
                                            </span>
                                        </div>
                                        <span className="text-sm text-slate-500">
                                            since last month
                                        </span>
                                    </div>
                                )
                            }
                        />
                        <WorkforceAnalyticsCard
                            title="Ongoing AI Tasks"
                            value={formatMoney().format(
                                taskAnalytics?.ongoingAiAgentTasks?.current
                            )}
                            bottomComp={
                                Boolean(taskAnalytics?.ongoingAiAgentTasks?.percentage) && (
                                    <div className="flex items-center space-x-2">
                                        <div
                                            className={`${
                                                String(
                                                    taskAnalytics?.ongoingAiAgentTasks?.percentage
                                                )?.startsWith("-")
                                                    ? "text-r-55 bg-r-25"
                                                    : "bg-[#F0FDF4] text-[#22C55E]"
                                            } text-sm px-1.5 py-[2px] flex items-center space-x-1 rounded-[3px] w-fit h-6`}
                                        >
                                            <i className="ri-arrow-up-line mt-[-2px] "></i>
                                            <span>
                                                {taskAnalytics?.ongoingAiAgentTasks?.percentage?.toFixed(
                                                    0
                                                )}
                                                %
                                            </span>
                                        </div>
                                        <span className="text-sm text-slate-500">
                                            since last month
                                        </span>
                                    </div>
                                )
                            }
                        />

                        <WorkforceAnalyticsCard
                            title="Task that requires human attention"
                            value={`${formatMoney().format(
                                taskAnalytics?.taskRequiringHumanAttention?.current
                            )}`}
                            bottomComp={
                                Boolean(taskAnalytics?.taskRequiringHumanAttention?.percentage) && (
                                    <div className="flex items-center space-x-2">
                                        <div
                                            className={`${
                                                String(
                                                    taskAnalytics?.taskRequiringHumanAttention
                                                        ?.percentage
                                                )?.startsWith("-")
                                                    ? "text-r-55 bg-r-25"
                                                    : "bg-[#F0FDF4] text-[#22C55E]"
                                            } text-sm px-1.5 py-[2px] flex items-center space-x-1 rounded-[3px] w-fit h-6`}
                                        >
                                            <i className="ri-arrow-up-line mt-[-2px] "></i>
                                            <span>
                                                {taskAnalytics?.taskRequiringHumanAttention?.percentage?.toFixed(
                                                    0
                                                )}
                                                %
                                            </span>
                                        </div>
                                        <span className="text-sm text-slate-500">
                                            since last month
                                        </span>
                                    </div>
                                )
                            }
                        />
                    </div>
                )}

                <div>
                    <TabsContainer
                        tabs={tabs}
                        activeTab={activeTab}
                        setActiveTab={(idx) => {
                            setActiveTab(idx);
                        }}
                        component={displayActiveTab()}
                        className="!px-0"
                        showButtonClassName
                        itemClassName="!pb-2 max-lg:whitespace-nowrap"
                        borderLineClase={"!text-slate-100 w-fit max-lg:w-full mb-6"}
                        showButton={
                            <div className="flex items-center space-x-2 mb-6 max-sm:grid max-sm:space-x-0 max-sm:space-y-2 max-sm:grid-cols-1">
                                <div className="rounded flex h-[40px] w-[80px] border border-slate-200">
                                    <span
                                        onClick={() => setSelectedView("grid")}
                                        className={`h-full w-full flex items-center justify-center hover:bg-slate-200 cursor-pointer border-r border-slate-200 ${
                                            selectedView === "grid" && "bg-slate-200"
                                        }`}
                                    >
                                        <i className="ri-layout-grid-line text-base"></i>
                                    </span>
                                    <span
                                        onClick={() => setSelectedView("list")}
                                        className={`h-full w-full flex items-center justify-center hover:bg-slate-200 cursor-pointer ${
                                            selectedView === "list" && "bg-slate-200"
                                        }`}
                                    >
                                        <i className="ri-list-check text-base"></i>
                                    </span>
                                </div>

                                <TextInput
                                    name={"search"}
                                    value={search}
                                    type={"text"}
                                    inputPlaceholder={"Search tasks"}
                                    inputClassName={"!h-[38px] pl-[0px] !bg-white text-sm !mb-0"}
                                    onChange={handleSearch}
                                    onInput={handleDebouncedChange}
                                    containerClassname={"!w-[250px] max-sm:!w-full"}
                                    leftIcon={
                                        <i className="ri-search-line before:content-['\f0d1] text-slate-400 mt-[2px]"></i>
                                    }
                                    inputContainerClassname={
                                        "!rounded-[4px] max-sm:!w-full !pl-[25px] !h-[40px] !bg-white !border !border-slate-200"
                                    }
                                />

                                <Dropdown
                                    name="filter"
                                    value={""}
                                    dropdownTriggerClassName={" "}
                                    handleChange={(name, value) => {
                                        handleTaskTypeChange(value);
                                    }}
                                    dropdown={
                                        <div className="bg-white flex items-center max-sm:justify-between h-10 gap-2 py-2.5 px-3 shadow-boxShadow-2 border border-slate-200 !rounded">
                                            <span className="text-[13px] whitespace-nowrap font-rocGroteskMedium">
                                                {camelCaseToRegularCase(taskType) &&
                                                camelCaseToRegularCase(taskType) !== "undefined"
                                                    ? camelCaseToRegularCase(taskType)
                                                    : "Task type"}
                                            </span>

                                            <div className="flex items-center space-x-2">
                                                {taskType && (
                                                    <i
                                                        onClick={(e) => {
                                                            e?.stopPropagation();
                                                            handleClearTaskTypeFilter();
                                                        }}
                                                        className="ri-close-circle-fill text-sm text-slate-500"
                                                    ></i>
                                                )}
                                                <i className="ri-arrow-down-s-line text-lg text-gm-50"></i>
                                            </div>
                                        </div>
                                    }
                                    dropdownClassName={"!w-full !mb-10 !-mt-7"}
                                    dropdownContainerClasses={`shadow-cardShadow-4 !right-0 !mt-0  !w-[159px] max-sm:!w-full  rounded !max-h-fit
                                                 !p-0 !top-[105%]`}
                                    wholeContainerClass={`max-sm:!w-full`}
                                    dropdownItemsClasses="!bg-transparent border-b-[1px] border-b-slate-100"
                                    dropdownOptions={aiAgents?.map((agent) => {
                                        return {
                                            label: camelCaseToRegularCase(agent?.type),
                                            value: agent?.type,
                                        };
                                    })}
                                />

                                <Dropdown
                                    name="filter"
                                    value={""}
                                    dropdownTriggerClassName={" "}
                                    handleChange={(name, value) => {
                                        handleTaskStatusChange(value);
                                    }}
                                    dropdown={
                                        <div className="bg-white flex items-center max-sm:justify-between h-10 gap-2 py-2.5 px-3 shadow-boxShadow-2 border border-slate-200 !rounded">
                                            <span className="text-[13px] whitespace-nowrap font-rocGroteskMedium capitalize">
                                                {taskStatus || "Status"}
                                            </span>

                                            <div className="flex items-center space-x-2">
                                                {taskStatus && (
                                                    <i
                                                        onClick={(e) => {
                                                            e?.stopPropagation();
                                                            handleClearTaskStatusFilter();
                                                        }}
                                                        className="ri-close-circle-fill text-sm text-slate-500"
                                                    ></i>
                                                )}
                                                <i className="ri-arrow-down-s-line text-lg text-gm-50"></i>
                                            </div>
                                        </div>
                                    }
                                    dropdownClassName={"!w-full !mb-10 !-mt-7"}
                                    dropdownContainerClasses={`shadow-cardShadow-4 !right-0 !mt-0  !w-[159px] max-sm:!w-full  rounded !max-h-fit
                                                 !p-0 !top-[105%]`}
                                    wholeContainerClass={`max-sm:!w-full`}
                                    dropdownItemsClasses="!bg-transparent border-b-[1px] border-b-slate-100"
                                    dropdownOptions={[
                                        {
                                            label: "Pending",
                                            value: "pending",
                                        },
                                        {
                                            label: "In Progress",
                                            value: "in progress",
                                        },
                                        {
                                            label: "Completed",
                                            value: "completed",
                                        },
                                        {
                                            label: "Failed",
                                            value: "failed",
                                        },
                                        {
                                            label: "Attention Required",
                                            value: "attention required",
                                        },
                                    ]}
                                />

                                <Dropdown
                                    handleChange={(name, value) => {
                                        console.log("");
                                    }}
                                    dropdown={
                                        <div className="flex items-center justify-between whitespace-nowrap h-[40px]  py-2 px-4 space-x-1 rounded-[5px] border border-slate-200">
                                            <span
                                                className={`text-g-75 font-rocGroteskMedium text-[13px]`}
                                            >
                                                {truncate(selectedTeammate?.name, { length: 20 }) ||
                                                    "Assigned to"}
                                            </span>
                                            <div className="flex items-center space-x-1">
                                                {selectedTeammate?.name && (
                                                    <i
                                                        onClick={handleClearAssignedToFilter}
                                                        className="ri-close-circle-line text-slate-500"
                                                    ></i>
                                                )}
                                                <i className="ri-arrow-down-s-line text-g-75"></i>
                                            </div>
                                        </div>
                                    }
                                    isLoading={fetchingAiTeammates}
                                    search={
                                        <div>
                                            <TextInput
                                                value={searchTeammate}
                                                name={"search"}
                                                type={"text"}
                                                required={false}
                                                onChange={handleTeammateSearch}
                                                onInput={handleTeammateDebouncedChange}
                                                inputPlaceholder="Search AI teammates"
                                                containerClassname=""
                                                // inputContainerClassname={"!border-none !rounded-none "}
                                                inputClassName="w-full"
                                            />
                                        </div>
                                    }
                                    wholeContainerClass="!w-[33.33%] max-sm:!w-full"
                                    dropdownContainerClasses={`shadow-cardShadow-4 !w-full !max-w-full !right-0 min-w-full border border-slate-100 rounded !max-h-fit !pt-0`}
                                    dropdownOptions={[
                                        {
                                            customChild: (
                                                <div>
                                                    <div className="max-h-[280px]">
                                                        {aiTeammates?.map((teammate) => {
                                                            return (
                                                                <div
                                                                    key={teammate?._id}
                                                                    onClick={() =>
                                                                        handleAssignedToFilterChange(
                                                                            teammate?.teamMateName,
                                                                            teammate?._id
                                                                        )
                                                                    }
                                                                >
                                                                    <div
                                                                        className={`flex items-center space-x-3 px-3 py-2 hover:bg-slate-100 cursor-pointer `}
                                                                    >
                                                                        <div>
                                                                            <div className="flex items-center space-x-3">
                                                                                <div>
                                                                                    <span className="block text-xs font-rocGroteskMedium">
                                                                                        {truncate(
                                                                                            teammate?.teamMateName,
                                                                                            {
                                                                                                length: 30,
                                                                                            }
                                                                                        )}
                                                                                    </span>
                                                                                    <span className="block text-[10px] text-slate-500 font-rocGroteskMedium">
                                                                                        {truncate(
                                                                                            camelCaseToRegularCase(
                                                                                                teammate?.agentType
                                                                                            ),
                                                                                            {
                                                                                                length: 50,
                                                                                            }
                                                                                        )}
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                </div>
                                            ),
                                        },
                                    ]}
                                />

                                {/* <Button
                                    btnText="Settings"
                                    btnClassname="!px-4 !py-2 !w-fit max-sm:!w-full !h-10 !bg-transparent !border !border-slate-200 !text-[13px] !whitespace-nowrap"
                                    icon={<i className="ri-settings-2-line text-base"></i>}
                                    onClick={() => {}}
                                />

                                <Button
                                    btnText="Create new task"
                                    btnClassname="!px-4 !py-2 !w-fit max-sm:!w-full !h-10 !text-[13px] !whitespace-nowrap"
                                    onClick={() => {
                                        // navigate(`/dashboard/workforce/create-ai-teammate`);
                                    }}
                                /> */}
                            </div>
                        }
                    />
                </div>
            </div>
        </div>
    );
};

export default TaskManager;
