import Button from "components/common/Button";
import CustomTable from "components/common/CustomTable";
import TextInput from "components/common/InputField/TextInput";
import VendorAvatar from "components/common/VendorAvatar";
import PageFrame from "components/layout/PageFrame";
import { formatMoney } from "helpers";
import useGetSingleBOM from "hooks/ProductionHooks/useGetSingleBOM";
import { truncate } from "lodash";
import moment from "moment";
import React, { useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

const BillOfMaterialDetails = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [params] = useSearchParams();
    const currTab = params.get("currTab");
    const [search, setSearch] = useState<string>("");
    const { data, isLoading } = useGetSingleBOM();

    const BOMTableHeader = [
        { title: "Category", widthClass: "w-[15%] !border !bg-slate-50 !border-slate-200" },
        {
            title: "Product",
            widthClass: "w-[20%]  !border !bg-slate-50 !border-slate-200",
        },
        {
            title: "Description",
            widthClass: "w-[18%] !border !bg-slate-50 !border-slate-200",
        },
        {
            title: "Quantity",
            widthClass: "w-[10%] !border !bg-slate-50 !border-slate-200",
        },
        {
            title: "Scrap",
            widthClass: "w-[8%] !border !bg-slate-50 !border-slate-200",
        },
        { title: "Total Quantity", widthClass: "w-[10%] !border !bg-slate-50 !border-slate-200" },
        {
            title: "UOM",
            widthClass: "w-[7%] !border !bg-slate-50 !border-slate-200",
        },
        {
            title: "Unit price",
            widthClass: "w-[10%] !border !bg-slate-50 !border-slate-200",
        },
        {
            title: "Total price",
            widthClass: "w-[10%] !border !bg-slate-50 !border-slate-200",
        },
    ];

    const BOMTableBody = data?.billOfMaterialIds?.map((product, idx) => {
        const totalQuantity = (product?.quantity * product?.scrap) / 100 + product?.quantity;
        const totalPrice = totalQuantity * product?.unitPrice;

        return [
            {
                content: (
                    <div className={`w-full  px-4 `}>
                        <p className="text-slate-950 text-sm">{product?.inventoryCategory}</p>
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                widthClass: "!border-slate-200",
            },
            {
                content: (
                    <div className={`w-full  `}>
                        <div className="flex items-center gap-2 py-2 px-2.5">
                            {!product ? (
                                <VendorAvatar
                                    // imageSrc={
                                    //     product?.productImageDetails?.productAvatar ||
                                    //     product?.productImageDetails?.productImages?.[0]
                                    // }
                                    size={32}
                                    // name={product?.productName}
                                    containerClassname="!rounded-md"
                                />
                            ) : (
                                <div className="w-8 h-8 rounded shrink-0 flex items-center justify-center bg-slate-200 ">
                                    <i className="ri-box-2-fill text-lg text-slate-500"></i>
                                </div>
                            )}

                            {/* <span>{truncate(item?.inventory?.productName, { length: 20 })}</span> */}
                            <div className="text-sm">
                                <p className="text-g-75"> {product?.productName}</p>
                                <p className="text-[#64748B]">{product?.sku}</p>
                            </div>
                        </div>
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                widthClass: "!border-slate-200",
            },
            {
                content: (
                    <div className={`w-full px-2.5 py-2`}>
                        <p className="text-slate-950 text-sm">
                            {product?.productDescription
                                ? truncate(product?.productDescription, { length: 40 })
                                : "N/A"}
                        </p>
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                widthClass: "!border-slate-200",
            },

            {
                content: (
                    <div className={`w-full px-2.5 py-2`}>
                        <p className="text-slate-950 text-sm">
                            {product?.quantity}
                            {` `}
                            {product?.quantity ? product?.unitOfMeasurement : ""}
                        </p>
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                widthClass: "!border-slate-200",
            },

            {
                content: (
                    <div className={`w-full px-2.5 py-2`}>
                        <p className="text-slate-950 text-sm">{product?.scrap}%</p>
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                widthClass: "!border-slate-200",
            },
            {
                content: (
                    <div className={`w-full px-2.5 py-2`}>
                        <p className="text-slate-950 text-sm">
                            {totalQuantity && Number.isInteger(totalQuantity)
                                ? totalQuantity
                                : totalQuantity && totalQuantity?.toFixed(2)}
                            {` `}
                            {totalQuantity ? product?.unitOfMeasurement : ""}
                        </p>
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                widthClass: "!border-slate-200",
            },
            {
                content: (
                    <div className={`w-full px-2.5 py-2`}>
                        <p className="text-slate-950 text-sm"> {product?.unitOfMeasurement}</p>
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                widthClass: "!border-slate-200",
            },
            {
                content: (
                    <div className={`w-full px-2.5 py-2`}>
                        <p className="text-slate-950 text-sm">
                            ${formatMoney()?.format(product?.unitPrice)}
                        </p>
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                widthClass: "!border-slate-200",
            },
            {
                content: (
                    <div className={`w-full px-2.5 py-2`}>
                        <p className="text-slate-950 text-sm">
                            ${formatMoney()?.format(totalPrice)}
                        </p>
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                widthClass: "!border-slate-200",
            },
            //     {
            //     content: (
            //         <div className={`w-full pl-4 py-2`}>

            //         <img src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1727444338/close-fill_xxogdp.svg" alt="" className="" />
            //         </div>
            //     ),
            //     tableDataDivClass: "!pl-0",
            //     widthClass:""

            // },
        ];
    });

    const tableFooter = () => {
        const totalUnitPrice = data?.billOfMaterialIds?.reduce(
            (sum, pro) => sum + (pro?.unitPrice || 0),
            0
        );

        const totalPrice = data?.billOfMaterialIds?.reduce(
            (sum, pro) =>
                sum + (((pro?.quantity * pro?.scrap) / 100 + pro?.quantity) * pro.unitPrice || 0),
            0
        );
        return (
            <div className="flex text-slate-500 text-[13px]">
                <p className="bg-slate-50 w-[15%] border-slate-200 border-l border-b rounded-bl-md p-2.5">
                    Total
                </p>
                <p className="bg-slate-50 w-[20%] border-slate-200 border-l border-b p-2.5">
                    {data?.billOfMaterialIds?.length}
                </p>
                <p className="bg-slate-50 w-[18%] border-slate-200 border-l border-b p-2.5"></p>
                <p className="bg-slate-50 w-[10%] border-slate-200 border-l border-b p-2.5"> </p>
                <p className="bg-slate-50 w-[8%] border-slate-200 border-l border-b p-2.5"></p>
                <p className="bg-slate-50 w-[10%] border-slate-200 border-l border-b p-2.5"></p>
                <p className="bg-slate-50 w-[7%] border-slate-200 border-l border-b p-2.5"></p>
                <p className="bg-slate-50 w-[10%] border-slate-200 border-l border-b p-2.5">
                    ${totalUnitPrice}
                </p>
                <p className="bg-slate-50 w-[10%] border-slate-200 border-x border-b p-2.5 rounded-br-md">
                    ${formatMoney()?.format(totalPrice)}
                </p>
                {/* <p className="bg-slate-50 w-[5%] border-slate-200 border-x border-b p-2.5 rounded-br-md"></p> */}
            </div>
        );
    };
    return (
        <PageFrame isLoading={isLoading}>
            <div>
                <div className="bg-white rounded-lg border border-slate-100 font-rocGroteskMedium pb-12">
                    <div className="flex items-center justify-between px-6 py-6 border-b border-n-20">
                        <div
                            className="flex items-center space-x-1.5 w-fit cursor-pointer"
                            onClick={() => navigate(`/dashboard/production?currTab=${currTab}`)}
                        >
                            <i className="ri-arrow-left-line text-[20px]"></i>
                            <p className="text-lg text-gm-50 font-rocGroteskMedium">BOM-00000123</p>
                        </div>

                        <div className="flex items-center  gap-3">
                            <Button
                                btnText="Close"
                                btnClassname="!px-4 !py-2 !bg-slate-100 !text-g-75 !w-fit text-[13px]"
                                // disabled={}
                                onClick={() => navigate(`/dashboard/production?currTab=${currTab}`)}
                            />
                            <Button
                                btnText="Edit BOM"
                                type="submit"
                                // isLoading={}
                                // disabled={}
                                onClick={() =>
                                    navigate(
                                        `/dashboard/production/edit-bom/${id}?currTab=${currTab}`
                                    )
                                }
                                btnClassname="!px-4 !py-2 !text-g-75 !w-fit text-[13px]"
                            />
                        </div>
                    </div>

                    <div className="px-6">
                        <div className="py-6 border-b border-slate-200">
                            <p className="text-gm-50 text-sm">Product</p>
                            <h6 className="text-g-75 text-2xl">{data?.productName}</h6>
                        </div>

                        <div className="">
                            <div className="grid grid-cols-6 py-6 border-b border-slate-200">
                                <div className="">
                                    <p className="text-slate-500 text-sm">Created on</p>
                                    <p className="text-gm-50 text-sm">
                                        {data?.bomCreatedAt
                                            ? moment(data?.bomCreatedAt).format("D MMM YYYY")
                                            : "N/A"}
                                    </p>
                                </div>
                                <div className="">
                                    <p className="text-slate-500 text-sm">Created by</p>
                                    <p className="text-gm-50 text-sm">
                                        {data?.bomCreator
                                            ? data?.bomCreator?.firstName || data?.bomCreator?.email
                                            : "N/A"}
                                    </p>

                                    {/* <img src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1727444195/Frame_1000004085_gffctg.svg" alt="" className="" /> */}
                                </div>
                                <div className="">
                                    <p className="text-slate-500 text-sm">Last Modified By</p>
                                    <p className="text-gm-50 text-sm">
                                        {data?.bomModifier ?? "N/A"}
                                    </p>
                                </div>
                                <div className="">
                                    <p className="text-slate-500 text-sm">Last Modified Date</p>
                                    <p className="text-gm-50 text-sm">
                                        {data?.bomModifiedAt
                                            ? moment(data?.bomModifiedAt).format("D MMM YYYY")
                                            : "N/A"}
                                    </p>
                                </div>
                                {/* <div className="">
                <p className="text-slate-500 text-sm">Version</p>
                <p className="text-gm-50 text-sm">1.2</p>
            </div>
            <div className="">
                <p className="text-slate-500 text-sm">Work Order</p>
                <p className="text-gm-50 text-sm">PO00123</p>
            </div> */}
                            </div>
                        </div>
                    </div>

                    <div className="mt-6 font-rocGroteskMedium px-6">
                        <div className="flex items-center justify-between p-4 border-x border-t  border-slate-200 rounded-t-md">
                            <p className="text-gm-75 text-base ">
                                Bill of materials (BOM) for {data?.productName}
                            </p>
                            <div className="flex gap-2 ">
                                <TextInput
                                    name="search"
                                    type="text"
                                    value={search}
                                    onChange={(e) => setSearch(e.target.value)}
                                    // onInput={handleDebouncedChange}
                                    inputPlaceholder="Search product"
                                    inputClassName={
                                        "!h-[40px] pl-[0px] !mb-0 !font-rocGroteskMedium !text-slate-400"
                                    }
                                    leftIcon={
                                        <i className="ri-search-line before:content-['\f0d1] text-slate-200 mt-[2px]"></i>
                                    }
                                    inputContainerClassname={
                                        "!rounded-[4px]  !h-[40px] !pl-[20px] !bg-white !border !border-slate-200 !shadow-boxShadow !w-[300px]"
                                    }
                                />
                            </div>
                        </div>

                        <CustomTable
                            tableBody={BOMTableBody}
                            tableHeader={BOMTableHeader}
                            isScrollable={false}
                            isCellBordered={true}
                            isCellSelectable={false}
                            isAllSelectable={false}
                            scrollWithin={false}
                            headerContainerClass=""
                            headerItemClass="font-rocGroteskMedium !text-[#334155] "
                            tbodyClass="!border !border-slate-200"
                            tableClass="!border-slate-200"
                            bodyItemClass="hover:!bg-transparent !cursor-default !border-slate-200"
                        />
                        {tableFooter()}
                    </div>
                </div>
            </div>
        </PageFrame>
    );
};

export default BillOfMaterialDetails;
