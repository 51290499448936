import VendorAvatar from "components/common/VendorAvatar";
import React from "react";

interface Props {
    imageSrc: string;
    imageName: string;
    title: string;
    content: string;
    connected: boolean;
    onClick: any;
    height?: number;
    width?: number;
}

const IntegrationCard = ({
    imageName,
    height = 38,
    width = 38,
    imageSrc,
    title,
    onClick,
    connected,
    content,
}: Props) => {
    return (
        <div className="bg-white border  rounded-[8px] border-slate-100 shadow-faintShadow">
            <div className="px-4 pt-4  ">
                <div className="flex justify-between items-center">
                    <div className="bg-white shadow-faintShadow rounded-[6px] w-[50px] h-[50px] flex justify-center items-center">
                        <img
                            width={width}
                            height={height}
                            src={imageSrc}
                            alt={imageName}
                        />
                    </div>
                    <div
                        className={` border ${
                            connected
                                ? "border-[#16A34A] bg-[#DCFCE7]"
                                : "border-[0.5px] border-slate-200 bg-slate-50"
                        } py-[5px] px-[15px] rounded`}
                    >
                        <p
                            className={`text-xs ${
                                connected ? "text-[#16A34A]" : "text-slate-400"
                            } font-rocGroteskMedium`}
                        >
                            {connected ? "Connected" : "Not connected"}
                        </p>
                    </div>
                </div>
                <div className="mt-3">
                    <p className="text-gm-50 font-rocGroteskMedium text-sm mb-1">
                        Connect Your {title} Account
                    </p>
                    <p className="text-[#64748B] mb-3 text-xs font-rocGroteskMedium ">{content}</p>
                </div>
            </div>
            <div className="border-t border-slate-100 pt-4 ">
                <div className="px-4 pb-4">
                    <div
                        onClick={onClick}
                        className={` cursor-pointer border-p-70 bg-slate-100 border
                               md:w-fit flex justify-center space-x-2 items-center py-[5px] px-[10px] rounded`}
                    >
                        <p className={`text-xs text-p-55  font-rocGroteskMedium`}>
                            {connected ? "View connection" : `Connect ${title}`}
                        </p>
                        <i
                            className={`ri-${
                                !connected ? "external-link-line " : "arrow-right-line"
                            } text-p-55 `}
                        ></i>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default IntegrationCard;
