export const oceanCarriers = [
    {
        "Carrier Name": "ACL SINGAPORE",
        "Full Name": "ADVANCE CONTAINER LINES",
        "Carrier Website": "http://aclship.com",
        SCAC: "PILX",
        "SCAC synonyms": "{ACL-SINGAPORE,ACLSINGAPORE}",
    },
    {
        "Carrier Name": "ACL USA",
        "Full Name": "ATLANTIC CONTAINER LINE",
        "Carrier Website": "http://aclcargo.com",
        SCAC: "ACLU",
        "SCAC synonyms": "{ACL-USA,ACLUSA,ACLZ}",
    },
    {
        "Carrier Name": "Admiral",
        "Full Name": "Admiral Container Lines",
        "Carrier Website": "https://www.acolines.com/",
        SCAC: "ADMU",
        "SCAC synonyms": "",
    },
    {
        "Carrier Name": "ALIANCA",
        "Full Name": "ALIANÇA NAVEGAÇÃO E LOGÍSTICA",
        "Carrier Website": "http://alianca.com.br",
        SCAC: "ALIU",
        "SCAC synonyms": "{ANRM,ANMR}",
    },
    {
        "Carrier Name": "ANL",
        "Full Name": "AUSTRALIAN NATIONAL LINES",
        "Carrier Website": "http://anl.com.au",
        SCAC: "ANNU",
        "SCAC synonyms": '{"ANL CONTAINER LINE"}',
    },
    {
        "Carrier Name": "APL",
        "Full Name": "AMERICAN PRESIDENTS LINE",
        "Carrier Website": "http://apl.com",
        SCAC: "APLU",
        "SCAC synonyms": '{"AMERICAN PRESIDENT L"}',
    },
    {
        "Carrier Name": "ARKAS LINE",
        "Full Name": "ARKAS LINE",
        "Carrier Website": "http://arkasline.com.tr",
        SCAC: "ARKU",
        "SCAC synonyms": "{ARKAS-LINE,ARKASLINE}",
    },
    {
        "Carrier Name": "BALAJI",
        "Full Name": "Avana Global FZCO",
        "Carrier Website": "",
        SCAC: "BLJU",
        "SCAC synonyms": "",
    },
    {
        "Carrier Name": "BLPL",
        "Full Name": "BLPL Singapore",
        "Carrier Website": "http://tgsblpl.com/",
        SCAC: "BLZU",
        "SCAC synonyms": "",
    },
    {
        "Carrier Name": "BLUE ANCHOR LINE",
        "Full Name": "BLUE ANCHOR LINE",
        "Carrier Website": "https://blue-anchor-line.com/",
        SCAC: "BANQ",
        "SCAC synonyms": "{BLUE-ANCHOR-LINE,BLUEANCHORLINE}",
    },
    {
        "Carrier Name": "Blue Water Lines (BWL)",
        "Full Name": "",
        "Carrier Website": "https://abraogroup.com/group-of-companies/bwl/",
        SCAC: "BWLU",
        "SCAC synonyms": "{Blue-Water-Lines-(BWL),BWL,BlueWaterLines(BWL),BlueWaterLines}",
    },
    {
        "Carrier Name": "Blue World Line",
        "Full Name": "",
        "Carrier Website": "",
        SCAC: "BWLE",
        "SCAC synonyms": "{Blue-World-Line,BWL,BlueWorldLine}",
    },
    {
        "Carrier Name": "BMC Line Shipping",
        "Full Name": "",
        "Carrier Website": "https://bmclines.com/",
        SCAC: "BMSU",
        "SCAC synonyms": "{BMC-Line-Shipping,BMCLineShipping}",
    },
    {
        "Carrier Name": "CARPENTERS",
        "Full Name": "CARPENTERS SHIPPING",
        "Carrier Website": "http://carpentersshipping.com",
        SCAC: "MBFU",
        "SCAC synonyms": "",
    },
    {
        "Carrier Name": "China Navigation Company (Swire Shipping)",
        "Full Name": "",
        "Carrier Website": "https://www.swirecnco.com/",
        SCAC: "CHVW",
        "SCAC synonyms":
            "{Swire-Shipping,SwireShipping,China-Navigation-Company,ChinaNavigationCompany,ChinaNavigationCompany(SwireShipping)}",
    },
    {
        "Carrier Name": "China United Lines",
        "Full Name": "",
        "Carrier Website": "https://www.culines.com/en/",
        SCAC: "CULU",
        "SCAC synonyms": "{China-United-Lines,ChinaUnitedLines}",
    },
    {
        "Carrier Name": "CK LINE",
        "Full Name": "CHUN KYUNG LINE",
        "Carrier Website": "http://ckline.co.kr",
        SCAC: "CKYX",
        "SCAC synonyms": "{CKLU,CK-LINE,CKLINE}",
    },
    {
        "Carrier Name": "CMA-CGM",
        "Full Name": "CMA-CGM GROUP",
        "Carrier Website": "http://cma-cgm.com",
        SCAC: "CMDU",
        "SCAC synonyms":
            '{CMA,CMA-CGM,"CMA CGM","CMA CGM (AMERICA) IN",UASC,CMACGM,CMAU,CMBU,CMDU}',
    },
    {
        "Carrier Name": "CNC",
        "Full Name": "Cheng Lie Navigation Co.,Ltd",
        "Carrier Website": "https://www.cnc-ebusiness.com/",
        SCAC: "11DX",
        "SCAC synonyms": "",
    },
    {
        "Carrier Name": "CNC LINE",
        "Full Name": "CHENG LIE NAVIGATION",
        "Carrier Website": "http://cncline.com.tw",
        SCAC: "CNCU",
        "SCAC synonyms": "{11DX,CNC-LINE,CNCLINE}",
    },
    {
        "Carrier Name": "CONTAINERSHIPS",
        "Full Name": "CONTAINERSHIPS",
        "Carrier Website": "http://containershipsgroup.com",
        SCAC: "CSFU",
        "SCAC synonyms": "",
    },
    {
        "Carrier Name": "COSCO",
        "Full Name": "COSCO CONTAINER LINES",
        "Carrier Website": "http://coscon.com",
        SCAC: "COSU",
        "SCAC synonyms":
            '{"CHINA OCEAN SHIPPING","CHINA COSCO SHIPPING CONTAINER LINE","COSCO SHIPPING LINES","CHINA COSCO SHIPPING CORPORATION LIMITED","COSCO SHIPPING","COSCO GROUP",COPU,CCLN}',
    },
    {
        "Carrier Name": "COSIARMA",
        "Full Name": "COSIARMA shipping",
        "Carrier Website": "http://www.cosiarma.it/en/",
        SCAC: "CMSP",
        "SCAC synonyms": "{CMSP,CRAU}",
    },
    {
        "Carrier Name": "CROWLEY",
        "Full Name": "CROWLEY LINER SERVICES",
        "Carrier Website": "http://crowley.com",
        SCAC: "CAMN",
        "SCAC synonyms": "{CMCU,CPRC}",
    },
    {
        "Carrier Name": "CULINES",
        "Full Name": "CHINA UNITED LINES",
        "Carrier Website": "http://culines.com",
        SCAC: "CULX",
        "SCAC synonyms": "",
    },
    {
        "Carrier Name": "DAL",
        "Full Name": "DEUTSCHE AFRIKA LINIEN",
        "Carrier Website": "http://dal.biz",
        SCAC: "DALD",
        "SCAC synonyms": "{DAYU}",
    },
    {
        "Carrier Name": "Damco",
        "Full Name": "",
        "Carrier Website": "",
        SCAC: "DMCQ",
        "SCAC synonyms": "",
    },
    {
        "Carrier Name": "DB Schenker",
        "Full Name": "",
        "Carrier Website": "https://www.dbschenker.com/global",
        SCAC: "SHKK",
        "SCAC synonyms": "{DB-Schenker,DBSchenker}",
    },
    {
        "Carrier Name": "Dongjin",
        "Full Name": "Dongjin Shipping",
        "Carrier Website": "http://korea.djship.co.kr/",
        SCAC: "DJLU",
        "SCAC synonyms": "{11PG}",
    },
    {
        "Carrier Name": "DONGJIN",
        "Full Name": "DONGJIN SHIPPING",
        "Carrier Website": "http://djship.co.kr",
        SCAC: "DJLE",
        "SCAC synonyms": "{11PG}",
    },
    {
        "Carrier Name": "Dsv Ocean Transport",
        "Full Name": "Dsv Ocean Transport",
        "Carrier Website": "https://www.dsv.com/",
        SCAC: "DSVF",
        "SCAC synonyms": "{SAT}",
    },
    {
        "Carrier Name": "Econship",
        "Full Name": "",
        "Carrier Website": "https://www.econshipping.com/",
        SCAC: "ECNU",
        "SCAC synonyms": "",
    },
    {
        "Carrier Name": "ECU",
        "Full Name": "ECU Worldwide",
        "Carrier Website": "https://ecuworldwide.com/",
        SCAC: "ECUI",
        "SCAC synonyms": "{ECUW,ECU-Worldwide,ECUWorldwide}",
    },
    {
        "Carrier Name": "EMIRATES",
        "Full Name": "EMIRATES SHIPPING LINE",
        "Carrier Website": "http://emiratesline.com",
        SCAC: "EPIR",
        "SCAC synonyms": "{ESPU}",
    },
    {
        "Carrier Name": "Emkay Lines",
        "Full Name": "",
        "Carrier Website": "https://emkayline.com/",
        SCAC: "EMKU",
        "SCAC synonyms": "{Emkay-Lines,EmkayLines}",
    },
    {
        "Carrier Name": "Ethiopian Shipping Line",
        "Full Name": "",
        "Carrier Website": "https://www.eslse.et/",
        SCAC: "ESLU",
        "SCAC synonyms": "{Ethiopian-Shipping-Line,EthiopianShippingLine,ESL}",
    },
    {
        "Carrier Name": "Eukor",
        "Full Name": "",
        "Carrier Website": "https://www.eukor.com/",
        SCAC: "EUKO",
        "SCAC synonyms": "",
    },
    {
        "Carrier Name": "EVERGREEN",
        "Full Name": "EVERGREEN LINES & EVERGREEN MARINE",
        "Carrier Website": "http://evergreen-marine.com",
        SCAC: "EGLV",
        "SCAC synonyms": '{EVERGREEN,"EVERGREEN LINE",EVRG}',
    },
    {
        "Carrier Name": "FESCO",
        "Full Name": "FAR EASTERN SHIPPING COMPANY",
        "Carrier Website": "http://fesco.com",
        SCAC: "FESO",
        "SCAC synonyms": "",
    },
    {
        "Carrier Name": "GOLD STAR",
        "Full Name": "GOLD STAR LINE (GSL)",
        "Carrier Website": "http://gslltd.com.hk",
        SCAC: "GSLU",
        "SCAC synonyms": "{GOSU,GOLD-STAR,GOLDSTAR}",
    },
    {
        "Carrier Name": "Grimaldi Lines",
        "Full Name": "Grimaldi Lines",
        "Carrier Website": "https://www.grimaldi.napoli.it/",
        SCAC: "GMGO",
        "SCAC synonyms": "{GRIU}",
    },
    {
        "Carrier Name": "HAMBURG SUD",
        "Full Name": "HAMBURG SÜDAMERIKANISCHE DAMPFSCHIFFFAHRTS",
        "Carrier Website": "http://hamburgsud.com",
        SCAC: "SUDU",
        "SCAC synonyms": '{"HAMBURG SUD",HSUD,HAMBURGSUD}',
    },
    {
        "Carrier Name": "HAPAG-LLOYD",
        "Full Name": "HAPAG-LLOYD CONTAINER LINE",
        "Carrier Website": "http://hapag-lloyd.com",
        SCAC: "HLCU",
        "SCAC synonyms":
            '{HAPAG,"HAPAG LLOYD","HAPAG-LLOYD AMERICA",HAPAGLLOYD,HLAG,HALG,HCLU,"HAPAG-LLOYD AG",HAPPAG,"HAPAG LLOYD AG",HLBU}',
    },
    {
        "Carrier Name": "HASCO",
        "Full Name": "HAI HUA SHANGHAI SHIPPING COMPANY",
        "Carrier Website": "http://hasco.com.cn",
        SCAC: "12GE",
        "SCAC synonyms":
            '{HAHU,"HAI HUA SHIPPING (HASCO)",HASCO,"HAI HUA SHIPPING","HAI HUA SHANGHAI SHIPPING COMPANY","HASCO JAPAN","SHANGHAI HAI HUA SHIPPING CO.LTD."}',
    },
    {
        "Carrier Name": "HEUNG-A",
        "Full Name": "HEUNG-A SHIPPING",
        "Carrier Website": "http://heung-a.co.kr",
        SCAC: "HAEU",
        "SCAC synonyms": '{HASL,HAEU,HALU,11QU,"HEUNG A",HEUNGA}',
    },
    {
        "Carrier Name": "Hillebrand",
        "Full Name": "Hillebrand",
        "Carrier Website": "https://www.hillebrand.com/",
        SCAC: "HGLU",
        "SCAC synonyms": "{HILEBRAND,HLGU}",
    },
    {
        "Carrier Name": "HYUNDAI",
        "Full Name": "HYUNDAI MERCHANT MARINE (HMM)",
        "Carrier Website": "http://hmm.co.kr",
        SCAC: "HDMU",
        "SCAC synonyms":
            '{"HYUNDAI MERCHANT MAR",HMM,HMDU,HYNDAI,"HMM Co.",Ltd.,HMM21,"HYUNDAI MERCHANT MARINE CO.LTD"}',
    },
    {
        "Carrier Name": "ICL",
        "Full Name": "INDEPENDENT CONTAINER LINE",
        "Carrier Website": "http://icl-ltd.com",
        SCAC: "IILU",
        "SCAC synonyms": "",
    },
    {
        "Carrier Name": "Indus Container Lines (IDCL)",
        "Full Name": "",
        "Carrier Website": "http://induscontainer.com/frmHomeWV.aspx",
        SCAC: "IDCL",
        "SCAC synonyms": "{Indus-Container-Lines,IndusContainerLines,IDCL}",
    },
    {
        "Carrier Name": "Interasia",
        "Full Name": "Interasia Lines",
        "Carrier Website": "https://www.interasia.cc/",
        SCAC: "12AT",
        "SCAC synonyms": "{IAAU,Interasia-Lines,InterasiaLines,IASL}",
    },
    {
        "Carrier Name": "JIN JIANG",
        "Full Name": "JIN JIANG SHANGHAI SHIPPING CORPORATION",
        "Carrier Website": "http://jinjiangshipping.com",
        SCAC: "JINX",
        "SCAC synonyms": "{11WJ,JIN-JIANG,JINJIANG}",
    },
    {
        "Carrier Name": "Kambara",
        "Full Name": "Kambara Kisen Co., Ltd.",
        "Carrier Website": "https://www.kambara-kisen.co.jp/",
        SCAC: "KKCL",
        "SCAC synonyms": "",
    },
    {
        "Carrier Name": "KAMBARA",
        "Full Name": "KAMBARA KISEN",
        "Carrier Website": "http://kambara-kisen.co.jp",
        SCAC: "KMBU",
        "SCAC synonyms": "{KKCL}",
    },
    {
        "Carrier Name": "K-LINE",
        "Full Name": "KAWASAKI KINKAI KISEN KAISHA LINE",
        "Carrier Website": "http://kline.com",
        SCAC: "KKLU",
        "SCAC synonyms": '{KLINE,"K LINE"}',
    },
    {
        "Carrier Name": "Kintetsu World Express",
        "Full Name": "Kintetsu World Express",
        "Carrier Website": "https://www.kwe.com/",
        SCAC: "KWEO",
        "SCAC synonyms": "",
    },
    {
        "Carrier Name": "KMTC",
        "Full Name": "KOREA MARINE TRANSPORT COMPANY",
        "Carrier Website": "http://kmtc.co.kr",
        SCAC: "KMTC",
        "SCAC synonyms": "{KMTU}",
    },
    {
        "Carrier Name": "Kuehne + Nagel (KN)",
        "Full Name": "",
        "Carrier Website": "https://home.kuehne-nagel.com/",
        SCAC: "KHNN",
        "SCAC synonyms": "{Kuehne-Nagel,KuehneNagel,Kuehne+Nagel,Kuehne+Nagel(KN),KN}",
    },
    {
        "Carrier Name": "LAUREL NAVIGATION",
        "Full Name": "LAUREL NAVIGATION",
        "Carrier Website": "https://lnl.com.hk/",
        SCAC: "LNLU",
        "SCAC synonyms": "{LAUREL-NAVIGATION,LAURELNAVIGATION}",
    },
    {
        "Carrier Name": "Leschaco",
        "Full Name": "",
        "Carrier Website": "https://www.leschaco.com/en/",
        SCAC: "LEHO",
        "SCAC synonyms": "",
    },
    {
        "Carrier Name": "MacAndrews",
        "Full Name": "MacAndrews",
        "Carrier Website": "https://www.macandrews.com/",
        SCAC: "MCAW",
        "SCAC synonyms": "{MACX}",
    },
    {
        "Carrier Name": "MACANDREWS",
        "Full Name": "MACANDREWS",
        "Carrier Website": "http://macandrews.com",
        SCAC: "MACX",
        "SCAC synonyms": "",
    },
    {
        "Carrier Name": "MAERSK",
        "Full Name": "MAERSK LINE",
        "Carrier Website": "http://maerskline.com",
        SCAC: "MAEU",
        "SCAC synonyms": '{MRKU,MAERSK,MSK,"MAERSK A/S",MAERSK}',
    },
    {
        "Carrier Name": "MARGUISA",
        "Full Name": "MARGUISA SHIPPING COMPANY",
        "Carrier Website": "http://marguisa.com",
        SCAC: "MGSU",
        "SCAC synonyms": "",
    },
    {
        "Carrier Name": "Mariana Express Lines (MELL)",
        "Full Name": "Mariana Express Lines (MELL)",
        "Carrier Website": "https://www.mellship.com/",
        SCAC: "MEXU",
        "SCAC synonyms": "",
    },
    {
        "Carrier Name": "Maritime Marfret",
        "Full Name": "MARFRET COMPAGNIE MARITIME",
        "Carrier Website": "http://marfret.com",
        SCAC: "MFTU",
        "SCAC synonyms": "{MFUS,Maritime-Marfret,MaritimeMarfret}",
    },
    {
        "Carrier Name": "MATSON",
        "Full Name": "MATSON NAVIGATION",
        "Carrier Website": "http://matson.com",
        SCAC: "MATS",
        "SCAC synonyms": "",
    },
    {
        "Carrier Name": "Maxicon Container Line (MCL)",
        "Full Name": "",
        "Carrier Website": "https://www.maxiconline.com/",
        SCAC: "MXCU",
        "SCAC synonyms":
            "{Maxicon-Container-Line,MaxiconContainerLine,MCL,MaxiconContainerLine(MCL)}",
    },
    {
        "Carrier Name": "MCC",
        "Full Name": "MCC Transport Pte. Ltd.",
        "Carrier Website": "",
        SCAC: "MCCQ",
        "SCAC synonyms": "",
    },
    {
        "Carrier Name": "MELL",
        "Full Name": "MARIANA EXPRESS LINES",
        "Carrier Website": "http://mariana-express.com",
        SCAC: "MELX",
        "SCAC synonyms": "{MEXU}",
    },
    {
        "Carrier Name": "MELL",
        "Full Name": "Mariana Express Lines Ltd",
        "Carrier Website": "https://www.mellship.com/",
        SCAC: "MELL",
        "SCAC synonyms": "",
    },
    {
        "Carrier Name": "MERATUS",
        "Full Name": "MERATUS LINE",
        "Carrier Website": "http://meratusline.com",
        SCAC: "MRTU",
        "SCAC synonyms": "",
    },
    {
        "Carrier Name": "MESSINA",
        "Full Name": "MESSINA LINEA",
        "Carrier Website": "http://messinaline.it",
        SCAC: "LMCU",
        "SCAC synonyms": "{LCMU}",
    },
    {
        "Carrier Name": "MILAHA",
        "Full Name": "QATAR NAVIGATION LINES (QNL)",
        "Carrier Website": "http://milahaml.com",
        SCAC: "QNLU",
        "SCAC synonyms": "",
    },
    {
        "Carrier Name": "Minsheng Ocean Shipping",
        "Full Name": "",
        "Carrier Website": "",
        SCAC: "13CQ",
        "SCAC synonyms": "{Minsheng-Ocean-Shipping,MinshengOceanShipping,MOS}",
    },
    {
        "Carrier Name": "MOL",
        "Full Name": "MITSUI OSK LINES",
        "Carrier Website": "http://molpower.com",
        SCAC: "MOLU",
        "SCAC synonyms": "",
    },
    {
        "Carrier Name": "MSC",
        "Full Name": "MEDITERRANEAN SHIPPING COMPANY",
        "Carrier Website": "http://msc.com",
        SCAC: "MSCU",
        "SCAC synonyms":
            '{MEDU,MSCX,MSC,"MSC MEDITTERRANEAN S","MSC MEDITERRANEAN SHIPPING COMPANY",MCSU}',
    },
    {
        "Carrier Name": "Namsung",
        "Full Name": "Namsung Shipping Co., Ltd.",
        "Carrier Website": "",
        SCAC: "NSRU",
        "SCAC synonyms": "",
    },
    {
        "Carrier Name": "NAMSUNG",
        "Full Name": "NAMSUNG SHIPPING - PEGASUS CONTAINER SERVICE",
        "Carrier Website": "http://namsung.co.jp",
        SCAC: "NSSU",
        "SCAC synonyms": "{NSRU}",
    },
    {
        "Carrier Name": "Namsung Shipping",
        "Full Name": "Namsung Shippin",
        "Carrier Website": "http://www.namsung.co.kr/",
        SCAC: "NRSU",
        "SCAC synonyms": "",
    },
    {
        "Carrier Name": "NILE DUTCH",
        "Full Name": "Nile Dutch Africa Line",
        "Carrier Website": "https://www.niledutch.com/",
        SCAC: "NIDU",
        "SCAC synonyms": "",
    },
    {
        "Carrier Name": "NIRINT LINES",
        "Full Name": "NIRINT SHIPPING",
        "Carrier Website": "http://nirint.com",
        SCAC: "NIRU",
        "SCAC synonyms": "{32GH,NIRINT-LINES,NIRINTLINES}",
    },
    {
        "Carrier Name": "Nirint Shipping",
        "Full Name": "Nirint Shipping Lines",
        "Carrier Website": "https://www.nirint.com/",
        SCAC: "32GH",
        "SCAC synonyms": "",
    },
    {
        "Carrier Name": "NPL",
        "Full Name": "NEPTUNE PACIFIC LINE",
        "Carrier Website": "http://neptunepacific.com",
        SCAC: "NPLX",
        "SCAC synonyms": "{NPDL,PDLU}",
    },
    {
        "Carrier Name": "NYK LINE",
        "Full Name": "NIPPON YUSEN KAISHA CONTAINER LINE",
        "Carrier Website": "http://nykline.com",
        SCAC: "NYKS",
        "SCAC synonyms": "{NYKLINE,NYK-LINE}",
    },
    {
        "Carrier Name": "ONE LINE",
        "Full Name": "OCEAN NETWORK EXPRESS (ONE)",
        "Carrier Website": "http://one-line.com",
        SCAC: "ONEY",
        "SCAC synonyms": '{ONE,"OCEAN NETWORK EXPRES",ONE-LINE,ONELINE,"ONE LINE"}',
    },
    {
        "Carrier Name": "OOCL",
        "Full Name": "ORIENT OVERSEAS CONTAINER LINE",
        "Carrier Website": "http://oocl.com",
        SCAC: "OOLU",
        "SCAC synonyms": '{"ORIENT OVERSEAS CONT",OOCL,OOUL,"ORIENT OVERSEAS CONTAINER LINE",OOIL}',
    },
    {
        "Carrier Name": "Orient Star",
        "Full Name": "",
        "Carrier Website": "https://www.orientstargroup.com/en/index.php",
        SCAC: "OSTI",
        "SCAC synonyms": "{Orient-Star,OrientStar}",
    },
    {
        "Carrier Name": "Pan Asia Line",
        "Full Name": "",
        "Carrier Website": "https://www.panasialine.com/",
        SCAC: "PALU",
        "SCAC synonyms": "{Pan-Asia-Line,PanAsiaLine}",
    },
    {
        "Carrier Name": "PANCON",
        "Full Name": "PAN CONTINENTAL SHIPPING LINE",
        "Carrier Website": "http://pancon.co.kr",
        SCAC: "PCLU",
        "SCAC synonyms": "{15AC}",
    },
    {
        "Carrier Name": "Pan Continental Shipping",
        "Full Name": "Pan Continental Shipping",
        "Carrier Website": "http://www.pancon.co.kr/",
        SCAC: "15AC",
        "SCAC synonyms": "",
    },
    {
        "Carrier Name": "Pan Ocean",
        "Full Name": "",
        "Carrier Website": "https://www.panocean.com/en",
        SCAC: "POBU",
        "SCAC synonyms": "{Pan-Ocean,PanOcean}",
    },
    {
        "Carrier Name": "Pasha Hawaii",
        "Full Name": "",
        "Carrier Website": "https://www.pashahawaii.com/",
        SCAC: "PSHI",
        "SCAC synonyms": "{Pasha-Hawaii,PashaHawaii}",
    },
    {
        "Carrier Name": "PCS LINE",
        "Full Name": "DONG YOUNG SHIPPING",
        "Carrier Website": "http://pcsline.co.kr",
        SCAC: "PCSU",
        "SCAC synonyms": "{PCSL,PCS-LINE,PCSLINE}",
    },
    {
        "Carrier Name": "PDL",
        "Full Name": "PACIFIC DIRECT LINE",
        "Carrier Website": "http://pdl123.co.nz",
        SCAC: "PDLU",
        "SCAC synonyms": "",
    },
    {
        "Carrier Name": "Perma Shipping Line",
        "Full Name": "",
        "Carrier Website": "http://perma.sg/",
        SCAC: "PMLU",
        "SCAC synonyms": "{Perma-Shipping-Line,PermaShippingLine}",
    },
    {
        "Carrier Name": "RCL",
        "Full Name": "REGIONAL CONTAINER LINES",
        "Carrier Website": "http://rclgroup.com",
        SCAC: "REGU",
        "SCAC synonyms": "",
    },
    {
        "Carrier Name": "Route Planner",
        "Full Name": "",
        "Carrier Website": "",
        SCAC: "SRRP",
        "SCAC synonyms": "{Route-Planner,RoutePlanner}",
    },
    {
        "Carrier Name": "SAFMARINE",
        "Full Name": "SAFMARINE CONTAINER LINE",
        "Carrier Website": "http://safmarine.com",
        SCAC: "SAFM",
        "SCAC synonyms": '{SAFMARINE,"SAFMARINE CONTAINER"}',
    },
    {
        "Carrier Name": "Salam Pacific Indonesia Lines (SPIL)",
        "Full Name": "",
        "Carrier Website": "https://www.spil.co.id/?lang=en",
        SCAC: "SPNU",
        "SCAC synonyms":
            "{Salam-Pacific-Indonesia-Lines,SalamPacificIndonesiaLines,SalamPacificIndonesiaLines(SPIL),SPIL}",
    },
    {
        "Carrier Name": "SAMUDERA",
        "Full Name": "SAMUDERA SHIPPING LINE",
        "Carrier Website": "http://samudera.com",
        SCAC: "SIKU",
        "SCAC synonyms": "",
    },
    {
        "Carrier Name": "Sarjak Container Lines",
        "Full Name": "",
        "Carrier Website": "https://www.sarjak.com/",
        SCAC: "SJKU",
        "SCAC synonyms": "{Sarjak-Container-Lines,SarjakContainerLines}",
    },
    {
        "Carrier Name": "Sea Hawk Lines (SHAL)",
        "Full Name": "",
        "Carrier Website": "",
        SCAC: "SHKU",
        "SCAC synonyms": "{Sea-Hawk-Lines,SeaHawkLines,SeaHawkLines(SHAL),SHAL}",
    },
    {
        "Carrier Name": "SEALAND AMERICAS",
        "Full Name": "MAERSK SEALAND / SEALAND MAERSK",
        "Carrier Website": "http://sealandmaersk.com",
        SCAC: "SEAU",
        "SCAC synonyms": "{SEALAND-AMERICAS,SEALANDAMERICAS}",
    },
    {
        "Carrier Name": "SEALAND EUROPE",
        "Full Name": "MAERSK SEALAND / SEAGO LINE",
        "Carrier Website": "http://sealandmaersk.com",
        SCAC: "SEJJ",
        "SCAC synonyms": "{SEALAND-EUROPE,SEALANDEUROPE}",
    },
    {
        "Carrier Name": "SeaLead",
        "Full Name": "SeaLead",
        "Carrier Website": "https://www.sea-lead.com/",
        SCAC: "SJHH",
        "SCAC synonyms": "",
    },
    {
        "Carrier Name": "SETH Shipping",
        "Full Name": "",
        "Carrier Website": "https://www.sethshipping.com/",
        SCAC: "SSPH",
        "SCAC synonyms": '{"SETH SHIPPING CORP H",SETHShipping}',
    },
    {
        "Carrier Name": "Shipco Transport",
        "Full Name": "",
        "Carrier Website": "https://www.shipco.com/",
        SCAC: "SHPT",
        "SCAC synonyms": "{Shipco-Transport,ShipcoTransport}",
    },
    {
        "Carrier Name": "Shipping Corporation of India (SCI)",
        "Full Name": "",
        "Carrier Website": "https://www.shipindia.com/",
        SCAC: "SCIU",
        "SCAC synonyms": "{Shipping-Corporation-of-India,ShippingCorporationofIndia,SCI}",
    },
    {
        "Carrier Name": "SHJJ",
        "Full Name": "Jin Jiang Shipping",
        "Carrier Website": "https://www.jjshipping.cn/en",
        SCAC: "11WJ",
        "SCAC synonyms": "",
    },
    {
        "Carrier Name": "SINOKOR MM",
        "Full Name": "SINOKOR MERCHANT MARINE",
        "Carrier Website": "http://sinokor.co.kr",
        SCAC: "SKMC",
        "SCAC synonyms": "{SKLU,SINOKOR-MM,SEALANDMM,SKMC}",
    },
    {
        "Carrier Name": "SINOLINES",
        "Full Name": "SINOLINES - SINOTRANS CONTAINER LINES",
        "Carrier Website": "http://sinolines.com",
        SCAC: "SNBU",
        "SCAC synonyms": "",
    },
    {
        "Carrier Name": "Sinotrans Container Lines",
        "Full Name": "",
        "Carrier Website": "",
        SCAC: "12IH",
        "SCAC synonyms": "{Sinotrans-Container-Lines,SinotransContainerLines}",
    },
    {
        "Carrier Name": "SM LINE",
        "Full Name": "SM LINE (EX. HANJIN)",
        "Carrier Website": "http://smlines.com",
        SCAC: "SMLM",
        "SCAC synonyms": "{SM-LINE,SMLINE}",
    },
    {
        "Carrier Name": "Stolt Tank Containers (STC)",
        "Full Name": "",
        "Carrier Website": "https://www.stolttankcontainers.com/",
        SCAC: "SNTU",
        "SCAC synonyms": "{Stolt-Tank-Containers,StoltTankContainers,STC,StoltTankContainers(STC)}",
    },
    {
        "Carrier Name": "Sunmarine Shipping Services",
        "Full Name": "",
        "Carrier Website": "http://www.sunmarine.com/",
        SCAC: "BAXU",
        "SCAC synonyms": "{Sunmarine-Shipping-Services,SunmarineShippingServices}",
    },
    {
        "Carrier Name": "SWIRE",
        "Full Name": "SWIRE SHIPPING",
        "Carrier Website": "http://swireshipping.com",
        SCAC: "JSSU",
        "SCAC synonyms": "",
    },
    {
        "Carrier Name": "TARROS",
        "Full Name": "TARROS INTERNATIONAL",
        "Carrier Website": "http://tarros.it",
        SCAC: "GETU",
        "SCAC synonyms": "",
    },
    {
        "Carrier Name": "TCLC",
        "Full Name": "TAICANG CONTAINER LINES",
        "Carrier Website": "http://tclcjpagent.com",
        SCAC: "TCGX",
        "SCAC synonyms": "{32GG}",
    },
    {
        "Carrier Name": "TOTE Maritime",
        "Full Name": "TOTE Maritime",
        "Carrier Website": "https://www.totemaritime.com/",
        SCAC: "TOTE",
        "SCAC synonyms": "{TOTE,TOTE-MARITIME,TOTEMARITIME,STRU,SSLH}",
    },
    {
        "Carrier Name": "Trans Asian Shipping Services",
        "Full Name": "",
        "Carrier Website": "https://tassgroup.com/",
        SCAC: "TLXU",
        "SCAC synonyms": "{Trans-Asian-Shipping-Services,TransAsianShippingServices}",
    },
    {
        "Carrier Name": "Transvision Shipping Line",
        "Full Name": "",
        "Carrier Website": "https://www.transvisionshipping.com/",
        SCAC: "TVSU",
        "SCAC synonyms": "{Transvision-Shipping-Line,TransvisionLineShipping}",
    },
    {
        "Carrier Name": "TROPICAL",
        "Full Name": "TROPICAL SHIPPING AND CONSTRUCTION",
        "Carrier Website": "http://tropical.com",
        SCAC: "TSCW",
        "SCAC synonyms": "{TSGL}",
    },
    {
        "Carrier Name": "TS LINES",
        "Full Name": "TS LINES",
        "Carrier Website": "http://tslines.com",
        SCAC: "TSQD",
        "SCAC synonyms": "{TSTU,13DF,TS-LINES,TSLINES}",
    },
    {
        "Carrier Name": "TURKON",
        "Full Name": "TURKON LINE",
        "Carrier Website": "http://turkon.com",
        SCAC: "TRKU",
        "SCAC synonyms": "",
    },
    {
        "Carrier Name": "Vasco Maritime (VAS)",
        "Full Name": "",
        "Carrier Website": "http://vascomaritime.com/",
        SCAC: "VMLU",
        "SCAC synonyms": "{Vasco-Maritime,VascoMaritime,VascoMaritime(VAS),VAS}",
    },
    {
        "Carrier Name": "WAN HAI",
        "Full Name": "WAN HAI LINES (WHL)",
        "Carrier Website": "http://wanhai.com",
        SCAC: "WHLC",
        "SCAC synonyms": '{22AA,"WAN HAI LINES LTD TA",WANHAI,"WAN HAI LINES LTD",WLHC,WHCL}',
    },
    {
        "Carrier Name": "WEC LINES",
        "Full Name": "WEST EUROPEAN CONTAINER LINES",
        "Carrier Website": "http://weclines.com",
        SCAC: "WECU",
        "SCAC synonyms": "{WEC-LINES,WECLINES}",
    },
    {
        "Carrier Name": "WESTWOOD",
        "Full Name": "WESTWOOD SHIPPING LINES (WSL)",
        "Carrier Website": "http://wsl.com",
        SCAC: "WWSU",
        "SCAC synonyms": "",
    },
    {
        "Carrier Name": "WHL",
        "Full Name": "Wan Hai Lines",
        "Carrier Website": "https://www.wanhai.com/",
        SCAC: "22AA",
        "SCAC synonyms": "",
    },
    {
        "Carrier Name": "WLS",
        "Full Name": "World Logistics Service (USA) Inc",
        "Carrier Website": "",
        SCAC: "WDLS",
        "SCAC synonyms": "",
    },
    {
        "Carrier Name": "WWL",
        "Full Name": "Wallenius Wilhelmsen Logistics",
        "Carrier Website": "https://www.walleniuswilhelmsen.com/",
        SCAC: "WLWH",
        "SCAC synonyms": "",
    },
    {
        "Carrier Name": "YANG MING",
        "Full Name": "YANG MING LINE (YML)",
        "Carrier Website": "http://yml.com.tw",
        SCAC: "YMPR",
        "SCAC synonyms":
            '{YMLZ,YML,YMLU,"YANG MING MARINE TRA","YANG MING",YANGMING,YANG-MING,"YANG MING LINE"}',
    },
    {
        "Carrier Name": "Yusen Logistics",
        "Full Name": "",
        "Carrier Website": "https://www.yusen-logistics.com/",
        SCAC: "YASV",
        "SCAC synonyms": "{Yusen-Logistics,YusenLogistics}",
    },
    {
        "Carrier Name": "ZIM",
        "Full Name": "ZIM INTEGRATED SHIPPING SERVICES (ZISS)",
        "Carrier Website": "http://zim.com",
        SCAC: "ZIMU",
        "SCAC synonyms": '{"ZIM CONTAINER SERVIC"}',
    },
];
