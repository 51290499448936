export const trackTraceTypes = {
    FETCH_TRACK_TRACE_CARD_REQUEST: "FETCH_TRACK_TRACE_CARD_REQUEST",
    FETCH_TRACK_TRACE_CARD_SUCCESS: "FETCH_TRACK_TRACE_CARD_SUCCESS",
    FETCH_TRACK_TRACE_CARD_FAILURE: "FETCH_TRACK_TRACE_CARD_FAILURE",
    FETCH_FINISHED_GOODS_REQUEST: "FETCH_FINISHED_GOODS_REQUEST",
    FETCH_FINISHED_GOODS_SUCCESS: "FETCH_FINISHED_GOODS_SUCCESS",
    FETCH_FINISHED_GOODS_FAILURE: "FETCH_FINISHED_GOODS_FAILURE",
    FETCH_RAW_MATERIALS_REQUEST: "FETCH_RAW_MATERIALS_REQUEST",
    FETCH_RAW_MATERIALS_SUCCESS: "FETCH_RAW_MATERIALS_SUCCESS",
    FETCH_RAW_MATERIALS_FAILURE: "FETCH_RAW_MATERIALS_FAILURE",
    FETCH_BATCH_DETAILS_REQUEST: "FETCH_BATCH_DETAILS_REQUEST",
    FETCH_BATCH_DETAILS_SUCCESS: "FETCH_BATCH_DETAILS_SUCCESS",
    FETCH_BATCH_DETAILS_FAILURE: "FETCH_BATCH_DETAILS_FAILURE",
    FETCH_PREVIOUSLY_SEARCHED_REQUEST: "FETCH_PREVIOUSLY_SEARCHED_REQUEST",
    FETCH_PREVIOUSLY_SEARCHED_SUCCESS: "FETCH_PREVIOUSLY_SEARCHED_SUCCESS",
    FETCH_PREVIOUSLY_SEARCHED_FAILURE: "FETCH_PREVIOUSLY_SEARCHED_FAILURE",
    ADD_PREVIOUSLY_SEARCHED_REQUEST: "ADD_PREVIOUSLY_SEARCHED_REQUEST",
    ADD_PREVIOUSLY_SEARCHED_SUCCESS: "ADD_PREVIOUSLY_SEARCHED_SUCCESS",
    ADD_PREVIOUSLY_SEARCHED_FAILURE: "ADD_PREVIOUSLY_SEARCHED_FAILURE",
    FETCH_TRACK_SEARCH_RESULT_REQUEST: "FETCH_TRACK_SEARCH_RESULT_REQUEST",
    FETCH_TRACK_SEARCH_RESULT_SUCCESS: "FETCH_TRACK_SEARCH_RESULT_SUCCESS",
    FETCH_TRACK_SEARCH_RESULT_FAILURE: "FETCH_TRACK_SEARCH_RESULT_FAILURE",
    FETCH_BATCH_INCIDENTS_REQUEST: "FETCH_BATCH_INCIDENTS_REQUEST",
    FETCH_BATCH_INCIDENTS_SUCCESS: "FETCH_BATCH_INCIDENTS_SUCCESS",
    FETCH_BATCH_INCIDENTS_FAILURE: "FETCH_BATCH_INCIDENTS_FAILURE",
    FETCH_BATCH_TRACKING_REQUEST: "FETCH_BATCH_TRACKING_REQUEST",
    FETCH_BATCH_TRACKING_SUCCESS: "FETCH_BATCH_TRACKING_SUCCESS",
    FETCH_BATCH_TRACKING_FAILURE: "FETCH_BATCH_TRACKING_FAILURE",
    FETCH_BATCH_WAREHOUSES_REQUEST: "FETCH_BATCH_WAREHOUSES_REQUEST",
    FETCH_BATCH_WAREHOUSES_SUCCESS: "FETCH_BATCH_WAREHOUSES_SUCCESS",
    FETCH_BATCH_WAREHOUSES_FAILURE: "FETCH_BATCH_WAREHOUSES_FAILURE",
    FETCH_BATCH_CUSTOMERS_REQUEST: "FETCH_BATCH_CUSTOMERS_REQUEST",
    FETCH_BATCH_CUSTOMERS_SUCCESS: "FETCH_BATCH_CUSTOMERS_SUCCESS",
    FETCH_BATCH_CUSTOMERS_FAILURE: "FETCH_BATCH_CUSTOMERS_FAILURE",
    FETCH_BATCH_DISTRIBUTORS_REQUEST: "FETCH_BATCH_DISTRIBUTORS_REQUEST",
    FETCH_BATCH_DISTRIBUTORS_SUCCESS: "FETCH_BATCH_DISTRIBUTORS_SUCCESS",
    FETCH_BATCH_DISTRIBUTORS_FAILURE: "FETCH_BATCH_DISTRIBUTORS_FAILURE",
};
