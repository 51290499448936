import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import Button from "components/common/Button";
import useGetSingleAiTeammate from "hooks/workforceHooks/useGetSingleAiTeammate";
import PageFrame from "components/layout/PageFrame";
import { camelCaseToRegularCase } from "helpers/camelCaseToRegularCase";
import moment from "moment";
import { formatMoney, getFromStorage } from "helpers";
import VendorAvatar from "components/common/VendorAvatar";

const AiTeammateDetails = () => {
    const navigate = useNavigate();
    const { data, isFetching } = useGetSingleAiTeammate();
    const { aiTeammateId } = useParams();
    const profile = getFromStorage("ally-user");
    const currentDay = moment();

    return (
        <PageFrame isLoading={isFetching}>
            <div className="bg-white rounded-lg border border-n-20 relative">
                <div className="flex items-center justify-between px-8 py-5 border-b border-n-20 sticky top-[-20px] bg-white z-[9999]">
                    <div className="flex items-center space-x-1.5">
                        <i
                            onClick={() => navigate(-1)}
                            className="ri-arrow-left-line text-xl mt-[-2px] cursor-pointer max-sm:hidden"
                        ></i>
                        <span className="text-lg font-rocGroteskMedium">
                            {`${data?.teamMateName} - ${camelCaseToRegularCase(data?.agentType)}`}
                        </span>
                    </div>
                    <div className="flex items-center space-x-3">
                        <Button
                            btnText="Cancel"
                            btnClassname="!px-4 !py-2 !bg-tradeally-neutral-20 !w-fit"
                            disabled={false}
                            onClick={() => navigate(-1)}
                        />
                        <Button
                            btnText="Edit AI teammate"
                            onClick={() => {
                                navigate(`/dashboard/workforce/edit-ai-teammate/${aiTeammateId}`);
                            }}
                            type="submit"
                            btnClassname="!px-4 !py-2 !w-fit"
                        />
                    </div>
                </div>

                <div className="py-8 flex justify-center font-rocGroteskMedium">
                    <div className="w-[67%]">
                        <div className="relative w-fit mb-[36px] shadow-[0px_20px_24px_-4px_#10182814]">
                            <img
                                src={data?.avatar}
                                alt="ai ageent avatar"
                                className="w-14 h-14 rounded-lg"
                            />
                        </div>

                        <div className="mb-6">
                            <div className="w-full mb-8 border-b border-b-[#E2E8F0] pb-8">
                                <p className="text-xs mb-[2px] font-rocGroteskMedium">
                                    AI teammate name
                                </p>
                                <p className="text-lg font-rocGroteskMedium">
                                    {`${data?.teamMateName} - ${camelCaseToRegularCase(
                                        data?.agentType
                                    )}`}
                                </p>
                            </div>

                            <div className="space-y-4 mb-8">
                                {data?.createdBy && (
                                    <div>
                                        <p className="text-sm text-slate-500 mb-1 font-rocGroteskMedium">
                                            Created by
                                        </p>
                                        <p className="text-sm flex items-center space-x-2 mb-1 font-rocGroteskMedium">
                                            <VendorAvatar
                                                imageSrc={data?.createdBy?.avatar}
                                                size={24}
                                                name={
                                                    data?.createdBy?.fullname
                                                        ? data?.createdBy?.fullname
                                                        : data?.createdBy?.firstName ||
                                                          data?.createdBy?.lastName
                                                        ? (data?.createdBy?.firstName || "") +
                                                          " " +
                                                          (data?.createdBy?.lastName || "")
                                                        : "N/A"
                                                }
                                            />
                                            <span>
                                                {data?.createdBy?.fullname
                                                    ? data?.createdBy?.fullname
                                                    : data?.createdBy?.firstName ||
                                                      data?.createdBy?.lastName
                                                    ? (data?.createdBy?.firstName || "") +
                                                      " " +
                                                      (data?.createdBy?.lastName || "")
                                                    : "N/A"}
                                            </span>
                                        </p>
                                    </div>
                                )}
                            </div>

                            <div className="border border-slate-200 rounded-md grid grid-cols-3 items-center mb-10">
                                <div className="px-5 py-4 border-r border-slate-200">
                                    <div className="mb-3">
                                        <i className="ri-money-dollar-circle-line text-2xl"></i>
                                    </div>

                                    <div>
                                        <p className="text-xs text-slate-500 mb-1 font-rocGroteskMedium">
                                            Total savings
                                        </p>
                                        <p className="text-sm flex items-center space-x-2 mb-1 font-rocGroteskMedium">
                                            ${formatMoney()?.format(data?.totalCompletedCount * 15)}
                                        </p>
                                    </div>
                                </div>
                                <div className="px-5 py-4 border-r border-slate-200">
                                    <div className="mb-3">
                                        <i className="ri-time-line text-2xl"></i>
                                    </div>

                                    <div>
                                        <p className="text-xs text-slate-500 mb-1 font-rocGroteskMedium">
                                            Time saved
                                        </p>
                                        <p className="text-sm flex items-center space-x-2 mb-1 font-rocGroteskMedium">
                                            {data?.totalCompletedCount} hours
                                        </p>
                                    </div>
                                </div>
                                <div className="px-5 py-4">
                                    <div className="mb-3">
                                        <i className="ri-calendar-line text-2xl"></i>
                                    </div>

                                    <div>
                                        <p className="text-xs text-slate-500 mb-1 font-rocGroteskMedium">
                                            Created on
                                        </p>
                                        <p className="text-sm flex items-center space-x-2 mb-1 font-rocGroteskMedium">
                                            {moment(data?.createdAt)?.format("MMM DD, YYYY")}
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="pt-10 border-t border-slate-200">
                                <h4 className="text-base mb-6">Recent tasks</h4>

                                <div className="space-y-6">
                                    {data?.recentTasks?.map((task) => {
                                        const runningDurationinHrs = moment(
                                            task?.endDate || currentDay
                                        )?.diff(task?.createdAt, "hours");
                                        const runningDurationinMins = moment(
                                            task?.endDate || currentDay
                                        )?.diff(task?.createdAt, "minutes");

                                        return (
                                            <div
                                                key={task?._id}
                                                className="px-5 py-4 rounded-md border border-slate-200 shadow-[0px_4px_8px_-4px_#1018281F]"
                                            >
                                                <div className="flex items-center justify-between mb-1.5">
                                                    <p className="text-sm">
                                                        {camelCaseToRegularCase(task?.taskType)}{" "}
                                                        task for{" "}
                                                        {profile?.user?.profile?.businessName}
                                                    </p>

                                                    <div className="flex items-center space-x-3">
                                                        <span className="text-sm text-slate-500 font-rocGroteskMedium">
                                                            Status
                                                        </span>
                                                        <div className="flex items-center space-x-3">
                                                            {task?.status?.toLowerCase() ===
                                                            "completed" ? (
                                                                <div className="flex w-fit items-center space-x-2">
                                                                    <span className="w-2 h-2 rounded-full bg-[#22C55E]"></span>
                                                                    <span className="text-sm ">
                                                                        {task?.status}
                                                                    </span>
                                                                </div>
                                                            ) : !task?.status
                                                                  ?.toLowerCase()
                                                                  ?.includes("attention") ? (
                                                                <div className="flex w-fit items-center space-x-2">
                                                                    <span
                                                                        className={`w-2 h-2 rounded-full ${
                                                                            task?.status?.toLowerCase() ===
                                                                            "failed"
                                                                                ? "bg-r-55"
                                                                                : "bg-[#EAB308]"
                                                                        }`}
                                                                    ></span>
                                                                    <span className="text-sm ">
                                                                        {task?.status}
                                                                    </span>
                                                                </div>
                                                            ) : null}

                                                            {task?.status
                                                                ?.toLowerCase()
                                                                ?.includes("attention") && (
                                                                <div
                                                                    className={`w-fit pl-1.5 pr-2 py-1 bg-[#FEE2E2] flex items-center space-x-1.5 h-[25px] rounded`}
                                                                >
                                                                    <i
                                                                        className={`ri-information-line text-base text-[#DC2626]`}
                                                                    ></i>
                                                                    <span
                                                                        className={`text-xs text-[#DC2626]`}
                                                                    >
                                                                        User Attention Needed
                                                                    </span>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>

                                                <p className="text-sm text-slate-500 mb-3">
                                                    {task?.state?.order_info?.order_summary}
                                                </p>

                                                <div className="flex items-center justify-between">
                                                    <div>
                                                        <p className="text-sm text-slate-500 mb-1 font-rocGroteskMedium">
                                                            Task Duration
                                                        </p>
                                                        <p className="text-sm flex items-center space-x-2 mb-1 font-rocGroteskMedium">
                                                            {runningDurationinHrs
                                                                ? `${runningDurationinHrs} hour(s)`
                                                                : `${runningDurationinMins} min(s)`}
                                                        </p>
                                                    </div>

                                                    <span
                                                        onClick={() =>
                                                            navigate(`/dashboard/task/${task?._id}`)
                                                        }
                                                        className="px-3 py-1.5 text-sm rounded border border-slate-200 cursor-pointer"
                                                    >
                                                        Show details
                                                    </span>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </PageFrame>
    );
};

export default AiTeammateDetails;
