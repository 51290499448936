import React from "react";
import moment from "moment";
import Loader from "./Loader";

interface SideDrawerProps {
    onClick: () => void;
    teams?: boolean;
    title?: string;
    data?: {[key: string]: any}[];
    isLoading?: boolean;
}

export const SideDrawer = ({onClick, teams, title, data, isLoading}: SideDrawerProps) => {
    return (
        <div className="bg-white py-7 px-7 border border-slate-100 drop-shadow-sm text-white w-[500px] fixed h-full transform top-0 right-0 transition-transform duration-300 ease-in-out z-10">
            <div className=" flex space-x-5 items-center">
                <span
                    className="material-icons border border-slate-200 px-1 rounded-[4px] text-slate-500 text-2xl cursor-pointer font-medium"
                    onClick={onClick}
                >
                    close
                </span>
                <div className="flex items-center space-x-3">
                    {teams && (
                        <div className="h-[30px] flex items-center justify-center w-[30px] rounded-[4px] border">
                            <img
                                src={
                                    "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1691946177/Tradeally_2.0/undefined_-_Imgur_c37dzz.jpg"
                                }
                                width={25}
                                height={25}
                                alt="vendor logo"
                            />
                        </div>
                    )}
                    <p className="font-rocGroteskMedium text-gm-50 text-[14px]">{title}</p>
                </div>
            </div>
            <div className="pt-7 border-b justify-between ">
                <div className={`flex  gap-x-8 px-6`}>
                    <p
                        className={` text-sm font-rocGroteskMedium  pb-2 cursor-pointer 
                                text-slate-700 border-slate-900 border-b-[2px]
                                   
                        `}
                    >
                        Activity log
                    </p>
                </div>
            </div>
            {/* <div className="w-full mt-10 flex items-center space-x-3">
                <div className="whitespace-nowrap">
                    <h6 className="text-slate-500 text-[14px] font-rocGroteskMedium leading-5">
                        Last 7 days
                    </h6>
                </div>
                <div className="border-b border-slate-100 w-full"></div>
            </div> */}
            {isLoading ? (
                <div className="w-full h-full flex items-center justify-center">
                    <Loader size={7} />
                </div>
            ) : (
                <div className="mt-5 h-[calc(100%-121px)] overflow-auto">
                    {data &&
                        data?.length > 0 &&
                        data?.map((activity) => {
                            return (
                                <div
                                    key={activity?._id}
                                    className="flex items-center space-x-2 mb-10"
                                >
                                    <div className="h-[32px] w-[32px] flex items-center justify-center rounded-[4px] bg-n-20">
                                        <img
                                            src={
                                                "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1691946177/Tradeally_2.0/undefined_-_Imgur_c37dzz.jpg"
                                            }
                                            width={20}
                                            height={20}
                                            alt="vendor logo"
                                        />
                                    </div>
                                    <div className="text-[14px] font-rocGroteskMedium">
                                        <p className="text-slate-700">{activity?.activity}</p>
                                        <p className="text-slate-500">
                                            {moment(activity?.createdAt).format("MMM DD, YYYY LT")}
                                        </p>
                                    </div>
                                </div>
                            );
                        })}
                </div>
            )}
        </div>
    );
};
