export const authTypes = {
    REGISTER_USER_REQUEST: "REGISTER_USER_REQUEST",
    REGISTER_USER_SUCCESS: "REGISTER_USER_SUCCESS",
    REGISTER_USER_FAILURE: "REGISTER_USER_FAILURE",
    VERIFY_USER_REQUEST: "VERIFY_USER_REQUEST",
    VERIFY_USER_SUCCESS: "VERIFY_USER_SUCCESS",
    VERIFY_USER_FAILURE: "VERIFY_USER_FAILURE",
    RESEND_VERIFY_CODE_REQUEST: "RESEND_VERIFY_CODE_REQUEST",
    RESEND_VERIFY_CODE_SUCCESS: "RESEND_VERIFY_CODE_SUCCESS",
    RESEND_VERIFY_CODE_FAILURE: "RESEND_VERIFY_CODE_FAILURE",
    USER_LOGIN_REQUEST: "USER_LOGIN_REQUEST",
    USER_LOGIN_SUCCESS: "USER_LOGIN_SUCCESS",
    USER_LOGIN_FAILURE: "USER_LOGIN_FAILURE",
    LOG_OUT: "LOG_OUT",
    FETCH_USER_PROFILE_REQUEST: "FETCH_USER_PROFILE_REQUEST",
    FETCH_USER_PROFILE_SUCCESS: "FETCH_USER_PROFILE_SUCCESS",
    FETCH_USER_PROFILE_FAILURE: "FETCH_USER_PROFILE_FAILURE",
    UPDATE_LDB_PROFILE_REQUEST: "UPDATE_LDB_PROFILE_REQUEST",
    UPDATE_LDB_PROFILE_SUCCESS: "UPDATE_LDB_PROFILE_SUCCESS",
    UPDATE_LDB_PROFILE_FAILURE: "UPDATE_LDB_PROFILE_FAILURE",
    UPDATE_USER_PROFILE_FAILURE: "UPDATE_USER_PROFILE_FAILURE",
    UPDATE_USER_PROFILE_REQUEST: "UPDATE_USER_PROFILE_REQUEST",
    UPDATE_USER_PROFILE_SUCCESS: "UPDATE_USER_PROFILE_SUCCESS",
    FORGOT_PASSWORD_REQUEST: "FORGOT_PASSWORD_REQUEST",
    FORGOT_PASSWORD_SUCCESS: "FORGOT_PASSWORD_SUCCESS",
    FORGOT_PASSWORD_FAILURE: "FORGOT_PASSWORD_FAILURE",
    RESET_PASSWORD_REQUEST: "RESET_PASSWORD_REQUEST",
    RESET_PASSWORD_SUCCESS: "RESET_PASSWORD_SUCCESS",
    RESET_PASSWORD_FAILURE: "RESET_PASSWORD_FAILURE",
    CHANGE_SIGNUP_EMAIL_REQUEST: "CHANGE_SIGNUP_EMAIL_REQUEST",
    CHANGE_SIGNUP_EMAIL_SUCCESS: "CHANGE_SIGNUP_EMAIL_SUCCESS",
    CHANGE_SIGNUP_EMAIL_FAILURE: "CHANGE_SIGNUP_EMAIL_FAILURE",
    ADD_TEAM_MEMBER_REQUEST: "ADD_TEAM_MEMBER_REQUEST",
    ADD_TEAM_MEMBER_SUCCESS: "ADD_TEAM_MEMBER_SUCCESS",
    ADD_TEAM_MEMBER_FAILURE: "ADD_TEAM_MEMBER_FAILURE",
    FETCH_TEAM_MEMBER_REQUEST: "FETCH_TEAM_MEMBER_REQUEST",
    FETCH_TEAM_MEMBER_SUCCESS: "FETCH_TEAM_MEMBER_SUCCESS",
    FETCH_TEAM_MEMBER_FAILURE: "FETCH_TEAM_MEMBER_FAILURE",
    DELETE_TEAM_MEMBER_REQUEST: "DELETE_TEAM_MEMBER_REQUEST",
    DELETE_TEAM_MEMBER_SUCCESS: "DELETE_TEAM_MEMBER_SUCCESS",
    DELETE_TEAM_MEMBER_FAILURE: "DELETE_TEAM_MEMBER_FAILURE",
    UPDATE_TEAM_MEMBER_REQUEST: "UPDATE_TEAM_MEMBER_REQUEST",
    UPDATE_TEAM_MEMBER_SUCCESS: "UPDATE_TEAM_MEMBER_SUCCESS",
    UPDATE_TEAM_MEMBER_FAILURE: "UPDATE_TEAM_MEMBER_FAILURE",
    ADD_VENDOR_REQUEST: "ADD_VENDOR_REQUEST",
    ADD_VENDOR_SUCCESS: "ADD_VENDOR_SUCCESS",
    ADD_VENDOR_FAILURE: "ADD_VENDOR_FAILURE",
    CHANGE_PASSWORD_REQUEST: "CHANGE_PASSWORD_REQUEST",
    CHANGE_PASSWORD_SUCCESS: "CHANGE_PASSWORD_SUCCESS",
    CHANGE_PASSWORD_FAILURE: "CHANGE_PASSWORD_FAILURE",
    DELETE_LDB_PROFILE_REQUEST: "DELETE_LDB_PROFILE_REQUEST",
    DELETE_LDB_PROFILE_SUCCESS: "DELETE_LDB_PROFILE_SUCCESS",
    DELETE_LDB_PROFILE_FAILURE: "DELETE_LDB_PROFILE_FAILURE",
    FETCH_ROLES_REQUEST: "FETCH_ROLES_REQUEST",
    FETCH_ROLES_SUCCESS: "FETCH_ROLES_SUCCESS",
    FETCH_ROLES_FAILURE: "FETCH_ROLES_FAILURE",
    CREATE_ROLES_REQUEST: "CREATE_ROLES_REQUEST",
    CREATE_ROLES_SUCCESS: "CREATE_ROLES_SUCCESS",
    CREATE_ROLES_FAILURE: "CREATE_ROLES_FAILURE",
};
