import React, { useState } from 'react'
import VendorAvatar from "./../../../../common/VendorAvatar"
import CustomTable from "./../../../../common/CustomTable"
import moment from 'moment'
import Button from 'components/common/Button'


const ChartView = () => {

  const currentDate = moment();
    const [tooltipVisible, setTooltipVisible] = useState(false);
      const [tooltipData, setTooltipData] = useState(null);

  const monthArray = [];
  for(let i = 0; i < 7; i++){
const month = currentDate.clone().add(i, 'months');
       const formattedMonth = i === 0 ? 'Current MTD' : month.format('MMM');
    monthArray.push({title: formattedMonth, widthClass: "w-[120px]", titleClass: "!justify-center"})
  }


  const chartToolTip = ((age:number, idx:number) => {
    return(
       <div className="rounded-md bg-white w-[429px] absolute top-4 transition-all z-[1200] border right-[300px] border-slate-200">
        
        <div className="absolute top-[50%] -left-3 border-x-transparent border-b-[8px] border-b-white border-x-[8px] w-[8px] -rotate-90"></div>
        
        <div className="py-2 px-4 flex items-center justify-between">
         <div className="flex items-center gap-2">
                    
                                <div className="w-8 h-8 rounded shrink-0 flex items-center justify-center bg-slate-200 ">
                                    <i className="ri-box-2-fill text-lg text-slate-500"></i>
                                </div>
                           
                        <div className="text-sm">
                        <p className="text-[#142837]"> Kōloa Kaua'i White Rum</p>
                        <p className="text-[#64748B]">KLR-WHT-00{age}{idx}</p>


                        </div>

                    </div>
                    <Button
                    btnText="Create plan"
                    btnClassname="!px-3 !py-1.5 !bg-tradeally-neutral-20 !text-gm-50 !w-fit"
                />
        </div>
        <div className="py-1 px-4 text-sm">
              <div className="flex justify-between items-center border-b border-b-slate-200 py-2">
                <p className="text-slate-500">Production targeted quantity</p>
                <p className="text-[#00BE41]">10,200 bottles</p>
              </div>
              <div className="flex justify-between items-center border-b border-b-slate-200 py-2">
                <p className="text-slate-500">Direct cost</p>
                <p className="text-g-75">$15,200</p>
              </div>
              <div className="flex justify-between items-center border-b border-b-slate-200 py-2">
                <p className="text-slate-500">Indirect cost</p>
                <p className="text-g-75">$100</p>
              </div>
               <div className="flex justify-between items-center border-b border-b-slate-200 py-2">
                <p className="text-slate-500">Total COGS</p>
                <p className="text-g-75">$7,000</p>
              </div>
               <div className="flex justify-between items-center border-b border-b-slate-200 py-2">
                <p className="text-slate-500">Start date</p>
                <p className="text-g-75">August 1, 2024</p>
              </div>
               <div className="flex justify-between items-center border-b border-b-slate-200 py-2">
                <p className="text-slate-500">Completion date</p>
                <p className="text-g-75">August 31, 2024</p>
              </div>
               <div className="flex justify-between items-center border-b border-b-slate-200 py-2">
                <p className="text-slate-500">Batch size</p>
                <p className="text-g-75">10</p>
              </div>
               <div className="flex justify-between items-center border-b border-b-slate-200 py-2">
                <p className="text-slate-500">Production manager</p>
                <p className="text-g-75"> Leilani Alan</p>
              </div>
               <div className="flex justify-between items-center">
                <p className="text-slate-500">Shift</p>
                <p className="text-g-75">Morning (8 AM - 5 PM)</p>
              </div>
        </div>
        
      </div>
    )
  })
    const initTableHeader = [
        { title: "Product", widthClass: "w-[272px] " },
        { title: "Wow trend", widthClass: "w-[131px]",  titleClass: "!justify-center" },
    ];

  const tableHeader = initTableHeader.concat(monthArray)

  const pp = [
    {
    name: "ade",
    age: 10
  },
   {
    name: "adeds",
    age: 30
  },
   {
    name: "ade34",
    age: 40
  },
]
  
    const tableBody = pp?.map((product, idx) => [
       {
            content: (
                <div
                    key={idx}

                    className={`!py-2 !px-2 font-rocGroteskMedium !border-slate-200 text-sm text-gm-50 `}
                >
                     <div className="flex items-center gap-2">
                     
                          {!product ? (
                                <VendorAvatar
                                    // imageSrc={
                                    //     product?.productImageDetails?.productAvatar ||
                                    //     product?.productImageDetails?.productImages?.[0]
                                    // }
                                    size={32}
                                    // name={product?.productName}
                                    containerClassname="!rounded-md"
                                />
                            ) : (
                                <div className="w-8 h-8 rounded shrink-0 flex items-center justify-center bg-slate-200 ">
                                    <i className="ri-box-2-fill text-lg text-slate-500"></i>
                                </div>
                            )}

                        {/* <span>{truncate(item?.inventory?.productName, { length: 20 })}</span> */}
                        <div className="text-sm">
                        <p className="text-[#142837]"> Kōloa Kaua'i White Rum</p>
                        <p className="text-[#64748B]">KLR-WHT-001</p>


                        </div>

                    </div>
                   
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`!py-2 !pl-6 font-rocGroteskMedium border-slate-200 text-sm text-gm-50 `}
                >

                   
                    <div className="flex gap-2 flex-col items-center justify-between">
                  <img src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1727443911/Chart_sa319j.svg" alt="" className="" />


                        <div className=" text-[#DC2626] text-xs">
                        <span className="">-14%</span>
                        <i className="ri-arrow-down-line"> </i>

                    </div>

                    </div>
                   
                </div>
            ),
        },
        {
            content: (
                 <div
          onMouseEnter={() => {
            setTooltipVisible(true);
            setTooltipData(product);
          }}
          onMouseLeave={() => {
            setTooltipVisible(false);
            setTooltipData(null);
          }}
          className={`!py-2 !pl-5 font-rocGroteskMedium border-slate-200 text-sm text-gm-50`}
        >
          {tooltipVisible && tooltipData && chartToolTip(tooltipData.age, idx)}
                  <div className="bg-p-25 rounded-full w-[77px] h-[77px] flex items-center justify-center">
                        <p className="text-p-75 text-sm">{product.age}5</p>

                  </div>
                  
                   
                </div>
            ),
            
            
        },
        {
            content: (
                <div
                    className={`!py-2 !pl-5 font-rocGroteskMedium border-slate-200 text-sm text-gm-50 `}
                >
                  <div className="bg-p-25 rounded-full w-[77px] h-[77px] flex items-center justify-center">
                        <p className="text-p-75 text-sm">75</p>

                  </div>
                   
                   
                </div>
            ),
        }, {
            content: (
                <div
                    className={`!py-2 !pl-5 font-rocGroteskMedium border-slate-200 text-sm text-gm-50 `}
                >
                  <div className="bg-p-25 rounded-full w-[77px] h-[77px] flex items-center justify-center">
                        <p className="text-p-75 text-sm">75</p>

                  </div>
                   
                   
                </div>
            ),
        }, {
            content: (
                <div
                    className={`!py-2 !pl-5 font-rocGroteskMedium border-slate-200 text-sm text-gm-50 `}
                >
                  <div className="bg-p-25 rounded-full w-[77px] h-[77px] flex items-center justify-center">
                        <p className="text-p-75 text-sm">75</p>

                  </div>
                   
                   
                </div>
            ),
        }, {
            content: (
                <div
                    className={`!py-2 !pl-5 font-rocGroteskMedium border-slate-200 text-sm text-gm-50 `}
                >
                  <div className="bg-p-25 rounded-full w-[77px] h-[77px] flex items-center justify-center">
                        <p className="text-p-75 text-sm">75</p>

                  </div>
                   
                   
                </div>
            ),
        }, {
            content: (
                <div
                    className={`!py-2 !pl-5 font-rocGroteskMedium border-slate-200 text-sm text-gm-50 `}
                >
                  <div className="bg-p-25 rounded-full w-[77px] h-[77px] flex items-center justify-center">
                        <p className="text-p-75 text-sm">75</p>

                  </div>
                   
                   
                </div>
            ),
        }, {
            content: (
                <div
                    className={`!py-2 !pl-5 font-rocGroteskMedium border-slate-200 text-sm text-gm-50 `}
                >
                  <div className="bg-p-25 rounded-full w-[77px] h-[77px] flex items-center justify-center">
                        <p className="text-p-75 text-sm">75</p>

                  </div>
                   
                   
                </div>
            ),
        },
    ])
  return (
    <div className="font-rocGroteskMedium">
     
      <CustomTable
        tableBody={tableBody}
        tableHeader={tableHeader}
        isCellBordered={false}
        isInifiniteScroll={true}
        isCellSelectable={false}
        isAllSelectable={false}
        stickyHeader={true}
        headerContainerClass="!bg-[#F8FAFC] !border-slate-200"
        headerItemClass="font-rocGroteskMedium !py-[11px] !px-4 !text-slate-700"
        tableClass="mb-12 !border-collapse"
        isScrollable={true}
        bodyItemClass="hover:bg-transparent"
        />
        
        </div>
  )
}

export default ChartView