import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import { dashboardActions } from "redux/Ldb/actions";

const useGetDashboardProductProfitMargin = (fetchData: boolean) => {
    const dispatch = useAppDispatch();
    const [productProfitMarginData, setProductProfitMarginData] = useState<{ [key: string]: any }>(
        []
    );

    const {
        fetchingProductProfitMargin,
        fetchedProductProfitMarginSuccess,
        fetchedProductProfitMarginFailure,
    } = useAppSelector((state) => state.dashboard);

    useEffect(() => {
        if (Boolean(fetchedProductProfitMarginSuccess)) {
            setProductProfitMarginData(fetchedProductProfitMarginSuccess?.products);
        }
    }, [fetchedProductProfitMarginSuccess]);

    useEffect(() => {
        if (fetchData) {
            dispatch(dashboardActions?.getProductsMarginProfit());
        }
    }, [fetchData]);

    return {
        data: productProfitMarginData,
        isFetching: fetchingProductProfitMargin,
        error: fetchedProductProfitMarginFailure,
    };
};

export default useGetDashboardProductProfitMargin;
