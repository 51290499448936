import config from "config/config";
import { authService } from "./authService";
import { createUrlSearchParams } from "helpers";

export const trackTraceService = {
    getTrackTraceCard,
    getFinishedGoods,
    getRawMaterials,
    getBatchDetails,
    getPreviouslySearched,
    addPreviouslySearched,
    getBatchIncidents,
    getBatchTracking,
    getBatchWarehouses,
    getBatchCustomers,
    getBatchDistributors,
};

async function getTrackTraceCard(channel?: string) {
    const requestOptions = {
        method: "GET",
    };
    const urlParams = createUrlSearchParams({
        channel: channel as string,
    });

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/inventory/track-trace/cards?${
            Boolean(urlParams?.toString()) ? urlParams?.toString() : ""
        }`,
        requestOptions
    );
    return res;
}

async function getFinishedGoods(
    limit?: number,
    page?: number,
    search?: string,
    warehouseId?: string,
    dateRange?: string,
    sortParams?: { [key: string]: number }
) {
    const requestOptions = {
        method: "GET",
    };

    const urlParams = createUrlSearchParams({
        page,
        search: search as string,
        warehouseId: warehouseId as string,
        dateRange: dateRange as string,
    });

    const key = typeof sortParams === "object" && sortParams !== null && Object.keys(sortParams)[0];
    const value = key ? sortParams[key] : null;
    const sortParamsFormat = key && value !== undefined ? `${key}=${value}` : "";

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/inventory/traceable/product/finished-goods?limit=${limit}${
            Boolean(urlParams?.toString()) ? "&" + urlParams?.toString() : ""
        }${key ? "&" + sortParamsFormat?.toString() : ""}`,
        requestOptions
    );
    return res;
}

async function getRawMaterials(
    limit?: number,
    search?: string,
    warehouseId?: string,
    dateRange?: string,
    sortParams?: { [key: string]: number }
) {
    const requestOptions = {
        method: "GET",
    };
    const urlParams = createUrlSearchParams({
        search: search as string,
        warehouseId: warehouseId as string,
        dateRange: dateRange as string,
    });

  const key = typeof sortParams === "object" && sortParams !== null && Object.keys(sortParams)[0];
const value = key ? sortParams[key] : null;
  const sortParamsFormat = key && value !== undefined ? `${key}=${value}` : "";
    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/inventory/traceable/product/raw-materials?limit=${limit}${
            Boolean(urlParams?.toString()) ? "&" + urlParams?.toString() : ""
        }${key ? "&" + sortParamsFormat?.toString() : ""}`,
        requestOptions
    );
    return res;
}

async function getBatchDetails(id?: string) {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/inventory/batch/details/${id}`,
        requestOptions
    );
    return res;
}

async function getPreviouslySearched() {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/inventory/get/recent/inventory/movement/search`,
        requestOptions
    );
    return res;
}
async function addPreviouslySearched(data: Record<string, any> | Array<Record<string, any>>) {
    const requestOptions = {
        method: "POST",
        body: JSON.stringify(data),
    };
    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/inventory/add/recent/inventory/movement/search`,
        requestOptions
    );
    return res;
}

async function getBatchIncidents(batchId: string, channel?: string, dateRange?: string) {
    const requestOptions = {
        method: "GET",
    };
    const urlParams = createUrlSearchParams({
        channel: channel as string,
        dateRange: dateRange as string,
    });

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/inventory/batch/incidents/${batchId}?${
            Boolean(urlParams?.toString()) ? "&" + urlParams?.toString() : ""
        }`,
        requestOptions
    );
    return res;
}
async function getBatchTracking(batchId?: string, dateRange?: string) {
    const requestOptions = {
        method: "GET",
    };

    const urlParams = createUrlSearchParams({
        dateRange: dateRange as string,
    });
    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/inventory/batch/tracking-detail/${batchId}?${
            Boolean(urlParams?.toString()) ? "&" + urlParams?.toString() : ""
        }`,
        requestOptions
    );
    return res;
}

async function getBatchWarehouses(state?: string, batchId?: string, limit?: number) {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/inventory/batch/warehouse/${state}/${batchId}?limit=${limit}`,
        requestOptions
    );
    return res;
}

async function getBatchCustomers(state?: string, batchId?: string, limit?: number) {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/inventory/batch/customers/${state}/${batchId}?limit=${limit}`,
        requestOptions
    );
    return res;
}
async function getBatchDistributors(state?: string, batchId?: string, limit?: number) {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/inventory/batch/sales/${state}/${batchId}?limit=${limit}`,
        requestOptions
    );
    return res;
}
