import React from "react";

const CustomMobileTable = ({ data }) => {
    return (
        <div className="flex flex-col gap-2">
            {data?.map((item, idx) => {
                return (
                    <div
                        key={item?.topString + idx}
                        onClick={(e) => item?.cellClickAction?.(e)}
                        className="flex gap-2 bg-white shadow-boxShadow-2 items-center justify-between px-4 py-[14px] rounded border border-slate-200"
                    >
                        <div className="flex gap-3 items-center">
                            {item?.avatar && <div>{item?.avatar}</div>}
                            <div>
                                {item?.topContent}
                                {item?.bottomContent}
                            </div>
                        </div>
                        {item?.rightIcon && <div className="flex items-end">{item?.rightIcon}</div>}
                    </div>
                );
            })}
        </div>
    );
};

export default CustomMobileTable;
