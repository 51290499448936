import React, { useState } from "react";
import PageTitleSection from "components/common/PageTitleSection";
import TabsContainer from "components/common/TabsContainer";
import Button from "components/common/Button";
import WorkforceAnalyticsCard from "components/common/WorkforceAnalyticsCard";
import TextInput from "components/common/InputField/TextInput";
import AiTeammateCard from "components/common/AiTeammateCard";
import { useNavigate } from "react-router-dom";
import useGetAiTeammates from "hooks/workforceHooks/useGetAiTeammates";
import Loader from "components/common/Loader";
import { camelCaseToRegularCase } from "helpers/camelCaseToRegularCase";
import moment from "moment";
import PageFrame from "components/layout/PageFrame";
import { formatMoney } from "helpers";

const Workforce = () => {
    const tabs = ["AI Teammate"];
    const [activeTab, setActiveTab] = useState<number>(0);

    const navigate = useNavigate();
    const {
        isFetching: fetchingAiTeammates,
        data: aiTeammates,
        pagination,
        isLoadingMore,
        search,
        handleSearch,
        handleDebouncedChange,
    } = useGetAiTeammates();

    const displayActiveTab = () => {
        switch (activeTab) {
            case 0:
                return (
                    <div>
                        {aiTeammates?.length > 0 ? (
                            <>
                                <div className="grid grid-cols-4 gap-4">
                                    {aiTeammates?.map((teammate, idx) => {
                                        return (
                                            <AiTeammateCard
                                                key={teammate?._id}
                                                _id={teammate?._id}
                                                index={idx}
                                                name={teammate?.teamMateName}
                                                role={camelCaseToRegularCase(teammate?.agentType)}
                                                createdOn={moment(teammate?.createdAt)?.format(
                                                    "MMMM DD, YYYY"
                                                )}
                                                avatarUrl={teammate?.avatar}
                                                onGoingTaskCount={teammate?.inprogressCount}
                                                totalSavings={`$${formatMoney()?.format(
                                                    teammate?.totalCompletedCount * 15
                                                )}`}
                                                timeSaved={`${teammate?.totalCompletedCount} hour(s)`}
                                            />
                                        );
                                    })}
                                </div>
                                {isLoadingMore && (
                                    <div className="flex my-4 justify-center">
                                        <Loader color="gm-25" size={4} />
                                    </div>
                                )}
                                {pagination?.currentPage === pagination?.noOfPages && (
                                    <div className="flex my-4 text-slate-500  justify-center text-sm font-rocGroteskMedium">
                                        End of list
                                    </div>
                                )}
                            </>
                        ) : (
                            <div className="flex justify-center mt-[68px]">
                                <div className="w-[42%] border border-slate-200 font-rocGroteskMedium rounded py-8 px-6 flex flex-col items-center justify-center text-center">
                                    <i className="ri-robot-line text-[40px] mb-3"></i>
                                    <span className="block text-base mb-[6px]">
                                        No AI Teammates Yet
                                    </span>
                                    <span className="block text-sm text-slate-500">
                                        You haven't created any AI teammates to help optimize your
                                        business operations. Start building your team now to unlock
                                        efficiency and savings.
                                    </span>
                                </div>
                            </div>
                        )}
                    </div>
                );

            default:
                break;
        }
    };

    return (
        <PageFrame isLoading={fetchingAiTeammates && aiTeammates?.length === 0}>
            <div className="p-4 border border-slate-100 rounded-[8px] bg-white">
                <div className="mb-6">
                    <PageTitleSection
                        leftComp={
                            <div className="flex items-center space-x-3">
                                <h3 className="text-xl font-rocGroteskMedium">WorkforceIQ</h3>
                            </div>
                        }
                    />
                </div>

                {/* <div className="grid grid-cols-3 items-center space-x-3 w-full mb-8">
                    <WorkforceAnalyticsCard title="All teammates" value="-" />
                    <WorkforceAnalyticsCard title="Total cost saved by your AI Team" value="-" />
                    <WorkforceAnalyticsCard title="AI teammate" value="-" />
                </div> */}

                <div>
                    <TabsContainer
                        tabs={tabs}
                        activeTab={activeTab}
                        setActiveTab={(idx) => {
                            setActiveTab(idx);
                        }}
                        component={displayActiveTab()}
                        className="!px-0"
                        showButtonClassName
                        itemClassName="!pb-2 max-lg:whitespace-nowrap"
                        borderLineClase={"!text-slate-100 w-fit max-lg:w-full mb-6"}
                        tabRowClassname={""}
                        showButton={
                            <div className="flex items-center space-x-2 mb-6 max-sm:grid max-sm:space-x-0 max-sm:space-y-2 max-sm:grid-cols-1">
                                <TextInput
                                    name={"search"}
                                    value={search}
                                    type={"text"}
                                    inputPlaceholder={"Search AI Teammate"}
                                    inputClassName={"!h-[38px] pl-[0px] !bg-white text-sm !mb-0"}
                                    onChange={handleSearch}
                                    onInput={handleDebouncedChange}
                                    containerClassname={"!w-[400px] max-sm:!w-full"}
                                    leftIcon={
                                        <i className="ri-search-line before:content-['\f0d1] text-slate-400 mt-[2px]"></i>
                                    }
                                    inputContainerClassname={
                                        "!rounded-[4px] max-sm:!w-full !pl-[40px] !h-[40px] !bg-white !border !border-slate-200"
                                    }
                                />

                                <Button
                                    btnText="Settings"
                                    btnClassname="!px-4 !py-2 !w-fit max-sm:!w-full !h-10 !bg-transparent !border !border-slate-200 !text-[13px] !whitespace-nowrap"
                                    icon={<i className="ri-settings-2-line text-base"></i>}
                                    onClick={() => {}}
                                />

                                <Button
                                    btnText="Create AI Teammate"
                                    btnClassname="!px-4 !py-2 !w-fit max-sm:!w-full !h-10 !text-[13px] !whitespace-nowrap"
                                    onClick={() => {
                                        navigate(`/dashboard/workforce/create-ai-teammate`);
                                    }}
                                />
                            </div>
                        }
                    />
                </div>
            </div>
        </PageFrame>
    );
};

export default Workforce;
