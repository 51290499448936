import Button from "components/common/Button";
import CustomTable from "components/common/CustomTable";
import CustomTableLoader from "components/common/CustomTableLoader";
import SelectInput from "components/common/InputField/SelectInput";
import TextInput from "components/common/InputField/TextInput";
import Loader from "components/common/Loader";
import VendorAvatar from "components/common/VendorAvatar";
import { getCurrencyFromCurrencyCode } from "helpers";
import useGetProductionPlanList from "hooks/ProductionHooks/useGetProductionPlanList";
import useGetProductionPlanTab from "hooks/ProductionHooks/useGetProductionPlanTab";
import { truncate } from "lodash";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";

type bomTypes = {
    bomDetails: any;
    productName: string;
    isLoading: boolean;
    debouncedSearch: string;
    handleDebouncedSearch: (e: any) => void;
    search: string;
    handleSearch: (e: React.ChangeEvent<HTMLInputElement>) => void;
    isLoadingMore: boolean;
    pagination: {
        current: string;
        number_of_pages: string;
    };
};
const BOM = ({
    bomDetails,
    productName,
    isLoading,
    debouncedSearch,
    handleDebouncedSearch,
    search,
    handleSearch,
    isLoadingMore,
    pagination,
}: bomTypes) => {
    const navigate = useNavigate();
    const [param] = useSearchParams();
    const { productionId } = useParams();
    const [shortages, setShortages] = useState<{ [key: string]: any }[]>([]);
    const [BOMList, setBOMList] = useState<{ [key: string]: any }[]>([]);
    useEffect(() => {
        setBOMList(bomDetails?.billOfMaterialIds);
    }, [bomDetails]);

    useEffect(() => {
        const getLowStockProd = BOMList?.filter((item) => {
            return item?.inventory?.productStockDetails?.stockLevel <= 0;
        })?.map((item) => {
            return {
                unitCostAmount: item?.inventory?.unitCost?.amount,
                unitCostCurrency: item?.inventory?.unitCost?.currency,
                sku: item?.inventory?.sku,
                productName: item?.inventory?.productName,
                inventoryId: item._id,
                quantity: "",
                unitCost: {
                    amount: "",
                    currency: "",
                },
                subtotal: {
                    amount: "",
                },
                deliveryDate: "",
                leadTime: "",
            };
        });

        setShortages(getLowStockProd);
    }, [BOMList]);

    const BOMTableHeader = [
        {
            title: "Product",
            widthClass: "w-[18%]  !border !bg-slate-50 !border-slate-200",
        },
        {
            title: "Description",
            widthClass: "w-[18%] !border !bg-slate-50 !border-slate-200",
        },
        {
            title: "Current Stock Level",
            widthClass: "w-[12%] !border !bg-slate-50 !border-slate-200",
        },
        {
            title: "Raw materials needed",
            widthClass: "w-[10%] !border !bg-slate-50 !border-slate-200",
        },
        {
            title: "Scrap",
            widthClass: "w-[8%] !border !bg-slate-50 !border-slate-200",
        },
        {
            title: "UOM",
            widthClass: "w-[7%] !border !bg-slate-50 !border-slate-200",
        },
        {
            title: "Unit price",
            widthClass: "w-[10%] !border !bg-slate-50 !border-slate-200",
        },
        {
            title: "Total price",
            widthClass: "w-[10%] !border !bg-slate-50 !border-slate-200",
        },
        {
            title: "Dependencies",
            widthClass: "w-[20%] !border !bg-slate-50 !border-slate-200",
        },
        {
            title: "Action",
            widthClass: "w-[15%] !border !bg-slate-50 !border-slate-200",
        },
    ];

    const BOMTableBody = BOMList?.map((plan, idx) => {
        const totalAmount =
            ((plan?.quantity * plan?.scrap) / 100 + plan?.quantity) *
            plan?.inventory?.unitCost?.amount;
        return [
            {
                content: (
                    <div key={idx} className={`w-full  `}>
                        <div className="flex items-center gap-2 py-2 px-2.5">
                            {plan?.inventory?.productImageDetails?.productAvatar ||
                            plan?.inventory?.productImageDetails?.productImages?.[0] ? (
                                <VendorAvatar
                                    imageSrc={
                                        plan?.inventory?.productImageDetails?.productAvatar ||
                                        plan?.inventory?.productImageDetails?.productImages?.[0]
                                    }
                                    size={32}
                                    name={plan?.productName}
                                    containerClassname="!rounded-md"
                                />
                            ) : (
                                <div className="w-8 h-8 rounded shrink-0 flex items-center justify-center bg-slate-200 ">
                                    <i className="ri-box-2-fill text-lg text-slate-500"></i>
                                </div>
                            )}

                            <div className="text-sm">
                                <p className="text-g-75">
                                    {truncate(plan?.inventory?.productName, { length: 20 })}
                                </p>
                                <p className="text-[#64748B]">{plan?.inventory?.sku}</p>
                            </div>
                        </div>
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                widthClass: "!border-slate-200",
            },
            {
                content: (
                    <div className={`w-full px-2.5 py-2`}>
                        <p className="text-slate-950 text-sm">
                            {truncate(plan?.instructions, { length: 30 })}
                        </p>
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                widthClass: "!border-slate-200",
            },

            {
                content: (
                    <div className={`w-full px-2.5 py-2`}>
                        <p
                            className={` text-sm ${
                                plan?.inventory?.productStockDetails?.stockLevel < 1
                                    ? "text-[#E4281D]"
                                    : "text-slate-950"
                            }`}
                        >
                            {plan?.inventory?.productStockDetails?.stockLevel}
                            {` `}
                            {plan?.inventory?.productStockDetails?.stockLevel !== null &&
                                plan?.inventory?.productStockDetails?.unitOfMeasurement}
                            {` `}
                            {plan?.inventory?.productStockDetails?.stockLevel < 1 &&
                                "Insufficient material"}
                        </p>
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                widthClass: "!border-slate-200",
            },
            {
                content: (
                    <div className={`w-full px-2.5 py-2`}>
                        <p className="text-slate-950 text-sm">{plan?.quantity}</p>
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                widthClass: "!border-slate-200",
            },
            {
                content: (
                    <div className={`w-full px-2.5 py-2`}>
                        <p className="text-slate-950 text-sm">
                            {plan?.scrap} {plan?.scrap && "%"}
                        </p>
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                widthClass: "!border-slate-200",
            },
            {
                content: (
                    <div className={`w-full px-2.5 py-2`}>
                        <p className="text-slate-950 text-sm">
                            {plan?.inventory?.productStockDetails?.unitOfMeasurement}
                        </p>
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                widthClass: "!border-slate-200",
            },

            {
                content: (
                    <div className={`w-full px-2.5 py-2`}>
                        <p className="text-slate-950 text-sm">
                            {plan?.inventory?.unitCost?.amount !== null
                                ? getCurrencyFromCurrencyCode(plan?.inventory?.unitCost?.currency)
                                : ""}
                            {plan?.inventory?.unitCost?.amount}
                        </p>
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                widthClass: "!border-slate-200",
            },
            {
                content: (
                    <div className={`w-full px-2.5 py-2`}>
                        <p className="text-slate-950 text-sm">
                            {plan?.inventory?.unitCost?.amount !== null
                                ? getCurrencyFromCurrencyCode(plan?.inventory?.unitCost?.currency)
                                : ""}
                            {totalAmount}
                        </p>
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                widthClass: "!border-slate-200",
            },
            {
                content: (
                    <div
                        className={`w-full px-2.5 text-sm py-2 flex flex-wrap gap-2 ${
                            plan?.linkedPurchaseOrders?.length === 0 &&
                            plan?.inventory?.productStockDetails?.stockLevel < 1 &&
                            "text-[#CE9606]"
                        }`}
                    >
                        {plan?.linkedPurchaseOrders?.length >= 1
                            ? plan?.linkedPurchaseOrders?.map((po) => {
                                  return (
                                      <Button
                                          btnText={`PO-${po?.taId}`}
                                          btnClassname="!px-4 !bg-transparent !py-2 !text-gm-50 !w-fit text-[13px] !border !border-slate-200 !shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)]"
                                          onClick={() =>
                                              navigate(`/dashboard/purchase/shipment/${po?._id}`)
                                          }
                                          icon=<i className="ri-file-line"></i>
                                          rightIcon=<i className="ri-arrow-right-line"></i>
                                      />
                                  );
                              })
                            : plan?.inventory?.productStockDetails?.stockLevel < 1
                            ? "Create PO for material shortage"
                            : "None"}
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                widthClass: "!border-slate-200",
            },
            {
                content: (
                    <div className={`w-full px-2.5 py-2`}>
                        <p className="text-slate-950 text-sm">
                            {plan?.linkedPurchaseOrders?.length >= 1 ? (
                                "PO Created"
                            ) : (
                                <Button
                                    btnText="Create PO"
                                    btnClassname="!px-4 !bg-transparent !py-2 !text-g-60 !w-fit text-[13px] !border !border-slate-200 !shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)]"
                                    // disabled={}
                                    onClick={() =>
                                        navigate(
                                            `/dashboard/purchase/create-purchase-request?planId=${productionId}&inventoryId=${plan?.inventoryId}`
                                        )
                                    }
                                    icon=<i className="ri-file-list-3-line"></i>
                                />
                            )}
                        </p>
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                widthClass: "!border-slate-200",
            },
        ];
    });

    return (
        <div className="mt-6 font-rocGroteskMedium">
            <div className="flex items-center justify-between p-4 border-x border-t  border-slate-200 rounded-t-md">
                <p className="text-gm-75 text-base ">
                    Bill of materials (BOM) {productName && `for ${productName}`}
                </p>
                <div className="flex gap-2 ">
                    <TextInput
                        name="search"
                        type="text"
                        value={search}
                        onChange={(e) => handleSearch(e)}
                        onInput={handleDebouncedSearch}
                        inputPlaceholder="Search BOM"
                        inputClassName={
                            "!h-[40px] pl-[0px] !mb-0 !font-rocGroteskMedium !text-slate-400"
                        }
                        leftIcon={
                            <i className="ri-search-line before:content-['\f0d1] text-slate-200 mt-[2px]"></i>
                        }
                        inputContainerClassname={
                            "!rounded-[4px]  !h-[40px] !pl-[20px] !bg-white !border !border-slate-200 !shadow-boxShadow !w-[300px]"
                        }
                    />

                    <Button
                        btnText={"Create POs for all shortages"}
                        type={"button"}
                        btnClassname="!py-2 !px-4 !text-black !w-fit !whitespace-nowrap max-sm:w-full"
                        disabled={shortages?.length < 1}
                        onClick={() =>
                            navigate(
                                `/dashboard/purchase/create-purchase-request?planId=${productionId}`,
                                {
                                    state: { shortages },
                                }
                            )
                        }
                    />
                    {/* <Button
                        btnText={"Create a new raw material"}
                        type={"button"}
                        btnClassname="!text-[13px] !bg-n-20 !text-gm-50 !py-2 !px-4 !w-fit !whitespace-nowrap"
                        onClick={() => navigate("")}
                    />
                    <Button
                        btnText={"Add raw material from Inventory"}
                        type={"button"}
                        btnClassname="!py-2 !px-4 !text-black !w-fit !whitespace-nowrap max-sm:w-full"
                        onClick={() => navigate("/dashboard/production/create-plan")}
                    /> */}
                </div>
            </div>

            {isLoading && BOMList?.length === 0 ? (
                <div className="overflow-x-hidden">
                    <CustomTableLoader tableHeader={BOMTableHeader} />
                </div>
            ) : BOMList?.length >= 1 ? (
                <>
                    <CustomTable
                        tableBody={BOMTableBody}
                        tableHeader={BOMTableHeader}
                        isScrollable={false}
                        isCellBordered={true}
                        isCellSelectable={false}
                        isAllSelectable={false}
                        scrollWithin={false}
                        headerContainerClass=""
                        headerItemClass="font-rocGroteskMedium !text-[#334155] "
                        tbodyClass="!border !border-slate-200"
                        tableClass="!border-slate-200"
                        bodyItemClass="hover:!bg-transparent !cursor-default !border-slate-200"
                    />

                    {isLoadingMore && (
                        <div className="flex justify-center my-4">
                            <Loader color="gm-25" size={4} />
                        </div>
                    )}

                    {pagination?.current === pagination?.number_of_pages && (
                        <div className="flex my-4 justify-center text-slate-500 text-sm font-rocGroteskMedium">
                            End of list
                        </div>
                    )}
                </>
            ) : (
                <>
                    {debouncedSearch && BOMList?.length === 0 ? (
                        <div className="h-[200px] flex items-center justify-center border-x border-b border-slate-200">
                            <p className="text-slate-500 font-rocGroteskMedium text-[16px]">
                                Sorry, no results match your search. Refine your criteria and try
                                again.
                            </p>
                        </div>
                    ) : (
                        ""
                    )}
                </>
            )}
        </div>
    );
};

export default BOM;
