import React, { useEffect, useState } from "react";
import Button from "components/common/Button";
import SelectInput from "components/common/InputField/SelectInput";
import TextInput from "components/common/InputField/TextInput";
import ModalContainer from "components/common/ModalContainer";
import { getFromStorage } from "helpers";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { authActions } from "redux/Ldb/actions";
import { accessTypes } from "variables";
import { toast } from "react-hot-toast";
import CustomToast from "components/common/CustomToast";
import { debounce } from "lodash";
import { useAppDispatch, useAppSelector } from "../../../../hooks/storeHooks";

type AddTeamMembersProps = {
    teamInfo: { [key: string]: any }[];
    setTeamInfo: React.Dispatch<React.SetStateAction<{ [key: string]: any }[]>>;
    activeStep?: number;
    setActiveStep: React.Dispatch<React.SetStateAction<number>>;
    setShowRolesAndResponsibiltyModal: React.Dispatch<React.SetStateAction<boolean>>;
};

const AddTeamMembers = ({
    teamInfo,
    setTeamInfo,
    activeStep,
    setActiveStep,
    setShowRolesAndResponsibiltyModal,
}: AddTeamMembersProps) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [addCustomRole, setAddCustomRole] = useState(false);
    const [selectedAccess, setSelectedAccess] = useState([]);
    const [profile, setProfile] = useState(() => getFromStorage("ally-user"));
    const [numDivs, setNumDivs] = useState(1);
    const [selectedPermissions, setSelectedPermissions] = useState<string[]>([]);
    const [roles, setRoles] = useState<{ [key: string]: string }[]>([]);
    const [role, setRole] = useState("");
    const [debouncedSearch, setDebouncedSearch] = useState({
        search: "",
    });
    const {
        addingTeamMember,
        addTeamMemberSuccess,
        fetchingRoles,
        fetchedRolesSuccess,
        createRoles,
        createRolesSuccess,
    } = useAppSelector((state) => state.auth);

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const body: { [key: string]: any }[] = [];
        const filteredTeamInfo = teamInfo.filter(
            (member, index) => member[`email-${index}`] && member[`role-${index}`]
        );

        filteredTeamInfo.map((member, index) => {
            const teamMember = {
                email: member[`email-${index}`],
                role: member[`role-${index}`],
            };

            body.push(teamMember);
        });

        const teamsBody = { teamMembers: body };

        dispatch(authActions.addTeamMember(teamsBody, profile?.token));
    };
    const onDelete = (index: number) => {
        const updatedTeamInfo = [...teamInfo];
        updatedTeamInfo.splice(index, 1);
        setTeamInfo(updatedTeamInfo);
    };

    const handleChange = (
        e: React.ChangeEvent<HTMLInputElement> | { target: { name: string; value: string } },
        index: number
    ) => {
        const { name, value } = e.target;
        const updatedTeamInfo = [...teamInfo];
        updatedTeamInfo[index] = {
            ...updatedTeamInfo[index],
            [name]: value,
        };
        setTeamInfo(updatedTeamInfo);
    };

    const addNewInputField = () => {
        const newInputField = {
            email: "",
            role: "",
            permissions: "",
        };
        setTeamInfo([...teamInfo, newInputField]);
    };
    useEffect(() => {
        dispatch(authActions.getRoles(20, profile?.token, debouncedSearch.search));
    }, [dispatch, debouncedSearch]);

    useEffect(() => {
        if (Boolean(fetchedRolesSuccess)) {
            setRoles(fetchedRolesSuccess?.rolesList);
        }
    }, [fetchedRolesSuccess]);

    const handleAddRole = () => {
        // e.preventDefault();
        const permission = selectedPermissions;
        if (role === "") {
            toast.custom((t) => (
                <CustomToast t={t} message={"Role name is required"} type="error" />
            ));
        }
        if (role !== "") {
            const teamMember = {
                role,
                ...(permission.length > 0 && { permission }),
            };
            dispatch(authActions.createRoles(teamMember, profile?.token));
        }
    };

    useEffect(() => {
        if (Boolean(createRolesSuccess)) {
            setSelectedPermissions([]);
            setAddCustomRole(false);
            dispatch(authActions.resetCreateRoles());
            dispatch(authActions.getRoles(20, profile?.token));
        }
    }, [createRolesSuccess, dispatch]);

    const handlePermissionChange = (permission: string) => {
        const isSelected = selectedPermissions.includes(permission);

        if (isSelected) {
            const updatedPermissions = selectedPermissions.filter((item) => item !== permission);
            setSelectedPermissions(updatedPermissions);
        } else {
            setSelectedPermissions([...selectedPermissions, permission]);
        }
    };

    useEffect(() => {
        if (Boolean(addTeamMemberSuccess)) {
            dispatch(authActions.resetAddTeamMemberSuccess());
            // navigate("/auth-steps", {replace: true});
            navigate("/dashboard/inventory", {replace: true});
        }
    }, [dispatch, addTeamMemberSuccess, activeStep, navigate, setActiveStep]);

    return (
        <div className="w-full flex justify-center">
            <div className="lg:w-[500px] w-[380px]">
                <div className="mb-3">
                    <p
                        className="flex items-center gap-3 cursor-pointer"
                        onClick={() => setActiveStep(1)}
                    >
                        <span className="material-icons text-base">arrow_back</span>
                        <span className="text-sm text-gm-45 font-rocGroteskMedium">Back</span>
                    </p>
                </div>
                <div className="w-full mb-2 flex items-start justify-between">
                    <div>
                        <p className="text-xl mb-0.5 font-rocGroteskMedium text-g-75">
                            Add team members to collaborate with
                        </p>
                        <p className="text-sm font-rocGroteskMedium text-gm-40">
                            Add team members to your workspace
                        </p>
                        <div
                            className="cursor-pointer w-fit my-3"
                            onClick={() => setShowRolesAndResponsibiltyModal(true)}
                        >
                            <p className="text-b-55  text-xs font-rocGroteskMedium  underline-offset-1 underline">
                                View roles and responsibilities
                            </p>
                        </div>
                    </div>
                </div>
                <form onSubmit={handleSubmit} className="w-full">
                    <div className="flex flex-col gap-4 w-full">
                        {teamInfo.map((inputData, index: number) => (
                            <div key={index} className="flex items-center group">
                                <div className="w-[80%]">
                                    <TextInput
                                        value={inputData[`email-${index}`]}
                                        name={`email-${index}`}
                                        type="email"
                                        onChange={(e) => handleChange(e, index)}
                                        placeholder="Enter email address"
                                        required={true}
                                        inputContainerClassname="!rounded-tr-none rounded-br-none"
                                    />
                                </div>
                                <div>
                                    <SelectInput
                                        value={inputData[`role-${index}`] || ""}
                                        name={`role-${index}`}
                                        // notShowBorder
                                        placeholder="Assign role"
                                        handleChange={(name, value) =>
                                            handleChange(
                                                {
                                                    target: { name, value },
                                                },
                                                index
                                            )
                                        }
                                        handleInputChange={debounce((evt) => {
                                            setDebouncedSearch({
                                                search: evt.target.value,
                                            });
                                        }, 800)}
                                        searchLoading={fetchingRoles}
                                        className={`!rounded-tl-none !bg-[#FCFCFC] rounded-bl-none`}
                                        isRequired={true}
                                        disabled={inputData[`email-${index}`] === ""}
                                        dropdownClassName="!py-0"
                                        customDropdownContainer={
                                            <div className="w-full py-1.5 flex flex-col gap-3.5">
                                                <div
                                                    onClick={() => setAddCustomRole(true)}
                                                    className="flex items-center gap-1 px-3 font-rocGroteskMedium cursor-pointer w-fit"
                                                >
                                                    <i className="ri-add-fill before:content-['\ea12'] text-p-55 text-xl mt-[-2px]"></i>
                                                    <span className="text-sm text-p-50">
                                                        Add custom role
                                                    </span>
                                                </div>
                                            </div>
                                        }
                                        dropdownOptions={roles?.map((item) => ({
                                            label: item?.label,
                                            value: item?.role,
                                        }))}
                                    />
                                </div>
                                {index !== 0 && (
                                    <div className="pl-2 group-hover:block hidden">
                                        <i
                                            className="ri-delete-bin-line text-[20px] text-slate-400 cursor-pointer"
                                            onClick={() => onDelete(index)}
                                        ></i>
                                    </div>
                                )}
                                <ModalContainer
                                    open={addCustomRole}
                                    showCloseIcon={false}
                                    modalClassName={"py-10 px-10"}
                                    tailwindClassName="w-[513px] xl:w-[600px]"
                                    closeModal={() => {
                                        setAddCustomRole(false);
                                    }}
                                >
                                    <div className="bg-white rounded-lg shadow-modalShadow relative pt-5 pb-10">
                                        <div className="flex justify-end px-10">
                                            <span
                                                onClick={() => setAddCustomRole(false)}
                                                className="material-icons text-gm-50 text-xl cursor-pointer"
                                            >
                                                close
                                            </span>
                                        </div>
                                        <div className="px-10 pt-3">
                                            <div className="flex flex-col items-center text-center mb-[45px]">
                                                <p className="text-2xl mb-2.5 font-rocGroteskMedium text-g-75">
                                                    Create a custom role
                                                </p>
                                                <p className="text-sm font-rocGroteskMedium text-gm-40">
                                                    Define what the new role’s responsibilities are
                                                </p>
                                            </div>
                                            <form className="w-full">
                                                <TextInput
                                                    name={`role`}
                                                    type={"text"}
                                                    placeholder={"Enter role name"}
                                                    value={role}
                                                    onChange={(e) => setRole(e.target.value)}
                                                    required={true}
                                                    containerClassname={"mb-6"}
                                                />
                                                <div>
                                                    <p className="text-base font-rocGroteskMedium mb-6">
                                                        Select access levels
                                                    </p>
                                                    <div className="flex flex-wrap gap-4 relative overflow-auto">
                                                        {accessTypes.map((access, idx) => (
                                                            <div
                                                                key={idx}
                                                                onClick={() =>
                                                                    handlePermissionChange(
                                                                        access.value
                                                                    )
                                                                }
                                                                className={`px-2.5 w-fit h-[30px] ${
                                                                    selectedPermissions.includes(
                                                                        access.value
                                                                    )
                                                                        ? "!bg-carribean-green !text-white !border-carribean-green"
                                                                        : ""
                                                                } border flex items-center gap-2 rounded-[8px] font-rocGroteskMedium bg-white hover:!bg-g-65 hover:text-white hover:!border-g-65 border-slate-300 text-[13px] cursor-pointer`}
                                                            >
                                                                {selectedPermissions.includes(
                                                                    access.value
                                                                ) ? (
                                                                    <i className="ri-checkbox-circle-fill text-base"></i>
                                                                ) : (
                                                                    <i className="ri-add-fill text-base"></i>
                                                                )}
                                                                {access.label}
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                                <Button
                                                    onClick={handleAddRole}
                                                    btnText={"Add role"}
                                                    isLoading={createRoles}
                                                    btnClassname={"my-10"}
                                                />
                                                <p
                                                    onClick={() => setAddCustomRole(false)}
                                                    className="text-sm font-rocGroteskMedium text-center cursor-pointer"
                                                >
                                                    Close
                                                </p>
                                            </form>
                                        </div>
                                    </div>
                                </ModalContainer>
                            </div>
                        ))}
                    </div>
                    <div
                        className="cursor-pointer flex space-x-1 items-center w-fit my-3"
                        onClick={addNewInputField}
                    >
                        <i className="ri-add-fill text-b-55  text-[24px]"></i>
                        <p className="text-b-55  text-xs font-rocGroteskMedium  underline-offset-1 underline">
                            Invite more team members
                        </p>
                    </div>
                    <div className="w-full mt-10">
                        <Button
                            btnText={"Complete setup!"}
                            isLoading={addingTeamMember}
                            type={"submit"}
                        />
                        <Button
                            btnText={"Skip"}
                            onClick={() => {
                                navigate("/dashboard/inventory", { replace: true });
                            }}
                            disabled={addingTeamMember}
                            btnClassname={"!bg-transparent !text-gm-75 mt-2"}
                        />
                    </div>
                </form>
            </div>
        </div>
    );
};

export default AddTeamMembers;
