import Button from "components/common/Button";
import TextareaInput from "components/common/InputField/TextareaInput";
import Loader from "components/common/Loader";
import VendorAvatar from "components/common/VendorAvatar";
import useGetInventoryIncidentComments from "hooks/inventoryHooks/useGetIncidentComments";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import { flatten } from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { inventoryActions } from "redux/Ldb/actions";

interface IncidentIdProps {
    inventoryIncidentId: string;
}
const IncidentComments = ({ inventoryIncidentId }: IncidentIdProps) => {
    const [comments, setComments] = useState("");
    const [incidentId, setIncidentId] = useState("");
    const [showAll, setShowAll] = useState(false);
    const { alertId } = useParams();
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (alertId) {
            setIncidentId(alertId);
        }
    }, [alertId]);
    useEffect(() => {
        if (inventoryIncidentId) {
            setIncidentId(inventoryIncidentId);
        }
    }, [inventoryIncidentId]);
    const { data, isFetching, pagination, isLoadingMore, handleRefectId, reFetchComments } =
        useGetInventoryIncidentComments();
    const { addInventoryIncidentComment, addInventoryIncidentCommentSuccess } = useAppSelector(
        (state) => state.inventory
    );
    // useEffect(()=>{
    //     if(inventoryIncidentId){
    //         handleRefectId(inventoryIncidentId)
    //     }
    // },[inventoryIncidentId])
    const addComment = () => {
        const body = {
            inventoryIncidentId: alertId ? alertId : inventoryIncidentId,
            comment: comments,
        };
        dispatch(inventoryActions.addIncidentComment(body));
    };

    useEffect(() => {
        if (Boolean(addInventoryIncidentCommentSuccess)) {
            dispatch(inventoryActions.resetInventoryIncidentComment());
            if (inventoryIncidentId) {
                handleRefectId(inventoryIncidentId);
            }
            reFetchComments();
            setComments("");
        }
    }, [addInventoryIncidentCommentSuccess, alertId]);

    const itemsToShow = showAll ? data : data?.slice(0, 5);

    return (
        <div>
            <div>
                <div className="flex items-center justify-between mb-3">
                    <span className="block text-base font-rocGroteskMedium">Add comments</span>
                    {/* <VendorAvatar
                        name="Augustine"
                        imageSrc=""
                        size={24}
                        textClassname="text-[8px]"
                    /> */}
                </div>

                <TextareaInput
                    name={"emailBody"}
                    value={comments}
                    onChange={(e) => setComments(e.target.value)}
                    rows={5}
                    placeholder={"Type a reply/comment"}
                    required={true}
                    containerClassname=""
                />

                <div className="flex items-center space-x-3 mt-4 mb-6">
                    <Button
                        btnText="Comment"
                        btnClassname="!px-4 !py-2 !h-[40px] !w-fit"
                        disabled={addInventoryIncidentComment}
                        isLoading={addInventoryIncidentComment}
                        onClick={addComment}
                    />
                    <Button
                        btnText="Cancel"
                        btnClassname="!px-4 !py-2 !h-[40px] !bg-n-20 !w-fit"
                        disabled={addInventoryIncidentComment}
                        onClick={() => {
                            setComments("");
                        }}
                    />
                </div>

                <div>
                    {isFetching ? (
                        <Loader />
                    ) : (
                        <>
                            {data?.length > 0 ? (
                                <>
                                    {itemsToShow?.map((commentData, index) => {
                                        const date = moment({
                                            year: commentData?._id?.year,
                                            month: commentData?._id?.month - 1,
                                            day: commentData?._id?.day,
                                        });

                                        return (
                                            <div key={index}>
                                                <div className="flex items-center space-x-4 mb-8">
                                                    <hr className="w-[45%] border-slate-200" />
                                                    <span className="text-sm whitespace-nowrap font-rocGroteskMedium">
                                                        {date?.format("DD MMM, YYYY")}
                                                    </span>
                                                    <hr className="w-[45%] border-slate-200" />
                                                </div>

                                                {commentData?.comments?.map((comment, idx) => (
                                                    <div
                                                        className={`w-[80%] pb-3  ${
                                                            idx > 0 && "pt-3"
                                                        }`}
                                                    >
                                                        <div className="flex items-start space-x-4">
                                                            <VendorAvatar
                                                                imageSrc={comment?.profile?.avatar}
                                                                name={comment?.creator?.fullName}
                                                                size={28}
                                                                containerClassname="!shrink-0"
                                                            />
                                                            <div>
                                                                <div className="mb-2">
                                                                    <span className="block text-[13px] font-rocGroteskMedium">
                                                                        {comment?.creator?.fullName}
                                                                    </span>
                                                                    <span className="block text-xs text-slate-500 font-rocGroteskMedium">
                                                                        {moment(
                                                                            comment?.createdAt
                                                                        ).format(
                                                                            "MMMM Do YYYY, h:mm:ss a"
                                                                        )}
                                                                    </span>
                                                                </div>

                                                                <span className="block text-[13px] text-slate-500 font-rocGroteskMedium">
                                                                    {comment?.comment}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        );
                                    })}
                                    {!showAll && data?.length > 5 && (
                                        <Button
                                            btnText="Load more messages"
                                            btnClassname="!px-4 !py-2 !h-[40px] !w-fit !bg-transparent !border !border-slate-300 my-6 "
                                            onClick={() => setShowAll(true)}
                                        />
                                    )}
                                    {isFetching && isLoadingMore && (
                                        <div className="flex justify-center my-4">
                                            <Loader color="gm-25" size={4} />
                                        </div>
                                    )}
                                    {pagination?.currentPage === pagination?.noOfPages &&
                                        showAll && (
                                            <div className="flex my-4 justify-center text-slate-500 text-sm font-rocGroteskMedium">
                                                End of list
                                            </div>
                                        )}
                                </>
                            ) : (
                                <div>
                                    <p>No comment</p>
                                </div>
                            )}
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default IncidentComments;
