import React from "react";
import CustomToast from "components/common/CustomToast";
import {toast} from "react-hot-toast";
import {settingsTypes} from "redux/Ldb/types";
import {settingsService} from "services/Ldb";
import {Dispatch} from "redux";

export const settingsActions = {
    getPaymentHistory,
    getSubscriptionPlan,
    getCreditHistory,
    createStripeSession,
    getStripeSession,
    resetGetStripeSession,
    resetCreateStripeSession,
    updateStripeSession,
    resetUpdateStripeSession,
    fetchCurrentSubscriptionPlan,
    cancelSubscriptionPlan,
    resetCancelSubscription,
    getWebsiteSubscriptionPlan
};

function getPaymentHistory() {
    return (dispatch: Dispatch) => {
        dispatch(request(settingsTypes.FETCH_PAYMENT_HISTORY_REQUEST));
        settingsService.fetchPaymentHistory().then(
            (res) => {
                dispatch(success(settingsTypes.FETCH_PAYMENT_HISTORY_SUCCESS, res?.data));
            },
            (error) => {
                if (error?.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(settingsTypes.FETCH_PAYMENT_HISTORY_FAILURE, error?.message));
                }
            }
        );
    };
}
function getCreditHistory() {
    return (dispatch: Dispatch) => {
        dispatch(request(settingsTypes.FETCH_CREDIT_HISTORY_REQUEST));
        settingsService.fetchCreditUsageHistory().then(
            (res) => {
                dispatch(success(settingsTypes.FETCH_CREDIT_HISTORY_SUCCESS, res?.data));
            },
            (error) => {
                if (error?.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(settingsTypes.FETCH_CREDIT_HISTORY_FAILURE, error?.message));
                }
            }
        );
    };
}
function getSubscriptionPlan(interval?: string | number) {
    return (dispatch: Dispatch) => {
        dispatch(request(settingsTypes.FETCH_PLANS_REQUEST));
        settingsService.fetchSubscriptionPlan(interval as string | number).then(
            (res) => {
                dispatch(success(settingsTypes.FETCH_PLANS_SUCCESS, res?.data));
            },
            (error) => {
                if (error?.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(settingsTypes.FETCH_PLANS_FAILURE, error?.message));
                }
            }
        );
    };
}

function getWebsiteSubscriptionPlan(interval) {
    return (dispatch) => {
        dispatch(request(settingsTypes.FETCH_WEBSITE_PLANS_REQUEST));
        settingsService.fetchWebsiteSubscriptionPlan(interval).then(
            (res) => {
                dispatch(success(settingsTypes.FETCH_WEBSITE_PLANS_SUCCESS, res?.data));
            },
            (error) => {
                if (error?.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(settingsTypes.FETCH_WEBSITE_PLANS_FAILURE, error?.message));
                }
            }
        );
    };
}

function fetchCurrentSubscriptionPlan() {
    return (dispatch: Dispatch) => {
        dispatch(request(settingsTypes.FETCH_CURRENT_SUBSCRIPTION_REQUEST));
        settingsService.fetchCurrentSubscription().then(
            (res) => {
                dispatch(success(settingsTypes.FETCH_CURRENT_SUBSCRIPTION_SUCCESS, res?.data));
            },
            (error) => {
                if (error?.message) {
                    console.log("error", error?.message);
                    dispatch(
                        failure(settingsTypes.FETCH_CURRENT_SUBSCRIPTION_FAILURE, error?.message)
                    );
                }
            }
        );
    };
}

function cancelSubscriptionPlan() {
    return (dispatch) => {
        dispatch(request(settingsTypes.CANCEL_SUBSCRIPTION_REQUEST));
        settingsService.cancelSubscriptionPlan().then(
            (res) => {
                dispatch(success(settingsTypes.CANCEL_SUBSCRIPTION_SUCCESS, res));
            },
            (error) => {
                if (error?.message) {
                    dispatch(
                        failure(settingsTypes.CANCEL_SUBSCRIPTION_FAILURE, error?.message)
                    );
                }
            }
        );
    };
}

function createStripeSession(body: Record<string, any> | Array<Record<string, any>>) {
    return (dispatch) => {
        dispatch(request(settingsTypes.CREATE_STRIPE_SESSION_REQUEST));
        settingsService.createStripeSession(body).then(
            (res) => {
                dispatch(success(settingsTypes.CREATE_STRIPE_SESSION_SUCCESS, res?.data));
            },
            (error) => {
                if (error?.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(settingsTypes.CREATE_STRIPE_SESSION_FAILURE, error?.message));
                }
            }
        );
    };
}

function updateStripeSession(body: Record<string, any> | Array<Record<string, any>>) {
    return (dispatch: Dispatch) => {
        dispatch(request(settingsTypes.UPDATE_STRIPE_SESSION_REQUEST));
        settingsService.updateStripeSession(body).then(
            (res) => {
                dispatch(success(settingsTypes.UPDATE_STRIPE_SESSION_SUCCESS, res?.data));
            },
            (error) => {
                if (error?.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(settingsTypes.UPDATE_STRIPE_SESSION_FAILURE, error?.message));
                }
            }
        );
    };
}

function getStripeSession(body: string | number) {
    return (dispatch: Dispatch) => {
        dispatch(request(settingsTypes.FETCH_CREDIT_STRIPE_SESSION_REQUEST));
        settingsService.fetchCreditStripeSession(body).then(
            (res) => {
                dispatch(success(settingsTypes.FETCH_CREDIT_STRIPE_SESSION_SUCCESS, res?.data));
            },
            (error) => {
                if (error?.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(settingsTypes.FETCH_CREDIT_STRIPE_SESSION_FAILURE, error?.message)
                    );
                }
            }
        );
    };
}

function resetCreateStripeSession() {
    return (dispatch: Dispatch) => {
        dispatch(success(settingsTypes.CREATE_STRIPE_SESSION_SUCCESS, null));
    };
}

function resetCancelSubscription() {
    return (dispatch) => {
        dispatch(success(settingsTypes.CANCEL_SUBSCRIPTION_SUCCESS, null));
    };
}

function resetUpdateStripeSession() {
    return (dispatch: Dispatch) => {
        dispatch(success(settingsTypes.UPDATE_STRIPE_SESSION_SUCCESS, null));
    };
}

function resetGetStripeSession() {
    return (dispatch: Dispatch) => {
        dispatch(success(settingsTypes.FETCH_CREDIT_STRIPE_SESSION_SUCCESS, null));
    };
}

function request(type: string) {
    return {type: type};
}

function success(type: string, data: any) {
    return {type: type, payload: data};
}
function failure(type: string, error: any) {
    return {type: type, payload: error ?? ""};
}
