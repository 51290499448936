import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../storeHooks";
import { trackTraceActions } from "redux/Ldb/actions";
import { useParams } from "react-router-dom";

const useGetTrackTraceBatchDetails = () => {
    const { id } = useParams();
    const dispatch = useAppDispatch();
    const [batchDetails, setBatchDetails] = useState<{ [key: string]: any }>([]);

    const { fetchingBatchDetails, fetchBatchDetailsSuccess, fetchBatchDetailsFailure } =
        useAppSelector((state) => state.trackTrace);

    useEffect(() => {
        if (Boolean(fetchBatchDetailsSuccess)) {
            setBatchDetails(fetchBatchDetailsSuccess);
        }
    }, [fetchBatchDetailsSuccess]);

    useEffect(() => {
        if (id) {
            dispatch(trackTraceActions.getBatchDetails(id));
        }
    }, [dispatch, id]);

    return {
        data: batchDetails,
        isFetching: fetchingBatchDetails,
        error: fetchBatchDetailsFailure,
    };
};

export default useGetTrackTraceBatchDetails;
