import Button from "components/common/Button";
import Dropdown from "components/common/Dropdown";
import Loader from "components/common/Loader";
import ModalContainer from "components/common/ModalContainer";
import PageFrame from "components/layout/PageFrame";
import useGetSingleProductWarehouses from "hooks/useGetSingleProductWarehouses";
import { truncate } from "lodash";
import moment from "moment";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const Warehouse = () => {
    const [deleteWarehouseShow, setDeleteWarehouseShow] = useState(false);
    const [warehouseId, setWarehouseId] = useState<string>("");
    const { data, isDeletingWarehouse, deleteWarehouseFunc, isLoadingMore, pagination } =
        useGetSingleProductWarehouses(warehouseId, setDeleteWarehouseShow);

    const navigate = useNavigate();

    return (
        <PageFrame isLoading={false}>
            {data?.length > 0 ? (
                <>
                    <div className="grid grid-cols-4 gap-4">
                        {data?.map((list) => {
                            return (
                                <div
                                    key={list?._id}
                                  
                                    className={`border border-[#E2E8F080] cursor-pointer rounded-md shadow-[0px_8px_8px_-4px_#10182808]`}
                                >
                                    <div className="p-3 border-b border-slate-100 flex items-center justify-between">
                                        <div className="flex  flex-col space-y-0.5">
                                            <div className="flex  items-center space-x-3">
                                                <div className="w-10 h-10 rounded bg-[#F3E8FF] flex items-center justify-center">
                                                    <i className="ri-store-2-line text-[#9333EA] text-[22px]"></i>
                                                </div>
                                                <div>
                                                    <span className="block text-g-75 text-sm font-rocGroteskMedium mb-[2px]">
                                                        {list?.warehouseName}
                                                    </span>
                                                    <span className="block text-slate-500 text-sm font-rocGroteskMedium mb-2">
                                                        {truncate(list?.address?.address, {
                                                            length: 25,
                                                        })}
                                                    </span>
                                                </div>
                                            </div>
                                            {list?.incidentType && (
                                                <div className="border border-[#FF3D1D] w-fit py-[2px] px-2 rounded-[3px]">
                                                   <p className="text-[12px] font-rocGroteskMedium text-[#FF3D1D] capitalize">
                                                       {list?.incidentType}
                                                   </p>
                                                </div> 
                                          )} 
                                        </div>
                                        <div>
                                            <Dropdown
                                                handleChange={(name, value) => {
                                                    console.log("first", name, value);
                                                }}
                                                dropdown={
                                                    <i className="ri-more-2-fill text-slate-500 text-base"></i>
                                                }
                                                // dropdownItemsClasses={`!px-0 !py-0`}
                                                // dropdownClassname={"!w-full !mb-10 !-mt-7"}
                                                dropdownContainerClasses={`shadow-cardShadow-4 border border-slate-100  rounded !max-h-fit
                                    `}
                                                dropdownOptions={[
                                                    {
                                                        label: "View warehouse",
                                                        value: "edit-warehouse",
                                                        labelClassName: "!text-gm-50",
                                                        action: () => {
                                                            navigate(
                                                                `/dashboard/inventory/warehouse/${list?._id}`
                                                            );
                                                        },
                                                    },
                                                    {
                                                        label: "Delete warehouse",
                                                        value: "delete-warehouse",
                                                        labelClassName: "!text-[red]",
                                                        action: () => {
                                                            setDeleteWarehouseShow(true);
                                                            setWarehouseId(list?.warehouse?._id);
                                                        },
                                                    },
                                                ]}
                                            />
                                        </div>
                                    </div>
                                    <div className="px-4 pt-4 pb-4 grid grid-cols-3 gap-3">
                                        <div className="text-xs text-slate-500 font-rocGroteskMedium">
                                            <span className="block">Curr. Stock</span>
                                            <span className="block">{list?.totalStock} units</span>
                                        </div>
                                        <div className="text-xs text-slate-500 font-rocGroteskMedium">
                                            <span className="block">Min. Stock</span>
                                            <span className="block">{list?.minStock} units</span>
                                        </div>
                                        <div className="text-xs text-slate-500 font-rocGroteskMedium">
                                            <span className="block">Max. Stock</span>
                                            <span className="block">{list?.maxStock} units</span>
                                        </div>
                                    </div>
                                    <div className="px-4 pb-4 grid grid-cols-3 gap-3">
                                        <div className="text-xs text-slate-500 font-rocGroteskMedium">
                                            <span className="block">Stock needs</span>
                                            <span
                                                className={`block ${
                                                    Number(list?.stockNeeds) > 0 && "text-[#E4281D]"
                                                }`}
                                            >
                                                {Number(list?.stockNeeds)?.toFixed(0)} units
                                            </span>
                                        </div>
                                        {list?.reorderDate && (
                                            <div className="text-xs whitespace-nowrap text-slate-500 font-rocGroteskMedium">
                                                <span className="block">Re-order date</span>
                                                <span className="block">
                                                    {moment(list?.reorderDate).format(
                                                        "DD MMM, YYYY"
                                                    )}
                                                </span>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            );
                        })}
                    </div>

                    {isLoadingMore && (
                        <div className="w-full flex justify-center my-3">
                            <Loader size={4} />
                        </div>
                    )}
                    {pagination?.currentPage === pagination?.noOfPages && (
                        <div className="flex my-3 text-slate-500  justify-center text-sm font-rocGroteskMedium">
                            End of list
                        </div>
                    )}
                </>
            ) : (
                <div className="flex my-[50px]  justify-center text-xl font-rocGroteskMedium">
                    No Warehouses available
                </div>
            )}

            <ModalContainer
                open={deleteWarehouseShow}
                showCloseIcon={false}
                modalClassName={"py-10 px-10"}
                tailwindClassName="w-[600px]"
                closeModal={() => {
                    setDeleteWarehouseShow(false);
                }}
            >
                <div className="bg-white py-7 px-6  shadow-cardShadow relative rounded-[8px]">
                    <div className="border-[2px] h-[50px] bg-[#FEF2F2] w-[50px] flex items-center justify-center rounded-full border-[#DC2626]">
                        <i className="ri-delete-bin-line text-[#DC2626] text-[26px]"></i>
                    </div>
                    <div className="mb-5 mt-4">
                        <h1 className="text-lg mb-2  font-rocGroteskMedium">Delete warehouse?</h1>
                        <p className="text-slate-500 font-rocGroteskMedium text-base">
                            Are you sure you want to delete this warehouse? Please make sure all
                            products are transferred first before proceeding.
                        </p>
                    </div>
                    <div className="flex justify-end items-center ">
                        <div className="flex items-center !whitespace-nowrap space-x-2 ">
                            <Button
                                btnText={"No, cancel"}
                                type={"button"}
                                btnClassname={"!bg-n-20 !w-fit !text-gm-50"}
                                disabled={isDeletingWarehouse}
                                onClick={() => setDeleteWarehouseShow(false)}
                            />
                            <Button
                                btnClassname={"!py-3 !w-fit"}
                                onClick={deleteWarehouseFunc}
                                btnText={"Delete warehouse"}
                                type={"button"}
                                isLoading={isDeletingWarehouse}
                                disabled={isDeletingWarehouse}
                            />
                        </div>
                    </div>
                </div>
            </ModalContainer>
        </PageFrame>
    );
};

export default Warehouse;
