import Button from "components/common/Button";
import Rating from "components/common/Rating";
import VendorAvatar from "components/common/VendorAvatar";
import { displayFileIcon, formatMoney } from "helpers";
import { getFileTypeFromUrl } from "helpers/getFileTypeFromUrl";
import { truncate } from "lodash";
import React from "react";

type ItemDetailsCardProps = {
    showQuotesButton?: boolean;
    showQuotesDownload?: boolean;
    showSuggestionQuote?: boolean;
    title?: string;
    email?: string;
    description?: string;
    documents?: { path: string; filename: string }[];
    logo?: string;
    amount?: number | string;
    currency?: string;
    sentAt?: string;
    rfqTaId?: string;
};

export const ItemDetailsCard = ({
    showQuotesButton,
    showSuggestionQuote,
    showQuotesDownload,
    title = "Alfa Express Cargo Solutions",
    email = "augustine@augustine.com",
    description,
    documents,
    logo,
    amount,
    currency,
    sentAt,
    rfqTaId,
}: ItemDetailsCardProps) => {
    return (
        <div className="border-b pb-5 mx-1 border-slate-100 mb-3">
            <div className="mt-5 px-4 lg:px-[34px]">
                {showSuggestionQuote && (
                    <div className="bg-[url('https://res.cloudinary.com/dvxi7qcmd/image/upload/v1692194429/Tradeally_2.0/purple_banner_b60tth.svg')] space-x-1 items-center flex w-[156px] h-[24px]">
                        <div className=" flex items-center justify-center border-r border-white w-[28px]">
                            <i className="ri-copper-diamond-line text-[17px] text-white"></i>
                        </div>
                        <p className="text-[12px] text-white font-rocGroteskMedium ">
                            suggested quote
                        </p>
                    </div>
                )}
                <div className="mt-3">
                    <p className="text-slate-900 text-[12px] font-rocGroteskMedium">
                        RFQ-{rfqTaId}
                    </p>
                    <div className="flex justify-between mt-1.5 items-center">
                        <div className="flex items-center space-x-3 ">
                            <div className="my-[15px]">
                                <VendorAvatar size={40} imageSrc={logo} name={title} />
                            </div>
                            <div>
                                <div className="flex items-center space-x-2">
                                    <div>
                                        <h5 className="text-[16px] font-rocGroteskMedium leading-6">
                                            {title}
                                        </h5>
                                    </div>
                                    {/* <div className="lg:block hidden">
                                        <Rating ratingNo={3} totalRating={5} />
                                    </div> */}
                                </div>
                                <h6 className="text-[14px] font-rocGroteskRegular text-slate-500">
                                    {email}
                                </h6>
                                {/* <div className="lg:hidden block">
                                    <Rating ratingNo={3} totalRating={5} />
                                </div> */}
                            </div>
                        </div>
                        {/* <div className="cursor-pointer justify-center bg-white border w-[34px] h-[34px] border-slate-100 p-2 rounded-full flex items-center">
                            <i className="ri-thumb-down-line"></i>
                        </div> */}
                    </div>
                    {description && (
                        <p className="text-gm-50 font-rocGroteskMedium text-sm mt-3">
                            {description}
                        </p>
                    )}
                </div>
                <div className="flex mt-4">
                    {showQuotesDownload &&
                        documents?.map((doc) => {
                            return (
                                <div
                                    onClick={() => {
                                        fetch(doc?.path)
                                            .then((response) => {
                                                response.blob().then((blob) => {
                                                    // Creating new object of PDF file
                                                    const fileURL =
                                                        window.URL.createObjectURL(blob);

                                                    let alink = document.createElement("a");
                                                    alink.href = fileURL;
                                                    alink.download = doc?.filename;
                                                    alink.click();
                                                });
                                            })
                                            .catch((error) => console.error(error));
                                    }}
                                    key={doc?.path}
                                    className="bg-white cursor-pointer border flex items-center py-2 px-3 space-x-2 rounded-[6px]  border-slate-200 shadow-box-shadow-5 drop-shadow-sm"
                                >
                                    {displayFileIcon(getFileTypeFromUrl(doc?.path), true)}
                                    <p className="text-gm-50 font-rocGroteskMedium text-[14px]">
                                        {truncate(doc?.filename, { length: 30 })}
                                    </p>
                                    <div className="cursor-pointer">
                                        <i className="ri-download-line text-gm-50"></i>
                                    </div>
                                </div>
                            );
                        })}
                </div>
                <div className="flex justify-between w-full mt-2 items-center">
                    <div className="flex sm:flex-row w-fit flex-col sm:space-y-0 space-y-2 sm:space-x-2 mt-2">
                        {amount && (
                            <div className="bg-slate-100 rounded-[4px] px-2 py-1">
                                <p className="text-slate-700  text-[14px] font-rocGroteskMedium">
                                    Amount: {currency}
                                    {amount}
                                </p>
                            </div>
                        )}
                        <div className="bg-slate-100 rounded-[4px] px-2 py-1">
                            <p className="text-slate-700  text-[14px] font-rocGroteskMedium">
                                Sent: {sentAt ? sentAt : "11 hours ago"}
                            </p>
                        </div>
                    </div>
                    {showQuotesButton && (
                        <div className="flex md:flex-row flex-col md:space-y-0 space-y-3 items-center  sm:w-[250px] whitespace-nowrap space-x-3">
                            <Button
                                btnClassname={"!bg-n-20 !text-gm-50"}
                                btnText={"Reject quote"}
                                type={"submit"}
                                isLoading={false}
                            />
                            <Button btnText={"Accept quote"} type={"submit"} isLoading={false} />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};
