import config from "config/config";
import { authService } from "./authService";
import { createUrlSearchParams } from "helpers";

export const workforceService = {
    getAiAgents,
    getAiTeammates,
    getSingleAiTeammate,
    createAiTeammate,
    getTaskList,
    getSingleTask,
    updateTaskSalesOrder,
    approveTaskStep,
    retryTaskStep,
    giveTaskStepFeedback,
    getTaskAnalytics,
    updateAiTeammate,
};

async function getAiAgents() {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(`${config.API_URL}/api/v1/ldb/ai/agents`, requestOptions);
    return res;
}

async function getAiTeammates(page: string | number, search?: string) {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/aiAgent/list?page=${page}${
            search ? `&search=${search}` : ""
        }`,
        requestOptions
    );
    return res;
}

async function getSingleAiTeammate(aiTeammateId: string) {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/aiAgent/single/${aiTeammateId}`,
        requestOptions
    );
    return res;
}

async function createAiTeammate(data: Record<string, any> | Array<Record<string, any>>) {
    const requestOptions = {
        method: "POST",
        body: JSON.stringify(data),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/aiAgent/create`,
        requestOptions
    );

    return res;
}

async function updateAiTeammate(
    data: Record<string, any> | Array<Record<string, any>>,
    aiTeammateId: string
) {
    const requestOptions = {
        method: "PUT",
        body: JSON.stringify(data),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/aiAgent/update/single/${aiTeammateId}`,
        requestOptions
    );

    return res;
}

async function getTaskList(
    page?: string | number,
    search?: string,
    taskStatus?: string,
    assignedTo?: string,
    taskType?: string
) {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/aiAgent/tasks/list?${page ? `&page=${page}` : ""}${
            search ? `&search=${search}` : ""
        }${taskStatus ? `&status=${taskStatus}` : ""}${assignedTo ? `&agentId=${assignedTo}` : ""}${
            taskType ? `&taskType=${taskType}` : ""
        }`,
        requestOptions
    );

    return res;
}

async function getSingleTask(taskId: string) {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/aiAgent/tasks/single/${taskId}`,
        requestOptions
    );

    return res;
}

async function updateTaskSalesOrder(data: Record<string, any> | Array<Record<string, any>>) {
    const requestOptions = {
        method: "PUT",
        body: JSON.stringify(data),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/aiAgent/step/edit`,
        requestOptions
    );

    return res;
}

async function approveTaskStep(data: Record<string, any> | Array<Record<string, any>>) {
    const requestOptions = {
        method: "PUT",
        body: JSON.stringify(data),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/aiAgent/step/state`,
        requestOptions
    );

    return res;
}

async function retryTaskStep(data: Record<string, any> | Array<Record<string, any>>) {
    const requestOptions = {
        method: "POST",
        body: JSON.stringify(data),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/aiAgent/step/retry`,
        requestOptions
    );

    return res;
}

async function giveTaskStepFeedback(data: Record<string, any> | Array<Record<string, any>>) {
    const requestOptions = {
        method: "POST",
        body: JSON.stringify(data),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/aiAgent/step/feedback`,
        requestOptions
    );

    return res;
}

async function getTaskAnalytics() {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/aiAgent/tasks/dashboard-analytics`,
        requestOptions
    );

    return res;
}
