import React from "react";

type SpinnerLoaderProps = {
    size?: number;
};

const SpinnerLoader = ({ size }: SpinnerLoaderProps) => {
    return (
        <svg className="animate-spin" viewBox="0 0 50 50" width={`${size}`} height={`${size}`}>
            <circle
                className="text-gray-200"
                cx="25"
                cy="25"
                r="20"
                strokeWidth="4"
                fill="none"
                stroke="currentColor"
            />
            <circle
                className="text-green-500"
                cx="25"
                cy="25"
                r="20"
                strokeWidth="4"
                fill="none"
                strokeDasharray="125.6"
                strokeDashoffset="100"
                strokeLinecap="round"
                stroke="currentColor"
            />
        </svg>
    );
};

export default SpinnerLoader;
