import useGetAllWarehouses from "hooks/useGetAllWarehouses";
import { truncate } from "lodash";
import Loader from "components/common/Loader";
import TextInput from "components/common/InputField/TextInput";
import AlertCard from "components/common/AlertCard";
import Dropdown from "components/common/Dropdown";
import useGetInventoryIncidentsStocking from "hooks/inventoryHooks/useGetInventoryIncidentsStocking";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

const PotentialOverStocking = () => {
    const { productId } = useParams();
    const [showAll, setShowAll] = useState(false);
    const potentialStockingContent = document.getElementById("potentialStockingContent");

    const {
        data,
        isFetching,
        pagination,
        isLoadingMore,
        incidentType,
        handleIncidentTypeChange,
        handleWarehouseChange,
    } = useGetInventoryIncidentsStocking(productId, potentialStockingContent);

    const {
        warehouseValue: warehouseDataValue,
        searchTwo,
        handleDebouncedChangeTwo,
        handleSearchTwo,
        searchResult,
        handleFilterChange: filterChange,
        handleClearFilter: clearFilter,
    } = useGetAllWarehouses();

    useEffect(() => {
        handleIncidentTypeChange("overstocking");
    }, []);

    const itemsToShow = showAll ? data : data?.slice(0, 2);

    const handleShowMore = () => {
        setShowAll(true);
    };
    return (
        <div className="max-h-[400px] overflow-y-auto" id="potentialStockingContent">
            {isFetching && data?.length === 0 ? (
                <Loader />
            ) : (
                <div>
                    <div className="flex justify-between items-center mb-4">
                        <h4 className="text-lg text-[#16A34A] font-rocGroteskMedium">
                            <i className="ri-arrow-up-line mr-1.5"></i>
                            <span>Potential Overstocking</span>
                        </h4>

                        <div className="border rounded border-slate-200">
                            <Dropdown
                                handleChange={(name, value) => {
                                    // console.log("first", name, value);
                                }}
                                dropdown={
                                    <div className="flex items-center space-x-2  whitespace-nowrap h-[40px] py-2 px-4 justify-between ">
                                        <div className="flex items-center space-x-1">
                                            <i className="ri-map-pin-line text-sm text-g-75 "></i>
                                            <span
                                                className={`text-g-75 font-rocGroteskMedium text-[13px]`}
                                            >
                                                {warehouseDataValue?.name ||
                                                    "All Warehouses / Outlets"}
                                            </span>
                                        </div>
                                        {warehouseDataValue?.name && (
                                            <i
                                                onClick={() => {
                                                    clearFilter();
                                                    handleWarehouseChange("");
                                                }}
                                                className="ri-close-circle-line text-slate-500"
                                            ></i>
                                        )}
                                        <i className="ri-arrow-down-s-line text-g-75"></i>
                                    </div>
                                }
                                search={
                                    <div>
                                        <TextInput
                                            value={searchTwo}
                                            name={"search"}
                                            type={"text"}
                                            required={false}
                                            onChange={handleSearchTwo}
                                            onInput={handleDebouncedChangeTwo}
                                            inputPlaceholder="Search warehouse"
                                            containerClassname=""
                                            // inputContainerClassname={"!border-none !rounded-none "}
                                            // inputClassName="w-[50%]"
                                        />
                                    </div>
                                }
                                dropdownContainerClasses={`shadow-cardShadow-4 !right-0  border border-slate-100  rounded `}
                                dropdownOptions={[
                                    {
                                        customChild: (
                                            <div>
                                                <div>
                                                    {searchResult?.map((warehouse) => {
                                                        return (
                                                            <div
                                                                key={warehouse?._id}
                                                                onClick={() => {
                                                                    filterChange(
                                                                        warehouse?.warehouseName,
                                                                        warehouse?._id
                                                                    );
                                                                    handleWarehouseChange(
                                                                        warehouse?._id
                                                                    );
                                                                }}
                                                            >
                                                                <div
                                                                    className={`flex items-center space-x-3 max-w-[300px] px-3 py-2 hover:bg-slate-100 cursor-pointer ${
                                                                        warehouseDataValue?.value ===
                                                                            warehouse?._id &&
                                                                        "bg-slate-100"
                                                                    }`}
                                                                >
                                                                    <i className="ri-map-pin-line text-base text-g-75 "></i>
                                                                    <div>
                                                                        <span
                                                                            className={`block text-g-75 text-sm font-rocGroteskMedium ${
                                                                                warehouse?.address
                                                                                    ?.address &&
                                                                                "mb-[2px]"
                                                                            }`}
                                                                        >
                                                                            {
                                                                                warehouse?.warehouseName
                                                                            }
                                                                        </span>
                                                                        <span className="block  text-g-75 text-sm font-rocGroteskMedium ">
                                                                            {
                                                                                warehouse?.address
                                                                                    ?.address
                                                                            }
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </div>
                                        ),
                                    },
                                ]}
                            />
                        </div>
                    </div>

                    {data?.length > 0 ? (
                        <>
                            <div className="space-y- shadow-boxShadow-12">
                                {itemsToShow?.map((item: any, idx) => (
                                    <Link
                                        key={idx}
                                        to={`/dashboard/inventory/alert/${item?._id}/${idx + 1}`}
                                    >
                                        <AlertCard
                                            key={idx}
                                            index={idx + 1}
                                            productName={item?.inventory?.productName}
                                            stockOutDescription={
                                                item?.inventory?.productDescription
                                            }
                                            incidentType={item?.incidentType}
                                            risk={item?.risk}
                                            createdAt={item?.createdAt}
                                            status={item?.status}
                                            stockOutQty={item?.qty || 0}
                                            potentialRevenue={
                                                item?.inventory?.unitCost?.amount * item?.qty || 0
                                            }
                                            forcastedStockDuration={item?.forecastLength}
                                            stockOutDate={item?.stockoutDate || "N/A"}
                                            currency={item?.inventory?.currency}
                                            uom={
                                                item?.inventory?.productStockDetails
                                                    ?.unitOfMeasurement || "N/A"
                                            }
                                            isStockout={false}
                                        />
                                    </Link>
                                ))}
                                {!showAll && data?.length > 2 && (
                                    <div
                                        className="space-x-2.5 flex items-center justify-center py-[14px] border border-n-20 cursor-pointer"
                                        onClick={handleShowMore}
                                    >
                                        <span className="text-sm font-rocGroteskMedium mt-[2px]">
                                            Show more
                                        </span>
                                        <i className="ri-arrow-right-line text-lg"></i>
                                    </div>
                                )}
                            </div>
                            {isFetching && isLoadingMore && (
                                <div className="flex justify-center my-4">
                                    <Loader color="gm-25" size={4} />
                                </div>
                            )}
                            {pagination?.currentPage === pagination?.noOfPages && showAll && (
                                <div className="flex my-4 justify-center text-slate-500 text-sm font-rocGroteskMedium">
                                    End of list
                                </div>
                            )}
                        </>
                    ) : (
                        <div className="items-center flex  h-[30%]  justify-center my-[20%]">
                            <div className="space-y-2  text-center">
                                <i className="ri-file-list-3-line text-gm-50 text-[24px]"></i>
                                <p className="text-slate-500 font-rocGroteskMedium text-[12px]">
                                    No potential overstocking event!
                                </p>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default PotentialOverStocking;
