import React, { useState } from "react";
import Dropdown from "components/common/Dropdown";
import PageFrame from "components/layout/PageFrame";
import { AnalyticsCard } from "components/views/Ldb/Dashboard/BusinessAnalytics/Card";
import useGetAllChannel from "hooks/useGetAllChannels";
import useGetTrackTraceBatchIncidents from "hooks/trackTraceHook/useGetTrackBatchIncidents";
import useGetTrackTraceBatchDetails from "hooks/trackTraceHook/useGetTrackTraceBatchDetails";
import moment from "moment";
import { useNavigate, useSearchParams } from "react-router-dom";
import SupplyChain from "./SupplyChain";
import Loader from "components/common/Loader";
import { truncate } from "lodash";

const ProductTrackDetails = () => {
    const [param] = useSearchParams();
    const productType = param.get("productType");
    const navigate = useNavigate();
    const { data: channels, isFetching: fetchingChannels } = useGetAllChannel();
    const { data: batchDetails, isFetching } = useGetTrackTraceBatchDetails();

    const {
        data: batchIncidents,
        handleChannelChange,
        handleDateChange,
        isFetching: fetchingIncidents,
        channel,
    } = useGetTrackTraceBatchIncidents();

    const [date, setDate] = useState<string>("");

    const values = [
        {
            label: "This year",
            value: "yearly",
        },
        {
            label: "This month",
            value: "monthly",
        },
        {
            label: "This week",
            value: "weekly",
        },

        {
            label: "Today",
            value: "daily",
        },
        {
            label: "All products",
            value: "all",
        },
    ];

    const colors = ["#0F846D", "#FFD8D2", "#FF5D43"];

    const getValues = (arr) => {
        return arr.filter((item) => item > 0);
    };

    return (
        <PageFrame isLoading={isFetching}>
            <div className="bg-white border rounded-[8px] py-4  border-[#F1F5F9]">
                <div className="border-b py-4 px-4 border-slate-100 w-full">
                    <div
                        className="flex items-center space-x-3 cursor-pointer text-[#3B4C58] text-sm font-rocGroteskMedium"
                        onClick={() => navigate("/dashboard/track-trace")}
                    >
                        <i className="ri-arrow-left-line"></i>
                        <p className="underline">Back</p>
                    </div>
                </div>

                <div className="px-5">
                    <div className="my-8 flex justify-between items-end">
                        <div className="">
                            <div className="flex gap-2 !font-rocGroteskMedium  text-sm mb-2">
                                {productType === "raw-material" && (
                                    <p className="bg-slate-200 text-slate-500 rounded py-0.5 px-2">
                                        {productType}
                                    </p>
                                )}

                                <p
                                    className={` ${
                                        batchDetails?.inventoryStockMovement?.status === "fulfilled"
                                            ? "text-g-60 bg-g-25"
                                            : batchDetails?.inventoryStockMovement?.status ===
                                              "rejected"
                                            ? "text-r-55 bg-r-25 "
                                            : batchDetails?.inventoryStockMovement?.status ===
                                              "defect-detected"
                                            ? "text-[#E2A400] bg-[rgba(255,185,3,0.15)]"
                                            : "text-slate-900 bg-slate-200"
                                    } capitalize !font-rocGroteskMedium text-sm rounded-2xl py-0.5 px-2`}
                                >
                                    {batchDetails?.inventoryStockMovement?.status ===
                                    "defect-detected"
                                        ? "Defect detected"
                                        : batchDetails?.inventoryStockMovement?.status ===
                                          "in-progess"
                                        ? "In progress"
                                        : batchDetails?.inventoryStockMovement?.status}
                                </p>
                            </div>

                            <p className="text-slate-900 font-rocGroteskMedium">
                                {batchDetails?.inventoryStock?.productName}
                            </p>
                            <p className="font-rocGroteskMedium">
                                <span className="text-slate-400 pr-1">
                                    {batchDetails?.inventoryStock?.batchId}
                                </span>
                                {batchDetails?.inventoryStock?.productViabilityDetails
                                    ?.expiryDate && (
                                    <>
                                        {" "}
                                        <span className="text-slate-600 pr-1">
                                            Exp.{" "}
                                            {moment(
                                                batchDetails?.inventoryStock
                                                    ?.productViabilityDetails?.expiryDate
                                            )?.format("DD/MM/YYYY")}
                                        </span>
                                        <span className="text-r-50">{`( ${batchDetails?.expiryDate} to expiry)`}</span>
                                    </>
                                )}
                            </p>
                        </div>

                        {/* <div className="flex gap-1.5 items-center ">
                        

                            <Button
                                btnText="Export report"
                                btnClassname="!bg-white !border !border-slate-100 rounded-[5px] !w-fit !py-1.5 !px-2.5 !shadow-buttonShadow2"
                                icon={
                                    <i className="ri-expand-right-line text-lg text-[#142837]"></i>
                                }
                            />

                            <div className="ml-5">
                                <Button
                                    btnText={"Initiate recall"}
                                    onClick={() => console.log("button")}
                                    type={"button"}
                                    btnClassname={"!w-auto !px-4 !py-3 !bg-n-20"}
                                    icon={
                                        <img
                                            src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1715781084/recall_hddqre.svg"
                                            className=" text-gm-50 text-[20px]"
                                        />
                                    }
                                />
                            </div>

                            <div className="ml-5">
                                <Button
                                    btnText={"Setup product alert"}
                                    onClick={() => console.log("button")}
                                    type={"button"}
                                    btnClassname={"!w-auto !px-4 !py-2"}
                                    icon={<i className="ri-add-fill text-gm-50 text-[20px]"></i>}
                                />
                            </div>
                        </div> */}
                    </div>
                    <div className="flex max-sm:grid max-sm:grid-cols-1 border-b !border-b-slate-200 mb-8">
                        <AnalyticsCard
                            title={"Total produced units"}
                            value={batchDetails?.totalProducedUnit}
                            addComma={true}
                            loader={false}
                            iconTwo
                            className="!shadow-none !border-y-0 !border-l-0 !border-r max-sm:border-none !border-r-slate-200 !pr-[45px] !py-4 !pl-[10px] !rounded-none"
                            titleClassName="!text-slate-950 !text-[13px]"
                            innerClassName="mb-4"
                        />
                        <AnalyticsCard
                            title={"Total faulty units"}
                            value={batchDetails?.totalFaultySupply}
                            loader={false}
                            iconTwo
                            className="!shadow-none !border-y-0 !border-l-0 !border-r max-sm:border-none !border-r-slate-200 !pr-[45px] !py-4 !pl-[10px] !rounded-none"
                            titleClassName="!text-slate-950"
                            innerClassName="mb-4"
                            addComma={true}
                        />
                        <AnalyticsCard
                            title={"Recalled units"}
                            value={batchDetails?.totalRecalledUnits}
                            loader={false}
                            iconTwo
                            className="!shadow-none !border-y-0 !border-x-0 !pr-[45px] !py-4 !pl-[10px] !rounded-none"
                            titleClassName="!text-slate-950"
                            innerClassName="mb-4"
                            addComma={true}
                        />
                    </div>

                    {/* <div className="space-y-8 shadow-tableShadow">
                        <div className="border-b !border-b-slate-200 bg-[#F8FAFC] w-full flex justify-between items-center p-4">
                            <p className="text-b-50 text-lg font-rocGroteskMedium">
                                Production information
                            </p>

                            {productType !== "raw-material" && (
                                <div className="flex gap-2 items-center">
                                    <Button
                                        btnText={
                                            showBom
                                                ? "Click to close  BOM details"
                                                : "Click to see  BOM details"
                                        }
                                        btnClassname="!bg-white rounded-md !border !border-slate-200 py-[7px] px-2.5"
                                        onClick={() => setShowBom(!showBom)}
                                    />

                                    <i
                                        className={`${
                                            showBom ? "ri-arrow-up-s-line " : "ri-arrow-down-s-line"
                                        } text-b-50 font-rocGroteskMedium text-2xl transition ease-in-out duration-300`}
                                    ></i>
                                </div>
                            )}
                        </div>
                        <div className="space-y-8 px-8 font-rocGroteskMedium pb-8 ">
                            <div className="flex items-center justify-between ">
                                <div className="flex gap-2.5 items-start">
                                    <i className="ri-calendar-2-fill text-base"></i>
                                    <div className="">
                                        <p className="text-slate-500 text-sm mb-2.5">
                                            Production date
                                        </p>
                                        <p className="text-gm-50 text-base">04/03/2022</p>
                                    </div>
                                </div>
                                <div className="flex gap-2.5 items-start">
                                    <i className="ri-calendar-2-fill text-base"></i>
                                    <div className="">
                                        <p className="text-slate-500 text-sm mb-2.5">
                                            Production expiry date
                                        </p>
                                        <p className="text-gm-50 text-base">04/03/2022</p>
                                    </div>
                                </div>
                                <div className="flex gap-2.5 items-start">
                                    <i className="ri-map-pin-fill text-base"></i>
                                    <div className="">
                                        <p className="text-slate-500 text-sm mb-2.5">
                                            Manufacturing location
                                        </p>
                                        <p className="text-gm-50 text-base">
                                            Morley Park, TX 3000C, Texas
                                        </p>
                                    </div>
                                </div>
                                <div className="flex gap-2.5 items-start">
                                    <i className="ri-file-search-line text-base"></i>
                                    <div className="">
                                        <p className="text-slate-500 text-sm mb-2.5">
                                            Quality control status
                                        </p>

                                        <p
                                            className={` text-sm ${
                                                status === 0
                                                    ? "text-g-60 bg-g-25"
                                                    : status === 1
                                                    ? "text-r-55 bg-r-25"
                                                    : "text-[#E2A400] bg-[rgba(255,185,3,0.15)]"
                                            }   py-0.5 px-2 rounded-2xl !w-fit`}
                                        >
                                            {status === 0
                                                ? "QC PASSED"
                                                : status === 1
                                                ? "Returned"
                                                : "Defect detected"}
                                        </p>
                                    </div>
                                </div>
                                <div className="flex gap-2.5 items-start">
                                    <i className="ri-refresh-line text-base"></i>
                                    <div className="">
                                        <p className="text-slate-500 text-sm mb-2.5">
                                            Recall status
                                        </p>
                                        <p className="text-gm-50 text-base">N/A</p>
                                    </div>
                                </div>
                            </div>
                            {showBom && (
                                <div className="transition ease-in-out duration-300 ">
                                    <form action="" className="">
                                        <div className="flex items-center justify-between mb-6">
                                            <p className="text-gm-50 text-base">
                                                Bill of Material (BOM)
                                            </p>
                                            <Button
                                                btnText={"Create a BOM"}
                                                onClick={() => console.log("button")}
                                                type={"submit"}
                                                btnClassname={"!w-auto !px-4 !py-2"}
                                            />
                                        </div>

                                        <div className="flex items-center gap-4 mb-8">
                                            <TextInput
                                                value={"Logitech 4K Pro Webcam"}
                                                name={"upc"}
                                                type={"text"}
                                                disabled={true}
                                                placeholder={"Product Name"}
                                                required={true}
                                                inputContainerClassname="!bg-slate-200"
                                            />
                                            <TextInput
                                                // value={productData.upc}
                                                name={"upc"}
                                                type={"text"}
                                                onChange={
                                                    (e) => console.log(e)
                                                    // handleChange(e.target.name, e.target.value)
                                                }
                                                placeholder={"BOM Name"}
                                                required={true}
                                                containerClassname=""
                                            />
                                        </div>
                                    </form>
                                    <div className="">
                                        <p className="text-gm-50 text-base mb-6">
                                            Parts/Product Recipe
                                        </p>
                                        <CustomTable
                                            tableHeader={recipesTableHeader}
                                            tableBody={recipesTableBody}
                                            isScrollable={false}
                                            isCellBordered={true}
                                            headerContainerClass="!bg-slate-50"
                                            headerItemClass="font-rocGroteskMedium "
                                            tableClass="mb-6"
                                        />
                                    </div>
                                </div>
                            )}

                            {productType === "raw-material" && (
                                <div className="">
                                    <p className="text-gm-50 text-base mb-6">
                                        Finished goods with this item as a raw material
                                    </p>
                                    <CustomTable
                                        tableHeader={finishedGoodsTableHeader}
                                        tableBody={finishedGoodsTableBody}
                                        isScrollable={false}
                                        isCellBordered={true}
                                        headerContainerClass="!bg-slate-50"
                                        headerItemClass="font-rocGroteskMedium "
                                        tableClass="mb-6"
                                    />
                                </div>
                            )}
                        </div>
                    </div> */}

                    <div className="">
                        {/* <div className="flex mt-10 gap-4 w-full">
                            <p className="text-slate-900 text-base font-rocGroteskMedium mb-[22px] w-[55%]">
                                Supply Chain tracking
                            </p>
                            <p className="text-slate-900 text-base font-rocGroteskMedium mb-[22px] w-[45%]">
                                Product tracing
                            </p>
                        </div> */}
                        <div className="flex max-lg:flex-col gap-4 items-stretch ">
                            <div className="w-[55%] max-lg:w-full">
                                <p className="text-slate-900 text-base font-rocGroteskMedium mb-[22px] w-full">
                                    Supply Chain tracking
                                </p>
                                <SupplyChain />
                            </div>

                            <div className="w-[45%] max-lg:w-full">
                                <p className="text-slate-900 text-base font-rocGroteskMedium mb-[22px] w-full">
                                    Product tracing
                                </p>

                                <div className="w-full border border-slate-200 rounded-t-lg">
                                    <div className=" font-rocGroteskMedium px-3 py-4 !my-0 !w-full">
                                        <div className="flex max-sm:flex-col max-sm:space-y-2 max-sm:px-0 max-sm:pt-0 max-sm:pb-2 max-sm:items-start justify-between items-center border-b border-slate-300 pb-3 px-1">
                                            <p className="text-base text-slate-900 font-rocGroteskMedium">
                                                Incidents per channel
                                            </p>
                                            <div className="flex gap-1">
                                                <div className="relative">
                                                    <Dropdown
                                                        handleChange={(name, value) =>
                                                            handleChannelChange({
                                                                target: { name, value },
                                                            })
                                                        }
                                                        dropdown={
                                                            <div className="flex items-center justify-between rounded-[6px] shadow-buttonShadow2 p-[5px] gap-2 !border !border-slate-200 w-[120px]">
                                                                <div
                                                                    className={`text-slate-800 font-rocGroteskMedium text-[13px] inline-block capitalize`}
                                                                >
                                                                    {channel
                                                                        ? truncate(channel, {
                                                                              length: 12,
                                                                          })
                                                                        : "All channels"}
                                                                </div>
                                                                <i className="ri-arrow-down-s-line text-lg text-[#142837]"></i>
                                                            </div>
                                                        }
                                                        dropdownItemsClasses="!px-0 !py-0"
                                                        dropdownClassName="!mb-10 !-mt-7"
                                                        dropdownContainerClasses={`shadow-cardShadow-4 border border-slate-100 right-0 rounded-[6px] !max-h-[200px] !w-[200px] !min-w-min
                            `}
                                                        name="valueRange"
                                                        dropdownOptions={[
                                                            ...channels?.map((item, idx) => ({
                                                                label: (
                                                                    <div
                                                                        key={idx}
                                                                        className=" text-gm-50 font-rocGroteskMedium text-xs flex justify-between
                                                border-b border-b-slate-100 !w-[159px] px-4 py-[10px] capitalize"
                                                                    >
                                                                        {item?.channel}
                                                                    </div>
                                                                ),
                                                                value: item?.channel,
                                                            })),
                                                            {
                                                                label: (
                                                                    <div
                                                                        className=" text-gm-50 font-rocGroteskMedium text-xs flex justify-between
                                                border-b border-b-slate-100 !w-[140px] px-4 py-[10px] capitalize"
                                                                    >
                                                                        All channels
                                                                    </div>
                                                                ),
                                                                value: "",
                                                            },
                                                        ]}
                                                    />
                                                </div>
                                                <div className="relative">
                                                    <Dropdown
                                                        handleChange={(name, values) => {
                                                            setDate(values?.split("-")[1]);
                                                            const value = values?.split("-")[0];
                                                            handleDateChange({
                                                                target: { name, value },
                                                            });
                                                        }}
                                                        dropdown={
                                                            <div className="flex items-center  justify-between rounded-[6px] shadow-buttonShadow2 p-[5px] gap-2 !border !border-slate-200 w-[120px]">
                                                                <div
                                                                    className={`text-slate-800 font-rocGroteskMedium  text-[13px] inline-block`}
                                                                >
                                                                    {date || "All products"}
                                                                </div>
                                                                <i className="ri-arrow-down-s-line text-lg text-[#142837]"></i>
                                                            </div>
                                                        }
                                                        dropdownItemsClasses="!px-0 !py-0"
                                                        dropdownClassName="!w-full !mb-10 !-mt-7"
                                                        dropdownContainerClasses={`shadow-cardShadow-4 border border-slate-100 !right-0 rounded-[6px] !max-h-fit !w-auto
                            `}
                                                        name="valueRange"
                                                        dropdownOptions={[
                                                            ...values?.map((item, idx) => ({
                                                                label: (
                                                                    <div
                                                                        key={idx}
                                                                        className=" text-gm-50 font-rocGroteskMedium text-xs flex justify-between
                                                border-b border-b-slate-100 !w-[159px] px-4 py-[10px]"
                                                                    >
                                                                        {item?.label}
                                                                    </div>
                                                                ),
                                                                value:
                                                                    item?.value + "-" + item?.label,
                                                            })),
                                                        ]}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="my-3">
                                            <div
                                                className={`${
                                                    batchIncidents?.length >= 1 && "pt-4 pb-6"
                                                }`}
                                            >
                                                {fetchingIncidents ? (
                                                    <div className="flex items-center h-[300px] w-full">
                                                        <Loader size={6} />
                                                    </div>
                                                ) : (
                                                    batchIncidents &&
                                                    (batchIncidents?.length === 0 ? (
                                                        <div className="flex items-center justify-center flex-col h-[300px]">
                                                            <i className="ri-file-list-3-line text-gm-40 text-2xl font-rocGroteskMedium mb-4"></i>
                                                            <p className="">No incidents found</p>
                                                            <p className="text-gm-40 text-base font-rocGroteskMedium max-w-[330px] text-center">
                                                                It seems like there are no incidents
                                                                to display at the moment.
                                                            </p>
                                                        </div>
                                                    ) : (
                                                        batchIncidents?.map((product, id) => {
                                                            return (
                                                                <div
                                                                    className="flex max-sm:flex-col max-sm:space-y-2 max-sm:items-start border-b border-slate-200 justify-between my-4"
                                                                    key={id}
                                                                >
                                                                    <div className="mb-[13px] w-[200px]">
                                                                        <p className="text-slate-600 text-[10px] ">
                                                                            {product?.sale?.channel}
                                                                        </p>

                                                                        <div className="flex max-w-[200px]">
                                                                            {getValues([
                                                                                product?.totalSales,
                                                                                product?.totalReturnStock,
                                                                                product?.totalDamagedStock,
                                                                            ]).map((value, idx) => {
                                                                                const width =
                                                                                    value > 6
                                                                                        ? value * 2
                                                                                        : value;
                                                                                return (
                                                                                    <div
                                                                                        className={` ${
                                                                                            getValues(
                                                                                                [
                                                                                                    product?.totalSales,
                                                                                                    product?.totalReturnStock,
                                                                                                    product?.totalDamagedStock,
                                                                                                ]
                                                                                            )
                                                                                                ?.length ===
                                                                                                idx +
                                                                                                    1 &&
                                                                                            "rounded-r-lg"
                                                                                        }  h-[26px] mb-1 flex justify-end items-center font-rocGroteskMedium pr-2 `}
                                                                                        key={idx}
                                                                                        style={{
                                                                                            width: `${width}px`,
                                                                                            backgroundColor: `${colors[idx]}`,
                                                                                        }}
                                                                                    ></div>
                                                                                );
                                                                            })}
                                                                        </div>
                                                                    </div>

                                                                    <div>
                                                                        {[
                                                                            product?.totalSales,
                                                                            product?.totalReturnStock,
                                                                            product?.totalDamagedStock,
                                                                        ]?.map((value, idx) => {
                                                                            return (
                                                                                <div
                                                                                    className={` ${
                                                                                        idx !== 2 &&
                                                                                        "border-r border-slate-200"
                                                                                    } px-2.5`}
                                                                                    key={idx}
                                                                                >
                                                                                    <div className="flex items-center gap-1 mb-2">
                                                                                        <p
                                                                                            className="h-2 w-2 rounded-full"
                                                                                            style={{
                                                                                                backgroundColor: `${colors[idx]}`,
                                                                                            }}
                                                                                        ></p>
                                                                                        <p className="text-[10px]">
                                                                                            {`${
                                                                                                idx ===
                                                                                                0
                                                                                                    ? "Good"
                                                                                                    : idx ===
                                                                                                      1
                                                                                                    ? "Recalled"
                                                                                                    : "Faulty"
                                                                                            } units`}
                                                                                        </p>
                                                                                    </div>
                                                                                    <p className="text-p-70 text-sm font-rocGroteskMedium text-left">
                                                                                        {value.toLocaleString()}
                                                                                    </p>
                                                                                </div>
                                                                            );
                                                                        })}
                                                                    </div>
                                                                </div>
                                                            );
                                                        })
                                                    ))
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </PageFrame>
    );
};

export default ProductTrackDetails;
