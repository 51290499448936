import { trackTraceTypes } from "../types";

const initialState = {
    fetchingTrackTraceCard: false,
    fetchTrackTraceCardSuccess: null,
    fetchTrackTraceCardFailure: null,
    fetchingFinishedGoods: false,
    fetchFinishedGoodsSuccess: null,
    fetchFinishedGoodsFailure: null,
    fetchingRawMaterials: false,
    fetchRawMaterialsSuccess: null,
    fetchRawMaterialsFailure: null,
    fetchingBatchDetails: false,
    fetchBatchDetailsSuccess: null,
    fetchBatchDetailsFailure: null,
    addingPreviouslySearched: false,
    addPreviouslySearchedSuccess: null,
    addPreviouslySearchedFailure: null,
    fetchingPreviouslySearched: false,
    fetchPreviouslySearchedSuccess: null,
    fetchPreviouslySearchedFailure: null,
    fetchingTrackSearchResult: false,
    fetchTrackSearchResultSuccess: null,
    fetchTrackSearchResultFailure: null,
    fetchingBatchIncidents: false,
    fetchBatchIncidentsSuccess: null,
    fetchBatchIncidentsFailure: null,
    fetchingBatchTracking: false,
    fetchBatchTrackingSuccess: null,
    fetchBatchTrackingFailure: null,
    fetchingBatchWarehouses: false,
    fetchBatchWarehousesSuccess: null,
    fetchBatchWarehousesFailure: null,
    fetchingBatchDistributors: false,
    fetchBatchDistributorsSuccess: null,
    fetchBatchDistributorsFailure: null,
    fetchingBatchCustomers: false,
    fetchBatchCustomersSuccess: null,
    fetchBatchCustomersFailure: null,
};

export const trackTrace = (state = initialState, action: { type: string; payload: any }) => {
    switch (action.type) {
        case trackTraceTypes.FETCH_TRACK_TRACE_CARD_REQUEST:
            return {
                ...state,
                fetchingTrackTraceCard: true,
                fetchTrackTraceCardSuccess: null,
                fetchTrackTraceCardFailure: null,
            };
        case trackTraceTypes.FETCH_TRACK_TRACE_CARD_SUCCESS:
            return {
                ...state,
                fetchingTrackTraceCard: false,
                fetchTrackTraceCardSuccess: action.payload,
                fetchTrackTraceCardFailure: null,
            };
        case trackTraceTypes.FETCH_TRACK_TRACE_CARD_FAILURE:
            return {
                ...state,
                fetchingTrackTraceCard: false,
                fetchTrackTraceCardSuccess: null,
                fetchTrackTraceCardFailure: action.payload,
            };
        case trackTraceTypes.FETCH_FINISHED_GOODS_REQUEST:
            return {
                ...state,
                fetchingFinishedGoods: true,
                fetchFinishedGoodsSuccess: null,
                fetchFinishedGoodsFailure: null,
            };
        case trackTraceTypes.FETCH_FINISHED_GOODS_SUCCESS:
            return {
                ...state,
                fetchingFinishedGoods: false,
                fetchFinishedGoodsSuccess: action.payload,
                fetchFinishedGoodsFailure: null,
            };
        case trackTraceTypes.FETCH_FINISHED_GOODS_FAILURE:
            return {
                ...state,
                fetchingFinishedGoods: false,
                fetchFinishedGoodsSuccess: null,
                fetchFinishedGoodsFailure: action.payload,
            };
        case trackTraceTypes.FETCH_RAW_MATERIALS_REQUEST:
            return {
                ...state,
                fetchingRawMaterials: true,
                fetchRawMaterialsSuccess: null,
                fetchRawMaterialsFailure: null,
            };
        case trackTraceTypes.FETCH_RAW_MATERIALS_SUCCESS:
            return {
                ...state,
                fetchingRawMaterials: false,
                fetchRawMaterialsSuccess: action.payload,
                fetchRawMaterialsFailure: null,
            };
        case trackTraceTypes.FETCH_RAW_MATERIALS_FAILURE:
            return {
                ...state,
                fetchingRawMaterials: false,
                fetchRawMaterialsSuccess: null,
                fetchRawMaterialsFailure: action.payload,
            };
        case trackTraceTypes.FETCH_BATCH_DETAILS_REQUEST:
            return {
                ...state,
                fetchingBatchDetails: true,
                fetchBatchDetailsSuccess: null,
                fetchBatchDetailsFailure: null,
            };
        case trackTraceTypes.FETCH_BATCH_DETAILS_SUCCESS:
            return {
                ...state,
                fetchingBatchDetails: false,
                fetchBatchDetailsSuccess: action.payload,
                fetchBatchDetailsFailure: null,
            };
        case trackTraceTypes.FETCH_BATCH_DETAILS_FAILURE:
            return {
                ...state,
                fetchingBatchDetails: false,
                fetchBatchDetailsSuccess: null,
                fetchBatchDetailsFailure: action.payload,
            };
        case trackTraceTypes.ADD_PREVIOUSLY_SEARCHED_REQUEST:
            return {
                ...state,
                addingPreviouslySearched: true,
                addPreviouslySearchedSuccess: null,
                addPreviouslySearchedFailure: null,
            };
        case trackTraceTypes.ADD_PREVIOUSLY_SEARCHED_SUCCESS:
            return {
                ...state,
                addingPreviouslySearched: false,
                addPreviouslySearchedSuccess: action.payload,
                addPreviouslySearchedFailure: null,
            };
        case trackTraceTypes.ADD_PREVIOUSLY_SEARCHED_FAILURE:
            return {
                ...state,
                addingPreviouslySearched: false,
                addPreviouslySearchedSuccess: null,
                addPreviouslySearchedFailure: action.payload,
            };
        case trackTraceTypes.FETCH_PREVIOUSLY_SEARCHED_REQUEST:
            return {
                ...state,
                fetchingPreviouslySearched: true,
                fetchPreviouslySearchedSuccess: null,
                fetchPreviouslySearchedFailure: null,
            };
        case trackTraceTypes.FETCH_PREVIOUSLY_SEARCHED_SUCCESS:
            return {
                ...state,
                fetchingPreviouslySearched: false,
                fetchPreviouslySearchedSuccess: action.payload,
                fetchPreviouslySearchedFailure: null,
            };
        case trackTraceTypes.FETCH_PREVIOUSLY_SEARCHED_FAILURE:
            return {
                ...state,
                fetchingPreviouslySearched: false,
                fetchPreviouslySearchedSuccess: null,
                fetchPreviouslySearchedFailure: action.payload,
            };
        case trackTraceTypes.FETCH_TRACK_SEARCH_RESULT_REQUEST:
            return {
                ...state,

                fetchingTrackSearchResult: true,
                fetchTrackSearchResultSuccess: null,
                fetchTrackSearchResultFailure: null,
            };
        case trackTraceTypes.FETCH_TRACK_SEARCH_RESULT_SUCCESS:
            return {
                ...state,
                fetchingTrackSearchResult: false,
                fetchTrackSearchResultSuccess: action.payload,
                fetchTrackSearchResultFailure: null,
            };
        case trackTraceTypes.FETCH_TRACK_SEARCH_RESULT_FAILURE:
            return {
                ...state,
                fetchingTrackSearchResult: false,
                fetchTrackSearchResultSuccess: null,
                fetchTrackSearchResultFailure: action.payload,
            };
        case trackTraceTypes.FETCH_BATCH_INCIDENTS_REQUEST:
            return {
                ...state,
                fetchingBatchIncidents: true,
                fetchBatchIncidentsSuccess: null,
                fetchBatchIncidentsFailure: null,
            };
        case trackTraceTypes.FETCH_BATCH_INCIDENTS_SUCCESS:
            return {
                ...state,
                fetchingBatchIncidents: false,
                fetchBatchIncidentsSuccess: action.payload,
                fetchBatchIncidentsFailure: null,
            };
        case trackTraceTypes.FETCH_BATCH_INCIDENTS_FAILURE:
            return {
                ...state,
                fetchingBatchIncidents: false,
                fetchBatchIncidentsSuccess: null,
                fetchBatchIncidentsFailure: action.payload,
            };
        case trackTraceTypes.FETCH_BATCH_TRACKING_REQUEST:
            return {
                ...state,
                fetchingBatchTracking: true,
                fetchBatchTrackingSuccess: null,
                fetchBatchTrackingFailure: null,
            };
        case trackTraceTypes.FETCH_BATCH_TRACKING_SUCCESS:
            return {
                ...state,
                fetchingBatchTracking: false,
                fetchBatchTrackingSuccess: action.payload,
                fetchBatchTrackingFailure: null,
            };
        case trackTraceTypes.FETCH_BATCH_TRACKING_FAILURE:
            return {
                ...state,
                fetchingBatchTracking: false,
                fetchBatchTrackingSuccess: null,
                fetchBatchTrackingFailure: action.payload,
            };
        case trackTraceTypes.FETCH_BATCH_WAREHOUSES_REQUEST:
            return {
                ...state,
                fetchingBatchWarehouses: true,
                fetchBatchWarehousesSuccess: null,
                fetchBatchWarehousesFailure: null,
            };
        case trackTraceTypes.FETCH_BATCH_WAREHOUSES_SUCCESS:
            return {
                ...state,
                fetchingBatchWarehouses: false,
                fetchBatchWarehousesSuccess: action.payload,
                fetchBatchWarehousesFailure: null,
            };
        case trackTraceTypes.FETCH_BATCH_WAREHOUSES_FAILURE:
            return {
                ...state,
                fetchingBatchWarehouses: false,
                fetchBatchWarehousesSuccess: null,
                fetchBatchWarehousesFailure: action.payload,
            };
        case trackTraceTypes.FETCH_BATCH_DISTRIBUTORS_REQUEST:
            return {
                ...state,
                fetchingBatchDistributors: true,
                fetchBatchDistributorsSuccess: null,
                fetchBatchDistributorsFailure: null,
            };
        case trackTraceTypes.FETCH_BATCH_DISTRIBUTORS_SUCCESS:
            return {
                ...state,
                fetchingBatchDistributors: false,
                fetchBatchDistributorsSuccess: action.payload,
                fetchBatchDistributorsFailure: null,
            };
        case trackTraceTypes.FETCH_BATCH_DISTRIBUTORS_FAILURE:
            return {
                ...state,
                fetchingBatchDistributors: false,
                fetchBatchDistributorsSuccess: null,
                fetchBatchDistributorsFailure: action.payload,
            };
        case trackTraceTypes.FETCH_BATCH_CUSTOMERS_REQUEST:
            return {
                ...state,
                fetchingBatchCustomers: true,
                fetchBatchCustomersSuccess: null,
                fetchBatchCustomersFailure: null,
            };
        case trackTraceTypes.FETCH_BATCH_CUSTOMERS_SUCCESS:
            return {
                ...state,
                fetchingBatchCustomers: false,
                fetchBatchCustomersSuccess: action.payload,
                fetchBatchCustomersFailure: null,
            };
        case trackTraceTypes.FETCH_BATCH_CUSTOMERS_FAILURE:
            return {
                ...state,
                fetchingBatchCustomers: false,
                fetchBatchCustomersSuccess: null,
                fetchBatchCustomersFailure: action.payload,
            };

        default:
            return state;
    }
};
