import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "components/common/Button";
import CustomTable from "components/common/CustomTable";
import Dropdown from "components/common/Dropdown";
import TextInput from "components/common/InputField/TextInput";
import ModalContainer from "components/common/ModalContainer";
import PageTitleSection from "components/common/PageTitleSection";
import PageFrame from "components/layout/PageFrame";
import { AnalyticsCard } from "components/views/Ldb/Dashboard/BusinessAnalytics/Card";
import FinishedGoods from "components/views/Synth2.0/TrackTrace/FinishedGoods";
import RawMaterial from "components/views/Synth2.0/TrackTrace/RawMaterials";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import useGetAllChannel from "hooks/useGetAllChannels";
import useGetAllWarehouses from "hooks/useGetAllWarehouses";
import useGetTrackTraceCard from "hooks/trackTraceHook/useGetTrackTraceCardHook";
import useGetTrackTraceFinishedGoods from "hooks/trackTraceHook/useGetTrackTraceFinishedGoods";
import useGetTrackTracePreviouslySearch from "hooks/trackTraceHook/useGetTrackTracePreviouslySearch";
import useInfiniteScroll from "hooks/useInfinteScroll";
import { truncate, uniqBy } from "lodash";
import moment from "moment";

import { trackTraceActions } from "redux/Ldb/actions";
import Loader from "components/common/Loader";
import CustomTableLoader from "components/common/CustomTableLoader";

const TrackAndTrace = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const limit = 10;
    const { handleDebouncedChange, handleSearch, search, setSearch, setDebouncedSearch } =
        useGetTrackTraceFinishedGoods();
    const { data: previouslySearch, isFetching: fecthingPreviouslySearch } =
        useGetTrackTracePreviouslySearch();
    const { data: warehouseData } = useGetAllWarehouses();
    const { data, isFetching: fetchingCards, channel, setChannel } = useGetTrackTraceCard();
    const { data: channels, isFetching: fetchingChannels } = useGetAllChannel();
    const [tabId, setTabId] = useState<number>(0);
    const values = [
        {
            label: "This year",
            value: "yearly",
        },
        {
            label: "This month",
            value: "monthly",
        },
        {
            label: "This week",
            value: "weekly",
        },

        {
            label: "Today",
            value: "daily",
        },
        {
            label: "All products",
            value: "all",
        },
    ];

    const [location, setLocation] = useState("");
    const [warehouse, setWarehouse] = useState<string>("");
    const [trackModal, setTrackModal] = useState<boolean>(false);
    const [searchResult, setSearchResult] = useState<boolean>(false);
    const [dateRange, setDateRange] = useState<string>("");
    const [trackSearch, setTrackSearch] = useState<string>("");
    const [trackSearchResult, setTrackSearchResult] = useState<{ [key: string]: any }[]>([]);

    const handleTrackSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        setTrackSearch(e.target.value);
    };
    const tabNames = [
        { id: 0, name: "Finished goods" },
        // { id: 1, name: "Raw materials" },
    ];

    const {
        fetchingTrackSearchResult,
        fetchTrackSearchResultSuccess,
        addPreviouslySearchedSuccess,
    } = useAppSelector((state) => state.trackTrace);

    const [pagination, setPagination] = useState<{ currentPage: string; noOfPages: string }>({
        currentPage: "1",
        noOfPages: "",
    });

    const { updatedPage, isLoadingMore, setLoadMore, setUpdatedPage } = useInfiniteScroll({
        current: pagination.currentPage,
        number_of_pages: pagination.noOfPages,
    });

    useEffect(() => {
        if (Boolean(fetchTrackSearchResultSuccess)) {
            setTrackSearchResult((prev) =>
                updatedPage > 1
                    ? uniqBy([...prev, ...fetchTrackSearchResultSuccess?.products], "_id")
                    : [...fetchTrackSearchResultSuccess?.products]
            );
            setSearchResult(true);
            setPagination({
                currentPage: fetchTrackSearchResultSuccess.pagination?.current,

                noOfPages: fetchTrackSearchResultSuccess.pagination?.number_of_pages,
            });
            setLoadMore(false);
        }
    }, [fetchTrackSearchResultSuccess]);
    const handleChangeValue = (e) => {
        const { value } = e.target;
        setDateRange(value);
    };
    const handleChannelChange = (e) => {
        const { value } = e.target;
        setChannel(value);
    };

    useEffect(() => {
        setLocation("");
        setWarehouse("");
        setDateRange("");
    }, [tabId]);

    useEffect(() => {
        setSearch("");
        setDebouncedSearch("");
    }, [trackModal]);

    useEffect(() => {
        if (trackSearch) {
            dispatch(trackTraceActions.getTrackSearchResult(limit, updatedPage, trackSearch));
        }
    }, [updatedPage]);

    // useEffect(() => {
    // }, [trackSearch]);

    // useEffect(() => {
    //     if (trackSearch) {
    //         dispatch(trackTraceActions.getTrackSearchResult(limit, updatedPage, trackSearch));
    //     }
    // }, [updatedPage]);

    // useEffect(() => {
    //     if (trackSearch) {
    //         dispatch(trackTraceActions.getTrackSearchResult(updatedLimit, trackSearch));
    //     }
    // }, [dispatch, updatedLimit]);

    // useEffect(() => {
    //     if (trackSearch) {
    //         dispatch(trackTraceActions.getTrackSearchResult(updatedLimit, trackSearch));
    //     }
    // }, [updatedLimit]);

    useEffect(() => {
        const handleKeyPress = (e) => {
            if (trackModal && e.key === "Enter" && trackSearch) {
                setUpdatedPage(1);
                dispatch(trackTraceActions.getTrackSearchResult(limit, updatedPage, trackSearch));
            }
        };
        document.addEventListener("keydown", handleKeyPress);

        return () => {
            document.removeEventListener("keydown", handleKeyPress);
        };
    }, [trackModal, trackSearch]);

    useEffect(() => {
        if (!trackModal) {
            setTrackSearchResult([]);
            setTrackSearch("");
            setSearchResult(false);
        }
    }, [trackModal]);

    useEffect(() => {
        const handleKeyPress = (e) => {
            if (trackModal && e.key === "Escape") {
                setTrackModal(false);
            }
        };
        document.addEventListener("keydown", handleKeyPress);

        return () => {
            document.removeEventListener("keydown", handleKeyPress);
        };
    }, [trackModal]);

    useEffect(() => {
        if (Boolean(addPreviouslySearchedSuccess)) {
            dispatch(trackTraceActions.resetAddPreviouslySearchedSuccess());
        }
    }, [addPreviouslySearchedSuccess]);

    useEffect(() => {}, [dispatch]);

    const handleAddPreviouslySearch = (batchId) => {
        const body = {
            inventoryStockMovementId: batchId,
            searchText: trackSearch,
        };
        dispatch(trackTraceActions.addPreviouslySearched(body));
    };

    const recentlySearchedTableHeader = [
        { title: `Batch/Lot No.`, widthClass: "w-[22%] " },
        { title: "Item", widthClass: "w-[24%]" },
        { title: "Item type", widthClass: "w-[23%]" },
        { title: "Status", widthClass: "w-[23%]" },
        { title: "Expiry Date", widthClass: "w-[20%]" },
        { title: "Customer", widthClass: "w-[30%]" },
    ];

    const searchResultTableBody = trackSearchResult?.map((product, idx) => [
        {
            content: (
                <div
                    key={idx}
                    className={`!font-rocGroteskMedium border-slate-100 text-sm text-slate-900  `}
                >
                    <p className="">{product?.batchId}</p>
                    <p className="text-slate-400 text-xs ">
                        {moment(product?.updatedAt).format("DD/MM/YYYY; h:mma")}
                    </p>
                </div>
            ),
            cellClickAction: () => {
                handleAddPreviouslySearch(product?._id);
                navigate(`/dashboard/track-trace/${product?.batchId}?productType=finished-good`);
            },
        },

        {
            content: (
                <div
                    className={`!font-rocGroteskMedium border-slate-100 text-sm text-slate-900  py-4`}
                >
                    <p className="">{product?.inventory?.productName}</p>
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`!font-rocGroteskMedium border-slate-100 text-sm text-slate-900  py-4`}
                >
                    <p
                        className={` ${
                            product?.status === "finished-goods"
                                ? "text-b-55 bg-lb-25"
                                : product?.status === "raw-materials"
                                ? "text-[#64748B] bg-[#E2E8F0] "
                                : "text-b-55 bg-lb-25"
                        } capitalize !font-rocGroteskMedium text-sm rounded-2xl py-0.5 px-2`}
                    >
                        Finished Goods
                    </p>
                </div>
            ),
        },
        {
            content: (
                <div className="py-4">
                    <p
                        className={` ${
                            product?.status === "fulfilled"
                                ? "text-g-60 bg-g-25"
                                : product?.status === "rejected"
                                ? "text-r-55 bg-r-25 "
                                : product?.status === "defect-detected"
                                ? "text-[#E2A400] bg-[rgba(255,185,3,0.15)]"
                                : "text-slate-900 bg-slate-200"
                        } capitalize !font-rocGroteskMedium text-sm rounded-2xl py-0.5 px-2`}
                    >
                        {product?.status === "defect-detected"
                            ? "Defect detected"
                            : product?.status === "in-progess"
                            ? "In progress"
                            : product?.status}
                    </p>
                </div>
            ),
        },

        {
            content: (
                <div
                    className={`!font-rocGroteskMedium border-slate-100 text-sm text-slate-900  py-4`}
                >
                    {product?.inventorystock?.productViabilityDetails?.expiryDate && (
                        <p className="">
                            {moment(
                                product?.inventorystock?.productViabilityDetails?.expiryDate
                            ).format("DD/MM/YYYY")}
                        </p>
                    )}
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`!font-rocGroteskMedium border-slate-100 text-sm text-slate-900  py-4`}
                >
                    {/* <div className="flex items-center gap-3"> */}
                    {/* <img
                            src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1712094307/sample_logo_rnigbf.svg"
                            alt="profile image"
                            className="w-[14px] h-[14px]"
                        /> */}
                    <span>{product?.customerDetails?.companyName}</span>
                    {/* </div> */}
                </div>
            ),
        },
    ]);

    const recentlySearchedTableBody = previouslySearch?.map((product, idx) => [
        {
            content: (
                <div
                    key={idx}
                    className={`!font-rocGroteskMedium border-slate-100 text-sm text-slate-900  `}
                >
                    <p className="">{product?.inventoryStockMovement?.batchId}</p>
                    <p className="text-slate-400 text-xs ">
                        {moment(product?.updatedAt).format("DD/MM/YYYY; h:mma")}
                    </p>
                </div>
            ),
            cellClickAction: () => {
                navigate(
                    `/dashboard/track-trace/${product?.inventoryStockMovement?.batchId}?productType=raw-material`
                );
            },
        },

        {
            content: (
                <div
                    className={`!font-rocGroteskMedium border-slate-100 text-sm text-slate-900  py-4`}
                >
                    <p className="">{product?.inventoryStockMovement?.inventory?.productName}</p>
                </div>
            ),
        },

        {
            content: (
                <div
                    className={`!font-rocGroteskMedium border-slate-100 text-sm text-slate-900  py-4`}
                >
                    <p
                        className={` ${
                            product?.status === "finished-goods"
                                ? "text-b-55 bg-lb-25"
                                : product?.status === "raw-materials"
                                ? "text-[#64748B] bg-[#E2E8F0] "
                                : "text-b-55 bg-lb-25"
                        } capitalize !font-rocGroteskMedium text-sm rounded-2xl py-0.5 px-2`}
                    >
                        Finished Goods
                    </p>
                </div>
            ),
        },
        {
            content: (
                <div className="py-4">
                    <p
                        className={` ${
                            product?.inventoryStockMovement?.status === "fulfilled"
                                ? "text-g-60 bg-g-25"
                                : product?.inventoryStockMovement?.status === "rejected"
                                ? "text-r-55 bg-r-25 "
                                : product?.inventoryStockMovement?.status === "defect-detected"
                                ? "text-[#E2A400] bg-[rgba(255,185,3,0.15)]"
                                : "text-slate-900 bg-slate-200"
                        } capitalize !font-rocGroteskMedium text-sm rounded-2xl py-0.5 px-2`}
                    >
                        {product?.inventoryStockMovement?.status === "defect-detected"
                            ? "Defect detected"
                            : product?.inventoryStockMovement?.status === "in-progess"
                            ? "In progress"
                            : product?.inventoryStockMovement?.status}
                    </p>
                </div>
            ),
        },

        {
            content: (
                <div
                    className={`!font-rocGroteskMedium border-slate-100 text-sm text-slate-900  py-4`}
                >
                    {product?.inventoryStock?.productViabilityDetails?.expiryDate && (
                        <p className="">
                            {moment(
                                product?.inventoryStock?.productViabilityDetails?.expiryDate
                            ).format("DD/MM/YYYY")}
                        </p>
                    )}
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`!font-rocGroteskMedium border-slate-100 text-sm text-slate-900  py-4`}
                >
                    <span>{product?.inventoryStockMovement?.customerDetails?.companyName}</span>
                </div>
            ),
        },
    ]);

    return (
        <PageFrame isLoading={fetchingChannels}>
            <div className="p-4 border border-slate-100 rounded-[8px] bg-white">
                <div className="mb-6">
                    <PageTitleSection
                        leftComp={
                            <div className="flex items-center space-x-3">
                                <h3 className="text-xl font-rocGroteskMedium">Track And Trace</h3>
                            </div>
                        }
                    />
                </div>
                {/* <div className="bg-slate-50 !w-fit p-1 flex gap-2 font-rocGroteskMedium font-sm text-slate-900 mb-8"></div> */}
                <div className="">
                    <div>
                        <div className="flex justify-between">
                            <div className="flex gap-[12px] items-center mb-10">
                                <p className="text-base text-slate-900  font-rocGroteskMedium mr-4">
                                    All Products
                                </p>
                            </div>

                            <div className="">
                                <Button
                                    btnText={"Track an item"}
                                    onClick={() => {
                                        setTrackModal(true);
                                    }}
                                    type={"button"}
                                    btnClassname={"!w-auto !px-4 !py-2"}
                                    icon={<i className="ri-add-fill text-black text-[20px]"></i>}
                                />
                            </div>
                        </div>
                        <div className="mb-14 flex max-lg:grid max-lg:grid-cols-2 max-lg:gap-x-3 border-b !border-b-slate-200">
                            <AnalyticsCard
                                title={"Total raw materials"}
                                value={data?.totalRawMaterials}
                                addComma={true}
                                loader={fetchingCards}
                                iconTwo
                                className="!shadow-none !border-y-0 !border-l-0 !border-r !border-r-slate-200 !pr-[45px] max-lg:!pr-2.5 !py-4 !pl-[10px] !rounded-none"
                                titleClassName="!text-slate-950 !text-[13px]"
                                innerClassName="mb-4"
                            />
                            <AnalyticsCard
                                title={"Total finished goods"}
                                value={data?.totalFinishedGoods}
                                filterDuration={`this month`}
                                percentageValue={Math.abs(data?.totalFinishedGoodsPercentageChange)}
                                increase={
                                    data?.totalFinishedGoodsPercentageChange >= 0 ? true : false
                                }
                                loader={fetchingCards}
                                iconTwo
                                className="!shadow-none !border-y-0 !border-l-0 !border-r !border-r-slate-200 max-lg:border-none !pr-[45px] max-lg:!pr-2.5 !py-4 !pl-[10px] !rounded-none"
                                titleClassName="!text-slate-950"
                                showImg={true}
                                innerClassName="mb-4"
                                addComma={true}
                            />
                            <AnalyticsCard
                                title={"Expired goods"}
                                value={data?.totalExpiredProducts}
                                filterDuration={`this month`}
                                percentageValue={Math.abs(
                                    data?.totalExpiredProductsPercentageChange
                                )}
                                increase={true}
                                loader={fetchingCards}
                                iconTwo
                                className="!shadow-none !border-y-0 !border-l-0 !border-r !border-r-slate-200 !pr-[45px] max-lg:!pr-2.5 !py-4 !pl-[10px] !rounded-none"
                                titleClassName="!text-slate-950"
                                showImg={true}
                                innerClassName="mb-4"
                                addComma={true}
                            />
                            <AnalyticsCard
                                title={"Recalled products"}
                                value={data?.totalRecalledProducts}
                                filterDuration={`this month`}
                                percentageValue={Math.abs(
                                    data?.totalRecalledProductsPercentageChange
                                )}
                                addComma={true}
                                increase={true}
                                loader={fetchingCards}
                                iconTwo
                                className="!shadow-none !border-none !rounded-none"
                                titleClassName="!text-slate-950"
                                showImg={true}
                                innerClassName="mb-4"
                                customDropdown={
                                    <div className="relative ">
                                        <Dropdown
                                            handleChange={(name, value) =>
                                                handleChannelChange({ target: { name, value } })
                                            }
                                            dropdown={
                                                <div className="flex items-center justify-between rounded-[5px] border border-slate-200 py-1 px-2 gap-1 ">
                                                    <div
                                                        className={`text-slate-600 font-rocGroteskMedium text-xs inline-block capitalize`}
                                                    >
                                                        {channel || "All Channels"}
                                                    </div>
                                                    <i className="ri-arrow-down-s-line text-lg text-slate-600"></i>
                                                </div>
                                            }
                                            dropdownItemsClasses="!px-0 !py-0"
                                            dropdownClassName="!w-full !mb-10 !-mt-7"
                                            dropdownContainerClasses={`shadow-cardShadow-4 border border-slate-100  !right-0 rounded-[6px] !max-h-fit !w-auto
                            `}
                                            name="valueRange"
                                            dropdownOptions={[
                                                ...channels?.map((item, idx) => ({
                                                    label: (
                                                        <div
                                                            key={idx}
                                                            className=" text-gm-50 font-rocGroteskMedium text-xs flex justify-between
                                                border-b border-b-slate-100 !w-[140px] px-4 py-[10px] capitalize"
                                                        >
                                                            {item?.channel}
                                                        </div>
                                                    ),
                                                    value: item?.channel,
                                                })),
                                                {
                                                    label: (
                                                        <div
                                                            className=" text-gm-50 font-rocGroteskMedium text-xs flex justify-between
                                                border-b border-b-slate-100 !w-[140px] px-4 py-[10px] capitalize"
                                                        >
                                                            All Channels
                                                        </div>
                                                    ),
                                                    value: "",
                                                },
                                            ]}
                                        />
                                    </div>
                                }
                            />
                        </div>
                    </div>

                    <div className="">
                        <p className="text-base text-slate-900  font-rocGroteskMedium mr-4 mb-8">
                            Traceability items
                        </p>

                        <div className="flex justify-between max-lg:flex-col max-lg:items-start  items-center mb-8">
                            <div className="bg-slate-50 max-lg:mb-3 !w-fit p-1 flex gap-2 font-rocGroteskMedium font-sm text-slate-900 ">
                                {tabNames?.map((tab, idx) => {
                                    return (
                                        <button
                                            className={`w-auto  py-[7px] px-4  text-sm ${
                                                tabId === idx &&
                                                "!bg-white rounded-[6px] shadow-buttonShadow"
                                            }`}
                                            key={idx}
                                            onClick={() => setTabId(idx)}
                                        >
                                            {tab?.name}
                                        </button>
                                    );
                                })}
                            </div>

                            <div className="flex gap-2 items-center max-sm:flex-wrap">
                                <div className="relative">
                                    <TextInput
                                        name="search"
                                        type="text"
                                        value={search}
                                        onChange={handleSearch}
                                        onInput={handleDebouncedChange}
                                        inputPlaceholder="Search product by batch number"
                                        inputClassName={
                                            "!h-[40px] pl-[0px] !mb-0 !font-rocGroteskMedium !text-slate-400"
                                        }
                                        leftIcon={
                                            <i className="ri-search-line before:content-['\f0d1] text-slate-400 mt-[2px]"></i>
                                        }
                                        inputContainerClassname={
                                            "!rounded-[4px] !w-[300px] !h-[40px] !pl-[40px] !bg-white shadow-buttonShadow2 !border !border-n-20"
                                        }
                                    />
                                </div>

                                <div className="relative">
                                    <Dropdown
                                        handleChange={(name, value) =>
                                            handleChangeValue({ target: { name, value } })
                                        }
                                        dropdown={
                                            <div className="flex items-center rounded-[6px] shadow-buttonShadow2 px-2.5 py-[5px] gap-2 !border !border-slate-100">
                                                <i className="ri-arrow-down-s-line text-lg text-[#142837]"></i>
                                                <div
                                                    className={`text-slate-800 font-rocGroteskMedium text-[13px] inline-block`}
                                                >
                                                    {dateRange?.split("-")[1] || "All products"}
                                                </div>
                                            </div>
                                        }
                                        dropdownItemsClasses="!px-0 !py-0"
                                        dropdownClassName="!w-full !mb-10 !-mt-7"
                                        dropdownContainerClasses={`shadow-cardShadow-4 border border-slate-100 right-0 left-0 rounded-[6px] !max-h-fit !w-auto
                            `}
                                        name="valueRange"
                                        dropdownOptions={[
                                            ...values?.map((item, idx) => ({
                                                label: (
                                                    <div
                                                        key={idx}
                                                        className=" text-gm-50 font-rocGroteskMedium text-xs flex justify-between
                                                border-b border-b-slate-100 !w-[159px] px-4 py-[10px]"
                                                    >
                                                        {item?.label}
                                                    </div>
                                                ),
                                                value: item?.value + "-" + item?.label,
                                            })),
                                        ]}
                                    />
                                </div>

                                <div className="relative">
                                    <Dropdown
                                        name="location"
                                        value={""}
                                        dropdownTriggerClassName={" "}
                                        handleChange={(name, value) => {
                                            setLocation(value?.split("-")?.[1]);
                                            setWarehouse(value?.split("-")?.[0]);
                                        }}
                                        dropdown={
                                            <div className="flex items-center rounded-[6px] shadow-buttonShadow2 px-2.5 py-[5px] gap-2 !border !border-slate-100">
                                                <i className="ri-arrow-down-s-line text-lg text-[#142837]"></i>
                                                <span className="text-[13px] whitespace-nowrap font-rocGroteskMedium">
                                                    {truncate(warehouse, { length: 12 }) ||
                                                        "All Outlets"}
                                                </span>
                                                {Boolean(warehouse) && (
                                                    <i
                                                        onClick={(e) => {
                                                            e?.stopPropagation();
                                                            setLocation("");
                                                            setWarehouse("");
                                                        }}
                                                        className="ri-close-circle-fill text-sm text-slate-500"
                                                    ></i>
                                                )}
                                            </div>
                                        }
                                        dropdownClassName={"!mb-10 !-mt-7"}
                                        dropdownContainerClasses={`shadow-cardShadow-4 right-0 !mt-0 !min-w-min !w-[250px] rounded !p-0 !top-[105%] !max-h-[290px] overflow-y-auto`}
                                        dropdownItemsClasses="!bg-transparent border-b-[1px] border-b-slate-100"
                                        dropdownOptions={warehouseData?.map((warehouse) => ({
                                            label: warehouse?.warehouseName,
                                            value: warehouse?.warehouseName + "-" + warehouse?._id,
                                        }))}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="">
                            {tabId === 0 ? (
                                <FinishedGoods
                                    dateRange={dateRange.split("-")[0]}
                                    warehouseId={location}
                                    search={search}
                                />
                            ) : (
                                <RawMaterial
                                    dateRange={dateRange.split("-")[0]}
                                    warehouseId={location}
                                />
                            )}
                        </div>
                    </div>
                    <ModalContainer
                        modalClassName="!bg-[rgba(217,217,217,0.3) backdrop-blur-sm transition ease-in-out delay-150 duration-300"
                        open={trackModal}
                        closeModal={() => setTrackModal(false)}
                        tailwindClassName="w-[90%] lg:w-[1000px]"
                    >
                        <div
                            className={` bg-white rounded-[20px]  h-[60vh] overflow-y-scroll`}
                            id="modalContent"
                        >
                            <div className="mx-4 mb-4 relative">
                                <div className="sticky top-0 bg-white z-[9999] ">
                                    <div className="py-4">
                                        <div className="px-6  shadow-boxShadow-3 bg-white rounded-[30px] flex items-center justify-between border border-slate-100">
                                            <div className="w-[70%]">
                                                <TextInput
                                                    name="trackSearch"
                                                    type="text"
                                                    value={trackSearch}
                                                    onChange={handleTrackSearch}
                                                    inputPlaceholder="Search for a product or a batch/lot number"
                                                    inputClassName={
                                                        "!h-fit pl-[0px] !mb-0 !font-rocGroteskMedium !text-slate-400"
                                                    }
                                                    inputContainerClassname={
                                                        " !bg-white !border-none !h-auto !px-0 !py-0"
                                                    }
                                                />
                                            </div>
                                            <div className="flex items-center gap-2 w-[30%] justify-end">
                                                <p className="text-black bg-slate-50 rounded border border-slate-100 px-2 py-0.5 text-[10px]">
                                                    Press Enter to search
                                                </p>
                                                <p className="text-black bg-slate-50 rounded border border-slate-100 px-2 py-0.5 text-[10px]">
                                                    Esc to exit
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="">
                                    <p className="text-slate-700 text-sm font-rocGroteskMedium my-4">
                                        {searchResult
                                            ? "Search Results"
                                            : "Recently searched items"}
                                    </p>
                                    {(fecthingPreviouslySearch || fetchingTrackSearchResult) &&
                                    !isLoadingMore ? (
                                        <CustomTableLoader
                                            tableHeader={recentlySearchedTableHeader}
                                        />
                                    ) : recentlySearchedTableBody?.length > 0 ||
                                      searchResultTableBody?.length > 0 ? (
                                        <div className="overflow-x-scroll mb-6 overflow-y-scroll">
                                            <CustomTable
                                                tableBody={
                                                    searchResult
                                                        ? searchResultTableBody
                                                        : recentlySearchedTableBody
                                                }
                                                tableHeader={recentlySearchedTableHeader}
                                                isScrollable={false}
                                                isCellBordered={false}
                                                isCellSelectable={false}
                                                isAllSelectable={false}
                                                headerContainerClass="!bg-slate-50"
                                                headerItemClass="font-rocGroteskMedium !text-xs !text-slate-700"
                                                tableClass="!border !border-slate-200"
                                                bodyItemClass="!border-slate-200"
                                            />
                                            {searchResult && isLoadingMore && (
                                                <div className="flex justify-center my-4">
                                                    <Loader color="gm-25" size={4} />
                                                </div>
                                            )}
                                            {pagination?.currentPage === pagination?.noOfPages && (
                                                <div className="flex my-4 justify-center text-slate-500 text-sm font-rocGroteskMedium">
                                                    End of list
                                                </div>
                                            )}
                                        </div>
                                    ) : (
                                        <div className="flex items-center justify-center my-12">
                                            <p className="text-slate-700 font-rocGroteskMedium text-[14px]">
                                                No Data available
                                            </p>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </ModalContainer>
                </div>
            </div>
        </PageFrame>
    );
};

export default TrackAndTrace;
