import React from "react";
import { useNavigate } from "react-router-dom";
import SalesOrderDetails from "components/views/Synth2.0/Order/SalesOrderDetails";

type OrderPreviewProps = {
    setIsPreview: React.Dispatch<React.SetStateAction<boolean>>;
};

const OrderPreview = ({ setIsPreview }: OrderPreviewProps) => {
    const navigate = useNavigate();

    return (
        <SalesOrderDetails
            onClickOne={() => setIsPreview(false)}
            onClickTwo={() => {}}
            title="Preview"
            channelImg=""
            channel="Synth"
            orderDate="Jan 24, 2024"
            customerPO="1234"
            deliveryDate="Jan 24, 2024"
            currency="USD"
            subTotal="$2500"
            discount="5%"
            total="$2500"
            customerName="Iroh Omolola"
            haulage="FEDex"
            email="omolola@synth.com"
            thirdParty="Amazon"
            phone="0904567894"
            billAddress="USA, Chicago, Illinois, 123 Block  City"
        />
    );
};

export default OrderPreview;
