import React, { useEffect, useRef, useState } from "react";
import PageTitleSection from "components/common/PageTitleSection";
import { unstable_HistoryRouter, useLocation, useNavigate, useParams } from "react-router-dom";
import TextInput from "components/common/InputField/TextInput";
import Button from "components/common/Button";
import Loader from "components/common/Loader";
import SelectInput from "components/common/InputField/SelectInput";
import { debounce } from "lodash";
import countries from "variables/countries";
import ReactCountryFlag from "react-country-flag";
import AddressInput from "components/common/InputField/AddressInput";
import CustomTable from "components/common/CustomTable";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import { documentActions, inventoryActions, vendorActions } from "redux/Ldb/actions";
import { firebaseService } from "services/firebaseService";
import toast from "react-hot-toast";
import CustomToast from "components/common/CustomToast";
import useGetUserProfile from "hooks/useGetUserProfile";
import { supplierCatgories } from "variables/supplierCategories";
import ModalContainer from "components/common/ModalContainer";
import PageFrame from "components/layout/PageFrame";
import { paymentTerms } from "variables/paymentTerms";
import { getCurrencyFromCurrencyCode } from "helpers";

const CreateSuppliers = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { supplierId } = useParams();
    const [profile] = useGetUserProfile();
    const [address, setAddress] = useState<{ [key: string]: any }>({});
    const [selectedFile, setSelectedFile] = useState("");
    const [successModal, setSuccessModal] = useState<boolean>(false);
    const [uploading, setUploading] = useState<string>("");
    const [supplierDetails, setSupplierDetails] = useState<{ [key: string]: any }>({
        mobileExtension: "+1",
    });
    const [supplierInfo, setSupplierInfo] = useState<{ [key: string]: any }>({});
    const [bankDetails, setBankDetails] = useState<{ [key: string]: any }>({
        bankCountry: "United States",
        currency: "USD",
    });
    const [contractDetails, setContractDetails] = useState<{ [key: string]: any }>({});
    const [addressDetails, setAddressDetails] = useState<{ [key: string]: any }>({});
    const [docUploading, setDocUploading] = useState<boolean>(false);
    const [resetAddress, setResetAddress] = useState<boolean>(false);
    const [online, setOnline] = useState<boolean>(false);
    const [productList, setProductList] = useState<{ [key: string]: any }>([]);
    const [productData, setProductData] = useState<{ [key: string]: any }[]>([]);
    const [addPaymentOption, setAddPaymentOption] = useState(false);
    const [newPaymentOption, setNewPaymentOption] = useState<string>("");
    const [paymentTermsData, setPaymentTermsData] = useState<string[]>([]);
    const [customIndex, setCustomIndex] = useState<number>();
    const [productCurrency, setProductCurrency] = useState<string[]>([]);
    const [debouncedSearch, setDebouncedSearch] = useState<string>("");
    const {
        addingVendor,
        addVendorSuccess,
        updatingVendor,
        updateVendorSuccess,
        fetchSupplierDetailsSuccess,
        fetchingSupplierDetails,
    } = useAppSelector((state) => state?.vendor);
    const {
        fetchingInventoryList,
        fetchedInventoryListSuccess,
        fetchedInventorySearchListSuccess,
    } = useAppSelector((state) => state?.inventory);

    useEffect(() => {
        if (Boolean(fetchedInventoryListSuccess)) {
            setProductList(fetchedInventoryListSuccess?.inventory?.data);
        }
    }, [fetchedInventoryListSuccess]);
    useEffect(() => {
        if (Boolean(fetchedInventorySearchListSuccess)) {
            setProductList(fetchedInventorySearchListSuccess.inventory?.data);
        }
    }, [fetchedInventorySearchListSuccess]);
    useEffect(() => {
        dispatch(inventoryActions.fetchInventoryList(20, 1, debouncedSearch));
    }, [dispatch, debouncedSearch]);

    useEffect(() => {
        if (supplierId) {
            dispatch(vendorActions?.fetchSupplierDetails(supplierId));
        }
    }, [dispatch, supplierId]);
    console.log("supplierInfo", supplierInfo);
    useEffect(() => {
        if (supplierId && supplierInfo) {
            setSupplierDetails((prev) => ({
                ...prev,
                logo: supplierInfo?.vendor?.profile?.logo,
                businessName: supplierInfo?.vendor?.profile?.businessName,
                email: supplierInfo?.vendor?.email,
                mobileExtension: supplierInfo?.vendor?.mobileExtension,
                mobile: supplierInfo?.vendor?.mobile,
                businessCategory: supplierInfo?.vendor?.profile?.businessCategory,
                firstName: supplierInfo?.vendor?.firstName,
                lastName: supplierInfo?.vendor?.lastName,
                source: supplierInfo?.vendor?.source,
                website: supplierInfo?.vendor?.profile?.website,
            }));
            setOnline(supplierInfo?.vendor?.source === "offline" ? false : true);
            setAddress((prev) => ({
                ...prev,
                contactAddress: {
                    address: supplierInfo?.vendor?.profile?.address?.address,
                    lat: supplierInfo?.vendor?.profile?.address?.lat,
                    lng: supplierInfo?.vendor?.profile?.address?.lng,
                    country: supplierInfo?.vendor?.profile?.address?.country,
                    city: supplierInfo?.vendor?.profile?.address?.city,
                },
            }));

            const product = supplierInfo?.contract?.[0]?.products?.map((item, idx) => ({
                inventoryId: item?.inventoryId,
                productName: item?.productName,
                unitPrice: item?.unitPrice,
                productCategory: item?.productCategory,
                currency: item?.currency,
                leadTime: {
                    duration: item?.leadTime?.duration,
                    value: item?.leadTime?.value,
                },
                discount: item?.discount,
                paymentTerms: item?.paymentTerms,
            }));
            if (product?.length >= 1) {
                setProductData(product);
            }
        }
    }, [supplierId, supplierInfo, addVendorSuccess]);
    useEffect(() => {
        if (Boolean(fetchSupplierDetailsSuccess)) {
            setSupplierInfo(fetchSupplierDetailsSuccess);
        }
    }, [fetchSupplierDetailsSuccess]);

    const handleSupplierDetails = (e) => {
        const { name, value } = e.target;
        setSupplierDetails((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const areAllValuesEmpty = (obj) => {
        return Object.values(obj).every((value) => {
            if (typeof value === "object" && value !== null) {
                // Recursively check nested objects
                return areAllValuesEmpty(value);
            }
            return value === "";
        });
    };

    // Check if all objects in the array have only empty string values
    const allObjectsEmpty = productData?.every((obj) => areAllValuesEmpty(obj));

    const handleBankDetails = (e) => {
        const { name, value } = e.target;

        setBankDetails((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    useEffect(() => {
        if (address?.contactAddress?.address) {
            const cityCountry = address?.contactAddress?.address?.split(",");
            setAddressDetails({
                city: cityCountry[cityCountry?.length - 2],
                country: cityCountry[cityCountry?.length - 1],
            });
        }
    }, [address]);

    const handleImageRemoval = (name) => {
        const url = name === "logo" ? supplierDetails?.logo : supplierDetails?.avatar;
        const imagePath = decodeURIComponent(url?.split("/o/")?.[1]?.split("?alt")?.[0]);
        firebaseService.removeFile(imagePath);

        setSupplierDetails((prevData) => ({
            ...prevData,
            ...(name === "logo" ? { logo: "" } : { avatar: "" }),
        }));
    };

    const handleImageUpload = (fileName, file, oldUrl, name) => {
        setUploading(name);

        firebaseService.uploadFile(
            `synthally/${process.env.REACT_APP_STAGE}/businessLogo/${fileName}`,
            file,
            (url) => {
                setSupplierDetails((prevData) => ({
                    ...prevData,
                    ...(name === "logo" ? { logo: url } : { avatar: url }),
                }));
                if (url && oldUrl) {
                    const imagePath = decodeURIComponent(
                        oldUrl?.split("/o/")?.[1]?.split("?alt")?.[0]
                    );
                    firebaseService.removeFile(imagePath);
                }
                setUploading("");
            }
        );
    };

    const handleUploadClick = (e) => {
        e.stopPropagation();
        const fileInput = document.getElementById("fileInput");
        fileInput.click();
    };
    const validateDocument = async (fileName, file, url, docName, expiryDate, oldUrl) => {
        try {
            const responseData = await dispatch(
                documentActions?.validateDocument({
                    document_url: url,
                    document_type: docName,
                })
            );

            if (responseData?.data?.doc_type) {
                setContractDetails((prev) => ({
                    ...prev,
                    path: url,
                    ...(responseData?.data?.expiry_date &&
                        expiryDate && {
                            expiryDate: new Date(responseData?.data?.expiry_date).toISOString(),
                        }),
                }));

                if (url && oldUrl) {
                    const imagePath = decodeURIComponent(
                        oldUrl?.split("/o/")?.[1]?.split("?alt")?.[0]
                    );
                    firebaseService.removeFile(imagePath);
                }
            } else if (!responseData?.data?.doc_type) {
                const imagePath = decodeURIComponent(url?.split("/o/")?.[1]?.split("?alt")?.[0]);
                firebaseService.removeFile(imagePath);
                toast.custom((t) => (
                    <CustomToast t={t} message={"Invalid document upload"} type="error" />
                ));
            }
        } catch (error) {
            const imagePath = decodeURIComponent(url?.split("/o/")?.[1]?.split("?alt")?.[0]);
            firebaseService.removeFile(imagePath);
        }
    };
    const handleContractUpload = async (fileName, file, docName, expiryDate, oldUrl) => {
        setDocUploading(true);
        let url;
        try {
            url = await new Promise((resolve) => {
                firebaseService.uploadFile(
                    `synthally/${process.env.REACT_APP_STAGE ?? "staging"}/businessDocs/${
                        supplierDetails?.businessName ||
                        profile?.profile?.businessName ||
                        profile?.email
                    }/${fileName}`,
                    file,
                    (url) => resolve(url)
                );
            });

            await validateDocument(fileName, file, url, docName, expiryDate, oldUrl);
        } catch (error) {
            toast.custom((t) => (
                <CustomToast t={t} message={"Document Upload failed"} type="error" />
            ));
        } finally {
            setDocUploading(false);
        }
    };

    const handleAddVendor = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const { businessName, email, mobile, mobileExtension, ...restOfSupplierData } =
            supplierDetails;
        const {
            email: businessEmail,
            mobile: businessPhone,
            mobileExtension: phoneExt,
            firstName,
            lastName,
            ...supplierData
        } = supplierDetails;
        const { website, ...userSupplierData } = supplierDetails;

        let body: { [key: string]: any } = {
            ...(supplierId && online
                ? restOfSupplierData
                : !supplierId && online
                ? supplierData
                : userSupplierData),
            source: online ? "online" : "offline",
            address: {
                lat: address?.contactAddress?.lat,
                lng: address?.contactAddress?.lng,
                address: address?.contactAddress?.address,
                country: addressDetails?.country?.trim(),
                city: addressDetails?.city?.trim() || addressDetails?.country?.trim(),
            },
            products: allObjectsEmpty ? [] : productData,
        };

        if (online) {
            if (supplierData?.firstName && supplierData?.lastName) {
                body = {
                    ...body,
                    firstName: supplierData?.firstName,
                    lastName: supplierData?.lastName,
                };
            }
        }
        console.log("body", body);
        if (supplierId) {
            if (online && !address?.contactAddress?.lat) {
                const { address, ...others } = body;
                dispatch(vendorActions.updateVendor(others, supplierId));
            } else {
                dispatch(vendorActions.updateVendor(body, supplierId));
            }
        } else {
            if (online && !address?.contactAddress?.lat) {
                const { address, ...others } = body;
                dispatch(vendorActions.addVendor(others));
            } else {
                dispatch(vendorActions.addVendor(body));
            }
        }
    };

    useEffect(() => {
        if (Boolean(updateVendorSuccess) && supplierInfo) {
            dispatch(vendorActions?.resetUpdateVendorSuccess());
            setBankDetails({});
            setSupplierDetails({});
            setAddress({});
            setAddressDetails({});
            setContractDetails({});
            setResetAddress(true);
            setSuccessModal(true);
            setProductData([{}]);
        }
    }, [updateVendorSuccess]);

    useEffect(() => {
        if (Boolean(addVendorSuccess)) {
            dispatch(vendorActions?.resetAddVendorSuccess());
            setBankDetails({});
            setSupplierDetails({});
            setAddress({});
            setProductData([{}]);
            setAddressDetails({});
            setContractDetails({});
            setResetAddress(true);
            setSuccessModal(true);
        }
    }, [addVendorSuccess]);

    const tableHeader = [
        { title: "Contract name", widthClass: "w-[20%]" },
        { title: "Exp. Date", widthClass: "w-[10%]" },
        { title: "Renewal Date", widthClass: "w-[10%]" },
    ];

    const tableBody = [1]?.map((po, idx) => [
        {
            content: (
                <div
                    className={`py-3 pr-3 flex w-full items-center justify-between font-rocGroteskMedium text-sm text-slate-800 `}
                >
                    <span className="text-base text-[#64748B] font-rocGroteskRegular">
                        {/* {contractDetails?.path ?
                            (
                       displayFileIcon(
                                                                                    fileNames[
                                                                                        docu?.name
                                                                                    ]?.type,
                                                                                    null,
                                                                                    "!w-6 !h-6"
                                                                                )}
                                                                            <span className="text-sm">
                                                                                {docu?.name}
                                                                            </span>
                      )  : "Upload your contract"} */}
                    </span>
                    <div className="relative group    flex justify-center items-center">
                        <input
                            className="relative z-10 opacity-0  "
                            type="file"
                            accept="pdf/*"
                            name="contract"
                            id="contract-doc"
                            onChange={(evt) => {
                                handleContractUpload(
                                    evt.target.files[0].name,
                                    evt.target.files[0],
                                    "spend insight",
                                    true,
                                    contractDetails?.path
                                );
                            }}
                        />
                        {/* fileName, file, docName, expiryDate, change, oldUrl */}
                        <div className="absolute top-0 right-0 cursor-pointer">
                            <div className="flex items-center justify-center p-1.5  space-x-2">
                                <div
                                    className="flex items-center space-x-1"
                                    onClick={handleUploadClick}
                                >
                                    {contractDetails?.path ? (
                                        <i className="ri-check-double-fill text-carribean-green"></i>
                                    ) : (
                                        <i className="ri-upload-cloud-line text-b-55"></i>
                                    )}
                                    <p className="text-sm text-[#003881] underline">
                                        {contractDetails?.path ? "Change" : "Upload"}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ),
        },
        {
            content: (
                <div className={`py-3 pr-3 font-rocGroteskMedium text-sm`}>
                    <span className="text-sm"> {contractDetails?.expiryDate || "---"} </span>
                </div>
            ),
        },
        {
            content: (
                <div className={`py-3 pr-3 font-rocGroteskMedium text-sm text-slate-500 `}>
                    <span className="text-sm">---</span>
                </div>
            ),
        },
    ]);

    const productTableHeader = [
        { title: "Product Category", widthClass: "w-[20%]" },
        { title: "Product name", widthClass: "w-[10%]" },
        { title: "Price per unit", widthClass: "w-[10%]" },
    ];

    const productTableBody = [1]?.map((po, idx) => [
        {
            content: (
                <div
                    className={`py-3 pr-3 flex w-full items-center justify-between font-rocGroteskMedium text-sm text-slate-800 `}
                >
                    <span className="text-base text-[#64748B] font-rocGroteskRegular">
                        Upload your contract
                    </span>
                    <div className="relative group    flex justify-center items-center">
                        <input
                            className="relative z-10 opacity-0  "
                            type="file"
                            accept="pdf/*"
                            name="contract"
                            id="contract-doc"
                            onChange={(evt) => {
                                console.log(evt.target.files[0].name, evt.target.files[0]);
                            }}
                        />
                        <div className="absolute top-0 right-0 cursor-pointer">
                            <div className="flex items-center justify-center p-1.5  space-x-2">
                                <div
                                    className="flex items-center space-x-1"
                                    onClick={handleUploadClick}
                                >
                                    <i className="ri-upload-cloud-line text-[#003881]"></i>
                                    <p className="text-sm text-[#003881] underline">
                                        {selectedFile ? "Change" : "Upload"}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ),
        },
        {
            content: (
                <div className={`py-3 pr-3 font-rocGroteskMedium text-sm`}>
                    <span className="text-sm">---</span>
                </div>
            ),
        },
        {
            content: (
                <div className={`py-3 pr-3 font-rocGroteskMedium text-sm text-slate-500 `}>
                    <span className="text-sm">---</span>
                </div>
            ),
        },
    ]);

    useEffect(() => {
        setPaymentTermsData(paymentTerms);
    }, []);

    const handleProductDataChange = (e, idx) => {
        const { name, value } = e.target;
        const copyProductData = [...productData];
        if (name === "value" || name === "duration") {
            copyProductData[idx] = {
                ...copyProductData[idx],
                leadTime: {
                    ...copyProductData[idx]?.leadTime,
                    [name]: value,
                },
            };
        } else if (name === "inventoryId") {
            const selectedProduct = productList?.filter((item) => item?._id === value);
            copyProductData[idx] = {
                ...copyProductData[idx],
                productName: selectedProduct[0]?.productName,
                inventoryId: selectedProduct[0]?._id,
                unitPrice: selectedProduct[0]?.unitCost?.amount,
                productCategory: selectedProduct[0]?.inventoryCategory,
                currency: selectedProduct[0]?.unitCost?.currency ?? "USD",
            };
            const copyProductCurrency = [...productCurrency];
            copyProductCurrency[idx] = value?.cost?.currency ?? "USD";
            setProductCurrency(copyProductCurrency);
        } else {
            copyProductData[idx][name] = value;
        }

        setProductData(copyProductData);
    };

    const handleDeleteProduct = (idx) => {
        const copyProductData = [...productData];
        copyProductData.splice(idx, 1);
        setProductData(copyProductData);
    };

    const handleAddProduct = () => {
        const newProduct = {
            productName: "",
            inventoryId: "",
            unitPrice: "",
            productCategory: "",
            currency: "",
            leadTime: {
                duration: "",
                value: "",
            },
            discount: "",
            paymentTerms: "",
        };
        setProductData([...productData, newProduct]);
    };

    const prodTableHeader = [
        { title: "Product name", widthClass: "w-[20%] !font-rocGroteskMedium" },
        { title: "Unit cost", widthClass: "w-[12%] !font-rocGroteskMedium" },
        { title: "Lead time duration", widthClass: "w-[20%] !font-rocGroteskMedium" },
        { title: "Lead time value", widthClass: "w-[12%] !font-rocGroteskMedium" },
        { title: "Discount", widthClass: "w-[12%] !font-rocGroteskMedium" },
        { title: "Payment terms", widthClass: "w-[20%] !font-rocGroteskMedium" },
        { title: "", widthClass: "w-[4%] !font-rocGroteskMedium" },
    ];
    console.log("productData", productData, allObjectsEmpty);
    const prodTableBody = productData?.map((item, idx) => {
        return [
            {
                content: (
                    <div className={`w-full  border-slate-100 text-sm text-slate-700 `}>
                        <SelectInput
                            value={item?.inventoryId ? item?.productName : ""}
                            name="inventoryId"
                            inputPlaceholder={"Select"}
                            handleChange={(name, value) => {
                                handleProductDataChange({ target: { name, value } }, idx);
                            }}
                            handleInputChange={debounce((evt) => {
                                setDebouncedSearch(evt.target.value);
                            }, 800)}
                            isRequired={!allObjectsEmpty}
                            className="!border-0 !rounded-none"
                            inputContainerClassName="!mb-0"
                            searchLoading={fetchingInventoryList}
                            clearValue={true}
                            dropdownOptions={productList?.map((item) => ({
                                label: (
                                    <div className="flex gap-3 items-center">
                                        <div className="flex gap flex-col">
                                            <p className="font-rocGroteskMedium">
                                                {item?.productName}
                                            </p>
                                        </div>
                                    </div>
                                ),
                                icon: (
                                    <div className="">
                                        {item?.productImageDetails?.productAvatar ? (
                                            <div className="h-8  w-8 rounded">
                                                <img
                                                    src={item?.productImageDetails?.productAvatar}
                                                    className="max-h-8 max-w-8"
                                                />
                                            </div>
                                        ) : (
                                            <div className="w-8 h-8 rounded flex items-center justify-center bg-slate-200 ">
                                                <i className="ri-box-2-fill text-lg text-slate-500"></i>
                                            </div>
                                        )}
                                    </div>
                                ),
                                subText: <p className="">SKU:{item?.sku}</p>,
                                value: item?._id,
                            }))}
                        />
                    </div>
                ),
                tableDataDivClass: "!pl-0",
            },
            {
                content: (
                    <div
                        className={`w-full border-slate-100 text-sm text-slate-700 flex items-center px-2.5`}
                    >
                        <p className="">
                            {productCurrency[idx]
                                ? getCurrencyFromCurrencyCode(productCurrency[idx])
                                : "$"}
                        </p>
                        <TextInput
                            value={item?.unitPrice ?? ""}
                            name={"unitPrice"}
                            required={!allObjectsEmpty}
                            onChange={(e) => handleProductDataChange(e, idx)}
                            inputPlaceholder="0"
                            containerClassname=""
                            inputContainerClassname={"!border-none !rounded-none "}
                            inputClassName="w-full !px-0 !py-0"
                        />
                    </div>
                ),
                tableDataDivClass: "!pl-0",
            },

            {
                content: (
                    <div className={`w-full  border-slate-100 text-sm text-slate-700 `}>
                        <SelectInput
                            value={item?.leadTime?.duration || ""}
                            name="duration"
                            inputPlaceholder={"Select"}
                            handleChange={(name, value) =>
                                handleProductDataChange({ target: { name, value } }, idx)
                            }
                            isRequired={!allObjectsEmpty}
                            className="!border-0 !rounded-none"
                            inputContainerClassName="!mb-0"
                            clearValue={true}
                            dropdownOptions={[
                                {
                                    label: "Hours",
                                    value: "hours",
                                },
                                {
                                    label: "Days",
                                    value: "days",
                                },
                                {
                                    label: "Weeks",
                                    value: "weeks",
                                },
                                {
                                    label: "Months",
                                    value: "months",
                                },
                            ]}
                        />
                    </div>
                ),
                tableDataDivClass: "!pl-0",
            },
            {
                content: (
                    <div className={`w-full border-slate-100 text-sm text-slate-700 px-2.5`}>
                        <TextInput
                            value={item?.leadTime?.value ?? ""}
                            name="value"
                            type={"number"}
                            onWheel={(e: any) => e.target.blur()}
                            onKeyDown={(evt) =>
                                ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) &&
                                evt.preventDefault()
                            }
                            required={!allObjectsEmpty}
                            onChange={(e) => handleProductDataChange(e, idx)}
                            inputPlaceholder="0"
                            containerClassname=""
                            inputContainerClassname={"!border-none !rounded-none "}
                            inputClassName="w-full "
                        />
                    </div>
                ),
                tableDataDivClass: "!pl-0",
            },
            {
                content: (
                    <div
                        className={`w-full border-slate-100 text-sm text-slate-700 px-2.5 flex items-center`}
                    >
                        <div className="!w-[50px]">
                            <TextInput
                                value={item?.discount ?? ""}
                                name={"discount"}
                                type={"number"}
                                onWheel={(e: any) => e.target.blur()}
                                onKeyDown={(evt) =>
                                    ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(
                                        evt.key
                                    ) && evt.preventDefault()
                                }
                                required={!allObjectsEmpty}
                                onChange={(e) => handleProductDataChange(e, idx)}
                                inputPlaceholder="0"
                                containerClassname=""
                                inputContainerClassname={"!border-none !rounded-none"}
                                inputClassName=" "
                            />
                        </div>
                        <p className="">%</p>
                    </div>
                ),
                tableDataDivClass: "!pl-0",
            },
            {
                content: (
                    <div className={`w-full border-slate-100 text-sm text-slate-700 `}>
                        <SelectInput
                            value={item?.paymentTerms}
                            name="paymentTerms"
                            handleChange={(name, value) =>
                                handleProductDataChange({ target: { name, value } }, idx)
                            }
                            inputPlaceholder={"Select"}
                            isRequired={!allObjectsEmpty}
                            dropdownOptions={paymentTermsData?.map((data) => ({
                                label: data,
                                value: data,
                            }))}
                            className="!border-0 !rounded-none"
                            dropdownClassName="!pb-0"
                            rightDropdownContainerClassName="!sticky bottom-0"
                            customRightDropdownContainer={
                                <div className="py-2 px-3  flex items-center bg-white !h-[48px] sticky w-full bottom-0">
                                    <div
                                        className="flex items-center gap-3 w-fit cursor-pointer"
                                        onClick={() => {
                                            setAddPaymentOption(true);
                                            setCustomIndex(idx);
                                        }}
                                    >
                                        <i className="ri-add-fill text-[#0046A2] text-sm"></i>

                                        <span className="text-sm font-rocGroteskMedium text-[#0046A2] underline">
                                            Add custom options
                                        </span>
                                    </div>
                                </div>
                            }
                        />
                    </div>
                ),
                tableDataDivClass: "!pl-0",
            },
            {
                content: (
                    <div className={`w-full border-slate-100 text-sm text-slate-500 `}>
                        <i
                            className={`ri-delete-bin-line text-r-55`}
                            onClick={() => handleDeleteProduct(idx)}
                        ></i>
                    </div>
                ),
            },
        ];
    });

    return (
        <PageFrame isLoading={fetchingSupplierDetails}>
            <div className="bg-white lg:border rounded-[8px] lg:p-4 border-[#F1F5F9]">
                <div className="lg:pb-4 lg:border-b lg:border-dotted border-[#E0E0E0] w-full">
                    <div
                        className="flex items-center space-x-1 mt-5 lg:mt-0 mb-5 cursor-pointer text-[#3B4C58] text-sm font-rocGroteskMedium"
                        onClick={() => navigate("/dashboard/suppliers")}
                    >
                        <i className="ri-arrow-left-line text-gm-50 text-lg"></i>
                        <p className="hidden lg:block underline">Back</p>
                        <p className="lg:hidden text-slate-900 pl-4">Add Supplier</p>
                    </div>
                    <div className="hidden lg:block">
                        <PageTitleSection
                            leftComp={
                                <h3 className=" text-[19px] leading-[26px] font-rocGroteskMedium">
                                    {supplierId
                                        ? "Update supplier"
                                        : "Add your supplier by filling the following details"}
                                </h3>
                            }
                        />
                    </div>
                </div>
                <form onSubmit={handleAddVendor} className="lg:px-4">
                    <div className="lg:mt-10">
                        <div className="hidden lg:flex pb-4 justify-between items-center lg:border-b lg:border-[#F1F5F9]">
                            <h4 className="text-[22px] leading-[33px] text-[#142837] font-rocGroteskMedium">
                                Supplier details form
                            </h4>
                            <div className="flex items-center space-x-3">
                                <Button
                                    btnText={"Cancel"}
                                    type={"button"}
                                    btnClassname="!py-2 !px-4 !w-fit max-sm:w-full !bg-transparent !border !border-[#F4F5F7]"
                                    onClick={() => {
                                        navigate("/dashboard/suppliers");
                                    }}
                                    disabled={addingVendor || updatingVendor}
                                />
                                <Button
                                    btnText={"Submit"}
                                    type={"submit"}
                                    btnClassname="!py-2 !px-6 !w-fit max-sm:w-full"
                                    isLoading={addingVendor || updatingVendor}
                                    // onClick={() => navigate("/dashboard/suppliers")}
                                />
                            </div>
                        </div>
                        <div className="pb-4 mt-8 border-b border-dotted border-[#E0E0E0] ">
                            {/* <div className="hidden lg:flex sm:flex-row flex-col sm:space-y-0 space-y-3 sm:space-x-6 items-center mb-6 mt-3">
                                <div
                                    className={`border border-dashed ${
                                        !supplierDetails?.logo && "cursor-pointer"
                                    } rounded border-n-40 w-[130px] h-[130px] relative flex items-center justify-center`}
                                    onClick={() => document.getElementById("fileInput").click()}
                                >
                                    {uploading !== "logo" ? (
                                        <>
                                            {supplierDetails?.logo ? (
                                                <img
                                                    src={supplierDetails.logo}
                                                    alt="business-logo"
                                                    className="w-[130px] h-[130px] rounded object-contain"
                                                />
                                            ) : (
                                                <>
                                                    <i className="ri-image-add-fill flex flex-col justify-center items-center text-[35px] text-tradeally-neutral-200">
                                                        <input
                                                            className={`absolute z-[-1] w-[130px] h-[130px] opacity-0 ${
                                                                supplierDetails.logo ? "hidden" : ""
                                                            }`}
                                                            type="file"
                                                            accept="image/*"
                                                            name="bgfile2"
                                                            id="fileInput"
                                                            // required
                                                            // style={{ display: "none" }}
                                                            onChange={(evt) => {
                                                                handleImageUpload(
                                                                    evt.target?.files[0]?.name,
                                                                    evt.target?.files[0],
                                                                    supplierDetails?.logo,
                                                                    "logo"
                                                                );
                                                            }}
                                                        />
                                                    </i>
                                                </>
                                            )}
                                        </>
                                    ) : (
                                        <Loader />
                                    )}
                                </div>

                                <div className="flex flex-col sm:items-start items-center justify-center space-y-2">
                                    <div className="flex">
                                        <h6 className="font-rocGroteskMedium text-[gm-50] text-sm">
                                            Business logo
                                        </h6>
                                    </div>
                                    <div className="flex space-x-2">
                                        <div className="relative group w-[140px] border rounded-[4px]  flex justify-center items-center">
                                            <input
                                                className="relative z-10 opacity-0  "
                                                type="file"
                                                accept="image/*"
                                                name="bgfile"
                                                id="bgfile"
                                                onChange={(evt) => {
                                                    handleImageUpload(
                                                        evt.target?.files[0]?.name,
                                                        evt.target?.files[0],
                                                        supplierDetails?.logo,
                                                        "logo"
                                                    );
                                                }}
                                            />
                                            <div className="absolute top-0 right-0 cursor-pointer bottom-0 left-0 ">
                                                <div className="flex items-center justify-center p-1.5  space-x-2">
                                                    <i className="ri-upload-2-line"></i>
                                                    <p className="text-gm-50 leading-5 font-rocGroteskMedium text-[13px]">
                                                        {supplierDetails?.logo
                                                            ? "Change image"
                                                            : "Upload image"}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <Button
                                            btnClassname={
                                                "!bg-white !py-2 border !text-dark-gray-2 !w-fit"
                                            }
                                            btnText={"Remove"}
                                            onClick={() => handleImageRemoval("logo")}
                                            type={"button"}
                                        />
                                    </div>
                                    <p className="text-xs font-rocGroteskMedium text-tradeally-neutral-200">
                                        JPG or PNG | Less than 5MB
                                    </p>
                                </div>
                            </div> */}
                            <div>
                                <div className="mb-5">
                                    <p className="text-base font-rocGroteskMedium ">
                                        This is an online supplier
                                    </p>
                                    <div className="flex items-center">
                                        <div
                                            className="mr-5 cursor-pointer flex items-center"
                                            onClick={() => setOnline(true)}
                                        >
                                            {online ? (
                                                <i
                                                    className={`ri-radio-button-fill text-xl ${
                                                        online && "text-y-10"
                                                    }`}
                                                ></i>
                                            ) : (
                                                <span
                                                    className={`material-icons text-xl text-n-50`}
                                                >
                                                    radio_button_unchecked
                                                </span>
                                            )}
                                            <span className="text-sm font-rocGroteskMedium ml-[5px]">
                                                Yes
                                            </span>
                                        </div>

                                        <div
                                            className="cursor-pointer flex items-center"
                                            onClick={() => setOnline(false)}
                                        >
                                            {!online ? (
                                                <i
                                                    className={`ri-radio-button-fill text-xl ${
                                                        !online && "text-y-10"
                                                    }`}
                                                ></i>
                                            ) : (
                                                <span
                                                    className={`material-icons text-xl text-n-50`}
                                                >
                                                    radio_button_unchecked
                                                </span>
                                            )}
                                            <span className="text-sm font-rocGroteskMedium ml-[5px] translate-y-[1px]">
                                                No
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="font-rocGroteskMedium mb-6">
                                    <p className="text-sm lg:text-base  text-gm-50">
                                        Supplier Information
                                        <span className="text-[#FF3D1D] text-base">*</span>
                                    </p>
                                    <p className="text-xs lg:text-sm text-[#64748B]">
                                        These details are mandatory when onboarding a supplier
                                    </p>
                                </div>
                                <div className="grid lg:grid-cols-2 gap-2 ">
                                    <TextInput
                                        name={"businessName"}
                                        type={"text"}
                                        value={supplierDetails?.businessName || ""}
                                        onChange={handleSupplierDetails}
                                        placeholder={"Company name"}
                                        required={true}
                                        disabled={supplierId ? true : false}
                                    />
                                    {online && (
                                        <TextInput
                                            name={"website"}
                                            type={"text"}
                                            value={supplierDetails?.website || ""}
                                            onChange={handleSupplierDetails}
                                            placeholder={"Website"}
                                            disabled={supplierId ? true : false}
                                        />
                                    )}
                                    {!online && (
                                        <TextInput
                                            name={"email"}
                                            type={"email"}
                                            value={supplierDetails?.email || ""}
                                            onChange={handleSupplierDetails}
                                            placeholder={"Email address"}
                                            required={true}
                                            disabled={supplierId ? true : false}
                                        />
                                    )}
                                    {!online && (
                                        <div className="w-full grid grid-cols-[115px_calc(100%-119px)] gap-1">
                                            <SelectInput
                                                value={supplierDetails?.mobileExtension || ""}
                                                name="mobileExtension"
                                                placeholder={"Ext"}
                                                handleChange={(name, value) => {
                                                    handleSupplierDetails({
                                                        target: { name, value },
                                                    });
                                                }}
                                                isRequired={true}
                                                inputClassName={
                                                    "!placeholder:text-slate-400 !placeholder:font-rocGroteskMedium"
                                                }
                                                clearValue={true}
                                                dropdownOptions={countries
                                                    .filter((data) => Boolean(data?.codes[0]))
                                                    .sort((p1, p2) =>
                                                        p1.codes[0] > p2.codes[0]
                                                            ? 1
                                                            : p1.codes[0] < p2.codes[0]
                                                            ? -1
                                                            : 0
                                                    )
                                                    .map((item) => ({
                                                        label: item.codes[0]?.replace(" ", ""),
                                                        value: item.codes[0]?.replace(" ", ""),
                                                        icon: (
                                                            <ReactCountryFlag
                                                                countryCode={item?.abbreviation}
                                                                svg
                                                                cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
                                                                cdnSuffix="svg"
                                                                style={{
                                                                    width: "16px",
                                                                    height: "16px",
                                                                    borderRadius: "50%",
                                                                    marginTop: "-4px",
                                                                }}
                                                            />
                                                        ),
                                                    }))}
                                            />
                                            <TextInput
                                                name={"mobile"}
                                                type={"number"}
                                                value={supplierDetails?.mobile || ""}
                                                placeholder={"Phone number"}
                                                onChange={handleSupplierDetails}
                                                min={5}
                                                onKeyDown={(evt) =>
                                                    [
                                                        "e",
                                                        "E",
                                                        "+",
                                                        "-",
                                                        "ArrowUp",
                                                        "ArrowDown",
                                                    ].includes(evt.key) && evt.preventDefault()
                                                }
                                                required={true}
                                                pattern={"^[0-9]+$"}
                                                title={"must be digits"}
                                                inputClassName={
                                                    "!placeholder:text-slate-400 !placeholder:font-rocGroteskMedium"
                                                }
                                            />
                                        </div>
                                    )}

                                    {!online && (
                                        <SelectInput
                                            value={supplierDetails?.businessCategory || ""}
                                            name="businessCategory"
                                            placeholder={"Select supplier category"}
                                            handleInputChange={debounce((evt) => {}, 800)}
                                            searchLoading={false}
                                            handleChange={(name, value) => {
                                                handleSupplierDetails({ target: { name, value } });
                                            }}
                                            isRequired={true}
                                            dropdownOptions={supplierCatgories?.map((res) => ({
                                                label: res?.label,
                                                value: res?.value,
                                            }))}
                                            clearValue={true}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="pb-4  border-b border-dotted border-[#E0E0E0] ">
                            <div className="font-rocGroteskMedium mt-4 mb-3">
                                <p className="text-sm lg:text-base  text-gm-50">
                                    Contact person Information
                                </p>
                                <p className="text-xs lg:text-sm text-[#64748B]">
                                    This is the supplier representative
                                </p>
                            </div>

                            <div>
                                <div className="grid lg:grid-cols-2 gap-2 ">
                                    <TextInput
                                        name={"firstName"}
                                        type={"text"}
                                        value={supplierDetails?.firstName || ""}
                                        onChange={handleSupplierDetails}
                                        placeholder={"First name"}
                                        required={online ? false : true}
                                    />
                                    <TextInput
                                        name={"lastName"}
                                        type={"text"}
                                        value={supplierDetails?.lastName || ""}
                                        onChange={handleSupplierDetails}
                                        placeholder={"Last name"}
                                        required={online ? false : true}
                                    />
                                </div>

                                {/* <div className="mt-2">
                                <TextInput
                                    value={supplierDetails?.role || ""}
                                    name="role"
                                    onChange={handleSupplierDetails}
                                    placeholder="Enter role"
                                    required={true}
                                    type={"text"}
                                />
                            </div> */}
                            </div>
                        </div>
                        <div>
                            <div className="pb-4  border-b border-dotted border-[#E0E0E0] mt-4">
                                <p className="text-sm lg:text-base text-gm-50 font-rocGroteskMedium mb-2">
                                    Address
                                </p>
                                <div className="">
                                    <AddressInput
                                        setData={setAddress}
                                        savedAddress={
                                            address?.contactAddress
                                                ? address?.contactAddress?.address
                                                : ""
                                        }
                                        placeholder="Address"
                                        required={online ? false : true}
                                        name={"contactAddress"}
                                        resetLocation={resetAddress}
                                        setResetLocation={setResetAddress}
                                    />
                                </div>
                            </div>

                            <div className="pb-4 border-b border-b-dotted border-b-[#E0E0E0] w-full mt-4">
                                <p className="text-sm lg:text-base text-gm-50 font-rocGroteskMedium lg:mb-2 flex items-center space-x-1.5">
                                    <span>Product details</span>{" "}
                                    <span className="text-xs text-p-50 px-1.5 rounded-xl border border-p-50 py-[2px]">
                                        Optional
                                    </span>
                                </p>
                                {productData?.length > 0 && (
                                    <div className="">
                                        <div className="hidden lg:block">
                                            <CustomTable
                                                tableBody={prodTableBody}
                                                tableHeader={prodTableHeader}
                                                isScrollable={false}
                                                isCellBordered={false}
                                                isCellSelectable={false}
                                                isAllSelectable={false}
                                                scrollWithin={false}
                                                headerContainerClass="!bg-slate-100"
                                                headerItemClass="font-rocGroteskMedium "
                                                tableClass=""
                                            />
                                        </div>
                                        <div className="lg:hidden font-rocGroteskMedium">
                                            {productData?.map((item, idx) => {
                                                return (
                                                    <div className="mt-4" key={idx}>
                                                        <div className="flex items-center justify-between mb-4">
                                                            <p className="text-slate-500 text-xs">
                                                                Product {idx + 1}
                                                            </p>
                                                            <i
                                                                className={`ri-delete-bin-line text-xl  text-r-55 ${
                                                                    productData?.length === 1 &&
                                                                    "cursor-default"
                                                                }`}
                                                                onClick={() =>
                                                                    handleDeleteProduct(idx)
                                                                }
                                                            ></i>
                                                        </div>
                                                        <div className="flex gap-2.5 flex-col">
                                                            <div className="flex border border-slate-200">
                                                                <div className="bg-slate-100 flex-1 py-2.5 px-4">
                                                                    <p className="text-sm text-slate-700">
                                                                        Product name
                                                                    </p>
                                                                </div>

                                                                <div
                                                                    className={`flex-1  border-slate-100 text-sm text-slate-700 `}
                                                                >
                                                                    <SelectInput
                                                                        value={
                                                                            item?.inventoryId ?? ""
                                                                        }
                                                                        name="inventoryId"
                                                                        inputPlaceholder={"Select"}
                                                                        handleChange={(
                                                                            name,
                                                                            value
                                                                        ) => {
                                                                            handleProductDataChange(
                                                                                {
                                                                                    target: {
                                                                                        name,
                                                                                        value,
                                                                                    },
                                                                                },
                                                                                idx
                                                                            );
                                                                        }}
                                                                        handleInputChange={debounce(
                                                                            (evt) => {
                                                                                setDebouncedSearch(
                                                                                    evt.target.value
                                                                                );
                                                                            },
                                                                            800
                                                                        )}
                                                                        isRequired={
                                                                            !allObjectsEmpty
                                                                        }
                                                                        className="!border-0 !rounded-none"
                                                                        inputContainerClassName="!mb-0"
                                                                        searchLoading={
                                                                            fetchingInventoryList
                                                                        }
                                                                        clearValue={true}
                                                                        dropdownOptions={productList?.map(
                                                                            (item) => ({
                                                                                label: (
                                                                                    <div className="flex gap-3 items-center">
                                                                                        <div className="flex gap flex-col">
                                                                                            <p className="font-rocGroteskMedium">
                                                                                                {
                                                                                                    item?.productName
                                                                                                }
                                                                                            </p>
                                                                                        </div>
                                                                                    </div>
                                                                                ),
                                                                                // icon: (
                                                                                //     <div className="">
                                                                                //         {item
                                                                                //             ?.productImageDetails
                                                                                //             ?.productAvatar ? (
                                                                                //             <div className="h-8  w-8 rounded">
                                                                                //                 <img
                                                                                //                     src={
                                                                                //                         item
                                                                                //                             ?.productImageDetails
                                                                                //                             ?.productAvatar
                                                                                //                     }
                                                                                //                     className="max-h-8 max-w-8"
                                                                                //                 />
                                                                                //             </div>
                                                                                //         ) : (
                                                                                //             <div className="w-8 h-8 rounded flex items-center justify-center bg-slate-200 ">
                                                                                //                 <i className="ri-box-2-fill text-lg text-slate-500"></i>
                                                                                //             </div>
                                                                                //         )}
                                                                                //     </div>
                                                                                // ),
                                                                                subText: (
                                                                                    <p className="">
                                                                                        SKU:
                                                                                        {item?.sku}
                                                                                    </p>
                                                                                ),
                                                                                value: item?._id,
                                                                            })
                                                                        )}
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div className="flex border border-slate-200">
                                                                <div className="bg-slate-100 flex-1 py-2.5 px-4">
                                                                    <p className="text-sm text-slate-700">
                                                                        Unit cost
                                                                    </p>
                                                                </div>

                                                                <div className="flex-1">
                                                                    <div
                                                                        className={` border-slate-100 text-sm text-slate-700 flex items-center px-2.5`}
                                                                    >
                                                                        <p className="">
                                                                            {productCurrency[idx]
                                                                                ? getCurrencyFromCurrencyCode(
                                                                                      productCurrency[
                                                                                          idx
                                                                                      ]
                                                                                  )
                                                                                : "$"}
                                                                        </p>
                                                                        <TextInput
                                                                            value={
                                                                                item?.unitPrice ??
                                                                                ""
                                                                            }
                                                                            name={"unitPrice"}
                                                                            required={
                                                                                !allObjectsEmpty
                                                                            }
                                                                            onChange={(e) =>
                                                                                handleProductDataChange(
                                                                                    e,
                                                                                    idx
                                                                                )
                                                                            }
                                                                            inputPlaceholder="0"
                                                                            containerClassname=""
                                                                            inputContainerClassname={
                                                                                "!border-none !rounded-none "
                                                                            }
                                                                            inputClassName="w-full !px-0 !py-0"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="flex border border-slate-200">
                                                                <div className="bg-slate-100 flex-1 py-2.5 px-4">
                                                                    <p className="text-sm text-slate-700">
                                                                        Lead time duration
                                                                    </p>
                                                                </div>

                                                                <div
                                                                    className={`flex-1  border-slate-100 text-sm text-slate-700 `}
                                                                >
                                                                    <SelectInput
                                                                        value={
                                                                            item?.leadTime
                                                                                ?.duration || ""
                                                                        }
                                                                        name="duration"
                                                                        inputPlaceholder={"Select"}
                                                                        handleChange={(
                                                                            name,
                                                                            value
                                                                        ) =>
                                                                            handleProductDataChange(
                                                                                {
                                                                                    target: {
                                                                                        name,
                                                                                        value,
                                                                                    },
                                                                                },
                                                                                idx
                                                                            )
                                                                        }
                                                                        isRequired={
                                                                            !allObjectsEmpty
                                                                        }
                                                                        className="!border-0 !rounded-none"
                                                                        inputContainerClassName="!mb-0"
                                                                        clearValue={true}
                                                                        dropdownOptions={[
                                                                            {
                                                                                label: "Hours",
                                                                                value: "hours",
                                                                            },
                                                                            {
                                                                                label: "Days",
                                                                                value: "days",
                                                                            },
                                                                            {
                                                                                label: "Weeks",
                                                                                value: "weeks",
                                                                            },
                                                                            {
                                                                                label: "Months",
                                                                                value: "months",
                                                                            },
                                                                        ]}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="flex border border-slate-200">
                                                                <div className="bg-slate-100 flex-1 py-2.5 px-4">
                                                                    <p className="text-sm text-slate-700">
                                                                        Lead time value
                                                                    </p>
                                                                </div>

                                                                <div
                                                                    className={`flex-1  border-slate-100 text-sm text-slate-700 `}
                                                                >
                                                                    <div
                                                                        className={`w-full border-slate-100 text-sm text-slate-700 px-2.5`}
                                                                    >
                                                                        <TextInput
                                                                            value={
                                                                                item?.leadTime
                                                                                    ?.value ?? ""
                                                                            }
                                                                            name="value"
                                                                            type={"number"}
                                                                            required={
                                                                                !allObjectsEmpty
                                                                            }
                                                                            onChange={(e) =>
                                                                                handleProductDataChange(
                                                                                    e,
                                                                                    idx
                                                                                )
                                                                            }
                                                                            inputPlaceholder="0"
                                                                            containerClassname=""
                                                                            inputContainerClassname={
                                                                                "!border-none !rounded-none "
                                                                            }
                                                                            inputClassName="w-full "
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="flex border border-slate-200">
                                                                <div className="bg-slate-100 flex-1 py-2.5 px-4">
                                                                    <p className="text-sm text-slate-700">
                                                                        Discount
                                                                    </p>
                                                                </div>

                                                                <div
                                                                    className={`flex-1  border-slate-100 text-sm text-slate-700 `}
                                                                >
                                                                    <div
                                                                        className={`w-full border-slate-100 text-sm text-slate-700 pl-4 flex items-center`}
                                                                    >
                                                                        <div className="!w-[50px]">
                                                                            <TextInput
                                                                                value={
                                                                                    item?.discount ??
                                                                                    ""
                                                                                }
                                                                                name={"discount"}
                                                                                type={"number"}
                                                                                required={
                                                                                    !allObjectsEmpty
                                                                                }
                                                                                onChange={(e) =>
                                                                                    handleProductDataChange(
                                                                                        e,
                                                                                        idx
                                                                                    )
                                                                                }
                                                                                inputPlaceholder="0"
                                                                                containerClassname=""
                                                                                inputContainerClassname={
                                                                                    "!border-none !rounded-none"
                                                                                }
                                                                                inputClassName=" "
                                                                            />
                                                                        </div>
                                                                        <p className="">%</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="flex border border-slate-200">
                                                                <div className="bg-slate-100 flex-1 py-2.5 px-4">
                                                                    <p className="text-sm text-slate-700">
                                                                        Payment terms
                                                                    </p>
                                                                </div>

                                                                <div
                                                                    className={`flex-1  border-slate-100 text-sm text-slate-700 `}
                                                                >
                                                                    <div
                                                                        className={`w-full border-slate-100 text-sm text-slate-700 `}
                                                                    >
                                                                        <SelectInput
                                                                            value={
                                                                                item?.paymentTerms
                                                                            }
                                                                            name="paymentTerms"
                                                                            handleChange={(
                                                                                name,
                                                                                value
                                                                            ) =>
                                                                                handleProductDataChange(
                                                                                    {
                                                                                        target: {
                                                                                            name,
                                                                                            value,
                                                                                        },
                                                                                    },
                                                                                    idx
                                                                                )
                                                                            }
                                                                            inputPlaceholder={
                                                                                "Select"
                                                                            }
                                                                            isRequired={
                                                                                !allObjectsEmpty
                                                                            }
                                                                            dropdownOptions={paymentTermsData?.map(
                                                                                (data) => ({
                                                                                    label: data,
                                                                                    value: data,
                                                                                })
                                                                            )}
                                                                            className="!border-0 !rounded-none"
                                                                            dropdownClassName="!pb-0"
                                                                            rightDropdownContainerClassName="!sticky bottom-0"
                                                                            customRightDropdownContainer={
                                                                                <div className="py-2 px-3  flex items-center bg-white !h-[48px] sticky w-full bottom-0">
                                                                                    <div
                                                                                        className="flex items-center gap-3 w-fit cursor-pointer"
                                                                                        onClick={() => {
                                                                                            setAddPaymentOption(
                                                                                                true
                                                                                            );
                                                                                            setCustomIndex(
                                                                                                idx
                                                                                            );
                                                                                        }}
                                                                                    >
                                                                                        <i className="ri-add-fill text-[#0046A2] text-sm"></i>

                                                                                        <span className="text-sm font-rocGroteskMedium text-[#0046A2] underline">
                                                                                            Add
                                                                                            custom
                                                                                            options
                                                                                        </span>
                                                                                    </div>
                                                                                </div>
                                                                            }
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                )}

                                <div className="flex items-center gap-3 mt-6">
                                    <Button
                                        btnText="Add more products"
                                        btnTextClassName="underline"
                                        btnClassname="text-[13px] !px-0 lg:!px-2.5 !bg-transparent lg:!bg-[rgba(0,56,129,0.06)] !w-fit text-slate-500 lg:!text-[rgba(0,56,129,1)] !rounded-[4px] "
                                        onClick={handleAddProduct}
                                        icon={
                                            <i className="lg:ri-add-circle-line text-[rgba(0,56,129,1)]"></i>
                                        }
                                    />
                                </div>
                            </div>

                            <div className="flex lg:hidden flex-col space-y-4 mt-10 mb-5">
                                <Button
                                    btnText={"Save Changes"}
                                    type={"submit"}
                                    btnClassname="!py-2.5 lg:!py-2 !px-6 w-full"
                                    isLoading={addingVendor || updatingVendor}
                                    // onClick={() => navigate("/dashboard/suppliers")}
                                />
                                <Button
                                    btnText={"Cancel"}
                                    type={"button"}
                                    btnClassname="!py-2.5 lg:!py-2 w-full !bg-transparent !border !border-[#F4F5F7]"
                                    onClick={() => {
                                        navigate("/dashboard/suppliers");
                                    }}
                                    disabled={addingVendor || updatingVendor}
                                />
                            </div>
                            {/* <div className="pb-4  border-b border-dotted border-[#E0E0E0] mt-4">
                            <p className="text-base text-gm-50 font-rocGroteskMedium mb-2">
                                Bank details
                            </p>
                            <div className="grid grid-cols-2 gap-2 ">
                                <SelectInput
                                    value={bankDetails?.bankCountry || ""}
                                    name="bankCountry"
                                    placeholder={"Select country"}
                                    handleChange={(name, value) =>
                                        handleBankDetails({ target: { name, value } })
                                    }
                                    dropdownOptions={countries.map((item) => ({
                                        label: item.name,
                                        value: item.name,
                                    }))}
                                    clearValue={true}
                                />
                                <TextInput
                                    value={bankDetails?.bankName || ""}
                                    name="bankName"
                                    placeholder={"Bank name"}
                                    onChange={handleBankDetails}
                                    required={true}
                                    type={"text"}
                                />
                                <TextInput
                                    name={"accountNumber"}
                                    type={"number"}
                                    value={bankDetails?.accountNumber || ""}
                                    onChange={handleBankDetails}
                                    placeholder={"Account number"}
                                    required={true}
                                    minLength={5}
                                />
                                <TextInput
                                    name={"accountName"}
                                    type={"text"}
                                    value={bankDetails?.accountName || ""}
                                    onChange={handleBankDetails}
                                    placeholder={"Account name"}
                                />
                                <TextInput
                                    name={"routingNumber"}
                                    type={"text"}
                                    value={bankDetails?.routingNumber || ""}
                                    onChange={handleBankDetails}
                                    placeholder={"Routing number"}
                                    minLength={5}
                                />
                                <SelectInput
                                    value={bankDetails?.currency || ""}
                                    name="currency"
                                    placeholder={"Account currency"}
                                    handleChange={(name, value) =>
                                        handleBankDetails({ target: { name, value } })
                                    }
                                    dropdownOptions={[
                                        {
                                            label: "GBP (£)",
                                            value: "GBP",
                                        },
                                        {
                                            label: "USD ($)",
                                            value: "USD",
                                        },
                                        {
                                            label: "EUR (€)",
                                            value: "EUR",
                                        },
                                        {
                                            label: "NGN (₦)",
                                            value: "NGN",
                                        },
                                    ]}
                                    clearValue={true}
                                />
                            </div>
                        </div> */}
                        </div>
                        <div>
                            {/* <div className="pb-4  border-b border-dotted border-[#E0E0E0] mt-4">
                            <p className="text-base text-gm-50 font-rocGroteskMedium mb-2">
                                Payment terms
                            </p>
                            <TextareaInput
                                name={"paymentTerms"}
                                value={supplierDetails?.paymentTerms}
                                onChange={handleSupplierDetails}
                                rows={5}
                                placeholder={"Enter your payment terms"}
                                required={true}
                                containerClassname=""
                            />
                        </div> */}
                            {/* <div className="pb-4  border-b border-dotted border-[#E0E0E0] mt-4">
                            <p className="text-base text-gm-50 font-rocGroteskMedium mb-1">
                                Contract details
                            </p>
                            <p className="text-sm  text-slate-500 font-rocGroteskMedium mb-4">
                                Fill the necessary details
                            </p>

                            <TextInput
                                name={"name"}
                                type={"text"}
                                value={contractDetails?.name || ""}
                                onChange={(e) =>
                                    setContractDetails({
                                        ...contractDetails,
                                        name: e.target.value,
                                    })
                                }
                                placeholder={"Contract name"}
                                required={true}
                                containerClassname="!mb-6"
                            />

                            <div>
                                <p className="text-sm  text-slate-500 font-rocGroteskMedium mb-4">
                                    Contract upload
                                </p>
                                <CustomTable
                                    tableBody={tableBody}
                                    tableHeader={tableHeader}
                                    isScrollable={false}
                                    isCellBordered={false}
                                    isCellSelectable={false}
                                    isAllSelectable={false}
                                    headerContainerClass="!bg-slate-200"
                                    headerItemClass="font-rocGroteskMedium "
                                    tableClass="mb-6"
                                />
                            </div>

                            <div>
                                <div className="flex w-full justify-between items-center">
                                    <p className="text-sm  text-slate-500 font-rocGroteskMedium mb-4">
                                        Product details
                                    </p>
                                    <p className="text-sm  text-slate-500 font-rocGroteskMedium mb-4">
                                        Warehouse location
                                    </p>
                                </div>

                                <CustomTable
                                    tableBody={productTableBody}
                                    tableHeader={productTableHeader}
                                    isScrollable={false}
                                    isCellBordered={false}
                                    isCellSelectable={false}
                                    isAllSelectable={false}
                                    headerContainerClass="!bg-slate-200"
                                    headerItemClass="font-rocGroteskMedium "
                                    tableClass="mb-12"
                                />
                            </div>
                        </div> */}
                        </div>
                    </div>
                </form>

                <ModalContainer
                    open={successModal}
                    modalClassName={"py-10 px-10"}
                    tailwindClassName="w-[500px]"
                    showCloseIcon={false}
                    closeModal={() => {
                        navigate("/dashboard/suppliers");
                    }}
                >
                    <div className="bg-white !px-2 pt-4 pb-7  rounded-[12px]">
                        <div className="mt-4 px-6">
                            <div className="text-center flex justify-center flex-col items-center space-y-5">
                                <div className="w-14 h-14 flex justify-center items-center bg-[#00DB8F] rounded-full">
                                    <i className="ri-checkbox-circle-fill text-[29px] text-white "></i>
                                </div>
                                <div className="text-gm-50 font-rocGroteskMedium">
                                    <h3 className="text-xl  ">Successful</h3>
                                    <p className="mt-1 text-sm">
                                        {supplierId
                                            ? "Supplier has been updated successfully"
                                            : "Your supplier has been added successfully"}
                                    </p>
                                </div>
                            </div>
                            <div className="mt-5">
                                <Button
                                    btnText={"Done"}
                                    btnClassname="!py-3 cursor-pointer !text-[14px] !text-gm-50 !border border-n-20 !font-rocGroteskMedium !px-4"
                                    onClick={() => {
                                        const scInventory = sessionStorage.getItem("scInventory");

                                        if (scInventory) {
                                            navigate("/dashboard/inventory/add-product");
                                        } else {
                                            navigate("/dashboard/suppliers");
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </ModalContainer>

                <ModalContainer
                    open={addPaymentOption}
                    modalClassName={"py-10 px-10"}
                    tailwindClassName="w-[488px]"
                    showCloseIcon={false}
                    closeModal={() => {
                        setAddPaymentOption(false);
                    }}
                >
                    <div className="bg-white !px-2 pt-6 pb-7  rounded-[12px]">
                        <div className="px-6">
                            <div className="text-start flex  flex-col  space-y-5">
                                <h3 className="text-xl text-[#333333] font-rocGroteskMedium">
                                    Add custom options
                                </h3>
                                <TextInput
                                    placeholder="Enter Option"
                                    value={newPaymentOption}
                                    onChange={(e) => setNewPaymentOption(e.target.value)}
                                />
                            </div>
                            <div className="flex justify-center lg:justify-end">
                                <div className="mt-5 flex justify-center lg:justify-end">
                                    <Button
                                        btnText={"Add payment options"}
                                        btnClassname="!py-3 cursor-pointer !text-[14px]  !font-rocGroteskMedium !px-4"
                                        onClick={() => {
                                            if (newPaymentOption.trim() !== "") {
                                                paymentTermsData?.push(newPaymentOption);
                                                const copyData = [...productData];

                                                copyData[customIndex] = {
                                                    ...copyData[customIndex],
                                                    paymentTerms: newPaymentOption,
                                                };
                                                setProductData(copyData);
                                                setAddPaymentOption(false);
                                                setNewPaymentOption("");
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </ModalContainer>
            </div>
        </PageFrame>
    );
};

export default CreateSuppliers;
