import React from "react";

type ToggleInputProps = {
    checked: boolean;
    name: string;
    disabled?: boolean;
    value?: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    labelClassname?: string;
    className?: string;
};

const ToggleInput = ({
    checked,
    name,
    disabled,
    value,
    onChange,
    labelClassname,
    className,
}: ToggleInputProps) => {
    return (
        <label className={`switch relative inline-block w-[37px] h-[24px] ${labelClassname}`}>
            <input
                name={name}
                type="checkbox"
                checked={checked}
                onChange={onChange}
                disabled={disabled}
                className="opacity-0 w-0 h-0 outline-none toggleInput "
            />
            <span
                className={`slider before:h-5 before:w-5 before:left-[2px] before:bottom-[2px] round ${className}`}
            ></span>
        </label>
    );
};

export default ToggleInput;
