import Button from "components/common/Button";
import CustomTable from "components/common/CustomTable";
import Dropdown from "components/common/Dropdown";
import SelectInput from "components/common/InputField/SelectInput";
import { truncate } from "lodash";
import moment from "moment";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

export const TeamGroups = () => {
    const navigate = useNavigate();
    const [viewGroup, setViewGroup] = useState<boolean>(false);

    const tableHeader = [
        { title: "Groups (6)", widthClass: "w-[30%]" },
        { title: "Number of members", widthClass: "w-[50%]" },
        { title: "", widthClass: "" },
    ];

    const groupLength = [1, 1, 1];

    const tableBody = [1, 1, 1].map((idx) => [
        {
            content: (
                <div className={`py-3 pr-3 text-sm text-slate-700`}>
                    <div className="flex items-center gap-3 !font-rocGroteskMedium">
                        <span>Shopify</span>
                    </div>
                </div>
            ),
            cellClickAction: () => {
                navigate("");
            },
        },
        {
            content: (
                <div className="py-3 relative flex   w-full justify-between pr-10 items-center h-full text-sm text-slate-700">
                    <div className="!font-rocGroteskMedium">
                        <p>Freight Forwarder</p>
                    </div>

                    <div className="flex items-center">
                        <div className="relative pb-8">
                            <div className="h-6 w-6 border-[1.5px] border-white bg-green-500 rounded-full absolute left-4"></div>
                            <div className="h-6 w-6 border-[1.5px] border-white bg-yellow-500 rounded-full absolute left-[30px]"></div>
                            <div className="h-6 w-6 border-[1.5px] border-white bg-red-500 rounded-full absolute left-[42px]"></div>
                            <div className="h-6 w-6 border-[1.5px] border-white flex justify-center items-center bg-slate-700 rounded-full absolute left-[55px]">
                                <p className="text-white text-[10px] font-rocGroteskBold text-center">
                                    +50
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-[18px] flex justify-end w-[82%] items-center h-full border-slate-100 text-sm text-slate-700 `}
                >
                    <Dropdown
                        dropdown={<i className="ri-more-2-fill text-lg text-slate-500"></i>}
                        dropdownClassName={"!w-full  !mb-10 !-mt-7"}
                        dropdownContainerClasses={`shadow-cardShadow-2 !left-[-150px] ${
                            groupLength?.length >= 9 &&
                            idx >= groupLength?.length - 2 &&
                            "!top-[-105px]"
                        } border-0 rounded !max-h-fit`}
                        dropdownItemsClasses={``}
                        name={"faq-dropdown"}
                        dropdownOptions={[
                            {
                                label: `View Group`,
                                value: "1",
                                action: () => {
                                    // setUpdateId(team?._id);
                                    // setViewMember(true);
                                    setViewGroup(true);
                                },
                            },
                            {
                                label: `Disable Group`,
                                value: "2",
                                action: () => {
                                    console.log("disable");
                                },
                            },
                            {
                                label: "Remove Group",
                                value: "3",
                                action: () => {
                                    console.log("remove");
                                },
                            },
                        ]}
                    />
                </div>
            ),
        },
    ]);

    const dummyData = [
        {
            permission: ["all_access"],
            role: "all_access",
            label: "All_access",
            active: true,
            fullName: "Augustine",
            email: "",
        },
        {
            permission: ["analytics_view", "inventory_edit"],
            role: "testingrestriction2",
            label: "TestingRestriction2",
            active: true,
            fullName: "Sarah Dransna",
            email: "",
        },
        {
            permission: [
                "financials_view",
                "shipment_view",
                "analytics_view",
                "workflow_view",
                "inventory_view",
                "supplier_view",
                "purchase_order_view",
                "team_view",
            ],
            role: "fortestingrestriction",
            label: "ForTestingRestriction",
            active: false,
            fullName: "Emily Kate",
            email: "",
        },
    ];

    const tableHeaderViewGroup = [
        { title: `Admin `, widthClass: "w-[25%]" },
        { title: "Role", widthClass: "w-[25%]" },
        { title: "Access level", widthClass: "w-[23%]" },
        { title: "Last active", widthClass: "w-[19%]" },
        { title: "Status", widthClass: "w-[12%]" },
        { title: "", widthClass: "w-[6%]" },
    ];
    const tableBodyViewGroup = dummyData.map((team, idx) => [
        {
            content: (
                <div
                    key={idx}
                    className={`py-3 pr-3 !font-rocGroteskMedium border-slate-100 text-sm  ${
                        team?.active ? "text-slate-800 " : "text-slate-500 "
                    }  `}
                >
                    <div className="flex items-center gap-3">
                        <img
                            src={
                                "https://firebasestorage.googleapis.com/v0/b/durable-path-390718.appspot.com/o/tradeally%2Fstaging%2FpersonalLogo%2FB56AF806-8316-41C7-8B69-8DBAF0B62833.jpg?alt=media&token=3da566fd-3bf5-4c84-955f-c4752fedb242"
                            }
                            alt="profile image"
                            className="rounded-full w-6 h-6"
                        />
                        <span>
                            {truncate(team?.fullName ? team?.fullName : team?.email, {
                                length: 17,
                            })}
                        </span>
                    </div>
                </div>
            ),
        },
        {
            content: (
                <>
                    {/* {userProfile?.user?.role === "owner" || userProfile?.user?.role === "admin" ? (
                        <div
                            className={` w-[200px] -ml-4 h-full !font-rocGroteskMedium border-slate-100 text-sm  ${
                                team?.active ? "text-slate-900 " : "text-slate-500 "
                            }`}
                        >
                            {userProfile?.user?.email !== team?.email ? (
                                <SelectInput
                                    value={userProfile?.user?.email !== team?.email && team?.role}
                                    name="role"
                                    handleInputChange={debounce((evt) => {
                                        setDebouncedSearch({
                                            search: evt.target.value,
                                        });
                                    }, 800)}
                                    disabled={userProfile?.user?.email === team?.email}
                                    searchLoading={fetchingRoles}
                                    optionItemClassName={"h-full"}
                                    handleChange={(name, value) => handleChange(value, team?._id)}
                                    inputClassName={`!!font-rocGroteskRegular  ${
                                        team?.active ? "!text-slate-800 " : "!text-slate-500 "
                                    }`}
                                    className={"!border-0 "}
                                    iconClassName={` ${
                                        team?.active ? "text-slate-800 " : "text-slate-500 "
                                    }`}
                                    isRequired={false}
                                    dropdownOptions={roles?.map((item) => ({
                                        label: item?.label,
                                        value: item?.role,
                                    }))}
                                />
                            ) : (
                                <p className="pl-4">Owner</p>
                            )}
                        </div>
                    ) : ( */}
                    <div
                        className={`py-3 pr-3 !font-rocGroteskMedium border-slate-100 text-sm  ${
                            team?.active ? "text-slate-800 " : "text-slate-500 "
                        } `}
                    >
                        <div className="flex items-center gap-3">
                            <p>Admin</p>
                        </div>
                    </div>
                    {/* )} */}
                </>
            ),
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 !font-rocGroteskMedium border-slate-100 text-sm  ${
                        team?.active ? "text-slate-800 " : "text-slate-500 "
                    }`}
                >
                    <p className="">Full access</p>
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 !font-rocGroteskMedium border-slate-100 text-sm  ${
                        team?.active ? "text-slate-800 " : "text-slate-500 "
                    }`}
                >
                    <div className="flex items-center gap-3">
                        <p>{moment().format("MMM Do, YYYY")}</p>
                    </div>
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-2.5 px-1   !font-rocGroteskMedium flex items-center justify-center h-full 
                    border-slate-100 text-sm text-slate-800 rounded gap-2 !w-[90px] ${
                        team?.active ? "bg-[rgba(101,98,229,0.2)]" : "bg-[rgba(239,105,93,0.2)]"
                    }`}
                >
                    <div
                        className={`h-2 w-2 ${
                            team?.active ? "bg-p-50" : "bg-[#EF695D]"
                        } rounded-full`}
                    ></div>
                    <p className={` ${team?.active ? "text-p-50" : "text-[#EF695D]"}`}>
                        {team?.active ? "Active" : "Inactive"}
                    </p>
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-[18px]  flex justify-center items-center  border-slate-100 text-sm text-slate-800 `}
                >
                    <Dropdown
                        dropdown={<i className="ri-more-2-fill text-lg text-slate-500"></i>}
                        dropdownClassName={"!w-full  !mb-10 !-mt-7"}
                        dropdownContainerClasses={`shadow-cardShadow-2 !left-[-150px] ${
                            dummyData?.length >= 9 &&
                            idx >= dummyData?.length - 2 &&
                            "!top-[-105px]"
                        } border-0 rounded !max-h-fit`}
                        dropdownItemsClasses={``}
                        name={"faq-dropdown"}
                        dropdownOptions={[
                            {
                                label: "Dropdown Option",
                                value: "1",
                                action: () => {
                                    console.log("Dropdown option1");
                                },
                            },
                            {
                                label: "Dropdown Option",
                                value: "2",
                                action: () => {
                                    console.log("Dropdown option2");
                                },
                            },
                            {
                                label: "Dropdown Option",
                                value: "3",
                                action: () => {
                                    console.log("Dropdown option3");
                                },
                            },
                        ]}
                    />
                </div>
            ),
        },
    ]);
    return ( 
        <div className="mt-10 overflow-x-scroll px-4">
            {viewGroup ? (
                <div className="">
                    <CustomTable
                        tableBody={tableBodyViewGroup}
                        tableHeader={tableHeaderViewGroup}
                        isCellBordered={false}
                        isCellSelectable={false}
                        isAllSelectable={false}
                        headerContainerClass="!bg-slate-100"
                        headerItemClass="font-rocGroteskMedium "
                        tableClass="!border !border-slate-200"
                        bodyItemClass="!border-slate-200"
                    />

                    <div className="w-full flex justify-center !bg-white">
                        <Button
                            btnText={"Back"}
                            type={"button"}
                            btnTextClassName="!underline"
                            btnClassname="!mb-2 !bg-transparent  !text-gm-50 !w-auto !px-0 !text-sm"
                            onClick={() => setViewGroup(false)}
                            icon={<i className="ri-arrow-left-line text-gm-50"></i>}
                        />
                    </div>
                </div>
            ) : (
                <CustomTable
                    tableBody={tableBody}
                    tableHeader={tableHeader}
                    isCellBordered={false}
                    isCellSelectable={false}
                    isAllSelectable={false}
                    headerContainerClass="!bg-slate-100"
                    headerItemClass="font-rocGroteskMedium "
                    tableClass="!border !border-slate-200"
                    bodyItemClass="!border-slate-200"
                />
            )}
        </div>
    );
};
function getRoleLabel(role: string): React.ReactNode {
    throw new Error("Function not implemented.");
}
