import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../storeHooks";
import { workforceActions } from "../../redux/Ldb/actions/workforceActions";
import { useParams } from "react-router-dom";

const useGetTaskAnalytics = () => {
    const dispatch = useAppDispatch();

    const { fetchingTaskAnalytics, fetchTaskAnalyticsSuccess, fetchTaskAnalyticsFailure } =
        useAppSelector((state) => state.workforce);

    const [taskAnalytics, setTaskAnalytics] = useState<{ [key: string]: any }>({});

    useEffect(() => {
        dispatch(workforceActions.getTaskAnalytics());
    }, [dispatch]);

    useEffect(() => {
        if (Boolean(fetchTaskAnalyticsSuccess)) {
            setTaskAnalytics(fetchTaskAnalyticsSuccess?.analytics);
        }
    }, [fetchTaskAnalyticsSuccess]);

    return {
        isFetching: fetchingTaskAnalytics,
        error: fetchTaskAnalyticsFailure,
        data: taskAnalytics,
        setTaskAnalytics,
    };
};

export default useGetTaskAnalytics;
