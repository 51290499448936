import React from "react";
import {Tooltip} from "../ToolTip";

type MapLabelsProps = {
    lastUpdated?: string;
    currentLocation?: string;
};

export const MapLabels = ({lastUpdated, currentLocation}: MapLabelsProps) => {
    return (
        <div className=" px-5 py-4  rounded-[8px]  w-full bg-slate-50 border border-slate-100">
            {/* <div className="flex w-full justify-between">
                {mapList.map((d) => (
                    
                    <div className={`${d.id !==6 && "w-full  flex items-center"}`}>
                        <Tooltip text={d.text}>
                            <div className="cursor-pointer">
                                {d.icon}
                            </div>
                        </Tooltip>

                        {d.id !== 6 && (
                            <div className="border-b-[0.5px]  border-p-55 w-full"></div>
                        )}
                    </div>
                ))}
            </div> */}
            <div className="font-rocGroteskMedium mt-1 flex md:flex-row flex-col space-y-2 md:space-y-0 md:space-x-2 leading-4 text-xs">
                <div className="flex space-x-1">
                    <p className="text-slate-500">Last updated:</p>
                    <p className="text-slate-700"> {lastUpdated || "N/A"}</p>
                </div>
                <div className="flex space-x-1">
                    <p className="text-slate-500">Current location: </p>
                    <p className="text-slate-700"> {currentLocation || "N/A"}</p>
                </div>
            </div>
        </div>
    );
};
