import BigCalendarComp from "components/common/BigCalendar/Calendar";
import Button from "components/common/Button";
import useGetProductionPlanList from "hooks/ProductionHooks/useGetProductionPlanList";
import useGetProductionPlanTab from "hooks/ProductionHooks/useGetProductionPlanTab";
import React, { useEffect, useState } from "react";

const GridView = ({ setGridDate, gridDate, view, setView, viewType, setViewType }) => {
    const [plans, setPlans] = useState<any>();
    const { data, isLoading, isLoadingMore, pagination, assigneeId, status } =
        useGetProductionPlanList();
    // const {
    //     data: tabDetails,
    //     isLoading: tabLoading,
    //     setQuery,
    //     debouncedSearch,
    //     setDebouncedSearch,
    //     handleDebouncedSearch,
    //     search,
    //     setSearch,
    //     handleSearch,
    //     isLoadingMore,
    //     pagination,
    // } = useGetProductionPlanTab();

    // useEffect(() => {
    //     setQueplans");
    // }, []);

    // useState(() => {
    //     setSteps(tabDetaiplans);
    // }, [tabDetails]);

    useEffect(() => {
        const event = data?.map((event) => {
            return {
                id: event?._id,
                start: new Date(event?.startDate),
                end: new Date(event?.endDate),
                event: "",
                po: event?.taId,
                productName: event?.productName,
                startDate: event?.startDate,
                endDate: event?.endDate,
                quantity: event?.quantity?.amount,
                status: event?.productionStatus,
            };
        });
        setPlans(event);
    }, [data]);

    console.log(plans);

    return (
        <div className="">
            <BigCalendarComp
                setGridDate={setGridDate}
                gridDate={gridDate}
                view={view}
                setView={setView}
                viewType={viewType}
                setViewType={setViewType}
                plans={plans}
            />
            
        </div>
    );
};

export default GridView;
