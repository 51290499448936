import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {authActions} from "redux/Ldb/actions";
import {useAppDispatch, useAppSelector} from "./storeHooks";

const useGetUserProfile = () => {
    const [profile, setProfile] = useState<{[key: string]: any}>({});
    const dispatch = useAppDispatch();
    const {fetchUserProfileSuccess} = useAppSelector((state) => state.auth);

    useEffect(() => {
        if (!Boolean(fetchUserProfileSuccess)) {
            dispatch(authActions.getLdbProfile());
        }
    }, [dispatch, fetchUserProfileSuccess]);

    useEffect(() => {
        if (Boolean(fetchUserProfileSuccess)) {
            setProfile(fetchUserProfileSuccess);
        }
    }, [fetchUserProfileSuccess]);

    return [profile];
};

export default useGetUserProfile;
