import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import VendorAvatar from "./../../../../common/VendorAvatar";
import CustomTable from "./../../../../common/CustomTable";
import useGetProductionPlanList from "hooks/ProductionHooks/useGetProductionPlanList";
import moment from "moment";
import CustomTableLoader from "components/common/CustomTableLoader";
import Loader from "components/common/Loader";
import EmptyStateComp from "components/common/EmptyStateComp";
import { truncate } from "lodash";

type listProps = {
    search: string;
    setSearch: (data: string) => void;
};

const ListView = ({ search, setSearch }: listProps) => {
    const navigate = useNavigate();
    const { data, isLoading, isLoadingMore, pagination, assigneeId, status } =
        useGetProductionPlanList();

    const tableHeader = [
        { title: "Production ID", widthClass: "w-[165px]" },
        { title: "Product", widthClass: "w-[375px]" },
        { title: "Expected Qty", widthClass: "w-[130px]" },
        { title: "Start Date", widthClass: "w-[135px]" },
        { title: "End Date", widthClass: "w-[135px]" },
        { title: "Status", widthClass: "w-[200px]" },
        { title: "Production Manager", widthClass: "w-[183px]" },
        // { title: "Production Location", widthClass: "w-[190px]" },
    ];

    const tableBody = data?.map((plan, idx) => [
        {
            content: (
                <div
                    key={idx}
                    className={`!py-2 !pr-4 font-rocGroteskMedium border-slate-200 text-sm text-gm-50 `}
                >
                    <p>PO-{plan?.taId}</p>
                </div>
            ),
            cellClickAction: () => {
                navigate(`/dashboard/production/${plan?._id}`);
            },
        },
        {
            content: (
                <div
                    className={`!py-2 !pr-4 font-rocGroteskMedium border-slate-200 text-sm text-gm-50 `}
                >
                    <div className="flex items-center gap-2">
                        {!plan ? (
                            <VendorAvatar
                                imageSrc={
                                    plan?.productImageDetails?.productAvatar ||
                                    plan?.productImageDetails?.productImages?.[0]
                                }
                                size={32}
                                name={plan?.productName}
                                containerClassname="!rounded-md"
                            />
                        ) : (
                            <div className="w-8 h-8 rounded shrink-0 flex items-center justify-center bg-slate-200 ">
                                <i className="ri-box-2-fill text-lg text-slate-500"></i>
                            </div>
                        )}

                        <div className="text-sm">
                            <p className="text-[#142837]">
                                {truncate(plan?.productName, { length: 20 })}
                            </p>
                            <p className="text-[#64748B]">{plan?.sku}</p>
                        </div>
                    </div>
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`!py-2 !pr-4 font-rocGroteskMedium border-slate-200 text-sm text-gm-50 `}
                >
                    <p>
                        {plan?.quantity?.amount.toLocaleString()}{" "}
                        {plan?.quantity?.amount !== null ? plan?.quantity?.unitOfMeasurement : ""}
                    </p>
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`!py-2 !pr-4 font-rocGroteskMedium border-slate-200 text-sm text-gm-50 `}
                >
                    <p>
                        {plan?.startDate ? moment(plan?.startDate).format("MMM DD, YYYY") : "N/A"}
                    </p>
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`!py-2 !pr-4 font-rocGroteskMedium border-slate-200 text-sm text-gm-50 `}
                >
                    <p>{plan?.endDate ? moment(plan?.endDate).format("MMM DD, YYYY") : "N/A"}</p>
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`!py-2 !pr-4 font-rocGroteskMedium border-slate-200 text-sm text-gm-50 `}
                >
                    <div className="flex items-center gap-2">
                        <div
                            className={`w-2 h-2 rounded-full shrink-0 flex items-center justify-center captialize
                                   ${
                                       plan?.productionStatus === "pending"
                                           ? "bg-[#6366F1]"
                                           : plan?.productionStatus === "completed"
                                           ? "bg-[#F97316]"
                                           : plan?.productionStatus === "inProgress"
                                           ? "bg-[#3B82F6]"
                                           : plan?.productionStatus === "cancelled"
                                           ? "bg-[#EF4444]"
                                           : plan?.productionStatus === "onhold"
                                           ? "bg-[#EAB308]"
                                           : plan?.productionStatus === "rescheduled"
                                           ? "bg-[#8B5CF6]"
                                           : "bg-[#6B7280]"
                                   }
                                    `}
                        ></div>

                        {/* $
                        {idx === 0
                            ? "bg-[#3B82F6]"
                            : idx === 1
                            ? "bg-[#14B8A6]"
                            : idx === 2
                            ? "bg-[#22C55E]"
                            : idx === 3
                            ? "bg-[#F97316]"
                            : idx === 4
                            ? "bg-[#EF4444]"
                            : idx === 5
                            ? "bg-[#EAB308]"
                            : idx === 6
                            ? "bg-[#8B5CF6]"
                            : idx === 7
                            ? "bg-[#6366F1]"
                            : idx === 8
                            ? "bg-[#6B7280]"
                            : idx === 9 && "bg-[#0EA5E9]"} */}
                        <span className="capitalize">
                            {/* {idx === 0
                                ? "In Progress"
                                : idx === 1
                                ? "Planned"
                                : idx === 2
                                ? "Completed"
                                : idx === 3
                                ? "Delayed"
                                : idx === 4
                                ? "Cancelled"
                                : idx === 5
                                ? "On Hold"
                                : idx === 6
                                ? "Rescheduled"
                                : idx === 7
                                ? "Pending Approval"
                                : idx === 8
                                ? "Not Started"
                                : idx === 9 && "Partially Completed"} */}
                            {plan?.productionStatus === "inProgress"
                                ? "In progress"
                                : plan?.productionStatus === "onHold"
                                ? "On hold"
                                : plan?.productionStatus}
                        </span>
                    </div>
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`!py-2 !pr-4 font-rocGroteskMedium border-slate-200 text-sm text-gm-50 `}
                >
                    <div className="flex items-center gap-2">
                        <div className="w-5 h-5 rounded-full shrink-0 flex items-center justify-center bg-slate-100 ">
                            <i className="ri-user-follow-line text-sm text-gm-50"></i>
                        </div>

                        <span>{plan?.managerName}</span>
                    </div>
                </div>
            ),
        },
        // {
        //     content: (
        //         <div
        //             className={`!py-2 !pr-4 font-rocGroteskMedium border-slate-200 text-sm text-gm-50 `}
        //         >
        //             <p></p>
        //         </div>
        //     ),
        // },
    ]);
    return (
        <div>
            {isLoading && data?.length === 0 ? (
                <div className="overflow-x-hidden max-lg:hidden">
                    <CustomTableLoader tableHeader={tableHeader} />
                </div>
            ) : data?.length >= 1 ? (
                <>
                    <CustomTable
                        tableBody={tableBody}
                        tableHeader={tableHeader}
                        isInifiniteScroll={true}
                        isCellSelectable={false}
                        isAllSelectable={false}
                        stickyHeader={true}
                        headerContainerClass="!bg-[#F8FAFC] !border-slate-200"
                        headerItemClass="font-rocGroteskMedium !py-[11px] !px-4 !text-slate-700"
                        tableClass="mb-12 !border-collapse !border-slate-200"
                        bodyItemClass=" !border-slate-200"
                        isScrollable={true}
                    />
                    {isLoadingMore && (
                        <div className="flex justify-center my-4">
                            <Loader color="gm-25" size={4} />
                        </div>
                    )}

                    {pagination?.currentPage === pagination?.noOfPages && (
                        <div className="flex my-4 justify-center text-slate-500 text-sm font-rocGroteskMedium">
                            End of list
                        </div>
                    )}
                </>
            ) : (search || assigneeId || status) && data?.length === 0 ? (
                <div className="h-[200px] flex items-center justify-center border-x border-b border-slate-200">
                    <p className="text-slate-500 font-rocGroteskMedium text-[16px]">
                        Sorry, no results match your search. Refine your criteria and try again.
                    </p>
                </div>
            ) : (
                <div className="border-x border-b border-slate-200">
                    <EmptyStateComp
                        title="No production schedule plan found"
                        description="It looks like you don't have any production schedule plans at the moment. To get started, create a new schedule plan now!"
                        icon={<i className="ri-building-3-line text text-[40px]"></i>}
                        hasButton={true}
                        btnText="Create production plan"
                        btnAction={() => {
                            navigate("/dashboard/production/create-plan");
                        }}
                        contentContainerClass="flex flex-col items-center max-w-[537px] !bg-white"
                        descriptionClass="flex flex-col items-center"
                        className=" "
                    />
                </div>
            )}
        </div>
    );
};

export default ListView;
