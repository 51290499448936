import React from "react";

type ProgressBarProps = {
    progress: number;
    className?: string;
    containerClass?: string;
    showProgressLevel?: boolean;
    styling?: { [key: string]: any };
};

const ProgressBar = ({
    progress,
    className,
    containerClass,
    showProgressLevel,
    styling = {},
}: ProgressBarProps) => {
    return (
        <div className={`h-1 w-full bg-gray-100 ${containerClass}`}>
            <div
                className={`h-full bg-carribean-green ${className} relative`}
                style={{ width: `${progress}%`, ...styling }}
            >
                {showProgressLevel && (
                    <span className="w-[39px] h-[34px] flex items-center justify-center text-[10px] font-rocGroteskBold bg-white shadow-boxShadow-11 rounded-full absolute right-[-14px] top-[-12px]">
                        {progress ? progress + "%" : ""}
                    </span>
                )}
            </div>
        </div>
    );
};

export default ProgressBar;
