export const productionTypes = {
    FETCH_BOM_LIST_REQUEST: "FETCH_BOM_LIST_REQUEST",
    FETCH_BOM_LIST_SUCCESS: "FETCH_BOM_LIST_SUCCESS",
    FETCH_BOM_LIST_FAILURE: "FETCH_BOM_LIST_FAILURE",
    FETCH_SINGLE_BOM_REQUEST: "FETCH_SINGLE_BOM_REQUEST",
    FETCH_SINGLE_BOM_SUCCESS: "FETCH_SINGLE_BOM_SUCCESS",
    FETCH_SINGLE_BOM_FAILURE: "FETCH_SINGLE_BOM_FAILURE",
    CREATE_BOM_REQUEST: "CREATE_BOM_REQUEST",
    CREATE_BOM_SUCCESS: "CREATE_BOM_SUCCESS",
    CREATE_BOM_FAILURE: "CREATE_BOM_FAILURE",
    UPDATE_BOM_REQUEST: "UPDATE_BOM_REQUEST",
    UPDATE_BOM_SUCCESS: "UPDATE_BOM_SUCCESS",
    UPDATE_BOM_FAILURE: "UPDATE_BOM_FAILURE",
    FETCH_PRODUCT_WITHOUT_BOM_REQUEST: "FETCH_PRODUCT_WITHOUT_BOM_REQUEST",
    FETCH_PRODUCT_WITHOUT_BOM_SUCCESS: "FETCH_PRODUCT_WITHOUT_BOM_SUCCESS",
    FETCH_PRODUCT_WITHOUT_BOM_FAILURE: "FETCH_PRODUCT_WITHOUT_BOM_FAILURE",
    FETCH_NON_FINISHED_INVENTORY_REQUEST: "FETCH_NON_FINISHED_INVENTORY_REQUEST",
    FETCH_NON_FINISHED_INVENTORY_SUCCESS: "FETCH_NON_FINISHED_INVENTORY_SUCCESS",
    FETCH_NON_FINISHED_INVENTORY_FAILURE: "FETCH_NON_FINISHED_INVENTORY_FAILURE",
    CREATE_PRODUCTION_PLAN_REQUEST: "CREATE_PRODUCTION_PLAN_REQUEST",
    CREATE_PRODUCTION_PLAN_SUCCESS: "CREATE_PRODUCTION_PLAN_SUCCESS",
    CREATE_PRODUCTION_PLAN_FAILURE: "CREATE_PRODUCTION_PLAN_FAILURE",
    UPDATE_PRODUCTION_PLAN_REQUEST: "UPDATE_PRODUCTION_PLAN_REQUEST",
    UPDATE_PRODUCTION_PLAN_SUCCESS: "UPDATE_PRODUCTION_PLAN_SUCCESS",
    UPDATE_PRODUCTION_PLAN_FAILURE: "UPDATE_PRODUCTION_PLAN_FAILURE",
    FETCH_FINISHED_INVENTORY_REQUEST: "FETCH_FINISHED_INVENTORY_REQUEST",
    FETCH_FINISHED_INVENTORY_SUCCESS: "FETCH_FINISHED_INVENTORY_SUCCESS",
    FETCH_FINISHED_INVENTORY_FAILURE: "FETCH_FINISHED_INVENTORY_FAILURE",
    FETCH_PRODUCTION_PLAN_LIST_REQUEST: "FETCH_PRODUCTION_PLAN_LIST_REQUEST",
    FETCH_PRODUCTION_PLAN_LIST_SUCCESS: "FETCH_PRODUCTION_PLAN_LIST_SUCCESS",
    FETCH_PRODUCTION_PLAN_LIST_FAILURE: "FETCH_PRODUCTION_PLAN_LIST_FAILURE",
    FETCH_SINGLE_PRODUCTION_PLAN_REQUEST: "FETCH_SINGLE_PRODUCTION_PLAN_REQUEST",
    FETCH_SINGLE_PRODUCTION_PLAN_SUCCESS: "FETCH_SINGLE_PRODUCTION_PLAN_SUCCESS",
    FETCH_SINGLE_PRODUCTION_PLAN_FAILURE: "FETCH_SINGLE_PRODUCTION_PLAN_FAILURE",
    FETCH_PRODUCTION_PLAN_TAB_REQUEST: "FETCH_PRODUCTION_PLAN_TAB_REQUEST",
    FETCH_PRODUCTION_PLAN_TAB_SUCCESS: "FETCH_PRODUCTION_PLAN_TAB_SUCCESS",
    FETCH_PRODUCTION_PLAN_TAB_FAILURE: "FETCH_PRODUCTION_PLAN_TAB_FAILURE",
    UPDATE_PLAN_STEPS_REQUEST: "UPDATE_PLAN_STEPS_REQUEST",
    UPDATE_PLAN_STEPS_SUCCESS: "UPDATE_PLAN_STEPS_SUCCESS",
    UPDATE_PLAN_STEPS_FAILURE: "UPDATE_PLAN_STEPS_FAILURE",
    FETCH_PRODUCTION_ANALYTICS_REQUEST: "FETCH_PRODUCTION_ANALYTICS_REQUEST",
    FETCH_PRODUCTION_ANALYTICS_SUCCESS: "FETCH_PRODUCTION_ANALYTICS_SUCCESS",
    FETCH_PRODUCTION_ANALYTICS_FAILURE: "FETCH_PRODUCTION_ANALYSIS_FAILURE",
    CREATE_PRODUCTION_STEPS_REQUEST: "CREATE_PRODUCTION_STEPS_REQUEST",
    CREATE_PRODUCTION_STEPS_SUCCESS: "CREATE_PRODUCTION_STEPS_SUCCESS",
    CREATE_PRODUCTION_STEPS_FAILURE: "CREATE_PRODUCTION_STEPSFAILURE",
};
