import React, { createContext, useContext, useState, Dispatch, SetStateAction } from "react";

interface NavContextType {
    navName: string;
    showNavIcon:boolean;
    setNavName: Dispatch<SetStateAction<string>>;
    onClick: () => void;
    setOnClick: Dispatch<SetStateAction<() => void>>;
    setShowNavIcon: Dispatch<SetStateAction<boolean>>;
    
}

const NavContext = createContext<NavContextType>({
    navName: "",
    showNavIcon:false,
    setShowNavIcon:()=>{},
    setNavName: () => {},
    onClick: () => {},
    setOnClick: () => {},
});

export const useNav = () => useContext(NavContext);

export const NavProvider = ({ children }) => {
    const [navName, setNavName] = useState("");
    const [showNavIcon, setShowNavIcon] = useState(false);
    const [onClick, setOnClick] = useState(() => () => {});

    return (
        <NavContext.Provider value={{ navName, setNavName, onClick, setOnClick, setShowNavIcon, showNavIcon }}>
            {children}
        </NavContext.Provider>
    );
};
