import { useEffect, useMemo, useState } from "react";
import { inventoryActions, salesActions } from "redux/Ldb/actions";
import { useAppDispatch, useAppSelector } from "../storeHooks";
import { useParams } from "react-router-dom";
import { getCurrentStartAndEndDate } from "helpers/getCurrentStartAndEndDate";

const useGetSalesDashboardStats = () => {
    const dispatch = useAppDispatch();
    const {
        fetchingSalesDashboardStats,
        fetchSalesDashboardStatsSuccess,
        fetchSalesDashboardStatsFailure,
    } = useAppSelector((state) => state.sales);

    const [salesStatsData, setSalesStatsData] = useState<{ [key: string]: any }>({});
    const [startEndDate, setStartEndDate] = useState<{ [key: string]: any }>(
        getCurrentStartAndEndDate("monthly")
    );

    const handleDurationChange = (value: string) => {
        const startAndEndDate = getCurrentStartAndEndDate(value);
        setStartEndDate(startAndEndDate);
    };

    useEffect(() => {
        dispatch(salesActions.getSalesDashboardStats(startEndDate?.start, startEndDate?.end));

        return () => {
            dispatch(salesActions.resetGetSalesDashboardStatsSuccess());
        };
    }, [dispatch, startEndDate?.start, startEndDate?.end]);

    useEffect(() => {
        if (Boolean(fetchSalesDashboardStatsSuccess)) {
            setSalesStatsData(fetchSalesDashboardStatsSuccess?.analytics);
        }
    }, [dispatch, fetchSalesDashboardStatsSuccess]);

    return {
        isFetching: fetchingSalesDashboardStats,
        error: fetchSalesDashboardStatsFailure,
        data: salesStatsData,
        setSalesStatsData,
        handleDurationChange,
        setStartEndDate,
    };
};

export default useGetSalesDashboardStats;
