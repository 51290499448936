import React from "react";

type ActivityCardProps = {
    activityTitle: string,
    datetime?: string,
    icon?: React.ReactNode,
    iconContainerClass?: string,
    className?: string,
    containerNo?: string,
};

const ActivityCard = ({
    activityTitle,
    datetime,
    icon,
    iconContainerClass,
    className,
    containerNo,
}: ActivityCardProps) => {
    return (
        <div className={`py-3 grid grid-cols-[32px_auto] items-center gap-3 ${className}`}>
            <div
                className={`w-8 h-8 rounded bg-n-20 flex items-center justify-center ${iconContainerClass}`}
            >
                {/* <i className="ri-flag-fill text-slate-500"></i> */}
                {icon}
            </div>
            <div>
                <p className="text-sm font-rocGroteskMedium text-slate-800 mb-1">{activityTitle}</p>
                <div className="flex items-center gap-2.5">
                    {datetime && (
                        <p className="text-sm font-rocGroteskRegular text-slate-500 mb-1">
                            Event time: <span className="font-rocGroteskMedium">{datetime}</span>
                        </p>
                    )}
                    {containerNo && (
                        <p className="text-sm font-rocGroteskRegular text-slate-500 mb-1">
                            Container number:{" "}
                            <span className="font-rocGroteskMedium">{containerNo}</span>
                        </p>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ActivityCard;
