import { useEffect, useState } from "react";
import { inventoryActions } from "redux/Ldb/actions";
import { useAppDispatch, useAppSelector } from "./storeHooks";
import { useParams } from "react-router-dom";

const useGetSingleProductBom = () => {
    const dispatch = useAppDispatch();
    const { productId } = useParams();
    const {
        fetchingSingleProductBom,
        fetchedSingleProductBomSuccess,
        fetchedSingleProductBomFailure,
    } = useAppSelector((state) => state.inventory);

    const [recipeData, setRecipeData] = useState<{ [key: string]: any }[]>([]);
    const [pagination, setPagination] = useState<{ currentPage: string; noOfPages: string }>({
        currentPage: "1",
        noOfPages: "",
    });

    useEffect(() => {
        // 661d0125cf366cf36104a8a0
        dispatch(inventoryActions.fetchSingleProductBom(productId));

        return () => {
            dispatch(inventoryActions.resetFetchSingleProductBom());
        };
    }, [dispatch, productId]);

    useEffect(() => {
        if (Boolean(fetchedSingleProductBomSuccess)) {
            setRecipeData(fetchedSingleProductBomSuccess?.billOfMaterialIds);
            setPagination({
                currentPage: fetchedSingleProductBomSuccess.pagination?.current,
                noOfPages: fetchedSingleProductBomSuccess.pagination?.number_of_pages,
            });
        }
    }, [fetchedSingleProductBomSuccess, productId]);

    return {
        isFetching: fetchingSingleProductBom,
        error: fetchedSingleProductBomFailure,
        data: recipeData,
        pagination,
    };
};

export default useGetSingleProductBom;
