const FAQs = [
    {
        title: "What does SynthAlly offer to freight forwarders?",
        content:
            "SynthAlly is a user-first software that makes it simple for freight forwarders to automate partner  management processes, connect with global freight partners, and scale their business operations. It enables forwarder-to-forwarder collaboration for small and mid-sized freight brokers.",
    },
    {
        title: "What payment methods are accepted for SynthAlly?",
        content:
            "There are different payment options available toSynthAlly users namely Bank Transfer, Pay with Card. We are also adding other options like Pay with USSD capability in the near future.",
    },
    {
        title: "Is there a free trial period for SynthAlly?",
        content:
            "Yes, there is a 30-Day free trial period on SynthAlly where you can access all the features available on both the Web and Mobile App from Shipment and Quote Management to Document Folder and Rate Management.",
    },
    {
        title: "How do I create an account with SynthAlly?",
        content:
            "Simply signup to the platform if you are using the web platform or download the Mobile App either on Google or Apple Play store and sign up using your Business email, Phone number and Country location and Address. Your Account will be confirmed after you have added Trade Lanes, Mode of Shipment and also Business registration details or documents",
    },
];

export default FAQs;
