import React from "react";

type RatingProps = {
    ratingNo: number;
    totalRating?: number;
    size?: "xs" | "sm" | "lg" | "xl";
};

const Rating = ({ratingNo, size="sm", totalRating = 5}: RatingProps) => {
    const totalRatingArray = Array.from(Array(totalRating).keys());

    return (
        <div className={`flex items-center ${size==="sm"?"gap-2":"gap-1"}`}>
            {totalRatingArray?.map((value, idx) =>
                idx < ratingNo ? (
                    <i key={value + "-" + idx} className={`ri-star-fill text-star-gold text-${size} `}></i>
                ) : (
                    <i key={value + "-" + idx} className={`ri-star-line text-slate-300 text-${size}`}></i>
                )
            )}
        </div>
    );
};

export default Rating;
