import { useEffect, useMemo, useState } from "react";
import { inventoryActions, trackTraceActions } from "redux/Ldb/actions";
import { useAppDispatch, useAppSelector } from "../storeHooks";
import { debounce } from "lodash";
import useInfiniteScroll from "../useInfinteScroll";

const useGetTrackTraceCard = () => {
    const dispatch = useAppDispatch();
    const [trackTraceCard, setTrackTraceCard] = useState<{ [key: string]: any }>({});
    const [channel, setChannel] = useState<string>("");

    const { fetchingTrackTraceCard, fetchTrackTraceCardSuccess, fetchTrackTraceCardFailure } =
        useAppSelector((state) => state.trackTrace);

    useEffect(() => {
        if (Boolean(fetchTrackTraceCardSuccess)) {
            setTrackTraceCard(fetchTrackTraceCardSuccess?.result);
        }
    }, [fetchTrackTraceCardSuccess]);

    useEffect(() => {
        dispatch(trackTraceActions.getTrackTraceCard(channel));
    }, [dispatch, channel]);

    return {
        isFetching: fetchingTrackTraceCard,
        error: fetchTrackTraceCardFailure,
        data: trackTraceCard,
        channel,
        setChannel,
    };
};

export default useGetTrackTraceCard;
