import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../storeHooks";
import { trackTraceActions } from "redux/Ldb/actions";
import { debounce } from "lodash";
import useInfiniteScroll from "../useInfinteScroll";

const useGetTrackTraceRawMaterials = () => {
    const dispatch = useAppDispatch();
    const [search, setSearch] = useState<string>("");
    const [debouncedSearch, setDebouncedSearch] = useState<string>("");
    const [rawMaterials, setRawMaterials] = useState<{ [key: string]: any }>([]);
    const [sortValues, setSortValues] = useState<any>({});
    const [warehouseId, setWarehouseId] = useState<string>("");
    const [dateRange, setDateRange] = useState<string>("");

    const [pagination, setPagination] = useState<{ currentPage: string; noOfPages: string }>({
        currentPage: "1",
        noOfPages: "",
    });

    const { updatedLimit, isLoadingMore, setLoadMore } = useInfiniteScroll(
        { current: pagination.currentPage, number_of_pages: pagination.noOfPages },
        10
    );

    const { fetchingRawMaterials, fetchRawMaterialsSuccess, fetchRawMaterialsFailure } =
        useAppSelector((state) => state.trackTrace);

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(e.target.value);
    };

    const handleDebouncedChange = debounce((e) => {
        setDebouncedSearch(e.target.value);
    }, 500);

    useEffect(() => {
        if (Boolean(fetchRawMaterialsSuccess)) {
            setRawMaterials(fetchRawMaterialsSuccess?.products);
            setPagination({
                currentPage: fetchRawMaterialsSuccess.pagination?.current,
                noOfPages: fetchRawMaterialsSuccess.pagination?.number_of_pages,
            });
            setLoadMore(false);
        }
    }, [fetchRawMaterialsSuccess]);

    useEffect(() => {
        dispatch(
            trackTraceActions.getRawMaterials(
                updatedLimit,
                debouncedSearch,
                warehouseId,
                dateRange,
                sortValues
            )
        );
    }, [dispatch, updatedLimit, debouncedSearch, warehouseId, dateRange, sortValues]);

    return {
        data: rawMaterials,
        isFetching: fetchingRawMaterials,
        error: fetchRawMaterialsFailure,
        isLoadingMore,
        handleSearch,
        handleDebouncedChange,
        setDateRange,
        pagination,
        search,
        setPagination,
        setSortValues,
        setWarehouseId,
    };
};

export default useGetTrackTraceRawMaterials;
