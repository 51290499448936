type VendorCategoryType = {
    category: string,
    documents: {
        title: string,
        required: boolean,
    }[],
}[];

export const vendorCategories: VendorCategoryType = [
    {
        category: "Freight Forwarder and Customs Broker",
        documents: [
            {
                title: "Business registration",
                required: true,
            },
            {
                title: "Licensing",
                required: true,
            },
            {
                title: "Insurance",
                required: true,
            },
            {
                title: "Audited Financial Account",
                required: true,
            },
            {
                title: "Tax Clearance",
                required: true,
            },
            {
                title: "Track record of successful shipments",
                required: false,
            },
            {
                title: "Capacity assessment",
                required: false,
            },
            {
                title: "Trade lanes",
                required: false,
            },
            {
                title: "HSE",
                required: false,
            },
        ],
    },
    {
        category: "Carriers and Shipping Lines",
        documents: [
            {
                title: "Evidence of Vessel ownership",
                required: true,
            },
            {
                title: "Company Registration docs",
                required: true,
            },
            {
                title: "Tax Clearance",
                required: true,
            },
            {
                title: "Audited Financial Account",
                required: false,
            },
            {
                title: "Compliance with maritime regulations",
                required: true,
            },
            {
                title: "Record of safe and timely shipments",
                required: false,
            },
            {
                title: "Insurance",
                required: true,
            },
        ],
    },
    {
        category: "Haulage Company",
        documents: [
            {
                title: "Business registration",
                required: true,
            },
            {
                title: "Tax Clearance",
                required: true,
            },
            {
                title: "Audited Financial Account",
                required: false,
            },
            {
                title: "Fleet of trucks",
                required: true,
            },
            {
                title: "Licenses",
                required: true,
            },
            {
                title: "GIT insurance",
                required: true,
            },
            {
                title: "Drivers License",
                required: true,
            },
            {
                title: "PPE evidence",
                required: true,
            },
            {
                title: "HSE policy",
                required: false,
            },
        ],
    },
    {
        category: "Courier and Parcel",
        documents: [
            {
                title: "Business registration",
                required: true,
            },
            {
                title: "Tax Clearance",
                required: true,
            },
            {
                title: "Audited Financial Account",
                required: false,
            },
            {
                title: "Fleet of trucks",
                required: true,
            },
            {
                title: "Licenses",
                required: true,
            },
            {
                title: "GIT insurance",
                required: true,
            },
            {
                title: "Drivers License",
                required: true,
            },
            {
                title: "PPE evidence",
                required: true,
            },
            {
                title: "HSE policy",
                required: false,
            },
        ],
    },
    {
        category: "Last Mile Delivery",
        documents: [
            {
                title: "Business registration",
                required: true,
            },
            {
                title: "Tax Clearance",
                required: true,
            },
            {
                title: "Audited Financial Account",
                required: false,
            },
            {
                title: "Fleet of trucks",
                required: true,
            },
            {
                title: "Licenses",
                required: true,
            },
            {
                title: "GIT insurance",
                required: true,
            },
            {
                title: "Drivers License",
                required: true,
            },
            {
                title: "PPE evidence",
                required: true,
            },
            {
                title: "HSE policy",
                required: false,
            },
        ],
    },
    {
        category: "Insurance Provider",
        documents: [
            {
                title: "Business registration",
                required: false,
            },
            {
                title: "Insurance Policies",
                required: false,
            },
            {
                title: "Coverage Details",
                required: false,
            },
        ],
    },
    {
        category: "Fulfillment Center Service Provider",
        documents: [
            {
                title: "Business Registration",
                required: false,
            },
            {
                title: "Fulfillment Service Agreement",
                required: false,
            },
            {
                title: "Insurance Certificate",
                required: false,
            },
        ],
    },
    {
        category: "Cold Chain Logistics Provider",
        documents: [
            {
                title: "Business Registration",
                required: false,
            },
            {
                title: "Insurance Certificate",
                required: false,
            },
            {
                title: "Temperature Control Certifications",
                required: false,
            },
            {
                title: "Cold Chain Handling Processes",
                required: false,
            },
        ],
    },
    {
        category: "Technology Provider",
        documents: [
            {
                title: "Business Registration",
                required: false,
            },
            {
                title: "Product Information",
                required: false,
            },
            {
                title: "Software Specifications",
                required: false,
            },
        ],
    },
    {
        category: "Packaging Supplier",
        documents: [
            {
                title: "Business Registration and Licenses",
                required: false,
            },
            {
                title: "Tax Identification Number (TIN)",
                required: false,
            },
            {
                title: "Certificate of Insurance",
                required: false,
            },
            {
                title: "W-9 Form (United States)",
                required: false,
            },
            {
                title: "Supplier Code of Conduct",
                required: false,
            },
            {
                title: "References or Customer Testimonials",
                required: false,
            },
            {
                title: "Safety Certifications",
                required: false,
            },
            {
                title: "Warranty and Service Agreements",
                required: false,
            },
            {
                title: "Financial Statements",
                required: false,
            },
        ],
    },
    {
        category: "Equipment and Machinery Supplier",
        documents: [
            {
                title: "Business Registration and Licenses",
                required: false,
            },
            {
                title: "Tax Identification Number (TIN)",
                required: false,
            },
            {
                title: "Certificate of Insurance",
                required: false,
            },
            {
                title: "W-9 Form (United States)",
                required: false,
            },
            {
                title: "Supplier Code of Conduct",
                required: false,
            },
            {
                title: "References or Customer Testimonials",
                required: false,
            },
            {
                title: "Safety Certifications",
                required: false,
            },
            {
                title: "Warranty and Service Agreements",
                required: false,
            },
            {
                title: "Financial Statements",
                required: false,
            },
        ],
    },
    {
        category: "Warehousing and Distribution",
        documents: [
            {
                title: "Company registration Docs",
                required: true,
            },
            {
                title: "Tax Clearance",
                required: true,
            },
            {
                title: "Audited Financial Account",
                required: false,
            },
            {
                title: "Warehouse infrastructure and Machinery",
                required: true,
            },
            {
                title: "Inventory management capabilities",
                required: true,
            },
            {
                title: "Security measures",
                required: true,
            },
            {
                title: "Distribution network",
                required: false,
            },
        ],
    },
];
