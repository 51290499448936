import React from "react";
import CustomToast from "components/common/CustomToast";
import { toast } from "react-hot-toast";
import { Dispatch } from "redux";
import { trackTraceTypes } from "../types";
import { trackTraceService } from "services/Ldb";

export const trackTraceActions = {
    getTrackTraceCard,
    getFinishedGoods,
    getRawMaterials,
    getBatchDetails,
    getPreviouslySearched,
    addPreviouslySearched,
    resetAddPreviouslySearchedSuccess,
    getTrackSearchResult,
    getBatchIncidents,
    getBatchTracking,
    getBatchWarehouses,
    getBatchDistributors,
    getBatchCustomers,
};

function getTrackTraceCard(channel?: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(trackTraceTypes.FETCH_TRACK_TRACE_CARD_REQUEST));

        trackTraceService.getTrackTraceCard(channel).then(
            (res) => {
                dispatch(success(trackTraceTypes.FETCH_TRACK_TRACE_CARD_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(trackTraceTypes.FETCH_TRACK_TRACE_CARD_FAILURE, error?.message)
                    );
                }
            }
        );
    };
}

function getFinishedGoods(
    limit?: number,
    page?: number,
    search?: string,
    warehouseId?: string,
    dateRange?: string,
    sortParams?: any,
    
) {
    return (dispatch: Dispatch) => {
        dispatch(request(trackTraceTypes.FETCH_FINISHED_GOODS_REQUEST));

        trackTraceService.getFinishedGoods(limit, page, search, warehouseId, dateRange, sortParams).then(
            (res) => {
                dispatch(success(trackTraceTypes.FETCH_FINISHED_GOODS_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(trackTraceTypes.FETCH_FINISHED_GOODS_FAILURE, error?.message));
                }
            }
        );
    };
}

function getRawMaterials(
    limit?: number,
    search?: string,
    warehouseId?: string,
    dateRange?: string,
    sortParams?: any
) {
    return (dispatch: Dispatch) => {
        dispatch(request(trackTraceTypes.FETCH_RAW_MATERIALS_REQUEST));
        trackTraceService.getRawMaterials(limit, search, warehouseId, dateRange, sortParams).then(
            (res) => {
                dispatch(success(trackTraceTypes.FETCH_RAW_MATERIALS_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(trackTraceTypes.FETCH_RAW_MATERIALS_FAILURE, error?.message));
                }
            }
        );
    };
}

function getBatchDetails(id?: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(trackTraceTypes.FETCH_BATCH_DETAILS_REQUEST));

        trackTraceService.getBatchDetails(id).then(
            (res) => {
                dispatch(success(trackTraceTypes.FETCH_BATCH_DETAILS_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(trackTraceTypes.FETCH_BATCH_DETAILS_FAILURE, error?.message));
                }
            }
        );
    };
}

function getPreviouslySearched() {
    return (dispatch: Dispatch) => {
        dispatch(request(trackTraceTypes.FETCH_PREVIOUSLY_SEARCHED_REQUEST));

        trackTraceService.getPreviouslySearched().then(
            (res) => {
                dispatch(success(trackTraceTypes.FETCH_PREVIOUSLY_SEARCHED_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(trackTraceTypes.FETCH_PREVIOUSLY_SEARCHED_FAILURE, error?.message)
                    );
                }
            }
        );
    };
}
function getTrackSearchResult(limit?: number,page?: number, search?: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(trackTraceTypes.FETCH_TRACK_SEARCH_RESULT_REQUEST));

        trackTraceService.getFinishedGoods(limit,page, search).then(
            (res) => {
                dispatch(success(trackTraceTypes.FETCH_TRACK_SEARCH_RESULT_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(trackTraceTypes.FETCH_TRACK_SEARCH_RESULT_FAILURE, error?.message)
                    );
                }
            }
        );
    };
}

function addPreviouslySearched(data: Record<string, any> | Array<Record<string, any>>) {
    return (dispatch: Dispatch) => {
        dispatch(request(trackTraceTypes.ADD_PREVIOUSLY_SEARCHED_REQUEST));

        trackTraceService.addPreviouslySearched(data).then(
            (res) => {
                dispatch(success(trackTraceTypes.ADD_PREVIOUSLY_SEARCHED_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(trackTraceTypes.ADD_PREVIOUSLY_SEARCHED_FAILURE, error?.message)
                    );
                }
            }
        );
    };
}

function getBatchIncidents(batchId?: string, channel?: string, dateRange?: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(trackTraceTypes.FETCH_BATCH_INCIDENTS_REQUEST));

        trackTraceService.getBatchIncidents(batchId, channel, dateRange).then(
            (res) => {
                dispatch(success(trackTraceTypes.FETCH_BATCH_INCIDENTS_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(trackTraceTypes.FETCH_BATCH_INCIDENTS_FAILURE, error?.message)
                    );
                }
            }
        );
    };
}

function getBatchTracking(batchId?: string, dateRange?: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(trackTraceTypes.FETCH_BATCH_TRACKING_REQUEST));

        trackTraceService.getBatchTracking(batchId, dateRange).then(
            (res) => {
                dispatch(success(trackTraceTypes.FETCH_BATCH_TRACKING_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(trackTraceTypes.FETCH_BATCH_TRACKING_FAILURE, error?.message));
                }
            }
        );
    };
}

function getBatchWarehouses(state?: string, batchId?: string, limit?: number) {
    return (dispatch: Dispatch) => {
        dispatch(request(trackTraceTypes.FETCH_BATCH_WAREHOUSES_REQUEST));

        trackTraceService.getBatchWarehouses(state, batchId, limit).then(
            (res) => {
                dispatch(success(trackTraceTypes.FETCH_BATCH_WAREHOUSES_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(trackTraceTypes.FETCH_BATCH_WAREHOUSES_FAILURE, error?.message)
                    );
                }
            }
        );
    };
}

function getBatchDistributors(state?: string, batchId?: string, limit?: number) {
    return (dispatch: Dispatch) => {
        dispatch(request(trackTraceTypes.FETCH_BATCH_DISTRIBUTORS_REQUEST));

        trackTraceService.getBatchDistributors(state, batchId, limit).then(
            (res) => {
                dispatch(success(trackTraceTypes.FETCH_BATCH_DISTRIBUTORS_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(trackTraceTypes.FETCH_BATCH_DISTRIBUTORS_FAILURE, error?.message)
                    );
                }
            }
        );
    };
}

function getBatchCustomers(state?: string, batchId?: string, limit?: number) {
    return (dispatch: Dispatch) => {
        dispatch(request(trackTraceTypes.FETCH_BATCH_CUSTOMERS_REQUEST));

        trackTraceService.getBatchCustomers(state, batchId, limit).then(
            (res) => {
                dispatch(success(trackTraceTypes.FETCH_BATCH_CUSTOMERS_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(trackTraceTypes.FETCH_BATCH_CUSTOMERS_FAILURE, error?.message)
                    );
                }
            }
        );
    };
}

function resetAddPreviouslySearchedSuccess() {
    return (dispatch) => {
        dispatch(success(trackTraceTypes.ADD_PREVIOUSLY_SEARCHED_SUCCESS, null));
    };
}

function request(type: string) {
    return { type: type };
}
function success(type: string, data?: any) {
    return { type: type, payload: data };
}
function failure(type: string, error?: any) {
    return { type: type, payload: error ?? "" };
}
