import DocumentCard from "components/common/DocumentCard";
import {downloadUrl} from "helpers/fileDownload";
import React from "react";

type DocumentsTabProps = {
    docs: {[key: string]: any}[];
    showNoContent?: boolean;
    dropDownClass?: string;
    hideUpload?: boolean;
    contentClass?: string;
};

const DocumentsTab = ({docs, showNoContent, dropDownClass, hideUpload, contentClass}: DocumentsTabProps) => {
    return docs && docs?.length > 0 ? (
        <div className="">
            {!showNoContent && (
                <div className="flex justify-between items-center mb-4 px-6 pt-6">
                    <p className="text-base text-slate-700 font-rocGroteskMedium">Documents</p>
                    {!hideUpload && (
                        <p className="text-[13px] border border-n-20 rounded px-4 py-2 cursor-pointer text-gm-50 font-rocGroteskMedium ">
                            Upload document
                        </p>
                    )}
                </div>
            )}

            <div className={`overflow-auto ${contentClass}`}>
                {docs?.map((doc, idx: number) => (
                    <DocumentCard
                        key={doc?.name + idx}
                        name={doc?.name}
                        fileUrl={doc?.path || doc?.file}
                        dropdownOptions={[
                            {
                                label: "Download",
                                value: "download",
                                action: async () => {
                                    const fbDownload = () => {
                                        const anchor = document.createElement("a");
                                        anchor.style.display = "none";
                                        anchor.href = doc?.path || doc?.file;
                                        anchor.download = doc?.name || doc?.filename;
                                        anchor.target = "_blank";
                                        anchor.rel = "noreferrer";
                                        document.body.appendChild(anchor);
                                        anchor.click();
                                        document.body.removeChild(anchor);
                                    };

                                    return (doc?.path || doc?.file)?.includes("firebase")
                                        ? fbDownload()
                                        : downloadUrl(
                                              doc?.path || doc?.file,
                                              doc?.name || doc?.filename
                                          );
                                },
                                disabled: !doc?.path && !doc?.file,
                            },
                        ]}
                        dropDownClass={dropDownClass}
                    />
                ))}
            </div>
        </div>
    ) : (
        <div className={`mt-[111px] flex justify-center`}>
            <div className="text-center w-[35%]">
                <i className="ri-file-line text-5xl text-slate-500 "></i>
                <p className="text-base font-rocGroteskMedium mb-1 mt-4">No Documents found</p>
                <p className="text-sm font-rocGroteskRegular text-slate-500">
                    It seems like there are no documents found.
                </p>
            </div>
        </div>
    );
};

export default DocumentsTab;
