import React from "react";
import { integrationTypes } from "redux//Ldb/types";
import { toast } from "react-hot-toast";
import CustomToast from "../../../components/common/CustomToast";
import { integrationService } from "services/Ldb";
import { Dispatch } from "redux";

export const integrationActions = {
    getQuickbooksExchangeCode,
    resetGetQuickbooksExchangeCodeSuccess,
    resetSetUpQuickbooksSuccess,
    setUpQuickbooks,
    getQuickbooksOauthUrl,
    getQuickbooksExpenseList,
    getQuickbooksApList,
    resetGetQuickbooksOauthUrlSuccess,
    getShopifyOauthUrl,
    verifyShopifyAccount,
    resetVerifyShopifySuccess,
    resetGetShopifyOauthUrlSuccess,
    fetchSocialAccount,
    updateSocialAccount,
    resetUpdateSocialAccountSuccess,
    getQuickbooksIncomeAccounts,
    getQuickbooksAssetAccounts,
    getShopifyData,
    resetShopifyDataSuccess,
    getAmazonOauthUrl,
    getAmazonData,
    resetAmazonDataSuccess,
    resetAmazonAuthSuccess,
    getSpsCommerceOauthUrl,
    resetSpsCommerceOauthUrlSuccess,
    completeSpsCommerceConnection,
    resetCompleteSpsCommerceConnectionSuccess,
    getQbDesktopOauthUrl,
    resetQbDesktopOauthUrlSuccess,
    completeQbDesktopConnection,
    resetCompleteQbDesktopConnectionSuccess,
    getQbDesktopCurrentAssetAccounts,
    getQbDesktopFixedAssetAccounts,
    getQbDesktopExpenseAccounts,
    getQbDesktopIncomeAccounts,
    getQbDesktopApAccounts,
    setUpQbDesktop,
    resetSetupQbDesktopSuccess,
};

function getQuickbooksExchangeCode(data: Record<string, any> | Array<Record<string, any>>) {
    return (dispatch: Dispatch) => {
        dispatch(request(integrationTypes.GET_QUICKBOOKS_EXCHANGE_CODE_REQUEST));

        integrationService.fetchQuickbooksExchangeCode(data).then(
            (res) => {
                dispatch(success(integrationTypes.GET_QUICKBOOKS_EXCHANGE_CODE_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(
                            integrationTypes.GET_QUICKBOOKS_EXCHANGE_CODE_FAILURE,
                            error?.message
                        )
                    );
                }
            }
        );
    };
}

function getQuickbooksOauthUrl() {
    return (dispatch: Dispatch) => {
        dispatch(request(integrationTypes.GET_QUICKBOOKS_OAUTH_URL_REQUEST));

        integrationService.fetchQuickbooksOauthUrl().then(
            (res) => {
                dispatch(success(integrationTypes.GET_QUICKBOOKS_OAUTH_URL_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(integrationTypes.GET_QUICKBOOKS_OAUTH_URL_FAILURE, error?.message)
                    );
                }
            }
        );
    };
}

function getSpsCommerceOauthUrl() {
    return (dispatch: Dispatch) => {
        dispatch(request(integrationTypes.GET_SPS_COMMERCE_OAUTH_URL_REQUEST));

        integrationService.fetchSpsCommerceOauthUrl().then(
            (res) => {
                dispatch(success(integrationTypes.GET_SPS_COMMERCE_OAUTH_URL_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(integrationTypes.GET_SPS_COMMERCE_OAUTH_URL_FAILURE, error?.message)
                    );
                }
            }
        );
    };
}

function completeSpsCommerceConnection(data: Record<string, any> | Array<Record<string, any>>) {
    return (dispatch: Dispatch) => {
        dispatch(request(integrationTypes.COMPLETE_SPS_COMMERCE_CONNECTION_REQUEST));

        integrationService.completeSpsCommerceConnection(data).then(
            (res) => {
                dispatch(
                    success(integrationTypes.COMPLETE_SPS_COMMERCE_CONNECTION_SUCCESS, res?.data)
                );
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(
                            integrationTypes.COMPLETE_SPS_COMMERCE_CONNECTION_FAILURE,
                            error?.message
                        )
                    );
                }
            }
        );
    };
}

function getQbDesktopOauthUrl() {
    return (dispatch: Dispatch) => {
        dispatch(request(integrationTypes.GET_QB_DESKTOP_OAUTH_URL_REQUEST));

        integrationService.fetchQbDesktopOauthUrl().then(
            (res) => {
                dispatch(success(integrationTypes.GET_QB_DESKTOP_OAUTH_URL_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(integrationTypes.GET_QB_DESKTOP_OAUTH_URL_FAILURE, error?.message)
                    );
                }
            }
        );
    };
}

function completeQbDesktopConnection() {
    return (dispatch: Dispatch) => {
        dispatch(request(integrationTypes.COMPLETE_QB_DESKTOP_CONNECTION_REQUEST));

        integrationService.completeQbDesktopConneection().then(
            (res) => {
                dispatch(
                    success(integrationTypes.COMPLETE_QB_DESKTOP_CONNECTION_SUCCESS, res?.data)
                );
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(
                            integrationTypes.COMPLETE_QB_DESKTOP_CONNECTION_FAILURE,
                            error?.message
                        )
                    );
                }
            }
        );
    };
}

function getAmazonOauthUrl() {
    return (dispatch: Dispatch) => {
        dispatch(request(integrationTypes.GET_AMAZON_OAUTH_URL_REQUEST));

        integrationService.fetchAmazonOauthUrl().then(
            (res) => {
                dispatch(success(integrationTypes.GET_AMAZON_OAUTH_URL_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(integrationTypes.GET_AMAZON_OAUTH_URL_FAILURE, error?.message)
                    );
                }
            }
        );
    };
}

function getQuickbooksApList() {
    return (dispatch: Dispatch) => {
        dispatch(request(integrationTypes.FETCH_QUICKBOOKS_AP_ACCOUNTS_REQUEST));

        integrationService.fetchQuickbooksApAccounts().then(
            (res) => {
                dispatch(success(integrationTypes.FETCH_QUICKBOOKS_AP_ACCOUNTS_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    // toast.custom((t) => (
                    //     <CustomToast t={t} message={error?.message} type="error" />
                    // ));
                    dispatch(
                        failure(
                            integrationTypes.FETCH_QUICKBOOKS_AP_ACCOUNTS_FAILURE,
                            error?.message
                        )
                    );
                }
            }
        );
    };
}
function getShopifyData(data: Record<string, any> | Array<Record<string, any>>) {
    return (dispatch: Dispatch) => {
        dispatch(request(integrationTypes.FETCH_SHOPIFY_DATA_REQUEST));

        integrationService.fetchShopifyData(data).then(
            (res) => {
                dispatch(success(integrationTypes.FETCH_SHOPIFY_DATA_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    // toast.custom((t) => (
                    //     <CustomToast t={t} message={error?.message} type="error" />
                    // ));
                    dispatch(failure(integrationTypes.FETCH_SHOPIFY_DATA_FAILURE, error?.message));
                }
            }
        );
    };
}

function getAmazonData(data: Record<string, any> | Array<Record<string, any>>) {
    return (dispatch: Dispatch) => {
        dispatch(request(integrationTypes.VERIFY_AMAZON_ACCOUNT_REQUEST));

        integrationService.fetchAmazonData(data).then(
            (res) => {
                dispatch(success(integrationTypes.VERIFY_AMAZON_ACCOUNT_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    // toast.custom((t) => (
                    //     <CustomToast t={t} message={error?.message} type="error" />
                    // ));
                    dispatch(
                        failure(integrationTypes.VERIFY_AMAZON_ACCOUNT_FAILURE, error?.message)
                    );
                }
            }
        );
    };
}

function getQuickbooksExpenseList() {
    return (dispatch: Dispatch) => {
        dispatch(request(integrationTypes.FETCH_QUICKBOOKS_EXPENSE_ACCOUNTS_REQUEST));

        integrationService.fetchQuickbooksExpenseAccounts().then(
            (res) => {
                dispatch(
                    success(integrationTypes.FETCH_QUICKBOOKS_EXPENSE_ACCOUNTS_SUCCESS, res?.data)
                );
            },
            (error) => {
                if (error.message) {
                    // toast.custom((t) => (
                    //     <CustomToast t={t} message={error?.message} type="error" />
                    // ));
                    dispatch(
                        failure(
                            integrationTypes.FETCH_QUICKBOOKS_EXPENSE_ACCOUNTS_FAILURE,
                            error?.message
                        )
                    );
                }
            }
        );
    };
}

function getQuickbooksIncomeAccounts() {
    return (dispatch: Dispatch) => {
        dispatch(request(integrationTypes.FETCH_QUICKBOOKS_INCOME_ACCOUNTS_REQUEST));

        integrationService.fetchIncomeAccounts().then(
            (res) => {
                dispatch(
                    success(integrationTypes.FETCH_QUICKBOOKS_INCOME_ACCOUNTS_SUCCESS, res?.data)
                );
            },
            (error) => {
                if (error.message) {
                    dispatch(
                        failure(
                            integrationTypes.FETCH_QUICKBOOKS_INCOME_ACCOUNTS_FAILURE,
                            error?.message
                        )
                    );
                }
            }
        );
    };
}

function getQuickbooksAssetAccounts() {
    return (dispatch: Dispatch) => {
        dispatch(request(integrationTypes.FETCH_QUICKBOOKS_ASSET_ACCOUNTS_REQUEST));

        integrationService.fetchAssetAccounts().then(
            (res) => {
                dispatch(
                    success(integrationTypes.FETCH_QUICKBOOKS_ASSET_ACCOUNTS_SUCCESS, res?.data)
                );
            },
            (error) => {
                if (error.message) {
                    dispatch(
                        failure(
                            integrationTypes.FETCH_QUICKBOOKS_ASSET_ACCOUNTS_FAILURE,
                            error?.message
                        )
                    );
                }
            }
        );
    };
}

function setUpQuickbooks(data: Record<string, any> | Array<Record<string, any>>) {
    return (dispatch: Dispatch) => {
        dispatch(request(integrationTypes.SET_UP_QUICKBOOKS_ACCOUNT_REQUEST));

        integrationService.setUpQuickbooks(data).then(
            (res) => {
                dispatch(success(integrationTypes.SET_UP_QUICKBOOKS_ACCOUNT_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(integrationTypes.SET_UP_QUICKBOOKS_ACCOUNT_FAILURE, error?.message)
                    );
                }
            }
        );
    };
}

function getShopifyOauthUrl(storeName) {
    return (dispatch: Dispatch) => {
        dispatch(request(integrationTypes.GET_SHOPIFY_OAUTH_URL_REQUEST));

        integrationService.fetchShopifyOauthUrl(storeName).then(
            (res) => {
                dispatch(success(integrationTypes.GET_SHOPIFY_OAUTH_URL_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(integrationTypes.GET_SHOPIFY_OAUTH_URL_FAILURE, error?.message)
                    );
                }
            }
        );
    };
}

function verifyShopifyAccount(data: Record<string, any> | Array<Record<string, any>>) {
    return (dispatch: Dispatch) => {
        dispatch(request(integrationTypes.VERIFY_SHOPIFY_ACCOUNT_REQUEST));

        integrationService.verifyShopifyAccount(data).then(
            (res) => {
                dispatch(success(integrationTypes.VERIFY_SHOPIFY_ACCOUNT_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(integrationTypes.VERIFY_SHOPIFY_ACCOUNT_FAILURE, error?.message)
                    );
                }
            }
        );
    };
}

function fetchSocialAccount(id: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(integrationTypes.FETCH_SOCIAL_ACCOUNT_REQUEST));

        integrationService.fetchSocialAccount(id).then(
            (res) => {
                dispatch(success(integrationTypes.FETCH_SOCIAL_ACCOUNT_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(integrationTypes.FETCH_SOCIAL_ACCOUNT_FAILURE, error?.message)
                    );
                }
            }
        );
    };
}

function updateSocialAccount(
    data: Record<string, any> | Array<Record<string, any>>,
    socialId: string
) {
    return (dispatch: Dispatch) => {
        dispatch(request(integrationTypes.UPDATE_SOCIAL_ACCOUNT_REQUEST));

        integrationService.updateSocialAccount(data, socialId).then(
            (res) => {
                dispatch(success(integrationTypes.UPDATE_SOCIAL_ACCOUNT_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(integrationTypes.UPDATE_SOCIAL_ACCOUNT_FAILURE, error?.message)
                    );
                }
            }
        );
    };
}

function getQbDesktopCurrentAssetAccounts() {
    return (dispatch: Dispatch) => {
        dispatch(request(integrationTypes.FETCH_QB_DESKTOP_CURRENT_ASSET_ACCOUNTS_REQUEST));

        integrationService.fetchQbDesktopCurrentAssetAccounts().then(
            (res) => {
                dispatch(
                    success(
                        integrationTypes.FETCH_QB_DESKTOP_CURRENT_ASSET_ACCOUNTS_SUCCESS,
                        res?.data
                    )
                );
            },
            (error) => {
                if (error.message) {
                    dispatch(
                        failure(
                            integrationTypes.FETCH_QB_DESKTOP_CURRENT_ASSET_ACCOUNTS_FAILURE,
                            error?.message
                        )
                    );
                }
            }
        );
    };
}

function getQbDesktopFixedAssetAccounts() {
    return (dispatch: Dispatch) => {
        dispatch(request(integrationTypes.FETCH_QB_DESKTOP_FIXED_ASSET_ACCOUNTS_REQUEST));

        integrationService.fetchQbDesktopFixedAssetAccounts().then(
            (res) => {
                dispatch(
                    success(
                        integrationTypes.FETCH_QB_DESKTOP_FIXED_ASSET_ACCOUNTS_SUCCESS,
                        res?.data
                    )
                );
            },
            (error) => {
                if (error.message) {
                    dispatch(
                        failure(
                            integrationTypes.FETCH_QB_DESKTOP_FIXED_ASSET_ACCOUNTS_FAILURE,
                            error?.message
                        )
                    );
                }
            }
        );
    };
}

function getQbDesktopExpenseAccounts() {
    return (dispatch: Dispatch) => {
        dispatch(request(integrationTypes.FETCH_QB_DESKTOP_EXPENSE_ACCOUNTS_REQUEST));

        integrationService.fetchQbDesktopExpenseAccounts().then(
            (res) => {
                dispatch(
                    success(integrationTypes.FETCH_QB_DESKTOP_EXPENSE_ACCOUNTS_SUCCESS, res?.data)
                );
            },
            (error) => {
                if (error.message) {
                    dispatch(
                        failure(
                            integrationTypes.FETCH_QB_DESKTOP_EXPENSE_ACCOUNTS_FAILURE,
                            error?.message
                        )
                    );
                }
            }
        );
    };
}

function getQbDesktopIncomeAccounts() {
    return (dispatch: Dispatch) => {
        dispatch(request(integrationTypes.FETCH_QB_DESKTOP_INCOME_ACCOUNTS_REQUEST));

        integrationService.fetchQbDesktopIncomeAccounts().then(
            (res) => {
                dispatch(
                    success(integrationTypes.FETCH_QB_DESKTOP_INCOME_ACCOUNTS_SUCCESS, res?.data)
                );
            },
            (error) => {
                if (error.message) {
                    dispatch(
                        failure(
                            integrationTypes.FETCH_QB_DESKTOP_INCOME_ACCOUNTS_FAILURE,
                            error?.message
                        )
                    );
                }
            }
        );
    };
}

function getQbDesktopApAccounts() {
    return (dispatch: Dispatch) => {
        dispatch(request(integrationTypes.FETCH_QB_DESKTOP_AP_ACCOUNTS_REQUEST));

        integrationService.fetchQbDesktopApAccounts().then(
            (res) => {
                dispatch(success(integrationTypes.FETCH_QB_DESKTOP_AP_ACCOUNTS_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    dispatch(
                        failure(
                            integrationTypes.FETCH_QB_DESKTOP_AP_ACCOUNTS_FAILURE,
                            error?.message
                        )
                    );
                }
            }
        );
    };
}

function setUpQbDesktop(data: Record<string, any> | Array<Record<string, any>>) {
    return (dispatch: Dispatch) => {
        dispatch(request(integrationTypes.SETUP_QB_DESKTOP_REQUEST));

        integrationService.setUpQbDesktop(data).then(
            (res) => {
                dispatch(success(integrationTypes.SETUP_QB_DESKTOP_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(integrationTypes.SETUP_QB_DESKTOP_FAILURE, error?.message));
                }
            }
        );
    };
}

function resetSetupQbDesktopSuccess() {
    return (dispatch: Dispatch) => {
        dispatch(success(integrationTypes.SETUP_QB_DESKTOP_SUCCESS, null));
    };
}

function resetCompleteQbDesktopConnectionSuccess() {
    return (dispatch: Dispatch) => {
        dispatch(success(integrationTypes.COMPLETE_QB_DESKTOP_CONNECTION_SUCCESS, null));
    };
}

function resetQbDesktopOauthUrlSuccess() {
    return (dispatch: Dispatch) => {
        dispatch(success(integrationTypes.GET_QB_DESKTOP_OAUTH_URL_SUCCESS, null));
    };
}

function resetCompleteSpsCommerceConnectionSuccess() {
    return (dispatch: Dispatch) => {
        dispatch(success(integrationTypes.COMPLETE_SPS_COMMERCE_CONNECTION_SUCCESS, null));
    };
}

function resetSpsCommerceOauthUrlSuccess() {
    return (dispatch: Dispatch) => {
        dispatch(success(integrationTypes.GET_SPS_COMMERCE_OAUTH_URL_SUCCESS, null));
    };
}

function resetUpdateSocialAccountSuccess() {
    return (dispatch: Dispatch) => {
        dispatch(success(integrationTypes.UPDATE_SOCIAL_ACCOUNT_SUCCESS, null));
    };
}

function resetGetQuickbooksOauthUrlSuccess() {
    return (dispatch: Dispatch) => {
        dispatch(success(integrationTypes.GET_QUICKBOOKS_OAUTH_URL_SUCCESS, null));
    };
}

function resetGetQuickbooksExchangeCodeSuccess() {
    return (dispatch: Dispatch) => {
        dispatch(success(integrationTypes.GET_QUICKBOOKS_EXCHANGE_CODE_SUCCESS, null));
    };
}
function resetVerifyShopifySuccess() {
    return (dispatch: Dispatch) => {
        dispatch(success(integrationTypes.VERIFY_SHOPIFY_ACCOUNT_SUCCESS, null));
    };
}
function resetGetShopifyOauthUrlSuccess() {
    return (dispatch: Dispatch) => {
        dispatch(success(integrationTypes.GET_SHOPIFY_OAUTH_URL_SUCCESS, null));
    };
}
function resetSetUpQuickbooksSuccess() {
    return (dispatch: Dispatch) => {
        dispatch(success(integrationTypes.GET_QUICKBOOKS_EXCHANGE_CODE_SUCCESS, null));
    };
}
function resetShopifyDataSuccess() {
    return (dispatch: Dispatch) => {
        dispatch(success(integrationTypes.FETCH_SHOPIFY_DATA_SUCCESS, null));
    };
}
function resetAmazonDataSuccess() {
    return (dispatch: Dispatch) => {
        dispatch(success(integrationTypes.VERIFY_AMAZON_ACCOUNT_SUCCESS, null));
    };
}
function resetAmazonAuthSuccess() {
    return (dispatch: Dispatch) => {
        dispatch(success(integrationTypes.GET_AMAZON_OAUTH_URL_SUCCESS, null));
    };
}
function request(type: string) {
    return { type: type };
}
function success(type: string, data?: any) {
    return { type: type, payload: data };
}
function failure(type: string, error?: any) {
    return { type: type, payload: error ?? "" };
}
