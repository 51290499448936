import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import { dashboardActions } from "redux/Ldb/actions";
import { debounce } from "lodash";
import useInfiniteScroll from "hooks/useInfinteScroll";

const useGetDashboardTopOnHandProductList = (fetchData: boolean) => {
    const dispatch = useAppDispatch();
    const [inventoryList, setTopOnHandProductList] = useState<{ [key: string]: any }>([]);
    const [inventoryId, setInventoryId] = useState<string>("");
    const [dateRange, setDateRange] = useState<string>("");
    const [channel, setChannel] = useState<string>("");
    const [debouncedSearch, setDebouncedSearch] = useState<string>("");
    const [sortValues, setSortValues] = useState<any>("");
    const [pagination, setPagination] = useState<{ currentPage: string; noOfPages: string }>({
        currentPage: "1",
        noOfPages: "",
    });

    const {
        fetchingTopOnHandProductList,
        fetchedTopOnHandProductListSuccess,
        fetchedTopOnHandProductListFailure,
    } = useAppSelector((state) => state.dashboard);

    const { updatedLimit, isLoadingMore, setLoadMore } = useInfiniteScroll(
        { current: pagination.currentPage, number_of_pages: pagination.noOfPages },
        10
    );

    useEffect(() => {
        if (Boolean(fetchedTopOnHandProductListSuccess)) {
            setTopOnHandProductList(fetchedTopOnHandProductListSuccess);
            setPagination({
                currentPage: fetchedTopOnHandProductListSuccess.pagination?.current,
                noOfPages: fetchedTopOnHandProductListSuccess.pagination?.number_of_pages,
            });
            setLoadMore(false);
        }
    }, [fetchedTopOnHandProductListSuccess]);

    const handleInventoryDebouncedChange = debounce((e) => {
        setDebouncedSearch(e?.target?.value ?? "");
    }, 500);

    useEffect(() => {
        if (fetchData) {
            dispatch(
                dashboardActions?.getTopOnHandProductList(
                    updatedLimit,
                    inventoryId,
                    channel,
                    dateRange,
                    debouncedSearch,
                    sortValues
                )
            );
        }
    }, [fetchData, updatedLimit, inventoryId, channel, dateRange, debouncedSearch, sortValues]);


    
    return {
        fetchData,
        data: inventoryList?.inventories,
        totalInventory: inventoryList?.totalInventoryStockCount,
        isFetching: fetchingTopOnHandProductList,
        error: fetchedTopOnHandProductListFailure,
        handleInventoryDebouncedChange,
        setInventoryId,
        setChannel,
        setDateRange,
        inventoryIsLoadingMore: isLoadingMore,
        inventoryPagination: pagination,
        inventorySetSortValues: setSortValues,
    
    };
};

export default useGetDashboardTopOnHandProductList;
