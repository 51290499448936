import Button from "components/common/Button";
import CustomTable from "components/common/CustomTable";
import Checkbox from "components/common/InputField/Checkbox";
import TextInput from "components/common/InputField/TextInput";
import Loader from "components/common/Loader";
import ModalContainer from "components/common/ModalContainer";
import ProgressBar from "components/common/ProgressBar";
import VendorAvatar from "components/common/VendorAvatar";
import { formatMoney, getCurrencyFromCurrencyCode } from "helpers";
import useGetBundleDetails from "hooks/inventoryHooks/useGetBundleDetails";
import { truncate } from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const BundleDetails = () => {
    const navigate = useNavigate();
    const [showProductSync, setShowProductSync] = useState<boolean>(false);
    const [showProductSyncInProgress, setShowProductSyncInProgress] = useState<boolean>(false);
    const [archiveProduct, setArchiveProduct] = useState<boolean>(false);
    const [unArchiveProduct, setUnArchiveProduct] = useState<boolean>(false);
    const [totalStockLevel, setTotalStockLevel] = useState<number>(0);
    const [totalPrice, setTotalPrice] = useState<number>(0);
    const [totalQuantity, setTotalQuantity] = useState<number>(0);

    const { isFetching, error, data } = useGetBundleDetails();

    const bundleItemsCanCreate = data?.bundleItems?.map((item) => {
        const inventory = item?.inventory;

        const bundleItemCanCreate =
            inventory?.productStockDetails?.stockLevel && item?.quantity
                ? (inventory?.productStockDetails?.stockLevel || 0) / (item?.quantity || 0)
                : 0;

        return bundleItemCanCreate;
    });

    const maxBundles = bundleItemsCanCreate?.length > 0 ? Math.min(...bundleItemsCanCreate) : 0;

    const tableHeader = [
        { title: "Product name", widthClass: "w-[40%]" },
        {
            title: "Current stock level",
            widthClass: "w-[20%]",
            titleClass: "!justify-center",
            itemClass: "!px-0",
        },
        {
            title: "Unit price",
            widthClass: "w-[20%] text-center",
            titleClass: "!justify-center",
            itemClass: "!px-0",
        },
        {
            title: "Quantity",
            widthClass: "w-[20%] text-center",
            titleClass: "!justify-center",
            itemClass: "!px-0",
        },
    ];

    const tableBody = data?.bundleItems?.map((item, idx) => {
        return [
            {
                content: (
                    <div
                        key={idx}
                        className={`w-full border-slate-100 text-sm font-rocGroteskMedium text-slate-900 py-1.5`}
                    >
                        <div className="font-rocGroteskMedium pl-3  items-center flex space-x-2">
                            {item?.productImageDetails?.productImages?.[0] ? (
                                <VendorAvatar
                                    imageSrc={
                                        item?.productImageDetails?.productAvatar ||
                                        item?.productImageDetails?.productImages?.[0]
                                    }
                                    size={32}
                                    name={item?.productName}
                                    containerClassname="!rounded-md"
                                />
                            ) : (
                                <div className="w-8 h-8 rounded flex items-center justify-center bg-slate-200 ">
                                    <i className="ri-box-2-fill text-lg text-slate-500"></i>
                                </div>
                            )}

                            <div className="text-sm font-rocGroteskMedium">
                                <p className="text-g-75 ">{item?.inventory?.productName}</p>
                                <p className="text-slate-500 ">SKU-{item?.inventory?.sku}</p>
                            </div>
                        </div>
                    </div>
                ),
                tableDataDivClass: "!pl-[0]",
            },

            {
                content: (
                    <div className={`w-full   border-slate-100 text-sm text-g-75 py-1.5`}>
                        <p className="text-center">
                            {formatMoney().format(
                                item?.inventory?.productStockDetails?.stockLevel || 0
                            )}
                        </p>
                    </div>
                ),
                tableDataDivClass: "!pl-3",
            },
            {
                content: (
                    <div className={`w-full  border-slate-100 text-sm text-g-75 py-1.5`}>
                        <p className="text-center">
                            {getCurrencyFromCurrencyCode(item?.inventory?.salesPrice?.currency)}{" "}
                            {formatMoney().format(item?.inventory?.salesPrice?.amount || 0)}
                        </p>
                    </div>
                ),
                tableDataDivClass: "!pl-3",
            },
            {
                content: (
                    <div className={`w-full border-slate-100 text-sm text-g-75 py-1.5`}>
                        <p className="text-center">{formatMoney().format(item?.quantity || 0)}</p>
                    </div>
                ),
                tableDataDivClass: "!pl-3",
            },
        ];
    });

    useEffect(() => {
        const totalStockLevel = data?.bundleItems?.reduce((total, item) => {
            return total + (item?.inventory?.productStockDetails?.stockLevel || 0);
        }, 0);
        setTotalStockLevel(totalStockLevel);
        const totalPrice = data?.bundleItems?.reduce((total, item) => {
            return total + (item?.inventory?.salesPrice?.amount || 0);
        }, 0);
        setTotalPrice(totalPrice);
        const totalQuantity = data?.bundleItems?.reduce((total, item) => {
            return total + (item?.quantity || 0);
        }, 0);
        setTotalQuantity(totalQuantity);
    }, [data]);

    return (
        <>
            <div className="bg-white rounded-lg border border-n-20">
                <div className="flex items-center justify-between px-8 py-5 border-b border-n-20">
                    <div className="flex items-center space-x-1.5">
                        <i
                            onClick={() => navigate(-1)}
                            className="ri-arrow-left-line text-xl mt-[-2px] cursor-pointer max-sm:hidden"
                        ></i>
                        <span className="text-lg font-rocGroteskMedium">
                            {data?.bundleName || "Bundle Details"}
                        </span>
                    </div>
                    <div className="flex items-center space-x-3">
                        <Button
                            btnText="Close"
                            btnClassname="!px-4 !py-2 !bg-tradeally-neutral-20 !w-fit"
                            disabled={false}
                            onClick={() => navigate("/dashboard/inventory?currProductType=1")}
                        />
                        <Button
                            btnText="Edit bundle"
                            onClick={() => {
                                navigate(`/dashboard/inventory/edit-bundle/${data?._id}`);
                            }}
                            btnClassname="!px-4 !py-2 !w-fit"
                        />
                    </div>
                </div>
                {isFetching ? (
                    <Loader />
                ) : (
                    <div className="px-8 mt-8 ">
                        <div className="w-[80%]">
                            <div className="mb-6">
                                <div className="flex items-center w-full justify-between mb-4">
                                    <p className="text-sm font-rocGroteskMedium">Bundle name</p>
                                </div>
                                <div className="border-b-[1px] border-b-[#E2E8F0] pb-4">
                                    <p className="!text-2xl font-rocGroteskMedium  !border-none outline-none w-full placeholder:pl-1 caret-slate-700 !leading-6">
                                        {data?.bundleName}
                                    </p>
                                </div>
                            </div>
                            <div className="flex  justify-between  gap-3 border-b border-slate-200 pb-6 items-center">
                                <div>
                                    <p className="text-sm font-rocGroteskMedium text-slate-500">
                                        Stock Level
                                    </p>
                                    <p className="text-sm font-rocGroteskMedium text-gm-50 text-left">
                                        {formatMoney().format(Math.floor(maxBundles))}
                                    </p>
                                </div>
                                <div>
                                    <p className="text-sm font-rocGroteskMedium text-slate-500">
                                        Price
                                    </p>
                                    <p className="text-sm font-rocGroteskMedium text-gm-50 text-center">
                                        {" "}
                                        {getCurrencyFromCurrencyCode(data?.bundleValue?.currency)}
                                        {formatMoney().format(data?.bundleValue?.amount)}
                                    </p>
                                </div>
                                <div>
                                    <p className="text-sm font-rocGroteskMedium text-slate-500">
                                        Bundle Value
                                    </p>
                                    <p className="text-sm font-rocGroteskMedium text-gm-50 text-center">
                                        {" "}
                                        {getCurrencyFromCurrencyCode(data?.salesPrice?.currency)}
                                        {formatMoney().format(data?.salesPrice?.amount)}
                                    </p>
                                </div>
                                <div>
                                    <p className="text-sm font-rocGroteskMedium text-slate-500">
                                        Discount
                                    </p>
                                    <p className="text-sm font-rocGroteskMedium text-gm-50 text-center">
                                        {data?.bundlePercentDiscount}% off
                                    </p>
                                </div>
                                <div>
                                    <p className="text-sm font-rocGroteskMedium text-slate-500">
                                        Created by
                                    </p>
                                    <p className="text-sm font-rocGroteskMedium text-gm-50">
                                        {truncate(data?.creator?.fullName ?? "N/A", {
                                            length: 20,
                                        })}
                                    </p>
                                </div>
                                <div>
                                    <p className="text-sm font-rocGroteskMedium text-slate-500">
                                        Created on
                                    </p>
                                    <p className="text-sm font-rocGroteskMedium text-gm-50">
                                        {moment(data?.createdAt).format("DD MMM, YYYY")}
                                    </p>
                                </div>
                            </div>
                            <div className="flex space-x-3 my-4 items-center ">
                                <Checkbox isChecked={data?.notifyOnPercentDiscount} name="" />
                                <p className="text-g-75 font-rocGroteskMedium text-sm">
                                    Inform the customer that they are receiving this product with a
                                    % discount.
                                </p>
                            </div>
                            <div className="mt-8">
                                <p className="text-base font-rocGroteskMedium mb-3 text-gm-50">
                                    Add products to bundle
                                </p>

                                <div className="pb-3">
                                    <CustomTable
                                        tableBody={tableBody}
                                        tableHeader={tableHeader}
                                        isAllSelectable={false}
                                        isCellSelectable={false}
                                        headerContainerClass="!bg-[#F8FAFC] !font-rocGroteskMedium !text-[#334155] !rounded !border-slate-200"
                                        isScrollable={false}
                                        isCellBordered={false}
                                        bodyItemClass={"hover:!bg-transparent"}
                                    />
                                    <div className="flex w-[100%]  py-3  border border-t-0 border-[#E2E8F0] rounded-bl bg-[#F8FAFC] rounded-br">
                                        <div className="w-[40%] pl-3">
                                            <p className="text-sm font-rocGroteskMedium text-g-75">
                                                Totals: {data?.bundleItems?.length} product(s)
                                            </p>
                                        </div>
                                        <div className="w-[20%] pl-0">
                                            <p className="text-sm font-rocGroteskMedium text-g-75 text-center">
                                                {formatMoney().format(totalStockLevel)}
                                            </p>
                                        </div>
                                        <div className="w-[20%] pl-0">
                                            <p className="text-sm font-rocGroteskMedium text-g-75 text-center">
                                                $ {formatMoney().format(totalPrice)}
                                            </p>
                                        </div>
                                        <div className="w-[20%] pl-0">
                                            <p className="text-sm font-rocGroteskMedium text-g-75 text-center">
                                                {formatMoney().format(totalQuantity)}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-5 pb-8">
                                <label className="text-base font-rocGroteskMedium">
                                    Additional info
                                </label>
                                <div className="mt-4">
                                    <p className="text-sm font-rocGroteskMedium text-slate-500">
                                        {data?.productDescription || "N/A"}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <ModalContainer
                open={showProductSync}
                showCloseIcon={false}
                tailwindClassName="w-[600px]"
                closeModal={() => {
                    setShowProductSync(false);
                }}
            >
                <div className="bg-white rounded-lg shadow-cardShadow relative">
                    <div className=" pt-4">
                        <div className="px-6 flex justify-between pb-3 items-center ">
                            <p className="text-lg font-rocGroteskMedium text-g-75">
                                Product syncs completed. Select products for inventory
                            </p>
                            <i
                                className="ri-close-fill text-xl text-gm-50 cursor-pointer"
                                onClick={() => {
                                    setShowProductSync(false);
                                }}
                            ></i>
                        </div>
                        <div className="border-b border-slate-200 "></div>
                        <div className="border-b border-slate-200">
                            <div className="px-6 mt-3 mb-6">
                                <div className="bg-[#E9FFF7] space-y-2 rounded-[6px] px-4 py-5">
                                    <div>
                                        <div className="flex justify-between items-center">
                                            <p className="text-sm font-rocGroteskMedium text-g-75">
                                                Amazon Products Synced completed!
                                            </p>
                                            <div className="flex space-x-1 items-center">
                                                {" "}
                                                <p className="text-sm font-rocGroteskMedium text-g-75">
                                                    100%
                                                </p>{" "}
                                                <i className="ri-checkbox-circle-fill text-lg text-[#00DB8F]"></i>
                                            </div>
                                        </div>
                                        <div className="">
                                            <ProgressBar
                                                className="!bg-[#00DB8F] mt-1 w-full !rounded-[5px]"
                                                progress={100}
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <div className="flex justify-between items-center">
                                            <p className="text-sm font-rocGroteskMedium text-g-75">
                                                Shopify Products Synced completed!
                                            </p>
                                            <div className="flex space-x-1 items-center">
                                                {" "}
                                                <p className="text-sm font-rocGroteskMedium text-g-75">
                                                    100%
                                                </p>{" "}
                                                <i className="ri-checkbox-circle-fill text-lg text-[#00DB8F]"></i>
                                            </div>
                                        </div>
                                        <div className="">
                                            <ProgressBar
                                                className="!bg-[#00DB8F] mt-1 w-full !rounded-[5px]"
                                                progress={100}
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <div className="flex justify-between items-center">
                                            <p className="text-sm font-rocGroteskMedium text-g-75">
                                                QuickBooks Products Synced completed!
                                            </p>
                                            <div className="flex space-x-1 items-center">
                                                {" "}
                                                <p className="text-sm font-rocGroteskMedium text-g-75">
                                                    100%
                                                </p>{" "}
                                                <i className="ri-checkbox-circle-fill text-lg text-[#00DB8F]"></i>
                                            </div>
                                        </div>
                                        <div className="">
                                            <ProgressBar
                                                className="!bg-[#00DB8F] mt-1 w-full !rounded-[5px]"
                                                progress={100}
                                            />
                                        </div>
                                    </div>
                                    <p className="text-sm pt-2 font-rocGroteskMedium text-g-75">
                                        Next step: Select Product/SKU to Import to Inventory
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="w-full space-x-3 px-6 flex  py-3">
                            <Button
                                btnText={"Close"}
                                type={"button"}
                                btnClassname="!py-3 !bg-n-20  !text-dark-gray-2 !px-4"
                                onClick={() => {
                                    setShowProductSync(false);
                                }}
                            />
                            <Button btnText={"View sync products"} type={"button"} />
                        </div>
                    </div>
                </div>
            </ModalContainer>
            <ModalContainer
                open={showProductSyncInProgress}
                showCloseIcon={false}
                tailwindClassName="w-[600px]"
                closeModal={() => {
                    setShowProductSyncInProgress(false);
                }}
            >
                <div className="bg-white rounded-lg shadow-cardShadow relative">
                    <div className=" pt-4">
                        <div className="px-6 flex justify-between pb-3 items-center ">
                            <p className="text-lg font-rocGroteskMedium text-g-75">
                                Sync in Progress
                            </p>
                            <i
                                className="ri-close-fill text-xl text-gm-50 cursor-pointer"
                                onClick={() => {
                                    setShowProductSyncInProgress(false);
                                }}
                            ></i>
                        </div>
                        <div className="border-b border-slate-200 "></div>
                        <div className="px-6 mt-3 mb-6">
                            <div className="bg-[#FFF9EB] space-y-3 rounded-[6px] px-4 py-5">
                                <div>
                                    <div className="flex justify-between items-center">
                                        <p className="text-sm font-rocGroteskMedium text-g-75">
                                            Amazon Products Synced completed!
                                        </p>
                                        <div className="flex items-center">
                                            {" "}
                                            <p className="text-sm font-rocGroteskMedium text-g-75">
                                                25%
                                            </p>{" "}
                                        </div>
                                    </div>
                                    <div className="">
                                        <ProgressBar
                                            containerClass="!bg-[#EADFC1]"
                                            className="!bg-[#FFB902] mt-1 w-full !rounded-[5px]"
                                            progress={25}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <div className="flex justify-between items-center">
                                        <p className="text-sm font-rocGroteskMedium text-g-75">
                                            Shopify Products Synced completed!
                                        </p>
                                        <div className="flex items-center">
                                            {" "}
                                            <p className="text-sm font-rocGroteskMedium text-g-75">
                                                43%
                                            </p>{" "}
                                        </div>
                                    </div>
                                    <div className="">
                                        <ProgressBar
                                            containerClass="!bg-[#EADFC1]"
                                            className="!bg-[#FFB902] mt-1 w-full !rounded-[5px]"
                                            progress={43}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <div className="flex justify-between items-center">
                                        <p className="text-sm font-rocGroteskMedium text-g-75">
                                            QuickBooks Products Synced completed!
                                        </p>
                                        <div className="flex items-center">
                                            {" "}
                                            <p className="text-sm font-rocGroteskMedium text-g-75">
                                                69%
                                            </p>{" "}
                                        </div>
                                    </div>
                                    <div className="">
                                        <ProgressBar
                                            containerClass="!bg-[#EADFC1]"
                                            className="!bg-[#FFB902] mt-1 w-full !rounded-[5px]"
                                            progress={69}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="px-6 space-y-3">
                            <div className="flex space-x-3">
                                <i className="ri-window-2-line -mt-1 text-2xl text-gm-50"></i>
                                <div>
                                    <p className="text-g-75 mb-1 text-base font-rocGroteskMedium">
                                        Optional to keep this window open
                                    </p>
                                    <p className="text-sm font-rocGroteskMedium text-slate-500">
                                        This process might take a few moments.You can close this
                                        modal and continue using the platform. We will notify you
                                        once the sync is complete.
                                    </p>
                                </div>
                            </div>
                            <div className="flex space-x-3">
                                <i className="ri-check-double-fill -mt-1 text-2xl text-gm-50"></i>
                                <div>
                                    <p className="text-g-75 mb-1 text-base font-rocGroteskMedium">
                                        Data Accuracy
                                    </p>
                                    <p className="text-sm font-rocGroteskMedium text-slate-500">
                                        Ensure that your product data from all platforms is
                                        up-to-date to reflect accurate information in Synth.
                                    </p>
                                </div>
                            </div>
                            <div className="flex space-x-3">
                                <i className="ri-customer-service-line -mt-1 text-2xl text-gm-50"></i>
                                <div>
                                    <p className="text-g-75 mb-1 text-base font-rocGroteskMedium">
                                        Need Help?
                                    </p>
                                    <p className="text-sm font-rocGroteskMedium text-slate-500">
                                        If you encounter any issues, please contact our support
                                        team:{" "}
                                        <span className="text-[#CB9300] underline">
                                            hello@heysynth.com
                                        </span>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="w-full flex justify-end space-x-3 px-6   py-3">
                            <Button
                                btnText={"Close"}
                                type={"button"}
                                btnClassname="!py-3  !w-fit !px-4"
                                onClick={() => {
                                    setShowProductSyncInProgress(false);
                                }}
                            />
                        </div>
                    </div>
                </div>
            </ModalContainer>

            <ModalContainer
                open={archiveProduct}
                showCloseIcon={false}
                modalClassName={"py-10 px-10"}
                tailwindClassName="w-[600px]"
                closeModal={() => {
                    setArchiveProduct(false);
                }}
            >
                <div className="bg-white py-5 px-6  shadow-cardShadow relative rounded-[8px]">
                    <div className="mb-5">
                        <h1 className="text-lg mb-2  font-rocGroteskMedium">Archive product?</h1>
                        <p className="text-slate-500 font-rocGroteskMedium text-base">
                            Are you sure you want to archive this product? It will be removed from
                            your active inventory but can be restored from the Settings page.
                        </p>
                    </div>
                    <div className="flex justify-end items-center ">
                        <div className="flex items-center !whitespace-nowrap space-x-2 ">
                            <Button
                                btnText={"No, cancel"}
                                type={"button"}
                                btnClassname={"!bg-n-20 !w-fit !text-gm-50"}
                                disabled={false}
                                onClick={() => setArchiveProduct(false)}
                            />
                            <Button
                                btnClassname={"!py-3 !w-fit"}
                                onClick={() => {}}
                                btnText={"Archive product"}
                                type={"button"}
                                isLoading={false}
                            />
                        </div>
                    </div>
                </div>
            </ModalContainer>

            <ModalContainer
                open={unArchiveProduct}
                showCloseIcon={false}
                modalClassName={"py-10 px-10"}
                tailwindClassName="w-[600px]"
                closeModal={() => {
                    setUnArchiveProduct(false);
                }}
            >
                <div className="bg-white py-5 px-6  shadow-cardShadow relative rounded-[8px]">
                    <div className="mb-5">
                        <h1 className="text-lg mb-2  font-rocGroteskMedium">Unarchive product?</h1>
                        <p className="text-slate-500 font-rocGroteskMedium text-base">
                            Are you sure you want to unarchive this product? It will be added back
                            to your active inventory.
                        </p>
                    </div>
                    <div className="flex justify-end items-center ">
                        <div className="flex items-center !whitespace-nowrap space-x-2 ">
                            <Button
                                btnText={"No, cancel"}
                                type={"button"}
                                btnClassname={"!bg-n-20 !w-fit !text-gm-50"}
                                disabled={false}
                                onClick={() => setUnArchiveProduct(false)}
                            />
                            <Button
                                btnClassname={"!py-3 !w-fit"}
                                onClick={() => {}}
                                btnText={"Unarchive product"}
                                type={"button"}
                                isLoading={false}
                            />
                        </div>
                    </div>
                </div>
            </ModalContainer>
        </>
    );
};

export default BundleDetails;
