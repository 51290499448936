import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../storeHooks";
import { trackTraceActions } from "redux/Ldb/actions";
import { useParams } from "react-router-dom";

const useGetTrackTraceBatchIncidents = () => {
    const { id } = useParams();
    const dispatch = useAppDispatch();
    const [batchIncidents, setBatchIncidents] = useState<{ [key: string]: any }>([]);
    const [channel, setChannel] = useState<string>("");
    const [dateRange, setDateRange] = useState<string>("");

    const { fetchingBatchIncidents, fetchBatchIncidentsSuccess, fetchBatchIncidentsFailure } =
        useAppSelector((state) => state.trackTrace);

    const handleChannelChange = (e) => {
        setChannel(e?.target.value);
    };
    const handleDateChange = (e) => {
        setDateRange(e?.target.value);
    };

    useEffect(() => {
        if (Boolean(fetchBatchIncidentsSuccess)) {
            setBatchIncidents(fetchBatchIncidentsSuccess?.results);
        }
    }, [fetchBatchIncidentsSuccess]);

    useEffect(() => {
        if (id) {
            dispatch(trackTraceActions.getBatchIncidents(id, channel, dateRange));
        }
    }, [dispatch, id, channel, dateRange]);

    return {
        data: batchIncidents,
        isFetching: fetchingBatchIncidents,
        error: fetchBatchIncidentsFailure,
        handleChannelChange,
        handleDateChange,
        channel,
        dateRange,
    };
};

export default useGetTrackTraceBatchIncidents;
