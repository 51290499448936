import CustomToast from "components/common/CustomToast";

import { toast } from "react-hot-toast";
import { documentTypes } from "../types";
import { documentService } from "services/Ldb";

export const documentActions = {
    validateDocument,
};

function validateDocument(data: Record<string, any> | Array<Record<string, any>>) {
    return async (dispatch) => {
        dispatch(request(documentTypes.VALIDATE_DOCUMENT_REQUEST));

        try {
            const res = await documentService.validateDocument(data);
            dispatch(success(documentTypes.VALIDATE_DOCUMENT_SUCCESS, res?.data));
            return res;
        } catch (error) {
            if (error.message) {
                toast.custom((t) => <CustomToast t={t} message={error.message} type="error" />);
                dispatch(failure(documentTypes.VALIDATE_DOCUMENT_FAILURE, error.message));
            }
            throw error;
        }
    };
}

function request(type) {
    return { type: type };
}
function success(type, data) {
    return { type: type, payload: data };
}
function failure(type, error) {
    return { type: type, payload: error ?? "" };
}
