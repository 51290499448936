import React from "react";


export const ShowRolesAndResponsibiltyModal = () => {
    const Data = [
        {
            icon: "ri-admin-line",
            role: "Admin",
            content:
                " Has full access and control over the logistics platform. Admins can manage all aspects of the system, including adding and removing team members, defining access levels, configuring settings, and accessing sensitive data.",
        },
        {
            icon: "ri-briefcase-line",
            role: "Manager",
            content:
                "Managers have elevated privileges and are responsible for overseeing specific operations within the logistics platform. They may have access to reports, analytics, and performance data, as well as the ability to assign tasks and manage team members",
        },
        {
            icon: "ri-ship-line",
            role: "Operator / Logistician",
            content:
                " Operators are the core users of the logistics platform. They handle day-to-day tasks related to shipment management, vendor interactions, and coordination. They have access to essential features like shipment assignment, communication tools, and shipment tracking.",
        },
        {
            icon: "ri-search-eye-line",
            role: "Viewer / Analyst",
            content:
                "This role focuses on data analysis and monitoring. Viewers have access to reports, dashboards, and analytics, allowing them to gain insights into the logistics operations without direct operational control.",
        },
    ];
    return (
        <div className="bg-white px-2   py-10 shadow-cardShadow relative rounded-[20px] max-h-[80vh] overflow-y-scroll">
            <div className="flex px-8 flex-col ">
                <div className="text-center w-full mb-[46px]">
                    <p className="mb-2.5 text-2xl font-rocGroteskMedium text-g-75">
                        Roles and responsibilities
                    </p>
                    <p className="text-sm font-rocGroteskMedium text-gm-40">
                        List of roles and access levels
                    </p>
                </div>
                <div className="grid gap-4 lg:grid-cols-2">
                    {Data.map((d, index) => (
                        <div className="border border-n-20 px-3 py-2 rounded-[4px]" key={index+1}>
                            <div className="flex space-x-1 mb-2 items-center">
                                <i className={`${d.icon} text-p-30 text-[24px]`}></i>
                                <p className="text-[14px] left-6 font-rocGroteskMedium text-gm-50">
                                   {d.role}
                                </p>
                            </div>
                            <p className="font-rocGroteskRegular text-[13px] text-gm-50">
                              {d.content}
                            </p>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};
