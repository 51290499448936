import React from "react";

type AnalyticsSummaryCardProps = {
    title: string;
    value: string;
    up: boolean;
    percentageChange: string;
    isPercentageValue?: boolean;
};

const AnalyticsSummaryCard = ({
    title,
    value,
    up,
    percentageChange,
    isPercentageValue,
}: AnalyticsSummaryCardProps) => {
    return (
        <div className="px-6">
            <p className="mb-[28px] text-sm text-slate-950 font-rocGroteskMedium">{title}</p>
            <p className="text-p-70 font-rocGroteskMedium">
                <span className="text-[36px]">{value}</span>
                {isPercentageValue && <span className="text-xl">%</span>}
            </p>
            <p className="flex items-center">
                {up ? (
                    <i className="ri-arrow-up-line text-g-50"></i>
                ) : (
                    <i className="ri-arrow-down-line text-r-50"></i>
                )}
                <span className="text-[13px] text-gm-35 font-rocGroteskMedium">
                    {up
                        ? `${percentageChange}% down this month`
                        : `${percentageChange}% up this month`}
                </span>
            </p>
        </div>
    );
};

export default AnalyticsSummaryCard;
