import Button from "components/common/Button";
import TextInput from "components/common/InputField/TextInput";
import Loader from "components/common/Loader";
import ModalContainer from "components/common/ModalContainer";
import { getFromStorage, saveToStorage } from "helpers";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { authActions } from "redux/Ldb/actions";
import { firebaseService } from "services/firebaseService";
import { useAppDispatch, useAppSelector } from "../../../../../../hooks/storeHooks";

const BusinessInfo = () => {
    const [openEditBusinessName, setOpenEditBusinessName] = useState(false);
    const [uploadingImg, setUploadingImg] = useState(false);
    const [userProfileData, setUserProfileData] = useState(() => getFromStorage("ally-user"));
    const [businessImageUrl, setBusinessImageUrl] = useState(
        userProfileData?.user?.profile?.avatar
    );

    const [profile, setProfile] = useState<{ [key: string]: any }>({});
    const [name, setName] = useState("");
    const dispatch = useAppDispatch();

    const { fetchUserProfileSuccess, updateLdbProfileRequest, updateLdbProfileSuccess } =
        useAppSelector((state) => state.auth);

    const updateAvatar = (newAvatarValue) => {
        if (userProfileData) {
            userProfileData.user.profile.avatar = newAvatarValue;
            setUserProfileData({ ...userProfileData });
            saveToStorage("ally-user", userProfileData);
        }
    };
    useEffect(() => {
        setBusinessImageUrl(profile?.profile?.avatar);
    }, [profile]);

    const handleBusinessLogoUpload = (fileName, file) => {
        setUploadingImg(true);
        firebaseService.uploadFile(
            `tradeally/${process.env.REACT_APP_STAGE}/businessLogo/${fileName}`,
            file,
            (url) => {
                setBusinessImageUrl(url);
                const body = {
                    avatar: url,
                };

                dispatch(authActions.updateLdbProfile(body, userProfileData?.token));

                setUploadingImg(false);
            }
        );
    };

    // useEffect(() => {
    //     const body = {
    //         avatar: businessImageUrl,
    //     };
    //     if (businessImageUrl !== "") {
    //         dispatch(authActions.updateLdbProfile(body, userProfileData?.token));
    //     }
    // }, [businessImageUrl]);

    useEffect(() => {
        if (Boolean(fetchUserProfileSuccess)) {
            setProfile(fetchUserProfileSuccess);
        }
    }, [fetchUserProfileSuccess]);

    useEffect(() => {
        setName(profile?.profile?.businessName);
    }, [profile]);

    const handleSubmit = () => {
        const body = {
            businessName: name,
        };
        dispatch(authActions.updateLdbProfile(body, userProfileData?.token));
    };

    useEffect(() => {
        if (Boolean(updateLdbProfileSuccess)) {
            updateAvatar(updateLdbProfileSuccess?.profile?.avatar);
            dispatch(authActions.resetProfileUpdate());
            dispatch(authActions.getLdbProfile(userProfileData?.token));
            setName(profile?.profile?.businessName);
            setOpenEditBusinessName(false);
        }
    }, [updateLdbProfileSuccess]);

    return (
        <div
            className={`w-full flex gap-[45px]  ${
                userProfileData?.user?.role?.toLowerCase() !== "owner" && "hidden"
            }`}
        >
            <div className="rounded w-full md:border md:border-slate-100 bg-white pt-[30px] md:p-5 flex flex-col gap-[26px]">
                <p className="text-base font-rocGroteskMedium">Business Info</p>
                <div className="flex items-center gap-[26px] flex-col md:flex-row">
                    <div className="w-[74px] h-[74px]  rounded-full flex items-center justify-center text-xl border ">
                        {!uploadingImg ? (
                            <div className="">
                                {businessImageUrl === "" ? (
                                    <i className="ri-image-add-fill text-[35px] text-tradeally-neutral-200"></i>
                                ) : (
                                    <img
                                        src={businessImageUrl}
                                        className="w-[74px] h-[74px]  rounded-full"
                                        alt="business-logo"
                                    />
                                )}
                            </div>
                        ) : (
                            <Loader />
                        )}
                    </div>
                    <div
                        className={`relative group w-[140px] mt-2 cursor-pointer flex justify-center items-center ${
                            userProfileData?.user?.role?.toLowerCase() !== "owner" && "hidden"
                        }`}
                    >
                        <input
                            className="absolute top-0 right-0 bottom-0 left-0 w-[inherit] z-[1] opacity-0 outline-none"
                            type="file"
                            accept="image/*"
                            name="businessbgfile"
                            id="businessbgfile"
                            onChange={(evt) => {
                                return userProfileData?.user?.role?.toLowerCase() !== "owner"
                                    ? null
                                    : handleBusinessLogoUpload(
                                          evt.target.files[0].name,
                                          evt.target.files[0]
                                      );
                            }}
                        />
                        <div
                            onClick={() => {
                                const elem = document.getElementById("businessbgfile");
                                return userProfileData?.user?.role?.toLowerCase() !== "owner"
                                    ? null
                                    : elem?.click();
                            }}
                            className="relative z-10 cursor-pointer md:border rounded px-4 py-2 bg-[#f4f5f7] md:bg-transparent"
                        >
                            <p className="text-slate-700  font-rocGroteskMedium text-sm ">
                                Change photo
                            </p>
                        </div>
                    </div>
                </div>
                <div
                    className="flex items-end md:items-center font-rocGroteskMedium justify-between border-b border-b-slate-100 md:border-none
                    pb-[10px] md:pb-0"
                >
                    <div className="w-[60%]">
                        <p className="text-sm">Business name</p>
                        <p className="text-sm text-slate-500 font-rocGroteskMedium md:font-rocGroteskRegular pt-[10px]">
                            {profile?.profile?.businessName}
                        </p>
                    </div>
                    <div
                        className={`cursor-pointer ${
                            userProfileData?.user?.role?.toLowerCase() !== "owner" && "hidden"
                        }`}
                        onClick={() =>
                            userProfileData?.user?.role?.toLowerCase() !== "owner"
                                ? null
                                : setOpenEditBusinessName(true)
                        }
                    >
                        <p className="text-sm font-rocGroteskMedium text-b-60 hidden md:block">
                            Edit name
                        </p>
                        <img
                            src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1703076259/edit_j8cs2p.svg"
                            className=" md:hidden"
                            alt="edit icon"
                        />
                    </div>
                </div>
                <div
                    className="flex items-end md:items-center font-rocGroteskMedium justify-between border-b border-b-slate-100 md:border-none
                    pb-[10px] md:pb-0"
                >
                    <div className="w-[60%]">
                        <p className="text-sm">Email address</p>
                        <p className="text-sm text-slate-500 font-rocGroteskMedium md:font-rocGroteskRegular">
                            {profile?.profile?.email}
                        </p>
                    </div>
                    {/* <div className="cursor-pointer" onClick={() => setOpenEditEmail(true)}>
                        <p className="text-sm font-rocGroteskMedium text-b-60 hidden md:block">
                            Edit email address
                        </p>
                        <img
                            src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1703076259/edit_j8cs2p.svg"
                            className=" md:hidden"
                            alt="edit icon"
                        />
                    </div> */}
                </div>
                <div
                    className="flex items-end md:items-center font-rocGroteskMedium justify-between border-b border-b-slate-100 md:border-none
                    pb-[10px] md:pb-0"
                >
                    <div className="w-[60%]">
                        <p className="text-sm">Phone number</p>
                        <p className="text-sm text-slate-500 font-rocGroteskRegular">
                            {profile?.profile?.mobile}
                        </p>
                    </div>
                    {/* <div className="cursor-pointer" onClick={() => setOpenEditPhone(true)}>
                        <p className="text-sm font-rocGroteskMedium text-b-60 hidden md:block">Edit phone number</p>
                        <img
                            src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1703076259/edit_j8cs2p.svg"
                            className=" md:hidden"
                            alt="edit icon"
                        />
                    </div> */}
                </div>
                {/* <div className="flex items-center font-rocGroteskMedium justify-between">
                    <div className="w-[60%]">
                        <p className="text-sm">Role</p>
                        <p className="text-sm text-slate-500">Admin</p>
                    </div>
                    <p className="text-sm font-rocGroteskMedium text-b-60">Edit role</p>
                </div>
                <div className="flex items-center font-rocGroteskMedium justify-between">
                    <div className="w-[60%]">
                        <p className="text-sm">Group</p>
                        <p className="text-sm text-slate-500">Admin</p>
                    </div>
                    <p className="text-sm font-rocGroteskMedium text-b-60">Edit group</p>
                </div> */}
            </div>

            {/* <div className="rounded w-[45%] h-fit border border-slate-100 bg-white p-5 flex flex-col gap-[26px]">
                <p className="text-base font-rocGroteskMedium">Business documents</p>
                <div className="flex items-center font-rocGroteskMedium justify-between py-3 px-6 border-b border-slate-100">
                    <div className="w-[60%] flex items-center gap-3">
                        {displayFileIcon("pdf", null, "!w-6 !h-6")}
                        <p className="text-sm ">Insurance Certificates</p>
                    </div>

                    <p className="text-sm font-rocGroteskMedium text-b-60">Change</p>
                </div>
                <div className="flex items-center font-rocGroteskMedium justify-between py-3 px-6 border-b border-slate-100">
                    <div className="w-[60%] flex items-center gap-3">
                        {displayFileIcon("png", null, "!w-6 !h-6")}
                        <p className="text-sm ">Insurance Certificates</p>
                    </div>

                    <p className="text-sm font-rocGroteskMedium text-b-60">Change</p>
                </div>
                <div className="flex items-center font-rocGroteskMedium justify-between py-3 px-6 border-b border-slate-100">
                    <div className="w-[60%] flex items-center gap-3">
                        {displayFileIcon("doc", null, "!w-6 !h-6")}
                        <p className="text-sm ">Insurance Certificates</p>
                    </div>

                    <p className="text-sm font-rocGroteskMedium text-b-60">Change</p>
                </div>
                <Button
                    btnText={"Add document"}
                    btnClassname={
                        "!py-2 !px-4 !bg-white border border-slate-200 !w-fit !text-gm-50 !text-xs"
                    }
                />
            </div> */}

            <ModalContainer
                open={openEditBusinessName}
                showCloseIcon={false}
                modalClassName={"p-4 md:p-10"}
                tailwindClassName="w-[553px]"
                closeModal={() => {
                    setOpenEditBusinessName(false);
                    setName(profile?.profile?.businessName);
                }}
            >
                <div className="bg-white px-2  pt-3 pb-10 shadow-cardShadow relative rounded-[20px]">
                    <div className=" px-4 flex justify-end ">
                        <i
                            onClick={() => {
                                setOpenEditBusinessName(false);
                                setName(profile?.profile?.businessName);
                            }}
                            className="ri-close-fill cursor-pointer text-gm-45 text-[24px]"
                        ></i>
                    </div>
                    <div className="flex px-4 md:px-8 flex-col items-center ">
                        <div className="text-center w-full mb-[46px]">
                            <p className="mb-2.5 text-2xl font-rocGroteskMedium text-g-75">
                                Edit business name
                            </p>
                        </div>
                        <form
                            className="w-full"
                            onSubmit={(e) => {
                                e.preventDefault();
                                handleSubmit();
                            }}
                        >
                            <div className="mb-10">
                                <div className="flex flex-col gap-6 w-full">
                                    <TextInput
                                        name={"name"}
                                        type="text"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                        placeholder="Edit full name"
                                        required={true}
                                        containerClassname="mb-2"
                                    />
                                </div>
                            </div>
                            <div className="w-full ">
                                <Button
                                    btnText={"Save changes"}
                                    type={"submit"}
                                    isLoading={updateLdbProfileRequest}
                                />
                                <Button
                                    btnText={"Close"}
                                    disabled={updateLdbProfileRequest}
                                    onClick={() => setOpenEditBusinessName(false)}
                                    btnClassname={"!bg-transparent !text-gm-75 mt-2"}
                                />
                            </div>
                        </form>
                    </div>
                </div>
            </ModalContainer>
        </div>
    );
};

export default BusinessInfo;
