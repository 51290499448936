import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../storeHooks";
import { dashboardActions } from "redux/Ldb/actions";
import { debounce, uniqBy } from "lodash";

const useGetDashboardTopPerforming = (fetchData: boolean) => {
    const dispatch = useAppDispatch();
    const [debouncedSearch, setDebouncedSearch] = useState<string>("");
    const [topPerforming, setTopPerforming] = useState<any>();
    const [sortValues, setSortValues] = useState<any>({});
    const [dateRange, setDateRange] = useState<string>("");
    const [warehouseId, setWarehouseId] = useState<string>("");
    const [inventoryId, setInventoryId] = useState<string>("");
    const [channel, setChannel] = useState<string>("");

    const [pagination, setPagination] = useState<{ currentPage: string; noOfPages: string }>({
        currentPage: "1",
        noOfPages: "",
    });

    const {
        fetchingDashboardTopPerforming,
        fetchedDashboardTopPerformingSuccess,
        fetchedDashboardTopPerformingFailure,
    } = useAppSelector((state) => state.dashboard);

    const handleDebouncedChange = debounce((e) => {
        setDebouncedSearch(e?.target?.value ?? "");
    }, 500);

    useEffect(() => {
        if (Boolean(fetchedDashboardTopPerformingSuccess)) {
            setTopPerforming((prev) => [...fetchedDashboardTopPerformingSuccess?.products]);
            setPagination({
                currentPage: fetchedDashboardTopPerformingSuccess.pagination?.current,
                noOfPages: fetchedDashboardTopPerformingSuccess.pagination?.number_of_pages,
            });
            // setLoadMore(false);
        }
    }, [fetchedDashboardTopPerformingSuccess]);

    useEffect(() => {
        if (Boolean(fetchedDashboardTopPerformingSuccess)) {
            setTopPerforming(fetchedDashboardTopPerformingSuccess?.products);
        }
    }, [fetchedDashboardTopPerformingSuccess]);

    useEffect(() => {
        if (fetchData) {
            dispatch(
                dashboardActions.getTopPerforming(
                    10,
                    1,
                    inventoryId,
                    warehouseId,
                    debouncedSearch,
                    channel,
                    dateRange,
                    sortValues
                )
            );
        }
    }, [
        dispatch,
        inventoryId,
        warehouseId,
        sortValues,
        debouncedSearch,
        channel,
        dateRange,
        fetchData,
    ]);

    return {
        data: topPerforming,
        isFetching: fetchingDashboardTopPerforming,
        error: fetchedDashboardTopPerformingFailure,
        // isLoadingMore,
        handleDebouncedChange,
        setDateRange,
        pagination,
        setPagination,
        setSortValues,
        setWarehouseId,
        setInventoryId,
        setDebouncedSearch,
        setChannel,
    };
};

export default useGetDashboardTopPerforming;
