export const shipmentTypes = {
    FETCH_SHIPMENTS_REQUEST: "FETCH_SHIPMENTS_REQUEST",
    FETCH_SHIPMENTS_SUCCESS: "FETCH_SHIPMENTS_SUCCESS",
    FETCH_SHIPMENTS_FAILURE: "FETCH_SHIPMENTS_FAILURE",
    CREATE_SHIPMENT_REQUEST: "CREATE_SHIPMENT_REQUEST",
    CREATE_SHIPMENT_SUCCESS: "CREATE_SHIPMENT_SUCCESS",
    CREATE_SHIPMENT_FAILURE: "CREATE_SHIPMENT_FAILURE",
    FETCH_SINGLE_SHIPMENT_REQUEST: "FETCH_SINGLE_SHIPMENT_REQUEST",
    FETCH_SINGLE_SHIPMENT_SUCCESS: "FETCH_SINGLE_SHIPMENT_SUCCESS",
    FETCH_SINGLE_SHIPMENT_FAILURE: "FETCH_SINGLE_SHIPMENT_FAILURE",
    FETCH_PO_SHIPMENT_REQUEST: "FETCH_PO_SHIPMENT_REQUEST",
    FETCH_PO_SHIPMENT_SUCCESS: "FETCH_PO_SHIPMENT_SUCCESS",
    FETCH_PO_SHIPMENT_FAILURE: "FETCH_PO_SHIPMENT_FAILURE",
    ASSIGN_SHIPMENT_TO_MEMBER_REQUEST: "ASSIGN_SHIPMENT_TO_MEMBER_REQUEST",
    ASSIGN_SHIPMENT_TO_MEMBER_SUCCESS: "ASSIGN_SHIPMENT_TO_MEMBER_SUCCESS",
    ASSIGN_SHIPMENT_TO_MEMBER_FAILURE: "ASSIGN_SHIPMENT_TO_MEMBER_FAILURE",
    UPDATE_SHIPMENT_REQUEST: "UPDATE_SHIPMENT_REQUEST",
    UPDATE_SHIPMENT_SUCCESS: "UPDATE_SHIPMENT_SUCCESS",
    UPDATE_SHIPMENT_FAILURE: "UPDATE_SHIPMENT_FAILURE",
};
