import config from "config/config";
import {authService} from "./Ldb/authService";

async function joinWaitList(data: Record<string, any> | Array<Record<string, any>>) {
    const requestOptions = {
        method: "POST",
        body: JSON.stringify(data),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/website/waitlist`,
        requestOptions
    );
    return res;
}

export const websiteService = {
    joinWaitList,
};
