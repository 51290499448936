import React from "react";

const ShipmentSubListTitle = ({title}: {title: string}) => {
    return (
        <div className="w-full flex items-center space-x-3">
            <div className="whitespace-nowrap">
                <h6 className="text-g-75 text-[14px] font-rocGroteskMedium leading-5">{title}</h6>
            </div>
            <div className="border-b border-slate-100 w-full"></div>
        </div>
    );
};

export default ShipmentSubListTitle;
