import React, { useEffect, useState } from "react";
import TextInput from "components/common/InputField/TextInput";
import Button from "components/common/Button";
import { truncate } from "lodash";
import ModalContainer from "components/common/ModalContainer";
import useGetProductList from "hooks/inventoryHooks/useGetProductList";
import CustomTable from "components/common/CustomTable";
import VendorAvatar from "components/common/VendorAvatar";
import { formatMoney, getCurrencyFromCurrencyCode } from "helpers";
import CustomTableLoader from "components/common/CustomTableLoader";
import Loader from "components/common/Loader";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import { useNavigate } from "react-router-dom";
import { inventoryActions } from "redux/Ldb/actions";

type SelectProductsProps = {
    step?: number;
    setStep?: React.Dispatch<React.SetStateAction<number>>;
    selectedProducts: { [key: string]: any }[];
};

const CreateProductBundles = ({ step, setStep, selectedProducts }: SelectProductsProps) => {
    const [addProduct, setAddProduct] = useState<boolean>(false);
    const [selectProduct, setSelectProduct] = useState<{ [key: string]: any }[]>([]);
    const [attachedProducts, setAttachedProducts] = useState<{ [key: string]: any }[]>([]);
    const [activeIndex, setActiveIndex] = useState<number>(0);
    const [clickCount, setClickCount] = useState({});
    const [inputValues, setInputValues] = useState({});

    const [search, setSearch] = useState<string>("");

    const scrollContent = document.getElementById("custom-modal");

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { creatingMultipleBundles, createMultipleBundlesSuccess } = useAppSelector(
        (state) => state.inventory
    );
    const {
        data: productData,
        handleDebouncedChange: producthandleDebouncedChange,
        handleSearch: producthandleSearch,
        isFetching: productListLoading,
        search: productListSearch,
        setSearch: setproductListSearch,
        setDebouncedSearch: setproductDebouncedSearch,
        pagination,
        isLoadingMore,
        updatedPage,
    } = useGetProductList(scrollContent);

    const handleGoBack = () => {
        setStep(2);
    };

    const handleSelectAllProducts = (deselect = false) => {
        const selectedProducts = deselect ? [] : productData;
        setSelectProduct(selectedProducts);
    };

    const handleCreateProductBundles = () => {
        const parsedData = selectedProducts
            .map((product, idx) => {
                const productAttached = attachedProducts?.find((attached) => {
                    return Object.entries(attached)?.some(([key, value]) => {
                        return Number(key) === idx;
                    });
                });
                const theAttached: any = Object.values(productAttached?.[idx] || {})?.map(
                    (attachProd) => attachProd
                );

                return {
                    bundleName: product?.productName,
                    sku: product?.sku,
                    bundleItems: theAttached?.map((prod) => {
                        return {
                            inventoryId: prod?._id,
                            quantity: 1,
                        };
                    }),
                };
            })
            ?.filter((item) => item?.bundleItems?.length > 0);

        const body = {
            bundles: parsedData,
        };

        if (parsedData.length === 0) {
            return;
        }

        dispatch(inventoryActions.createMultipleBundles(body));
    };

    const handleSelectEachProduct = (itemSelected, values) => {
        const selectedProductsCopy = { ...inputValues };

        if (!selectedProductsCopy[itemSelected?._id]) {
            selectedProductsCopy[itemSelected?._id] = {};
        }

        selectedProductsCopy[itemSelected?._id] = {
            ...selectedProductsCopy[itemSelected?._id],
            ...values,
        };

        setInputValues(selectedProductsCopy);
    };

    const tableProductHeader = [
        {
            title: "Product name",
            widthClass: "w-[40%] !px-3 !border-r-none",
            onSelectAll: () => {
                handleSelectAllProducts(selectProduct?.length > 0);
            },
            isAllChecked: selectProduct?.length === productData?.length,
        },
        { title: "Current stock level", widthClass: "w-[20%] !border-r-none" },
        { title: "Unit price", widthClass: "w-[20%] !border-r-none" },
    ];

    const handleCheckboxClick = (itemSelected) => {
        const clickCountCopy = { ...clickCount };

        if (!clickCountCopy[itemSelected?._id]) {
            clickCountCopy[itemSelected?._id] = 1;
        } else {
            clickCountCopy[itemSelected?._id] += 1;
        }

        setClickCount(clickCountCopy);
        const check = selectProduct.some((product) => product._id === itemSelected?._id);

        const updatedSelectProduct = check
            ? selectProduct.filter((product) => product?._id !== itemSelected?._id)
            : [...selectProduct, itemSelected];

        setSelectProduct(updatedSelectProduct);
    };

    const tableProductBody = productData?.map((item, idx) => {
        const isChecked = selectProduct.some((product) => product._id === item._id);
        const selectedBundle = selectedProducts?.[activeIndex]._id === item._id;

        return [
            {
                content: (
                    <div
                        key={idx}
                        className={`w-full py-2 border-slate-100 text-sm font-rocGroteskMedium text-slate-900 `}
                    >
                        <div className="font-rocGroteskMedium   items-center flex space-x-3">
                            {item?.productImageDetails?.productImages?.[0] ? (
                                <VendorAvatar
                                    imageSrc={
                                        item?.productImageDetails?.productAvatar ||
                                        item?.productImageDetails?.productImages?.[0]
                                    }
                                    size={32}
                                    name={item?.productName}
                                    containerClassname="!rounded-md"
                                />
                            ) : (
                                <div className="w-8 h-8 rounded shrink-0 flex items-center justify-center bg-slate-200 ">
                                    <i className="ri-box-2-fill text-lg text-slate-500"></i>
                                </div>
                            )}

                            <div className="text-sm font-rocGroteskMedium">
                                <p className="text-g-75 ">{item.productName}</p>
                                <p className="text-slate-500 ">{item.sku}</p>
                            </div>
                        </div>
                    </div>
                ),
                isItemChecked: isChecked,
                onCheckBoxClick: () => {
                    handleCheckboxClick(item);
                },
                checkItemIsDisabled: Boolean(selectedBundle),
                tableDataDivClass: "!pl-3",
                widthClass: `!border-none ${
                    Boolean(selectedBundle) ? "cursor-not-allowed bg-slate-200" : ""
                }`,
            },
            {
                content: (
                    <div
                        className={`w-full py-2 font-rocGroteskMedium border-slate-100 text-sm text-g-75 `}
                    >
                        <p>
                            {item?.productStockDetails?.stockLevel
                                ? item?.productStockDetails?.stockLevel?.toLocaleString()
                                : "N/A"}
                        </p>
                    </div>
                ),
                tableDataDivClass: "!pl-3",
                widthClass: `!border-none ${
                    Boolean(selectedBundle) ? "cursor-not-allowed bg-slate-200" : ""
                }`,
            },
            {
                content: (
                    <div
                        className={`w-full py-2 font-rocGroteskMedium border-slate-100 text-sm text-g-75 `}
                    >
                        <p>
                            {getCurrencyFromCurrencyCode(item.unitCost.currency)}{" "}
                            {formatMoney().format(item.unitCost.amount)}
                        </p>
                    </div>
                ),
                tableDataDivClass: "!pl-3",
                widthClass: `!border-none ${
                    Boolean(selectedBundle) ? "cursor-not-allowed bg-slate-200" : ""
                }`,
            },
        ];
    });

    return (
        <div className="mt-[31px] flex justify-center">
            <div className="w-[70%] h-[75vh] bg-white rounded-[6px] shadow-[0px_20px_24px_-4px_#10182814]">
                <div className="w-full h-full relative">
                    <div className="px-6 pt-4 pb-6 border-b border-slate-200">
                        <div className="font-rocGroteskMedium flex justify-between items-center mb-2">
                            <div className="flex items-center space-x-1.5">
                                {/* <i
                                    onClick={handleGoBack}
                                    className="ri-arrow-left-line text-lg mt-[-2px] cursor-pointer"
                                ></i> */}
                                <h4 className="text-g-75 text-base">
                                    Add products/SKUs to create Bundled SKUs
                                </h4>
                            </div>
                            <span className="text-sm text-slate-500 ">Step {step}/3</span>
                        </div>

                        <div className="mb-4">
                            <TextInput
                                name={"search"}
                                value={""}
                                type={"text"}
                                inputPlaceholder={"Search products"}
                                inputClassName={"!h-[38px] pl-[0px] !bg-white text-sm !mb-0"}
                                // onChange={handleSearch}
                                // onInput={handleDebouncedChange}
                                containerClassname={"!w-full max-sm:!w-full"}
                                leftIcon={
                                    <i className="ri-search-line before:content-['\f0d1] text-slate-400 mt-[2px]"></i>
                                }
                                inputContainerClassname={
                                    "!rounded-[4px] max-sm:!w-full !pl-[40px] !h-[40px] !bg-white !border !border-slate-200"
                                }
                            />
                        </div>

                        <div className="grid grid-cols-3 space-x-1.5">
                            <span className="h-1 bg-[#FFB902] rounded"></span>
                            <span className="h-1 bg-[#FFB902] rounded"></span>
                            <span className="h-1 bg-[#FFB902] rounded"></span>
                        </div>
                    </div>

                    <div className="h-[48.8vh] overflow-y-auto space-y-4 px-6 py-6">
                        {selectedProducts?.map((product, idx) => {
                            const productAttached = attachedProducts?.find((attached) => {
                                return Object.entries(attached)?.some(([key, value]) => {
                                    return Number(key) === idx;
                                });
                            });
                            const theAttached: any = Object.values(
                                productAttached?.[idx] || {}
                            )?.map((attachProd) => attachProd);

                            return (
                                <div
                                    key={product?._id}
                                    className="border border-slate-200 rounded-[6px]"
                                >
                                    <div className="py-3 px-4 text-sm rounded-t-[6px] font-rocGroteskMedium flex space-x-1.5 bg-slate-50 border-b border-slate-200">
                                        <span>{product?.productName}</span>
                                        <span className="text-[#64748B]">{product?.sku}</span>
                                    </div>
                                    <div className="px-4 py-4 overflow-y-auto flex space-x-3">
                                        {theAttached?.map((item, index) => {
                                            return (
                                                <div
                                                    key={String(item?._id) + String(index)}
                                                    className="flex shrink-0 items-center border border-slate-200 w-[220px] rounded"
                                                >
                                                    <div className="">
                                                        {item?.productImageDetails
                                                            ?.productImages?.[0] ? (
                                                            <VendorAvatar
                                                                imageSrc={
                                                                    item?.productImageDetails
                                                                        ?.productAvatar ||
                                                                    item?.productImageDetails
                                                                        ?.productImages?.[0]
                                                                }
                                                                size={56}
                                                                name={item?.productName}
                                                                containerClassname="!rounded-md"
                                                            />
                                                        ) : (
                                                            <div className="w-[56px] h-[56px] rounded-l flex items-center justify-center bg-slate-200 ">
                                                                <i className="ri-box-2-fill text-lg text-slate-500"></i>
                                                            </div>
                                                        )}
                                                    </div>

                                                    <div className="border-l border-slate-200 h-[56px] pl-3 flex items-center">
                                                        <div>
                                                            <span className="block font-rocGroteskMedium text-sm">
                                                                {truncate(item?.productName, {
                                                                    length: 18,
                                                                })}
                                                            </span>
                                                            <span className="text-sm font-rocGroteskMedium text-slate-500">
                                                                {item?.sku}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                        <div className="h-14 w-14 cursor-pointer shrink-0 rounded-[2px] border border-dashed border-slate-300 flex items-center justify-center">
                                            <i
                                                className="ri-add-fill text-[28px] text-slate-400"
                                                onClick={() => {
                                                    setActiveIndex(idx);
                                                    setSelectProduct(theAttached);
                                                    setAddProduct(true);
                                                }}
                                            ></i>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>

                    {
                        <div className="flex items-center justify-end absolute bottom-0 w-full bg-white border-t border-slate-200 px-6 py-4">
                            <div className="flex items-center space-x-3">
                                <Button
                                    btnText="Cancel"
                                    btnClassname="h-[48px] !bg-n-20"
                                    disabled={creatingMultipleBundles}
                                    onClick={() => navigate(`/dashboard/inventory`)}
                                />

                                <Button
                                    btnText="Create product bundles"
                                    btnClassname="h-[48px] !whitespace-nowrap"
                                    isLoading={creatingMultipleBundles}
                                    onClick={() => {
                                        handleCreateProductBundles();
                                    }}
                                />
                            </div>
                        </div>
                    }
                </div>
            </div>

            <ModalContainer
                open={addProduct}
                showCloseIcon={false}
                closeModal={() => {
                    setAddProduct(false);
                    setproductListSearch("");
                }}
                tailwindClassName="w-[55%]"
            >
                <div className="bg-white  rounded-lg max-h-[90vh] overflow-y-scroll">
                    <div className=" px-6 py-4 flex gap-4 items-center justify-between border-b border-b-slate-200">
                        <p className="text-lg text-gm-50 font-rocGroteskMedium">
                            Add products/SKUs to {selectedProducts?.[activeIndex]?.productName}
                        </p>
                        <i
                            onClick={() => {
                                setproductListSearch("");
                                setAddProduct(false);
                            }}
                            className="ri-close-fill cursor-pointer text-gm-50 text-2xl "
                        ></i>
                    </div>
                    <div className="my-8 ">
                        <div className="mx-8">
                            <TextInput
                                name={"search"}
                                value={productListSearch}
                                type={"text"}
                                inputPlaceholder={"Search  products"}
                                inputClassName={"!h-[38px] pl-[0px] !bg-white text-sm !mb-0"}
                                onChange={producthandleSearch}
                                onInput={producthandleDebouncedChange}
                                // containerClassname={"!w-[400px] max-sm:!w-full"}
                                leftIcon={
                                    <i className="ri-search-line before:content-['\f0d1] text-slate-400 mt-[2px]"></i>
                                }
                                inputContainerClassname={
                                    "!rounded-[4px] max-sm:!w-full !pl-[40px] !h-[40px] !bg-white shadow-[0_1px_2px_0_rgba(91,104,113,0.15)] !border !border-n-20"
                                }
                            />
                        </div>

                        <div className="">
                            {productListLoading && Number(updatedPage) === 1 ? (
                                <CustomTableLoader tableHeader={tableProductHeader} />
                            ) : (
                                <div
                                    className="max-h-[300px] mt-5 overflow-y-auto"
                                    id="custom-modal"
                                >
                                    <CustomTable
                                        tableBody={tableProductBody}
                                        tableHeader={tableProductHeader}
                                        isAllSelectable={false}
                                        isCellSelectable={true}
                                        isCellBordered={true}
                                        headerItemClass="font-rocGroteskMedium   !text-slate-700 !py-[11px] !text-xs"
                                        headerContainerClass="!bg-slate-50 !border !border-[#E2E8F0] "
                                        isScrollable={false}
                                        bodyItemClass="!border-t-none !border-r-none"
                                        tableClass="!border-t-none !border-r-none !border-l-none"
                                    />
                                    {isLoadingMore && (
                                        <div className="flex justify-center my-4">
                                            <Loader color="gm-25" size={4} />
                                        </div>
                                    )}

                                    {pagination?.currentPage === pagination?.noOfPages && (
                                        <div className="flex my-4 justify-center text-slate-500 text-sm font-rocGroteskMedium">
                                            End of list
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="flex   px-6 border-t py-3 justify-between items-center">
                        <div className=" ">
                            <p className="text-sm font-rocGroteskMedium text-g-75 ">
                                {selectProduct?.length} product(s) selected
                            </p>
                        </div>
                        <div className="flex  items-center space-x-2 ">
                            <Button
                                btnClassname={"!py-2 !text-gm-50 !w-fit !px-8"}
                                onClick={() => {
                                    if (selectProduct?.length > 0) {
                                        const attachedProductsCopy = [...attachedProducts];
                                        const attachedProduct = attachedProductsCopy?.find(
                                            (attached) => {
                                                return Object.entries(attached)?.some(
                                                    ([key, value]) => {
                                                        return Number(key) === activeIndex;
                                                    }
                                                );
                                            }
                                        );

                                        if (attachedProduct) {
                                            attachedProductsCopy[activeIndex] = {
                                                ...attachedProduct,
                                                [activeIndex]: selectProduct,
                                            };
                                            setAttachedProducts(attachedProductsCopy);
                                        } else {
                                            setAttachedProducts((prev) => [
                                                ...prev,
                                                { [activeIndex]: selectProduct },
                                            ]);
                                        }
                                    }
                                    setAddProduct(false);
                                    setproductListSearch("");
                                    setSelectProduct([]);
                                }}
                                btnText={"Add product(s)"}
                                type={"button"}
                                isLoading={false}
                            />
                        </div>
                    </div>
                </div>
            </ModalContainer>
        </div>
    );
};

export default CreateProductBundles;
