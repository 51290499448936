/* eslint-disable */
import Button from "components/common/Button";
import VendorAvatar from "components/common/VendorAvatar";
import PageFrame from "components/layout/PageFrame";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import { truncate } from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { inventoryActions } from "redux/Ldb/actions";

const StockAdjustmentDetails = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [stockAdjustDetails, setStockAdjustDetails] = useState<{ [key: string]: any }>({});

    const { fetchedSingleStockAdjustmentSuccess, fetchingSingleStockAdjustment } = useAppSelector(
        (state) => state.inventory
    );

    useEffect(() => {
        if (Boolean(fetchedSingleStockAdjustmentSuccess)) {
            setStockAdjustDetails(fetchedSingleStockAdjustmentSuccess?.stockMovement);
        }
    }, [fetchedSingleStockAdjustmentSuccess]);
    useEffect(() => {
        if (id) {
            dispatch(inventoryActions?.fetchSingleStockAdjusment(id));
        }
    }, [id]);

    return (
        <PageFrame
            isLoading={fetchingSingleStockAdjustment}
            containerClassName={"h-[calc(100vh-14px)]"}
        >
            <div className="">
                <div className="bg-white rounded-lg border border-slate-100">
                    <div className="">
                        <div className="flex items-center justify-between px-8 py-4 border-b border-n-20 top-[-10px] md:sticky  z-[50] bg-white">
                            <p className="text-base font-rocGroteskMedium text-gun-metal">
                                Stock adjustment
                            </p>
                            <div className="">
                                <Button
                                    btnText="Close"
                                    btnClassname="!px-4 !py-2 !w-fit"
                                    onClick={() => navigate(-1)}
                                />
                            </div>
                        </div>

                        <div className="w-full pt-6 pb-8 px-8">
                            <div className="w-full mb-8">
                                <p className="text-sm font-rocGroteskMedium mb-2">
                                    Stock adjustment number <span className="text-r-50">*</span>
                                </p>
                                <p className="text-2xl font-rocGroteskMedium text-slate-700">
                                    {stockAdjustDetails?.reference}
                                </p>
                            </div>
                            <div className="w-full py-6 border-b border-slate-200 ">
                                <div className="flex justify-between text-slate-700 text-sm font-rocGroteskMedium border-b border-slate-200  pb-3 pr-20">
                                    <p className="w-[390px]">Products</p>
                                    <p className="">Initial Qty</p>
                                    <p className="">Change Qty</p>
                                    <p className="">Final Qty</p>
                                </div>

                                <div className="flex items-center justify-between pt-6 font-rocGroteskMedium text-sm pr-20">
                                    <div className="flex items-center gap-3 w-[360px]">
                                        {stockAdjustDetails?.inventory?.productImageDetails
                                            ?.productAvatar && (
                                            <div className="w-16 h-16">
                                                <img
                                                    src={
                                                        stockAdjustDetails?.inventory
                                                            ?.productImageDetails?.productAvatar
                                                    }
                                                    alt=""
                                                    className="rounded "
                                                />
                                            </div>
                                        )}

                                        <div className="">
                                            <p className="text-gm-50">
                                                {" "}
                                                {stockAdjustDetails?.inventory?.productName}
                                            </p>
                                            <p className="text-slate-500">
                                                {truncate(
                                                    stockAdjustDetails?.inventory
                                                        ?.productDescription,
                                                    { length: 40 }
                                                )}
                                            </p>
                                        </div>
                                    </div>
                                    <p className=" py-1 px-2 rounded bg-slate-200">
                                        {" "}
                                        {stockAdjustDetails?.previousQuantity
                                            ? stockAdjustDetails?.previousQuantity
                                            : 0}
                                    </p>
                                    <p className="py-1 px-2 rounded bg-slate-200">
                                        {stockAdjustDetails?.quantity}
                                    </p>
                                    <p className="py-1 px-2 rounded bg-slate-200">
                                        {stockAdjustDetails?.movementCategory !== "adjustment" ? (
                                            <>
                                                {stockAdjustDetails?.previousQuantity
                                                    ? stockAdjustDetails?.previousQuantity -
                                                      stockAdjustDetails?.quantity
                                                    : stockAdjustDetails?.quantity}
                                            </>
                                        ) : (
                                            <>{stockAdjustDetails?.quantity}</>
                                        )}
                                    </p>
                                </div>
                            </div>
                            <div className="w-[70%]">
                                <div className="w-full py-6 border-b border-slate-200">
                                    <p className="text-sm font-rocGroteskMedium text-gun-metal">
                                        Warehouse
                                    </p>
                                    <p className="text-sm font-rocGroteskMedium text-slate-500">
                                        {
                                            stockAdjustDetails?.warehouse?.warehouseFrom
                                                ?.warehouseName
                                        }
                                    </p>
                                </div>
                                <div className="w-full py-6 border-b border-slate-200 font-rocGroteskMedium">
                                    <p className=" text-slate-700 text-xs mb-3">Adjusted by</p>
                                    <div className=" flex items-center gap-3 text-sm">
                                        {stockAdjustDetails?.creator?.profile?.avatar && (
                                            <VendorAvatar
                                                imageSrc={
                                                    stockAdjustDetails?.creator?.profile?.avatar
                                                }
                                                name={
                                                    stockAdjustDetails?.creator?.profile
                                                        ?.businessName
                                                }
                                                size={32}
                                            />
                                        )}

                                        <div className="">
                                            <p className="text-gun-metal">
                                                {stockAdjustDetails?.creator?.fullName}
                                            </p>
                                            <p className="text-slate-500">
                                                {stockAdjustDetails?.creator?.email}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="w-full py-6 border-b border-slate-200 font-rocGroteskMedium">
                                    <p className=" text-slate-700 text-xs mb-3">Approved by</p>
                                    <div className=" flex items-center gap-3 text-sm">
                                        {stockAdjustDetails?.creator?.profile?.avatar && (
                                            <VendorAvatar
                                                imageSrc={
                                                    stockAdjustDetails?.creator?.profile?.avatar
                                                }
                                                name={
                                                    stockAdjustDetails?.creator?.profile
                                                        ?.businessName
                                                }
                                                size={32}
                                            />
                                        )}

                                        <div className="">
                                            <p className="text-gun-metal">
                                                {stockAdjustDetails?.creator?.fullName}
                                            </p>
                                            <p className="text-slate-500">
                                                {stockAdjustDetails?.creator?.email}
                                            </p>
                                        </div>
                                    </div>
                                </div> */}
                                <div className="w-full py-6 border-b border-slate-200">
                                    <p className="text-sm font-rocGroteskMedium text-gun-metal">
                                        Date
                                    </p>
                                    <p className="text-sm font-rocGroteskMedium text-slate-500 capitalize">
                                        {moment(stockAdjustDetails?.movementDate).format(
                                            "MMM DD, YYYY • h:mm A"
                                        )}
                                    </p>
                                </div>
                                <div className="w-full py-6 border-b border-slate-200">
                                    <p className="text-sm font-rocGroteskMedium text-gun-metal">
                                        Reason
                                    </p>
                                    <p className="text-sm font-rocGroteskMedium text-slate-500 capitalize">
                                        {stockAdjustDetails?.movementCategory}
                                    </p>
                                </div>
                                {stockAdjustDetails?.notes && (
                                    <div className="w-full py-6">
                                        <p className="text-sm font-rocGroteskMedium text-gun-metal">
                                            Additional Note
                                        </p>
                                        <p className="text-sm font-rocGroteskMedium text-slate-500 mt-2">
                                            {stockAdjustDetails?.notes}
                                        </p>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </PageFrame>
    );
};

export default StockAdjustmentDetails;
