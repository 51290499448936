import React from "react";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "react-tooltip";

type AiTeammateCardProps = {
    name: string;
    role: string;
    createdOn: string;
    onGoingTaskCount?: number;
    totalSavings?: string;
    timeSaved?: string;
    index?: number;
    _id: string;
    avatarUrl?: string;
};

const AiTeammateCard = ({
    name,
    role,
    createdOn,
    onGoingTaskCount,
    totalSavings,
    timeSaved,
    index,
    _id,
    avatarUrl,
}: AiTeammateCardProps) => {
    const navigate = useNavigate();

    return (
        <div className="rounded-md border border-slate-200 shadow-[0px_4px_8px_-4px_#1018281F]">
            <div className="p-4">
                <div className="mb-3">
                    {avatarUrl ? (
                        <img
                            src={avatarUrl}
                            alt="ai avatar"
                            className="w-8 h-8 rounded-full object-cover"
                        />
                    ) : (
                        <div className="w-8 h-8 rounded-full bg-p-50 mb-3 flex justify-center items-center">
                            <i className="ri-robot-fill text-white text-[18px]"></i>
                        </div>
                    )}
                </div>

                <div className="text-slate-500 font-rocGroteskMedium text-sm">
                    <span className="block text-g-75">
                        {name} - {role}
                    </span>
                    <span className="block text-slate-500">Created on: {createdOn}</span>
                    <span className="block text-slate-500">{onGoingTaskCount} ongoing tasks</span>
                </div>
            </div>

            <div className="w-full flex border-y border-slate-200 font-rocGroteskMedium">
                <div className="py-2.5 px-4 border-r border-slate-200 w-1/2">
                    <div className="flex items-center space-x-1.5">
                        <span className="block text-xs text-slate-500">Total savings</span>
                        <div>
                            <a id={`ai-team-cost-saving-${index}`}>
                                <i className="ri-information-fill text-sm text-slate-400"></i>
                            </a>
                            <Tooltip
                                anchorSelect={`#ai-team-cost-saving-${index}`}
                                place={"top"}
                                content={
                                    "This AI teammate helps you save an average of $15 on each sales order"
                                }
                                style={{
                                    maxWidth: "250px",
                                    zIndex: 9999,
                                    background: "black",
                                    borderRadius: "6px",
                                    border: "1px solid #E2E8F0",
                                    color: "white",
                                    boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.04)",
                                    fontSize: 12,
                                }}
                            />
                        </div>
                    </div>

                    <span className="block ">{totalSavings}</span>
                </div>
                <div className="py-2.5 px-4 w-1/2">
                    <div className="flex items-center space-x-1.5">
                        <span className="block text-xs text-slate-500 ">Time saved</span>

                        <div>
                            <a id={`ai-team-time-saving-${index}`}>
                                <i className="ri-information-fill text-sm text-slate-400"></i>
                            </a>
                            <Tooltip
                                anchorSelect={`#ai-team-time-saving-${index}`}
                                place={"top"}
                                content={
                                    "This AI teammate helps you save an average of 1 hour  on each sales order"
                                }
                                style={{
                                    maxWidth: "250px",
                                    zIndex: 9999,
                                    background: "black",
                                    borderRadius: "6px",
                                    border: "1px solid #E2E8F0",
                                    color: "white",
                                    boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.04)",
                                    fontSize: 12,
                                }}
                            />
                        </div>
                    </div>

                    <span className="block ">{timeSaved}</span>
                </div>
            </div>

            <div
                onClick={() => navigate(`/dashboard/workforce/ai-teammate/${_id}`)}
                className="rounded-b-md flex items-center justify-center space-x-2 py-2.5 bg-slate-50 cursor-pointer"
            >
                <span className="text-[13px] font-rocGroteskMedium">View more info</span>
                <i className="ri-arrow-right-line text-base"></i>
            </div>
        </div>
    );
};

export default AiTeammateCard;
