import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import { dashboardActions } from "redux/Ldb/actions";

const useGetDashboardBusinessStats = () => {
    const dispatch = useAppDispatch();
    const [businessStats, setBusinessStats] = useState<{ [key: string]: any }>([]);

    const {
        fetchingDashboardBusinessStats,
        fetchedDashboardBusinessStatsSuccess,
        fetchedDashboardBusinessStatsFailure,
    } = useAppSelector((state) => state.dashboard);

    useEffect(() => {
        if (Boolean(fetchedDashboardBusinessStatsSuccess)) {
            setBusinessStats(fetchedDashboardBusinessStatsSuccess);
        }
    }, [fetchedDashboardBusinessStatsSuccess]);

    useEffect(() => {
        dispatch(dashboardActions?.getDashboardBusinessStats());
    }, [dispatch]);

    return {
        data: businessStats,
        isFetching: fetchingDashboardBusinessStats,
        error: fetchedDashboardBusinessStatsFailure,
    };
};

export default useGetDashboardBusinessStats;
