import React, { useState, useEffect, useRef } from "react";

interface Props {
    children: React.ReactNode;
    setAtBottom: any;
    modalHeader:React.ReactNode;
    closeModal?: () => void;

}
const ScrollableModal = ({ children, modalHeader, setAtBottom, closeModal}: Props) => {
    const modalRef = useRef<HTMLDivElement>(null);

    const checkScrollPosition = () => {
        if (modalRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = modalRef.current;
            setAtBottom(scrollTop + clientHeight >= scrollHeight);
        }
    };
    const checkModalHeight = () => {
        if (modalRef.current) {
            const { scrollHeight } = modalRef.current;
            setAtBottom(scrollHeight <= 400);
        }
    };

    useEffect(() => {
        checkScrollPosition();
        checkModalHeight();
        if (modalRef.current) {
            modalRef.current.addEventListener("scroll", checkScrollPosition);
            window.addEventListener("resize", checkModalHeight);
            return () => {
                modalRef.current?.removeEventListener("scroll", checkScrollPosition);
                window.removeEventListener("resize", checkModalHeight);
            };
        }
    }, [modalRef.current]);

    return (
        <div
        onClick={closeModal}
        className="fixed bg-gm-50/20 left-0 top-0 w-full h-full flex items-center justify-center">
            <div className="p-4 w-[700px]" onClick={(evt) => evt.stopPropagation()}>
                <div
                    className={`max-h-[500px] h-fit pb-4 rounded-lg shadow-cardShadow relative bg-white`}
                >
                    <div className="">
                       {modalHeader}

                        <div ref={modalRef} className="px-2 mt-2 max-h-[400px] overflow-y-scroll">
                            {children}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ScrollableModal;
