import { useEffect, useState } from "react";
import { inventoryActions } from "redux/Ldb/actions";
import { useAppDispatch, useAppSelector } from "../storeHooks";

const useGetOngoingSyncConnections = (
    shopifySalesCompleted?: boolean,
    amazonSalesCompleted?: boolean
) => {
    const [ongoingSyncData, setOngoingSyncData] = useState<{ [key: string]: any }[]>([]);
    const [pagination, setPagination] = useState<{ currentPage: string; noOfPages: string }>({
        currentPage: "1",
        noOfPages: "",
    });

    const dispatch = useAppDispatch();
    const {
        fetchingOngoingSyncConnections,
        fetchedOngoingSyncConnectionsSuccess,
        fetchedOngoingSyncConnectionsFailure,
        importingSyncedProducts,
        importedSyncedProductsSuccess,
    } = useAppSelector((state) => state.inventory);
    const { manageConnectionSuccess } = useAppSelector((state) => state.vendor);

    useEffect(() => {
        dispatch(inventoryActions.fetchOngoingSyncConnections());
    }, [
        dispatch,
        manageConnectionSuccess,
        importedSyncedProductsSuccess,
        shopifySalesCompleted,
        amazonSalesCompleted,
    ]);

    useEffect(() => {
        if (Boolean(fetchedOngoingSyncConnectionsSuccess)) {
            setOngoingSyncData(fetchedOngoingSyncConnectionsSuccess?.connections);
        }
    }, [fetchedOngoingSyncConnectionsSuccess]);

    return {
        isFetching: fetchingOngoingSyncConnections,
        error: fetchedOngoingSyncConnectionsFailure,
        data: ongoingSyncData,

        pagination,
        setPagination,
    };
};

export default useGetOngoingSyncConnections;
