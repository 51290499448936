import React from "react";
import { dashboardTypes } from "redux/Ldb/types";
import { toast } from "react-hot-toast";
import CustomToast from "../../../components/common/CustomToast";
import { Dispatch } from "redux";
import { dashboardService } from "services/Ldb";

export const dashboardActions = {
    getDashboardGWM,
    getSalesGWM,
    getSalesChannel,
    resetGetDashboardGWMSuccess,
    getDashboardAnalytics,
    getDashboardBusinessStats,
    getTopPerforming,
    getLeastPerforming,
    getTopOnHandProduct,
    getProductsMarginProfit,
    getProductProfitList,
    getTopOnHandProductList,
    getDashboardConfigList,
    resetGetDashboardConfigList,
    addForecastConfig,
    resetAddForecastConfig,
};

function getDashboardGWM(
    category?: string,
    product?: string,
    channel?: string,
    startDate?: any,
    endDate?: any,
    dateRange?: string
) {
    return (dispatch: Dispatch) => {
        dispatch(request(dashboardTypes.FETCH_DASHBOARD_GMW_REQUEST));

        dashboardService
            .fetchDashboardGMW(category, product, channel, startDate, endDate, dateRange)
            .then(
                (res) => {
                    dispatch(success(dashboardTypes.FETCH_DASHBOARD_GMW_SUCCESS, res?.data));
                },
                (error) => {
                    if (error.message) {
                        toast.custom((t) => (
                            <CustomToast t={t} message={error?.message} type="error" />
                        ));
                        dispatch(
                            failure(dashboardTypes.FETCH_DASHBOARD_GMW_FAILURE, error?.message)
                        );
                    }
                }
            );
    };
}

function getSalesGWM(
    category?: string,
    product?: string,
    warehouse?: string,
    channel?: string,
    startDate?: any,
    endDate?: any,
    dateRange?: string
) {
    return (dispatch: Dispatch) => {
        dispatch(request(dashboardTypes.FETCH_SALES_GMW_REQUEST));

        dashboardService
            .fetchSalesGMW(category, product, warehouse, channel, startDate, endDate, dateRange)
            .then(
                (res) => {
                    dispatch(success(dashboardTypes.FETCH_SALES_GMW_SUCCESS, res?.data));
                },
                (error) => {
                    if (error.message) {
                        toast.custom((t) => (
                            <CustomToast t={t} message={error?.message} type="error" />
                        ));
                        dispatch(failure(dashboardTypes.FETCH_SALES_GMW_FAILURE, error?.message));
                    }
                }
            );
    };
}

function getSalesChannel() {
    return (dispatch: Dispatch) => {
        dispatch(request(dashboardTypes.FETCH_SALES_CHANNEL_REQUEST));

        dashboardService.fetchSalesChannel().then(
            (res) => {
                dispatch(success(dashboardTypes.FETCH_SALES_CHANNEL_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(dashboardTypes.FETCH_SALES_CHANNEL_FAILURE, error?.message));
                }
            }
        );
    };
}

function getDashboardAnalytics(period?: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(dashboardTypes.FETCH_DASHBOARD_ANALYTICS_REQUEST));

        dashboardService.fetchDashboardAnalytics(period).then(
            (res) => {
                dispatch(success(dashboardTypes.FETCH_DASHBOARD_ANALYTICS_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(dashboardTypes.FETCH_DASHBOARD_ANALYTICS_FAILURE, error?.message)
                    );
                }
            }
        );
    };
}

function getDashboardBusinessStats() {
    return (dispatch: Dispatch) => {
        dispatch(request(dashboardTypes.FETCH_DASHBOARD_BUSINESS_STATS_REQUEST));

        dashboardService.fetchBusinessStats().then(
            (res) => {
                dispatch(success(dashboardTypes.FETCH_DASHBOARD_BUSINESS_STATS_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(
                            dashboardTypes.FETCH_DASHBOARD_BUSINESS_STATS_FAILURE,
                            error?.message
                        )
                    );
                }
            }
        );
    };
}

function getTopPerforming(
    limit?: number,
    page?: number,
    inventoryId?: string,
    warehouseId?: string,
    search?: string,
    channel?: string,
    dateRange?: string,
    sortParams?: any
) {
    return (dispatch: Dispatch) => {
        dispatch(request(dashboardTypes.FETCH_DASHBOARD_TOP_PERFORMING_REQUEST));

        dashboardService
            .fetchTopPerforming(
                limit,
                page,
                inventoryId,
                warehouseId,
                search,
                channel,
                dateRange,
                sortParams
            )
            .then(
                (res) => {
                    dispatch(
                        success(dashboardTypes.FETCH_DASHBOARD_TOP_PERFORMING_SUCCESS, res?.data)
                    );
                },
                (error) => {
                    if (error.message) {
                        toast.custom((t) => (
                            <CustomToast t={t} message={error?.message} type="error" />
                        ));
                        dispatch(
                            failure(
                                dashboardTypes.FETCH_DASHBOARD_TOP_PERFORMING_FAILURE,
                                error?.message
                            )
                        );
                    }
                }
            );
    };
}
function getLeastPerforming(
    limit?: number,
    page?: number,
    inventoryId?: string,
    warehouseId?: string,
    search?: string,
    channel?: string,
    dateRange?: string,
    sortParams?: any
) {
    return (dispatch: Dispatch) => {
        dispatch(request(dashboardTypes.FETCH_DASHBOARD_LEAST_PERFORMING_REQUEST));

        dashboardService
            .fetchLeastPerforming(
                limit,
                page,
                inventoryId,
                warehouseId,
                search,
                channel,
                dateRange,
                sortParams
            )
            .then(
                (res) => {
                    dispatch(
                        success(dashboardTypes.FETCH_DASHBOARD_LEAST_PERFORMING_SUCCESS, res?.data)
                    );
                },
                (error) => {
                    if (error.message) {
                        toast.custom((t) => (
                            <CustomToast t={t} message={error?.message} type="error" />
                        ));
                        dispatch(
                            failure(
                                dashboardTypes.FETCH_DASHBOARD_LEAST_PERFORMING_FAILURE,
                                error?.message
                            )
                        );
                    }
                }
            );
    };
}

function getProductsMarginProfit() {
    return (dispatch: Dispatch) => {
        dispatch(request(dashboardTypes.FETCH_DASHBOARD_PRODUCT_PROFIT_MARGIN_REQUEST));

        dashboardService.fetchProductsProfitMargin().then(
            (res) => {
                dispatch(
                    success(dashboardTypes.FETCH_DASHBOARD_PRODUCT_PROFIT_MARGIN_SUCCESS, res?.data)
                );
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(
                            dashboardTypes.FETCH_DASHBOARD_PRODUCT_PROFIT_MARGIN_FAILURE,
                            error?.message
                        )
                    );
                }
            }
        );
    };
}

function getTopOnHandProduct() {
    return (dispatch: Dispatch) => {
        dispatch(request(dashboardTypes.FETCH_DASHBOARD_TOP_ON_HAND_PRODUCT_REQUEST));

        dashboardService.fetchTopOnHandProduct().then(
            (res) => {
                dispatch(
                    success(dashboardTypes.FETCH_DASHBOARD_TOP_ON_HAND_PRODUCT_SUCCESS, res?.data)
                );
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(
                            dashboardTypes.FETCH_DASHBOARD_TOP_ON_HAND_PRODUCT_FAILURE,
                            error?.message
                        )
                    );
                }
            }
        );
    };
}

function getTopOnHandProductList(
    limit?: number,
    inventoryId?: string,
    channel?: string,
    dateRange?: string,
    search?: string,
    sortParams?: any
) {
    return (dispatch: Dispatch) => {
        dispatch(request(dashboardTypes.FETCH_DASHBOARD_TOP_ON_HAND_PRODUCT_LIST_REQUEST));

        dashboardService
            .fetchTopOnHandProductList(limit, inventoryId, channel, dateRange, search, sortParams)
            .then(
                (res) => {
                    dispatch(
                        success(
                            dashboardTypes.FETCH_DASHBOARD_TOP_ON_HAND_PRODUCT_LIST_SUCCESS,
                            res?.data
                        )
                    );
                },
                (error) => {
                    if (error.message) {
                        toast.custom((t) => (
                            <CustomToast t={t} message={error?.message} type="error" />
                        ));
                        dispatch(
                            failure(
                                dashboardTypes.FETCH_DASHBOARD_TOP_ON_HAND_PRODUCT_LIST_FAILURE,
                                error?.message
                            )
                        );
                    }
                }
            );
    };
}

function getProductProfitList(
    limit?: number,
    inventoryId?: string,
    channel?: string,
    dateRange?: string,
    search?: string,
    sortParams?: any
) {
    return (dispatch: Dispatch) => {
        dispatch(request(dashboardTypes.FETCH_DASHBOARD_PRODUCT_PROFIT_LIST_REQUEST));

        dashboardService
            .fetchProductsProfitList(limit, inventoryId, channel, dateRange, search, sortParams)
            .then(
                (res) => {
                    dispatch(
                        success(
                            dashboardTypes.FETCH_DASHBOARD_PRODUCT_PROFIT_LIST_SUCCESS,
                            res?.data
                        )
                    );
                },
                (error) => {
                    if (error.message) {
                        toast.custom((t) => (
                            <CustomToast t={t} message={error?.message} type="error" />
                        ));
                        dispatch(
                            failure(
                                dashboardTypes.FETCH_DASHBOARD_PRODUCT_PROFIT_LIST_FAILURE,
                                error?.message
                            )
                        );
                    }
                }
            );
    };
}

function getDashboardConfigList(
    page?: number,
    dateRange?: string,
    productId?: string,
    channel?: string,
    category?: string
) {
    return (dispatch: Dispatch) => {
        dispatch(request(dashboardTypes.FETCH_DASHBOARD_CONFIG_LIST_REQUEST));

        dashboardService
            .fetchDashboardConfigList(page, dateRange, productId, channel, category)
            .then(
                (res) => {
                    dispatch(
                        success(dashboardTypes.FETCH_DASHBOARD_CONFIG_LIST_SUCCESS, res?.data)
                    );
                },
                (error) => {
                    if (error.message) {
                        toast.custom((t) => (
                            <CustomToast t={t} message={error?.message} type="error" />
                        ));
                        dispatch(
                            failure(
                                dashboardTypes.FETCH_DASHBOARD_CONFIG_LIST_FAILURE,
                                error?.message
                            )
                        );
                    }
                }
            );
    };
}

function addForecastConfig(data: Record<string, any> | Array<Record<string, any>>) {
    return (dispatch: Dispatch) => {
        dispatch(request(dashboardTypes.ADD_FORECAST_CONFIG_REQUEST));

        dashboardService.addForecastConfig(data).then(
            (res) => {
                dispatch(success(dashboardTypes.ADD_FORECAST_CONFIG_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(dashboardTypes.ADD_FORECAST_CONFIG_FAILURE, error?.message));
                }
            }
        );
    };
}

function resetAddForecastConfig() {
    return (dispatch: Dispatch) => {
        dispatch(success(dashboardTypes.ADD_FORECAST_CONFIG_SUCCESS, null));
    };
}

function resetGetDashboardConfigList() {
    return (dispatch: Dispatch) => {
        dispatch(success(dashboardTypes.FETCH_DASHBOARD_CONFIG_LIST_SUCCESS, null));
    };
}

function resetGetDashboardGWMSuccess() {
    return (dispatch: Dispatch) => {
        dispatch(success(dashboardTypes.FETCH_DASHBOARD_GMW_SUCCESS, null));
    };
}
function request(type: string) {
    return { type: type };
}
function success(type: string, data?: any) {
    return { type: type, payload: data };
}
function failure(type: string, error?: any) {
    return { type: type, payload: error ?? "" };
}
