import { onScroll } from "helpers";
import React, { Dispatch, SetStateAction } from "react";

type TaskColumnContainerProps = {
    title: string;
    borderColor: string;
    total?: string | number;
    icon?: JSX.Element;
    children: JSX.Element;
    pagination?: { currentPage: string; noOfPages: string };
    setUpdatedPage?: Dispatch<SetStateAction<number>>;
    setLoadMore?: Dispatch<SetStateAction<boolean>>;
};

const TaskColumnContainer = ({
    title,
    borderColor,
    total,
    icon,
    children,
    pagination,
    setUpdatedPage,
    setLoadMore,
}: TaskColumnContainerProps) => {
    return (
        <div>
            <div
                className={`flex items-center justify-between pb-1 border-b-[2px] mb-3`}
                style={{
                    borderColor: borderColor,
                }}
            >
                <div className="text-sm font-rocGroteskMedium flex items-center space-x-2">
                    {icon}
                    <span>{title}</span>
                    {Boolean(total) && (
                        <span className="text-xs font-rocGroteskMedium w-[25px] h-[25px] rounded-full flex items-center justify-center border border-slate-200">
                            {total}
                        </span>
                    )}
                </div>

                {/* <div className="w-[22px] h-[22px] flex items-center justify-center rounded bg-slate-100">
                    <i className="ri-add-fill text-sm text-slate-600"></i>
                </div> */}
            </div>

            <div
                onScroll={(e) => {
                    onScroll(
                        e.target as HTMLElement,
                        Number(pagination?.currentPage),
                        Number(pagination?.noOfPages),
                        () => {
                            setUpdatedPage((prev) => prev + 1);
                            setLoadMore(true);
                        }
                    );
                }}
                className="rounded-lg bg-slate-50 p-3 h-[70vh] overflow-y-auto space-y-3"
            >
                {children}
            </div>
        </div>
    );
};

export default TaskColumnContainer;
