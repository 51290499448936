import Button from "components/common/Button";
import CustomTable from "components/common/CustomTable";
import Dropdown from "components/common/Dropdown";
import TextInput from "components/common/InputField/TextInput";
import Loader from "components/common/Loader";
import VendorAvatar from "components/common/VendorAvatar";
import PageFrame from "components/layout/PageFrame";
import { determineRiskStatus } from "helpers/determineRiskStatus";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import useInfiniteScroll from "hooks/useInfinteScroll";
import { cloneDeep, debounce, truncate, uniqBy } from "lodash";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { inventoryActions } from "redux/Ldb/actions";

const Suppliers = () => {
    const [filter, setFilter] = useState<string>("");
    const [search, setSearch] = useState<string>("");
    const [debouncedSearch, setDebouncedSearch] = useState<string>("");
    const [suppliers, setSuppliers] = useState<{ [key: string]: any }[]>([]);
    const [pagination, setPagination] = useState<{ [key: string]: any }>({
        currentPage: "1",
        noOfPages: "",
    });
    const [topSuppliers, setTopSuppliers] = useState<{ [key: string]: any }[]>([]);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { productId } = useParams();
    const { fetchingProductSuppliers, fetchedProductSuppliersSuccess } = useAppSelector(
        (state) => state.inventory
    );
    const { updatedLimit, isLoadingMore, setLoadMore, updatedPage, setUpdatedPage } =
        useInfiniteScroll(
            { current: pagination.currentPage, number_of_pages: pagination.noOfPages },
            20
        );
    const filteredTopSuppliers = topSuppliers?.filter((supplier) => supplier !== undefined);

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(e.target.value);
    };

    const handleDebouncedChange = debounce((e: React.ChangeEvent<HTMLInputElement>) => {
        setUpdatedPage(1);
        setDebouncedSearch(e.target.value);
    }, 1000);

    const suppliersTableHeader = [
        { title: "Supplier", widthClass: "w-[17.4%]" },
        { title: "Contact person", widthClass: "w-[11.4%]" },
        { title: "Email address", widthClass: "w-[18.5%]" },
        { title: "Phone number", widthClass: "w-[11.4%]" },
        { title: "Lead time", widthClass: "w-[7.6%]" },
        { title: "Risk status", widthClass: "w-[11.5%]" },
        { title: "Address", widthClass: "w-[22.2%]" },
    ];

    const suppliersTableBody = suppliers?.map((supplier, idx) => [
        {
            content: (
                <div
                    className={`py-3 pr-3 flex font-rocGroteskMedium items-center h-full border-slate-100 text-sm text-slate-800 `}
                >
                    <div className="flex items-center space-x-2">
                        <VendorAvatar
                            imageSrc={supplier?.vendor?.profile?.avatar}
                            size={32}
                            name={supplier?.vendor?.profile?.businessName}
                            containerClassname="!rounded-md"
                        />
                        <span>
                            {truncate(supplier?.vendor?.profile?.businessName, { length: 15 })}
                        </span>
                    </div>
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-800 `}
                >
                    <span>{supplier?.vendor?.lastName + " " + supplier?.vendor?.firstName}</span>
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-800 `}
                >
                    <span>{supplier?.vendor?.email}</span>
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-800 `}
                >
                    <span>{`${supplier?.vendor?.mobileExtension ?? ""}${
                        supplier?.vendor?.mobile ?? ""
                    }`}</span>
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-800 `}
                >
                    <span>{supplier?.leadTime + " days"}</span>
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-800 `}
                >
                    <div className="text-sm font-rocGroteskMedium flex items-center space-x-3">
                        {/* <i className="ri-information-line text-sm text-r-50"></i> */}
                        {/* <i className="ri-bar-chart-2-fill text-base text-r-50 "></i> */}
                        <img
                            src={determineRiskStatus(supplier?.vendor?.riskDetails?.riskScore)?.img}
                            className="h-[18px] w-[24px]"
                            alt=""
                        />
                        <span>
                            {determineRiskStatus(supplier?.vendor?.riskDetails?.riskScore)?.value}{" "}
                            risk
                        </span>
                    </div>
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-800 `}
                >
                    <span>{supplier?.vendor?.profile?.address?.address}</span>
                </div>
            ),
        },
    ]);

    useEffect(() => {
        dispatch(
            inventoryActions.fetchProductSuppliers(
                15,
                updatedPage,
                productId,
                filter === "all" ? "" : filter,
                debouncedSearch
            )
        );

        return () => {
            dispatch(inventoryActions.resetFetchProductSuppliers());
        };
    }, [filter, productId, debouncedSearch, updatedPage]);

    useEffect(() => {
        if (Boolean(fetchedProductSuppliersSuccess)) {
            const parsedSupplier = cloneDeep(fetchedProductSuppliersSuccess);

            setSuppliers((prev) =>
                updatedPage === 1
                    ? uniqBy([...parsedSupplier?.suppliers], "_id")
                    : uniqBy([...prev, ...parsedSupplier?.suppliers], "_id")
            );
            setTopSuppliers([
                parsedSupplier?.topSupplier?.[0],
                parsedSupplier?.leastRiskSupplier?.[0],
            ]);
            setPagination({
                currentPage: parsedSupplier.pagination?.current,
                noOfPages: parsedSupplier.pagination?.number_of_pages,
            });
            setLoadMore(false);
        }
    }, [fetchedProductSuppliersSuccess, updatedPage]);

    return (
        <PageFrame>
            <div>
                <div className="pb-6">
                    {filteredTopSuppliers?.length > 0 && (
                        <div className="mb-8">
                            <div className="flex items-center space-x-2.5 mb-4">
                                <h5 className="text-xl font-rocGroteskMedium text-g-75">
                                    Top suppliers
                                </h5>
                            </div>

                            <div className="grid grid-cols-4 space-x-3">
                                {topSuppliers?.[0] && (
                                    <div className="p-4 rounded-[6px] border border-slate-200 shadow-boxShadow-12">
                                        <div className="mb-1 flex items-center space-x-1">
                                            <VendorAvatar
                                                size={32}
                                                name={
                                                    topSuppliers?.[0]?.vendorProfile?.businessName
                                                }
                                                imageSrc={topSuppliers?.[0]?.vendorProfile?.avatar}
                                                textClassname=""
                                            />
                                        </div>
                                        <span className="block text-base font-rocGroteskMedium text-g-75">
                                            {truncate(
                                                topSuppliers?.[0]?.vendorProfile?.businessName,
                                                {
                                                    length: 25,
                                                }
                                            )}
                                        </span>
                                        <span className="block text-sm font-rocGroteskMedium text-slate-500 mb-2">
                                            {truncate(
                                                topSuppliers?.[0]?.vendorProfile?.address?.address,
                                                {
                                                    length: 30,
                                                }
                                            )}
                                        </span>
                                        <div className="flex items-center space-x-1 px-1.5 h-6 rounded-[3px] bg-[#F0FDF4] w-fit">
                                            <i className="ri-arrow-up-line text-sm text-[#22C55E] mt-[-2px]"></i>
                                            <span className="text-sm text-[#22C55E] font-rocGroteskMedium">
                                                {topSuppliers?.[0]?.leadTime} Days
                                            </span>
                                        </div>
                                    </div>
                                )}

                                {topSuppliers?.[1] && (
                                    <div className="p-4 rounded-[6px] border border-slate-200 shadow-boxShadow-12">
                                        <div className="mb-1 flex items-center space-x-1">
                                            <VendorAvatar
                                                size={32}
                                                name={
                                                    topSuppliers?.[1]?.vendorProfile?.businessName
                                                }
                                                imageSrc={topSuppliers?.[1]?.vendorProfile?.avatar}
                                                textClassname=""
                                            />
                                        </div>
                                        <span className="block text-base font-rocGroteskMedium text-g-75">
                                            {truncate(
                                                topSuppliers?.[1]?.vendorProfile?.businessName,
                                                {
                                                    length: 25,
                                                }
                                            )}
                                        </span>
                                        <span className="block text-sm font-rocGroteskMedium text-slate-500 mb-2">
                                            {truncate(
                                                topSuppliers?.[1]?.vendorProfile?.address?.address,
                                                {
                                                    length: 30,
                                                }
                                            )}
                                        </span>
                                        <div className="flex items-center space-x-1 px-1.5 h-6 rounded-[3px] border border-slate-200 w-fit">
                                            <i className="ri-bar-chart-2-fill text-sm text-slate-500 mt-[-2px]"></i>
                                            <span className="text-sm text-slate-500 font-rocGroteskMedium">
                                                {
                                                    determineRiskStatus(
                                                        topSuppliers?.[1]?.riskDetails?.riskScore
                                                    )?.value
                                                }{" "}
                                                risk
                                            </span>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}

                    <div className="mb-8">
                        <div className="flex items-center justify-end mb-4">
                            {/* <h5 className="text-xl font-rocGroteskMedium text-g-75">
                                Suppliers
                            </h5> */}

                            <div className="flex items-center space-x-3">
                                <TextInput
                                    name={"search"}
                                    value={search}
                                    type={"text"}
                                    inputPlaceholder={"Search supplier, Date, Status"}
                                    inputClassName={"!h-[38px] pl-[0px] !bg-white text-sm !mb-0"}
                                    onChange={handleSearch}
                                    onInput={handleDebouncedChange}
                                    leftIcon={
                                        <i className="ri-search-line before:content-['\f0d1] text-slate-400 mt-[2px]"></i>
                                    }
                                    inputContainerClassname={
                                        "!rounded-[4px] !w-[400px] max-sm:!w-full !pl-[40px] !h-[40px] !bg-white !border !border-n-20"
                                    }
                                    containerClassname={"!w-fit"}
                                />

                                <Dropdown
                                    handleChange={(name, value) => {
                                        setUpdatedPage(1);
                                        setFilter(value);
                                    }}
                                    dropdown={
                                        <div className="flex items-center justify-between whitespace-nowrap h-[40px] w-[237px] py-2 px-4 space-x-1 rounded-[5px] border border-n-20">
                                            <div className="flex items-center space-x-1">
                                                <i className="ri-bar-chart-2-fill text-sm text-g-75 "></i>
                                                <span
                                                    className={`text-g-75 font-rocGroteskMedium text-[13px] capitalize`}
                                                >
                                                    {filter} risk supplier
                                                </span>
                                            </div>
                                            <i className="ri-arrow-down-s-line text-g-75"></i>
                                        </div>
                                    }
                                    // dropdownItemsClasses={`!px-0 !py-0`}
                                    // dropdownClassname={"!w-full !mb-10 !-mt-7"}
                                    dropdownContainerClasses={`shadow-cardShadow-4 border border-slate-100  rounded !max-h-fit`}
                                    dropdownOptions={[
                                        {
                                            label: "All",
                                            value: "all",
                                        },
                                        {
                                            label: "High risk supplier",
                                            value: "high",
                                        },
                                        {
                                            label: "Medium risk supplier",
                                            value: "medium",
                                        },
                                        {
                                            label: "Low risk supplier",
                                            value: "low",
                                        },
                                    ]}
                                />

                                <Button
                                    btnClassname="px-4 py-2 !w-[114px] !h-10 whitespace-nowrap"
                                    btnText="Add Supplier"
                                    onClick={() => navigate("/dashboard/suppliers/create-supplier")}
                                />
                            </div>
                        </div>
                        {suppliers.length > 0 ? (
                            <div>
                                <CustomTable
                                    tableHeader={suppliersTableHeader}
                                    tableBody={suppliersTableBody}
                                    isScrollable={false}
                                    isCellBordered={false}
                                    isCellSelectable={false}
                                    isAllSelectable={false}
                                    headerContainerClass="!bg-slate-50"
                                    headerItemClass="font-rocGroteskMedium "
                                    tableClass="mb-6"
                                />

                                {fetchingProductSuppliers && isLoadingMore && (
                                    <div className="flex justify-center my-4">
                                        <Loader color="gm-25" size={4} />
                                    </div>
                                )}
                                {pagination?.currentPage === pagination?.noOfPages && (
                                    <div className="flex my-4 justify-center text-slate-500 text-sm font-rocGroteskMedium">
                                        End of list
                                    </div>
                                )}
                            </div>
                        ) : (
                            <div>
                                <div className="flex justify-center items-center h-[300px]">
                                    <span className="text-g-75 font-rocGroteskMedium text-lg">
                                        No supplier data available
                                    </span>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </PageFrame>
    );
};

export default Suppliers;
