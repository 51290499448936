import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../hooks/storeHooks";
import { vendorActions } from "../../../../redux/Ldb/actions";
import ModalContainer from "../../../common/ModalContainer";
import Button from "../../../common/Button";
import { useNavigate } from "react-router-dom";

type deleteModalProps = {
    deleteSupplier: { name: string; id: string };
    confirmDeleteModal: boolean;
    setConfirmDeleteModal: (value: boolean) => void;
    detailsPage?: boolean;
};

const DeleteSupplierModal = ({
    deleteSupplier,
    confirmDeleteModal,
    setConfirmDeleteModal,
    detailsPage,
}: deleteModalProps) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [failedModal, setFailedModal] = useState<boolean>(false);
    const [successModal, setSuccessModal] = useState<boolean>(false);

    const { deletingSupplier, deleteSupplierSuccess, deleteSupplierFailure } = useAppSelector(
        (state) => state?.vendor
    );
    useEffect(() => {
        if (Boolean(deleteSupplierSuccess)) {
            dispatch(vendorActions.resetDeleteVendor());
            setConfirmDeleteModal(false);
            if (deleteSupplierSuccess?.success) {
                if (detailsPage) {
                    setTimeout(() => {
                        navigate("/dashboard/suppliers");
                    }, 500);
                } else {
                    dispatch(vendorActions?.fetchVendors());
                }
                setSuccessModal(true);
            }
        }
    }, [deleteSupplierSuccess]);

    useEffect(() => {
        if (Boolean(deleteSupplierFailure)) {
            dispatch(vendorActions.resetDeleteVendor());

            setConfirmDeleteModal(false);
            setFailedModal(true);
        }
    }, [deleteSupplierFailure]);

    return (
        <div>
            <ModalContainer
                open={confirmDeleteModal}
                modalClassName={"py-10 px-10"}
                tailwindClassName="sm:w-[500px]"
                showCloseIcon={false}
                closeModal={() => {
                    setConfirmDeleteModal(false);
                }}
            >
                <div className="bg-white !px-2 pt-4 pb-7  rounded-[12px]">
                    <div className="flex px-6 items-center justify-end">
                        <span
                            className="material-icons text-gm-45 text-[24px] cursor-pointer font-medium"
                            onClick={() => {
                                setConfirmDeleteModal(false);
                            }}
                        >
                            close
                        </span>
                    </div>

                    <div className="mt-4 px-6">
                        <div className="text-center flex justify-center flex-col items-center space-y-5">
                            <i className="ri-delete-bin-line text-[29px] text-[#ff0000] "></i>
                            <div className=" font-rocGroteskMedium">
                                <h3 className="text-xl  ">Delete supplier?</h3>
                                <p className="mt-1 text-sm text-slate-500">
                                    Are you sure you want to delete this supplier{" "}
                                    <span className="text-gm-50">
                                        {" "}
                                        {`${deleteSupplier?.name} ?`}
                                    </span>
                                    <br />
                                    This action cannot be undone.
                                </p>
                            </div>
                        </div>
                        <div className="mt-5 border-t py-4 border-slate-200 flex justify-between gap-2.5 flex-col xs:flex-row">
                            <Button
                                btnText={"Close"}
                                btnClassname="!py-3 cursor-pointer !text-[14px] !text-gm-50 !border border-n-20 !font-rocGroteskMedium !px-4 !bg-n-20"
                                onClick={() => {
                                    setConfirmDeleteModal(false);
                                }}
                            />
                            <Button
                                btnText={"Delete Supplier"}
                                btnClassname="!py-3 cursor-pointer !text-[14px] !text-[#fff] !border border-n-20 !font-rocGroteskMedium !px-4 !bg-[#ff0000]"
                                isLoading={deletingSupplier}
                                onClick={() => {
                                    dispatch(vendorActions?.deleteSupplier(deleteSupplier?.id));
                                }}
                            />
                        </div>
                    </div>
                </div>
            </ModalContainer>

            {successModal && (
                <ModalContainer
                    open={successModal}
                    modalClassName={"py-10 px-10"}
                    tailwindClassName="w-[500px]"
                    showCloseIcon={false}
                    closeModal={() => {
                        setSuccessModal(false);
                        if (detailsPage) {
                            navigate("/dashboard/suppliers");
                        }
                    }}
                >
                    <div className="bg-white !px-2 pt-4 pb-7  rounded-[12px]">
                        <div className="flex px-6 items-center justify-end">
                            <span
                                className="material-icons text-gm-45 text-[24px] cursor-pointer font-medium"
                                onClick={() => {
                                    setSuccessModal(false);
                                    if (detailsPage) {
                                        navigate("/dashboard/suppliers");
                                    }
                                }}
                            >
                                close
                            </span>
                        </div>
                        <div className="mt-4 px-6">
                            <div className="text-center flex justify-center flex-col items-center space-y-5">
                                <div className="w-14 h-14 flex justify-center items-center bg-[#00DB8F] rounded-full">
                                    <i className="ri-checkbox-circle-fill text-[29px] text-white "></i>
                                </div>
                                {/* <i className="ri-delete-bin-line text-[29px] text-[#ff0000] "></i> */}
                                <div className=" font-rocGroteskMedium">
                                    <h3 className="text-xl  ">Delete complete</h3>
                                    <p className="mt-1 text-sm text-slate-500">
                                        The supplier has been successfully deleted from the system.
                                        All associated date and transactions has been permentaly
                                        removed
                                    </p>
                                </div>
                            </div>
                            <div className="mt-5 border-t pt-4 border-slate-200 ">
                                <Button
                                    btnText={"Done"}
                                    btnClassname="!py-3 cursor-pointer !text-[14px] !text-gm-50 !border border-n-20 !font-rocGroteskMedium !px-4 !bg-n-20"
                                    onClick={() => {
                                        setSuccessModal(false);
                                        if (detailsPage) {
                                            navigate("/dashboard/suppliers");
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </ModalContainer>
            )}
            {failedModal && (
                <ModalContainer
                    open={failedModal}
                    modalClassName={"py-10 px-10"}
                    tailwindClassName="w-[500px]"
                    showCloseIcon={false}
                    closeModal={() => {
                        setFailedModal(false);
                    }}
                >
                    <div className="bg-white !px-2 pt-4 pb-7  rounded-[12px]">
                        <div className="flex px-6 items-center justify-end">
                            <span
                                className="material-icons text-gm-45 text-[24px] cursor-pointer font-medium"
                                onClick={() => {
                                    setFailedModal(false);
                                }}
                            >
                                close
                            </span>
                        </div>
                        <div className="mt-4 px-6">
                            <div className="text-center flex justify-center flex-col items-center space-y-5">
                                <img
                                    src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1713887164/Caution_sign_kjimm1.svg"
                                    alt=""
                                    className=""
                                />

                                <div className=" font-rocGroteskMedium">
                                    <h3 className="text-xl  ">Invalid action</h3>
                                    <p className="mt-1 text-sm text-slate-500">
                                        Unfortunately, due to ongoing activities you are unable to
                                        delete this supplier from the system
                                    </p>
                                </div>
                            </div>
                            <div className="mt-5 border-t pt-4 border-slate-200 ">
                                <Button
                                    btnText={"Close"}
                                    btnClassname="!py-3 cursor-pointer !text-[14px] !text-gm-50 !border border-n-20 !font-rocGroteskMedium !px-4 !bg-n-20"
                                    onClick={() => {
                                        setFailedModal(false);
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </ModalContainer>
            )}
        </div>
    );
};

export default DeleteSupplierModal;
