import React from "react";
import {DndProvider, useDrag, useDrop} from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend";
import TextInput from "./TextInput";
import { formatMoney } from "helpers";

type DraggableContainerProps = {
    index: number;
    onDelete: (index: number) => void;
    containers: any;
    moveContainer: (fromIndex: number, toIndex: number) => void;
    onChange: (containerIndex: number, fieldName: string, value: string) => void;
};

type DraggableContainerListProps = {
    onChange: (containerIndex: number, fieldName: string, value: string) => void;
    currency: string;
    containers: {[key: string]: any}[];
    setContainers: React.Dispatch<React.SetStateAction<{[key: string]: any}[]>>;
};

const DraggableContainer = ({
    index,
    onDelete,
    containers,
    moveContainer,
    onChange,
}: DraggableContainerProps) => {
    const [, ref] = useDrag({
        type: "CONTAINER",
        item: {index},
    });

    const [, drop] = useDrop({
        accept: "CONTAINER",
        hover: (item: {index: number}) => {
            if (item.index !== index) {
                moveContainer(item.index, index);
                item.index = index;
            }
        },
    });

    return (
        <div ref={(node) => ref(drop(node))} className="flex md:flex items-center space-x-2 ">
            <div className=" md:flex hidden items-center">
                <i className="ri-draggable text-slate-400 cursor-pointer text-[27px]"></i>
            </div>
            <div className="w-full">
            <div className="w-full  md:hidden block">
                    <TextInput
                        name={`name-${index}`}
                        type="text"
                        value={containers[index]?.name || ""}
                        onChange={(e) => onChange(index, "name", e.target.value)}
                        placeholder="Item/Service name"
                        required={true}
                        containerClassname="mb-2"
                    />
            </div>
            <div className="flex w-full gap-3 md:gap-0 md:space-x-5 pt-3 md:items-center items-start">
                <div className="md:w-[90%] md:block hidden">
                    <TextInput
                        name={`name-${index}`}
                        type="text"
                        value={containers[index]?.name || ""}
                        onChange={(e) => onChange(index, "name", e.target.value)}
                        placeholder="Item/Service name"
                        required={true}
                        containerClassname="mb-2"
                    />
                </div>
                <div className="md:w-[30%] w-full">
                    <TextInput
                        name={`qty-${index}`}
                        type="number"
                        value={containers[index]?.qty || ""} // Set value from state
                        onChange={(e) => onChange(index, "qty", e.target.value)}
                        placeholder="QTY"
                        required={true}
                        containerClassname="mb-2"
                    />
                </div>
                <div className="md:w-[30%] w-full">
                    <TextInput
                        name={`price-${index}`}
                        type="number"
                        value={containers[index]?.price?.amount || ""}
                        onChange={(e) =>
                            onChange(index, "price", {
                                ...containers[index].price,
                                amount: e.target.value,
                            })
                        }
                        placeholder="Unit Price"
                        required={true}
                        containerClassname="mb-2"
                    />
                </div>
                <div className="md:w-[30%] w-full border rounded text-center items-center flex justify-center mb-2 h-[50px]">
                    <p>{formatMoney()?.format(containers[index]?.subtotal?.amount) || 0}</p>
                </div>
            </div>
            </div>
            <div>
                <i
                    className="ri-delete-bin-line text-[20px] text-slate-400 cursor-pointer"
                    onClick={() => onDelete(index)}
                ></i>
            </div>
        </div>
    );
};

export const DraggableContainerList = ({
    onChange,
    currency,
    containers,
    setContainers,
}: DraggableContainerListProps) => {
    const moveContainer = (fromIndex: number, toIndex: number) => {
        const newContainers = [...containers];
        const [movedContainer] = newContainers.splice(fromIndex, 1);
        newContainers.splice(toIndex, 0, movedContainer);
        setContainers(newContainers);
    };

    const handleAddContainer = () => {
        setContainers([
            ...containers,
            {
                name: "",
                qty: "",
                price: {
                    amount: 0,
                    currency: currency || "",
                },
                subtotal: {
                    amount: 0,
                    currency: currency || "",
                },
            },
        ]);
    };

    const handleDeleteContainer = (index: number) => {
        const newContainers = [...containers];
        newContainers.splice(index, 1);
        setContainers(newContainers);
    };

    return (
        <DndProvider backend={HTML5Backend}>
            <div>
                {containers.map((_, index) => (
                    <DraggableContainer
                        key={index}
                        index={index}
                        onDelete={handleDeleteContainer}
                        moveContainer={moveContainer}
                        containers={containers}
                        onChange={(containerIndex, fieldName, value) =>
                            onChange(containerIndex, fieldName, value)
                        }
                    />
                ))}

                <div
                    className="flex items-center mt-7 w-fit  px-7  space-x-3 cursor-pointer pb-6"
                    onClick={handleAddContainer}
                >
                    <div className="w-[40px] h-[40px] rounded-full flex justify-center items-center border border-slate-100">
                        <i className="ri-add-fill text-slate-500 text-[20px]"></i>
                    </div>
                    <p className="text-[14px] font-rocGroteskMedium text-slate-500 ">Add an item</p>
                </div>
            </div>
        </DndProvider>
    );
};
