import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import useInfiniteScroll from "hooks/useInfinteScroll";
import { uniqBy } from "lodash";
import React, { useEffect, useState } from "react";
import { productionActions } from "redux/Ldb/actions";

const useGetBOMList = (debouncedSearch) => {
    const dispatch = useAppDispatch();
    const [bomList, setBomList] = useState<{ [key: string]: any }[]>([]);

    const [pagination, setPagination] = useState<{ currentPage: string; noOfPages: string }>({
        currentPage: "1",
        noOfPages: "",
    });

    const { fetchingBOMList, fetchedBOMListSuccess } = useAppSelector((state) => state.production);

    const { updatedLimit, isLoadingMore, setLoadMore, updatedPage, setUpdatedPage } =
        useInfiniteScroll(
            { current: pagination.currentPage, number_of_pages: pagination.noOfPages },
            10
        );

    useEffect(() => {
        setUpdatedPage(1);
    }, [debouncedSearch]);

    useEffect(() => {
        dispatch(productionActions.getBOMList(10, updatedPage, debouncedSearch));
    }, [dispatch, debouncedSearch, updatedPage]);

    useEffect(() => {
        if (Boolean(fetchedBOMListSuccess)) {
            setBomList(fetchedBOMListSuccess?.products);
        }
    }, [fetchedBOMListSuccess]);

    useEffect(() => {
        if (Boolean(fetchedBOMListSuccess)) {
            setBomList((prev) =>
                updatedPage === 1
                    ? uniqBy([...fetchedBOMListSuccess?.products], "_id")
                    : uniqBy([...prev, ...fetchedBOMListSuccess?.products], "_id")
            );
            setPagination({
                currentPage: fetchedBOMListSuccess.pagination?.current,
                noOfPages: fetchedBOMListSuccess.pagination?.number_of_pages,
            });
            setLoadMore(false);
            dispatch(productionActions.resetBOMList());
        }
    }, [dispatch, fetchedBOMListSuccess, updatedPage, debouncedSearch]);

    return {
        data: bomList,
        isLoading: fetchingBOMList,
        isLoadingMore,
        pagination,
    };
};

export default useGetBOMList;
