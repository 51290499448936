import { getFromStorage } from "helpers";
import useGetUserProfile from "hooks/useGetUserProfile";

const Navlinks = () => {
    const [profile] = useGetUserProfile();
    // const userProfile = getFromStorage("ally-user");

    const links = [
        {
            title: "Dashboard",
            link: "/dashboard/home",
            childrenLinks: [""],
            iconClass: "",
        },
        {
            title: "Inventory",
            link: "/dashboard/inventory",
            childrenLinks: [""],
            iconClass: "ri-shopping-basket-line",
        },
        {
            title: "Purchase",
            link: "/dashboard/purchase",
            childrenLinks: [""],
            iconClass: "ri-file-list-3-line",
        },
        {
            title: "Suppliers",
            link: "/dashboard/suppliers",
            childrenLinks: [""],
            iconClass: "ri-building-line",
        },
        // {
        //     title: "Production",
        //     link: "/dashboard/production",
        //     childrenLinks: [""],
        //     iconClass: "",
        // },
        {
            title: "WorkforceIQ",
            link: "/dashboard/workforce",
            childrenLinks: [""],
            iconClass: "ri-building-line",
        },
        {
            title: "Task",
            link: "/dashboard/task",
            childrenLinks: [""],
            iconClass: "ri-building-line",
        },

        {
            title: "Sales",
            link: "/dashboard/order",
            childrenLinks: [""],
            iconClass: "ri-file-line",
        },

        // {
        //     title: "Integrations",
        //     link: "/dashboard/integrations",
        //     childrenLinks: [""],
        //     iconClass: "ri-link",
        // },
        // {
        //     title: "Teams",
        //     link: "/dashboard/teams",
        //     childrenLinks: [""],
        //     iconClass: "",
        // },

        // {
        //     title: "Playground",
        //     link: "/dashboard/playground",
        //     childrenLinks: [""],
        //     iconClass: "ri-task-line",
        // },

        // {
        //     title: "Track & Trace",
        //     link: "/dashboard/track-trace",
        //     childrenLinks: [""],
        //     iconClass: "",
        // },
    ];
    // ?.filter((item) => {
    //     if (profile?.role === "owner") {
    //         return true;
    //     } else {
    //         return !item?.title?.toLowerCase()?.includes("workforce");
    //     }
    // });

    return links;
};

export default Navlinks;
