import Button from "components/common/Button";
import CustomTable from "components/common/CustomTable";
import CustomTableLoader from "components/common/CustomTableLoader";
import Dropdown from "components/common/Dropdown";
import TextInput from "components/common/InputField/TextInput";
import Loader from "components/common/Loader";
import ModalContainer from "components/common/ModalContainer";
import VendorAvatar from "components/common/VendorAvatar";
import PageFrame from "components/layout/PageFrame";
import { formatMoney, getCurrencyFromCurrencyCode, onScroll } from "helpers";
import useGetSalesChannel from "hooks/Dashboard/useGetSalesChannel";
import useGetProductList from "hooks/inventoryHooks/useGetProductList";
import useGetWarehouseStats from "hooks/inventoryHooks/useGetWarehouseStats";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import { debounce, filter, truncate } from "lodash";
import moment, { duration } from "moment";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { inventoryActions } from "redux/Ldb/actions";

const WarehouseDetails = () => {
    const { id } = useParams();
    const limit = 10;
    const inputRef = useRef(null);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [warehouseLimit, setWarehouseLimit] = useState(limit);
    const [warehouseDetails, setWarehouseDetails] = useState<any>(null);
    const [warehouseList, setWarehouseList] = useState<any>([]);
    const [loadMoreWarehouse, setLoadMoreWarehouse] = useState(false);
    const [modalProductFilter, setModalProductFilter] = useState<string>("");
    const [modalDurationFilter, setModalDurationFilter] = useState<string>("This year");
    const [deleteWarehouseShow, setDeleteWarehouseShow] = useState(false);
    const [modalChannelFilter, setModalChannelFilter] = useState<string>("");
    const [filters, setFilters] = useState({
        search: "",
        channel: "",
        duration: "",
    });
    const [debouncedSearch, setDebouncedSearch] = useState("");
    const clearSearchRef = useRef(null);
    const [warehousePagination, setWarehousePagination] = useState<{
        current: string | number;
        number_of_pages: string | number;
    }>({
        current: "",
        number_of_pages: "",
    });

    const {
        fetchingSingleWarehouseList,
        fetchedSingleWarehouseListSuccess,
        fetchingListStockWarehouse,
        fetchedListStockWarehouseSuccess,
        deleteWarehouse,
        deleteWarehouseSuccess,
    } = useAppSelector((state) => state.inventory);

    const { data: salesChannel, isFetching: salesChannelLoading } = useGetSalesChannel();
    const {
        data: productData,
        handleDebouncedChange,
        handleSearch,
        isFetching: productListLoading,
        search,
        setSearch,
        // setDebouncedSearch,
        pagination,
        isLoadingMore,
    } = useGetProductList();
    const { data: stats, isFetching: statsLoading } = useGetWarehouseStats();
    const dashboardContent = document.getElementById("custom-table");
    // const onWarehouseScroll = useCallback(
    //     () =>
    //         onScroll(
    //             dashboardContent,
    //             warehousePagination?.current as number,
    //             warehousePagination?.number_of_pages as number,
    //             () => {
    //                 setWarehouseLimit(() => warehouseLimit + limit);
    //                 setLoadMoreWarehouse(true);
    //             }
    //         ),
    //     [dashboardContent, warehousePagination, warehouseLimit]
    // );
    const getStartAndEndDate = (duration: string) => {
        let startDate, endDate;
        if (duration === "yearly") {
            startDate = moment().startOf("year").toISOString();
            endDate = moment().endOf("year").toISOString();
        } else if (duration === "monthly") {
            startDate = moment().startOf("month").toISOString();
            endDate = moment().endOf("month").toISOString();
        } else if (duration === "weekly") {
            startDate = moment().startOf("week").toISOString();
            endDate = moment().endOf("week").toISOString();
        } else if (duration === "daily") {
            startDate = moment().startOf("day").toISOString();
            endDate = moment().endOf("day").toISOString();
        }
        return { startDate, endDate };
    };

    const deleteProductWarehouse = () => {
        dispatch(inventoryActions.deleteWarehouse(id));
    };

    useEffect(() => {
        if (Boolean(deleteWarehouseSuccess)) {
            dispatch(inventoryActions.resetDeleteWarehouse());
            setDeleteWarehouseShow(false);
            navigate(-1);
        }
    }, [deleteWarehouseSuccess]);

    useEffect(() => {
        const period = filters?.duration?.split("-");
        const { startDate, endDate } = getStartAndEndDate(period?.[0]);

        dispatch(
            inventoryActions.fetchSingleWarehouseList(
                id,
                null,
                debouncedSearch,
                filters?.channel,
                startDate,
                endDate
            )
        );
    }, [id, debouncedSearch, filters?.channel, filters?.duration]);

    // useEffect(() => {
    //     dispatch(inventoryActions.fetchListStockWarehouse(id, warehouseLimit));
    // }, [id, warehouseLimit]);

    // useEffect(() => {
    //     dashboardContent?.addEventListener("scroll", onWarehouseScroll);
    //     return () => {
    //         dashboardContent?.removeEventListener("scroll", onWarehouseScroll);
    //     };
    // }, [dashboardContent, onWarehouseScroll]);

    useEffect(() => {
        if (Boolean(fetchedSingleWarehouseListSuccess)) {
            // console.log("fetchedSingleWarehouseListSuccess", fetchedSingleWarehouseListSuccess);
            setWarehouseDetails(fetchedSingleWarehouseListSuccess?.warehouse);
        }
    }, [fetchedSingleWarehouseListSuccess]);

    // useEffect(() => {
    //     if (Boolean(fetchedListStockWarehouseSuccess)) {
    //         setWarehouseList(fetchedListStockWarehouseSuccess?.products);
    //         setWarehousePagination({
    //             current: fetchedListStockWarehouseSuccess?.pagination?.current,
    //             number_of_pages: fetchedListStockWarehouseSuccess?.pagination?.number_of_pages,
    //         });
    //     }
    // }, [fetchedListStockWarehouseSuccess]);

    const dateValues2 = [
        {
            period: "This year",
            value: "yearly",
        },
        {
            period: "This month",
            value: "monthly",
        },
        {
            period: "This week",
            value: "weekly",
        },

        {
            period: "Today",
            value: "daily",
        },
        {
            period: "All times",
            value: "",
        },
    ];

    const tableHeader = [
        { title: "Product", widthClass: "w-[30%]", sortIcon: false },
        { title: "Current Qty", widthClass: "w-[18%]", sortIcon: false },
        { title: "Last Restocked", widthClass: "w-[20%]", sortIcon: false },
        { title: "Supplier", widthClass: "w-[23%]" },
        { title: "Supplier lead time", widthClass: "w-[20%]" },
        { title: "Forecasted needs", widthClass: "w-[20%]" },
        { title: "Demand forecast", widthClass: "w-[21%]", sortIcon: false },
        { title: "Unit price", widthClass: "w-[15%]", sortIcon: false },
        { title: "Action", widthClass: "w-[22%]" },
    ];

    const tableBody = warehouseDetails?.products?.map((item) => [
        {
            content: (
                <div
                    key={item?._id}
                    className={`py-3 pr-3 border-slate-100 text-sm text-gm-50 font-rocGroteskMedium `}
                >
                    <div className="flex items-center gap-2">
                        <VendorAvatar
                            name={item?.inventory?.productName}
                            imageSrc={item?.inventory?.productImageDetails?.productAvatar}
                            size={32}
                            containerClassname="!rounded-none !border !bg-white"
                        />

                        <span>{truncate(item?.inventory?.productName, { length: 20 })}</span>
                    </div>
                </div>
            ),
            widthClass: "!border-b",
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 space-y-1 flex flex-col items-start h-full border-slate-100 text-sm font-rocGroteskMedium text-gm-50 `}
                >
                    <p className={item?.inventory?.inventoryIncident?.incidentType && "text-r-55"}>
                        {formatMoney().format(item?.totalStockLevel) || "N/A"}
                    </p>
                    {item?.inventory?.inventoryIncident?.incidentType && (
                        <div className="bg-[#FFD8D2] rounded-[10px] px-2 py-[2px]">
                            <p className="text-[8px] leading-[11px] capitalize text-[#FF3D1D] font-rocGroteskMedium">
                                Likely {item?.inventory?.inventoryIncident?.incidentType}
                            </p>
                        </div>
                    )}
                </div>
            ),
            widthClass: "!border-b",
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 flex items-center h-full border-slate-100 text-sm font-rocGroteskMedium text-gm-50 `}
                >
                    {item?.lastSupplied
                        ? moment(item?.lastSupplied)?.format("MMM DD, YYYY")
                        : "N/A"}
                </div>
            ),
            widthClass: "!border-b",
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 flex items-center h-full border-slate-100 text-sm font-rocGroteskMedium text-gm-50 `}
                >
                    <div className="flex items-center gap-2">
                        {/* <VendorAvatar
                            name="05"
                            imageSrc={item?.inventory?.productImageDetails?.productAvatar}
                            size={16}
                            containerClassname="!rounded-none !border !bg-white"
                        /> */}

                        <span>
                            {item?.lastSupplier
                                ? truncate(item?.lastSupplier, { length: 20 })
                                : "N/A"}
                        </span>
                    </div>
                </div>
            ),
            widthClass: "!border-b",
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 flex items-center h-full border-slate-100 text-sm font-rocGroteskMedium text-gm-50 `}
                >
                    {item?.leadTime?.value && item?.leadTime?.duration
                        ? `${item?.leadTime?.value} ${item?.leadTime?.duration}`
                        : "N/A"}
                </div>
            ),
            widthClass: "!border-b",
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 flex items-center h-full border-slate-100 text-sm font-rocGroteskMedium text-gm-50 `}
                >
                    {item?.forecastNeeds ? (
                        <div className="flex items-center space-x-1.5">
                            <span className="material-symbols-outlined text-[14px] text-r-50">
                                warning
                            </span>
                            <span>{formatMoney().format(item?.forecastNeeds?.toFixed(0))}</span>
                        </div>
                    ) : (
                        "N/A"
                    )}
                </div>
            ),
            widthClass: "!border-b",
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 border-slate-100 text-sm text-gm-50 font-rocGroteskMedium `}
                >
                    <div className="flex items-center gap-2">
                        {item?.demandForecast
                            ? formatMoney().format(item?.demandForecast?.toFixed(0))
                            : "N/A"}
                    </div>
                </div>
            ),
            widthClass: "!border-b",
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 border-slate-100 text-sm text-gm-50 font-rocGroteskMedium `}
                >
                    <div className="flex items-center gap-2">
                        {getCurrencyFromCurrencyCode(item?.inventory?.unitCost?.currency)}
                        {item?.inventory?.unitCost?.amount}
                    </div>
                </div>
            ),
            widthClass: "!border-b",
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 border-slate-100 text-sm text-gm-50 font-rocGroteskMedium `}
                >
                    <Button
                        btnText="Restock"
                        btnClassname="!px-4 !py-2 !w-fit !h-[35px] !text-[13px] !whitespace-nowrap"
                        onClick={() => {
                            navigate(
                                `/dashboard/purchase/create-purchase-request?inventory=${item?.inventory?._id}`
                            );
                            // handleIncidentId(product?._id);
                        }}
                    />

                    {/* <Dropdown
                        name="filter"
                        value={""}
                        dropdownTriggerClassName={" "}
                        // handleChange={(name, value) => handleFilterChange(value)}
                        dropdown={
                            <div className="bg-y-10 flex items-center justify-between h-10 space-x-2  px-3 !rounded">
                                <div className="flex items-center space-x-2 py-2.5 pr-3 border-r border-[#D89C00]">
                                    <span className="text-[13px] whitespace-nowrap font-rocGroteskMedium">
                                        Re-order
                                    </span>
                                </div>
                                <div className=" py-2.5">
                                    <i className="ri-arrow-down-s-line text-lg text-gm-50"></i>
                                </div>
                            </div>
                        }
                        dropdownClassName={"!w-full !mb-10 !-mt-7"}
                        dropdownContainerClasses={`shadow-cardShadow-4 left-[-38px] !mt-0  !w-[159px] max-sm:!w-full rounded !max-h-fit
                                                 !p-0 !top-[105%]`}
                        dropdownItemsClasses="!bg-transparent border-b-[1px] border-b-slate-100"
                        dropdownOptions={[
                            {
                                customChild: (
                                    <div>
                                        <div className="py-[2px] px-3 flex items-center space-x-2 border-b border-slate-200">
                                            <span className="text-[10px] whitespace-nowrap text-slate-500 font-rocGroteskMedium">
                                                Schedule re-order date
                                            </span>
                                        </div>
                                        <div className="py-2.5 px-3 flex items-center space-x-2 border-b border-slate-200">
                                            <span className="text-[13px] whitespace-nowrap font-rocGroteskMedium">
                                                Re-order tomorrow
                                            </span>
                                        </div>
                                        <div className="py-2.5 px-3 flex items-center space-x-2 border-b border-slate-200">
                                            <span className="text-[13px] whitespace-nowrap font-rocGroteskMedium">
                                                Re-order next week
                                            </span>
                                        </div>
                                        <div className="py-2.5 px-3 flex items-center space-x-2">
                                            <span className="text-[13px] whitespace-nowrap font-rocGroteskMedium">
                                                Custom date
                                            </span>
                                        </div>
                                    </div>
                                ),
                            },
                        ]}
                    /> */}
                </div>
            ),
            widthClass: "!border-b",
        },
    ]);

    useEffect(() => {
        (inputRef?.current as any)?.focus();
    }, []);

    return (
        <PageFrame isLoading={(fetchingSingleWarehouseList && !warehouseDetails) || statsLoading}>
            <div className="">
                <div className="bg-white rounded-lg border border-slate-100">
                    <div className="">
                        <div className="flex items-center justify-between px-8 py-5 border-b border-slate-100">
                            <div
                                className="flex items-center space-x-2 w-fit cursor-pointer"
                                onClick={() => navigate(-1)}
                            >
                                <i className="ri-arrow-left-line text-[#042821]"></i>
                                <p className="text-sm underline font-rocGroteskMedium">Back</p>
                            </div>
                            <div className="flex items-center gap-3">
                                <Button
                                    btnText="Delete"
                                    onClick={() => setDeleteWarehouseShow(true)}
                                    btnClassname="!px-4 !py-2.5 border !border-slate-300 !bg-tradeally-neutral-20 !w-fit"
                                    icon={<i className="ri-delete-bin-5-line text-[#142837]"></i>}
                                />
                                <Button
                                    btnText="Edit details"
                                    btnClassname="!px-4 !py-2.5 border !border-slate-300 !bg-tradeally-neutral-20 !w-fit"
                                    onClick={() =>
                                        navigate(`/dashboard/inventory/edit-warehouse/${id}`)
                                    }
                                    icon={<i className="ri-edit-2-line text-[#142837]"></i>}
                                />

                                {/* <Button
                                    btnText="Add products"
                                    btnClassname="!px-4 !py-2.5 !w-fit"
                                    onClick={() =>
                                        navigate(
                                            `/dashboard/inventory/edit-warehouse/${warehouseDetails?._id}`
                                        )
                                    }
                                    icon={<i className="ri-add-line text-[#142837]"></i>}
                                /> */}
                            </div>
                        </div>
                        <div className="w-full py-5 ">
                            <div className="mb-3 space-y-6 w-full">
                                <div className="w-full px-8 flex space-x-3 border-b pb-5 mb-4">
                                    <div className="flex items-center space-x-4">
                                        <div className="w-[45px] h-[45px] rounded bg-[#FFF1F2] flex items-center justify-center">
                                            <i className="ri-store-2-line text-[#E11D48] text-[25px]"></i>
                                        </div>
                                    </div>
                                    <div className="flex space-x-2 items-start">
                                        <div>
                                            <p className="text-[20px] leading-[30px] font-rocGroteskMedium text-[#333333]">
                                                {warehouseDetails?.warehouseName}
                                            </p>
                                            <p className="text-sm text-[#64748B] font-rocGroteskMedium">
                                                {warehouseDetails?.address?.address}
                                            </p>
                                        </div>
                                        {stats?.warehouseInfo?.incidentType && (
                                            <div className="border border-[#FF3D1D] py-[2px] px-2 rounded-[3px]">
                                                <p className="text-[12px] font-rocGroteskMedium text-[#FF3D1D]">
                                                    {stats?.warehouseInfo?.incidentType
                                                        .charAt(0)
                                                        .toUpperCase() +
                                                        stats?.warehouseInfo?.incidentType.slice(
                                                            1
                                                        )}{" "}
                                                    detected
                                                </p>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className=" px-8 flex  ">
                                    <div className=" w-[300px] space-y-2">
                                        <p className="text-sm font-rocGroteskMedium">
                                            Contact person’s full name
                                        </p>
                                        <p className="text-sm font-rocGroteskMedium text-slate-500">
                                            {warehouseDetails?.contactPersonName}
                                        </p>
                                    </div>
                                    <div className="space-y-2">
                                        <p className="text-sm font-rocGroteskMedium">
                                            Contact person’s email address
                                        </p>
                                        <p className="text-sm font-rocGroteskMedium text-slate-500">
                                            {warehouseDetails?.contactPersonEmail}
                                        </p>
                                    </div>
                                </div>

                                <div className="w-full px-8  ">
                                    <p className="text-sm font-rocGroteskMedium">Notes</p>
                                    <p className="text-sm font-rocGroteskMedium text-slate-500">
                                        {warehouseDetails?.additionalNotes}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="px-8 flex w-full space-x-3">
                        <div className="border w-full border-[#E2E8F0] font-rocGroteskMedium shadow-scrollShadow-1 rounded-[6px] p-6">
                            <p className="text-base text-slate-500 ">Total products</p>
                            <p className="text-[22px] leading-[32px] text-[#042821]">
                                {formatMoney().format((stats?.totalProducts || 0)?.toFixed(0))}
                            </p>
                        </div>
                        <div className="border w-full border-[#E2E8F0] font-rocGroteskMedium shadow-scrollShadow-1 rounded-[6px] p-6">
                            <p className="text-base text-slate-500 ">Total due replenishments</p>
                            <p className="text-[22px] leading-[32px] text-[#042821]">
                                {formatMoney().format((stats?.totalReplenishment || 0)?.toFixed(0))}
                            </p>
                        </div>
                        <div className="border w-full border-[#E2E8F0] font-rocGroteskMedium shadow-scrollShadow-1 rounded-[6px] p-6">
                            <p className="text-base text-slate-500 ">Total sales</p>
                            <p className="text-[22px] leading-[32px] text-[#042821]">
                                ${formatMoney().format((stats?.totalSales || 0)?.toFixed(0))}
                            </p>
                        </div>
                        <div className="border w-full border-[#E2E8F0] font-rocGroteskMedium shadow-scrollShadow-1 rounded-[6px] p-6">
                            <p className="text-base text-slate-500 ">Demand forecast</p>
                            <p className="text-[22px] leading-[32px] text-[#042821]">
                                {formatMoney().format((stats?.demandForecast || 0)?.toFixed(0))}
                            </p>
                        </div>
                    </div>
                    <div className="px-8 mt-6">
                        <div className="border  rounded-tr-[6px] rounded-tl-[6px] pt-3">
                            <p className="text-base px-3 border-b pb-2 font-rocGroteskMedium text-gm-50">
                                Products in this warehouse
                            </p>
                            <div className="flex mt-2 px-3 items-center gap-y-2.5 gap-x-0 lg:gap-2.5 flex-wrap lg:flex-nowrap">
                                <div className="w-full ">
                                    <TextInput
                                        name={"search"}
                                        type={"text"}
                                        value={filters.search}
                                        inputPlaceholder={"Search product"}
                                        inputClassName={"!h-[32px] pl-[0px] !bg-slate-50 text-sm "}
                                        containerClassname={""}
                                        leftIcon={
                                            <i className="ri-search-line before:content-['\f0d1] text-slate-400 mt-[2px]"></i>
                                        }
                                        inputContainerClassname={
                                            "!rounded-[4px] !h-[40px]  !pl-[40px] !bg-slate-50 shadow-boxShadow-2 !border !border-n-20"
                                        }
                                        onChange={(e) => {
                                            setFilters({ ...filters, search: e.target.value });
                                        }}
                                        onInput={debounce((e) => {
                                            setDebouncedSearch(e.target.value);
                                        }, 800)}
                                    />
                                </div>
                                <Dropdown
                                    handleChange={(name, value) => {
                                        setFilters({ ...filters, channel: value });
                                    }}
                                    dropdown={
                                        <div className="flex items-center justify-between lg:rounded-[6px] lg:shadow-buttonShadow2 py-[6px] px-[10px] gap-2 !border !border-slate-100">
                                            <div
                                                className={`text-g-75 whitespace-nowrap font-rocGroteskMedium text-[13px] inline-block ${
                                                    filters?.channel && "capitalize"
                                                }`}
                                            >
                                                {truncate(filters?.channel, {
                                                    length: 15,
                                                }) || "All channels"}
                                            </div>
                                            <i className="ri-arrow-down-s-line text-lg text-g-75"></i>
                                        </div>
                                    }
                                    dropdownItemsClasses="!px-0 !py-0"
                                    dropdownClassName="!mb-10 !-mt-7"
                                    dropdownContainerClasses={`shadow-cardShadow-4 border border-slate-100 right-0 rounded-[6px] !max-h-[200px] !w-[190px] !min-w-min
                            `}
                                    name="valueRange"
                                    dropdownOptions={[
                                        {
                                            label: (
                                                <div
                                                    className="text-slate-600 font-rocGroteskMedium text-[13px] flex items-center gap-2
                                                border-b border-b-slate-100 !w-[190px] px-4 py-[10px]"
                                                >
                                                    <i
                                                        className={`${
                                                            filters?.channel === ""
                                                                ? "ri-checkbox-line text-carribean-green"
                                                                : "ri-checkbox-blank-line text-slate-200"
                                                        }    text-xl`}
                                                    ></i>
                                                    All channels
                                                </div>
                                            ),
                                            value: "",
                                        },
                                        ...salesChannel?.map((item, idx) => ({
                                            label: (
                                                <div
                                                    key={idx}
                                                    className=" text-slate-600 font-rocGroteskMedium text-[13px] flex items-center gap-2
                                                border-b border-b-slate-100 !w-[190px] px-4 py-[10px] capitalize"
                                                >
                                                    <i
                                                        className={`${
                                                            filters?.channel === item
                                                                ? "ri-checkbox-line text-carribean-green"
                                                                : "ri-checkbox-blank-line text-slate-200"
                                                        }    text-xl`}
                                                    ></i>
                                                    {item}
                                                </div>
                                            ),
                                            value: item,
                                        })),
                                    ]}
                                />

                                <Dropdown
                                    handleChange={(name, value) => {
                                        setFilters({ ...filters, duration: value });
                                    }}
                                    dropdown={
                                        <div className="flex items-center justify-between lg:rounded-[6px] lg:shadow-buttonShadow2 py-[6px] px-[10px] gap-2 !border !border-slate-100">
                                            <i className="ri-arrow-down-s-line text-lg text-[#142837]"></i>

                                            <div
                                                className={`text-g-75 font-rocGroteskMedium whitespace-nowrap text-[13px] inline-block`}
                                            >
                                                {filters?.duration?.split("-")?.[1] || "All Times"}
                                            </div>
                                        </div>
                                    }
                                    dropdownItemsClasses="!px-0 !py-0"
                                    dropdownClassName="!w-full !mb-10 !-mt-7"
                                    dropdownContainerClasses={`shadow-cardShadow-4 border border-slate-100 right-0 rounded-[6px] !max-h-fit !w-auto`}
                                    name="valueRange"
                                    dropdownOptions={[
                                        ...dateValues2?.map((item, idx) => ({
                                            label: (
                                                <div
                                                    key={idx}
                                                    className=" text-g-75 font-rocGroteskMedium text-[13px] flex justify-between
                                                border-b border-b-slate-100 !w-[120px] px-4 py-[10px]"
                                                >
                                                    {item?.period}
                                                </div>
                                            ),
                                            value: item?.value + "-" + item?.period,
                                        })),
                                    ]}
                                />
                            </div>
                            {fetchingSingleWarehouseList ? (
                                <CustomTableLoader tableHeader={tableHeader} />
                            ) : (
                                <div
                                    className={`${
                                        warehouseDetails?.products?.length > 0 &&
                                        "overflow-scroll max-h-[calc(100vh-550px)] "
                                    } w-full mt-2`}
                                    id="custom-table"
                                >
                                    {warehouseDetails?.products?.length > 0 ? (
                                        <>
                                            <CustomTable
                                                tableBody={tableBody}
                                                tableHeader={tableHeader}
                                                stickyHeader={true}
                                                isAllSelectable={false}
                                                isScrollable={false}
                                                isCellBordered={true}
                                                headerContainerClass="!bg-slate-50  !border-slate-100 "
                                                bodyItemClass="hover:border-slate-100 !border-b hover:bg-slate-50 "
                                                headerItemClass="font-rocGroteskMedium !text-slate-700"
                                            />

                                            {loadMoreWarehouse && fetchingListStockWarehouse && (
                                                <div className="flex my-4 justify-center">
                                                    <Loader color="gm-25" size={4} />
                                                </div>
                                            )}
                                            {warehousePagination?.current ===
                                                warehousePagination?.number_of_pages && (
                                                <div className="flex my-4 text-slate-500  justify-center text-sm font-rocGroteskMedium">
                                                    End of list
                                                </div>
                                            )}
                                        </>
                                    ) : (
                                        <div className="flex items-center justify-center mt-[76px]">
                                            <div>
                                                <img
                                                    src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1704890969/Tradeally_2.0/empty_state_img_a8twwl.svg"
                                                    alt="empty state"
                                                    className="w-[268px] h-[235px]"
                                                />
                                                <p className="text-center text-sm text-[#475569] font-rocGroteskMedium mt-5">
                                                    You have no product
                                                </p>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            <ModalContainer
                open={deleteWarehouseShow}
                showCloseIcon={false}
                modalClassName={"py-10 px-10"}
                tailwindClassName="w-[600px]"
                closeModal={() => {
                    setDeleteWarehouseShow(false);
                }}
            >
                <div className="bg-white py-7 px-6  shadow-cardShadow relative rounded-[8px]">
                    <div className="border-[2px] h-[50px] bg-[#FEF2F2] w-[50px] flex items-center justify-center rounded-full border-[#DC2626]">
                        <i className="ri-delete-bin-line text-[#DC2626] text-[26px]"></i>
                    </div>
                    <div className="mb-5 mt-4">
                        <h1 className="text-lg mb-2  font-rocGroteskMedium">Delete warehouse?</h1>
                        <p className="text-slate-500 font-rocGroteskMedium text-base">
                            Are you sure you want to delete this warehouse? Please make sure all
                            products are transferred first before proceeding.
                        </p>
                    </div>
                    <div className="flex justify-end items-center ">
                        <div className="flex items-center !whitespace-nowrap space-x-2 ">
                            <Button
                                btnText={"No, cancel"}
                                type={"button"}
                                btnClassname={"!bg-n-20 !w-fit !text-gm-50"}
                                disabled={deleteWarehouse}
                                onClick={() => setDeleteWarehouseShow(false)}
                            />
                            <Button
                                btnClassname={"!py-3 !w-fit"}
                                onClick={deleteProductWarehouse}
                                btnText={"Delete warehouse"}
                                type={"button"}
                                isLoading={deleteWarehouse}
                                disabled={deleteWarehouse}
                            />
                        </div>
                    </div>
                </div>
            </ModalContainer>
        </PageFrame>
    );
};

export default WarehouseDetails;
