import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../storeHooks";
import { debounce, uniqBy } from "lodash";
import useInfiniteScroll from "../useInfinteScroll";
import { dashboardActions } from "redux/Ldb/actions";

const useGetDashboardLeastPerforming = (fetch?: boolean) => {
    const dispatch = useAppDispatch();
    const limit = 10;
    const [debouncedSearch, setDebouncedSearch] = useState<string>("");
    const [LeastPerforming, setLeastPerforming] = useState<{ [key: string]: any }[]>([]);
    const [sortValues, setSortValues] = useState<any>("");
    const [dateRange, setDateRange] = useState<string>("");
    const [warehouseId, setWarehouseId] = useState<string>("");
    const [inventoryId, setInventoryId] = useState<string>("");
    const [channel, setChannel] = useState<string>("");

    const [pagination, setPagination] = useState<{ currentPage: string; noOfPages: string }>({
        currentPage: "1",
        noOfPages: "",
    });

    const {
        fetchingDashboardLeastPerforming,
        fetchedDashboardLeastPerformingSuccess,
        fetchedDashboardLeastPerformingFailure,
    } = useAppSelector((state) => state.dashboard);

    const handleDebouncedChange = debounce((e) => {
        setDebouncedSearch(e?.target?.value ?? "");
    }, 500);

    useEffect(() => {
        if (Boolean(fetchedDashboardLeastPerformingSuccess)) {
            setLeastPerforming((prev) => [...fetchedDashboardLeastPerformingSuccess?.products]);
            setPagination({
                currentPage: fetchedDashboardLeastPerformingSuccess.pagination?.current,
                noOfPages: fetchedDashboardLeastPerformingSuccess.pagination?.number_of_pages,
            });
            // setLoadMore(false);
        }
    }, [fetchedDashboardLeastPerformingSuccess]);

    useEffect(() => {
        if (fetch) {
            dispatch(
                dashboardActions.getLeastPerforming(
                    10,
                    1,
                    inventoryId,
                    warehouseId,
                    debouncedSearch,
                    channel,
                    dateRange,
                    sortValues
                )
            );
        }
    }, [
        dispatch,
        fetch,
        // updatedLimit,
        inventoryId,
        warehouseId,
        debouncedSearch,
        channel,
        dateRange,
        sortValues,
    ]);

    return {
        data: LeastPerforming,
        isFetching: fetchingDashboardLeastPerforming,
        error: fetchedDashboardLeastPerformingFailure,
        // isLoadingMore,
        handleDebouncedChange,
        setDateRange,
        pagination,
        setPagination,
        setSortValues,
        setWarehouseId,
        setInventoryId,
        setDebouncedSearch,
        setChannel,
    };
};

export default useGetDashboardLeastPerforming;
