import React, { Dispatch, SetStateAction } from "react";
import SelectInput from "./InputField/SelectInput";
import MultiselectInput from "./InputField/MultiselectInput";
import Button from "./Button";
import TextInput from "./InputField/TextInput";
import { vendorCategories } from "../../variables/vendorCategories";

type AddVendorFormProps = {
    onSubmit?: (e: React.FormEvent<HTMLFormElement>) => void;
    email?: string;
    title?: React.ReactNode | string;
    vendorCategory: string;
    onChangeCategory?: (value: string) => void;
    onChangeDocs?: Dispatch<SetStateAction<any[]>>;
    onChangeEmail?: (value: string) => void;
    isSending?: boolean;
};

const AddVendorForm = ({
    onSubmit,
    email,
    title,
    vendorCategory,
    onChangeCategory,
    onChangeDocs,
    onChangeEmail,
    isSending,
}: AddVendorFormProps) => {
    const categoryDocs = vendorCategories?.find(
        (cat) => cat.category === vendorCategory
    )?.documents;

    return (
        <div className="bg-white shadow-cardShadow relative rounded-[20px]">
            <div className="p-10 max-lg:px-5 flex flex-col items-center ">
                {title ? (
                    title
                ) : (
                    <div className="text-center w-full mb-[46px]">
                        <>
                            <p className="mb-2.5 text-2xl font-rocGroteskMedium text-g-75">
                                Add Vendors
                            </p>
                            <p className="text-sm font-rocGroteskMedium text-gm-40">
                                Add new and existing to your vendor list to help you get started
                            </p>
                        </>
                    </div>
                )}
                <form className="w-full" onSubmit={onSubmit}>
                    <div className="mb-10 w-full">
                        <TextInput
                            value={email}
                            name={"email"}
                            type={"email"}
                            pattern={"^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$"}
                            title={"Please fill in a valid email address"}
                            onChange={(e) => onChangeEmail?.(e.target.value)}
                            placeholder={"Enter email address"}
                            required={true}
                            containerClassname="mb-3"
                        />

                        <SelectInput
                            value={vendorCategory}
                            name="vendorCategory"
                            placeholder={"Select vendor category"}
                            className="mb-3"
                            handleChange={(_, value) => onChangeCategory?.(value)}
                            isRequired={true}
                            dropdownOptions={vendorCategories.map((item) => ({
                                label: item.category,
                                value: item.category,
                            }))}
                        />
                        <MultiselectInput
                            value={[]}
                            name="requiredDocuments"
                            placeholder={"Select required document"}
                            className=""
                            isRequired={true}
                            handleChange={(name, value, valuesArray) =>
                                onChangeDocs?.(valuesArray as string[])
                            }
                            switchOptions={vendorCategory}
                            dropdownOptions={categoryDocs?.map((item) => ({
                                label: item.title,
                                value: item.title,
                                required: item.required,
                            }))}
                        />
                    </div>

                    <div className="w-full ">
                        <Button btnText={"Send invite"} type={"submit"} isLoading={isSending} />
                    </div>
                </form>
            </div>
        </div>
    );
};

export default AddVendorForm;
