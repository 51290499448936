export const downloadUrl = (url: string, name: string) => {
    fetch(url)
        .then((resp) => resp.blob())
        .then((blobobject) => {
            const blob = window.URL.createObjectURL(blobobject);
            const anchor = document.createElement("a");
            anchor.style.display = "none";
            anchor.href = blob;
            anchor.download = name;
            document.body.appendChild(anchor);
            anchor.click();
            window.URL.revokeObjectURL(blob);
        })
        .catch((error) => console.error(error));
};

export const fbDownload = (url: string, name: string) => {
    const anchor = document.createElement("a");
    anchor.style.display = "none";
    anchor.href = url;
    anchor.download = name;
    anchor.target = "_blank";
    anchor.rel = "noreferrer";
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
};
