import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import { chatActions } from "redux/Ldb/actions";
import useInfiniteScroll from "hooks/useInfinteScroll";

const useGetAllAiConversations = (scrollContainer) => {
    const dispatch = useAppDispatch();
    const [conversationData, setConversationData] = useState<{ [key: string]: any }>([]);
    const [pagination, setPagination] = useState<{ [key: string]: any }>([]);
    const {
        fetchingAllConversations,
        fetchAllConversationsSuccess,
        fetchAllConversationsFailure,
        createConversationSuccess,
    } = useAppSelector((state) => state.chat);

    const { updatedLimit, isLoadingMore, setLoadMore } = useInfiniteScroll(
        { current: pagination.currentPage, number_of_pages: pagination.noOfPages },
        15,
        scrollContainer
    );

    useEffect(() => {
        if (Boolean(fetchAllConversationsSuccess)) {
            setConversationData(fetchAllConversationsSuccess?.data);
            setPagination({
                currentPage: fetchAllConversationsSuccess.pagination?.current,
                noOfPages: fetchAllConversationsSuccess.pagination?.number_of_pages,
            });
            setLoadMore(false);
        }
    }, [fetchAllConversationsSuccess]);

    useEffect(() => {
        dispatch(chatActions.getAllConversations(updatedLimit));
    }, [dispatch, updatedLimit, createConversationSuccess]);

    return {
        data: conversationData,
        isFetching: fetchingAllConversations,
        error: fetchAllConversationsFailure,
        isLoadingMore,
    };
};

export default useGetAllAiConversations;
