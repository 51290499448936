import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../storeHooks";
import { workforceActions } from "../../redux/Ldb/actions/workforceActions";

const useGetAiAgents = () => {
    const dispatch = useAppDispatch();

    const { fetchingAiAgents, fetchAiAgentSuccess, fetchAiAgentFailure } = useAppSelector(
        (state) => state.workforce
    );

    const [aiAgents, setAiAgents] = useState<{ [key: string]: any }[]>([]);

    useEffect(() => {
        dispatch(workforceActions.getWorkforceActions());
    }, [dispatch]);

    useEffect(() => {
        if (Boolean(fetchAiAgentSuccess)) {
            setAiAgents(fetchAiAgentSuccess?.agents);
        }
    }, [fetchAiAgentSuccess]);

    return {
        isFetching: fetchingAiAgents,
        error: fetchAiAgentFailure,
        data: aiAgents,
        setAiAgents,
    };
};

export default useGetAiAgents;
