import { useEffect, useState } from "react";
import { inventoryActions } from "redux/Ldb/actions";
import { useAppDispatch, useAppSelector } from "../storeHooks";
import { useParams } from "react-router-dom";

const useGetReplishmentStockList = () => {
    const dispatch = useAppDispatch();
    const { alertId } = useParams();

    const {
        fetchingReplishmentStockList,
        fetchedReplishmentStockListSuccess,
        fetchedReplishmentStockListFailure,
    } = useAppSelector((state) => state.inventory);
    const [replishmentStock, setReplishmentStock] = useState<{ [key: string]: any }>([]);
    const [refetch, setRefetch] = useState<boolean>(false);
    const [incidentId, setIncidentId] = useState<string>("");

    const reFetchData = () => {
        setRefetch(!refetch);
    };
    const handleIncidentId = (id: string) => {
        setIncidentId(id);
    };
    useEffect(() => {
        if (incidentId) {
            dispatch(inventoryActions.fetchReplishmentStockList(incidentId));
        }
    }, [dispatch, refetch, incidentId]);

    useEffect(() => {
        if (alertId) {
            dispatch(inventoryActions.fetchReplishmentStockList(alertId));
        }
    }, [dispatch, refetch, alertId]);

    useEffect(() => {
        if (Boolean(fetchedReplishmentStockListSuccess)) {
            setReplishmentStock(fetchedReplishmentStockListSuccess?.stocks);
        }
    }, [fetchedReplishmentStockListSuccess]);

    return {
        isFetching: fetchingReplishmentStockList,
        error: fetchedReplishmentStockListFailure,
        data: replishmentStock,
        reFetchData,
        handleIncidentId,
    };
};

export default useGetReplishmentStockList;
