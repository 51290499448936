import Button from "components/common/Button";
import CustomTable from "components/common/CustomTable";
import CustomTableLoader from "components/common/CustomTableLoader";
import CustomToast from "components/common/CustomToast";
import Checkbox from "components/common/InputField/Checkbox";
import SelectInput from "components/common/InputField/SelectInput";
import TextInput from "components/common/InputField/TextInput";
import TextareaInput from "components/common/InputField/TextareaInput";
import Loader from "components/common/Loader";
import ModalContainer from "components/common/ModalContainer";
import VendorAvatar from "components/common/VendorAvatar";
import PageFrame from "components/layout/PageFrame";
import { formatMoney, getCurrencyFromCurrencyCode } from "helpers";
import useGetBundleDetails from "hooks/inventoryHooks/useGetBundleDetails";
import useGetProductList from "hooks/inventoryHooks/useGetProductList";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import { cloneDeep } from "lodash";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import ResponsivePaginationComponent from "react-responsive-pagination";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { inventoryActions } from "redux/Ldb/actions";

const AddBundleProduct = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [products, setProducts] = useState([]);
    const [addProduct, setAddProduct] = useState<boolean>(false);
    const [selectProduct, setSelectProduct] = useState<{ [key: string]: any }[]>([]);
    const [clickCount, setClickCount] = useState({});
    const [bundleData, setBundleData] = useState<any>({});
    const [totalPrice, setTotalPrice] = useState<number>(0);
    const [totalStock, setTotalStock] = useState<number>(0);
    const [totalQuantity, setTotalQuantity] = useState<number>(0);
    const [totalUnitCost, setTotalUnitCost] = useState<number>(0);
    const [percentage, setPercentage] = useState<number>(0);

    const {
        rawData,
        data: productData,
        handleDebouncedChange: producthandleDebouncedChange,
        handleSearch: producthandleSearch,
        isFetching: productListLoading,
        search: productListSearch,
        setSearch: setproductListSearch,
        setDebouncedSearch: setproductDebouncedSearch,
        pagination,
        isLoadingMore,
        setUpdatedPage,
    } = useGetProductList();

    const { bundleId } = useParams();
    const [params] = useSearchParams();
    const inventoryId = params.get("inventory");
    const { data } = useGetBundleDetails();
    const {
        creatingSingleBundle,
        createdSingleBundleSuccess,
        creatingSingleBundleFromProduct,
        createSingleBundleFromProductSuccess,
        updatingSingleBundle,
        updateSingleBundleSuccess,
        fetchingSingleInventory,
        fetchedSingleInventorySuccess,
    } = useAppSelector((state) => state.inventory);

    const handleSelectAllProducts = (deselect = false) => {
        const selectedProducts = deselect ? [] : productData;
        setSelectProduct(selectedProducts);
    };

    const handleChange = (
        e:
            | React.ChangeEvent<HTMLInputElement>
            | { target: { name: string; value: string; checked?: boolean } }
    ) => {
        const { name, value, checked, type } = e.target as HTMLInputElement;

        setBundleData({
            ...bundleData,
            [name]: type === "checkbox" ? checked : value,
        });
    };

    const tableHeader = [
        { title: "Product name", widthClass: "w-[40%]" },
        { title: "Current stock level", widthClass: "w-[15%]" },
        { title: "Unit price", widthClass: "w-[11.6%]" },
        { title: "Quantity", widthClass: "w-[16.5%]" },
        { title: "", widthClass: "w-[4.5%]" },
    ];

    useEffect(() => {
        if (Boolean(data?.bundleName) && bundleId) {
            const parsedSelectedProduct = data?.bundleItems.map((item) => ({
                ...item.inventory,
                quantity: item.quantity,
                inventoryId: item.inventoryId,
            }));
            setSelectProduct(parsedSelectedProduct);
            setBundleData({
                productName: data?.bundleName,
                sku: data?.sku,
                bundleValue: data?.bundleValue?.amount,
                notes: data?.productDescription,
                receivingProduct: data?.notifyOnPercentDiscount,
            });
        }
    }, [data?.bundleName, bundleId]);

    useEffect(() => {
        const calculateTotalUnitCost = (selectProduct) => {
            return selectProduct
                .reduce((total, item) => {
                    const unitCost = Number(item?.unitCost?.amount) * Number(item?.quantity || 1);
                    return total + unitCost;
                }, 0)
                ?.toFixed(0);
        };
        const calculateTotalPrice = (selectProduct) => {
            return selectProduct.reduce((total, item) => {
                const unitCost = Number(item?.unitCost?.amount);
                return total + unitCost;
            }, 0);
        };
        const calculateTotalStock = (selectProduct) => {
            return selectProduct.reduce((total, item) => {
                const stockLevel = item?.productStockDetails?.stockLevel;
                return total + stockLevel;
            }, 0);
        };
        const calculateTotalQuantity = (selectProduct) => {
            return selectProduct.reduce((total, item) => {
                const quantity = Number(item?.quantity);
                return total + quantity;
            }, 0);
        };
        setTotalQuantity(calculateTotalQuantity(selectProduct));
        setTotalUnitCost(calculateTotalPrice(selectProduct));
        setTotalStock(calculateTotalStock(selectProduct));
        setTotalPrice(calculateTotalUnitCost(selectProduct));
    }, [selectProduct]);

    const tableBody = selectProduct?.map((item, idx) => {
        const handleDeleteProduct = (idx) => {
            const updatedProducts = selectProduct.filter((item, index) => index !== idx);
            setSelectProduct(updatedProducts);
        };

        const handleQuantityChange = (idx, newQuantity) => {
            const updatedProducts = selectProduct.map((product, index) => {
                if (index === idx) {
                    return { ...product, quantity: newQuantity };
                }
                return product;
            });
            setSelectProduct(updatedProducts);
        };
        return [
            {
                content: (
                    <div
                        key={idx}
                        className={`w-full    border-slate-100 text-sm font-rocGroteskMedium text-slate-900 `}
                    >
                        <div className="font-rocGroteskMedium pl-3  items-center flex space-x-2">
                            {item?.productImageDetails?.productImages?.[0] ? (
                                <VendorAvatar
                                    imageSrc={
                                        item?.productImageDetails?.productAvatar ||
                                        item?.productImageDetails?.productImages?.[0]
                                    }
                                    size={24}
                                    name={item?.productName}
                                    containerClassname="!rounded-md"
                                />
                            ) : (
                                <div className="w-8 h-8 rounded flex items-center justify-center bg-slate-200 ">
                                    <i className="ri-box-2-fill text-lg text-slate-500"></i>
                                </div>
                            )}

                            <div className="text-sm font-rocGroteskMedium">
                                <p className="text-g-75 ">{item?.productName}</p>
                                <p className="text-slate-500 ">SKU-{item?.sku}</p>
                            </div>
                        </div>
                    </div>
                ),
                tableDataDivClass: "!pl-[0]",
            },

            {
                content: (
                    <div className={`w-full  border-slate-100 text-sm text-g-75 `}>
                        <p>{formatMoney().format(item?.productStockDetails?.stockLevel)} </p>
                    </div>
                ),
                tableDataDivClass: "!pl-3",
            },
            {
                content: (
                    <div className={`w-full  border-slate-100 text-sm text-g-75 `}>
                        <p>
                            {getCurrencyFromCurrencyCode(item?.unitCost?.currency)}{" "}
                            {formatMoney().format(item?.unitCost?.amount)}
                        </p>
                    </div>
                ),
                tableDataDivClass: "!pl-3",
            },
            {
                content: (
                    <div className={`w-full border-slate-100 py-2 text-sm text-g-75 `}>
                        <TextInput
                            value={item?.quantity || ""}
                            name={"quantity"}
                            type={"number"}
                            onWheel={(e: any) => e.target.blur()}
                            onKeyDown={(evt) =>
                                ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) &&
                                evt.preventDefault()
                            }
                            required={true}
                            onChange={(e) => handleQuantityChange(idx, e.target.value)}
                            inputPlaceholder="0"
                            containerClassname=""
                            inputContainerClassname={"!border !rounded !h-[36px]   "}
                            inputClassName="w-full disabled:!bg-white"
                        />
                    </div>
                ),
                tableDataDivClass: "!pl-3",
            },
            {
                content: (
                    <div
                        className={`w-full border-slate-100 text-sm text-slate-700 relative`}
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                    >
                        <i
                            className="ri-delete-bin-line text-slate-500 text-[16px]"
                            onClick={() => handleDeleteProduct(idx)}
                        ></i>
                    </div>
                ),
                tableDataDivClass: "!pl-3",
            },
        ];
    });

    const handleSubmit = (e) => {
        e.preventDefault();
        if (selectProduct.length === 0) {
            toast.custom((t) => (
                <CustomToast
                    t={t}
                    message={"Please select at least one product to create Bundle"}
                    type={"error"}
                />
            ));
        }

        const body: any = {
            bundleName: bundleData.productName,
            productDescription: bundleData.notes,
            salesPrice: {
                amount: Number(bundleData.bundleValue),
                currency: "USD",
            },
            bundlePercentDiscount: 10,
            bundleValue: {
                amount: Number(totalPrice),
                currency: "USD",
            },
            notifyOnPercentDiscount: bundleData.receivingProduct === true ? true : false,
            bundleItems: selectProduct.map((item) => ({
                inventoryId: item?._id,
                quantity: item.quantity,
            })),
        };

        if (selectProduct.length > 0) {
            if (!bundleId && !inventoryId) {
                body.sku = bundleData.sku;
                dispatch(inventoryActions.createSingleBundle(body));
            } else if (inventoryId) {
                console.log("herree");
                body.sku = bundleData.sku;
                dispatch(inventoryActions.createSingleBundleFromProduct(body, inventoryId));
            } else {
                console.log("herree ---");

                dispatch(inventoryActions.updateSingleBundle(body, bundleId));
            }
        }
    };

    useEffect(() => {
        if (createdSingleBundleSuccess) {
            setSelectProduct([]);
            setBundleData({});
            dispatch(inventoryActions.resetCreateSingleBundle());
            toast.custom((t) => <CustomToast t={t} message={"Bundle created"} type={"success"} />);
            navigate("/dashboard/inventory?currProductType=1");
        }
    }, [createdSingleBundleSuccess]);

    useEffect(() => {
        if (Boolean(createSingleBundleFromProductSuccess)) {
            setSelectProduct([]);
            setBundleData({});
            dispatch(inventoryActions.resetCreateSingleBundleFromProductSuccess());
            toast.custom((t) => <CustomToast t={t} message={"Bundle created"} type={"success"} />);
            navigate("/dashboard/inventory?currProductType=1");
        }
    }, [createSingleBundleFromProductSuccess]);

    useEffect(() => {
        if (Boolean(updateSingleBundleSuccess)) {
            setSelectProduct([]);
            setBundleData({});
            dispatch(inventoryActions.resetUpdateSingleBundle());
            toast.custom((t) => <CustomToast t={t} message={"Bundle Updated"} type={"success"} />);
            dispatch(inventoryActions.resetUpdateSingleBundle());
            navigate(
                bundleId
                    ? `/dashboard/inventory/bundle/${bundleId}`
                    : "/dashboard/inventory?currProductType=1"
            );
        }
    }, [dispatch, updateSingleBundleSuccess, bundleId]);

    useEffect(() => {
        if (Number(bundleData.bundleValue) < Number(totalPrice)) {
            const percentage = (Number(totalPrice) - bundleData.bundleValue) / 100;
            setBundleData((prev) => ({ ...prev, discount: percentage?.toFixed(0) }));
        }
    }, [bundleData.bundleValue, totalPrice]);

    useEffect(() => {
        if (Boolean(inventoryId)) {
            dispatch(inventoryActions.fetchSingleInventory(inventoryId));
        }
    }, [inventoryId]);

    useEffect(() => {
        if (Boolean(inventoryId) && Boolean(fetchedSingleInventorySuccess)) {
            const productDatacopy = cloneDeep({ ...fetchedSingleInventorySuccess?.inventory });

            setBundleData((prev) => {
                return {
                    ...prev,
                    productName: productDatacopy?.productName,
                    sku: productDatacopy?.sku,
                    bundleValue: productDatacopy?.salesPrice?.amount?.toFixed(2),
                };
            });
        }
    }, [inventoryId, fetchedSingleInventorySuccess]);

    const handleCheckboxClick = (itemSelected) => {
        const clickCountCopy = { ...clickCount };

        if (!clickCountCopy[itemSelected?._id]) {
            clickCountCopy[itemSelected?._id] = 1;
        } else {
            clickCountCopy[itemSelected?._id] += 1;
        }

        setClickCount(clickCountCopy);
        const check = selectProduct.some((product) => product._id === itemSelected?._id);

        const updatedSelectProduct = check
            ? selectProduct.filter((product) => product?._id !== itemSelected?._id)
            : [...selectProduct, itemSelected];

        setSelectProduct(updatedSelectProduct);
    };

    const tableProductHeader = [
        {
            title: "Product name",
            widthClass: "w-[40%] !px-3 !border-none",
            onSelectAll: () => {
                handleSelectAllProducts(selectProduct?.length > 0);
            },
            isAllChecked: selectProduct?.length === productData?.length,
        },
        { title: "Current stock level", widthClass: "w-[20%] !border-none" },
        { title: "Unit price", widthClass: "w-[20%] !border-none" },
    ];

    const tableProductBody = rawData?.map((item, idx) => {
        const isChecked = selectProduct.some((product) => product._id === item._id);

        return [
            {
                content: (
                    <div
                        key={idx}
                        className={`w-full py-2 border-slate-100 text-sm font-rocGroteskMedium text-slate-900 `}
                    >
                        <div className="font-rocGroteskMedium   items-center flex space-x-3">
                            <VendorAvatar
                                imageSrc={item?.productImageDetails?.productAvatar}
                                size={32}
                                containerClassname="!rounded-[4px]"
                                name={item?.productName}
                            />

                            <div className="text-sm font-rocGroteskMedium">
                                <p className="text-g-75 ">{item?.productName}</p>
                                <p className="text-slate-500 ">SKU-{item?.sku}</p>
                            </div>
                        </div>
                    </div>
                ),
                isItemChecked: isChecked,
                onCheckBoxClick: () => {
                    handleCheckboxClick(item);
                },
                tableDataDivClass: "!pl-3",
                widthClass: "!border-none",
            },
            {
                content: (
                    <div
                        className={`w-full font-rocGroteskMedium  border-slate-100 text-sm text-g-75 py-2`}
                    >
                        <p>
                            {formatMoney().format(item?.productStockDetails?.stockLevel)}{" "}
                            {item.productStockDetails?.unitOfMeasurement}
                        </p>
                    </div>
                ),
                tableDataDivClass: "!pl-3",
                widthClass: "!border-none",
            },
            {
                content: (
                    <div
                        className={`w-full font-rocGroteskMedium border-slate-100 text-sm text-g-75 py-2`}
                    >
                        <p>
                            {getCurrencyFromCurrencyCode(item?.unitCost?.currency)}{" "}
                            {formatMoney().format(item?.unitCost?.amount)}
                        </p>
                    </div>
                ),
                tableDataDivClass: "!pl-3",
            },
        ];
    });

    return (
        <PageFrame isLoading={fetchingSingleInventory}>
            <div className="bg-white rounded-lg border border-n-20">
                <form action="" onSubmit={handleSubmit}>
                    <div className="flex items-center justify-between px-8 py-5 border-b border-n-20">
                        <div className="flex items-center space-x-1.5">
                            <i
                                onClick={() => navigate(-1)}
                                className="ri-arrow-left-line text-xl mt-[-2px] cursor-pointer max-sm:hidden"
                            ></i>
                            <span className="text-lg font-rocGroteskMedium">
                                {bundleId ? "Edit" : "Create"} product bundle
                            </span>
                        </div>
                        <div className="flex items-center space-x-3">
                            <p className="flex items-center space-x-3">
                                <span className="w-2 h-2 rounded-full bg-slate-300"></span>
                                <span className="text-slate-500 text-sm font-rocGroteskMedium">
                                    Draft saved
                                </span>
                            </p>
                            <Button
                                btnText="Cancel"
                                btnClassname="!px-4 !py-2 !bg-tradeally-neutral-20 !w-fit"
                                disabled={
                                    creatingSingleBundle ||
                                    updatingSingleBundle ||
                                    creatingSingleBundleFromProduct
                                }
                                onClick={() => navigate("/dashboard/inventory?currProductType=1")}
                            />
                            <Button
                                btnText={bundleId ? "Update bundle" : "Create bundle"}
                                type="submit"
                                isLoading={
                                    creatingSingleBundle ||
                                    updatingSingleBundle ||
                                    creatingSingleBundleFromProduct
                                }
                                btnClassname="!px-4 !py-2 !w-fit"
                            />
                        </div>
                    </div>
                    <div className="px-8 mt-8  flex justify-center">
                        <div className="w-[70%]">
                            <div className="mb-6">
                                <div className="flex items-center w-full justify-between mb-4">
                                    <p className="text-sm font-rocGroteskMedium">
                                        Bundle name <span className="text-r-50">*</span>
                                    </p>
                                </div>
                                <div className="border-b-[1px] border-b-[#E2E8F0] pb-4">
                                    <input
                                        type="text"
                                        placeholder="Untitled Bundle"
                                        className="!text-2xl font-rocGroteskMedium  placeholder:text-[#CBD5E1] !border-none outline-none w-full placeholder:pl-1 caret-slate-700 !leading-6"
                                        name="productName"
                                        value={bundleData.productName}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                            </div>
                            <div className="grid gap-3 grid-cols-4 items-center">
                                <div>
                                    <TextInput
                                        value={bundleData.sku}
                                        name={"sku"}
                                        type={"text"}
                                        disabled={Boolean(bundleId)}
                                        onChange={handleChange}
                                        placeholder={"SKU number"}
                                        required={true}
                                        containerClassname=""
                                    />
                                </div>
                                <div className="flex space-x-1 items-center">
                                    <div className="w-[70%]">
                                        <SelectInput
                                            value={"USD"}
                                            name="currency"
                                            handleChange={(name, value) => {
                                                {
                                                }
                                            }}
                                            isRequired={true}
                                            dropdownOptions={[
                                                {
                                                    label: "GBP (£)",
                                                    value: "GBP",
                                                },
                                                {
                                                    label: "USD ($)",
                                                    value: "USD",
                                                },
                                                {
                                                    label: "EUR (€)",
                                                    value: "EUR",
                                                },
                                            ]}
                                        />
                                    </div>
                                    <div className="border-g-60 flex items-center border-[0.5px] border-b-[1px] px-[16px] rounded w-full">
                                        <div
                                            className={`w-full  border-slate-100 text-sm text-slate-700 flex flex-col`}
                                        >
                                            <p className="whitespace-nowrap text-[8px] font-rocGroteskMedium">
                                                Price{" "}
                                            </p>
                                            <div className="flex items-center">
                                                <p className="pb-2">$</p>
                                                <TextInput
                                                    value={bundleData.bundleValue}
                                                    name={"bundleValue"}
                                                    required={true}
                                                    type="number"
                                                    onChange={handleChange}
                                                    // placeholder="Bundle value"
                                                    inputPlaceholder="0"
                                                    containerClassname=""
                                                    inputContainerClassname={
                                                        "!border-none !h-[29px]  !text-[#89939B] !rounded-none !pt-0 !pb-2 "
                                                    }
                                                    inputClassName="w-full !px-0 !h-[30px]"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex space-x-1 items-center">
                                    <div className="w-[65%] bg-[#F2F2F2] border-[#D0D4D7] flex justify-center items-center border-[0.5px] py-3.5 border-b-[1px] px-[16px] rounded">
                                        <div className="!border-none flex font-rocGroteskMedium justify-center text-sm text-center !text-[#89939B] !rounded-none ">
                                            <p>{"USD"}</p>
                                        </div>
                                    </div>
                                    <div className="border-[#D0D4D7] flex items-center bg-[#F2F2F2] border-[0.5px] border-b-[1px] px-[16px] rounded w-full">
                                        <div
                                            className={`w-full  border-slate-100 text-sm text-slate-700 flex flex-col`}
                                        >
                                            <p className="whitespace-nowrap text-[8px] font-rocGroteskMedium">
                                                Bundle value
                                            </p>
                                            <div className="flex items-center">
                                                <p className="pb-2">$</p>
                                                <div className="!border-none !h-[29px] !bg-[#F2F2F2] !text-[#89939B] !rounded-none !pt-0 !pb-2  ">
                                                    <p>{formatMoney().format(totalPrice)}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className={`w-full border-g-60 border-[0.5px] border-b-[1px] px-[16px] rounded  text-sm text-slate-700  flex items-center `}
                                >
                                    <p className="">%</p>
                                    <TextInput
                                        value={bundleData.discount}
                                        name={"Discount"}
                                        required={true}
                                        disabled={true}
                                        onChange={handleChange}
                                        inputPlaceholder="0"
                                        containerClassname="!bg-white"
                                        inputContainerClassname={
                                            "!border-none !bg-white !rounded-none "
                                        }
                                        inputClassName="w-full !px-0 !py-0"
                                    />
                                </div>
                            </div>
                            <div className="flex space-x-3 my-3 items-center ">
                                <Checkbox
                                    name="receivingProduct"
                                    isChecked={bundleData.receivingProduct || false}
                                    onChange={handleChange}
                                />
                                <p className="text-g-75 font-rocGroteskMedium text-sm">
                                    Inform the customer that they are receiving this product with a
                                    % discount.
                                </p>
                            </div>
                            <div className="mt-8">
                                <div className="flex justify-between items-center mb-3">
                                    <p className="text-base font-rocGroteskMedium mb-3 text-gm-50">
                                        Add products to bundle
                                    </p>
                                    {!addProduct && selectProduct.length > 0 && (
                                        <div>
                                            <Button
                                                btnText="Add product"
                                                btnClassname="!px-4 !py-2 !w-fit max-sm:!w-full !h-10 !text-[13px] !whitespace-nowrap"
                                                onClick={() => setAddProduct(true)}
                                            />
                                        </div>
                                    )}
                                </div>
                                {productListLoading && Number(pagination?.currentPage) === 1 ? (
                                    <div className="flex items-center justify-center w-full border-[1.5px] border-dashed rounded py-10">
                                        <Loader size={6} />
                                    </div>
                                ) : (
                                    (selectProduct.length === 0 ||
                                        (selectProduct.length > 0 && addProduct)) && (
                                        <div className="text-center flex space-y-2 flex-col items-center p-8 justify-center border-[1.5px] border-dashed rounded">
                                            <i className="ri-shopping-basket-line text-[32px] text-slate-500"></i>
                                            <div>
                                                <p className="text-sm font-rocGroteskMedium text-gm-50">
                                                    No products found
                                                </p>
                                                <p className="text-sm font-rocGroteskMedium text-slate-500">
                                                    You haven't added any products to your bundle
                                                    yet.
                                                    <br /> Start building your bundle by selecting
                                                    products from your inventory.
                                                </p>
                                            </div>
                                            <div>
                                                <Button
                                                    btnText="Add product"
                                                    btnClassname="!px-4 !py-2 !w-fit max-sm:!w-full !h-10 !text-[13px] !whitespace-nowrap"
                                                    onClick={() => setAddProduct(true)}
                                                />
                                            </div>
                                        </div>
                                    )
                                )}
                                {!addProduct && selectProduct.length > 0 && (
                                    <div className="pb-3">
                                        <CustomTable
                                            tableBody={tableBody}
                                            tableHeader={tableHeader}
                                            isAllSelectable={false}
                                            isCellSelectable={false}
                                            headerContainerClass="!bg-[#F8FAFC] !font-rocGroteskMedium !text-[#334155] !rounded !border-slate-200"
                                            isScrollable={false}
                                            isCellBordered={false}
                                            bodyItemClass={"hover:!bg-[#F8FAFC]"}
                                        />
                                        <div className="flex w-full p-3  border border-t-0 border-[#E2E8F0] rounded-bl bg-[#F8FAFC] rounded-br">
                                            <div className="w-[47%] ml-3">
                                                <p className="text-sm font-rocGroteskMedium text-g-75">
                                                    Totals:{" "}
                                                    {formatMoney().format(selectProduct.length)}{" "}
                                                    product(s)
                                                </p>
                                            </div>
                                            <div className="w-[18%]  ">
                                                <p className="text-sm font-rocGroteskMedium text-g-75">
                                                    {formatMoney().format(totalStock)}
                                                </p>
                                            </div>
                                            <div className="w-[16.6%]">
                                                <p className="text-sm font-rocGroteskMedium text-g-75">
                                                    {getCurrencyFromCurrencyCode("USD")}{" "}
                                                    {formatMoney().format(totalUnitCost)}
                                                </p>
                                            </div>
                                            <div className="w-[19.5%] ">
                                                <p className="text-sm font-rocGroteskMedium text-g-75">
                                                    {formatMoney().format(
                                                        Number(totalQuantity || 0)
                                                    )}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className="mt-5">
                                <label className="text-base font-rocGroteskMedium">
                                    Additional info
                                </label>
                                <div className="mt-4">
                                    <TextareaInput
                                        name={"notes"}
                                        value={bundleData.notes}
                                        onChange={handleChange}
                                        rows={5}
                                        placeholder={"Add note"}
                                        required={false}
                                        inputContainerClassname={`
                                       
                                        "!border-g-60 !border-[0.5px] !border-b-[1px]"
                                    `}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </form>

                <ModalContainer
                    open={addProduct}
                    showCloseIcon={false}
                    closeModal={() => {
                        setAddProduct(false);
                        setSelectProduct([]);
                        setproductListSearch("");
                    }}
                    modalClassName="px-10 py-10"
                    tailwindClassName="max-w-[800px]"
                >
                    <div className="bg-white  rounded-lg max-h-[90vh] overflow-y-scroll">
                        <div className=" px-6 py-4 flex gap-4 items-center justify-between border-b border-b-slate-200">
                            <p className="text-lg text-gm-50 font-rocGroteskMedium">
                                Add products/SKUs
                            </p>
                            <i
                                onClick={() => {
                                    setproductListSearch("");
                                    setSelectProduct([]);
                                    setAddProduct(false);
                                }}
                                className="ri-close-fill cursor-pointer text-gm-50 text-2xl "
                            ></i>
                        </div>
                        <div className="my-8 ">
                            <div className="mx-8">
                                <TextInput
                                    name={"search"}
                                    value={productListSearch}
                                    type={"text"}
                                    inputPlaceholder={"Search  products"}
                                    inputClassName={"!h-[38px] pl-[0px] !bg-white text-sm !mb-0"}
                                    onChange={producthandleSearch}
                                    onInput={producthandleDebouncedChange}
                                    // containerClassname={"!w-[400px] max-sm:!w-full"}
                                    leftIcon={
                                        <i className="ri-search-line before:content-['\f0d1] text-slate-400 mt-[2px]"></i>
                                    }
                                    inputContainerClassname={
                                        "!rounded-[4px] max-sm:!w-full !pl-[40px] !h-[40px] !bg-white shadow-[0_1px_2px_0_rgba(91,104,113,0.15)] !border !border-n-20"
                                    }
                                />
                            </div>

                            <div className="">
                                {productListLoading && Number(pagination?.currentPage) === 1 ? (
                                    <CustomTableLoader tableHeader={tableProductHeader} />
                                ) : (
                                    <div
                                        className="max-h-[550px] mt-5 overflow-y-auto"
                                        id="custom-modal"
                                    >
                                        <CustomTable
                                            tableBody={tableProductBody}
                                            tableHeader={tableProductHeader}
                                            isAllSelectable={true}
                                            isCellBordered={true}
                                            headerItemClass="font-rocGroteskMedium   !text-slate-700 !py-[11px] !text-xs"
                                            headerContainerClass="!bg-slate-50 !border !border-[#E2E8F0] "
                                            isScrollable={false}
                                            // scrollWithin={false}
                                            bodyItemClass="!border-t-none !border-r-none"
                                            tableClass="!border-t-none !border-r-none !border-l-none"
                                        />
                                        {/* {productListLoading && isLoadingMore && (
                                            <div className="flex justify-center my-4">
                                                <Loader color="gm-25" size={4} />
                                            </div>
                                        )}
                                        {pagination?.currentPage === pagination?.noOfPages && (
                                            <div className="flex my-4 justify-center text-slate-500 text-sm font-rocGroteskMedium">
                                                End of list
                                            </div>
                                        )} */}

                                        <div className="mt-5 w-full flex items-center justify-end">
                                            <ResponsivePaginationComponent
                                                current={Number(pagination.currentPage)}
                                                total={Number(pagination.noOfPages)}
                                                maxWidth={320}
                                                onPageChange={(page) => {
                                                    setUpdatedPage(page);
                                                }}
                                            />
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className="flex   px-6 border-t py-3 justify-between items-center">
                            <div className=" ">
                                <p className="text-sm font-rocGroteskMedium text-g-75 ">
                                    {selectProduct.length} products selected
                                </p>
                            </div>
                            <div className="flex  items-center space-x-2 ">
                                <Button
                                    btnClassname={"!py-2 !text-gm-50 !w-fit !px-8"}
                                    onClick={() => setAddProduct(false)}
                                    btnText={"Add product(s)"}
                                    type={"button"}
                                    isLoading={false}
                                />
                            </div>
                        </div>
                    </div>
                </ModalContainer>
            </div>
        </PageFrame>
    );
};

export default AddBundleProduct;
