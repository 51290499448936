import React, { useEffect, useReducer, useRef, useState } from "react";
import Button from "components/common/Button";
import CustomTable from "components/common/CustomTable";
import DateInput from "components/common/DateInput";
import SelectInput from "components/common/InputField/SelectInput";
import TextareaInput from "components/common/InputField/TextareaInput";
import TextInput from "components/common/InputField/TextInput";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { unitOfMeasurement } from "variables/unitOfMeasurement";
import ModalContainer from "components/common/ModalContainer";
import VendorAvatar from "components/common/VendorAvatar";
import ProductionModals from "../../../components/views/Synth2.0/Production/ProductionModals";
import moment, { duration } from "moment";
import { debounce, truncate } from "lodash";
import { getCurrencyFromCurrencyCode } from "helpers";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import { authActions, inventoryActions, productionActions } from "redux/Ldb/actions";
import useGetSingleProductionPlan from "hooks/ProductionHooks/useGetSingleProductionPLan";
import { calculateDateDifference } from "helpers/getTimeDiffInDaysWeeksMths";
import useGetProductionPlanTab from "hooks/ProductionHooks/useGetProductionPlanTab";
import ProductionSteps from "./../../../components/views/Synth2.0/Production/ProductionSteps";
import Loader from "components/common/Loader";

const CreateProductionPlan = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { productionId } = useParams();
    const [param] = useSearchParams();
    const planTab = param.get("planTab");

    const [showStartDate, setShowStartDate] = useState<boolean>(false);
    const [showCompletionDate, setShowCompletionDate] = useState<boolean>(false);
    const [showPlannedStartDate, setShowPlannedStartDate] = useState<boolean>(false);
    const [showPlannedEndDate, setShowPlannedEndDate] = useState<boolean>(false);
    const [showDueDate, setShowDueDate] = useState<boolean>(false);
    const [successModal, setSuccessModal] = useState<boolean>(false);
    const [insufficientModal, setInsufficientModal] = useState<boolean>(false);
    const [productionStepModal, setProductionStepModal] = useState<boolean>(false);
    const [productionPlanId, setProductionPlanId] = useState<string>("");
    const [productName, setProductName] = useState<string>("");
    const [warning, setWarning] = useState<boolean>(false);
    const [bomList, setBomList] = useState<{ [key: string]: any }[]>([{}]);
    const [directCost, setDirectCost] = useState<{ [key: string]: any }[]>([{}]);
    const [indirectCost, setIndirectCost] = useState<{ [key: string]: any }[]>([{}]);
    const [step, setStep] = useState<{ [key: string]: any }>({});
    const [productionSteps, setProductionSteps] = useState<{ [key: string]: any }[]>([]);
    const [stepIdx, setStepIdx] = useState<number>(-1);
    const [teamMembers, setTeamMembers] = useState<{ [key: string]: any }[]>([]);
    const [nonFinishedInventory, setNonFinishedInventory] = useState<{ [key: string]: any }[]>([]);
    const [productData, setProductData] = useState<{ [key: string]: any }>({
        duration: "days",
    });
    const [directCostSum, setDirectCostSum] = useState<number>();
    const [indirectCostSum, setIndirectCostSum] = useState<number>();
    // const [stepsId, setStepsId] = useState<string>("");
    const [savedStepId, setSavedStepId] = useState<string>("");

    const { data: planDetails, isLoading } = useGetSingleProductionPlan();
    const {
        data: stepsDetails,
        isLoading: stepsLoading,
        setQuery,
        debouncedSearch,
        setDebouncedSearch,
        handleDebouncedSearch,
        search,
        setSearch,
        handleSearch,
        isLoadingMore,
        pagination,
    } = useGetProductionPlanTab();

    const [finishedProduct, setFinishedProduct] = useState<{ [key: string]: any }[]>([]);

    const { fetchingTeamMember, fetchedTeamMemberSuccess } = useAppSelector((state) => state.auth);

    const {
        fetchingFinishedInventory,
        fetchedFinishedInventorySuccess,
        fetchingNonFinishedInventory,
        fetchedNonFinishedInventorySuccess,
        creatingProductionPlan,
        createProductionPlanSuccess,
        updatingProductionPlan,
        updateProductionPlanSuccess,
        creatingPlanSteps,
        createPlanStepsSuccess,
        updatingPlanSteps,
        updatePlanStepsSuccess,
    } = useAppSelector((state) => state.production);

    useEffect(() => {
        setQuery("steps");
    }, []);

    useEffect(() => {
        if (productionId && planDetails) {
            setProductData({
                inventoryId: planDetails?.inventory?._id,
                quantity: planDetails?.quantity?.amount,
                unitOfMeasurement: planDetails?.quantity?.unitOfMeasurement,
                lotNumber: planDetails?.batchId,
                startDate: moment(planDetails?.startDate),
                dueDate: moment(planDetails?.dueDate),
                endDate: moment(planDetails?.endDate),
                managerId: planDetails?.managerId,
                ...(planDetails?.qualityControlId && {
                    qualityControlId: planDetails?.qualityControlId,
                }),
                ...(planDetails?.notes && { notes: planDetails?.notes }),
                leadTime: calculateDateDifference(
                    planDetails?.startDate,
                    planDetails?.estimatedLeadTime
                )?.time,
                duration: calculateDateDifference(
                    planDetails?.startDate,
                    planDetails?.estimatedLeadTime
                )?.duration,
            });

            const newArray = planDetails?.directCosts?.map((cost) => {
                const { _id, ...rest } = cost;
                return rest;
            });
            setDirectCost(newArray);
            if (planDetails?.indirectCosts?.length >= 1) {
                const newIndirectCost = planDetails?.indirectCosts?.map((cost) => {
                    const { _id, ...rest } = cost;
                    return rest;
                });
                setIndirectCost(newIndirectCost);
            }

            const newBOM = planDetails?.billOfMaterialIds?.map((bom) => {
                const { _id, inventory, ...rest } = bom;
                return rest;
            });
            setBomList(newBOM);

            const modSteps = stepsDetails?.steps?.sort((a, b) => a?.step - b?.step);

            const newSteps =
                modSteps?.length >= 1
                    ? modSteps?.map((step) => {
                          return {
                              name: step?.name,
                              description: step?.description,
                              amount: step?.quantity?.amount,
                              unitOfMeasurement: step?.quantity?.unitOfMeasurement,
                              step: step?.step,
                              plannedStart: moment(step?.plannedStart)?.format("YYYY-MM-DDTHH:MM"),
                              plannedEnd: moment(step?.plannedEnd)?.format("YYYY-MM-DDTHH:MM"),
                              id: step?._id,
                          };
                      })
                    : [];
            setProductionSteps(newSteps);
        }
    }, [productionId, planDetails, stepsDetails]);

    useEffect(() => {
        dispatch(authActions.getTeamMembers(40));
    }, [dispatch]);

    useEffect(() => {
        if (Boolean(fetchedTeamMemberSuccess)) {
            setTeamMembers(fetchedTeamMemberSuccess.teamMembers);
        }
    }, [fetchedTeamMemberSuccess]);

    useEffect(() => {
        dispatch(productionActions.getFinishedInventory(40));
    }, [dispatch]);

    useEffect(() => {
        if (Boolean(fetchedFinishedInventorySuccess)) {
            setFinishedProduct(fetchedFinishedInventorySuccess?.products);
        }
    }, [fetchedFinishedInventorySuccess]);

    useEffect(() => {
        dispatch(productionActions.getNonFinishedInventory(40));
    }, [dispatch]);

    useEffect(() => {
        if (Boolean(fetchedNonFinishedInventorySuccess)) {
            setNonFinishedInventory(fetchedNonFinishedInventorySuccess?.products);
        }
    }, [fetchedNonFinishedInventorySuccess]);

    const ModalTableHeader = [
        {
            title: "Material needed",
            widthClass: "w-[50%] !border !bg-slate-50 !border-slate-200",
        },
        {
            title: "Current Inventory",
            widthClass: "w-[25%] !border !bg-slate-50 !border-slate-200",
        },
        { title: "Needed inventory", widthClass: "w-[25%] !border !bg-slate-50 !border-slate-200" },
    ];

    const ModalTableBody = [1, 1, 1, 1]?.map((product, idx) => {
        return [
            {
                content: (
                    <div className={`w-full  px-4 `}>
                        <div className="flex items-center gap-2 py-2">
                            {!product ? (
                                <VendorAvatar
                                    // imageSrc={
                                    //     product?.productImageDetails?.productAvatar ||
                                    //     product?.productImageDetails?.productImages?.[0]
                                    // }
                                    size={32}
                                    // name={product?.productName}
                                    containerClassname="!rounded-md"
                                />
                            ) : (
                                <div className="w-8 h-8 rounded shrink-0 flex items-center justify-center bg-slate-200 ">
                                    <i className="ri-box-2-fill text-lg text-slate-500"></i>
                                </div>
                            )}

                            {/* <span>{truncate(item?.inventory?.productName, { length: 20 })}</span> */}
                            <div className="text-sm">
                                <p className="text-g-75"> Cane Sugar</p>
                                <p className="text-[#64748B]">SKU-11223</p>
                            </div>
                        </div>
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                widthClass: "!border-slate-200",
            },
            {
                content: (
                    <div className={`w-full  px-4`}>
                        <p className="text-[#E4281D] text-sm">500kg</p>
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                widthClass: "!border-slate-200",
            },
            {
                content: (
                    <div className={`w-full px-4`}>
                        <p className="text-g-75 text-sm">1000kg</p>
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                widthClass: "!border-slate-200",
            },
        ];
    });

    const handleBOMData = (e, idx) => {
        const { name, value } = e.target;
        const copyArr = [...bomList];
        const copy = copyArr[idx];
        copy[name] = value;
        copyArr[idx] = copy;
        setBomList(copyArr);
    };

    const handleDateChange = (name, date) => {
        setProductData((prev) => ({
            ...prev,
            [name]: date,
        }));
    };

    const calcTotalQuantity = (quantity, scrap, unitPrice?: number) => {
        const quantityNum = parseInt(quantity);
        const scrapNum = parseInt(scrap);
        const percentage = (quantityNum * scrapNum) / 100;
        const totalQuantity = percentage + parseInt(quantity);
        if (unitPrice !== undefined) {
            return totalQuantity * unitPrice;
        }

        return Number.isInteger(totalQuantity) ? totalQuantity : totalQuantity.toFixed(2);
    };

    const handleAddMoreBom = () => {
        console.log(bomList);
        setBomList((prev) => [...prev, {}]);
    };

    const BOMTableHeader = [
        { title: "Category", widthClass: "w-[20%] !font-rocGroteskMedium" },
        { title: "Product", widthClass: "w-[20%] !font-rocGroteskMedium" },
        { title: "Description", widthClass: "w-[20%] !font-rocGroteskMedium" },
        { title: "Quantity", widthClass: "w-[12%] !font-rocGroteskMedium" },
        { title: "Scrap (%)", widthClass: "w-[12%] !font-rocGroteskMedium" },
        { title: "Total Quantity", widthClass: "w-[20%] !font-rocGroteskMedium" },
        { title: "UOM", widthClass: "w-[12%] !font-rocGroteskMedium" },
        { title: "Unit price", widthClass: "w-[20%] !font-rocGroteskMedium" },
        { title: "Total price", widthClass: "w-[20%] !font-rocGroteskMedium" },
    ];

    console.log(bomList);
    const BOMTableBody = bomList?.map((item, idx) => {
        return [
            {
                content: (
                    <div key={idx} className={`w-full  border-slate-200 text-sm text-slate-500 `}>
                        <SelectInput
                            value={item?.inventoryId || ""}
                            name="inventoryId"
                            inputPlaceholder={"Select product"}
                            handleChange={(name, value) => {
                                if (value) {
                                    setBomList((prevSelectedItems) => {
                                        const updatedItems = [...prevSelectedItems];
                                        const selectedItem = nonFinishedInventory.find(
                                            (item) => item._id === value
                                        );
                                        if (selectedItem) {
                                            updatedItems[idx] = {
                                                ...updatedItems[idx],
                                                inventoryCategory: selectedItem?.inventoryCategory,
                                                unitPrice: selectedItem?.unitCost?.amount,
                                                currency: selectedItem?.unitCost?.currency,
                                                productDescription:
                                                    selectedItem?.productDescription,
                                                unitOfMeasurement:
                                                    selectedItem?.productStockDetails
                                                        ?.unitOfMeasurement,
                                                productAvatar:
                                                    selectedItem?.productImageDetails
                                                        ?.productAvatar ||
                                                    selectedItem?.productImageDetails
                                                        ?.productImages?.[0],
                                                productSKu: selectedItem?.sku,
                                                inventoryId: selectedItem?._id,
                                            };
                                        }
                                        return updatedItems;
                                    });
                                }
                            }}
                            // handleInputChange={debounce((evt) => {
                            //     setDebouncedSearch(evt.target.value);
                            // }, 800)}
                            isRequired={true}
                            className="!border-0 !rounded-none"
                            inputContainerClassName="!mb-0"
                            // searchLoading={fetchingInventoryList}
                            clearValue={true}
                            dropdownOptions={nonFinishedInventory?.map((inv) => ({
                                label: (
                                    <div className="text-sm">
                                        <p className="text-g-75">
                                            {" "}
                                            {truncate(inv?.productName, { length: 20 })}
                                        </p>
                                        <p className="text-[#64748B]">{inv?.sku}</p>
                                    </div>
                                ),

                                icon: (
                                    <div className="flex items-center gap-2">
                                        {inv?.productImageDetails?.productAvatar ||
                                        inv?.productImageDetails?.productImages?.[0] ? (
                                            <VendorAvatar
                                                imageSrc={
                                                    inv?.productImageDetails?.productAvatar ||
                                                    inv?.productImageDetails?.productImages?.[0]
                                                }
                                                size={32}
                                                name={inv?.productName}
                                                containerClassname="!rounded-md"
                                            />
                                        ) : (
                                            <div className="w-8 h-8 rounded shrink-0 flex items-center justify-center bg-slate-200 ">
                                                <i className="ri-box-2-fill text-lg text-slate-500"></i>
                                            </div>
                                        )}
                                    </div>
                                ),
                                value: inv._id,
                            }))}
                        />
                    </div>
                ),
                tableDataDivClass: "!pl-0",
            },
            {
                content: (
                    <div className={`w-full  border-slate-200 text-sm text-slate-500 `}>
                        <TextInput
                            value={
                                item?.inventoryCategory
                                    ? item?.inventoryCategory
                                    : item?.inventoryId && !item?.inventoryCategory
                                    ? "N/A"
                                    : ""
                            }
                            name="value"
                            type={"text"}
                            disabled={true}
                            inputPlaceholder="Category"
                            inputContainerClassname={"!border-none !rounded-none !bg-transparent"}
                            inputClassName="w-full "
                        />
                    </div>
                ),
                tableDataDivClass: "!pl-0",
            },
            {
                content: (
                    <div
                        className={`w-full border-slate-200 text-sm text-slate-500 flex items-center px-2.5`}
                    >
                        <TextInput
                            value={
                                item?.productDescription
                                    ? item?.productDescription
                                    : item?.instructions
                                    ? item?.instructions
                                    : item?.inventoryId &&
                                      !item?.productDescription &&
                                      !item?.instructions
                                    ? "N/A"
                                    : ""
                            }
                            name="productDescription"
                            type={"text"}
                            disabled={true}
                            inputPlaceholder="Description"
                            inputContainerClassname={"!border-none !rounded-none !bg-transparent"}
                            inputClassName="w-full "
                        />
                    </div>
                ),
                tableDataDivClass: "!pl-0",
            },
            {
                content: (
                    <div className={`w-full border-slate-200 text-sm text-slate-500 `}>
                        <TextInput
                            value={item?.quantity || ""}
                            name="quantity"
                            type={"number"}
                            onWheel={(e: any) => e.target.blur()}
                            onKeyDown={(evt) =>
                                ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(evt.key) &&
                                evt.preventDefault()
                            }
                            required={true}
                            onChange={(e) => handleBOMData(e, idx)}
                            inputPlaceholder="Quantity"
                            containerClassname=""
                            // inputContainerClassname={"!border-[2px] !border-[#000] !rounded-none "}
                            inputClassName="w-full "
                            inputContainerClassname={"!border-0 !rounded-none "}
                            focusBorder="!border-[2px] !border-b-[2px] !border-[#000]"
                        />
                    </div>
                ),
                tableDataDivClass: "!pl-0",
            },
            {
                content: (
                    <div className={`w-full border-slate-200 text-sm text-slate-500 px-2.5`}>
                        <TextInput
                            value={item?.scrap || ""}
                            name="scrap"
                            type={"number"}
                            onWheel={(e: any) => e.target.blur()}
                            required={true}
                            onChange={(e) => handleBOMData(e, idx)}
                            inputPlaceholder="Scrap"
                            containerClassname=""
                            inputClassName="w-full"
                            inputContainerClassname={"!border-0 !rounded-none "}
                            focusBorder="!border-[2px] !border-b-[2px] !border-[#000]"
                        />
                    </div>
                ),
                tableDataDivClass: "!pl-0",
            },

            {
                content: (
                    <div className={`w-full border-slate-200 text-sm text-slate-500 px-2.5`}>
                        <TextInput
                            value={String(calcTotalQuantity(item?.quantity, item?.scrap))}
                            name="value"
                            type={"number"}
                            onWheel={(e: any) => e.target.blur()}
                            disabled={true}
                            inputPlaceholder="0"
                            containerClassname=""
                            inputContainerClassname={"!border-none !rounded-none !bg-transparent"}
                            inputClassName="w-full "
                        />
                    </div>
                ),
                tableDataDivClass: "!pl-0",
            },
            {
                content: (
                    <div className={`w-full border-slate-200 text-sm text-slate-500 px-2.5`}>
                        <TextInput
                            value={item?.unitOfMeasurement || ""}
                            name="unitOfMeasurement"
                            type={"text"}
                            disabled={true}
                            inputPlaceholder="UOM"
                            inputContainerClassname={"!border-none !rounded-none !bg-transparent"}
                            inputClassName="w-full "
                        />
                    </div>
                ),
                tableDataDivClass: "!pl-0",
            },
            {
                content: (
                    <div
                        className={`w-full border-slate-200 text-sm text-slate-500 px-2.5 flex items-center`}
                    >
                        <p className="text-slate-500">
                            {getCurrencyFromCurrencyCode(item?.currency)}
                        </p>

                        <div className="!w-[50px]">
                            <TextInput
                                value={item?.unitPrice || ""}
                                name={"unitPrice"}
                                type={"number"}
                                onWheel={(e: any) => e.target.blur()}
                                onKeyDown={(evt) =>
                                    ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(
                                        evt.key
                                    ) && evt.preventDefault()
                                }
                                disabled={true}
                                inputPlaceholder="0"
                                containerClassname=""
                                inputContainerClassname={
                                    "!border-none !rounded-none !bg-transparent"
                                }
                                inputClassName=" "
                            />
                        </div>
                    </div>
                ),
                tableDataDivClass: "!pl-0",
            },
            {
                content: (
                    <div
                        className={`w-full border-slate-200 text-sm text-slate-500 px-2.5 flex items-center`}
                    >
                        <p className="text-slate-500">
                            {getCurrencyFromCurrencyCode(item?.currency)}
                        </p>

                        <div className="">
                            <TextInput
                                value={
                                    item?.unitPrice
                                        ? String(
                                              calcTotalQuantity(
                                                  item?.quantity,
                                                  item?.scrap,
                                                  item?.unitPrice
                                              )
                                          )
                                        : ""
                                }
                                name={"totalCost"}
                                type={"number"}
                                onWheel={(e: any) => e.target.blur()}
                                disabled={true}
                                inputPlaceholder="0"
                                containerClassname=""
                                inputContainerClassname={
                                    "!border-none !rounded-none !bg-transparent"
                                }
                                inputClassName=" "
                            />
                        </div>
                    </div>
                ),
                tableDataDivClass: "!pl-0",
            },
        ];
    });

    const DirectCostTableHeader = [
        { title: "Category", widthClass: "w-[50%] !font-rocGroteskMedium !border-slate-200" },
        // {
        //     title: "Total Estimated Cost",
        //     widthClass: "w-[33%] !font-rocGroteskMedium !border-slate-200",
        //     sortIcon: true,
        // },
        { title: "Estimated Cost", widthClass: "w-[50%] !font-rocGroteskMedium !border-slate-200" },
    ];

    const DirectCostTableBody = directCost?.map((item, idx) => {
        return [
            {
                content: (
                    <div
                        key={idx}
                        className={`w-full border-slate-200 text-sm text-slate-500 flex items-center  font-rocGroteskMedium `}
                    >
                        <TextInput
                            value={item?.category ?? ""}
                            name={"category"}
                            type="text"
                            required={idx === 0 ? true : item?.amount && true}
                            onChange={(e) => handleDirectCostData(e, idx)}
                            inputPlaceholder="Enter category"
                            containerClassname=""
                            inputClassName="w-full"
                            inputContainerClassname={"!border-0 !rounded-none "}
                            focusBorder="!border-[2px] !border-b-[2px] !border-[#000]"
                        />
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                widthClass: "!border-slate-200",
            },
            // {
            //     content: (
            //         <div
            //             className={`w-full border-slate-200 text-sm text-slate-500 flex items-center px-2.5 font-rocGroteskMedium`}
            //         >
            //             {/* <p className="text-slate-500">$</p> */}

            //             <div className="w-[160px]">
            //                 <SelectInput
            //                     value={item?.currency || ""}
            //                     name="currency"
            //                     // placeholder="Currency"
            //                     handleChange={(name, value) => {
            //                         handleDirectCostData({ target: { name, value } }, idx);
            //                     }}
            //                     // className={`${
            //                     //     stockData?.cost?.currency &&
            //                     //     "!border-g-60 !border-[0.5px] !border-b-[1px]"
            //                     // }`}
            //                     className={"!border-none !rounded-none !h-auto !w-full !py-0"}
            //                     isRequired={item?.category && true}
            //                     clearValue
            //                     dropdownOptions={[
            //                         {
            //                             label: "GBP (£)",
            //                             value: "GBP",
            //                         },
            //                         {
            //                             label: "USD ($)",
            //                             value: "USD",
            //                         },
            //                         {
            //                             label: "EUR (€)",
            //                             value: "EUR",
            //                         },
            //                         {
            //                             label: "NGN (₦)",
            //                             value: "NGN",
            //                         },
            //                     ]}
            //                 />
            //             </div>
            //             {/* <div className="!w-[50px]"></div> */}
            //         </div>
            //     ),
            //     tableDataDivClass: "!pl-0",
            //     widthClass: "!border-slate-200",
            // },
            {
                content: (
                    <div
                        className={`w-full border-slate-200 text-[13px] text-slate-500 flex items-center font-rocGroteskMedium `}
                    >
                        <TextInput
                            value={item?.amount ?? ""}
                            name={"amount"}
                            type="number"
                            required={idx === 0 ? true : item?.category && true}
                            onChange={(e) => handleDirectCostData(e, idx)}
                            inputPlaceholder="0.00"
                            containerClassname=""
                            inputClassName="w-full"
                            inputContainerClassname={"!border-0 !rounded-none "}
                            focusBorder="!border-[2px] !border-b-[2px] !border-[#000]"
                        />
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                widthClass: "!border-slate-200",
            },
        ];
    });

    const DirectCostTableFooter = () => {
        return (
            <div className="flex rounded-b-md border-x border-b border-slate-200 bg-[#F8FAFC]">
                <div className=" border-slate-200 w-[50%]"></div>
                {/* <div className="w-[33%]"></div> */}
                <div className="w-[50%] border-l border-slate-200">
                    <p className="text-g-75 text-md py-[14px] px-2.5">${directCostSum}</p>
                </div>
            </div>
        );
    };

    const IndirectCostTableHeader = [
        { title: "Category", widthClass: "w-[50%] !font-rocGroteskMedium !border-slate-200" },
        // {
        //     title: "Total Estimated Cost",
        //     widthClass: "w-[33%] !font-rocGroteskMedium !border-slate-200",
        //     sortIcon: true,
        // },
        { title: "Estimated Cost", widthClass: "w-[50%] !font-rocGroteskMedium !border-slate-200" },
    ];

    const IndirectCostTableBody = indirectCost?.map((item, idx) => {
        return [
            {
                content: (
                    <div
                        key={idx}
                        className={`w-full border-slate-200 text-sm text-slate-500 flex items-center font-rocGroteskMedium `}
                    >
                        <TextInput
                            value={item?.category ?? ""}
                            name={"category"}
                            type="text"
                            required={item?.amount && true}
                            onChange={(e) => handleIndirectCostData(e, idx)}
                            inputPlaceholder="Enter category"
                            inputClassName="w-full"
                            inputContainerClassname={"!border-0 !rounded-none "}
                            focusBorder="!border-[2px] !border-b-[2px] !border-[#000]"
                        />
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                widthClass: "!border-slate-200",
            },
            // {
            //     content: (
            //         <div
            //             className={`w-full border-slate-200 text-sm text-slate-500 flex items-center px-2.5 font-rocGroteskMedium`}
            //         >

            //             <div className="">

            //             </div>
            //             {/* <p className="text-slate-500">$</p> */}

            //             {/* <div className="w-[160px]">
            //                 <SelectInput
            //                     value={item?.currency || ""}
            //                     name="currency"
            //                     // placeholder="Currency"
            //                     handleChange={(name, value) => {
            //                         handleIndirectCostData({ target: { name, value } }, idx);
            //                     }}
            //                     // className={`${
            //                     //     stockData?.cost?.currency &&
            //                     //     "!border-g-60 !border-[0.5px] !border-b-[1px]"
            //                     // }`}
            //                     className={"!border-none !rounded-none !h-auto !w-full !py-0"}
            //                     isRequired={item?.category && true}
            //                     clearValue
            //                     dropdownOptions={[
            //                         {
            //                             label: "GBP (£)",
            //                             value: "GBP",
            //                         },
            //                         {
            //                             label: "USD ($)",
            //                             value: "USD",
            //                         },
            //                         {
            //                             label: "EUR (€)",
            //                             value: "EUR",
            //                         },
            //                         {
            //                             label: "NGN (₦)",
            //                             value: "NGN",
            //                         },
            //                     ]}
            //                 />
            //             </div> */}
            //             {/* <div className="!w-[50px]"></div> */}
            //         </div>
            //     ),
            //     tableDataDivClass: "!pl-0",
            //     widthClass: "!border-slate-200",
            // },
            {
                content: (
                    <div
                        className={`w-full border-slate-200 text-[13px] text-slate-500 flex items-center font-rocGroteskMedium `}
                    >
                        <TextInput
                            value={item?.amount ?? ""}
                            name={"amount"}
                            type="number"
                            required={item?.category && true}
                            onChange={(e) => handleIndirectCostData(e, idx)}
                            inputPlaceholder="0.00"
                            containerClassname=""
                            inputClassName="w-full !placeholder:text-slate-500"
                            inputContainerClassname={"!border-0 !rounded-none "}
                            focusBorder="!border-[2px] !border-b-[2px] !border-[#000]"
                        />
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                widthClass: "!border-slate-200",
            },
        ];
    });
    const IndirectCostTableFooter = () => {
        return (
            <div className="flex rounded-b-md border-x border-b border-slate-200 bg-[#F8FAFC]">
                <div className=" border-slate-200 w-[50%]"></div>
                {/* <div className="w-[33%]"></div> */}
                <div className="w-[50%]  border-l border-slate-200">
                    <p className="text-g-75 text-md py-[14px] px-2.5">${indirectCostSum}</p>
                </div>
            </div>
        );
    };

    useEffect(() => {
        const sum = Array.isArray(directCost)
            ? directCost.reduce((sum, cost) => sum + (Number(cost?.amount) || 0), 0)
            : 0;
        setDirectCostSum(sum);
    }, [directCost]);

    useEffect(() => {
        const sum = indirectCost?.reduce((sum, cost) => sum + (Number(cost?.amount) || 0), 0);
        setIndirectCostSum(sum);
    }, [indirectCost]);

    const handleDirectCostData = (e, idx) => {
        const { name, value } = e.target;
        let copyArr = [...directCost];
        copyArr[idx] = {
            ...copyArr[idx],
            [name]: value,
        };
        setDirectCost(copyArr);
    };
    const handleIndirectCostData = (e, idx) => {
        const { name, value } = e.target;
        let copyArr = [...indirectCost];
        copyArr[idx] = {
            ...copyArr[idx],
            [name]: value,
        };
        setIndirectCost(copyArr);
    };
    const handleProductData = (e) => {
        const { name, value } = e.target;
        setProductData((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleDebouncedBatchIdCheck = debounce((e) => {
        const { value } = e.target;

        if (value) {
            validateBatchNo(value);
        }
    }, 3000);

    const validateBatchNo = async (batchId) => {
        try {
            const res = await dispatch(inventoryActions.validateBatchId(batchId));
            if (!res?.success) {
                setWarning(true);
            } else {
                setWarning(false);
            }
        } catch (err) {
            console.log(err);
        }
    };

    const handleCreateProductionPlan = () => {
        const saveIndirect =
            indirectCost?.length >= 1
                ? indirectCost
                      ?.filter((item) => {
                          return item?.category && item?.amount;
                      })
                      ?.map((item) => {
                          return {
                              category: item?.category,
                              currency: "USD",
                              amount: item?.amount,
                          };
                      })
                : [];

        const body = {
            ...(!productionId && { inventoryId: productData?.inventoryId }),
            quantity: {
                amount: productData?.quantity,
                unitOfMeasurement: productData?.unitOfMeasurement,
            },
            ...(productData?.lotNumber && !productionId && { batchId: productData?.lotNumber }),
            startDate: productData?.startDate?.toISOString(),
            dueDate: productData?.dueDate?.toISOString(),
            endDate: productData?.endDate?.toISOString(),
            managerId: productData?.managerId,
            ...(productData?.qualityControlId && {
                qualityControlId: productData?.qualityControlId,
            }),
            ...(productData?.notes && { notes: productData?.notes }),
            directCosts: directCost
                ?.filter((item) => {
                    return item?.category && item?.amount;
                })
                ?.map((item) => {
                    return {
                        category: item?.category,
                        currency: "USD",
                        amount: item?.amount,
                    };
                }),
            ...(saveIndirect?.length >= 1 && { indirectCosts: saveIndirect }),
            ...(!productionId &&
                productionSteps?.length >= 1 && {
                    productionSteps: productionSteps?.map((item) => {
                        return {
                            name: item?.name,
                            description: item?.description,
                            plannedStart: moment(item?.plannedStart)?.toISOString(),
                            plannedEnd: moment(item?.plannedEnd)?.toISOString(),
                            quantity: {
                                amount: item?.amount,
                                unitOfMeasurement: item?.unitOfMeasurement,
                            },
                            step: item?.step,
                        };
                    }),
                }),
            billOfMaterialIds: bomList?.map((item) => {
                return {
                    inventoryId: item?.inventoryId,
                    quantity: item?.quantity,
                    scrap: item?.scrap,
                    ...(item?.instructions && { instructions: item?.instructions }),
                };
            }),
            estimatedLeadTime: moment(productData?.startDate)
                .add(productData?.leadTime, productData?.duration)
                .format("YYYY-MM-DD"),
        };

        console.log(body);

        if (productionId) {
            dispatch(productionActions?.updateProductionPlan(body, productionId));
        } else {
            dispatch(productionActions?.createProductionPlan(body));
        }

        // console.log("for update production plan", body, productionId);

        // console.log("for creating", body);
    };

    const handleStepsSubmission = () => {
        if (productionId) {
            const modStep = {
                name: step?.name,
                description: step?.description,
                plannedStart: moment(step?.plannedStart)?.toISOString(),
                plannedEnd: moment(step?.plannedEnd)?.toISOString(),
                quantity: {
                    amount: step?.amount,
                    unitOfMeasurement: step?.unitOfMeasurement,
                },
                step: step?.step,
            };

            if (savedStepId) {
                dispatch(productionActions?.updateProductionPlanSteps(modStep, savedStepId));
            } else {
                const save = {
                    steps: [modStep],
                };
                dispatch(productionActions?.createProductionPlanSteps(save, productionId));
            }
        }
    };

    useEffect(() => {
        if (!productionStepModal) {
            setStep({});
            setStepIdx(-1);
            setSavedStepId("");
            if (!productionId) {
                const copyArr = [...productionSteps];
                const sortArr = copyArr?.sort((a, b) => a?.step - b?.step);
                setProductionSteps(sortArr);
            }
        }
    }, [productionStepModal]);

    useEffect(() => {
        if (Boolean(createPlanStepsSuccess)) {
            dispatch(productionActions?.resetCreateProductionPlanSteps());
            dispatch(productionActions.fetchProductionPlanTab(productionId, "steps", 10, 1));
            setProductionStepModal(false);
        }
    }, [createPlanStepsSuccess]);

    useEffect(() => {
        if (Boolean(updatePlanStepsSuccess)) {
            dispatch(productionActions.fetchProductionPlanTab(productionId, "steps", 10, 1));
            dispatch(productionActions?.resetUpdateProductionPlanSteps());
            setProductionStepModal(false);
        }
    }, [updatePlanStepsSuccess]);

    useEffect(() => {
        if (Boolean(createProductionPlanSuccess)) {
            setSuccessModal(true);
            dispatch(productionActions?.resetCreateProductionPlan());
            setProductionPlanId(createProductionPlanSuccess?.productionPlan?._id);
        }
    }, [createProductionPlanSuccess]);

    useEffect(() => {
        if (Boolean(updateProductionPlanSuccess)) {
            setSuccessModal(true);
            dispatch(productionActions?.resetUpdateProductionPlan());
            setProductionPlanId(updateProductionPlanSuccess?.productionPlan?._id);
        }
    }, [updateProductionPlanSuccess]);

    return (
        <div>
            <form
                onSubmit={(e) => {
                    e.preventDefault();
                    handleCreateProductionPlan();
                }}
                className="bg-white rounded-lg border border-n-20 font-rocGroteskMedium pb-12"
            >
                <div className="flex items-center justify-between px-8 py-[15px] border-b border-n-20">
                    <div className="flex items-center space-x-1.5">
                        <i
                            onClick={() => navigate(`/dashboard/production?planTab=${planTab}`)}
                            className="ri-arrow-left-line text-xl mt-[-2px] cursor-pointer max-sm:hidden"
                        ></i>
                        <span className="text-lg font-rocGroteskMedium">
                            {productionId ? "Edit production plan" : "Create a new production plan"}
                        </span>
                    </div>
                    <div className="flex items-center space-x-3">
                        <Button
                            btnText="Cancel"
                            btnClassname="!px-4 !py-2.5 !bg-tradeally-neutral-20 !w-fit"
                            disabled={creatingProductionPlan || updatingProductionPlan}
                            onClick={() => navigate(`/dashboard/production?planTab=${planTab}`)}
                        />
                        <Button
                            btnText={productionId ? "Save changes" : "Create production plan"}
                            type="submit"
                            isLoading={creatingProductionPlan || updatingProductionPlan}
                            disabled={creatingProductionPlan || updatingProductionPlan}
                            btnClassname="!px-4 !py-2.5 !w-fit"
                        />
                    </div>
                </div>
                {warning && (
                    <div
                        className={`h-[36px] bg-[rgba(228,40,29,0.05)] flex items-center justify-center gap-2 w-full text-[#E4281D] text-sm transition-opacity`}
                    >
                        <i className="ri-alarm-warning-fill text-[#E4281D]"></i>
                        Duplicate LOT Number Detected: The LOT number you entered already exists in
                        Synth. Please enter a different LOT number to proceed.
                    </div>
                )}
                <div className="px-8">
                    <div className="py-6 ">
                        <div className="grid grid-cols-4 max-lg:grid-cols-2 max-sm:grid-cols-1 items-center gap-4">
                            <SelectInput
                                value={productData?.inventoryId}
                                name="inventoryId"
                                placeholder={"Product"}
                                handleChange={(name, value) => {
                                    handleProductData({ target: { name, value } });
                                    if (value) {
                                        const selectedProduct = finishedProduct?.find(
                                            (item) => item?._id === value
                                        );
                                        console.log(selectedProduct);
                                        setProductName(selectedProduct?.productName);

                                        if (selectedProduct?.billOfMaterialIds?.length >= 1) {
                                            console.log("here");
                                            setBomList(selectedProduct?.billOfMaterialIds);
                                        } else {
                                            console.log("here2");

                                            setBomList([{}]);
                                        }
                                    }
                                }}
                                isRequired={true}
                                dropdownOptions={finishedProduct?.map((product) => ({
                                    label: product.productName,
                                    value: product._id,
                                }))}
                                disabled={productionId && true}
                            />

                            <div className="">
                                <SelectInput
                                    value={productData.unitOfMeasurement}
                                    name="unitOfMeasurement"
                                    placeholder={"UOM"}
                                    handleChange={(name, value) => {
                                        handleProductData({ target: { name, value } });
                                    }}
                                    isRequired={true}
                                    dropdownOptions={unitOfMeasurement}
                                    dropdownClassName="!pb-0"
                                    rightDropdownContainerClassName="!sticky bottom-0"
                                />
                            </div>
                            <TextInput
                                value={productData.quantity}
                                name={"quantity"}
                                type={"text"}
                                onChange={(e) => handleProductData(e)}
                                placeholder={"Quantity"}
                                required={true}
                                containerClassname=""
                            />
                            <TextInput
                                value={productData.lotNumber}
                                name={"lotNumber"}
                                type={"text"}
                                onChange={(e) => handleProductData(e)}
                                onInput={(e) => handleDebouncedBatchIdCheck(e)}
                                placeholder={"Lot Number"}
                                required={true}
                                disabled={productionId && true}
                                inputContainerClassname={`${
                                    warning && "!border !border-[#E4281D] rounded"
                                }`}
                                inputClassName={`${warning && "!text-[#E4281D] "}`}
                            />
                            <div className="gap-4 flex w-full">
                                <div className="w-[70%]">
                                    <TextInput
                                        value={productData.leadTime}
                                        name={"leadTime"}
                                        type={"text"}
                                        onChange={(e) => handleProductData(e)}
                                        placeholder={"Production Lead Time"}
                                        required={true}
                                        containerClassname=""
                                    />
                                </div>
                                <div className="w-[30%]">
                                    <SelectInput
                                        value={productData.duration}
                                        name="duration"
                                        placeholder={"Duration"}
                                        handleChange={(name, value) => {
                                            handleProductData({ target: { name, value } });
                                        }}
                                        isRequired={true}
                                        dropdownOptions={[
                                            {
                                                label: "Days",
                                                value: "days",
                                            },
                                            {
                                                label: "Weeks",
                                                value: "weeks",
                                            },
                                            {
                                                label: "Months",
                                                value: "months",
                                            },
                                        ]}
                                        dropdownClassName="!pb-0"
                                        rightDropdownContainerClassName="!sticky bottom-0"
                                    />
                                </div>
                            </div>

                            <DateInput
                                label="Start Date"
                                value={productData?.startDate}
                                onChange={(newDate) => handleDateChange("startDate", newDate)}
                                showCalendar={showStartDate}
                                onCalendarToggle={setShowStartDate}
                                borderClassname="!bg-transparent !border-none"
                                required={true}
                                disablePastDate={true}
                            />
                            <DateInput
                                label="Completion Date"
                                value={productData?.endDate}
                                onChange={(newDate) => handleDateChange("endDate", newDate)}
                                showCalendar={showCompletionDate}
                                onCalendarToggle={setShowCompletionDate}
                                borderClassname="!bg-transparent !border-none"
                                required={true}
                                disablePastDate={true}
                            />
                            <DateInput
                                label="Due Date"
                                value={productData?.dueDate}
                                onChange={(newDate) => handleDateChange("dueDate", newDate)}
                                showCalendar={showDueDate}
                                onCalendarToggle={setShowDueDate}
                                borderClassname="!bg-transparent !border-none"
                                required={true}
                                disablePastDate={true}
                                calendarClassname="!right-0"
                            />
                        </div>
                        <div className="grid grid-cols-2 gap-4 pt-6">
                            <SelectInput
                                value={productData.managerId}
                                name="managerId"
                                placeholder={"Production Manager"}
                                handleChange={(name, value) => {
                                    handleProductData({ target: { name, value } });
                                }}
                                isRequired={true}
                                searchLoading={fetchingTeamMember}
                                dropdownOptions={teamMembers?.map((member) => ({
                                    label: member?.fullName ? member?.fullName : member?.email,
                                    value: member?._id,
                                }))}
                            />
                            <SelectInput
                                value={productData.qualityControl}
                                name="qualityControlId"
                                placeholder={"Quality control officer"}
                                handleChange={(name, value) => {
                                    handleProductData({ target: { name, value } });
                                }}
                                searchLoading={fetchingTeamMember}
                                dropdownOptions={teamMembers?.map((member) => ({
                                    label: member?.fullName ? member?.fullName : member?.email,
                                    value: member?._id,
                                }))}
                            />
                        </div>
                        <div className="mt-4">
                            <TextareaInput
                                name={"notes"}
                                value={productData?.notes || ""}
                                onChange={handleProductData}
                                rows={5}
                                placeholder={"Additional info"}
                                required={false}
                            />
                        </div>
                    </div>
                    <div className="mt-12  ">
                        <p className="text-gm-75 text-base p-4 border-x border-t  border-slate-200 rounded-t-md">
                            Bill of materials (BOM) {productName && `for ${productName}`}
                        </p>
                        <CustomTable
                            tableBody={BOMTableBody}
                            tableHeader={BOMTableHeader}
                            isScrollable={false}
                            isCellBordered={true}
                            isCellSelectable={false}
                            isAllSelectable={false}
                            scrollWithin={false}
                            headerContainerClass="!bg-slate-50 !border-slate-200"
                            headerItemClass="font-rocGroteskMedium !text-[#334155] "
                            tableClass=""
                            bodyItemClass="hover:!bg-transparent !cursor-default !border-slate-200"
                        />
                    </div>
                    <div className="flex items-center gap-3 mt-6">
                        <Button
                            btnText="Add more"
                            btnClassname="!text-[13px] !py-2 !px-3 !bg-n-20 !w-fit text-gm-50 !rounded-[4px] "
                            onClick={handleAddMoreBom}
                        />
                    </div>
                    <div className="mt-12  ">
                        <p className="text-gm-75 text-base py-4">
                            Cost breakdown {productName && `for ${productName}`}
                        </p>
                        <div className="grid grid-cols-4 gap-2">
                            <div className="border border-slate-200 p-4 rounded-md">
                                <p className="text-slate-500 text-sm">Direct cost</p>

                                <div className={` text-lg text-g-75 py-2 flex items-center`}>
                                    <p className="text-g-75">${directCostSum}</p>
                                </div>
                            </div>
                            <div className="border border-slate-200 p-4 rounded-md">
                                <p className="text-slate-500 text-sm">Indirect cost</p>

                                <div className={` text-lg text-g-75 py-2 flex items-center`}>
                                    <p className="">${indirectCostSum}</p>
                                </div>
                            </div>{" "}
                            {/* <div className="border border-slate-200 p-4 rounded-md">
                                <p className="text-slate-500 text-sm">Total COGS</p>

                                <div className={` text-lg text-g-75 py-2 flex items-center`}>
                                    <p className="">$</p>

                                    <div className="!w-[50px]">
                                        <TextInput
                                            value="0"
                                            name={"direct`cost"}
                                            type={"number"}
                                            onWheel={(e: any) => e.target.blur()}
                                            onKeyDown={(evt) =>
                                                [
                                                    "e",
                                                    "E",
                                                    "+",
                                                    "-",
                                                    "ArrowUp",
                                                    "ArrowDown",
                                                ].includes(evt.key) && evt.preventDefault()
                                            }
                                            required={true}
                                            // onChange={(e) => handleBOMData(e, idx)}
                                            inputPlaceholder="0"
                                            inputClassName="!text-g-75 !py-0 !text-lg !pl-1"
                                            inputContainerClassname={"!border-none !rounded-none"}
                                        />
                                    </div>
                                </div>
                            </div> */}
                            <div className="border border-slate-200 p-4 rounded-md">
                                <p className="text-slate-500 text-sm">Total production cost</p>

                                <div className={` text-lg text-g-75 py-2 flex items-center`}>
                                    <p className="">${indirectCostSum + directCostSum}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="mt-8  ">
                        <div className="grid grid-cols-2 gap-6">
                            <div className="">
                                <p className="text-gm-75 text-base p-4 border-x border-t  border-slate-200 rounded-t-md">
                                    Direct costs
                                </p>
                                <CustomTable
                                    tableBody={DirectCostTableBody}
                                    tableHeader={DirectCostTableHeader}
                                    isScrollable={false}
                                    isCellBordered={true}
                                    isCellSelectable={false}
                                    isAllSelectable={false}
                                    scrollWithin={false}
                                    headerContainerClass="!bg-slate-50 !border-slate-200 "
                                    headerItemClass="font-rocGroteskMedium !text-[#334155] "
                                    tableClass="!border-slate-200"
                                    bodyItemClass="hover:!bg-transparent !cursor-default !border-slate-200"
                                />
                                <DirectCostTableFooter />
                                <Button
                                    btnText="Add more direct cost"
                                    btnClassname=" mt-6 !text-[13px] !py-2 !px-3 !bg-n-20 !w-fit text-gm-50 !rounded-[4px] "
                                    onClick={() => {
                                        setDirectCost((prev) => [...prev, {}]);
                                    }}
                                />
                            </div>

                            <div className="">
                                <p className="text-gm-75 text-base p-4 border-x border-t  border-slate-200 rounded-t-md">
                                    Indirect costs
                                </p>
                                <CustomTable
                                    tableBody={IndirectCostTableBody}
                                    tableHeader={IndirectCostTableHeader}
                                    isScrollable={false}
                                    isCellBordered={true}
                                    isCellSelectable={false}
                                    isAllSelectable={false}
                                    scrollWithin={false}
                                    headerContainerClass="!bg-slate-50 !border-slate-200 "
                                    headerItemClass="font-rocGroteskMedium !text-[#334155] "
                                    tableClass="!border-slate-200"
                                    bodyItemClass="hover:!bg-transparent"
                                />
                                <IndirectCostTableFooter />
                                <Button
                                    btnText="Add more indirect cost"
                                    btnClassname=" mt-6 !text-[13px] !py-2 !px-3 !bg-n-20 !w-fit text-gm-50 !rounded-[4px] "
                                    onClick={() => setIndirectCost((prev) => [...prev, {}])}
                                />
                            </div>
                        </div>
                    </div>

                    {!stepsLoading ? (
                        <div className="mt-12  ">
                            <div className="flex items-center justify-between gap-2">
                                <p className="text-gm-75 text-base py-4">
                                    Production schedule {productName && `for ${productName}`}
                                </p>

                                {productionSteps?.length >= 1 && (
                                    <div className="flex gap-2.5">
                                        <Button
                                            btnClassname="!py-2 !px-4 !w-fit !h-10 !text-[13px]"
                                            btnText={"Add production Step"}
                                            onClick={() => {
                                                setProductionStepModal(true);
                                            }}
                                        />
                                        <Button
                                            btnClassname="!py-2 !px-4 !w-fit !h-10 !text-[13px] !bg-transparent !border !border-slate-200"
                                            btnText={"Import steps from file"}
                                            // onClick={}
                                        />
                                    </div>
                                )}
                            </div>
                            {productionSteps?.length === 0 ? (
                                <div className="border border-slate-300 border-dashed mx-auto text-center pt-6 pb-8 rounded-md">
                                    <div className=" flex items-center flex-col ">
                                        <i className="ri-flow-chart text-g-75 text-[40px]"></i>
                                        <h6 className="mb-2 text-base font-rocGroteskMedium text-g-75 ">
                                            No production schedule found
                                        </h6>
                                        <p className="text-sm  mb-4 text-slate-500 max-w-[570px]">
                                            You haven't added any production steps or plans yet.
                                            Start organizing your production schedule to streamline
                                            your processes and improve efficiency.
                                        </p>
                                        <div className="flex gap-2.5">
                                            <Button
                                                btnClassname="!py-2 !px-4 !w-fit !h-10 !text-[13px]"
                                                btnText={"Add Production Step"}
                                                onClick={() => {
                                                    setProductionStepModal(true);
                                                }}
                                            />
                                            <Button
                                                btnClassname="!py-2 !px-4 !w-fit !h-10 !text-[13px] !bg-transparent !border !border-slate-200"
                                                btnText={"Import from document"}
                                                // onClick={}
                                            />
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                productionSteps?.map((step, idx) => {
                                    return (
                                        <div className="font-rocGroteskMedium">
                                            <div className="">
                                                {idx === 0 ? (
                                                    <div className="w-6 h-6 rounded-full bg-[#00DB8F] flex items-center justify-center relative z-1">
                                                        <div className="w-3 h-3 rounded-full bg-white flex items-center justify-center">
                                                            <i className="ri-check-line text-[#00DB8F] text-[10px]"></i>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div className="w-6 h-6 rounded-full bg-[#FEF3C7] flex items-center justify-center relative z-1">
                                                        <i className="ri-time-line text-[#D97706] text-xs"></i>
                                                    </div>
                                                )}
                                                <div
                                                    className={` pb-4 pl-[26px] ml-2.5 border-l border-slate-200 -mt-4`}
                                                >
                                                    <div className="border border-slate-200 rounded-[6px] font-rocGroteskMedium shadow-boxShadow-12  px-6 py-4">
                                                        <div className="flex items-start justify-between">
                                                            <div className="space-y-2">
                                                                <div className="py-1 px-3 border border-slate-200 bg-slate-100 rounded text-xs text-gm-50 w-fit">
                                                                    Day {step?.step}
                                                                </div>
                                                                <p className="text-sm text-g-75">
                                                                    Step {idx + 1}: {step?.name}
                                                                </p>
                                                                <p className="text-sm text-slate-500">
                                                                    {step?.description}
                                                                </p>
                                                            </div>

                                                            <div className="space-y-2">
                                                                <div className="flex gap-2">
                                                                    <p className="text-g-75 text-sm">
                                                                        Planned Start:{" "}
                                                                        <span className="text-slate-500 text-sm ">
                                                                            {moment(
                                                                                step?.plannedStart
                                                                            )?.format(
                                                                                "YYYY-MM-DD HH:MM:SS A"
                                                                            )}
                                                                        </span>
                                                                    </p>
                                                                    <p className="text-g-75 text-sm">
                                                                        Planned End:{" "}
                                                                        <span className="text-slate-500 text-sm ">
                                                                            {moment(
                                                                                step?.plannedEnd
                                                                            )?.format(
                                                                                "YYYY-MM-DD HH:MM:SS A"
                                                                            )}
                                                                        </span>
                                                                    </p>
                                                                    <p className="text-g-75 text-sm">
                                                                        Quantity: {` `}
                                                                        <span className="text-slate-500 text-sm ">
                                                                            {step?.amount}
                                                                            {` `}
                                                                            {
                                                                                step?.unitOfMeasurement
                                                                            }
                                                                        </span>
                                                                    </p>
                                                                </div>

                                                                <div className="flex gap-2">
                                                                    {step?.actualStart && (
                                                                        <p className="text-g-75 text-sm">
                                                                            <span className="text-slate-500 text-sm ">
                                                                                {moment(
                                                                                    step?.actualStart
                                                                                )?.format(
                                                                                    "YYYY-MM-DD HH:MM:SS A"
                                                                                )}
                                                                            </span>
                                                                        </p>
                                                                    )}

                                                                    {step?.actualStart && (
                                                                        <p className="text-g-75 text-sm">
                                                                            Actual End:{" "}
                                                                            <span className="text-slate-500 text-sm ">
                                                                                {moment(
                                                                                    step?.actualEnd
                                                                                )?.format(
                                                                                    "YYYY-MM-DD HH:MM:SS A"
                                                                                )}
                                                                            </span>
                                                                        </p>
                                                                    )}
                                                                    {/* <p className="text-g-75 text-sm">
                                                            Quantity
                                                            <span className="text-slate-500 text-sm ">
                                                                {" "}
                                                                525kg
                                                            </span>
                                                        </p> */}
                                                                </div>
                                                                {step?.actualStart &&
                                                                    step?.actualEnd && (
                                                                        <div className="flex gap-2">
                                                                            <p className="text-g-75 text-sm">
                                                                                Total time spent:{" "}
                                                                                <span className="text-g-75 text-sm ">
                                                                                    {" "}
                                                                                    4 hrs 10 mins
                                                                                </span>
                                                                            </p>
                                                                            <p className="text-g-75 text-sm">
                                                                                Total Quantity:
                                                                                <span className="text-g-75 text-sm ">
                                                                                    {" "}
                                                                                    525kg
                                                                                </span>
                                                                            </p>
                                                                        </div>
                                                                    )}
                                                            </div>
                                                        </div>

                                                        <div className="flex gap-2 pt-2">
                                                            {/* <Button
                                                                btnText="Add quality control checklist"
                                                                btnClassname="!px-4 !py-2 !bg-transparent !w-fit !border !border-slate-200 shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)] !text-[13px]"
                                                                icon={
                                                                    <i className="ri-list-unordered text-gm-50 text-xs"></i>
                                                                }
                                                                // disabled={}
                                                                // onClick={}
                                                            /> */}
                                                            <Button
                                                                btnText="Edit step"
                                                                btnClassname="!px-4 !py-2 !bg-transparent !w-fit !border !border-slate-200 shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)] !text-[13px]"
                                                                icon={
                                                                    <i className="ri-edit-2-line text-gm-50 text-xs"></i>
                                                                }
                                                                onClick={() => {
                                                                    setStepIdx(idx);
                                                                    setStep(productionSteps[idx]);
                                                                    setProductionStepModal(true);
                                                                    productionId &&
                                                                        setSavedStepId(step?.id);
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })
                            )}
                        </div>
                    ) : (
                        <div className="flex justify-center  items-center h-[100px]">
                            <Loader color="gm-25" size={6} />
                        </div>
                    )}
                </div>
            </form>

            {successModal && (
                <ProductionModals
                    successModal={successModal}
                    setSuccessModal={setSuccessModal}
                    productionPlanId={productionPlanId}
                />
            )}
            {/* <ModalContainer
                open={successModal}
                modalClassName={"py-10 px-10"}
                tailwindClassName="w-[700px]"
                showCloseIcon={false}
                closeModal={() => {
                    setSuccessModal(true);
                }}
            >
                <div className="bg-white rounded-[8px] relative font-rocGroteskMedium">
                    
                    <div className="mt-4 ">
                        <div className="text-center flex justify-center flex-col items-center px-8 pt-10 mb-8">
                            <div className="mb-4 w-12 h-12 bottom-0 right-[-13px] rounded-full bg-[#00DB8F] flex items-center justify-center">
                                <i className="ri-checkbox-circle-fill text-[24px] text-white"></i>
                            </div>
                            <div className="px-6 font-rocGroteskMedium">
                                <span className="block mb-2 text-center text-[22px]">
                                    Purchase orders created for materials
                                </span>
                                <p className="mt-1 text-sm text-slate-500 text-center ">
                                    All purchase orders for {productName && `for ${productName}`} have been
                                    successfully created. The raw materials from different suppliers
                                    will be replenished soon. You can track the order status for
                                    each supplier in the 'Purchase' section to ensure timely
                                    deliveries.
                                </p>
                            </div>
                        </div>
                        <div className="px-6 pb-8">
                            <p className="text-gm-50 text-sm mb-2">Summary</p>

                            <div className="border border-[#E2E8F0] rounded-md px-3 py-4 space-y-3">
                                <button className="border-b border-slate-200 text-g-75 text-sm underline pb-2">
                                    PO #KGR-2024-1223 (Molasses Supplier): Expected Delivery Date:
                                    August 25, 2024
                                </button>

                                <button className="border-b border-slate-200 text-g-75 text-sm underline pb-2">
                                    PO #KGR-2024-1223 (Molasses Supplier): Expected Delivery Date:
                                    August 25, 2024
                                </button>
                                <button className="border-b border-slate-200 text-g-75 text-sm underline pb-2">
                                    PO #KGR-2024-1223 (Molasses Supplier): Expected Delivery Date:
                                    August 25, 2024
                                </button>
                            </div>
                        </div>
                        <div className="border-t border-t-slate-200 py-4 px-6 flex items-center space-x-4">
                            <Button
                                btnText={"Return to production plan"}
                                btnClassname="!py-3 cursor-pointer !text-[14px] !bg-n-20 !text-g-75 !border border-n-20 !font-rocGroteskMedium !px-4"
                                onClick={() => {
                                    setSuccessModal(false);
                                }}
                            />
                            <Button
                                btnText={"View purchase orders"}
                                btnClassname="!py-3 cursor-pointer !text-[14px] !text-g-75 !border border-n-20 !font-rocGroteskMedium !px-4"
                                onClick={() => {}}
                            />
                        </div>
                    </div>
                </div>
            </ModalContainer> */}
            <ModalContainer
                open={insufficientModal}
                modalClassName={"py-10 px-10"}
                tailwindClassName="w-[700px]"
                showCloseIcon={false}
                closeModal={() => {
                    setInsufficientModal(true);
                }}
            >
                <div className="bg-white rounded-[8px] relative font-rocGroteskMedium">
                    <div className="mt-4 ">
                        <div className="text-center flex justify-center flex-col items-center px-8 pt-10 mb-8">
                            <i className="ri-box-3-line text-[32px] mb-4 text-[#E4281D]"></i>

                            <div className="px-6 font-rocGroteskMedium">
                                <span className="block mb-2 text-center text-[22px]">
                                    Insufficient materials for production
                                </span>
                                <p className="mt-1 text-sm text-slate-500 text-center ">
                                    The current inventory levels for the following raw materials are
                                    insufficient to meet the production plan for Kōloa Kauaʻi Gold
                                    Rum. You can create a purchase order (PO) to fulfill these
                                    shortages and ensure production can proceed.
                                </p>
                            </div>
                        </div>
                        <div className="px-6 pb-8">
                            <CustomTable
                                tableBody={ModalTableBody}
                                tableHeader={ModalTableHeader}
                                isScrollable={false}
                                isCellBordered={true}
                                isCellSelectable={false}
                                isAllSelectable={false}
                                scrollWithin={false}
                                headerContainerClass=""
                                headerItemClass="font-rocGroteskMedium !text-[#334155] "
                                tableClass=""
                                bodyItemClass="hover:bg-transparent"
                            />
                        </div>
                        <div className="border-t border-t-slate-200 py-4 px-6 flex items-center space-x-4">
                            <Button
                                btnText={"Not now"}
                                btnClassname="!py-3 cursor-pointer !text-[14px] !bg-n-20 !text-g-75 !border border-n-20 !font-rocGroteskMedium !px-4"
                                onClick={() => {
                                    setInsufficientModal(false);
                                }}
                            />
                            <Button
                                btnText={"View purchase orders"}
                                btnClassname="!py-3 cursor-pointer !text-[14px] !text-g-75 !border border-n-20 !font-rocGroteskMedium !px-4"
                                onClick={() => {}}
                            />
                        </div>
                    </div>
                </div>
            </ModalContainer>

            {productionStepModal && (
                <ProductionSteps
                    productionStepModal={productionStepModal}
                    setProductionStepModal={setProductionStepModal}
                    productionSteps={productionSteps}
                    setProductionSteps={setProductionSteps}
                    step={step}
                    setStep={setStep}
                    stepIdx={stepIdx}
                    setStepIdx={setStepIdx}
                    handleSubmit={handleStepsSubmission}
                    isSaving={creatingPlanSteps || updatingPlanSteps}
                    productionId={productionId}
                />
            )}
            {/* <ModalContainer
                open={productionStepModal}
                modalClassName={"py-10 px-10"}
                tailwindClassName="w-[700px]"
                showCloseIcon={false}
                closeModal={() => {
                    setProductionStepModal(true);
                }}
            >
                <form
                    className="bg-white rounded-[8px] relative font-rocGroteskMedium"
                    onSubmit={(e) => {
                        e.preventDefault();
                        if (stepIdx >= 0) {
                            let copyArr = [...productionSteps];
                            copyArr[stepIdx] = step;
                            setProductionSteps(copyArr);
                        } else {
                            setProductionSteps((prev) => [...prev, step]);
                        }
                        setProductionStepModal(false);
                        setStepIdx(-1);
                        setStep({});
                    }}
                >
                    <div className="mt-4 ">
                        <div className="px-6 font-rocGroteskMedium border-b border-b-slate-200 flex items-center justify-between py-3">
                            <p className="mt-1 text-base text-gm-50 ">
                                {stepIdx >= 0 ? "Edit" : "Add"} step for production schedule
                            </p>
                            <i
                                className="ri-close-fill text-gm-50 cursor-pointer"
                                onClick={() => {
                                    setProductionStepModal(false);
                                    setStepIdx(-1);
                                }}
                            ></i>
                        </div>
                        <div className="px-6 pb-8 space-y-3 pt-3">
                            <TextInput
                                value={step.name}
                                name={"name"}
                                type={"text"}
                                onChange={(e) => handleStepData(e)}
                                placeholder={"Step name"}
                                required={true}
                                containerClassname=""
                            />

                            <TextareaInput
                                name={"description"}
                                value={step?.description}
                                onChange={handleStepData}
                                rows={5}
                                placeholder={"Description"}
                                required={true}
                            />
                            <div className="grid grid-cols-2 gap-3">
                                <TextInput
                                    value={step?.plannedStart}
                                    name={"plannedStart"}
                                    type={"datetime-local"}
                                    required={true}
                                    onChange={(e) => {
                                        handleStepData(e);
                                    }}
                                    inputPlaceholder={"Monday | 8:00am"}
                                    containerClassname=""
                                />
                                <TextInput
                                    value={step?.plannedEnd}
                                    name={"plannedEnd"}
                                    type={"datetime-local"}
                                    required={true}
                                    onChange={(e) => {
                                        handleStepData(e);
                                    }}
                                    inputPlaceholder={"Monday | 8:00am"}
                                    containerClassname=""
                                />
                            </div>

                            <TextInput
                                value={step.step}
                                name={"step"}
                                type={"number"}
                                onChange={(e) => handleStepData(e)}
                                placeholder={"Day"}
                                required={false}
                                containerClassname=""
                            />
                            <div className="flex gap-3">
                                <TextInput
                                    value={step?.amount}
                                    name={"amount"}
                                    type={"text"}
                                    onChange={(e) => handleStepData(e)}
                                    placeholder={"Quantity"}
                                    required={false}
                                    containerClassname=""
                                />
                                <SelectInput
                                    value={step?.unitOfMeasurement}
                                    name="unitOfMeasurement"
                                    placeholder={"UOM"}
                                    handleChange={(name, value) => {
                                        handleStepData({ target: { name, value } });
                                    }}
                                    isRequired={true}
                                    dropdownOptions={unitOfMeasurement}
                                />
                            </div>
                        </div>
                        <div
                            className={`border-t border-t-slate-200 py-4 px-6 flex items-center w-full    ${
                                stepIdx >= 0 ? "justify-between" : "justify-end"
                            } space-x-4`}
                        >
                            {stepIdx >= 0 && (
                                <Button
                                    btnText={"Delete step"}
                                    btnClassname="!py-3 cursor-pointer !text-[14px] !bg-[rgba(228,40,29,0.1)] !text-r-50 !border border-[#E4281D] !font-rocGroteskMedium !px-4 !w-fit"
                                    onClick={() => {
                                        const copyArr = [...productionSteps];
                                        copyArr.splice(stepIdx, 1);
                                        setProductionSteps(copyArr);
                                        setProductionStepModal(false);
                                    }}
                                    icon={<i className="ri-delete-bin-6-fill"></i>}
                                />
                            )}
                            <div className="flex items-center  justify-end gap-4">
                                <Button
                                    btnText={"Cancel"}
                                    btnClassname="!py-3 cursor-pointer !text-[14px] !bg-n-20 !text-gm-50 !border border-n-20 !font-rocGroteskMedium !px-4 !w-fit"
                                    onClick={() => {
                                        setProductionStepModal(false);
                                        setStepIdx(-1);
                                    }}
                                />
                                <Button
                                    btnText={`${stepIdx >= 0 ? "Edit" : "Add production step"}`}
                                    btnClassname="!py-3 cursor-pointer !text-[14px] !text-gm-50 !border border-n-20 !font-rocGroteskMedium !px-4 !w-fit"
                                    onClick={() => {
                                        if (stepIdx >= 0) {
                                            let copyArr = [...productionSteps];
                                            copyArr[stepIdx] = step;
                                            setProductionSteps(copyArr);
                                        } else {
                                            setProductionSteps((prev) => [...prev, step]);
                                        }
                                        setProductionStepModal(false);
                                        setStepIdx(-1);
                                        setStep({});
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </form>
            </ModalContainer> */}
        </div>
    );
};

export default CreateProductionPlan;
// unit, pcs, units, cartridges, rolls, each, box, lb;
