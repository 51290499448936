import LandingFooter from "components/views/Landing/LandingFooter";
import LandingNav from "components/views/Landing/LandingNav";
import React, { useEffect } from "react";
import { tableOfContent } from "variables/websiteData";

const Terms = () => {
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    }, []);

    return (
        <div className="bg-[#FFFBEB]">
            <LandingNav />
            <div className="flex justify-center">
                <div className=" w-[86%] ">
                    <div className="w-full pt-[112px] max-lg:pt-8 pb-10 h-full">
                        <div className="mb-[48px]  max-lg:mb-8">
                            <h2 className="text-[48px] max-sm:text-[32px] font-flame mb-1 text-y-10">
                                Terms and Conditions
                            </h2>
                            <span className="text-2xl max-sm:text-xl font-flame">
                                Last updated: 5th Mar. 2024
                            </span>
                        </div>
                        <div className="flex flex-col space-y-[48px] ">
                            {tableOfContent?.map(({ title, subtext }, idx) => {
                                return (
                                    <div key={title + idx} id={"section" + idx}>
                                        <div className="flex mb-3 items-center gap-3 font-flame">
                                            <p className={`text-lg  text-green-2`}>
                                                {idx + 1}. {title}
                                            </p>
                                        </div>
                                        <p
                                            dangerouslySetInnerHTML={{ __html: subtext }}
                                            className="text-base text-green-2 font-interDisplay"
                                        ></p>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
            <LandingFooter />
        </div>
    );
};

export default Terms;
