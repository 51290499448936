import config from "config/config";
import { authService } from "./authService";

export const chatService = {
    createConversation,
    getConversation,
    getAllConversations,
    sendChat,
    updateChatData,
};

async function createConversation(data: Record<string, any> | Array<Record<string, any>>) {
    const requestOptions = {
        method: "POST",
        body: JSON.stringify(data),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/chat/conversation`,
        requestOptions
    );
    return res;
}

async function getConversation(conversationId: string) {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/chat?conversationId=${conversationId}`,
        requestOptions
    );
    return res;
}

async function getAllConversations(limit?: number | string) {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/chat/conversations/co-pilot?limit=${limit}`,
        requestOptions
    );
    return res;
}

async function sendChat(data: Record<string, any> | Array<Record<string, any>>) {
    const requestOptions = {
        method: "POST",
        body: JSON.stringify(data),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/chat/send-ai`,
        requestOptions
    );
    return res;
}

async function updateChatData(data: Record<string, any> | Array<Record<string, any>>) {
    const requestOptions = {
        method: "PUT",
        body: JSON.stringify(data),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/chat/chat-data`,
        requestOptions
    );
    return res;
}
