import Button from "components/common/Button";
import CustomTable from "components/common/CustomTable";
import CustomToast from "components/common/CustomToast";
import DateInput from "components/common/DateInput";
import TextInput from "components/common/InputField/TextInput";
import ToggleInput from "components/common/InputField/ToggleInput";
import ModalContainer from "components/common/ModalContainer";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import { cloneDeep, set } from "lodash";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import { inventoryActions } from "redux/Ldb/actions";

const Settings = () => {
    const { productId } = useParams();

    const [forecastFactors, setForecastFactors] = useState<any>([
        {
            platform: "weather",
            active: false,
            keywords: [],
            _id: "1",
        },
        {
            platform: "tiktok",
            active: false,
            keywords: [],
            _id: "2",
        },
        // {
        //     platform: "instagram",
        //     active: false,
        //     keywords: [],
        //     _id: "3",
        // },
        // {
        //     platform: "facebook",
        //     active: false,
        //     keywords: [],
        //     _id: "4",
        // },
        // {
        //     platform: "google",
        //     active: false,
        //     keywords: [],
        //     _id: "5",
        // },
        {
            platform: "news",
            active: false,
            keywords: [],
            _id: "6",
        },
    ]);
    const [addKeywordModal, setAddKeywordModal] = useState<boolean>(false);
    const [changeForcastModal, setChangeForecastModal] = useState<boolean>(false);
    const [addEventModal, setAddEventModal] = useState<boolean>(false);
    const [deleteEventModal, setDeleteEventModal] = useState<boolean>(false);
    const [deleteKeywordModal, setDeleteKeywordModal] = useState<boolean>(false);
    const [openDate, setOpenDate] = useState<boolean>(false);
    const [selectedTrend, setSelectedTrend] = useState<any>({});
    const [newKeywords, setNewKeywords] = useState<string>("");
    const [forecastEvents, setForecastEvents] = useState<any>([]);
    const [selectedKeyword, setSelectedKeyword] = useState<any>({
        keyword: "",
        id: "",
    });
    const [eventData, setEventData] = useState<any>({
        eventName: "",
        salesQty: "",
        salesIncrease: "",
        eventTimeline: [],
        id: "",
    });

    const dispatch = useAppDispatch();
    const lastMessageRef = useRef(null);

    const {
        fetchingSingleInventory,
        fetchedSingleInventorySuccess,
        updatingSingleProduct,
        updatedSingleProductSuccess,
        addingForecastEvent,
        addForecastEventSuccess,
        updatingForecastEvent,
        updateForecastEventSuccess,
        deletingForecastEvent,
        deleteForecastEventSuccess,
    } = useAppSelector((state) => state.inventory);

    const title = forecastFactors
        ?.map((item) => item?.platform)
        ?.slice(0, forecastFactors?.length - 1)
        ?.join(", ");

    const tableHeader = [
        { title: "", widthClass: "w-[5%]" },
        { title: "Events name", widthClass: "w-[22.4%] " },
        { title: "Sales quantity", widthClass: "w-[15.3%] " },
        { title: "Events timeline", widthClass: "w-[26.6%] " },
        { title: "Expected increase in sales", widthClass: "w-[20%] " },
        { title: "", widthClass: "w-[5%]" },
        { title: "", widthClass: "w-[5%]" },
    ];

    const tableBody = forecastEvents?.map((data, idx) => {
        return [
            {
                content: (
                    <div className={`w-full border-slate-100 text-sm  py-[14px]`}>
                        <span className="material-symbols-outlined text-base">drag_indicator</span>
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`w-full h-full border-slate-100 text-sm font-rocGroteskMedium py-[14px] `}
                    >
                        <span>{data?.eventName}</span>
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`w-full h-full border-slate-100 text-sm font-rocGroteskMedium py-[14px] `}
                    >
                        <span>{data?.salesQuantity}</span>
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`w-full flex items-center h-full border-slate-100 text-sm font-rocGroteskMedium py-[14px]`}
                    >
                        <span>
                            {moment(data?.eventTimeline?.start)?.format("DD MMM, YYYY")} -{" "}
                            {moment(data?.eventTimeline?.end)?.format("DD MMM, YYYY")}
                        </span>
                    </div>
                ),

                // tableDataDivClass: `!pl-0`,
            },
            {
                content: (
                    <div
                        className={`w-full h-full border-slate-100 text-sm py-[14px] font-rocGroteskMedium `}
                    >
                        <span>{data?.expectedIncreaseInSales}%</span>
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`w-full h-full border-slate-100 text-sm py-[14px] font-rocGroteskMedium`}
                    >
                        <i
                            className="ri-edit-2-line text-base text-slate-500"
                            onClick={() => {
                                setEventData({
                                    eventName: data?.eventName,
                                    salesQty: data?.salesQuantity,
                                    salesIncrease: data?.expectedIncreaseInSales,
                                    eventTimeline: [
                                        data?.eventTimeline?.start,
                                        data?.eventTimeline?.end,
                                    ],
                                    id: data?._id,
                                });
                                setAddEventModal(true);
                            }}
                        ></i>
                    </div>
                ),
            },
            {
                content: (
                    <div
                        className={`w-full h-full border-slate-100 text-sm py-[14px] font-rocGroteskMedium`}
                    >
                        <i
                            className="ri-delete-bin-line text-base text-slate-500"
                            onClick={() => {
                                setEventData({
                                    eventName: data?.eventName,
                                    salesQty: data?.salesQuantity,
                                    salesIncrease: data?.expectedIncreaseInSales,
                                    eventTimeline: [
                                        data?.eventTimeline?.start,
                                        data?.eventTimeline?.end,
                                    ],
                                    id: data?._id,
                                });
                                setDeleteEventModal(true);
                            }}
                        ></i>
                    </div>
                ),
            },
        ];
    });

    const handleToggleTrend = (trendId: string, runUpdate?: boolean) => {
        const forecastFactorsCopy = [...forecastFactors];
        const trendIndex = forecastFactorsCopy.findIndex((item) => item?._id === trendId);
        forecastFactorsCopy[trendIndex].active = !forecastFactorsCopy[trendIndex].active;
        const data = forecastFactorsCopy?.map((item) => {
            return {
                platform: item?.platform,
                active: item?.active,
                keywords: item?.keywords,
            };
        });

        if (runUpdate) {
            dispatch(inventoryActions.updateSingleProduct({ forecastKeywords: data }, productId));
            return;
        } else {
            if (selectedTrend?._id) {
                setSelectedTrend(data[trendIndex]);
            }
            setForecastFactors(data);
            return;
        }
    };

    const handleEventChange = (name: string, value: string | Date[]) => {
        setEventData((prev) => ({ ...prev, [name]: value }));
    };

    const handleAddEvent = () => {
        const body = {
            inventoryId: productId,
            eventName: eventData?.eventName,
            salesQuantity: Number(eventData?.salesQty),
            eventTimeline: {
                start: moment(eventData?.eventTimeline[0])?.format("YYYY-MM-DD"), // minimum current date
                end: moment(eventData?.eventTimeline[1])?.format("YYYY-MM-DD"), // minimum current date
            },
            expectedIncreaseInSales: Number(eventData?.salesIncrease), // percentage
        };

        dispatch(inventoryActions.addForecastEvent(body));
    };

    const handleUpdateEvent = () => {
        const body = {
            // inventoryId: productId,
            eventName: eventData?.eventName,
            salesQuantity: Number(eventData?.salesQty),
            eventTimeline: {
                start: moment(eventData?.eventTimeline[0])?.format("YYYY-MM-DD"), // minimum current date
                end: moment(eventData?.eventTimeline[1])?.format("YYYY-MM-DD"), // minimum current date
            },
            expectedIncreaseInSales: Number(eventData?.salesIncrease), // percentage
        };

        dispatch(inventoryActions.updateForecastEvent(body, eventData?.id));
    };

    const handleRemoveKeyword = (keyword?: string, id?: string) => {
        const forecastFactorsCopy = [...forecastFactors];
        const trendIndex = forecastFactorsCopy.findIndex((item) => item?._id === id);
        forecastFactorsCopy[trendIndex].keywords = forecastFactorsCopy[trendIndex].keywords.filter(
            (item) => item !== keyword
        );

        const data = forecastFactorsCopy?.map((item) => {
            return {
                platform: item?.platform,
                active: item?.active,
                keywords: item?.keywords,
            };
        });

        dispatch(inventoryActions.updateSingleProduct({ forecastKeywords: data }, productId));
    };

    useEffect(() => {
        if (fetchedSingleInventorySuccess) {
            const keywordsData = [
                ...cloneDeep(fetchedSingleInventorySuccess?.inventory?.forecastKeywords || []),
            ];
            const eventsData = [
                ...cloneDeep(fetchedSingleInventorySuccess?.inventoryForecastEvents || []),
            ];

            const combinedKeywordsData = forecastFactors.map((item) => {
                const presentFactorIdx = keywordsData.findIndex(
                    (data) => data?.platform?.toLowerCase() === item?.platform?.toLowerCase()
                );

                if (presentFactorIdx !== -1) {
                    item.active = keywordsData[presentFactorIdx]?.active;
                    item.keywords = keywordsData[presentFactorIdx]?.keywords;
                }

                return item;
            });

            setForecastFactors(combinedKeywordsData);
            setForecastEvents(eventsData);
        }
    }, [fetchedSingleInventorySuccess]);

    useEffect(() => {
        if (addForecastEventSuccess || updateForecastEventSuccess) {
            toast.custom((t) => (
                <CustomToast
                    t={t}
                    message={
                        updateForecastEventSuccess
                            ? "Event updated successfully"
                            : "Event added successfully"
                    }
                    type={"success"}
                />
            ));

            if (updateForecastEventSuccess) {
                const events = [...forecastEvents]
                    .filter((item) => item?._id !== updateForecastEventSuccess?.updatedEvent?._id)
                    .concat({
                        eventName: updateForecastEventSuccess?.updatedEvent?.eventName,
                        salesQuantity: updateForecastEventSuccess?.updatedEvent?.salesQuantity,
                        eventTimeline: {
                            start: moment(
                                updateForecastEventSuccess?.updatedEvent?.eventTimeline?.start
                            )?.format("DD MMM, YYYY"),
                            end: moment(
                                updateForecastEventSuccess?.updatedEvent?.eventTimeline?.end
                            )?.format("DD MMM, YYYY"),
                        },
                        expectedIncreaseInSales:
                            updateForecastEventSuccess?.updatedEvent?.expectedIncreaseInSales,
                        _id: updateForecastEventSuccess?.updatedEvent?._id,
                    });

                setForecastEvents(events);
            }

            if (addForecastEventSuccess) {
                setForecastEvents((prev) => [...prev, addForecastEventSuccess?.forecastEvent]);
            }

            setAddEventModal(false);
            setEventData({
                eventName: "",
                salesQty: "",
                salesIncrease: "",
                eventTimeline: [],
                id: "",
            });
            dispatch(inventoryActions.resetAddForecastEvent());
            dispatch(inventoryActions.resetUpdateForecastEvent());
        }
    }, [addForecastEventSuccess, updateForecastEventSuccess]);

    useEffect(() => {
        if (deleteForecastEventSuccess) {
            toast.custom((t) => <CustomToast t={t} message={"Event deleted"} type={"success"} />);
            const events = [...forecastEvents].filter((item) => item?._id !== eventData?.id);
            setForecastEvents(events);
            setDeleteEventModal(false);
            setEventData({
                eventName: "",
                salesQty: "",
                salesIncrease: "",
                eventTimeline: [],
                id: "",
            });
            dispatch(inventoryActions.resetDeleteForecastEvent());
        }
    }, [deleteForecastEventSuccess, eventData?.id]);

    useEffect(() => {
        if (updatedSingleProductSuccess) {
            toast.custom((t) => (
                <CustomToast t={t} message={"Update successful"} type={"success"} />
            ));

            setChangeForecastModal(false);
            setSelectedTrend({});
            setAddKeywordModal(false);
            setNewKeywords("");
            setDeleteKeywordModal(false);
            setSelectedKeyword({
                keyword: "",
                id: "",
            });
            dispatch(inventoryActions.resetUpdateSingleProductSuccess());
        }
    }, [dispatch, updatedSingleProductSuccess, selectedTrend?._id, newKeywords]);

    useEffect(() => {
        if (lastMessageRef.current) {
            lastMessageRef.current.scrollIntoView({ behavior: "smooth" });
        }
    });

    return (
        <div>
            <div className="w-[73%]">
                {/* <div className="pb-10 mb-10 border-b border-slate-200">
                    <h5 className="text-lg font-rocGroteskMedium mb-6">Re-ordering method</h5>

                    <div className="">
                        <div className="mb-8">
                            <div className="flex items-center space-x-2 mb-2">
                                <i className={`ri-radio-button-fill text-lg text-y-10`}></i>
                                <span className="text-sm font-rocGroteskMedium">
                                    Automatic Re-order (Auto)
                                </span>
                            </div>

                            <p className="text-slate-500 font-rocGroteskMedium text-sm">
                                Synth dynamically determines the re-order point based on various
                                factors such as forecasted demand, supplier lead time, risk, product
                                shelf life, and inventory usage velocity, and automatically
                                calculates the quantity to be ordered, ensuring efficient inventory
                                management.
                            </p>
                        </div>

                        <div className="mb-8">
                            <div className="flex items-center space-x-2 mb-2">
                                <span className={`material-icons text-lg text-n-50`}>
                                    radio_button_unchecked
                                </span>
                                <span className="text-sm font-rocGroteskMedium">
                                    Fixed Re-order point with Min/Max Qty
                                </span>
                            </div>

                            <p className="text-slate-500 font-rocGroteskMedium text-sm mb-3">
                                You set the minimum stock level that triggers the reorder process
                                and define a range of quantities (minimum and maximum) to be ordered
                                each time, customizable at the product level.
                            </p>

                            <div className="flex justify-between items-center space-x-4">
                                <TextInput
                                    value={""}
                                    name={"minimumQty"}
                                    type={"number"}
                                    onWheel={(e: any) => e.target.blur()}
                                    onKeyDown={(evt) =>
                                        ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(
                                            evt.key
                                        ) && evt.preventDefault()
                                    }
                                    // onChange={handleChange}
                                    placeholder={"Minimum Qty"}
                                    required={false}
                                    containerClassname=""
                                />

                                <TextInput
                                    value={""}
                                    name={"qtyToBeOrdered"}
                                    type={"number"}
                                    onWheel={(e: any) => e.target.blur()}
                                    onKeyDown={(evt) =>
                                        ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(
                                            evt.key
                                        ) && evt.preventDefault()
                                    }
                                    // onChange={handleChange}
                                    placeholder={"Quantity to be ordered"}
                                    required={false}
                                    containerClassname=""
                                />
                            </div>
                        </div>

                        <div className="mb-8">
                            <div className="flex items-center space-x-2 mb-2">
                                <span className={`material-icons text-lg text-n-50`}>
                                    radio_button_unchecked
                                </span>
                                <span className="text-sm font-rocGroteskMedium">
                                    Fixed Re-order Point with EOQ (Economic Order Quantity)
                                </span>
                            </div>

                            <p className="text-slate-500 font-rocGroteskMedium text-sm mb-3">
                                Set the minimum stock level that initiates the reorder process, and
                                the system uses the standard EOQ formula to calculate the optimal
                                quantity to be ordered.
                            </p>

                            <div className="flex justify-between items-center space-x-4">
                                <TextInput
                                    value={""}
                                    name={"minimumQuantity"}
                                    type={"number"}
                                    onWheel={(e: any) => e.target.blur()}
                                    onKeyDown={(evt) =>
                                        ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(
                                            evt.key
                                        ) && evt.preventDefault()
                                    }
                                    // onChange={handleChange}
                                    placeholder={"Minimum Qty"}
                                    required={false}
                                    containerClassname=""
                                />

                                <TextInput
                                    value={""}
                                    name={"fixedQty"}
                                    type={"number"}
                                    onWheel={(e: any) => e.target.blur()}
                                    onKeyDown={(evt) =>
                                        ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(
                                            evt.key
                                        ) && evt.preventDefault()
                                    }
                                    // onChange={handleChange}
                                    placeholder={"Fixed Qty"}
                                    required={false}
                                    containerClassname=""
                                />
                            </div>
                        </div>

                        <div className="">
                            <div className="flex items-center space-x-2 mb-2">
                                <span className={`material-icons text-lg text-n-50`}>
                                    radio_button_unchecked
                                </span>
                                <span className="text-sm font-rocGroteskMedium">
                                    Fixed Re-order Point with EOQ (Economic Order Quantity)
                                </span>
                            </div>

                            <p className="text-slate-500 font-rocGroteskMedium text-sm mb-3">
                                Set the minimum stock level that initiates the reorder process, and
                                the system uses the standard EOQ formula to calculate the optimal
                                quantity to be ordered.
                            </p>

                            <div className="flex justify-between items-center">
                                <TextInput
                                    value={""}
                                    name={"minQty"}
                                    type={"number"}
                                    onWheel={(e: any) => e.target.blur()}
                                    onKeyDown={(evt) =>
                                        ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(
                                            evt.key
                                        ) && evt.preventDefault()
                                    }
                                    // onChange={handleChange}
                                    placeholder={"Minimum Qty"}
                                    required={false}
                                    containerClassname=""
                                />
                            </div>
                        </div>
                    </div>
                </div> */}

                <div className="pb-10 mb-10 border-b border-slate-200">
                    <div className="mb-6">
                        <h5 className="text-lg font-rocGroteskMedium ">Forecasting factors</h5>

                        <span className="text-base text-slate-500 font-rocGroteskMedium">
                            You can add keywords for the following factors.
                        </span>
                    </div>

                    <div>
                        {forecastFactors?.map((item: any, idx: number) => {
                            return (
                                <div
                                    key={item?.platform}
                                    className={`py-4 ${
                                        idx !== forecastFactors?.length - 1 && "border-b"
                                    } border-slate-200`}
                                >
                                    <div className="flex items-center justify-between w-full mb-2">
                                        <span className="text-base font-rocGroteskMedium capitalize">
                                            {item?.platform}
                                        </span>

                                        <ToggleInput
                                            checked={item?.active}
                                            name={"google trends"}
                                            onChange={(evt) => {
                                                setSelectedTrend(item);
                                                setChangeForecastModal(true);
                                            }}
                                        />
                                    </div>

                                    <div className="flex items-center space-x-2 flex-wrap">
                                        {item?.keywords?.map((keyword: any, index: number) => {
                                            return (
                                                <div
                                                    key={keyword + String(index)}
                                                    className="flex items-center capitalize h-6 rounded bg-slate-100 w-fit text-sm font-rocGroteskMedium border border-slate-200"
                                                >
                                                    <span className="pl-2 pr-1.5 py-[2px] border-r border-slate-200">
                                                        {keyword}
                                                    </span>
                                                    <div className="px-1.5">
                                                        <i
                                                            className="ri-close-fill cursor-pointer text-base"
                                                            onClick={() => {
                                                                setSelectedKeyword({
                                                                    keyword,
                                                                    id: item?._id,
                                                                });
                                                                setDeleteKeywordModal(true);
                                                            }}
                                                        ></i>
                                                    </div>
                                                </div>
                                            );
                                        })}

                                        {item?.platform?.toLowerCase() !== "weather" && (
                                            <div
                                                onClick={() => {
                                                    setSelectedTrend(item);
                                                    setAddKeywordModal(true);
                                                }}
                                                className="flex items-center space-x-1.5 px-2 py-[2px] h-6 rounded bg-[#DCFCE7] text-[#16A34A] w-fit text-sm font-rocGroteskMedium border border-[#16A34A] cursor-pointer"
                                            >
                                                <i className="ri-add-fill text-base mt-[-2px]"></i>
                                                <span className="text-sm">
                                                    Add{" "}
                                                    {item?.keywords?.length > 0 ? "more" : "new"}
                                                </span>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>

                <div className="pb-10 ">
                    <div className="mb-6">
                        <h5 className="text-lg font-rocGroteskMedium">Events</h5>
                        <span className="text-sm text-slate-500 font-rocGroteskMedium">
                            Create/add events that affect the products sales
                        </span>
                    </div>

                    {forecastEvents?.length > 0 && (
                        <div className="mb-6">
                            <CustomTable
                                tableBody={tableBody}
                                tableHeader={tableHeader}
                                isAllSelectable={false}
                                headerContainerClass="!bg-slate-50"
                                tableClass=" w-full"
                                isScrollable={false}
                                isCellBordered={false}
                            />
                        </div>
                    )}

                    <Button
                        btnText={forecastEvents?.length === 0 ? "Add new event" : "Add more"}
                        btnClassname="!w-fit !px-[14px] !py-2 !h-[40px]"
                        icon={<i className="ri-add-fill text-base"></i>}
                        onClick={() => setAddEventModal(true)}
                    />
                </div>
            </div>

            <ModalContainer
                open={false}
                showCloseIcon={false}
                tailwindClassName="w-[41%] max-lg:w-1/2 max-sm:w-[95%]"
                // closeModal={() => {
                //     setSourceVendor(false);
                // }}
            >
                <div className="bg-white rounded-lg shadow-cardShadow relative">
                    <div className="p-6">
                        <div className="mb-6">
                            <p className="text-lg mb-2 font-rocGroteskMedium">
                                Adjust re-ordering method?
                            </p>
                            <p className="text-base font-rocGroteskMedium text-slate-500">
                                Please be aware that adjusting the re-ordering method will only
                                impact this specific product and not all products.
                            </p>
                        </div>
                        <div className="flex justify-end space-x-2">
                            <Button
                                btnText="No, cancel"
                                btnClassname="!w-fit !px-4 !py-3 !h-12 !bg-n-20"
                            />

                            <Button
                                btnText="Update re-ordering method"
                                btnClassname="!w-fit !px-4 !py-3 !h-12"
                            />
                        </div>
                    </div>
                </div>
            </ModalContainer>

            <ModalContainer
                open={changeForcastModal}
                showCloseIcon={false}
                tailwindClassName="w-[41%] max-lg:w-1/2 max-sm:w-[95%]"
                closeModal={() => {
                    setSelectedTrend({});
                    setChangeForecastModal(false);
                }}
            >
                <div className="bg-white rounded-lg shadow-cardShadow relative">
                    <div className="p-6">
                        <div className="mb-6">
                            <p className="text-lg mb-2 font-rocGroteskMedium">
                                Adjust forecast settings for this product?
                            </p>
                            <p className="text-base font-rocGroteskMedium text-slate-500">
                                Please note that any changes made to forecast or event settings will
                                solely impact this specific product.
                            </p>
                        </div>
                        <div className="flex justify-end space-x-2">
                            <Button
                                btnText="No, cancel"
                                btnClassname="!w-fit !px-4 !py-3 !h-12 !bg-n-20"
                                onClick={() => {
                                    setSelectedTrend({});
                                    setChangeForecastModal(false);
                                }}
                            />

                            <Button
                                btnText="Adjust forecast for this product"
                                btnClassname="!w-fit !px-4 !py-3 !h-12"
                                isLoading={updatingSingleProduct}
                                onClick={() => handleToggleTrend(selectedTrend?._id, true)}
                            />
                        </div>
                    </div>
                </div>
            </ModalContainer>

            <ModalContainer
                open={addKeywordModal}
                showCloseIcon={false}
                tailwindClassName="w-[41%] max-lg:w-1/2 max-sm:w-[95%]"
                closeModal={() => {
                    setSelectedTrend({});
                    setAddKeywordModal(false);
                    setNewKeywords("");
                }}
            >
                <div className="bg-white rounded-lg shadow-cardShadow relative">
                    <div className="">
                        <div className="mb-6">
                            <div className="flex justify-between items-center py-4 px-6 border-b border-slate-200">
                                <p className="text-lg font-rocGroteskMedium">
                                    Add keywords for{" "}
                                    <span className="capitalize">{selectedTrend?.platform}</span>
                                </p>
                                <i
                                    onClick={() => {
                                        setSelectedTrend({});
                                        setAddKeywordModal(false);
                                        setNewKeywords("");
                                    }}
                                    className="ri-close-fill text-2xl cursor-pointer"
                                ></i>
                            </div>

                            <div className="p-6">
                                <div className="">
                                    <div className="flex items-center justify-between w-full mb-4">
                                        <span className="text-base font-rocGroteskMedium capitalize">
                                            {selectedTrend?.platform}
                                        </span>
                                        <ToggleInput
                                            checked={selectedTrend?.active}
                                            name={"trends"}
                                            onChange={() => {
                                                handleToggleTrend(selectedTrend?._id);
                                            }}
                                        />
                                    </div>
                                    <div
                                        onClick={() => {
                                            const elem = document.getElementById("addTrends");
                                            elem?.focus();
                                        }}
                                        className="border-[0.5px] border-[#0F846D] p-3 rounded min-h-[148px] max-h-[148px] overflow-y-auto relative"
                                    >
                                        <span className="mb-2 block text-sm font-rocGroteskMedium fixed">
                                            Include keywords that impact sales in{" "}
                                            {selectedTrend?.platform}.
                                        </span>

                                        <div className="flex items-start gap-2 flex-wrap mt-[30px]">
                                            {selectedTrend?.keywords?.map(
                                                (keyword: any, index: number) => {
                                                    return (
                                                        <div
                                                            key={keyword + String(index)}
                                                            className="px-2 py-[2px] capitalize h-6 rounded bg-slate-100 w-fit text-sm font-rocGroteskMedium border border-slate-200"
                                                        >
                                                            {keyword}
                                                        </div>
                                                    );
                                                }
                                            )}

                                            <input
                                                id="addTrends"
                                                type="text"
                                                value={newKeywords}
                                                placeholder="keyword1, keyword2..."
                                                onChange={(e) => {
                                                    setNewKeywords(e.target.value);
                                                }}
                                                className="font-rocGroteskMedium text-sm outline-none border-none w-fill-available"
                                            />

                                            <div ref={lastMessageRef}></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex justify-end space-x-2 px-6 py-4 border-t border-slate-200">
                            <Button
                                btnText="Cancel"
                                onClick={() => {
                                    setSelectedTrend({});
                                    setAddKeywordModal(false);
                                    setNewKeywords("");
                                }}
                                btnClassname="!w-fit !px-4 !py-3 !h-[48px] !bg-n-20"
                            />

                            <Button
                                btnText="Add keyword"
                                btnClassname="!w-fit !px-4 !py-3 !h-12"
                                isLoading={updatingSingleProduct}
                                onClick={() => {
                                    const forecastFactorsCopy = [...forecastFactors];
                                    const trendIndex = forecastFactorsCopy.findIndex(
                                        (item) => item?._id === selectedTrend?._id
                                    );
                                    forecastFactorsCopy[trendIndex].keywords = [
                                        ...forecastFactorsCopy[trendIndex].keywords,
                                        ...newKeywords?.split(",").map((item) => item.trim()),
                                    ];

                                    const data = forecastFactorsCopy?.map((item) => {
                                        return {
                                            platform: item?.platform,
                                            active: item?.active,
                                            keywords: item?.keywords,
                                        };
                                    });

                                    dispatch(
                                        inventoryActions.updateSingleProduct(
                                            { forecastKeywords: data },
                                            productId
                                        )
                                    );
                                }}
                            />
                        </div>
                    </div>
                </div>
            </ModalContainer>

            <ModalContainer
                open={addEventModal}
                showCloseIcon={false}
                tailwindClassName={`w-[41%] max-lg:w-1/2 max-sm:w-[95%] ${
                    openDate && "mt-[-18%]"
                } !overflow-visible`}
                closeModal={() => {
                    setAddEventModal(false);
                    setEventData({
                        eventName: "",
                        salesQty: "",
                        salesIncrease: "",
                        eventTimeline: [],
                        id: "",
                    });
                }}
            >
                <div className="bg-white rounded-lg shadow-cardShadow relative">
                    <form
                        onSubmit={(e) => {
                            e.preventDefault();

                            if (eventData?.id) {
                                handleUpdateEvent();
                            } else {
                                handleAddEvent();
                            }
                        }}
                        className=""
                    >
                        <div className="flex justify-between items-center py-4 px-6 border-b border-slate-200">
                            <p className="text-lg font-rocGroteskMedium">
                                {eventData?.id ? "Update event" : "Create /add events"} that affect
                                your products sales
                            </p>
                            <i
                                className="ri-close-fill text-2xl cursor-pointer"
                                onClick={() => {
                                    setAddEventModal(false);
                                    setEventData({
                                        eventName: "",
                                        salesQty: "",
                                        salesIncrease: "",
                                        eventTimeline: [],
                                        id: "",
                                    });
                                }}
                            ></i>
                        </div>

                        <div className="p-6 flex-col space-y-4">
                            <TextInput
                                value={eventData?.eventName}
                                name={"eventName"}
                                type={"text"}
                                onChange={(e) => handleEventChange(e.target.name, e.target.value)}
                                placeholder={"Event name"}
                                required={true}
                                containerClassname=""
                            />

                            <TextInput
                                value={eventData?.salesQty}
                                name={"salesQty"}
                                type={"number"}
                                onWheel={(e: any) => e.target.blur()}
                                onKeyDown={(evt) =>
                                    ["e", "E", "+", "-", ".", "ArrowUp", "ArrowDown"].includes(
                                        evt.key
                                    ) && evt.preventDefault()
                                }
                                onChange={(e) => handleEventChange(e.target.name, e.target.value)}
                                placeholder={"Sales quantity"}
                                required={true}
                                containerClassname=""
                            />

                            <TextInput
                                value={eventData?.salesIncrease}
                                name={"salesIncrease"}
                                type={"number"}
                                leftIcon={"%"}
                                onWheel={(e: any) => e.target.blur()}
                                onKeyDown={(evt) =>
                                    ["e", "E", "+", "-", ".", "ArrowUp", "ArrowDown"].includes(
                                        evt.key
                                    ) && evt.preventDefault()
                                }
                                onChange={(e) => handleEventChange(e.target.name, e.target.value)}
                                placeholder={"Expected sales increase due to the event"}
                                required={true}
                                containerClassname=""
                            />

                            <div>
                                <DateInput
                                    label="Time frame for this event?"
                                    value={eventData?.eventTimeline}
                                    doubleValue={true}
                                    onChange={(newDate) =>
                                        handleEventChange("eventTimeline", newDate as Date[])
                                    }
                                    showCalendar={openDate}
                                    showDoubleView={true}
                                    required={true}
                                    borderClassname="!rounded-[0px] !border-x-0"
                                    onCalendarToggle={(value) => setOpenDate(value)}
                                />
                            </div>
                        </div>

                        <div className="flex justify-end space-x-2 px-6 py-4 border-t border-slate-200">
                            <Button
                                btnText="Close"
                                btnClassname="!w-fit !px-4 !py-3 !h-[48px] !bg-n-20"
                                onClick={() => {
                                    setAddEventModal(false);
                                    setEventData({
                                        eventName: "",
                                        salesQty: "",
                                        salesIncrease: "",
                                        eventTimeline: [],
                                        id: "",
                                    });
                                }}
                                disabled={addingForecastEvent}
                            />

                            <Button
                                btnText={eventData?.id ? "Update" : "Add"}
                                type="submit"
                                btnClassname="!w-fit !px-6 !py-3 !h-12"
                                isLoading={addingForecastEvent || updatingForecastEvent}
                            />
                        </div>
                    </form>
                </div>
            </ModalContainer>

            <ModalContainer
                open={deleteEventModal}
                showCloseIcon={false}
                tailwindClassName="w-[41%] max-lg:w-1/2 max-sm:w-[95%]"
                closeModal={() => {
                    setDeleteEventModal(false);
                    setEventData({
                        eventName: "",
                        salesQty: "",
                        salesIncrease: "",
                        eventTimeline: [],
                        id: "",
                    });
                }}
            >
                <div className="bg-white rounded-lg shadow-cardShadow relative">
                    <div className="p-6">
                        <div className="mb-6">
                            <p className="text-lg mb-2 font-rocGroteskMedium">Delete Event?</p>
                            <p className="text-base font-rocGroteskMedium text-slate-500">
                                Are you sure you want to delete this event?
                            </p>
                        </div>
                        <div className="flex justify-end space-x-2">
                            <Button
                                btnText="No, cancel"
                                btnClassname="!w-fit !px-4 !py-3 !h-12 !bg-n-20"
                                disabled={deletingForecastEvent}
                                onClick={() => {
                                    setDeleteEventModal(false);
                                    setEventData({
                                        eventName: "",
                                        salesQty: "",
                                        salesIncrease: "",
                                        eventTimeline: [],
                                        id: "",
                                    });
                                }}
                            />

                            <Button
                                btnText="Yes, delete"
                                btnClassname="!w-fit !px-4 !py-3 !h-12 !bg-r-50 !text-white"
                                isLoading={deletingForecastEvent}
                                onClick={() => {
                                    dispatch(inventoryActions.deleteForecastEvent(eventData?.id));
                                }}
                            />
                        </div>
                    </div>
                </div>
            </ModalContainer>

            <ModalContainer
                open={deleteKeywordModal}
                showCloseIcon={false}
                tailwindClassName="w-[41%] max-lg:w-1/2 max-sm:w-[95%]"
                closeModal={() => {
                    setDeleteKeywordModal(false);
                    setSelectedKeyword({
                        keyword: "",
                        id: "",
                    });
                }}
            >
                <div className="bg-white rounded-lg shadow-cardShadow relative">
                    <div className="p-6">
                        <div className="mb-6">
                            <p className="text-lg mb-2 font-rocGroteskMedium">Delete Event?</p>
                            <p className="text-base font-rocGroteskMedium text-slate-500">
                                Are you sure you want to delete this keyword?
                            </p>
                        </div>
                        <div className="flex justify-end space-x-2">
                            <Button
                                btnText="No, cancel"
                                btnClassname="!w-fit !px-4 !py-3 !h-12 !bg-n-20"
                                disabled={updatingSingleProduct}
                                onClick={() => {
                                    setDeleteKeywordModal(false);
                                    setSelectedKeyword({
                                        keyword: "",
                                        id: "",
                                    });
                                }}
                            />

                            <Button
                                btnText="Yes, delete"
                                btnClassname="!w-fit !px-4 !py-3 !h-12 !bg-r-50 !text-white"
                                isLoading={updatingSingleProduct}
                                onClick={() => {
                                    handleRemoveKeyword(
                                        selectedKeyword?.keyword,
                                        selectedKeyword?.id
                                    );
                                }}
                            />
                        </div>
                    </div>
                </div>
            </ModalContainer>
        </div>
    );
};

export default Settings;
