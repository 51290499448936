import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {useAppSelector} from "../../../../../../../hooks/storeHooks";

interface PeopleTabProps {
    people: {[key: string]: any}[];
}

const PeopleTab = ({people}: PeopleTabProps) => {
    const [profile, setProfile] = useState<{[key: string]: any}>({});
    const {fetchUserProfileSuccess} = useAppSelector((state) => state.auth);

    useEffect(() => {
        if (Boolean(fetchUserProfileSuccess)) {
            setProfile(fetchUserProfileSuccess);
        }
    }, [fetchUserProfileSuccess]);

    return (
        <div className="">
            <p className="text-base text-slate-700 font-rocGroteskMedium mb-3 px-6 pt-6">People</p>

            <div>
                <div className="py-3 p-6 flex items-center justify-between border-b border-slate-100">
                    <div className="flex items-center gap-3">
                        <div className="w-9 h-9 rounded-full bg-n-20 flex items-center justify-center">
                            <i className="ri-user-line before:content-['\f264'] text-slate-500 text-xl"></i>
                        </div>
                        <p className="text-sm font-rocGroteskMedium text-slate-700">
                            {profile?.email}
                        </p>
                    </div>
                </div>
                {people?.map((person, idx) => (
                    <div
                        key={idx}
                        className="py-3 p-6 flex items-center justify-between border-b border-slate-100"
                    >
                        <div className="flex items-center gap-3">
                            <div className="w-9 h-9 rounded-full bg-n-20 flex items-center justify-center">
                                <i className="ri-user-line before:content-['\f264'] text-slate-500 text-xl"></i>
                            </div>
                            <p className="text-sm font-rocGroteskMedium text-slate-700">
                                {person?.email}
                            </p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default PeopleTab;
