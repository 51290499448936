import Button from "components/common/Button";
import Navbar from "components/common/Navbar/Navbar";
import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {authActions} from "redux/Ldb/actions";
import {useAppDispatch, useAppSelector} from "../../../hooks/storeHooks";

const SignupSuccess = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [param] = useSearchParams();
    const {token} = useParams();
    const resend = param.get("resend");
    const {resendingVerificationCode} = useAppSelector((state) => state.auth);

    const handleResendCode = () => {
        dispatch(authActions.resendVerifyCode(true, token));
    };

    useEffect(() => {
        if (resend && token) {
            dispatch(authActions.resendVerifyCode(false, token));
        }
    }, [resend, token, dispatch]);

    return (
        <div className="w-full h-screen bg-body-1">
            <Navbar />
            <div className="h-[calc(100vh-84px)] w-full flex items-center justify-center">
                <div className="max-w-[420px] max-lg:max-w-[90%] bg-white py-10 px-14 flex flex-col items-center rounded-[8px] shadow-cardShadow-2">
                    <div className="text-center w-full">
                        <div className="mb-8 mt-5 flex justify-center">
                            <img
                                src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1692879624/Tradeally_2.0/verifyEmail_gqbf3f.svg"
                                alt="add business info"
                                width={120}
                                height={118}
                            />
                        </div>
                        <div className="mb-8 text-center">
                            <p className="text-xl font-rocGroteskBold text-gm-50 mb-1">
                                Verify your email address
                            </p>
                            <p className="text-sm font-rocGroteskRegular text-gm-50">
                                We just sent a verification link to your email address. Kindly click
                                it to verify your account.
                            </p>
                        </div>

                        <div>
                            <Button
                                btnClassname={
                                    "!bg-white !py-3 !text-gm-50 border !border-n-20  !font-rocGroteskMedium"
                                }
                                btnText={"Resend link "}
                                onClick={handleResendCode}
                                type={"button"}
                                isLoading={resendingVerificationCode}
                            />
                            <Button
                                btnClassname={
                                    "!bg-transparent mt-3 !text-slate-500  !font-rocGroteskMedium"
                                }
                                btnText={"Change email"}
                                onClick={() => navigate(`/change-email/${token}`)}
                                type={"button"}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SignupSuccess;
