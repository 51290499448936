import LandingFooter from "components/views/Landing/LandingFooter";
import LandingNav from "components/views/Landing/LandingNav";
import React, { useEffect } from "react";

const Policy = () => {
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    }, []);
    return (
        <div className="bg-[#FFFBEB]">
            <LandingNav />
            <div className="flex justify-center w-full">
                <div className="pt-[104px] max-lg:pt-8 w-[86%] pb-10 ">
                    <div className="mb-[48px] max-lg:mb-8">
                        <h2 className="text-[48px] max-sm:text-[32px] font-flame mb-1 text-y-10">
                            Policy
                        </h2>
                        <span className="text-2xl max-sm:text-xl font-flame">
                            Last updated: 5th Mar. 2024
                        </span>
                    </div>

                    <div className="flex flex-col space-y-[48px]">
                        <div>
                            <h3 className="text-lg font-flame mb-3">Introduction</h3>
                            <p className="text-green-2 text-sm font-interDisplay">
                                Welcome to SynthAlly Inc, we are committed to protecting your
                                privacy and ensuring the security of your personal and business
                                information. This Privacy Policy explains how we collect, use,
                                disclose, and safeguard your data when you use our AI-powered supply
                                chain solution.
                            </p>
                        </div>
                        <div>
                            <h3 className="text-lg font-flame mb-3 flex gap-3 items-center">
                                Information we collect
                            </h3>
                            <ul className="list-disc list-outside pl-3.5 text-green-2 text-sm font-interDisplay">
                                <li>
                                    User Information: When you sign up for our service, we may
                                    collect personal information such as your name, email address,
                                    company name, and contact details.
                                </li>
                                <li>
                                    Business Documents: Our product may collect and store business
                                    documents you upload, including but not limited to purchase
                                    orders (POs), invoices, receipts, and other supply chain-related
                                    documents.
                                </li>
                                <li>
                                    AI Data: To provide AI-powered features, our system reads and
                                    processes emails, social channels, and other communication
                                    channels for the purpose of extracting supply chain-related
                                    information, such as orders, requests for quotation (RFQs), and
                                    purchase orders (POs).
                                </li>
                                <li>
                                    Usage Data: We may collect data on how you use our product, such
                                    as feature usage, time spent on the platform, and interactions
                                    with AI-generated recommendations.
                                </li>
                            </ul>
                        </div>
                        <div>
                            <h3 className="text-lg font-flame mb-3 flex gap-3 items-center">
                                How we use your Information
                            </h3>
                            <ul className="list-disc list-outside pl-3.5 text-green-2 text-sm font-interDisplay">
                                <li>
                                    We use the collected data to improve our AI-powered supply chain
                                    ERP solution for your specific business needs.
                                </li>
                                <li>
                                    To extract and process supply chain-related data from emails,
                                    social channels, and other communication sources.
                                </li>
                                <li>
                                    To offer personalized experiences, including AI-generated
                                    recommendations and insights.
                                </li>
                                <li>
                                    To communicate with you, respond to inquiries, and provide
                                    customer support.
                                </li>
                                <li>To meet legal and regulatory requirements.</li>
                            </ul>
                        </div>
                        <div>
                            <h3 className="text-lg font-flame mb-3 flex gap-3 items-center">
                                Data sharing and Disclosure
                            </h3>
                            <p className="text-green-2 text-sm font-interDisplay">
                                We do not share your personal or business data with third parties,
                                except in the following circumstances:
                            </p>
                            <ul className="list-disc list-outside pl-3.5 text-green-2 text-sm font-interDisplay">
                                <li>
                                    We use the collected data to improve our AI-powered supply chain
                                    ERP solution for your specific business needs.
                                </li>
                                <li>
                                    To extract and process supply chain-related data from emails,
                                    social channels, and other communication sources.
                                </li>
                                <li>
                                    To offer personalized experiences, including AI-generated
                                    recommendations and insights.
                                </li>
                                <li>
                                    To communicate with you, respond to inquiries, and provide
                                    customer support.
                                </li>
                                <li>To meet legal and regulatory requirements.</li>
                            </ul>
                        </div>
                        <div>
                            <h3 className="text-lg font-flame mb-3 flex gap-3 items-center">
                                Third Party Privacy
                            </h3>
                            <p className="text-green-2 text-sm font-interDisplay">
                                Our product may integrate with third-party services or platforms.
                                Your use of such third-party services is subject to their respective
                                privacy policies. We are not responsible for the privacy practices
                                of these third parties.
                            </p>
                        </div>
                        <div>
                            <h3 className="text-lg font-flame mb-3 flex gap-3 items-center">
                                Cookies and Web Beacons
                            </h3>
                            <p className="text-green-2 text-sm font-interDisplay">
                                We use cookies and web beacons to collect and store information
                                about your usage of our product. This helps us understand user
                                behavior, improve user experience, and tailor content to your
                                preferences. You can manage cookie preferences through your browser
                                settings.
                            </p>
                        </div>
                        <div>
                            <h3 className="text-lg font-flame mb-3 flex gap-3 items-center">
                                Log Files
                            </h3>
                            <p className="text-green-2 text-sm font-interDisplay">
                                Our system collects log files that include IP addresses, browser
                                types, referring pages, operating systems, and timestamps. These
                                logs are used for system administration, analyzing trends, and
                                troubleshooting issues.
                            </p>
                        </div>
                        <div>
                            <h3 className="text-lg font-flame mb-3 flex gap-3 items-center">
                                Data Security
                            </h3>
                            <p className="text-green-2 text-sm font-interDisplay">
                                We employ industry-standard security measures to protect your data.
                                However, no data transmission or storage system can be guaranteed to
                                be 100% secure. If you have reason to believe your interaction with
                                us is no longer secure, please notify us immediately.
                            </p>
                        </div>
                        <div>
                            <h3 className="text-lg font-flame mb-3 flex gap-3 items-center">
                                Your Choice and Rights
                            </h3>
                            <p className="text-green-2 text-sm font-interDisplay">
                                We may update this Privacy Policy to reflect changes in our
                                practices and services. We encourage you to review this policy
                                periodically to stay informed about how we are protecting your data.
                            </p>
                        </div>
                        <div>
                            <h3 className="text-lg font-flame mb-3 flex gap-3 items-center">
                                Changes in Privacy Policy
                            </h3>
                            <p className="text-green-2 text-sm font-interDisplay">
                                We may update this Privacy Policy to reflect changes in our
                                practices and services. We encourage you to review this policy
                                periodically to stay informed about how we are protecting your data.
                            </p>
                        </div>
                        <div>
                            <h3 className="text-lg font-flame mb-3 flex gap-3 items-center">
                                Contact Us
                            </h3>
                            <p className="text-green-2 text-sm font-interDisplay">
                                If you have any questions, concerns, or requests regarding this
                                Privacy Policy, please contact us at{" "}
                                <a
                                    href="mailto:hello@synthally.com"
                                    className="text-orange-1 underline"
                                >
                                    hello@synthally.com
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <LandingFooter />
        </div>
    );
};

export default Policy;
