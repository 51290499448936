import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../storeHooks";
import { trackTraceActions } from "redux/Ldb/actions";
import { useParams } from "react-router-dom";
import useInfiniteScroll from "hooks/useInfinteScroll";

const useGetTrackTraceBatchDistributors = () => {
    const { id } = useParams();
    const dispatch = useAppDispatch();
    const [batchDistributors, setBatchDistributors] = useState<{ [key: string]: any }>([]);
    const [state, setState] = useState<string>("");
    const [pagination, setPagination] = useState<{ currentPage: string; noOfPages: string }>({
        currentPage: "1",
        noOfPages: "",
    });

    const { updatedLimit, isLoadingMore, setLoadMore } = useInfiniteScroll(
        { current: pagination.currentPage, number_of_pages: pagination.noOfPages },
        10
    );

    const {
        fetchingBatchDistributors,
        fetchBatchDistributorsSuccess,
        fetchBatchDistributorsFailure,
    } = useAppSelector((state) => state.trackTrace);

    const handleStateChange = (value) => {
        setState(value);
    };

    useEffect(() => {
        if (Boolean(fetchBatchDistributorsSuccess)) {
            setBatchDistributors(fetchBatchDistributorsSuccess?.sales);
            setPagination({
                currentPage: fetchBatchDistributorsSuccess.pagination?.current,
                noOfPages: fetchBatchDistributorsSuccess.pagination?.number_of_pages,
            });
            setLoadMore(false);
        }
    }, [fetchBatchDistributorsSuccess]);

    useEffect(() => {
        if (id && state) {
            dispatch(trackTraceActions.getBatchDistributors(state, id, updatedLimit));
        }
    }, [dispatch, id, state, updatedLimit]);

    return {
        data: batchDistributors,
        isFetching: fetchingBatchDistributors,
        error: fetchBatchDistributorsFailure,
        handleStateChange,
        state,
        isLoadingMore,
    };
};

export default useGetTrackTraceBatchDistributors;
