import ActivityCard from "components/common/ActivityCard";
import moment from "moment";
import React from "react";

type ActivityTabProps = {
    activities: {[key: string]: any}[];
    className?: string;
    topClassName?: string;
    showNoContent?: boolean;
    subContentClasName?: string;
    content?: string;
    subContent?: string;
    contentClass?: string;
};

const ActivityTab = ({
    activities,
    className = "px-6",
    topClassName = "px-5",
    showNoContent,
    subContentClasName,
    content = "Activity",
    subContent = "July 2023",
    contentClass,
}: ActivityTabProps) => {
    return activities && activities?.length > 0 ? (
        <div>
            {!showNoContent && (
                <p className="text-base mt-5 px-6 text-slate-700 font-rocGroteskMedium mb-4">
                    {content}
                </p>
            )}
            <div className={`flex  w-full items-center gap-2 mb-2 ${topClassName}`}>
                <p
                    className={`text-sm font-rocGroteskMedium whitespace-nowrap  ${subContentClasName} text-slate-500`}
                >
                    {subContent}
                </p>
                <hr className="w-[90%] text-slate-100 hidden md:block" />
            </div>

            <div className={`overflow-auto ${contentClass}`}>
                {activities?.map((activiy, idx) => (
                    <div key={idx} className={`py-3 ${className}`}>
                        <div>
                            <ActivityCard
                                activityTitle={activiy?.title}
                                datetime={activiy?.dateTime}
                                containerNo={activiy?.containerNumber}
                                className={"!py-1"}
                                icon={activiy?.icon}
                                iconContainerClass={activiy?.iconContainerClass}
                            />
                            {/* <ActivityCard
                            activityTitle={"You have a new message"}
                            datetime={"Apr 17, 2023 at 2:01 AM EDT"}
                            icon={
                                <i className="ri-flag-fill before:content-['\ed3a'] text-slate-500"></i>
                            }
                        /> */}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    ) : (
        <div className={`mt-[111px] flex justify-center`}>
            <div className="text-center w-[35%]">
                <i className="ri-flag-line text-5xl text-slate-500 "></i>
                <p className="text-base font-rocGroteskMedium mb-1 mt-4">No Activities</p>
                <p className="text-sm font-rocGroteskRegular text-slate-500">
                    It seems like there are no activities at this time.
                </p>
            </div>
        </div>
    );
};

export default ActivityTab;
