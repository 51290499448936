import Dropdown from "components/common/Dropdown";
import EmptyStateComp from "components/common/EmptyStateComp";
import Loader from "components/common/Loader";
import VendorAvatar from "components/common/VendorAvatar";
import { determineRiskStatus } from "helpers/determineRiskStatus";
import useGetEvents from "hooks/inventoryHooks/useGetEvents";
import { set, truncate } from "lodash";
import moment from "moment";
import React, { useState } from "react";
import { useParams } from "react-router-dom";

type MarketInsightProps = {
    productName: string;
    isModalShow?: boolean;
};

const MarketInsight = ({ productName, isModalShow }: MarketInsightProps) => {
    const [eventCategory, setEventCategory] = useState<string>("all");
    const [eventImpact, setEventImpact] = useState<string>("all");
  const {productId} = useParams()
 
    const {
        data: newsData,
        isFetching,
        pagination,
        isLoadingMore,
        setUpdatedPage,
    } = useGetEvents(productId, productName, eventCategory, eventImpact);
    return (
        <div className="pb-6">
            <div className="mb-8">
                <div className="flex items-center space-x-2.5 mb-4">
                    <i className="ri-fire-fill text-xl text-[#FF0000]"></i>
                    <h5 className="text-xl font-rocGroteskMedium text-g-75">Happening now</h5>
                </div>

                {newsData.length > 0 ? (
                    <div
                        className={` w-full ${
                            !isModalShow ? "overflow-x-auto flex " : "grid grid-cols-4 "
                        }  space-x-3`}
                    >
                        {newsData?.slice(0, 4)?.map((data) => {
                            return (
                                <a
                                    key={data?._id}
                                    href={data?.url ? data?.url : data?.video ? data?.video : "#"}
                                    target={data?.url || data?.video ? "_blank" : "_self"}
                                    className={`${
                                        !isModalShow ? "w-[260px] flex-shrink-0" : "w-full"
                                    }`}
                                >
                                    <div className="p-4 h-full  flex flex-col justify-between rounded-[6px] border border-slate-200 shadow-boxShadow-12">
                                        <span
                                            dangerouslySetInnerHTML={{
                                                __html: truncate(data?.title || data?.description, {
                                                    length: 75,
                                                }),
                                            }}
                                            className="text-base font-rocGroteskMedium text-g-75 leading-[24px]"
                                        ></span>
                                        <div className="flex items-center justify-between mt-1">
                                            <div className="flex items-center space-x-1.5">
                                                <span className="w-5 h-5 flex items-center justify-center rounded-full border border-slate-100">
                                                    <i className="ri-cloud-fill text-xs text-[#56B648]"></i>
                                                </span>
                                                <span className="text-xs font-rocGroteskMedium text-slate-500">
                                                    {data?.newsId
                                                        ? "News"
                                                        : data?.temp
                                                        ? "Weather"
                                                        : "Social Media"}
                                                </span>
                                            </div>
                                            {/* <span className="text-xs font-rocGroteskMedium text-slate-500">
                                        1
                                    </span> */}
                                        </div>
                                    </div>
                                </a>
                            );
                        })}
                    </div>
                ) : (
                    <EmptyStateComp
                        title="No market insights available"
                        description="Currently, there are no market insights to display. Check back later for the latest updates and trends."
                        icon={
                            <i className="ri-bar-chart-grouped-fill text-slate-500 text-[40px]"></i>
                        }
                    />
                )}
            </div>

            <div>
                <div className="mb-8">
                    <h5 className="text-xl font-rocGroteskMedium text-g-75 mb-4">
                        Real-time events feed
                    </h5>
                    <div className="flex items-center justify-between max-sm:flex-col max-sm:space-y-2 max-sm:items-start mb-4">
                        <div className="flex items-center space-x-3 max-sm:flex-wrap max-sm:gap-3 max-sm:space-x-0">
                            <span
                                onClick={() => {
                                    setUpdatedPage(1);
                                    setEventCategory("all");
                                }}
                                className={`text-[13px] font-rocGroteskMedium px-4 py-2.5 rounded border border-slate-200 cursor-pointer hover:text-white hover:bg-g-75 ${
                                    eventCategory === "all" && "bg-g-75 text-white"
                                }`}
                            >
                                All events
                            </span>
                            <span
                                onClick={() => {
                                    setUpdatedPage(1);
                                    setEventCategory("news");
                                }}
                                className={`text-[13px] font-rocGroteskMedium px-4 py-2.5 rounded border border-slate-200 cursor-pointer hover:text-white hover:bg-g-75 ${
                                    eventCategory === "news" && "bg-g-75 text-white"
                                }`}
                            >
                                News
                            </span>
                            <span
                                onClick={() => {
                                    setUpdatedPage(1);
                                    setEventCategory("social");
                                }}
                                className={`text-[13px] font-rocGroteskMedium px-4 py-2.5 rounded border border-slate-200 cursor-pointer hover:text-white hover:bg-g-75 ${
                                    eventCategory === "social" && "bg-g-75 text-white"
                                }`}
                            >
                                Social media
                            </span>
                            <span
                                onClick={() => {
                                    setUpdatedPage(1);
                                    setEventCategory("weather");
                                }}
                                className={`text-[13px] font-rocGroteskMedium px-4 py-2.5 rounded border border-slate-200 cursor-pointer hover:text-white hover:bg-g-75 ${
                                    eventCategory === "weather" && "bg-g-75 text-white"
                                }`}
                            >
                                Weather
                            </span>
                        </div>

                        <Dropdown
                            handleChange={(name, value) => {
                                setUpdatedPage(1);
                                setEventImpact(value);
                            }}
                            dropdown={
                                <div className="flex items-center whitespace-nowrap h-[40px] py-2 px-4 space-x-1 rounded-[5px] border border-slate-200">
                                    <span className={`text-g-75 font-rocGroteskMedium text-sm`}>
                                        {eventImpact && eventImpact !== "all"
                                            ? eventImpact[0].toUpperCase() +
                                              eventImpact.slice(1) +
                                              " Impact"
                                            : "Sort by relevance"}
                                    </span>
                                    <i className="ri-arrow-down-s-line text-g-75"></i>
                                </div>
                            }
                            dropdownContainerClasses={`shadow-cardShadow-4 border border-slate-100  rounded !max-h-fit`}
                            dropdownOptions={[
                                {
                                    label: "All",
                                    value: "all",
                                },
                                {
                                    label: "High Impact",
                                    value: "high",
                                },
                                {
                                    label: "Medium Impact",
                                    value: "medium",
                                },
                                {
                                    label: "Low Impact",
                                    value: "low",
                                },
                            ]}
                        />
                    </div>
                </div>

                <div>
                    {newsData.slice(4).length > 0 ? (
                        <div className="space-y-4">
                            {newsData.slice(4)?.map((data, idx) => {
                                return (
                                    <div
                                        key={data?._id}
                                        className="rounded-[10px] border border-slate-100 "
                                    >
                                        <div className="flex items-center justify-between space-x-[64px] py-6 pr-6 pl-1.5">
                                            <div className="flex items-start">
                                                <span className="text-xl font-rocGroteskMedium py-3 px-[17.5px]">
                                                    {idx + 1}
                                                </span>
                                                <div>
                                                    <div className="flex items-center space-x-1.5 mb-3">
                                                        <span className="w-6 h-6 flex items-center justify-center rounded-full border border-slate-100">
                                                            <i className="ri-cloud-fill text-sm text-[#56B648]"></i>
                                                        </span>
                                                        <span className="text-xs font-rocGroteskMedium text-slate-500">
                                                            Source:{" "}
                                                            {data?.newsId
                                                                ? "News"
                                                                : data?.temp
                                                                ? "Weather"
                                                                : "Social Media"}
                                                        </span>
                                                    </div>

                                                    <div>
                                                        <div className="flex items-center space-x-1 mb-1">
                                                            <a
                                                                href={
                                                                    data?.url
                                                                        ? data?.url
                                                                        : data?.video
                                                                        ? data?.video
                                                                        : "#"
                                                                }
                                                                target={
                                                                    data?.url || data?.video
                                                                        ? "_blank"
                                                                        : "_self"
                                                                }
                                                            >
                                                                <span
                                                                    dangerouslySetInnerHTML={{
                                                                        __html: truncate(
                                                                            data?.title ||
                                                                                data?.description,
                                                                            {
                                                                                length: 86,
                                                                            }
                                                                        ),
                                                                    }}
                                                                    className="text-lg underline font-rocGroteskMedium text-g-75"
                                                                ></span>
                                                            </a>

                                                            <i className="ri-arrow-down-s-line text-2xl text-g-75"></i>
                                                        </div>
                                                        <p className="text-sm font-rocGroteskMedium text-slate-500 mb-3">
                                                            {truncate(data?.description, {
                                                                length: 420,
                                                            })}
                                                        </p>
                                                        <div className="flex items-center space-x-1.5 mb-4">
                                                            <span className="text-sm font-rocGroteskMedium text-slate-500">
                                                                {data?.author ||
                                                                    data?.authorNickname}{" "}
                                                                |{" "}
                                                                {moment(data?.date)?.format(
                                                                    "DD MMM YYYY"
                                                                )}{" "}
                                                                | {moment(data?.date)?.fromNow()}
                                                            </span>
                                                        </div>

                                                        <div className="flex items-center space-x-1.5 bg-slate-50 border border-slate-200 rounded py-1 px-3 h-[28px] w-fit">
                                                            <i className="ri-bar-chart-2-fill text-base text-slate-500 "></i>
                                                            <span className="text-sm text-slate-500 font-rocGroteskMedium">
                                                                {
                                                                    determineRiskStatus(
                                                                        data?.buyingSentiment
                                                                    ).value
                                                                }{" "}
                                                                Impact
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            {data?.image && (
                                                <img
                                                    src={data?.image}
                                                    alt={
                                                        data?.newsId
                                                            ? "News"
                                                            : data?.temp
                                                            ? "Weather"
                                                            : "Social Media"
                                                    }
                                                    className="rounded-[8px] w-[195px] h-[126px] object-cover bg-amber-300 shrink-0"
                                                />
                                            )}

                                            {data?.video && (
                                                <video
                                                    src={data?.video}
                                                    playsInline
                                                    controls={true}
                                                    autoPlay={false}
                                                    controlsList="nodownload"
                                                    className="rounded-[8px] w-[295px] h-[226px] object-contain bg-amber-300 shrink-0"
                                                />
                                            )}
                                        </div>

                                        {/* <div className="border-t border-slate-100 rounded-b-[10px] p-6 bg-[#F0FDF4]">
                                        <span className="text-base font-rocGroteskMedium">
                                            Related News
                                        </span>
                                        <div className="grid grid-cols-4 space-x-3 mt-4">
                                            {[1, 2, 3, 4]?.map((_, idx) => {
                                                return (
                                                    <div
                                                        key={idx}
                                                        className="p-4 rounded-[6px] bg-white border border-slate-200 shadow-boxShadow-12"
                                                    >
                                                        <div className="mb-1 flex items-center space-x-1">
                                                            <VendorAvatar
                                                                size={16}
                                                                name="amazon"
                                                                textClassname="!text-[8px]"
                                                            />
                                                            <span className="text-xs font-rocGroteskMedium text-slate-500">
                                                                amazon.com
                                                            </span>
                                                        </div>
                                                        <span className="text-base font-rocGroteskMedium text-g-75 leading-[24px]">
                                                            Retail Universe for U.S. Premium Natural
                                                            Organic Food, Supple...
                                                        </span>
                                                        <div className="flex items-center justify-between mt-1">
                                                            <div className="flex items-center space-x-1.5">
                                                                <span className="w-5 h-5 flex items-center justify-center rounded-full border border-slate-100">
                                                                    <i className="ri-cloud-fill text-xs text-[#56B648]"></i>
                                                                </span>
                                                                <span className="text-xs font-rocGroteskMedium text-slate-500">
                                                                    Weather
                                                                </span>
                                                            </div>
                                                            <span className="text-xs font-rocGroteskMedium text-slate-500">
                                                                1
                                                            </span>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div> */}
                                    </div>
                                );
                            })}

                            {isFetching && isLoadingMore && (
                                <div className="flex justify-center my-4">
                                    <Loader color="gm-25" size={4} />
                                </div>
                            )}
                            {pagination?.currentPage === pagination?.noOfPages && (
                                <div className="flex my-4 justify-center text-slate-500 text-sm font-rocGroteskMedium">
                                    End of list
                                </div>
                            )}
                        </div>
                    ) : (
                        <EmptyStateComp
                            title="No market insights available"
                            description="Currently, there are no market insights to display. Check back later for the latest updates and trends."
                            icon={
                                <i className="ri-bar-chart-grouped-fill text-slate-500 text-[40px]"></i>
                            }
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default MarketInsight;
