export const AiNameSuggestions = [
    "Astra",
    "Nexus",
    "Vela",
    "Orion",
    "Solara",
    "Vega",
    "Nova",
    "Quanta",
    "Verity",
    "Solace",
    "Helix",
    "Aria",
    "Atlas",
    "Lumina",
];

export const aiAvatarArr = [
    "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1729265371/AI_Teammate_1_ure13x.svg",
    "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1729265877/ai_avatar_deep_purple_qakuk8.svg",
    "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1729265877/ai_avatar_black_rldsfi.svg",
    "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1729265877/ai_avatar_lemon_ozcz6g.svg",
    "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1729265877/ai_avatar_blue_nk658e.svg",
    "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1729265877/ai_avatar_green_omidbc.svg",
    "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1729265877/ai_avatar_light_blue_ka09qw.svg",
    "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1729265879/ai_avatar_purple_cq0vhm.svg",
    "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1729265878/ai_avatar_light_maroon_tnxbvr.svg",
    "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1729265879/ai_avatar_yellow_oe5os5.svg",
    "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1729265878/ai_avatar_orange_llneem.svg",
    "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1729265878/ai_avatar_maroon_t6py13.svg",
];
