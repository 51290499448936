import Button from "components/common/Button";
import Loader from "components/common/Loader";
import React, { useEffect, useState } from "react";
import ProductionSteps from "../ProductionSteps";
import moment from "moment";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import { productionActions } from "redux/Ldb/actions";

type workTypes = {
    steps: any;
    productName: string;
    isLoading: boolean;
};

const ProductionWorkflow = ({ steps, productName, isLoading }: workTypes) => {
    const dispatch = useAppDispatch();

    const { productionId } = useParams();

    const [productionSteps, setProductionSteps] = useState<{ [key: string]: any }[]>([]);
    const [productionStepModal, setProductionStepModal] = useState<boolean>(false);
    const [step, setStep] = useState<{ [key: string]: any }>({});
    const [stepIdx, setStepIdx] = useState<number>(-1);
    const [savedStepId, setSavedStepId] = useState<string>("");

    const { creatingPlanSteps, createPlanStepsSuccess, updatingPlanSteps, updatePlanStepsSuccess } =
        useAppSelector((state) => state?.production);

    useEffect(() => {
        if (steps?.steps) {
            const modSteps = steps?.steps?.sort((a, b) => a?.step - b?.step);

            const newSteps =
                modSteps?.length >= 1
                    ? modSteps?.map((step) => {
                          return {
                              name: step?.name,
                              description: step?.description,
                              amount: step?.quantity?.amount,
                              unitOfMeasurement: step?.quantity?.unitOfMeasurement,
                              step: step?.step,
                              plannedStart: moment(step?.plannedStart)?.format("YYYY-MM-DDTHH:MM"),
                              plannedEnd: moment(step?.plannedEnd)?.format("YYYY-MM-DDTHH:MM"),
                              id: step?._id,
                          };
                      })
                    : [];
            setProductionSteps(newSteps);
        }
    }, [steps]);

    const handleSubmit = () => {
        const modStep = {
            name: step?.name,
            description: step?.description,
            plannedStart: moment(step?.plannedStart)?.toISOString(),
            plannedEnd: moment(step?.plannedEnd)?.toISOString(),
            quantity: {
                amount: step?.amount,
                unitOfMeasurement: step?.unitOfMeasurement,
            },
            step: step?.step,
        };
        if (savedStepId) {
            dispatch(productionActions?.updateProductionPlanSteps(modStep, savedStepId));
        } else {
            const save = {
                steps: [modStep],
            };
            dispatch(productionActions?.createProductionPlanSteps(save, productionId));
        }
    };

    useEffect(() => {
        if (Boolean(createPlanStepsSuccess)) {
            dispatch(productionActions?.resetCreateProductionPlanSteps());
            dispatch(productionActions.fetchProductionPlanTab(productionId, "steps", 10, 1));
            setProductionStepModal(false);
        }
    }, [createPlanStepsSuccess]);

    useEffect(() => {
        if (Boolean(updatePlanStepsSuccess)) {
            dispatch(productionActions.fetchProductionPlanTab(productionId, "steps", 10, 1));
            dispatch(productionActions?.resetUpdateProductionPlanSteps());
            setProductionStepModal(false);
        }
    }, [updatePlanStepsSuccess]);

    useEffect(() => {
        if (!productionStepModal) {
            setStep({});
            setStepIdx(-1);
            setSavedStepId("");
        }
    }, [productionStepModal]);

    return (
        <div className="font-rocGroteskMedium">
            {/* <div className="">
                <div className="w-6 h-6 rounded-full bg-[#00DB8F] flex items-center justify-center relative z-1">
                    <div className="w-3 h-3 rounded-full bg-white flex items-center justify-center">
                        <i className="ri-check-line text-[#00DB8F] text-[10px]"></i>
                    </div>
                </div>
                <div className={` pb-4 pl-[26px] ml-2.5 border-l border-slate-200 -mt-4`}>
                    <div className="border border-slate-200 rounded-[6px] font-rocGroteskMedium shadow-boxShadow-12  px-6 py-4">
                        <div className="flex items-start justify-between">
                            <div className="space-y-2">
                                <div className="py-1 px-3 border border-slate-200 bg-slate-100 rounded text-xs text-gm-50 w-fit">
                                    Day 1
                                </div>
                                <p className="text-sm text-g-75">Step 1: Ingredient Preparation</p>
                                <p className="text-sm text-slate-500">
                                    Gather and prepare cane sugar, water, yeast, and flavorings
                                </p>
                            </div>

                            <div className="space-y-2">
                                <div className="flex gap-2">
                                    <p className="text-g-75 text-sm">
                                        Planned Start:{" "}
                                        <span className="text-slate-500 text-sm ">
                                            {" "}
                                            2024-08-01 08:00 AM
                                        </span>
                                    </p>
                                    <p className="text-g-75 text-sm">
                                        Planned End:{" "}
                                        <span className="text-slate-500 text-sm ">
                                            {" "}
                                            2024-08-01 12:00 PM
                                        </span>
                                    </p>
                                    <p className="text-g-75 text-sm">
                                        Quantity
                                        <span className="text-slate-500 text-sm "> 525kg</span>
                                    </p>
                                </div>

                                <div className="flex gap-2">
                                    <p className="text-g-75 text-sm">
                                        Actual Start:{" "}
                                        <span className="text-slate-500 text-sm ">
                                            {" "}
                                            2024-08-01 08:10 AM
                                        </span>
                                    </p>
                                    <p className="text-g-75 text-sm">
                                        Actual End:{" "}
                                        <span className="text-slate-500 text-sm ">
                                            {" "}
                                            2024-08-01 12:15 PM
                                        </span>
                                    </p>
                                    <p className="text-g-75 text-sm">
                                        Quantity
                                        <span className="text-slate-500 text-sm "> 525kg</span>
                                    </p>
                                </div>
                                <div className="flex gap-2">
                                    <p className="text-g-75 text-sm">
                                        Total time spent:{" "}
                                        <span className="text-g-75 text-sm "> 4 hrs 10 mins</span>
                                    </p>
                                    <p className="text-g-75 text-sm">
                                        Total Quantity:
                                        <span className="text-g-75 text-sm "> 525kg</span>
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="flex gap-2 pt-2">
                            <Button
                                btnText="Add quality control checklist"
                                btnClassname="!px-4 !py-2 !bg-transparent !w-fit !border !border-slate-200 shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)] !text-[13px]"
                                icon={<i className="ri-list-unordered text-gm-50 text-xs"></i>}
                                // disabled={}
                                // onClick={}
                            />
                            <Button
                                btnText="Edit step"
                                btnClassname="!px-4 !py-2 !bg-transparent !w-fit !border !border-slate-200 shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)] !text-[13px]"
                                icon={<i className="ri-edit-2-line text-gm-50 text-xs"></i>}
                                // disabled={}
                                // onClick={}
                            />
                           
                        </div>
                    </div>
                </div>
            </div>
            <div className="">
                <div className="w-6 h-6 rounded-full bg-[#FEF3C7] flex items-center justify-center relative z-1">
                    <i className="ri-time-line text-[#D97706] text-xs"></i>
                </div>
                <div className={` pb-4 pl-[26px] ml-2.5 border-l border-slate-200 -mt-4`}>
                    <div className="border border-slate-200 rounded-[6px] font-rocGroteskMedium shadow-boxShadow-12 flex items-center justify-between px-6 py-4">
                        <div className={``}>
                            <span className="text-sm mb-1 block">
                                Review and approve sales orders
                            </span>
                            <p className="text-slate-500 text-[13px] ">
                                Once the sales order is created, it will be forwarded to the
                                appropriate team member for approval.
                            </p>
                        </div>

                        <span className="text-[13px] bg-[#FEF3C7] text-[#E4A500] px-3 py-2.5 h-10 rounded-[30px]">
                            Awaiting approval
                        </span>
                    </div>
                </div>
            </div> */}

            <div className="">
                <div className="flex items-center justify-between gap-2">
                    <p className="text-gm-75 text-base pb-4">
                        Production workflow {productName && `for ${productName}`}
                    </p>

                    {productionSteps?.length >= 1 && (
                        <div className="flex gap-2.5">
                            <Button
                                btnClassname="!py-2 !px-4 !w-fit !h-10 !text-[13px]"
                                btnText={"Add production Step"}
                                onClick={() => {
                                    setProductionStepModal(true);
                                }}
                            />
                            <Button
                                btnClassname="!py-2 !px-4 !w-fit !h-10 !text-[13px] !bg-transparent !border !border-slate-200"
                                btnText={"Import steps from file"}
                                // onClick={}
                            />
                        </div>
                    )}
                </div>
                {isLoading && productionSteps?.length === 0 ? (
                    <div className="flex justify-center  items-center h-[100px]">
                        <Loader color="gm-25" size={6} />
                    </div>
                ) : productionSteps?.length === 0 ? (
                    <div className="border border-slate-300 border-dashed mx-auto text-center pt-6 pb-8 rounded-md">
                        <div className=" flex items-center flex-col ">
                            <i className="ri-flow-chart text-g-75 text-[40px]"></i>
                            <h6 className="mb-2 text-base font-rocGroteskMedium text-g-75 ">
                                No production workflow found
                            </h6>
                            <p className="text-sm  mb-4 text-slate-500 max-w-[570px]">
                                You haven't added any production steps or plans yet. Start
                                organizing your production schedule to streamline your processes and
                                improve efficiency.
                            </p>
                            <div className="flex gap-2.5">
                                <Button
                                    btnClassname="!py-2 !px-4 !w-fit !h-10 !text-[13px]"
                                    btnText={"Add Production Step"}
                                    onClick={() => {
                                        // setProductionStepModal(true);
                                    }}
                                />
                                <Button
                                    btnClassname="!py-2 !px-4 !w-fit !h-10 !text-[13px] !bg-transparent !border !border-slate-200"
                                    btnText={"Import from document"}
                                    // onClick={}
                                />
                            </div>
                        </div>
                    </div>
                ) : (
                    productionSteps?.map((step, idx) => {
                        return (
                            <div className="font-rocGroteskMedium">
                                <div className="">
                                    {idx === 0 ? (
                                        <div className="w-6 h-6 rounded-full bg-[#00DB8F] flex items-center justify-center relative z-1">
                                            <div className="w-3 h-3 rounded-full bg-white flex items-center justify-center">
                                                <i className="ri-check-line text-[#00DB8F] text-[10px]"></i>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="w-6 h-6 rounded-full bg-[#FEF3C7] flex items-center justify-center relative z-1">
                                            <i className="ri-time-line text-[#D97706] text-xs"></i>
                                        </div>
                                    )}
                                    <div
                                        className={` pb-4 pl-[26px] ml-2.5 border-l border-slate-200 -mt-4`}
                                    >
                                        <div className="border border-slate-200 rounded-[6px] font-rocGroteskMedium shadow-boxShadow-12  px-6 py-4">
                                            <div className="flex items-start justify-between">
                                                <div className="space-y-2">
                                                    <div className="py-1 px-3 border border-slate-200 bg-slate-100 rounded text-xs text-gm-50 w-fit">
                                                        Day {step?.step}
                                                    </div>
                                                    <p className="text-sm text-g-75">
                                                        Step {idx + 1}: {step?.name}
                                                    </p>
                                                    <p className="text-sm text-slate-500">
                                                        {step?.description}
                                                    </p>
                                                </div>

                                                <div className="space-y-2">
                                                    <div className="flex gap-2">
                                                        <p className="text-g-75 text-sm">
                                                            Planned Start:{" "}
                                                            <span className="text-slate-500 text-sm ">
                                                                {moment(step?.plannedStart)?.format(
                                                                    "YYYY-MM-DD HH:MM:SS A"
                                                                )}
                                                            </span>
                                                        </p>
                                                        <p className="text-g-75 text-sm">
                                                            Planned End:{" "}
                                                            <span className="text-slate-500 text-sm ">
                                                                {moment(step?.plannedEnd)?.format(
                                                                    "YYYY-MM-DD HH:MM:SS A"
                                                                )}
                                                            </span>
                                                        </p>
                                                        <p className="text-g-75 text-sm">
                                                            Quantity: {` `}
                                                            <span className="text-slate-500 text-sm ">
                                                                {step?.amount}
                                                                {` `}
                                                                {step?.unitOfMeasurement}
                                                            </span>
                                                        </p>
                                                    </div>

                                                    <div className="flex gap-2">
                                                        {step?.actualStart && (
                                                            <p className="text-g-75 text-sm">
                                                                <span className="text-slate-500 text-sm ">
                                                                    {moment(
                                                                        step?.actualStart
                                                                    )?.format(
                                                                        "YYYY-MM-DD HH:MM:SS A"
                                                                    )}
                                                                </span>
                                                            </p>
                                                        )}

                                                        {step?.actualStart && (
                                                            <p className="text-g-75 text-sm">
                                                                Actual End:{" "}
                                                                <span className="text-slate-500 text-sm ">
                                                                    {moment(
                                                                        step?.actualEnd
                                                                    )?.format(
                                                                        "YYYY-MM-DD HH:MM:SS A"
                                                                    )}
                                                                </span>
                                                            </p>
                                                        )}
                                                        {/* <p className="text-g-75 text-sm">
                                                            Quantity
                                                            <span className="text-slate-500 text-sm ">
                                                                {" "}
                                                                525kg
                                                            </span>
                                                        </p> */}
                                                    </div>
                                                    {step?.actualStart && step?.actualEnd && (
                                                        <div className="flex gap-2">
                                                            <p className="text-g-75 text-sm">
                                                                Total time spent:{" "}
                                                                <span className="text-g-75 text-sm ">
                                                                    {" "}
                                                                    4 hrs 10 mins
                                                                </span>
                                                            </p>
                                                            <p className="text-g-75 text-sm">
                                                                Total Quantity:
                                                                <span className="text-g-75 text-sm ">
                                                                    {" "}
                                                                    525kg
                                                                </span>
                                                            </p>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>

                                            <div className="flex gap-2 pt-2">
                                                {/* <Button
                                                    btnText="Add quality control checklist"
                                                    btnClassname="!px-4 !py-2 !bg-transparent !w-fit !border !border-slate-200 shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)] !text-[13px]"
                                                    icon={
                                                        <i className="ri-list-unordered text-gm-50 text-xs"></i>
                                                    }
                                                    disabled={}
                                                    onClick={}
                                                /> */}
                                                <Button
                                                    btnText="Edit step"
                                                    btnClassname="!px-4 !py-2 !bg-transparent !w-fit !border !border-slate-200 shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)] !text-[13px]"
                                                    icon={
                                                        <i className="ri-edit-2-line text-gm-50 text-xs"></i>
                                                    }
                                                    onClick={() => {
                                                        setStepIdx(idx);
                                                        setStep(productionSteps[idx]);
                                                        setProductionStepModal(true);
                                                        setSavedStepId(step?.id);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })
                )}
            </div>

            {productionStepModal && (
                <ProductionSteps
                    productionStepModal={productionStepModal}
                    setProductionStepModal={setProductionStepModal}
                    productionSteps={productionSteps}
                    setProductionSteps={setProductionSteps}
                    step={step}
                    setStep={setStep}
                    stepIdx={stepIdx}
                    setStepIdx={setStepIdx}
                    handleSubmit={handleSubmit}
                    isSaving={creatingPlanSteps || updatingPlanSteps}
                    productionId={productionId}
                />
            )}
        </div>
    );
};

export default ProductionWorkflow;
