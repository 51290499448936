import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import { dashboardActions } from "redux/Ldb/actions";

interface Props{
    productId:string
}
const useGetSalesGWM = (productId:any) => {
    const dispatch = useAppDispatch();
    const [gwmData, setGwmData] = useState<{ [key: string]: any }>([]);
    // const [productId, setProductId] = useState<string>("");
    const [warehouseId, setWarehouseId] = useState<string>("");
    const [channel, setChannel] = useState<string>("");
    const [category, setCategory] = useState<string>("");
    const [dateRange, setDateRange] = useState<any>("yearly");
    const [endDate, setEndDate] = useState<string>("");
    const [startDate, setStartDate] = useState<any>("");

    const handleCategoryChange = (value) => {
        setCategory(value);
    };

    const handleChannelChange = (value) => {
        setChannel(value);
    };
    // const handleProductChange = (value) => {
    //     setProductId(value);
    // };
    const handleDateRangeChange = (period) => {
        setDateRange(period);
        setStartDate("");
        setEndDate("");
    };
    const handleWarehouseChange = (value) => {
        setWarehouseId(value);
    };
    const handleStartAndEndDateChange = (customDate) => {
        setStartDate(customDate[0].toISOString());
        setEndDate(customDate[1].toISOString());
        setDateRange("custom");
    };

    const { fetchingSalesGWM, fetchedSalesGWMSuccess, fetchedSalesGWMFailure } =
        useAppSelector((state) => state.dashboard);

    useEffect(() => {
        if (Boolean(fetchedSalesGWMSuccess)) {
            return setGwmData(fetchedSalesGWMSuccess);
        }
    }, [fetchedSalesGWMSuccess]);

    useEffect(() => {
        dispatch(
            dashboardActions?.getSalesGWM(
                category,
                productId,
                warehouseId,
                channel,
                startDate,
                endDate,
                dateRange
            )
        );
    }, [dispatch, category, productId, warehouseId, channel, startDate, endDate, dateRange]);

    return {
        data: gwmData,
        isFetching: fetchingSalesGWM,
        error: fetchedSalesGWMFailure,
        handleCategoryChange,
        handleDateRangeChange,
        handleChannelChange,
        handleStartAndEndDateChange,
        handleWarehouseChange
    };
};

export default useGetSalesGWM;
