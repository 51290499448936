import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import useInfiniteScroll from "hooks/useInfinteScroll";
import { debounce, uniqBy } from "lodash";
import React, { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { productionActions } from "redux/Ldb/actions";

const useGetProductionAnalytics = (scrollContent?: HTMLElement) => {
    const dispatch = useAppDispatch();
    const [dates, setDates] = useState<{ [key: string]: any }>({
        startDate: "",
        endDate: "",
    });
    const [analytics, setAnalytics] = useState<{ [key: string]: any }>({});

    const { fetchingProductionAnalytics, fetchedProductionAnalyticsSuccess } = useAppSelector(
        (state) => state.production
    );

    useEffect(() => {
        if (Boolean(fetchedProductionAnalyticsSuccess)) {
            setAnalytics(fetchedProductionAnalyticsSuccess);
        }
    }, [fetchedProductionAnalyticsSuccess]);

    useEffect(() => {
        dispatch(productionActions.fetchProductionAnalytics(dates?.startDate, dates?.endDate));
    }, [dispatch, dates]);

    return {
        analytics,
        isLoading: fetchingProductionAnalytics,
        dates,
        setDates,
    };
};

export default useGetProductionAnalytics;
