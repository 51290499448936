import React from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import ModalContainer from "./../../../common/ModalContainer";
import Button from "./../../../common/Button";

type modalProps = {
    successModal: boolean;
    setSuccessModal: (data: boolean) => void;
    productionPlanId: string;
};

const ProductionModals = ({ successModal, setSuccessModal, productionPlanId }: modalProps) => {
    const navigate = useNavigate();
    const { productionId } = useParams();
    const [param] = useSearchParams();
    const planTab = param.get("planTab");
    return (
        <div>
            <ModalContainer
                open={successModal}
                modalClassName={"py-10 px-10"}
                tailwindClassName="w-[600px]"
                showCloseIcon={false}
                closeModal={() => {
                    setSuccessModal(true);
                }}
            >
                <div className="bg-white rounded-[8px] relative">
                    <i
                        className="ri-close-fill cursor-pointer text-gm-50 text-xl absolute  top-4 right-4"
                        onClick={() => {
                            // setSuccessModal(true);
                        }}
                    ></i>
                    <div className="mt-4 ">
                        <div className="text-center flex justify-center flex-col items-center px-8 pt-10 mb-8">
                            <div className="mb-4 w-12 h-12 bottom-0 right-[-13px] rounded-full bg-[#00DB8F] flex items-center justify-center">
                                <i className="ri-checkbox-circle-fill text-[24px] text-white"></i>
                            </div>
                            <div className="px-6 font-rocGroteskMedium">
                                <span className="block mb-2 text-center text-[22px]">
                                    {productionId
                                        ? "Changes saved successfully!"
                                        : "Production plan created!"}
                                </span>
                                {/* Changes saved successfully! */}
                                <p className="mt-1 text-base text-slate-500 text-center ">
                                    {productionId
                                        ? "Your production plan details have been updated and saved. The changes are now reflected in your schedule."
                                        : "Your production plan has been successfully created. You can now review and manage the production plan details to ensure everything is set for processing."}
                                </p>
                                {/* Your production plan details have been updated and saved. The
                                changes are now reflected in your schedule. */}
                            </div>
                        </div>
                        <div className="border-t border-t-slate-200 py-4 px-6 flex items-center space-x-4">
                            <Button
                                btnText={"Close"}
                                btnClassname="!py-3 cursor-pointer !text-[14px] !bg-n-20 !text-g-75 !border border-n-20 !font-rocGroteskMedium !px-4"
                                onClick={() => {
                                    // setSuccessModal(false);
                                    navigate(`/dashboard/production?planTab=${planTab}`);

                                    // setInventoryId("");
                                    // setBomList([{}]);
                                }}
                            />
                            <Button
                                btnText={"View details"}
                                btnClassname="!py-3 cursor-pointer !text-[14px] !text-g-75 !border border-n-20 !font-rocGroteskMedium !px-4"
                                onClick={() =>
                                    navigate(`/dashboard/production/${productionPlanId}?tab=bom`)
                                }
                            />
                        </div>
                    </div>
                </div>
            </ModalContainer>
        </div>
    );
};

export default ProductionModals;
