import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import MarketInsight from "../ProductList/ProductDetail/Tabs/MarketInsight";
import IncidentsComments from "components/views/Synth2.0/Inventory/Tabs/Alerts/Tabs/IncidentComments";
import SuggestedActions from "components/views/Synth2.0/Inventory/Tabs/Alerts/Tabs/SuggestedActions";
import useGetReplishmentStockList from "hooks/inventoryHooks/useGetReplishmentStock";
import Button from "components/common/Button";
import { displayFileIcon } from "helpers";
import PreviousAlerts from "./Tabs/PreviousAlerts";
import TabsContainer from "components/common/TabsContainer";
import VendorAvatar from "components/common/VendorAvatar";
import moment from "moment";
import { shipment } from "redux/Ldb/reducers";
import { firebaseService } from "services/firebaseService";
import Loader from "components/common/Loader";
import { PDFDownloadLink } from "@react-pdf/renderer";
import PurchaseOrdersPDF from "components/views/Ldb/Orders/PDFPurchase";

interface AlertProps {
    data: any;
    isFetching: boolean;
    pagination: any;
    isLoadingMore: boolean;
    modalShow?: boolean;
    isFetchingReplishment: boolean;
    onClickDocument: any;
    onClickShipment: any;
    replishmentStock: any;
    incidentId?: string;
}
interface Docs {
    documentType: string;
    filename: string;
    path: string;
    _id: string;
}
const AlertTabDetails = ({
    data,
    isFetching,
    pagination,
    isLoadingMore,
    onClickDocument,
    onClickShipment,
    modalShow,
    replishmentStock,
    isFetchingReplishment,
    incidentId,
}: AlertProps) => {
    const [activeTab, setActiveTab] = useState(0);
    const navigate = useNavigate();
    const { alertId } = useParams();
    const [tabs, setTabs] = useState(["Timeline", "Comments", "Sources", "Previous alerts"]);
    const [allAttachments, setAllAttachments] = useState([]);
    const [deletingFile, setDeletingFile] = useState<boolean>(false);
    const [container, setContainer] = useState([]);
    const [subTotals, setSubTotals] = useState<number>(0);
    const [taxTotals, setTaxTotals] = useState<number>(0);
    const [customerDetails, setCustomerDetails] = useState<{ [key: string]: any }>({
        issuedDate: "",
        dueDate: "",
        shippingTerms: "",
        fobPoint: "",
        shippingVia: "",
        source: "",
        shippingAndHandling: "",
        currency: "",
        termsAndCondition: "",
        additionalInfo: "",
        tax: "",
        incoterm: "",
    });
    const fbDownload = (doc: Docs) => {
        const anchor = document.createElement("a");
        anchor.style.display = "none";
        anchor.href = doc?.path;
        anchor.download = doc?.filename;
        anchor.target = "_blank";
        anchor.rel = "noreferrer";
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
    };
    useEffect(() => {
        setAllAttachments(
            replishmentStock?.shipments?.flatMap((shipment) => shipment.attachments) || []
        );
    }, [replishmentStock?.shipments]);

    const handleRemoveUpload = (idx) => {
        const newArr = [...allAttachments];

        const imagePath = decodeURIComponent(
            allAttachments[idx]?.path?.split("/o/")?.[1]?.split("?alt")?.[0]
        );

        setDeletingFile(true);
        firebaseService.removeFile(imagePath, () => {
            newArr?.splice(idx, 1);
            setAllAttachments(newArr);
            setDeletingFile(false);
        });
    };
    useEffect(() => {
        let totalSubtotals = 0;
        if (replishmentStock?.purchaseOrder?.itemsOrServices?.length > 0) {
            for (const container of replishmentStock?.purchaseOrder?.itemsOrServices) {
                const subtotal = parseFloat(container.subtotal.amount);
                totalSubtotals += subtotal;
            }
            const taxPercentage = parseFloat(replishmentStock?.purchaseOrder?.tax?.percentage);
            const taxAmount = (taxPercentage / 100) * totalSubtotals;

            setSubTotals(totalSubtotals);
            setTaxTotals(taxAmount);
        }
        setContainer(
            replishmentStock?.purchaseOrder?.itemsOrServices?.map((data, idx) => ({
                productImageDetails: {
                    avatar: data?.inventory?.productImageDetails?.productAvatar,
                },
                productName: data?.inventory?.productName,
                sku: data?.inventory?.sku,
                description: data?.inventory?.productDescription,
                unitCost: {
                    amount: data?.price?.amount,
                    currency: data?.price?.currency,
                },
                subtotal: {
                    amount: data?.subtotal?.amount,
                },
                quantity: data?.qty,
                deliveryDate: data?.deliveryDate,
                leadTime: data?.leadTime,
            }))
        );
    }, [replishmentStock?.purchaseOrder]);

    useEffect(() => {
        setCustomerDetails({
            issuedDate: replishmentStock?.purchaseOrder?.issuedDate,
            dueDate: replishmentStock?.purchaseOrder?.dueDate,
            shippingTerms: replishmentStock?.purchaseOrder?.shippingTerms,
            fobPoint: replishmentStock?.purchaseOrder?.fobPoint,
            shippingVia: replishmentStock?.purchaseOrder?.shippingMethod,
            source: replishmentStock?.purchaseOrder?.source,
            currency: replishmentStock?.purchaseOrder?.currency,
            tax: replishmentStock?.purchaseOrder?.tax?.percentage,
            shippingAndHandling: replishmentStock?.purchaseOrder?.shippingAndHandling?.amount,
            termsAndCondition: replishmentStock?.purchaseOrder?.termsAndCondition,
            paymentTerms: replishmentStock?.purchaseOrder?.paymentTerms,
            incoterm: replishmentStock?.purchaseOrder?.incoterm,
        });
    }, [replishmentStock?.purchaseOrder]);

    useEffect(() => {
        if (data?.incidentType?.toLowerCase() === "stockout") {
            setTabs(["Timeline", "Comments", "Sources", "Previous alerts"]);
        } else {
            setTabs(["Suggested Actions", "Comments", "Sources", "Previous alerts"]);
        }
    }, [data?.incidentType]);

    const quantities = replishmentStock?.purchaseOrder?.itemsOrServices?.map((item) =>
        Number(item.qty)
    );
    const totalQuantity = quantities?.reduce((acc, curr) => acc + curr, 0);
    const displayActiveTab = () => {
        switch (activeTab) {
            case 0:
                return (
                    <div>
                        {replishmentStock?.purchaseOrder &&
                            typeof replishmentStock.purchaseOrder === "object" &&
                            Object.keys(replishmentStock.purchaseOrder).length > 0 && (
                                <div>
                                    <div className="flex items-center justify-between">
                                        <div className="flex items-center space-x-4">
                                            <div className="w-5 h-5 rounded-full bg-[#00DB8F] flex items-center justify-center">
                                                <i className="ri-checkbox-circle-fill text-white text-[10px]"></i>
                                            </div>
                                            <span className="text-base font-rocGroteskMedium text-g-75">
                                                Created a purchase request
                                            </span>
                                        </div>
                                        <div className="flex items-center space-x-1">
                                            <span className="text-sm font-rocGroteskMedium text-slate-500">
                                                {moment(
                                                    replishmentStock?.purchaseOrder?.createdAt
                                                ).format("D MMM YYYY [at] hh:mmA [UTC]")}
                                            </span>
                                            <i className="ri-arrow-down-s-line text-lg text-gm-50"></i>
                                        </div>
                                    </div>
                                    <div
                                        className={`pt-3 pb-6 pl-[26px] ml-2.5 ${
                                            ((typeof replishmentStock.purchaseOrder === "object" &&
                                                Object.keys(replishmentStock.purchaseOrder).length >
                                                    0) ||
                                                (Array.isArray(replishmentStock.purchaseOrder) &&
                                                    replishmentStock.purchaseOrder.length > 0)) &&
                                            "border-l"
                                        }  border-slate-200`}
                                    >
                                        <div className="border border-slate-200 rounded-[6px]   shadow-boxShadow-12">
                                            <div className={`flex flex-col pb-3 pt-3 px-6`}>
                                                <p className="text-g-75 text-sm font-rocGroteskMedium">
                                                    We've created a purchase request for you (
                                                    {`PR ${replishmentStock?.purchaseOrder?.taId}`})
                                                </p>
                                                {replishmentStock?.purchaseOrder?.documents
                                                    ?.length > 0 ? (
                                                    <div
                                                        className="space-x-2 cursor-pointer flex items-center py-2 px-3 border-slate-200 rounded shadow-faintShadow_1 border w-fit mt-2"
                                                        onClick={() =>
                                                            fbDownload(
                                                                replishmentStock?.purchaseOrder
                                                                    ?.documents[0]
                                                            )
                                                        }
                                                    >
                                                        <i className="ri-file-text-line"></i>
                                                        <span className="text-sm font-rocGroteskMedium">
                                                            PR{" "}
                                                            {replishmentStock?.purchaseOrder?.taId}
                                                        </span>
                                                    </div>
                                                ) : (
                                                    <PDFDownloadLink
                                                        document={
                                                            <PurchaseOrdersPDF
                                                                imageDetails={
                                                                    replishmentStock?.purchaseOrder
                                                                        ?.ldb?.profile?.avatar
                                                                }
                                                                customerDetails={customerDetails}
                                                                ldbName={
                                                                    replishmentStock?.purchaseOrder
                                                                        ?.ldb?.profile?.businessName
                                                                }
                                                                ldbEmail={
                                                                    replishmentStock?.purchaseOrder
                                                                        ?.ldb?.email
                                                                }
                                                                ldbPhone={
                                                                    replishmentStock?.purchaseOrder
                                                                        ?.ldb?.mobile
                                                                }
                                                                ldbAddress={
                                                                    replishmentStock?.purchaseOrder
                                                                        ?.ldb?.profile?.address
                                                                        ?.address
                                                                }
                                                                vendorName={
                                                                    replishmentStock?.purchaseOrder
                                                                        ?.vendor?.profile
                                                                        ?.businessName
                                                                }
                                                                vendorEmail={
                                                                    replishmentStock?.purchaseOrder
                                                                        ?.vendor?.email
                                                                }
                                                                vendorAddress={
                                                                    replishmentStock?.purchaseOrder
                                                                        ?.vendor?.profile?.address
                                                                        ?.address
                                                                }
                                                                vendorPhone={
                                                                    replishmentStock?.purchaseOrder
                                                                        ?.vendor?.mobile
                                                                }
                                                                totalQuantity={totalQuantity}
                                                                totalAmount={subTotals}
                                                                filesAttached={
                                                                    replishmentStock?.purchaseOrder
                                                                        ?.attachments
                                                                }
                                                                taxAmount={
                                                                    replishmentStock?.purchaseOrder
                                                                        ?.tax?.amount
                                                                }
                                                                containers={container}
                                                            />
                                                        }
                                                        fileName="purchase-order.pdf"
                                                    >
                                                        {({ loading }) => {
                                                            return loading ? (
                                                                <div className="space-x-2 cursor-pointer  flex items-center py-2 px-3 border-slate-200 rounded shadow-faintShadow_1 border w-fit mt-2">
                                                                    <i className="ri-file-text-line"></i>
                                                                    <span className="text-sm font-rocGroteskMedium">
                                                                        PR{" "}
                                                                        {
                                                                            replishmentStock
                                                                                ?.purchaseOrder
                                                                                ?.taId
                                                                        }
                                                                    </span>
                                                                </div>
                                                            ) : (
                                                                <div className="space-x-2 cursor-pointer flex items-center py-2 px-3 border-slate-200 rounded shadow-faintShadow_1 border w-fit mt-2">
                                                                    <i className="ri-file-text-line"></i>
                                                                    <span className="text-sm font-rocGroteskMedium">
                                                                        PR{" "}
                                                                        {
                                                                            replishmentStock
                                                                                ?.purchaseOrder
                                                                                ?.taId
                                                                        }
                                                                    </span>
                                                                </div>
                                                            );
                                                        }}
                                                    </PDFDownloadLink>
                                                )}
                                            </div>
                                            <div className="border-t rounded-b-[6px] border-slate-200 px-6 py-3 bg-slate-50 flex items-center space-x-2">
                                                <i className="ri-sparkling-2-fill text-base text-slate-500"></i>
                                                <span className="text-sm font-rocGroteskMedium text-slate-500">
                                                    Synth has created a purchase request for you and
                                                    sent it to the right team members for approval
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        {((typeof replishmentStock.purchaseOrder === "object" &&
                            Object.keys(replishmentStock.purchaseOrder).length === 0) ||
                            (Array.isArray(replishmentStock.purchaseOrder) &&
                                replishmentStock.purchaseOrder.length === 0)) && (
                            <div>
                                <div className="flex items-center justify-between">
                                    <div className="flex items-center space-x-4">
                                        <div className="w-5 h-5 rounded-full bg-[#FEF3C7] flex items-center justify-center">
                                            <i className="ri-hourglass-fill text-[#D97706] text-[10px]"></i>
                                        </div>
                                        <span className="text-base font-rocGroteskMedium text-g-75">
                                            Create a purchase request
                                        </span>
                                    </div>
                                    <i className="ri-arrow-down-s-line text-lg text-gm-50"></i>
                                </div>
                                <div
                                    className={`py-3 pl-[26px] ml-2.5 ${
                                        ((typeof replishmentStock.purchaseOrder === "object" &&
                                            Object.keys(replishmentStock.purchaseOrder).length !==
                                                0) ||
                                            (Array.isArray(replishmentStock.purchaseOrder) &&
                                                replishmentStock.purchaseOrder.length > 0)) &&
                                        "border-l"
                                    }  border-slate-200`}
                                >
                                    <div className="border border-slate-200 rounded-[6px] py-4 px-6 shadow-boxShadow-12">
                                        <p className="text-g-75 font-rocGroteskMedium text-sm">
                                            Generate a purchase request and seek approval to
                                            facilitate the ordering and replenishment of this
                                            product.
                                        </p>
                                        <div className="mt-2">
                                            <Button
                                                btnText="Create purchase request"
                                                btnClassname="!px-4 !py-2.5 !w-fit"
                                                onClick={() => {
                                                    navigate(
                                                        `/dashboard/purchase/create-purchase-request?incidentId=${
                                                            incidentId ? incidentId : alertId
                                                        }`
                                                    );
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        {((typeof replishmentStock.purchaseOrder === "object" &&
                            Object.keys(replishmentStock.purchaseOrder).length !== 0) ||
                            (Array.isArray(replishmentStock.purchaseOrder) &&
                                replishmentStock.purchaseOrder.length > 0)) && (
                            <>
                                {replishmentStock?.purchaseOrder?.approvalStatus?.toLowerCase() ===
                                    "approved" && (
                                    <div>
                                        <div className="flex items-center justify-between">
                                            <div className="flex items-center space-x-4">
                                                <div className="w-5 h-5 rounded-full bg-[#00DB8F] flex items-center justify-center">
                                                    <i className="ri-checkbox-circle-fill text-white text-[10px]"></i>
                                                </div>
                                                <div
                                                    className={`flex ${
                                                        !modalShow
                                                            ? "flex-col space-y-2"
                                                            : "flex-row items-center space-x-2"
                                                    }  `}
                                                >
                                                    <span className="text-base font-rocGroteskMedium text-g-75">
                                                        Purchase request approval
                                                        {/* countdown due in: */}
                                                    </span>
                                                    {/* <div className="space-x-1 flex w-fit rounded-[3px] items-center border py-1 bg-[#FEE2E2] border-[#DC2626] px-2">
                                                 <i className="ri-time-line text-[#DC2626] text-[13px]"></i>
                                                 <span className="text-[#DC2626] text-base font-rocGroteskMedium">
                                                     2d: 0h: 45m: 22s
                                                 </span>
                                             </div> */}
                                                </div>
                                            </div>
                                            <div className="">
                                                <div className="flex items-center space-x-1">
                                                    <span className="text-sm font-rocGroteskMedium text-slate-500">
                                                        {moment(
                                                            replishmentStock?.purchaseOrder
                                                                ?.approvalTime
                                                        ).format("D MMM YYYY [at] hh:mmA [UTC]")}
                                                    </span>
                                                    <i className="ri-arrow-down-s-line text-lg text-gm-50"></i>
                                                </div>
                                                {/* {!modalShow && (
                                        <Button
                                            icon={<i className="ri-edit-line"></i>}
                                            btnText="Edit purchase request"
                                            btnClassname="!px-4 mt-1 !py-2.5 !whitespace-nowrap !bg-tradeally-neutral-20 !text-gm-50 !w-fit"
                                            onClick={() => navigate(`/dashboard/purchase/edit-purchase-request/${replishmentStock?.purchaseOrder?._id}`)}
                                        />
                                    )} */}
                                            </div>
                                        </div>

                                        <div className="py-3 pl-[26px] ml-2.5 border-l border-slate-200">
                                            <div className="border border-slate-200 rounded-[6px] py-4 px-6 shadow-boxShadow-12">
                                                <div className="flex space-x-4 justify-between items-center">
                                                    <div>
                                                        <span className="block text-sm text-g-75 font-rocGroteskMedium mb-1">
                                                            Review and approve purchase requests
                                                        </span>
                                                        <span className="block text-[13px] text-slate-500 font-rocGroteskMedium mb-3">
                                                            Team members in charge of approval are
                                                            required to review and approve purchase
                                                            requests before the countdown expires.
                                                            Your timely action is critical to
                                                            maintain the efficiency of process.
                                                        </span>
                                                    </div>
                                                    {/* {modalShow && (
                                                        <Button
                                                            icon={<i className="ri-edit-line"></i>}
                                                            btnText="Edit purchase request"
                                                            btnClassname="!px-4 !py-2.5 !whitespace-nowrap !bg-tradeally-neutral-20 !text-gm-50 !w-fit"
                                                            onClick={() => {}}
                                                        />
                                                    )} */}
                                                </div>
                                                <span className="block text-xs text-slate-500 font-rocGroteskMedium mb-2">
                                                    Approved by:
                                                </span>
                                                <div>
                                                    {replishmentStock?.purchaseOrder?.approvals?.map(
                                                        (data, idx) => {
                                                            return (
                                                                <div
                                                                    key={idx}
                                                                    className={` pb-3 ${
                                                                        idx < 2 &&
                                                                        "border-b border-slate-100"
                                                                    } ${idx > 0 && "pt-3"}`}
                                                                >
                                                                    <div className="flex items-center justify-between mb-2">
                                                                        <div className="flex items-center space-x-4">
                                                                            <VendorAvatar
                                                                                name={
                                                                                    data?.person
                                                                                        ?.fullName
                                                                                }
                                                                                imageSrc={
                                                                                    data?.person
                                                                                        ?.profile
                                                                                        ?.avatar
                                                                                }
                                                                                size={28}
                                                                            />
                                                                            <div>
                                                                                <span className="block text-[13px] font-rocGroteskMedium">
                                                                                    {
                                                                                        data?.person
                                                                                            ?.fullName
                                                                                    }
                                                                                </span>
                                                                                <span className="block text-xs text-slate-500 font-rocGroteskMedium">
                                                                                    {moment(
                                                                                        replishmentStock
                                                                                            ?.purchaseOrder
                                                                                            ?.approvalTime
                                                                                    ).format(
                                                                                        "MMMM D, YYYY; h:mma"
                                                                                    )}
                                                                                </span>
                                                                            </div>
                                                                        </div>

                                                                        <span className="text-xs font-rocGroteskMedium px-3 py-1 w-fit bg-[#D0F4ED] rounded flex items-center justify-center">
                                                                            {data?.status}
                                                                        </span>
                                                                    </div>

                                                                    {/* <span className="block text-[13px] text-slate-500 font-rocGroteskMedium">
                                                                    I like the way you structured
                                                                    the Purchase Request. Please
                                                                    keep up the good work. Thank you
                                                                </span> */}
                                                                </div>
                                                            );
                                                        }
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}

                                {replishmentStock?.purchaseOrder?.approvalStatus?.toLowerCase() !==
                                    "approved" && (
                                    <div>
                                        <div className="flex items-center justify-between">
                                            <div className="flex items-center space-x-4">
                                                <div className="w-5 h-5 rounded-full bg-[#FEF3C7] flex items-center justify-center">
                                                    <i className="ri-hourglass-fill text-[#D97706] text-[10px]"></i>
                                                </div>
                                                <span className="text-base font-rocGroteskMedium text-g-75">
                                                    Purchase request approval
                                                </span>
                                            </div>
                                            <div className="flex items-center space-x-1">
                                                <span className="text-sm font-rocGroteskMedium text-slate-500">
                                                    {moment(
                                                        replishmentStock?.purchaseOrder?.createdAt
                                                    ).format("D MMM YYYY [at] hh:mmA [UTC]")}
                                                </span>
                                                <i className="ri-arrow-down-s-line text-lg text-gm-50"></i>
                                            </div>
                                        </div>

                                        <div className="py-3 pl-[26px] ml-2.5 border-l border-slate-200">
                                            <div className="border border-slate-200 rounded-[6px] py-4 px-6 shadow-boxShadow-12">
                                                <div className="flex space-x-4 justify-between items-center">
                                                    <div>
                                                        <span className="block text-sm text-g-75 font-rocGroteskMedium mb-1">
                                                            Review and approve purchase requests
                                                        </span>
                                                        <span className="block text-[13px] text-slate-500 font-rocGroteskMedium mb-3">
                                                            Once the purchase request is created, it
                                                            will be forwarded to the appropriate
                                                            team member for approval.
                                                        </span>
                                                    </div>
                                                    <Button
                                                        icon={<i className="ri-edit-line"></i>}
                                                        btnText="Edit purchase request"
                                                        btnClassname="!px-4 !py-2.5 !whitespace-nowrap !bg-tradeally-neutral-20 !text-gm-50 !w-fit"
                                                        onClick={() =>
                                                            navigate(
                                                                `/dashboard/purchase/edit-purchase-request/${replishmentStock?.purchaseOrder?._id}`
                                                            )
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {replishmentStock?.purchaseOrder?.approvalStatus?.toLowerCase() ===
                                    "approved" && (
                                    <div>
                                        <div className="flex items-center justify-between">
                                            <div className="flex items-center space-x-4">
                                                <div className="w-5 h-5 rounded-full bg-[#00DB8F] flex items-center justify-center">
                                                    <i className="ri-checkbox-circle-fill text-white text-[10px]"></i>
                                                </div>
                                                <span className="text-base font-rocGroteskMedium text-g-75">
                                                    PO sent to supplier
                                                </span>
                                            </div>
                                            <div className="flex items-center space-x-1">
                                                <span className="text-sm font-rocGroteskMedium text-slate-500">
                                                    {moment(
                                                        replishmentStock?.purchaseOrder
                                                            ?.approvalTime
                                                    ).format("D MMM YYYY [at] hh:mmA [UTC]")}
                                                </span>
                                                <i className="ri-arrow-down-s-line text-lg text-gm-50"></i>
                                            </div>
                                        </div>
                                        <div className="pt-3  pl-[26px] ml-2.5 border-l border-slate-200">
                                            {/* <div className="border border-slate-200 rounded-[6px]   shadow-boxShadow-12">
                                <div className={`flex flex-col pb-3 pt-3 px-6`}>
                                    <p className="text-g-75 text-sm font-rocGroteskMedium">
                                        Subject: Purchase Order Confirmation - PO 001 - From ABC
                                        Corporation to Kogan Hi Kogan, I hope this email finds
                                        you well. We are currently in the process of planning
                                        our next production run and wanted to discuss pricing
                                        and potential discounts with you. As we prepare to place
                                        an order for a specific quantity, I wanted to confirm
                                        that we have initiated an inquiry regarding not only
                                        pricing and potential discounts but also other relevant
                                        details pertaining to our upcoming production run. This
                                        includes any specifications, delivery schedules, and
                                        additional services you may offer. Looking forward to
                                        hearing from you soon. Best regards, Augustine Asiuwhu
                                    </p>
                                    <div className="space-x-2 flex items-center py-2 px-3 border-slate-200 rounded shadow-faintShadow_1 border w-fit mt-2">
                                        {displayFileIcon("pdf", false, "!w-4 !h-4")}
                                        <span className="text-sm font-rocGroteskMedium">
                                            PR 001
                                        </span>
                                    </div>
                                </div>
                                <div className="border-t rounded-b-[6px] border-slate-200 px-6 py-3 bg-slate-50 flex items-center space-x-2">
                                    <i className="ri-sparkling-2-fill text-base text-slate-500"></i>
                                    <span className="text-sm font-rocGroteskMedium text-slate-500">
                                        Synth has created a purchase request for you and sent it
                                        to the right team members for approval
                                    </span>
                                </div>
                            </div> */}
                                        </div>
                                    </div>
                                )}
                            </>
                        )}

                        {((typeof replishmentStock.purchaseOrder === "object" &&
                            Object.keys(replishmentStock.purchaseOrder).length > 0) ||
                            (Array.isArray(replishmentStock.purchaseOrder) &&
                                replishmentStock.purchaseOrder.length > 0)) && (
                            <div>
                                <div className="flex items-center justify-between">
                                    <div className="flex items-center space-x-4">
                                        <div className="w-5 h-5 rounded-full bg-[#FEF3C7] flex items-center justify-center">
                                            <i className="ri-hourglass-fill text-[#D97706] text-[10px]"></i>
                                        </div>
                                        <span className="text-base font-rocGroteskMedium text-g-75">
                                            Awaiting shipment(s) from supplier
                                        </span>
                                    </div>
                                    <i className="ri-arrow-down-s-line text-lg text-gm-50"></i>
                                </div>
                                <div
                                    className={`py-3 pl-[26px] ml-2.5 border-l
                                    }  border-slate-200`}
                                >
                                    <div className="border border-slate-200 rounded-[6px] py-4 px-6 shadow-boxShadow-12">
                                        <p className="text-g-75 font-rocGroteskMedium text-sm">
                                            We're expecting your shipment(s)
                                        </p>
                                        {replishmentStock?.shipments?.length > 0 ? (
                                            <div className="mt-2">
                                                {replishmentStock?.shipments?.map(
                                                    (shipment, idx) => (
                                                        <div
                                                            key={idx}
                                                            className="flex items-center space-x-3 border px-3 py-2 w-fit rounded border-slate-200 shadow-faintShadow_1 "
                                                        >
                                                            <i
                                                                className={` ${
                                                                    shipment?.shipmentMode?.toLowerCase() ===
                                                                        "ocean" && "ri-ship-line"
                                                                } ${
                                                                    shipment?.shipmentMode?.toLowerCase() ===
                                                                        "land" && "ri-truck-line"
                                                                } ${
                                                                    shipment?.shipmentMode?.toLowerCase() ===
                                                                        "air" && "ri-plane-fill"
                                                                } text-gm-50`}
                                                            >
                                                                {" "}
                                                            </i>
                                                            <p className="text-sm text-gm-50 font-rocGroteskMedium">
                                                                SYN{shipment?.taId}:{" "}
                                                                {shipment?.origin?.address} →{" "}
                                                                {
                                                                    shipment?.destination?.address
                                                                        ?.country
                                                                }{" "}
                                                            </p>
                                                            {/* <i className="ri-delete-bin-5-line text-slate-500"></i> */}
                                                        </div>
                                                    )
                                                )}
                                                {replishmentStock?.purchaseOrder?.approvalStatus?.toLowerCase() ===
                                                    "approved" && (
                                                    <Button
                                                        btnText="Add shipment(s)"
                                                        btnClassname="!px-4 !mt-4 !py-2.5 !w-fit"
                                                        onClick={onClickShipment}
                                                    />
                                                )}
                                            </div>
                                        ) : (
                                            <div className="mt-2 flex space-x-4 items-center ">
                                                {replishmentStock?.purchaseOrder?.approvalStatus?.toLowerCase() ===
                                                    "approved" && (
                                                    <Button
                                                        btnText="Add shipment(s)"
                                                        btnClassname="!px-4 !mt-4 !py-2.5 !w-fit"
                                                        onClick={onClickShipment}
                                                    />
                                                )}
                                                {/* <div className="flex items-center space-x-2">
                                                    <VendorAvatar
                                                        name="Silverstone technology"
                                                        imageSrc=""
                                                        size={28}
                                                    />
                                                    <span className="block text-sm font-rocGroteskMedium">
                                                        Silverstone technology
                                                    </span>
                                                    <i className="ri-arrow-down-s-line text-lg text-gm-50"></i>
                                                </div> */}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}

                        {Array.isArray(replishmentStock.shipments) &&
                            replishmentStock.shipments?.length > 0 && (
                                <div>
                                    <div className="flex items-center justify-between">
                                        <div className="flex items-center space-x-4">
                                            <div className="w-5 h-5 rounded-full bg-[#FEF3C7] flex items-center justify-center">
                                                <i className="ri-hourglass-fill text-[#D97706] text-[10px]"></i>
                                            </div>
                                            <span className="text-base font-rocGroteskMedium text-g-75">
                                                Add inspection report or any other documents related
                                                to the shipment(s)
                                            </span>
                                        </div>
                                        <i className="ri-arrow-down-s-line text-lg text-gm-50"></i>
                                    </div>
                                    <div className="py-3 pl-[26px] ml-2.5 border-l border-slate-200">
                                        <div className="border border-slate-200 rounded-[6px] py-4 px-6 shadow-boxShadow-12">
                                            <p className="text-g-75 font-rocGroteskMedium text-sm">
                                                Please submit any inspection reports or relevant
                                                documents related to the shipment(s). These
                                                documents are crucial for maintaining quality
                                                control and ensuring compliance.
                                            </p>
                                            {allAttachments?.length > 0 ? (
                                                <div className="mt-2 space-y-2">
                                                    {allAttachments?.map((doc, idx) => (
                                                        <div
                                                            key={idx}
                                                            className="flex items-center space-x-3 border px-3 py-2 w-fit rounded border-slate-200 shadow-faintShadow_1 "
                                                        >
                                                            {displayFileIcon(
                                                                "pdf",
                                                                true,
                                                                "w-[20px] h-[20px]"
                                                            )}
                                                            <p className="text-sm text-gm-50 font-rocGroteskMedium">
                                                                {doc?.filename}
                                                            </p>
                                                            {/* <div>
                                                                {deletingFile ? (
                                                                    <Loader />
                                                                ) : (
                                                                    <i
                                                                        className="ri-delete-bin-5-line text-slate-500 cursor-pointer"
                                                                        onClick={() =>
                                                                            handleRemoveUpload(idx)
                                                                        }
                                                                    ></i>
                                                                )}
                                                            </div> */}
                                                        </div>
                                                    ))}
                                                    <Button
                                                        btnText="Add documents"
                                                        onClick={onClickDocument}
                                                        btnClassname="!px-4 !mt-4 !py-2.5 !w-fit"
                                                    />
                                                </div>
                                            ) : (
                                                <div className="mt-2 flex space-x-4 items-center ">
                                                    <Button
                                                        btnText="Add documents"
                                                        onClick={onClickDocument}
                                                        btnClassname="!px-4 !py-2.5 !w-fit"
                                                    />
                                                    {/* <Button
                                        btnText="Assign team member"
                                        btnClassname="!px-4 !py-2.5 !bg-tradeally-neutral-20 !w-fit"
                                        onClick={() => {}}
                                    /> */}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            )}

                        {Array.isArray(replishmentStock?.shipments) &&
                            replishmentStock?.shipments.length > 0 && (
                                <div>
                                    <div className="flex items-center justify-between">
                                        <div className="flex items-center space-x-4">
                                            <div className="w-5 h-5 rounded-full bg-[#FEF3C7] flex items-center justify-center">
                                                <i className="ri-hourglass-fill text-[#D97706] text-[10px]"></i>
                                            </div>
                                            <span className="text-base font-rocGroteskMedium text-g-75">
                                                Add stock to inventory
                                            </span>
                                        </div>
                                        <i className="ri-arrow-down-s-line text-lg text-gm-50"></i>
                                    </div>
                                    <div className="py-3 pl-[26px] ml-2.5">
                                        <div className="border border-slate-200 rounded-[6px] py-4 px-6 shadow-boxShadow-12">
                                            <span className="block text-sm text-g-75 font-rocGroteskMedium">
                                                After shipment is added, Synth will automatically
                                                add products from shipment(s) to inventory
                                            </span>
                                            <Button
                                                btnText="Add stock"
                                                onClick={() =>
                                                    navigate(
                                                        "/dashboard/inventory/stock-control/stock-entry?channel=shipment&currTab=1"
                                                    )
                                                }
                                                btnClassname="!px-8 !mt-3 !py-2.5 !w-fit"
                                            />
                                        </div>
                                    </div>
                                </div>
                            )}
                    </div>
                );

            case 1:
                return <IncidentsComments inventoryIncidentId={incidentId} />;

            case 2:
                return (
                    <MarketInsight
                        productName={data?.inventory?.productName}
                        isModalShow={modalShow}
                    />
                );

            case 3:
                return (
                    <PreviousAlerts
                        isFetching={isFetching}
                        data={data?.previousIncident}
                        pagination={pagination}
                        isLoadingMore={isLoadingMore}
                        isModalShow={modalShow}
                    />
                );

            default:
                break;
        }
    };
    const displayOverstockingActiveTab = () => {
        switch (activeTab) {
            case 0:
                return <SuggestedActions />;
            case 1:
                return <IncidentsComments inventoryIncidentId={incidentId} />;

            case 2:
                return (
                    <MarketInsight
                        productName={data?.inventory?.productName}
                        isModalShow={modalShow}
                    />
                );

            case 3:
                return (
                    <PreviousAlerts
                        isFetching={isFetching}
                        data={data?.previousIncident}
                        pagination={pagination}
                        isLoadingMore={isLoadingMore}
                        isModalShow={modalShow}
                    />
                );

            default:
                break;
        }
    };

    return (
        <div>
            <div className="px-6">
                <TabsContainer
                    tabs={tabs}
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                    component={
                        data?.incidentType?.toLowerCase() === "stockout"
                            ? displayActiveTab()
                            : displayOverstockingActiveTab()
                    }
                    className="!px-0"
                    itemClassName="!pb-2 max-sm:whitespace-nowrap"
                    borderLineClase={"!text-slate-100 w-[100%] max-sm:w-full max-lg:w-[65%] mb-6"}
                />
            </div>
        </div>
    );
};

export default AlertTabDetails;
