import React, { useEffect, useState } from "react";
import AuthFrame from "./AuthFrame";
import TextInput from "components/common/InputField/TextInput";
import Button from "components/common/Button";
import { Link, useNavigate } from "react-router-dom";
import { authActions } from "redux/Ldb/actions";
import config from "config/config";
import Checkbox from "components/common/InputField/Checkbox";
import { useAppDispatch, useAppSelector } from "../../../hooks/storeHooks";
import { getFromStorage } from "helpers";
import BookADemoModal from "components/views/Landing/BookADemoModal";

const Login = () => {
    const [loginInfo, setLoginInfo] = useState({
        email: "",
        password: "",
        remember: false,
    });
    const [openModal, setOpenModal] = useState<boolean>(false);

    const dispatch = useAppDispatch();
    const redirectUrl = getFromStorage("redirect-url");
    const navigate = useNavigate();
    const { userLoggingIn, userLoggedInSuccess, fetchUserProfileRequest, fetchUserProfileSuccess } =
        useAppSelector((state) => state.auth);

    const handleChange = (evt) => {
        const { name, value } = evt.target;

        setLoginInfo((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const body = {
            email: loginInfo.email,
            password: loginInfo.password,
        };

        dispatch(authActions.loginUser(body, loginInfo.remember));
    };

    useEffect(() => {
        if (Boolean(userLoggedInSuccess)) {
            const hasOnboarded =
                Boolean(userLoggedInSuccess?.user?.profile?.businessName) &&
                Boolean(userLoggedInSuccess?.user?.profile?.country) &&
                Boolean(userLoggedInSuccess?.user?.profile?.businessType) &&
                Boolean(userLoggedInSuccess?.user?.fullName) &&
                Boolean(userLoggedInSuccess?.user?.profile?.address);

            if (hasOnboarded) {
                navigate(
                    redirectUrl && redirectUrl !== "/new-onboard" ? redirectUrl : "/dashboard/home"
                );
            } else if (userLoggedInSuccess?.user?.role?.toLowerCase() !== "owner") {
                navigate(
                    redirectUrl && redirectUrl !== "/new-onboard" ? redirectUrl : "/dashboard/home"
                );
            } else {
                navigate("/new-onboard");
            }
        }
    }, [userLoggedInSuccess]);

    // useEffect(() => {
    //     if (Boolean(userLoggedInSuccess) && !hasOnboarded) {
    //         navigate("/new-onboard", { replace: true });
    //     }

    //     if (Boolean(userLoggedInSuccess) && hasOnboarded) {
    //         navigate("/dashboard/home", { replace: true });
    //     }
    // }, [navigate, userLoggedInSuccess, hasOnboarded]);

    const hangleGoogleLogin = () => {
        window.open(`${config.API_URL}/api/v1/ldb/auth/google/login`);
    };

    return (
        <AuthFrame>
            <div className="w-full h-fit bg-white shadow-dropShadow-cookie p-[40px] rounded-[8px]">
                <div className="mb-[38px] flex flex-col items-center justify-center">
                    <Link to={"/"}>
                        <img
                            src={
                                "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1715104205/SynthLogo_h118c2.svg"
                            }
                            alt="Logo Icon"
                            className="mb-2 "
                            height={26.4}
                            width={88}
                        />
                    </Link>
                    <p className="text-[26px] font-flame font-normal text-center  text-[#013D28]">
                        Login to your account
                    </p>
                </div>
                <form onSubmit={handleSubmit}>
                    <TextInput
                        value={loginInfo.email}
                        name={"email"}
                        type={"email"}
                        placeholder={"Email address"}
                        onChange={handleChange}
                        required={true}
                        containerClassname="mb-6 "
                        inputContainerClassname="!rounded-lg"
                    />
                    <TextInput
                        value={loginInfo.password}
                        name={"password"}
                        type={"password"}
                        onChange={handleChange}
                        placeholder={"Password"}
                        required={true}
                        containerClassname="mb-2"
                        inputContainerClassname="!rounded-lg"
                    />
                    <div className="flex items-center justify-end mb-6">
                        {/* <div className="flex items-center gap-2">
                            <Checkbox
                                name="remember"
                                isChecked={loginInfo.remember}
                                onChange={(evt) => {
                                    handleChange({
                                        target: {
                                            name: "remember",
                                            value: evt.target.checked,
                                        },
                                    });
                                }}
                                className={"!h-[14px] !w-[14px] !rounded-[4px]"}
                            />
                            <label
                                htmlFor="remember"
                                className="text-xs text-gm-45 font-rocGroteskRegular mt-[2px]"
                            >
                                Remember password
                            </label>
                        </div> */}
                        <Link
                            to={"/forgot-password"}
                            className="text-sm font-interDisplay font-medium text-[#013D28]"
                        >
                            Forgot Password?
                        </Link>
                    </div>

                    <div className="w-full mb-6">
                        <Button
                            isLoading={userLoggingIn}
                            btnText={"Login"}
                            type={"submit"}
                            btnClassname={
                                "!h-[48px] !text-base  !rounded-md !px-6 !border-[2px] !border-[#FFD25B80] !font-flame !shadow-[0px_-3px_0px_0px_#0000001A_inset]"
                            }
                        />
                    </div>
                    <div>
                        <p className="text-sm font-rocGroteskMedium flex items-center space-x-1.5 justify-center">
                            <span className="text-slate-500">Not Registered Yet?</span>
                            <span
                                onClick={() => setOpenModal(true)}
                                className="underline text-green-2 cursor-pointer"
                            >
                                Request Access Here
                            </span>
                        </p>
                    </div>
                    {/* <div className="flex items-center gap-[10px] w-full mb-6">
                        <hr className="w-[46%]" /> <span className="text-sm">or</span>{" "}
                        <hr className="w-[46%]" />
                    </div>
                    <div className="w-full mb-6">
                        <Button
                            btnClassname={"!bg-white border"}
                            onClick={hangleGoogleLogin}
                            btnCustomContent={
                                <div className="flex items-center gap-2">
                                    <img
                                        src={
                                            "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1690905410/Web%20App/google_logo_u0iqig.svg"
                                        }
                                        width={24}
                                        height={24}
                                        alt="google logo"
                                    />
                                    <span className="text-sm font-rocGroteskRegular text-dark-gray-2">
                                        Log in with google
                                    </span>
                                </div>
                            }
                            type={"button"}
                        />
                    </div> */}
                    {/* <p className="text-center">
                        <span className="text-xs text-gm-45 font-rocGroteskRegular">
                            Don't have a SynthAlly account yet?
                        </span>{" "}
                        <Link
                            to={"/register"}
                            className="text-xs text-carribean-green font-rocGroteskBold cursor-pointer"
                        >
                            Get started
                        </Link>
                    </p> */}
                </form>
            </div>

            <BookADemoModal openModal={openModal} setOpenModal={setOpenModal} />
        </AuthFrame>
    );
};

export default Login;
