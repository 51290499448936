/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useMemo, useRef, useState } from "react";
import Loader from "../Loader";

type MultiselectInputProps = {
    label?: string;
    handleChange?: (name: string | null, value: string | null, selectedItems?: string[]) => void;
    name?: string;
    value?: string | string[];
    placeholder?: string;
    inputPlaceholder?: string;
    dropdownOptions?: Array<{
        label: string | JSX.Element;
        value: string;
        icon?: JSX.Element;
        subText?: string;
        required?: boolean;
        role?: string;
        subLabel?: string;
    }>;
    isRequired?: boolean;
    className?: string;
    id?: string;
    labelClassName?: string;
    dropdownClassName?: string;
    optionItemClassName?: string;
    optionItemContainerClassName?: string;
    activeOptionItemContainerClassName?: string;
    switchOptions?: any;
    searchLoading?: boolean;
    handleInputChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    customDropdownContainer?: JSX.Element;
    labelClasses?: string;
    roleClasses?: string;
    addModalButton?: boolean;
    onAddNewMember?: () => void;
    subLabelClass?: string;
};

const MultiselectInput = ({
    label,
    handleChange,
    name,
    value,
    placeholder,
    inputPlaceholder,
    dropdownOptions,
    isRequired,
    className,
    id,
    labelClassName,
    dropdownClassName,
    optionItemClassName,
    optionItemContainerClassName,
    activeOptionItemContainerClassName,
    switchOptions,
    searchLoading,
    handleInputChange,
    customDropdownContainer,
    labelClasses,
    roleClasses,
    addModalButton,
    onAddNewMember,
    subLabelClass,
}: MultiselectInputProps) => {
    const [open, setOpen] = useState(false);
    const [isTyping, setIsTyping] = useState(false);
    const [isFocused, setIsFocused] = useState(false);
    const [selectedIcon, setSelectedIcon] = useState<string | JSX.Element | null>(null);
    const [selectedItems, setSelectedItems] = useState<string[] | string | undefined>([]);
    const [selectedOpts, setSelectedOpts] = useState<{ [key: string]: any }[]>([]);
    const [requiredItems, setRequiredItems] = useState<
        { value: string; required: boolean | undefined }[] | undefined
    >([]);
    const [filter, setfilter] = useState<string>("");
    const customDropdown = document.getElementById("custom-select");
    const selectedOption = dropdownOptions?.find(
        (item) => item.value === value && (item?.label as string).includes(filter)
    );

    const ref = useRef<HTMLDivElement>(null);
    const inputRef = useRef(null);
    const toggleRef = useRef(null);

    const filteredOptions = isTyping
        ? dropdownOptions?.filter((item) => {
              return (
                  (item?.label as string)?.toLowerCase().includes(filter.toLowerCase()) ||
                  item?.value?.toLowerCase().includes(filter.toLowerCase())
              );
          })
        : dropdownOptions;

    const getRequiredValues = () => {
        const selected = dropdownOptions?.filter((item) => item?.required);
        const selectedArr = selected?.map((item) => item?.value);
        const requiredArr = selected?.map((item) => ({
            value: item?.value,
            required: item?.required,
        }));

        setSelectedItems(requiredArr?.length > 0 ? selectedArr : (selectedItems as string[]));
        setRequiredItems(requiredArr);
        handleChange?.(
            name,
            null,
            requiredArr?.length > 0 ? selectedArr : (selectedItems as string[])
        );

        return selectedArr;
    };

    const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsTyping(true);
        setOpen(true);
        // handleChange?.(name as string, "");
        setSelectedIcon(null);
        setfilter(event.target.value);
    };

    const handleSelect = (label: string | React.ReactNode, value: string, icon: JSX.Element) => {
        const selected = dropdownOptions?.find(
            (item) => item?.value === value && item?.label === label
        );
        const selectedItemsCopy = [...(selectedItems as string[])];
        const selectedItemIndex = selectedItemsCopy.findIndex(
            (item) => item?.toLowerCase() === selected?.value?.toLowerCase()
        );

        if (selectedItemIndex !== -1) {
            selectedItemsCopy.splice(selectedItemIndex, 1);
        } else {
            selectedItemsCopy.push(value);
        }
        setSelectedItems(selectedItemsCopy);
        handleChange?.(name as string, value, selectedItemsCopy);
        setSelectedOpts((prev) => [...prev, selected]);
        handleInputChange?.({ target: { value: "" } } as React.ChangeEvent<HTMLInputElement>);
        setSelectedIcon(icon);
        setfilter("");
        setIsTyping(false);
    };

    useEffect(() => {
        getRequiredValues();
    }, [switchOptions]);

    // useEffect(() => {
    //     setfilter(selectedOption?.label ?? "");
    //     if (selectedOption?.icon) {
    //         setSelectedIcon(selectedOption?.icon);
    //     }
    // }, [value]);

    useEffect(() => {
        setSelectedItems(value);
    }, [value]);

    useEffect(() => {
        setfilter((selectedOption?.label as string) ?? "");
    }, [selectedOption]);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (ref.current && event.target !== toggleRef.current) {
                if (!ref.current.contains(event.target as Node)) {
                    setOpen(false);
                }
            }

            if (!event.target) setOpen(false);
        };
        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref, open]);

    return (
        <div className="w-full">
            {Boolean(label) && (
                <p className={`text-sm mb-1 font-rocGroteskMedium ${labelClassName}`}>{label}</p>
            )}
            <div className="relative">
                <div
                    onClick={() => setOpen(!open)}
                    className={`flex justify-between cursor-pointer rounded items-center border-[0.5px]  ${
                        selectedIcon && filter && "pl-4 max-475:pl-3"
                    } pr-2 475:pr-4 outline-0 w-full h-[50px] overflow-hidden border-gm-25 text-sm font-rocGrotesk font-medium ${className}`}
                    ref={toggleRef}
                >
                    <div className="w-full h-[50px] py-2 overflow-y-auto">
                        {selectedItems && selectedItems?.length === 0 && (
                            <span className="floating-label text-sm text-gm-35 font-rocGroteskRegular">
                                {placeholder || inputPlaceholder}
                                {isRequired && !isFocused && (
                                    <span className="required text-r-50 text-sm mt-[-2px] font-rocGroteskMedium">
                                        *
                                    </span>
                                )}
                            </span>
                        )}

                        {selectedItems && selectedItems?.length > 0 && (
                            <div className="flex gap-2 flex-wrap px-4 ">
                                {(selectedItems as string[]).map((item, idx) => {
                                    const displayedItem = [
                                        ...dropdownOptions,
                                        ...selectedOpts,
                                    ]?.find((opt) => opt?.value === item);

                                    return (
                                        <div
                                            key={item}
                                            className="w-fit flex items-center gap-x-2 px-3 py-1.5  rounded bg-tradeally-neutral-20"
                                        >
                                            <div className="text-xs whitespace-nowrap font-rocGroteskMedium text-tradeally-neutral-200">
                                                {displayedItem?.label}
                                            </div>
                                            {!requiredItems?.[idx]?.required && (
                                                <span
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        const selectedItemsCopy = [
                                                            ...(selectedItems as string[]),
                                                        ];
                                                        const selectedItemIndex =
                                                            selectedItemsCopy.findIndex(
                                                                (selected) =>
                                                                    item?.toLowerCase() ===
                                                                    selected?.toLowerCase()
                                                            );

                                                        selectedItemsCopy.splice(
                                                            selectedItemIndex,
                                                            1
                                                        );
                                                        setSelectedOpts(
                                                            dropdownOptions?.filter((opt) =>
                                                                selectedItemsCopy?.includes(
                                                                    opt?.value
                                                                )
                                                            )
                                                        );

                                                        setSelectedItems(selectedItemsCopy);

                                                        handleChange?.(
                                                            name,
                                                            null,
                                                            selectedItemsCopy
                                                        );
                                                    }}
                                                    className="material-icons text-sm cursor-point"
                                                >
                                                    close
                                                </span>
                                            )}
                                        </div>
                                    );
                                })}
                            </div>
                        )}
                    </div>
                    {searchLoading ? (
                        <Loader size={5} color={"gm-50"} />
                    ) : (
                        <span
                            className={`material-icons text-2xl ${
                                isFocused ? "text-g-75" : "text-gm-25"
                            }`}
                            // ref={toggleRef}
                            // onClick={() => setOpen(!open)}
                        >
                            keyboard_arrow_down
                        </span>
                    )}
                </div>
                {open &&
                    (!searchLoading && filteredOptions && filteredOptions?.length > 0 ? (
                        <div
                            ref={ref}
                            className={`pb-2 bg-white absolute top-[101%] w-full h-auto  z-[1200]  border ${dropdownClassName}`}
                            id="custom-select"
                        >
                            <div
                                // onClick={() => setOpen(!open)}
                                className={`flex justify-between cursor-pointer items-center border-b-[0.5px] ${
                                    isFocused ? "!border-g-60 !border-b-[1px]" : "border-gm-25"
                                } ${
                                    selectedIcon && filter && "pl-4 max-475:pl-3"
                                } pr-2 475:pr-4 outline-0 w-full min-h-[50px]   text-sm font-rocGrotesk font-medium ${className}`}
                            >
                                <input
                                    className={`inputText w-full outline-0 h-full max-475:pl-3 ${
                                        selectedIcon ? "pl-2" : "pl-3"
                                    } bg-transparent font-rocGroteskRegular text-gm-50 outline-none focus:border-0 placeholder:text-sm placeholder:!font-rocGroteskRegular`}
                                    placeholder={"Search"}
                                    type={"text"}
                                    value={filter}
                                    id={id}
                                    ref={inputRef}
                                    // required={
                                    //     isRequired
                                    //         ? !Boolean((selectedItems as string[])?.length > 0)
                                    //         : isRequired
                                    // }
                                    onChange={handleFilterChange}
                                    onInput={handleInputChange}
                                    onFocus={() => setIsFocused(true)}
                                    onBlur={() => setIsFocused(false)}
                                />
                            </div>
                            <div className="max-h-64 overflow-auto">
                                {filteredOptions.map((option, idx) => {
                                    return (
                                        <div
                                            key={idx}
                                            onClick={() => {
                                                if (!option?.required) {
                                                    handleSelect(
                                                        option?.label,
                                                        option?.value,
                                                        option?.icon as JSX.Element
                                                    );
                                                }
                                            }}
                                            className={`flex items-center px-4 justify-between hover:bg-slate-100 ${
                                                selectedOption?.value === option?.value &&
                                                selectedOption?.label === option?.label &&
                                                // selectedOption?.role === option?.role &&
                                                "tradeally-blue " +
                                                    activeOptionItemContainerClassName
                                            } cursor-pointer ${optionItemContainerClassName}`}
                                        >
                                            <div
                                                className={`text-sm flex gap-3 py-3 items-center ${option.role} ${optionItemClassName}`}
                                            >
                                                {option?.icon && <span>{option?.icon}</span>}{" "}
                                                {option?.role ? (
                                                    <div className="flex flex-col ">
                                                        <p className={labelClasses}>
                                                            {option?.label}
                                                        </p>
                                                        {option?.role && (
                                                            <p className={roleClasses}>
                                                                {option?.role}
                                                            </p>
                                                        )}
                                                    </div>
                                                ) : (
                                                    <div>
                                                        <div className="font-rocGroteskMedium">
                                                            <span>{option?.label}</span>
                                                            {option?.subLabel && " - "}
                                                            {option?.subLabel && (
                                                                <span className={subLabelClass}>
                                                                    {option?.subLabel}
                                                                </span>
                                                            )}
                                                        </div>
                                                        {option?.subText && (
                                                            <div className="text-xs font-rocGroteskMedium text-slate-500">
                                                                {option?.subText}
                                                            </div>
                                                        )}
                                                    </div>
                                                )}
                                            </div>
                                            {selectedItems?.includes(option?.value) ? (
                                                <i className="ri-checkbox-fill before:content-['\eb82'] text-xl mt-[-2px] text-carribean-green"></i>
                                            ) : (
                                                <i className="ri-checkbox-blank-line before:content-['\eb7f'] text-xl mt-[-2px] text-gm-25"></i>
                                            )}
                                        </div>
                                    );
                                })}
                            </div>
                            {addModalButton && (
                                <div
                                    className="px-5 cursor-pointer space-x-1 text-g-50 text-[14px] font-rocGroteskMedium flex items-center"
                                    onClick={onAddNewMember}
                                >
                                    <i className="ri-add-line text-[18px]"></i>
                                    <p>Invite new member</p>
                                </div>
                            )}

                            {customDropdownContainer && <div>{customDropdownContainer}</div>}
                        </div>
                    ) : (
                        <div
                            className={`py-2 bg-white absolute top-[101%] w-full h-auto max-h-64 z-[999] overflow-auto border ${dropdownClassName}`}
                            id="custom-select"
                            ref={ref}
                        >
                            <div
                                // onClick={() => setOpen(!open)}
                                className={`flex justify-between cursor-pointer items-center border-b-[0.5px] ${
                                    isFocused ? "!border-g-60 !border-b-[1px]" : "border-gm-25"
                                } ${
                                    selectedIcon && filter && "pl-4 max-475:pl-3"
                                } pr-2 475:pr-4 outline-0 w-full min-h-[50px]   text-sm font-rocGrotesk font-medium ${className}`}
                            >
                                <input
                                    className={`inputText w-full outline-0 h-full max-475:pl-3 ${
                                        selectedIcon ? "pl-2" : "pl-3"
                                    } bg-transparent font-rocGroteskRegular text-gm-50 outline-none focus:border-0 placeholder:text-sm placeholder:!font-rocGroteskRegular`}
                                    placeholder={"Search"}
                                    type={"text"}
                                    value={filter}
                                    id={id}
                                    ref={inputRef}
                                    // required={
                                    //     isRequired
                                    //         ? !Boolean((selectedItems as string[])?.length > 0)
                                    //         : isRequired
                                    // }
                                    onChange={handleFilterChange}
                                    onInput={handleInputChange}
                                    onFocus={() => setIsFocused(true)}
                                    onBlur={() => setIsFocused(false)}
                                />
                            </div>
                            <div className="flex px-4 justify-between hover:tradeally-blue cursor-pointer">
                                <p className="text-sm flex gap-3 py-3 items-center font-rocGroteskRegular">
                                    <span>{searchLoading ? "Searching..." : "No Data"}</span>
                                </p>
                            </div>
                            {customDropdownContainer && <div>{customDropdownContainer}</div>}
                        </div>
                    ))}
            </div>
        </div>
    );
};

export default MultiselectInput;
