import Button from "components/common/Button";
import React, { useEffect, useState } from "react";

const CookiePrompt = () => {
    const cookies = localStorage.getItem("cookies");
    const [showPrompt, setShowPrompt] = useState<string | boolean>(cookies ?? false);

    useEffect(() => {
        setShowPrompt(cookies as string);
    }, [cookies]);

    return !showPrompt ? (
        <div className="fixed bottom-3 left-0 right-0 z-[1002]">
            <div className=" p-[24px] mx-[5%] md:mx-[10%]  max-w-[520px] rounded-[8px] bg-[#FFFFFF] shadow-md drop-shadow-dropShadow-cookie">
                <div>
                    <div className="flex justify-between items-center mb-2">
                        <p className="md:text-[24px] text-lg sm:text-[20px] sm:leading-[24px] md:leading-[28px] text-[#013D28] font-flame">
                            Your privacy matters to us
                        </p>
                        <i
                            className="ri-close-fill cursor-pointer text-end text-[20px] text-[#013D28]"
                            onClick={() => {
                                setShowPrompt(true);
                                localStorage.setItem("cookies", "false");
                            }}
                        ></i>
                    </div>
                    <p className="md:text-base text-sm text-[#013D28] font-[600] font-interDisplay mb-6">
                        Synth uses cookies to enhance your browsing experience, analyze site
                        traffic, and personalize content. By clicking "Accept all Cookies," you
                        agree to the use of cookies on this website. These cookies are essential for
                        the proper functioning of Synth and help us improve our services.
                    </p>
                    <div className="flex md:flex-row flex-col md:items-center gap-4">
                        <Button
                            btnText={"Accept all Cookies"}
                            onClick={() => {
                                setShowPrompt(true);
                                localStorage.setItem("cookies", "true");
                            }}
                            btnClassname={
                                "!h-[48px] !text-base !md:w-fit !rounded-md !px-6  !border-[#FFD25B80] !font-flame !shadow-[0px_-3px_0px_0px_#0000001A_inset]"
                            }
                        />
                        <Button
                            btnText={"Decline Cookies"}
                            onClick={() => {
                                setShowPrompt(true);
                                localStorage.setItem("cookies", "false");
                            }}
                            btnClassname={
                                "!h-[48px] !text-base !md:w-fit !bg-gradient-to-r from-[#EFEFEF] from-100% to-[#E5E5E5] to-100% !rounded-md !px-6  !border-[#FFD25B80] !font-flame !shadow-btnShadow-4"
                            }
                        />
                    </div>
                </div>
            </div>
        </div>
    ) : null;
};

export default CookiePrompt;
