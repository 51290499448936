import {ReactMultiEmail} from "react-multi-email";
import "react-multi-email/dist/style.css";
import React from "react";

type EmailInputProps = {
    label?: string;
    emails?: string[];
    setEmails: React.Dispatch<React.SetStateAction<string[]>>;
    required?: boolean;
    placeholder?: string;
    inputPlaceholder?: string;
};

const EmailInput = ({
    label,
    emails,
    setEmails,
    required,
    placeholder,
    inputPlaceholder,
}: EmailInputProps) => {
    const [focused, setFocused] = React.useState(false);

    return (
        <div>
            {label && <p className="text-sm mb-1 font-rocGroteskMedium">{label}</p>}
            <div
                className={`w-full  relative border-[0.5px] border-gm-25 h-fit ${
                    focused ? "!border-p-60 !border-[0.5px] !border-b-[1px]" : ""
                } rounded-[2px] flex items-center `}
            >
                <div className="w-full">
                    <ReactMultiEmail
                        emails={emails}
                        placeholder={inputPlaceholder}
                        onChange={(_emails) => {
                            setEmails(_emails);
                        }}
                        className={`inputText  !border-0 bg-transparent rounded !outline-0 w-full  px-4 !pt-3 text-sm text-gm-50 font-rocGroteskRegular placeholder:text-gm-35 placeholder:font-rocGroteskRegular`}
                        autoFocus={true}
                        onFocus={() => setFocused(true)}
                        onBlur={() => setFocused(false)}
                        getLabel={(email, index, removeEmail) => {
                            return (
                                <div data-tag key={index} className="!mt-3">
                                    <div data-tag-item>{email}</div>
                                    <span data-tag-handle onClick={() => removeEmail(index)}>
                                        ×
                                    </span>
                                </div>
                            );
                        }}
                    />
                    {placeholder && (
                        <span
                            className={`floating-label mb-3 text-sm text-gm-35 font-rocGroteskRegular`}
                        >
                            {placeholder}
                            {required && !focused && (
                                <span className="required text-r-50 text-sm mt-[-2px] font-rocGroteskMedium">
                                    *
                                </span>
                            )}
                        </span>
                    )}
                </div>
            </div>
        </div>
    );
};

export default EmailInput;
