import { useEffect, useState } from "react";
import { inventoryActions } from "redux/Ldb/actions";
import { useAppDispatch, useAppSelector } from "./storeHooks";
import { useParams } from "react-router-dom";
import useInfiniteScroll from "./useInfinteScroll";

const useGetSingleProductWarehouses = (
    warehouseId?: string,
    setDeleteWarehouseShow?: React.Dispatch<React.SetStateAction<boolean>>
) => {
    const dispatch = useAppDispatch();
    const { productId } = useParams();
    const {
        fetchedInventoryWarehouseSuccess,
        fetchingInventoryWarehouse,
        fetchedInventoryWarehouseFailure,
        deleteWarehouse,
        deleteWarehouseSuccess,
    } = useAppSelector((state) => state.inventory);

    const [warehouses, setWarehouses] = useState<{ [key: string]: any }[]>([]);
    const [pagination, setPagination] = useState<{ currentPage: string; noOfPages: string }>({
        currentPage: "1",
        noOfPages: "",
    });

    const { updatedLimit, isLoadingMore, setLoadMore, updatedPage } = useInfiniteScroll(
        { current: pagination.currentPage, number_of_pages: pagination.noOfPages },
        24
    );

    const deleteProductWarehouse = () => {
        dispatch(inventoryActions.deleteWarehouse(warehouseId));
    };

    useEffect(() => {
        if (Boolean(deleteWarehouseSuccess)) {
            dispatch(inventoryActions.resetDeleteWarehouse());
            // dispatch(inventoryActions.fetchWarehouseList(12));
            setDeleteWarehouseShow?.(false);
        }
    }, [dispatch, deleteWarehouseSuccess]);

    useEffect(() => {
        dispatch(
            inventoryActions.fetchInventoryWarehouse(productId, updatedLimit, "", updatedPage)
        );

        return () => {
            dispatch(inventoryActions.resetFetchInventoryWarehouse());
        };
    }, [dispatch, productId, updatedLimit, updatedPage]);

    useEffect(() => {
        if (Boolean(fetchedInventoryWarehouseSuccess)) {
            setWarehouses((prev) => [...prev, ...fetchedInventoryWarehouseSuccess?.warehouses]);
            setPagination({
                currentPage: fetchedInventoryWarehouseSuccess.pagination?.current,
                noOfPages: fetchedInventoryWarehouseSuccess.pagination?.number_of_pages,
            });
            setLoadMore(false);
        }
    }, [fetchedInventoryWarehouseSuccess]);

    return {
        isFetching: fetchingInventoryWarehouse,
        error: fetchedInventoryWarehouseFailure,
        data: warehouses,
        pagination,
        isLoadingMore,
        deleteWarehouseFunc: deleteProductWarehouse,
        isDeletingWarehouse: deleteWarehouse,
    };
};

export default useGetSingleProductWarehouses;
