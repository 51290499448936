import React, { useEffect, useRef, useState } from "react";
import MultipleLineChart from "./MultipleLineChart";
import useGetProductList from "hooks/inventoryHooks/useGetProductList";
import useGetSalesChannel from "hooks/Dashboard/useGetSalesChannel";
import { getDashboardChartData } from "helpers";
import Button from "./Button";
import CustomCalendar from "./CalendarComp.js/Calendar";
import SelectInput from "./InputField/SelectInput";
import ModalContainer from "./ModalContainer";
import DateInput from "./DateInput";
import millify from "millify";
import { AnalyticsCard } from "components/views/Ldb/Dashboard/BusinessAnalytics/Card";
import { useNavigate } from "react-router-dom";
import { productCategories } from "variables/productCategories";

type ForecastLineChartProps = {
    graphData?: { [key: string]: any };
    isExpanded?: boolean;
};

const ForecastLineChart = ({ graphData, isExpanded }: ForecastLineChartProps) => {
    const navigate = useNavigate();
    const {
        data: productData,
        handleDebouncedChange,
        handleSearch,
        isFetching: productListLoading,
        search,
        setSearch,
        setDebouncedSearch,
        pagination,
        isLoadingMore,
    } = useGetProductList();

    const { data: salesChannel, isFetching: salesChannelLoading } = useGetSalesChannel();

    const [chartData, setChartData] = useState<{ [key: string]: any }>([]);
    const [chartFilter, setChartFilter] = useState<string>("yearly");

    const [customValue, customOnChange] = useState(new Date());
    const [showCustomDate, setShowCustomDate] = useState<boolean>(false);
    const [startAndEndDate, setStartAndEndDate] = useState<string[]>([]);
    const [customFilter, setCustomFilter] = useState<string>("");

    const [forecastModal, setForecastModal] = useState<boolean>(false);

    const customRef = useRef(null);

    useEffect(() => {
        if (graphData) {
            let chartDataValues;
            if (customFilter === "custom" && startAndEndDate?.length === 2) {
                chartDataValues = getDashboardChartData(graphData, "custom", startAndEndDate);
            } else {
                chartDataValues = getDashboardChartData(graphData, chartFilter);
            }

            setChartData(chartDataValues);
        }
    }, [chartFilter, startAndEndDate, graphData]);

    return (
        <div>
            <div
                className={`bg-white ${
                    isExpanded && "border border-slate-200 rounded-xl "
                } py-[15px] px-5 mt-[20px] mb-10`}
            >
                <div className="flex flex-wrap justify-between">
                    <p className="text-g-75 font-rocGroteskMedium text-lg">Forecast</p>
                    <div className="">
                        <div className="flex flex-wrap gap-[10px]">
                            {/* <Dropdown
                                handleChange={(name, value) =>
                                    handleCategoryValue({
                                        target: { name, value },
                                    })
                                }
                                dropdown={
                                    <div className="flex items-center justify-between rounded-[6px] shadow-buttonShadow2 py-[6px] px-[10px] gap-2 !border !border-slate-100">
                                        <div
                                            className={`text-g-75 font-rocGroteskMedium text-[13px] inline-block`}
                                        >
                                            {categoryFilter ? categoryFilter : "All product types"}
                                        </div>
                                        <i className="ri-arrow-down-s-line text-lg text-g-75"></i>
                                    </div>
                                }
                                disabled={gwmLoading}
                                dropdownItemsClasses="!px-0 !py-0"
                                dropdownClassName="!mb-10 !-mt-7"
                                dropdownContainerClasses={`shadow-cardShadow-4 border border-slate-100 right-0 rounded-[6px]  !w-[150px] !min-w-min
                            `}
                                name="valueRange"
                                dropdownOptions={[
                                    {
                                        label: (
                                            <div
                                                className=" text-g-75 font-rocGroteskMedium text-[13px] flex justify-between
                                                border-b border-b-slate-100 !w-[150px] px-4 py-[10px]"
                                            >
                                                All product types
                                            </div>
                                        ),
                                        value: "",
                                    },
                                    ...productCategories.map((item, idx) => ({
                                        label: (
                                            <div
                                                key={idx}
                                                className=" text-g-75 font-rocGroteskMedium text-[13px] flex justify-between
                                                border-b border-b-slate-100 !w-[150px] px-4 py-[10px]"
                                            >
                                                {item.label}
                                            </div>
                                        ),
                                        value: item.value,
                                    })),
                                ]}
                            /> */}

                            {/* <Dropdown
                                handleChange={(name, value) =>
                                    handleProductValue({ target: { name, value } })
                                }
                                dropdown={
                                    <div className="flex items-center rounded-[6px] shadow-buttonShadow2 py-[6px] px-[10px] gap-2 !border !border-slate-100">
                                        <div
                                            className={`text-g-75 font-rocGroteskMedium text-[13px] inline-block`}
                                        >
                                            {productFilter
                                                ? truncate(productFilter?.split("-")[1], {
                                                      length: 15,
                                                  })
                                                : "All products"}
                                        </div>
                                        <i className="ri-arrow-down-s-line text-lg text-g-75"></i>
                                    </div>
                                }
                                dropdownItemsClasses="!px-0 !py-0"
                                dropdownClassName="!w-full !mb-10 !-mt-7"
                                dropdownContainerClasses={`shadow-cardShadow-4 !left-0 border border-slate-100 right-0 rounded-[6px] 
                                    !min-h-[300px] !max-h-[300px] !w-auto !pt-0
                            `}
                                disabled={productListLoading || gwmLoading}
                                loader={isLoadingMore}
                                endOfList={pagination?.currentPage === pagination?.noOfPages}
                                name="valueRange"
                                search={
                                    <div ref={clearSearchRef} className="">
                                        <TextInput
                                            value={search}
                                            name={"search"}
                                            type={"text"}
                                            required={false}
                                            onChange={handleSearch}
                                            onInput={handleDebouncedChange}
                                            inputPlaceholder="Search product"
                                            containerClassname=""
                                            inputContainerClassname={"!h-[40px]"}
                                            // inputClassName="w-[50%]"
                                        />
                                    </div>
                                }
                                dropdownOptions={[
                                    {
                                        label: (
                                            <div
                                                className=" text-slate-600 font-rocGroteskMedium text-[13px] flex items-center gap-2
                                                border-b border-b-slate-100 !w-[190px] px-4 py-[10px]"
                                            >
                                                <i
                                                    className={`${
                                                        productFilter === ""
                                                            ? "ri-checkbox-line"
                                                            : "ri-checkbox-blank-line"
                                                    }   text-slate-200 text-xl`}
                                                ></i>
                                                All product
                                            </div>
                                        ),
                                        value: "",
                                    },
                                    ...(graphData?.products || productData)?.map((item, idx) => ({
                                        label: (
                                            <div
                                                key={idx}
                                                className=" text-slate-600 font-rocGroteskMedium text-[13px] gap-2 flex items-center
                                                border-b border-b-slate-100 !w-[190px] px-4 py-[10px]"
                                            >
                                                <i
                                                    className={`${
                                                        productFilter?.split("-")[0] ===
                                                        (item?.inventoryId || item?._id)
                                                            ? "ri-checkbox-line"
                                                            : "ri-checkbox-blank-line"
                                                    }   text-slate-200 text-xl`}
                                                ></i>

                                                <div className="flex flex-col">
                                                    {truncate(item?.productName, {
                                                        length: 18,
                                                    })}
                                                    <p className="text-[10px]">{` SKU No: ${item?.sku}`}</p>
                                                </div>
                                            </div>
                                        ),
                                        value:
                                            (item?.inventoryId || item?._id) +
                                            "-" +
                                            item?.productName,
                                    })),
                                ]}
                            />

                            <Dropdown
                                handleChange={(name, value) =>
                                    handleChangeValue({
                                        target: { name, value },
                                    })
                                }
                                dropdown={
                                    <div className="flex items-center justify-between rounded-[6px] shadow-buttonShadow2 py-[6px] px-[10px] gap-2 !border !border-slate-100">
                                        <div
                                            className={`text-g-75 font-rocGroteskMedium text-[13px] inline-block `}
                                        >
                                            {truncate(channelFilter, { length: 15 }) ||
                                                "All channels"}
                                        </div>
                                        <i className="ri-arrow-down-s-line text-lg text-g-75"></i>
                                    </div>
                                }
                                dropdownItemsClasses="!px-0 !py-0"
                                dropdownClassName="!mb-10 !-mt-7"
                                dropdownContainerClasses={`shadow-cardShadow-4 border border-slate-100 right-0 rounded-[6px] !max-h-[200px] !w-[190px] !min-w-min
                            `}
                                name="valueRange"
                                disabled={salesChannelLoading || gwmLoading}
                                dropdownOptions={[
                                    {
                                        label: (
                                            <div
                                                className=" text-slate-600 font-rocGroteskMedium text-[13px] flex items-center gap-2
                                                border-b border-b-slate-100 !w-[190px] px-4 py-[10px]"
                                            >
                                                <i
                                                    className={`${
                                                        channelFilter === ""
                                                            ? "ri-checkbox-line"
                                                            : "ri-checkbox-blank-line"
                                                    }   text-slate-200 text-xl`}
                                                ></i>
                                                All channels
                                            </div>
                                        ),
                                        value: "",
                                    },
                                    ...salesChannel?.map((item, idx) => ({
                                        label: (
                                            <div
                                                key={idx}
                                                className=" text-slate-600 font-rocGroteskMedium text-[13px] flex items-center gap-2
                                                border-b border-b-slate-100 !w-[190px] px-4 py-[10px]"
                                            >
                                                <i
                                                    className={`${
                                                        channelFilter === item
                                                            ? "ri-checkbox-line"
                                                            : "ri-checkbox-blank-line"
                                                    }   text-slate-200 text-xl`}
                                                ></i>
                                                {item}
                                            </div>
                                        ),
                                        value: item,
                                    })),
                                ]}
                            />

                            <Dropdown
                                handleChange={(name, value) =>
                                    handleDateChange({ target: { name, value } })
                                }
                                dropdown={
                                    <div className="flex items-center justify-between rounded-[6px] shadow-buttonShadow2 py-[6px] px-[10px] gap-2 !border !border-slate-100">
                                        <i className="ri-arrow-down-s-line text-lg text-[#142837]"></i>

                                        <div
                                            className={`text-g-75 font-rocGroteskMedium text-[13px] inline-block`}
                                        >
                                            {startAndEndDate?.length !== 0
                                                ? ` ${startAndEndDate[0]} to ${startAndEndDate[1]}`
                                                : durationFilter || "This year"}
                                        </div>
                                    </div>
                                }
                                dropdownItemsClasses="!px-0 !py-0"
                                dropdownClassName="!w-full !mb-10 !-mt-7"
                                dropdownContainerClasses={`shadow-cardShadow-4 border border-slate-100 right-0 !left-0 rounded-[6px] !max-h-fit !w-auto`}
                                name="valueRange"
                                disabled={gwmLoading}
                                dropdownOptions={[
                                    ...dateValues?.map((item, idx) => ({
                                        label: (
                                            <div
                                                key={idx}
                                                className=" text-g-75 font-rocGroteskMedium text-[13px] flex justify-between
                                                border-b border-b-slate-100 !w-[120px] px-4 py-[10px]"
                                            >
                                                {item?.period}
                                            </div>
                                        ),
                                        value: item?.value + "-" + item?.period,
                                    })),
                                ]}
                            /> */}
                            <div className="relative">
                                {showCustomDate && (
                                    <div
                                        ref={customRef}
                                        className="w-[630px]  z-[10000] absolute  right-0 top-16 px-[28px] pt-8 pb-8 bg-white
                                             rounded-2xl shadow-[0px_0px_4px_rgba(0,0,0,0.04),0px_4px_32px_rgba(0,0,0,0.06)]"
                                    >
                                        <CustomCalendar
                                            showDoubleView={true}
                                            selectRange={true}
                                            value={customValue}
                                            onChange={customOnChange}
                                        />
                                        <div className="flex justify-end">
                                            <Button
                                                btnText={"Close"}
                                                btnClassname={"!w-fit !py-2"}
                                                onClick={() => setShowCustomDate(false)}
                                            />
                                        </div>
                                    </div>
                                )}
                            </div>

                            <div className="relative">
                                <ModalContainer
                                    modalClassName="!bg-transparent absolute !w-[530px] !left-auto !right-0 top-[50px] transition ease-in-out delay-150 duration-300 !h-auto shadow-tableShadow !rounded-xl"
                                    closeModal={() => setForecastModal(false)}
                                    tailwindClassName="w-[90%] lg:w-[1000px]"
                                    open={forecastModal}
                                >
                                    <div className="bg-white  px-4 pt-[15px] pb-[25px] !w-[530px]">
                                        <div className="flex justify-between items-center border-b border-slate-100 text-slate-800 font-rocGroteskBold">
                                            <p className="text-base !leading-[23px] ">
                                                Adjust forecast
                                            </p>
                                            <i
                                                className="ri-close-line  text-2xl cursor-pointer text-slate-800"
                                                onClick={() => setForecastModal(false)}
                                            ></i>
                                        </div>

                                        <div className="space-y-2.5 mt-5">
                                            <SelectInput
                                                floatingPlaceholderClass="!text-slate-400"
                                                className="!border-gm-25"
                                                placeholder="Select influencing event"
                                                iconClassName="!text-slate-400"
                                            />
                                            <p className="text-slate-800 font-sm font-rocGroteskMedium">
                                                Select timeframe
                                            </p>
                                            <div className="grid grid-cols-2 items-center gap-2 relative">
                                                <DateInput
                                                    label="Date from"
                                                    value={""}
                                                    borderContainerClassname="w-full !border-gm-25"
                                                    borderClassname="!border-gm-25"
                                                />

                                                <DateInput label="Date to" value={""} />
                                                <div className="absolute left-[47%] z-[2] top-[20%] rounded-full w-[30px] h-[30px] shadow-buttonShadow2 flex items-center justify-center bg-white">
                                                    <i className="ri-arrow-right-line text-sm cursor-pointer text-slate-400"></i>
                                                </div>
                                            </div>
                                            <p className="text-slate-800 font-sm font-rocGroteskMedium">
                                                Select other factors
                                            </p>
                                            <SelectInput
                                                floatingPlaceholderClass="!text-slate-400"
                                                className="!border-gm-25"
                                                iconClassName="!text-slate-400"
                                                placeholder="Select channel"
                                            />
                                        </div>
                                        <div className="flex justify-between gap-3.5 mt-6">
                                            <Button
                                                btnText="Adjust Forecast"
                                                btnClassname=" !w-full  !text-[13px] !px-2 !py-4 rounded"
                                                onClick={() => setForecastModal(false)}
                                            />
                                            <Button
                                                btnText="Cancel"
                                                btnClassname="!bg-n-20 !w-full !text-[13px] !px-2 !py-4 rounded"
                                                onClick={() => setForecastModal(false)}
                                            />
                                        </div>
                                    </div>
                                </ModalContainer>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className={`flex justify-between items-start mt-8  ${
                        !isExpanded && "flex-col"
                    }`}
                >
                    <div className={`flex gap-3.5 mb-5 ${!isExpanded && "w-full"}`}>
                        <div className="flex items-center">
                            <img
                                src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1712800594/chartLine_xbqdvq.svg"
                                alt="this-trend"
                            />
                            <p className="text-slate-700 font-rocGroteskMedium text-[10px] leading-3 ml-1">
                                Current forecast
                            </p>
                        </div>

                        <div className="flex items-center">
                            <img
                                src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1712800595/chartLine2_o41vii.svg"
                                alt="this-trend"
                            />
                            <p className="text-slate-700 font-rocGroteskMedium text-[10px] leading-3 ml-1">
                                Sales forecast
                            </p>
                        </div>
                    </div>

                    <div className={`flex flex-wrap ${!isExpanded && "flex-col !w-full"}`}>
                        <div
                            className={`flex w-full lg:w-fit   ${
                                !isExpanded
                                    ? "border-b border-b-slate-200  items-center justify-between"
                                    : ""
                            }`}
                        >
                            <AnalyticsCard
                                title={"Forecasted revenue"}
                                figure={
                                    graphData?.forecastTotalSale
                                        ? millify(Number(graphData?.forecastTotalSale), {
                                              precision: 2,
                                          })
                                        : "0"
                                }
                                currency={graphData?.forecastTotalSale && "$"}
                                sign={graphData?.forecastTotalSale && "+"}
                                loader={false}
                                iconTwo
                                increaseContainerClass={"bg-g-25 rounded-[2px] px-[2px]"}
                                hideDirection={true}
                                increaseClassName="!text-g-60"
                                className={`!shadow-none !border-y-0 !border-l-0 !py-2 !pl-0 !pr-8  !rounded-none flex-1  ${
                                    !isExpanded
                                        ? ""
                                        : "!border-r border-r-slate-200 pl-[15px] w-auto"
                                }`}
                                arrUpClass={"!text-g-60"}
                                titleClassName="!text-slate-500 !text-[10px]"
                                figureClassName="!mt-2.5 !text-slate-600  !text-[20px] !leading-[28px]"
                                tooltipClassName="!max-w-[195px] !bg-gm-75  !rounded-[12px] before:!border-y-transparent before:!border-l-transparent before:!border-r-gm-75 before:!top-[40%] before:!-left-[9%] !z-[999] before:!border-[6px] !left-[120px]"
                                toolTipContent={
                                    <div className="!p-3">
                                        <p className="underline text-xs font-rocGroteskMedium mb-1">
                                            Forecasted revenue
                                        </p>
                                        <p className="text-[11px]">
                                            This is the estimated amount of money that you are
                                            likely to earn over a future period. This is calculated
                                            using external factors such as seasonality, social
                                            media, supplier lead time, and historical sales.
                                        </p>
                                    </div>
                                }
                            />
                            <div
                                className={`h-[29px] ${
                                    isExpanded && "hidden"
                                } w-[1px] bg-slate-200`}
                            ></div>

                            <AnalyticsCard
                                title={"Forecasted sales quantity"}
                                value={Math.round(graphData?.totalForecastQty)}
                                loader={false}
                                iconTwo
                                // className="!shadow-none !border-y-0 !border-l-0 lg:!border-r lg:!border-r-slate-200 !py-2 !pl-[15px] !pr-8  !rounded-none  flex-1 lg:!w-auto"
                                className={`!shadow-none !border-y-0 !border-l-0 !py-2 !pl-[15px] !pr-8  !rounded-none flex-1  ${
                                    !isExpanded
                                        ? ""
                                        : "!border-r border-r-slate-200 pl-[15px] w-auto"
                                }`}
                                titleClassName="!text-slate-500 !text-[10px]"
                                innerClassName=""
                                figureClassName="!mt-2.5 !text-slate-600  !text-[20px] !leading-[28px]"
                                tooltipClassName={`!max-w-[195px] !bg-gm-75  !rounded-[12px] before:!border-y-transparent before:!top-[40%] !z-[999] before:!border-[6px]  ${
                                    isExpanded
                                        ? "left-[120px] before:!right-auto before:!-left-[9%] before:!border-r-gm-75 before:!border-l-transparent"
                                        : "!-left-[100px]  before:!-right-[95px] before:!border-l-gm-75 before:!border-r-transparent"
                                }`}
                                toolTipContent={
                                    <div className="!p-3">
                                        <p className="underline text-xs font-rocGroteskMedium mb-1">
                                            Forecasted sales quantity
                                        </p>
                                        <p className="text-[11px]">
                                            This is the estimated number of units of all products
                                            that you are likely to sell in a future period.
                                        </p>
                                    </div>
                                }
                            />
                        </div>
                        <AnalyticsCard
                            title={"Forecasted inventory deficit"}
                            value={Math.round(graphData?.forecastedStockNeeded)}
                            loader={false}
                            iconTwo
                            addTextTwo={"(oos)"}
                            addTextTwoClass="!text-r-55 !text-xl"
                            className="!shadow-none !border-none !py-2 !pl-0 lg:!pl-[15px] !pr-8  !rounded-none !w-auto"
                            titleClassName="!text-r-55 !text-[10px]"
                            figureClassName="!mt-2.5 !text-r-55  !text-[20px] !leading-[28px] flex gap-1 items-end"
                            tooltipClassName={`!max-w-[195px] !bg-gm-75  !rounded-[12px] before:!border-y-transparent before:!border-l-transparent before:!top-[40%] !z-[999] before:!border-[6px]   ${
                                isExpanded
                                    ? "!-left-[250px] before:!-right-[6px] before:!left-auto before:!border-l-gm-75 before:!border-r-transparent"
                                    : "!left-[0px] before:!-left-[9%] before:!border-r-gm-75"
                            }`}
                            toolTipContent={
                                <div className="!p-3">
                                    <p className="underline text-xs font-rocGroteskMedium mb-1">
                                        Forecasted inventory deficit
                                    </p>
                                    <p className="text-[11px] ">
                                        This is the estimated number of units of all your products
                                        that are likely to be out of stock in a future period. These
                                        are the likely out of stock units of products with respect
                                        to the demand forecast.
                                    </p>
                                </div>
                            }
                        />
                    </div>
                </div>

                {!graphData?.forecastTotalSale &&
                !graphData?.totalForecastQty &&
                !graphData?.forecastedStockNeeded ? (
                    <div className="w-[100%] h-[400px] flex items-center justify-center">
                        <div
                            className={`flex items-center  gap-[3px] flex-col ${
                                isExpanded && "w-[353px]"
                            }  justify-center`}
                        >
                            <img
                                src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1717426949/icon_w8c4mn.png"
                                alt=""
                                className=""
                            />
                            <p className="text-g-75 font-rocGroteskBold text-base ">
                                {" "}
                                No forecast to show yet{" "}
                            </p>
                            <p className="text-slate-700 font-rocGroteskMedium text-xs !text-center">
                                Synth is yet to get enough information about your sales and
                                inventory data
                            </p>

                            <div
                                className={`flex gap-4 mt-4 px-2 ${
                                    !isExpanded && "max-2xl:flex-col"
                                }`}
                            >
                                <div className="flex-1">
                                    <Button
                                        btnText="Connect an app"
                                        btnClassname={`!bg-[#FFB902] !rounded-[5px] !px-2.5 !py-2 !text-slate-800  ${
                                            isExpanded ? "!w-[150px]" : "w-full"
                                        }`}
                                        onClick={() => navigate("integrations")}
                                        icon=<i className="ri-link !text-slate-800"></i>
                                    />
                                </div>
                                <div className="flex-1">
                                    <Button
                                        btnText="Add a new product"
                                        btnClassname={`!bg-slate-50 !rounded-[5px] !px-2.5 !py-2 !text-slate-800  ${
                                            isExpanded ? "!w-[180px]" : "w-full"
                                        }`}
                                        onClick={() => navigate("inventory/add-product")}
                                        icon=<i className="ri-add-line !text-slate-800"></i>
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="mt-20 mb-6">
                        <MultipleLineChart mapData={chartData} />
                    </div>
                )}
            </div>
        </div>
    );
};

export default ForecastLineChart;
