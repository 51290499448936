import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../storeHooks";
import { trackTraceActions } from "redux/Ldb/actions";
import { useParams } from "react-router-dom";

const useGetTrackTraceBatchTracking = () => {
    const { id } = useParams();
    const dispatch = useAppDispatch();
    const [batchIncidents, setBatchTracking] = useState<{ [key: string]: any }>([]);
    const [dateRange, setDateRange] = useState<string>("");

    const { fetchingBatchTracking, fetchBatchTrackingSuccess, fetchBatchTrackingFailure } =
        useAppSelector((state) => state.trackTrace);

    const handleDateChange = (e) => {
        setDateRange(e?.target.value);
    };
    useEffect(() => {
        if (Boolean(fetchBatchTrackingSuccess)) {
            setBatchTracking(fetchBatchTrackingSuccess?.result);
        }
    }, [fetchBatchTrackingSuccess]);

    useEffect(() => {
        if (id) {
            dispatch(trackTraceActions.getBatchTracking(id, dateRange));
        }
    }, [dispatch, id, dateRange]);

    return {
        data: batchIncidents,
        isFetching: fetchingBatchTracking,
        error: fetchBatchTrackingFailure,
        dateRange,
        handleDateChange,
    };
};

export default useGetTrackTraceBatchTracking;
