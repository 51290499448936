import Button from "components/common/Button";
import PageTitleSection from "components/common/PageTitleSection";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import SalesDashboard from "components/views/Synth2.0/Order/Tabs/SalesDashboard";
import OrderList from "components/views/Synth2.0/Order/Tabs/OrderList";
import Dropdown from "components/common/Dropdown";
import SalesCard from "components/common/SalesCard";
import useGetSalesDashboardStats from "hooks/salesHooks/useGetSalesDashboardStats";
import { formatMoney } from "helpers";
import Skeleton from "react-loading-skeleton";
import useGetSalesOrderList from "hooks/salesHooks/useGetSalesOrderList";
import CustomCalendar from "components/common/CalendarComp.js/Calendar";
import toast from "react-hot-toast";
import CustomToast from "components/common/CustomToast";
import moment from "moment";

const Order = () => {
    const [activeTab, setActiveTab] = useState<number>(0);
    const [label, setLabel] = useState<string>("This month");
    const [orderListlabel, setOrderListLabel] = useState<string>("This month");
    const [openCalendar, setOpenCalendar] = useState(false);
    const [dateRange, setDateRange] = useState([]);
    const [ordersDateRange, setOrdersDateRange] = useState([]);
    const navigate = useNavigate();
    const [param] = useSearchParams();
    const currTab = param.get("currTab");
    const tabs = ["Insights", "Orders"];

    const dashboardDuration = label?.includes("month")
        ? "monthly"
        : label?.toLowerCase()?.includes("week")
        ? "weekly"
        : label?.toLowerCase()?.includes("custom")
        ? "custom"
        : "yearly";

    const salesOrderListDuration = orderListlabel?.includes("month")
        ? "monthly"
        : orderListlabel?.toLowerCase()?.includes("week")
        ? "weekly"
        : orderListlabel?.toLowerCase()?.includes("custom")
        ? "custom"
        : "yearly";

    // const { handleDurationChange } = useGetSalesDashboardStats();
    // const { handleDurationChange: handleOrderListDurationChange } = useGetSalesOrderList("");

    const handleDateChange = (range) => {
        const [start, end] = range;

        // Check if start and end dates are within the same month and year
        if (start.getMonth() === end.getMonth() && start.getFullYear() === end.getFullYear()) {
            if (activeTab === 0) {
                setDateRange(range);
            } else {
                setOrdersDateRange(range);
            }
        } else {
            toast.custom((t) => (
                <CustomToast
                    t={t}
                    message={"Unable to select date range outside of a month"}
                    type={"error"}
                />
            ));
        }
    };

    const displayDurationLabel = () => {
        if (activeTab === 0) {
            return label?.toLowerCase()?.includes("custom") && dateRange?.[0] && dateRange?.[1]
                ? moment(dateRange?.[0])?.format("MMM DD, YYYY") +
                      " - " +
                      moment(dateRange?.[1])?.format("MMM DD, YYYY")
                : label;
        } else {
            return orderListlabel?.toLowerCase()?.includes("custom") &&
                ordersDateRange?.[0] &&
                ordersDateRange?.[1]
                ? moment(ordersDateRange?.[0])?.format("MMM DD, YYYY") +
                      " - " +
                      moment(ordersDateRange?.[1])?.format("MMM DD, YYYY")
                : orderListlabel;
        }
    };

    const displayActiveTab = () => {
        switch (activeTab) {
            case 0:
                return <SalesDashboard durationValue={dashboardDuration} dateRange={dateRange} />;

            case 1: {
                return (
                    <OrderList durationValue={salesOrderListDuration} dateRange={ordersDateRange} />
                );
            }
            default:
                break;
        }
    };

    useEffect(() => {
        if (currTab) {
            setActiveTab(Number(currTab));
        }
    }, [currTab]);

    return (
        <div className="p-4 border border-slate-100 rounded-[8px] bg-white">
            <div className="p-4">
                <PageTitleSection
                    leftComp={<h3 className="text-xl font-rocGroteskMedium">Sales</h3>}
                />
            </div>

            <div className="p-4">
                <div className="flex items-center justify-between mb-6">
                    <div className="w-fit bg-slate-50 rounded p-1 flex items-center space-x-2.5 ">
                        {tabs.map((tab, index) => (
                            <div
                                key={index}
                                className={`px-4 py-[7px] cursor-pointer ${
                                    activeTab === index &&
                                    "bg-white rounded shadow-[0px_4px_10px_0px_#0000000D]"
                                }`}
                                onClick={() => {
                                    setActiveTab(index);
                                    setOpenCalendar(false);
                                    navigate(`/dashboard/order?currTab=${index}`);
                                }}
                            >
                                <span className="text-sm font-rocGroteskMedium">{tab}</span>
                            </div>
                        ))}
                    </div>

                    <div className="flex items-center justify-between space-x-6">
                        <div className="relative">
                            <Dropdown
                                name="period"
                                value={""}
                                dropdownTriggerClassName={" "}
                                handleChange={(name, value) => {
                                    if (activeTab === 0) {
                                        setLabel(value?.split("-")?.[0]);
                                    } else {
                                        setOrderListLabel(value?.split("-")?.[0]);
                                    }
                                }}
                                dropdown={
                                    <div className="bg-white flex justify-between items-center h-10 space-x-2 py-2.5 px-3 shadow-boxShadow-2 border border-tradeally-neutral-20 !rounded">
                                        <span className="text-[13px] whitespace-nowrap font-rocGroteskMedium">
                                            {displayDurationLabel()}
                                        </span>

                                        <div className="flex items-center space-x-2">
                                            <i className="ri-arrow-down-s-line text-lg text-gm-50"></i>
                                        </div>
                                    </div>
                                }
                                dropdownClassName={"!w-full !mb-10 !-mt-7"}
                                dropdownContainerClasses={`shadow-cardShadow-4 !right-0 !mt-0  !w-[159px] max-sm:!w-full max-sm:z-[9999] rounded !max-h-fit
                                                 !p-0 !top-[105%]`}
                                leaveOpen
                                dropdownItemsClasses="!bg-transparent border-b-[1px] border-b-slate-100"
                                dropdownOptions={[
                                    {
                                        label: "This week",
                                        value: "This week-weekly",
                                        action: () => setDateRange([]),
                                    },
                                    {
                                        label: "This month",
                                        value: "This month-monthly",
                                        action: () => setDateRange([]),
                                    },
                                    {
                                        label: "Custom date",
                                        value: "Custom date-custom",
                                        action: () => setOpenCalendar(true),
                                    },
                                ]}
                            />

                            <div className="absolute top-0">
                                {(dashboardDuration === "custom" ||
                                    salesOrderListDuration === "custom") &&
                                    openCalendar && (
                                        <div
                                            // ref={customRef}
                                            className="w-[315px]  z-[10000] absolute  right-0 top-16 px-[28px] pt-8 pb-8 bg-white
                                                    rounded-2xl shadow-[0px_0px_4px_rgba(0,0,0,0.04),0px_4px_32px_rgba(0,0,0,0.06)]"
                                        >
                                            <CustomCalendar
                                                showDoubleView={false}
                                                selectRange={true}
                                                value={
                                                    activeTab === 0 ? dateRange : ordersDateRange
                                                }
                                                onChange={handleDateChange}
                                            />
                                            <div className="flex justify-end">
                                                <Button
                                                    btnText={"Close"}
                                                    btnClassname={"!w-fit !py-2"}
                                                    onClick={() => {
                                                        if (dateRange?.[0] && dateRange?.[1]) {
                                                            setOpenCalendar(false);
                                                        } else {
                                                            setLabel("This month");
                                                            setOrderListLabel("This month");
                                                            setOpenCalendar(false);
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    )}
                            </div>
                        </div>

                        <Button
                            btnClassname="!px-4 !py-2 !h-10 !w-fit whitespace-nowrap"
                            btnText="Create sales order"
                            icon={<i className="ri-add-circle-line"></i>}
                            onClick={() => navigate("/dashboard/order/create")}
                        />
                    </div>
                </div>

                {displayActiveTab()}
            </div>
        </div>
    );
};

export default Order;
