export const onScroll = (
    scrollContainer: HTMLElement,
    currentPage: number,
    numberOfPages: number,
    onScrollFunc?: () => void,
    isLoading?: boolean
) => {
    let lastScrollTop = 0;
    let scrollTop = scrollContainer.scrollTop;
    const scrollHeight = scrollContainer.scrollHeight;
    const clientHeight = scrollContainer.clientHeight;
    let st = window.pageYOffset || scrollTop;
    const condition = scrollTop + clientHeight >= scrollHeight - 1;
    if (condition && st > lastScrollTop && !isLoading) {
        if (currentPage < numberOfPages) {
            onScrollFunc?.();
        }
    }
    lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling
};
let lastScrollTop = 0;
export const onModalScroll = (scrollContainer, setIsAtBottom) => {
    let scrollTop = scrollContainer.scrollTop;
    const scrollHeight = scrollContainer.scrollHeight;
    const clientHeight = scrollContainer.clientHeight;
    let st = window.pageYOffset;
    const atBottom = scrollTop + clientHeight >= scrollHeight - 1;
    const atTop = scrollTop === 0;

    if (atBottom) {
        setIsAtBottom(false); // Hide the div when scrolled to the bottom
    } else if (!atTop) {
        setIsAtBottom(true); // Show the div otherwise
    } else if (atTop) {
        setIsAtBottom(false); // Hide the div when at the top
    }

    lastScrollTop = st <= 0 ? 0 : st;
};
