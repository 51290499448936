import { useEffect, useMemo, useState } from "react";
import { inventoryActions, salesActions } from "redux/Ldb/actions";
import { useAppDispatch, useAppSelector } from "../storeHooks";
import { debounce, uniqBy } from "lodash";
import useInfiniteScroll from "../useInfinteScroll";
import { getCurrentStartAndEndDate } from "helpers/getCurrentStartAndEndDate";

const useGetSalesOrderList = (status?: string) => {
    const dispatch = useAppDispatch();
    const { fetchingSalesOrderList, fetchSalesOrderListSuccess, fetchSalesOrderListFailure } =
        useAppSelector((state) => state.sales);

    const [startEndDate, setStartEndDate] = useState<{ [key: string]: any }>(
        getCurrentStartAndEndDate("monthly")
    );
    const [salesOrder, setSalesOrder] = useState<{ [key: string]: any }[]>([]);
    const [ongoingSalesOrder, setOngoingSalesOrder] = useState<{ [key: string]: any }[]>([]);
    const [fulfilledSalesOrder, setFulfilledSalesOrder] = useState<{ [key: string]: any }[]>([]);
    const [pendingSalesOrder, setPendingSalesOrder] = useState<{ [key: string]: any }[]>([]);
    const [pagination, setPagination] = useState<{ currentPage: string; noOfPages: string }>({
        currentPage: "1",
        noOfPages: "",
    });
    const [ongoingPagination, setOngoingPagination] = useState<{
        currentPage: string;
        noOfPages: string;
    }>({
        currentPage: "1",
        noOfPages: "",
    });
    const [fulfilledPagination, setFulfilledPagination] = useState<{
        currentPage: string;
        noOfPages: string;
    }>({
        currentPage: "1",
        noOfPages: "",
    });
    const [pendingPagination, setPendingPagination] = useState<{
        currentPage: string;
        noOfPages: string;
    }>({
        currentPage: "1",
        noOfPages: "",
    });
    const [debouncedSearch, setDebouncedSearch] = useState<string>("");
    const [search, setSearch] = useState<string>("");

    const [debouncedOngoingSearch, setDebouncedOngoingSearch] = useState<string>("");
    const [ongoingSearch, setOngoingSearch] = useState<string>("");

    const [debouncedFulfilledSearch, setDebouncedFulfilledSearch] = useState<string>("");
    const [fulfilledSearch, setFulfilledSearch] = useState<string>("");

    const [debouncedPendingSearch, setDebouncedPendingSearch] = useState<string>("");
    const [pendingSearch, setPendingSearch] = useState<string>("");

    const [warehousefilter, setWarehouseFilter] = useState<{ name: string; id: string }>({
        name: "",
        id: "",
    });

    const [ongoingWarehousefilter, setOngoingWarehouseFilter] = useState<{
        name: string;
        id: string;
    }>({
        name: "",
        id: "",
    });

    const [fulfilledWarehousefilter, setFulfilledWarehouseFilter] = useState<{
        name: string;
        id: string;
    }>({
        name: "",
        id: "",
    });

    const [pendingWarehousefilter, setPendingWarehouseFilter] = useState<{
        name: string;
        id: string;
    }>({
        name: "",
        id: "",
    });

    const { updatedLimit, isLoadingMore, setLoadMore, updatedPage, setUpdatedPage } =
        useInfiniteScroll(
            { current: pagination.currentPage, number_of_pages: pagination.noOfPages },
            10
        );

    const handleDurationChange = (value: string) => {
        const startAndEndDate = getCurrentStartAndEndDate(value);
        setStartEndDate(startAndEndDate);
    };

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (status === "fulfilled") {
            setFulfilledSearch(e.target.value);
        } else if (status === "ongoing") {
            setOngoingSearch(e.target.value);
        } else if (status === "pending") {
            setPendingSearch(e.target.value);
        } else {
            setSearch(e.target.value);
        }
    };

    const handleDebouncedChange = debounce((e: React.ChangeEvent<HTMLInputElement>) => {
        setUpdatedPage(1);

        if (status === "fulfilled") {
            setDebouncedFulfilledSearch(e.target.value);
        } else if (status === "ongoing") {
            setDebouncedOngoingSearch(e.target.value);
        } else if (status === "pending") {
            setDebouncedPendingSearch(e.target.value);
        } else {
            setDebouncedSearch(e.target.value);
        }
    }, 800);

    const handleFilterChange = (name: string, id: string) => {
        setUpdatedPage(1);
        if (status === "fulfilled") {
            setFulfilledWarehouseFilter({ name, id });
        } else if (status === "ongoing") {
            setOngoingWarehouseFilter({ name, id });
        } else if (status === "pending") {
            setPendingWarehouseFilter({ name, id });
        } else {
            setWarehouseFilter({ name, id });
        }
    };

    const handleClearFilter = () => {
        setUpdatedPage(1);
        if (status === "fulfilled") {
            setFulfilledWarehouseFilter({ name: "", id: "" });
        } else if (status === "ongoing") {
            setOngoingWarehouseFilter({ name: "", id: "" });
        } else if (status === "pending") {
            setPendingWarehouseFilter({ name: "", id: "" });
        } else {
            setWarehouseFilter({ name: "", id: "" });
        }
    };

    useEffect(() => {
        dispatch(
            salesActions.getSalesOrderList(
                updatedPage,
                status,
                status === "fulfilled"
                    ? debouncedFulfilledSearch
                    : status === "ongoing"
                    ? debouncedOngoingSearch
                    : status === "pending"
                    ? debouncedPendingSearch
                    : debouncedSearch,
                status === "fulfilled"
                    ? fulfilledWarehousefilter?.id
                    : status === "ongoing"
                    ? ongoingWarehousefilter?.id
                    : status === "pending"
                    ? pendingWarehousefilter?.id
                    : warehousefilter?.id,
                startEndDate?.start,
                startEndDate?.end
            )
        );

        return () => {
            dispatch(salesActions.resetGetSalesOrderListSuccess());
        };
    }, [
        dispatch,
        debouncedSearch,
        updatedPage,
        status,
        debouncedOngoingSearch,
        debouncedFulfilledSearch,
        debouncedPendingSearch,
        warehousefilter?.id,
        ongoingWarehousefilter?.id,
        fulfilledWarehousefilter?.id,
        pendingWarehousefilter?.id,
        startEndDate?.start,
        startEndDate?.end,
    ]);

    useEffect(() => {
        if (Boolean(fetchSalesOrderListSuccess && !status)) {
            setSalesOrder((prev) =>
                updatedPage === 1
                    ? uniqBy([...fetchSalesOrderListSuccess.sales], "_id")
                    : uniqBy([...prev, ...fetchSalesOrderListSuccess.sales], "_id")
            );
            setPagination({
                currentPage: fetchSalesOrderListSuccess.pagination?.current,
                noOfPages: fetchSalesOrderListSuccess.pagination?.number_of_pages,
            });
            setLoadMore(false);
        }

        if (Boolean(fetchSalesOrderListSuccess && status === "ongoing")) {
            setOngoingSalesOrder((prev) =>
                updatedPage === 1
                    ? uniqBy([...fetchSalesOrderListSuccess.sales], "_id")
                    : uniqBy([...prev, ...fetchSalesOrderListSuccess.sales], "_id")
            );
            setOngoingPagination({
                currentPage: fetchSalesOrderListSuccess.pagination?.current,
                noOfPages: fetchSalesOrderListSuccess.pagination?.number_of_pages,
            });
            setLoadMore(false);
        }

        if (Boolean(fetchSalesOrderListSuccess && status === "fulfilled")) {
            setFulfilledSalesOrder((prev) =>
                updatedPage === 1
                    ? uniqBy([...fetchSalesOrderListSuccess.sales], "_id")
                    : uniqBy([...prev, ...fetchSalesOrderListSuccess.sales], "_id")
            );
            setFulfilledPagination({
                currentPage: fetchSalesOrderListSuccess.pagination?.current,
                noOfPages: fetchSalesOrderListSuccess.pagination?.number_of_pages,
            });
            setLoadMore(false);
        }

        if (Boolean(fetchSalesOrderListSuccess && status === "penging")) {
            setPendingSalesOrder((prev) =>
                updatedPage === 1
                    ? uniqBy([...fetchSalesOrderListSuccess.sales], "_id")
                    : uniqBy([...prev, ...fetchSalesOrderListSuccess.sales], "_id")
            );
            setPendingPagination({
                currentPage: fetchSalesOrderListSuccess.pagination?.current,
                noOfPages: fetchSalesOrderListSuccess.pagination?.number_of_pages,
            });
            setLoadMore(false);
        }
    }, [
        dispatch,
        fetchSalesOrderListSuccess,
        updatedPage,
        debouncedSearch,
        status,
        debouncedOngoingSearch,
        debouncedFulfilledSearch,
        debouncedPendingSearch,
    ]);

    return {
        isFetching: fetchingSalesOrderList,
        error: fetchSalesOrderListFailure,
        data: salesOrder,
        ongoingSalesOrder,
        fulfilledSalesOrder,
        pendingSalesOrder,
        ongoingPagination,
        fulfilledPagination,
        pendingPagination,
        setSalesOrder,
        pagination,
        setPagination,
        debouncedSearch,
        ongoingSearch,
        fulfilledSearch,
        pendingSearch,
        search,
        setSearch,
        setDebouncedSearch,
        handleDebouncedChange,
        handleSearch,
        handleFilterChange,
        handleClearFilter,
        isLoadingMore,
        updatedPage,
        setUpdatedPage,
        warehousefilter,
        ongoingWarehousefilter,
        fulfilledWarehousefilter,
        pendingWarehousefilter,
        handleDurationChange,
        setStartEndDate,
    };
};

export default useGetSalesOrderList;
