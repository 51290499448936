import CustomToast from "components/common/CustomToast";

import { toast } from "react-hot-toast";
import { workforceTypes } from "../types";
import { workforceService } from "services/Ldb";
import { Dispatch } from "redux";

export const workforceActions = {
    getWorkforceActions,
    getAiTeammates,
    getSingleAiTeammate,
    resetGetSingleAiTeammateSuccess,
    createAiTeammate,
    resetCreateAiTeammateSuccess,
    listTasks,
    getSingleTask,
    resetGetSingleTaskSuccess,
    updateTaskSalesOrder,
    resetUpdateTaskSalesOrderSuccess,
    approveTaskStep,
    resetApproveTaskStepSuccess,
    retryTaskStep,
    resetRetryTaskStepSuccess,
    giveTaskStepFeedback,
    resetGiveeTaskStepFeedbackSuccess,
    getTaskAnalytics,
    updateAiTeammate,
    resetUpdateAiTeammateSuccess,
};

function getWorkforceActions() {
    return (dispatch: Dispatch) => {
        dispatch(request(workforceTypes.FETCH_AI_AGENTS_REQUEST));

        workforceService.getAiAgents().then(
            (res) => {
                dispatch(success(workforceTypes.FETCH_AI_AGENTS_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(workforceTypes.FETCH_AI_AGENTS_FAILURE, error?.message));
                }
            }
        );
    };
}

function getAiTeammates(page: string | number, search?: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(workforceTypes.FETCH_AI_TEAMMATES_REQUEST));

        workforceService.getAiTeammates(page, search).then(
            (res) => {
                dispatch(success(workforceTypes.FETCH_AI_TEAMMATES_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(workforceTypes.FETCH_AI_TEAMMATES_FAILURE, error?.message));
                }
            }
        );
    };
}

function getSingleAiTeammate(aiTeammateId: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(workforceTypes.FETCH_SINGLE_AI_TEAMMATE_REQUEST));

        workforceService.getSingleAiTeammate(aiTeammateId).then(
            (res) => {
                dispatch(success(workforceTypes.FETCH_SINGLE_AI_TEAMMATE_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(workforceTypes.FETCH_SINGLE_AI_TEAMMATE_FAILURE, error?.message)
                    );
                }
            }
        );
    };
}

function createAiTeammate(data: Record<string, any> | Array<Record<string, any>>) {
    return (dispatch: Dispatch) => {
        dispatch(request(workforceTypes.CREATE_AI_TEAMMATE_REQUEST));

        workforceService.createAiTeammate(data).then(
            (res) => {
                dispatch(success(workforceTypes.CREATE_AI_TEAMMATE_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(workforceTypes.CREATE_AI_TEAMMATE_FAILURE, error?.message));
                }
            }
        );
    };
}

function updateAiTeammate(
    data: Record<string, any> | Array<Record<string, any>>,
    aiTeammateId: string
) {
    return (dispatch: Dispatch) => {
        dispatch(request(workforceTypes.UPDATE_AI_TEAMMATE_REQUEST));

        workforceService.updateAiTeammate(data, aiTeammateId).then(
            (res) => {
                dispatch(success(workforceTypes.UPDATE_AI_TEAMMATE_SUCCESS, res?.data));
                toast.custom((t) => (
                    <CustomToast t={t} message={"Update Successful"} type="success" />
                ));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(workforceTypes.UPDATE_AI_TEAMMATE_FAILURE, error?.message));
                }
            }
        );
    };
}

function listTasks(
    page?: string | number,
    search?: string,
    taskStatus?: string,
    assignedTo?: string,
    taskType?: string
) {
    return (dispatch: Dispatch) => {
        dispatch(request(workforceTypes.FETCH_TASK_LIST_REQUEST));

        workforceService.getTaskList(page, search, taskStatus, assignedTo, taskType).then(
            (res) => {
                dispatch(success(workforceTypes.FETCH_TASK_LIST_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(workforceTypes.FETCH_TASK_LIST_FAILURE, error?.message));
                }
            }
        );
    };
}

function getSingleTask(taskId: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(workforceTypes.FETCH_SINGLE_TASK_REQUEST));

        workforceService.getSingleTask(taskId).then(
            (res) => {
                dispatch(success(workforceTypes.FETCH_SINGLE_TASK_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(workforceTypes.FETCH_SINGLE_TASK_FAILURE, error?.message));
                }
            }
        );
    };
}

function updateTaskSalesOrder(data: Record<string, any> | Array<Record<string, any>>) {
    return (dispatch: Dispatch) => {
        dispatch(request(workforceTypes.UPDATE_TASK_SALES_ORDER_REQUEST));

        workforceService.updateTaskSalesOrder(data).then(
            (res) => {
                dispatch(success(workforceTypes.UPDATE_TASK_SALES_ORDER_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(workforceTypes.UPDATE_TASK_SALES_ORDER_FAILURE, error?.message)
                    );
                }
            }
        );
    };
}

function approveTaskStep(data: Record<string, any> | Array<Record<string, any>>) {
    return (dispatch: Dispatch) => {
        dispatch(request(workforceTypes.APPROVE_TASK_STEP_REQUEST));

        workforceService.approveTaskStep(data).then(
            (res) => {
                dispatch(success(workforceTypes.APPROVE_TASK_STEP_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(workforceTypes.APPROVE_TASK_STEP_FAILURE, error?.message));
                }
            }
        );
    };
}

function retryTaskStep(data: Record<string, any> | Array<Record<string, any>>) {
    return (dispatch: Dispatch) => {
        dispatch(request(workforceTypes.RETRY_TASK_STEP_REQUEST));

        workforceService.retryTaskStep(data).then(
            (res) => {
                dispatch(success(workforceTypes.RETRY_TASK_STEP_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(workforceTypes.RETRY_TASK_STEP_FAILURE, error?.message));
                }
            }
        );
    };
}

function giveTaskStepFeedback(data: Record<string, any> | Array<Record<string, any>>) {
    return (dispatch: Dispatch) => {
        dispatch(request(workforceTypes.GIVE_TASK_STEP_FEEDBACK_REQUEST));

        workforceService.giveTaskStepFeedback(data).then(
            (res) => {
                dispatch(success(workforceTypes.GIVE_TASK_STEP_FEEDBACK_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(workforceTypes.GIVE_TASK_STEP_FEEDBACK_FAILURE, error?.message)
                    );
                }
            }
        );
    };
}

function getTaskAnalytics() {
    return (dispatch: Dispatch) => {
        dispatch(request(workforceTypes.FETCH_TASK_ANALYTICS_REQUEST));

        workforceService.getTaskAnalytics().then(
            (res) => {
                dispatch(success(workforceTypes.FETCH_TASK_ANALYTICS_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(workforceTypes.FETCH_TASK_ANALYTICS_FAILURE, error?.message));
                }
            }
        );
    };
}

function resetGetSingleAiTeammateSuccess() {
    return (dispatch: Dispatch) => {
        dispatch(success(workforceTypes.FETCH_SINGLE_AI_TEAMMATE_SUCCESS, null));
    };
}

function resetGetSingleTaskSuccess() {
    return (dispatch: Dispatch) => {
        dispatch(success(workforceTypes.FETCH_SINGLE_TASK_SUCCESS, null));
    };
}

function resetUpdateAiTeammateSuccess() {
    return (dispatch: Dispatch) => {
        dispatch(success(workforceTypes.UPDATE_AI_TEAMMATE_SUCCESS, null));
    };
}

function resetGiveeTaskStepFeedbackSuccess() {
    return (dispatch: Dispatch) => {
        dispatch(success(workforceTypes.GIVE_TASK_STEP_FEEDBACK_SUCCESS, null));
    };
}

function resetRetryTaskStepSuccess() {
    return (dispatch: Dispatch) => {
        dispatch(success(workforceTypes.RETRY_TASK_STEP_SUCCESS, null));
    };
}

function resetApproveTaskStepSuccess() {
    return (dispatch: Dispatch) => {
        dispatch(success(workforceTypes.APPROVE_TASK_STEP_SUCCESS, null));
    };
}

function resetUpdateTaskSalesOrderSuccess() {
    return (dispatch: Dispatch) => {
        dispatch(success(workforceTypes.UPDATE_TASK_SALES_ORDER_SUCCESS, null));
    };
}

function resetCreateAiTeammateSuccess() {
    return (dispatch: Dispatch) => {
        dispatch(success(workforceTypes.CREATE_AI_TEAMMATE_SUCCESS, null));
    };
}

function request(type) {
    return { type: type };
}

function success(type, data) {
    return { type: type, payload: data };
}

function failure(type, error) {
    return { type: type, payload: error ?? "" };
}
