export const unitOfMeasurement = [
    {
        label: "unit",
        value: "unit",
    },
    {
        label: "pcs",
        value: "pcs",
    },
    {
        label: "units",
        value: "units",
    },
    {
        label: "milliliters (ml)",
        value: "milliliters (ml)",
    },
    {
        label: "liters (l)",
        value: "liters (l)",
    },
    {
        label: "grams (g)",
        value: "grams (g)",
    },
    {
        label: "kilograms (kg)",
        value: "kilograms (kg)",
    },
    {
        label: "cartridges",
        value: "cartridges",
    },
    {
        label: "rolls",
        value: "rolls",
    },
    {
        label: "box",
        value: "box",
    },
    {
        label: "truckload (tl)",
        value: "tl",
    },
    {
        label: "pallet",
        value: "pallet",
    },
    {
        label: "case",
        value: "case",
    },
    {
        label: "pounds",
        value: "pounds",
    },
    {
        label: "ea",
        value: "ea",
    },
    {
        label: "gallon",
        value: "gallon",
    },
];
