import React from "react";

type SalesCardProps = {
    title: string;
    icon?: JSX.Element;
    value: string | number;
    bottomComp?: JSX.Element;
};

const SalesCard = ({ title, icon, value, bottomComp }: SalesCardProps) => {
    return (
        <div className="p-4 border border-slate-200 rounded-lg">
            {icon}
            <div>
                <span className="block text-sm font-rocGroteskMedium text-slate-500 mb-[2px]">
                    {title}
                </span>
                <span className="block text-2xl font-rocGroteskMedium">{value}</span>
                {bottomComp}
            </div>
        </div>
    );
};

export default SalesCard;
