import QuoteDetails from "components/views/Synth2.0/Order/QuoteDetails";
import React from "react";

const QuoteSingleDetails =()=>{

    return (
        <>
        <QuoteDetails/>
        </>
    )
}

export default QuoteSingleDetails