import config from "config/config";
import { authService } from "./authService";
import { createUrlSearchParams } from "helpers";

export const productionService = {
    getBOMList,
    getSingleBOM,
    createBOM,
    editBOM,
    getProductWithoutBOM,
    getNonFinishedInventory,
    getFinishedInventory,
    createProductionPlan,
    updateProductionPlan,
    getProductionPlanList,
    getSingleProductionPlan,
    getProductionPlanTab,
    createPlanSteps,
    updatePlanSteps,
    fetchProductionAnalytics,
};

async function fetchProductionAnalytics(startDate?: string, endDate?: string) {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/production/metrics${
            Boolean(startDate && endDate) ? `?startDate=${startDate}&endDate=${endDate}` : ""
        }`,
        requestOptions
    );
    return res;
}

async function getBOMList(limit?: number, page?: number, search?: string) {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/inventory/products/with-bom?limit=${limit}&page=${page}${
            Boolean(search) ? "&search=" + search : ""
        }`,
        requestOptions
    );
    return res;
}

async function getSingleBOM(inventoryId: string) {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/inventory/product/${inventoryId}/bom-details`,
        requestOptions
    );
    return res;
}

async function createBOM(data: Record<string, any> | Array<Record<string, any>>, inventoryId) {
    const requestOptions = {
        method: "POST",
        body: JSON.stringify(data),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/inventory/product/${inventoryId}/bom`,
        requestOptions
    );
    return res;
}

async function editBOM(data: Record<string, any> | Array<Record<string, any>>, inventoryId) {
    const requestOptions = {
        method: "PUT",
        body: JSON.stringify(data),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/inventory/product/${inventoryId}/bom`,
        requestOptions
    );
    return res;
}

async function getProductWithoutBOM() {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/inventory/products/without-bom`,
        requestOptions
    );
    return res;
}

async function getNonFinishedInventory(limit?: number, page?: number, search?: string) {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${
            config.API_URL
        }/api/v1/ldb/inventory/list/non-finished-inventory?limit=${limit}&page=${page}${
            Boolean(search) ? "&search=" + search : ""
        }`,
        requestOptions
    );
    return res;
}

async function getFinishedInventory(limit?: number, page?: number, search?: string) {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/inventory/list/finished-products?limit=${limit}&page=${page}${
            Boolean(search) ? "&search=" + search : ""
        }`,
        requestOptions
    );
    return res;
}

async function createProductionPlan(data: Record<string, any> | Array<Record<string, any>>) {
    const requestOptions = {
        method: "POST",
        body: JSON.stringify(data),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/production/create-plan`,
        requestOptions
    );
    return res;
}

async function updateProductionPlan(
    data: Record<string, any> | Array<Record<string, any>>,
    planId: string
) {
    const requestOptions = {
        method: "PUT",
        body: JSON.stringify(data),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/production/update-plan/${planId}`,
        requestOptions
    );
    return res;
}

async function getProductionPlanList(
    limit?: number,
    page?: number,
    search?: string,
    status?: string,
    assigneeId?: string,
    startDate?: string,
    endDate?: string
) {
    const requestOptions = {
        method: "GET",
    };

    const urlParams = createUrlSearchParams({
        page,
        search: search as string,
        status,
        assigneeId,
        startDate,
        endDate,
    });

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/production/list/plans?limit=${limit}${
            Boolean(urlParams?.toString()) ? "&" + urlParams?.toString() : ""
        }`,
        requestOptions
    );
    return res;
}

async function getSingleProductionPlan(planId: string) {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/production/plan/${planId}/details`,
        requestOptions
    );
    return res;
}

async function getProductionPlanTab(
    planId: string,
    query?: string,
    limit?: number,
    page?: number,
    search?: string
) {
    const requestOptions = {
        method: "GET",
    };

    const urlParams = createUrlSearchParams({
        page,
        search,
        query,
    });

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/production/plan/${planId}/details?limit=${limit}${
            Boolean(urlParams?.toString()) ? "&" + urlParams?.toString() : ""
        }`,
        requestOptions
    );
    return res;
}

async function updatePlanSteps(
    data: Record<string, any> | Array<Record<string, any>>,
    stepId: string
) {
    const requestOptions = {
        method: "PUT",
        body: JSON.stringify(data),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/production/update-step/${stepId}`,
        requestOptions
    );
    return res;
}

async function createPlanSteps(data: Record<string, any> | Array<Record<string, any>>, planId) {
    const requestOptions = {
        method: "POST",
        body: JSON.stringify(data),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/production/add-step/${planId}`,
        requestOptions
    );
    return res;
}

// https://synth-staging.tradeally.io/api/v1/ldb/production/plan/66fd1f0f0101e200be1f665b/details?limit=10&query=bom
// 66fe95749a056f6eaf685b53
