import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import { dashboardActions } from "redux/Ldb/actions";

const useGetSalesChannel = () => {
    const dispatch = useAppDispatch();
    const [salesChannel, setSalesChannel] = useState<{ [key: string]: any }>([]);

    const { fetchingSalesChannel, fetchedSalesChannelSuccess, fetchedSalesChannelFailure } =
        useAppSelector((state) => state.dashboard);

    useEffect(() => {
        if (Boolean(fetchedSalesChannelSuccess)) {
            setSalesChannel(fetchedSalesChannelSuccess?.result?.channels);
        }
    }, [fetchedSalesChannelSuccess]);

    useEffect(() => {
        dispatch(dashboardActions?.getSalesChannel());
    }, [dispatch]);

    return {
        data: salesChannel,
        isFetching: fetchingSalesChannel,
        error: fetchedSalesChannelFailure,
    };
};

export default useGetSalesChannel;
