import { useEffect, useState } from "react";
import { inventoryActions } from "redux/Ldb/actions";
import { useAppDispatch, useAppSelector } from "../storeHooks";
import { useParams } from "react-router-dom";
import useGetSalesBreakdown from "./useGetSalesBreakdown";

const useGetProductsAnalytics = (
    warehouseId?: string,
    inventoryId?: string,
    dateRange?: string
) => {
    const dispatch = useAppDispatch();
    const {
        fetchingProductsAnalytics,
        fetchedProductsAnalyticsSuccess,
        fetchedProductsAnalyticsFailure,
    } = useAppSelector((state) => state.inventory);
    const { data: salesBreakdown, isFetching: isLoadingData } = useGetSalesBreakdown(
        warehouseId,
        inventoryId,
        dateRange
    );

    const [analyticsData, setAnalyticsData] = useState<{ [key: string]: any }>({});

    useEffect(() => {
        dispatch(inventoryActions.fetchProductsAnalytics());
    }, [dispatch]);

    useEffect(() => {
        if (Boolean(fetchedProductsAnalyticsSuccess)) {
            setAnalyticsData(fetchedProductsAnalyticsSuccess?.stockDetails);
        }
    }, [fetchedProductsAnalyticsSuccess]);

    return {
        isFetching: fetchingProductsAnalytics,
        error: fetchedProductsAnalyticsFailure,
        data: analyticsData,
        salesBreakdown,
    };
};

export default useGetProductsAnalytics;
