import Button from "components/common/Button";
import CustomTable from "components/common/CustomTable";
import PageTitleSection from "components/common/PageTitleSection";
import VendorAvatar from "components/common/VendorAvatar";
import PageFrame from "components/layout/PageFrame";
import { getCurrencyFromCurrencyCode, hasHTMLTag, sanitizeHtml } from "helpers";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { vendorActions } from "redux/Ldb/actions";
import moment from "moment";
import { downloadUrl, fbDownload } from "helpers/fileDownload";

const SupplierContract = () => {
    const { id } = useParams();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [supplierContract, setSupplierContract] = useState<{ [key: string]: any }>({});

    const { fetchingSupplierDetails, fetchSupplierDetailsSuccess } = useAppSelector(
        (state) => state?.vendor
    );

    useEffect(() => {
        if (Boolean(fetchSupplierDetailsSuccess)) {
            setSupplierContract(fetchSupplierDetailsSuccess);
        }
    }, [fetchSupplierDetailsSuccess]);
    useEffect(() => {
        if (id) {
            dispatch(vendorActions?.fetchSupplierDetails(id));
        }
    }, [dispatch, id]);

    const tableHeader = [
        { title: "Product category", widthClass: "w-[10.8%]" },
        { title: "Product name", widthClass: "w-[10.8%]" },
        { title: "Price per unit", widthClass: "w-[10.6%]" },
    ];

    const tableBody = supplierContract?.contract?.[0]?.products?.map((product, idx) => [
        {
            content: (
                <div
                    className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-base text-gm-50 `}
                >
                    <span className="">{product?.productCategory}</span>
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-base text-[#64748B] `}
                >
                    <span className="">{product?.productName}</span>
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-base text-[#64748B] `}
                >
                    <span className="">
                        {" "}
                        {`${getCurrencyFromCurrencyCode(product?.currency)}${product?.unitPrice}`}
                    </span>
                </div>
            ),
        },
    ]);

    return (
        <PageFrame isLoading={fetchingSupplierDetails}>
            <div className="bg-white border rounded-[8px] py-4  border-[#F1F5F9]">
                <div className="pb-4 border-b px-4 border-slate-100 w-full">
                    <div
                        className="flex items-center space-x-1 mb-5 cursor-pointer text-[#3B4C58] text-sm font-rocGroteskMedium"
                        onClick={() => navigate(-1)}
                    >
                        <i className="ri-arrow-left-line"></i>
                        <p className="underline">Back</p>
                    </div>
                    <PageTitleSection
                        leftComp={
                            <h3 className="text-[19px] leading-[26px] font-rocGroteskMedium">
                                Contract details
                            </h3>
                        }
                        rightComp={
                            <Button
                                btnText={"Download Contract"}
                                type={"button"}
                                btnClassname="!py-2 !px-4 !w-fit !whitespace-nowrap max-sm:w-full"
                                onClick={() => {
                                    supplierContract?.contract?.[0]?.document?.path?.includes(
                                        "firebase"
                                    )
                                        ? fbDownload(
                                              supplierContract?.contract?.[0]?.document?.path,
                                              supplierContract?.contract?.[0]?.document?.name
                                          )
                                        : downloadUrl(
                                              supplierContract?.contract?.[0]?.document?.path,
                                              supplierContract?.contract?.[0]?.document?.name
                                          );
                                }}
                            />
                        }
                    />
                </div>
                <div className="mt-5 px-8 ">
                    <div className="border-b-[2px] border-dotted pb-3 border-[#E2E8F0]">
                        <p className="text-base mb-1 text-gm-50 font-rocGroteskMedium">
                            Contract Information
                        </p>
                        <div className="flex w-full items-center ">
                            <div className="font-rocGroteskMedium w-full space-y-1">
                                <p className="text-[16px] leading-[20px] text-[#64748B] ">
                                    Contract ID
                                </p>
                                <p className="text-[16px] leading-[20px] text-[#64748B] ">
                                    Start date date
                                </p>
                                <p className="text-[16px] leading-[20px] text-[#64748B] ">
                                    End date
                                </p>
                                <p className="text-[16px] leading-[20px] text-[#64748B] ">
                                    Payment terms
                                </p>
                            </div>
                            <div className="font-rocGroteskMedium w-full space-y-1">
                                <p className="text-[16px] leading-[23px] text-gm-50 ">CON.001</p>
                                <p className="text-[16px] leading-[23px] text-gm-50 ">
                                    {moment(supplierContract?.contract?.[0]?.startDate)?.format(
                                        "DD MMM, YYYY"
                                    )}
                                </p>
                                <p className="text-[16px] leading-[23px] text-gm-50 ">
                                    {moment(supplierContract?.contract?.[0]?.renewalDate)?.format(
                                        "DD MMM, YYYY"
                                    )}
                                </p>
                                <p className="text-[16px] leading-[23px] text-gm-50 ">
                                    {supplierContract?.contract?.[0]?.paymentTerms}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="border-b-[2px] mt-4 border-dotted pb-3 border-[#E2E8F0]">
                        <p className="text-base mb-1 text-gm-50 font-rocGroteskMedium">
                            Supplier Information
                        </p>
                        <div className="flex w-full items-center ">
                            <div className="font-rocGroteskMedium w-full space-y-1">
                                <p className="text-[16px] leading-[20px] text-[#64748B] ">
                                    Supplier
                                </p>
                                <p className="text-[16px] leading-[20px] text-[#64748B] ">Email</p>
                                <p className="text-[16px] leading-[20px] text-[#64748B] ">
                                    Phone number
                                </p>
                                <p className="text-[16px] leading-[20px] text-[#64748B] ">
                                    Contact person
                                </p>
                            </div>
                            <div className="font-rocGroteskMedium w-full space-y-1">
                                <div className=" flex items-start space-x-1.5">
                                    <VendorAvatar
                                        size={32}
                                        containerClassname="!rounded-none"
                                        imageSrc={supplierContract?.vendor?.profile?.logo}
                                        name={supplierContract?.vendor?.profile?.businessName}
                                    />
                                    <p className="text-[16px] leading-[23px] text-gm-50">
                                        {supplierContract?.vendor?.profile?.businessName}
                                    </p>
                                </div>
                                <p className="text-[16px] leading-[23px] text-gm-50 ">
                                    {supplierContract?.vendor?.email}
                                </p>
                                <p className="text-[16px] leading-[23px] text-gm-50 ">
                                    {`${supplierContract?.vendor?.mobileExtension} ${supplierContract?.vendor?.mobile}`}
                                </p>
                                <div className=" flex items-center space-x-1.5">
                                    <VendorAvatar
                                        size={24}
                                        imageSrc={supplierContract?.vendor?.profile?.avatar}
                                        name={supplierContract?.vendor?.firstName}
                                    />
                                    <p className="text-[16px] leading-[23px] text-gm-50">
                                        {`${supplierContract?.vendor?.firstName} ${supplierContract?.vendor?.lastName}`}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="border-b-[2px] mt-4 border-dotted pb-3 border-[#E2E8F0]">
                        <p className="text-base mb-1 text-gm-50 font-rocGroteskMedium">
                            Warehouse information
                        </p>
                        <div className="flex w-full items-start ">
                            <div className="font-rocGroteskMedium w-full space-y-1">
                                <p className="text-[16px] leading-[20px] text-[#64748B] ">
                                    Warehouse location
                                </p>
                                <p className="text-[16px] leading-[20px] text-[#64748B] ">
                                    Capacity
                                </p>
                                <p className="text-[16px] leading-[20px] text-[#64748B] ">
                                    Quality control
                                </p>
                                <p className="text-[16px] leading-[20px] text-[#64748B] ">
                                    Security
                                </p>
                            </div>
                            <div className="font-rocGroteskMedium w-full space-y-1">
                                <div className="">
                                    <p className="text-[16px] leading-[23px] text-gm-50">
                                        {supplierContract?.vendor?.warehouse?.location}
                                    </p>
                                </div>
                                <p className="text-[16px] leading-[23px] text-gm-50 "></p>
                                <p className="text-[16px] leading-[23px] text-gm-50 "></p>
                                <p className="text-[16px] leading-[23px] text-gm-50"></p>
                            </div>
                        </div>
                    </div>
                    <div className="border-b-[2px] mt-4 border-dotted pb-3 border-[#E2E8F0]">
                        <p className="text-base mb-4  text-gm-50 font-rocGroteskMedium">
                            Product details
                        </p>
                        <div className="flex flex-col space-y-6">
                            <div>
                                <p className="text-[#64748B] mb-3 font-rocGroteskMedium text-[16px] leading-[20px]">
                                    Description
                                </p>
                                <p className="text-gm-50 font-rocGroteskMedium text-[16px] leading-[23px]">
                                    {supplierContract?.contract?.[0]?.productDetails}
                                </p>
                            </div>
                            <div>
                                <p className="text-[#64748B] mb-3 font-rocGroteskMedium text-[16px] leading-[20px]">
                                    Product list
                                </p>
                                <div>
                                    <CustomTable
                                        tableBody={tableBody}
                                        tableHeader={tableHeader}
                                        isScrollable={false}
                                        // isScrolled={isScrolled}
                                        isCellBordered={false}
                                        isCellSelectable={false}
                                        isAllSelectable={false}
                                        headerContainerClass="!bg-slate-50"
                                        headerItemClass="font-rocGroteskMedium !text-sm "
                                        tableClass="mb-1"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className=" mt-4  pb-3 ">
                        <p className="text-base mb-4  text-gm-50 font-rocGroteskMedium">
                            Terms and Condition
                        </p>
                        <div className="space-y-0.5 text-[14px] font-rocGroteskMedium text-slate-500">
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: hasHTMLTag(
                                        supplierContract?.contract?.[0]?.termsAndConditions
                                    )
                                        ? sanitizeHtml(
                                              supplierContract?.contract?.[0]?.termsAndConditions
                                          ).sanitizedHTML?.join("")
                                        : supplierContract?.contract?.[0]?.termsAndConditions,
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div className="items-center flex w-full justify-center flex-col mt-8 font-rocGroteskMedium">
                    <p className="text-gm-50 text-xl">Authorisation</p>
                    <p className="text-sm text-gm-50">
                        The parties authorize the terms outlined in this contract and agree to be
                        bound by its provisions.
                    </p>

                    <div className="mt-5 flex items-center ">
                        <p className="text-sm text-gm-50 ">Powered by</p>
                        <div className="">
                            <img
                                src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1711114409/synthAlly_logo_skcywb.svg"
                                alt="synthAlly logo"
                                className="w-[110px] h-6"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </PageFrame>
    );
};

export default SupplierContract;
