import { useEffect, useMemo, useState } from "react";
import { inventoryActions } from "redux/Ldb/actions";
import { useAppDispatch, useAppSelector } from "../storeHooks";
import { cloneDeep } from "lodash";

const useGetSalesBreakdown = (warehouseId?: string, inventoryId?: string, dateRange?: string) => {
    const dispatch = useAppDispatch();
    const { fetchingSalesBreakdown, fetchedSalesBreakdownSuccess, fetchedSalesBreakdownFailure } =
        useAppSelector((state) => state.inventory);

    // const [breakdownData, setBreakdownData] = useState<{ [key: string]: any }>({});
    const [pagination, setPagination] = useState<{ currentPage: string; noOfPages: string }>({
        currentPage: "1",
        noOfPages: "",
    });

    const breakdownData = useMemo(() => {
        if (!fetchedSalesBreakdownSuccess) {
            return { channelData: [], totalSales: 0 };
        }
        return {
            channelData: fetchedSalesBreakdownSuccess.channels,
            totalStock: fetchedSalesBreakdownSuccess.totalStock,
        };
    }, [fetchedSalesBreakdownSuccess]);

    useEffect(() => {
        dispatch(inventoryActions.fetchSalesBreakdown(warehouseId, inventoryId, dateRange));
    }, [dispatch, warehouseId, inventoryId, dateRange]);

    return {
        isFetching: fetchingSalesBreakdown,
        error: fetchedSalesBreakdownFailure,
        data: cloneDeep(breakdownData),
        pagination,
        setPagination,
    };
};

export default useGetSalesBreakdown;
