import Button from "components/common/Button";
import TextInput from "components/common/InputField/TextInput";
import Navbar from "components/common/Navbar/Navbar";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { authActions } from "redux/Ldb/actions";
import { useAppDispatch, useAppSelector } from "../../../hooks/storeHooks";
import AuthFrame from "./AuthFrame";

const ResetPassword = () => {
    const [checks, setChecks] = useState(0);
    const [passwordInfo, setPasswordInfo] = useState({
        newPassword: "",
        confirmPassword: "",
    });
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [param] = useSearchParams();
    const code = param.get("code");
    const token = param.get("token");
    const { resettingPassword } = useAppSelector((state) => state.auth);

    const handleChange = (evt) => {
        const confirmPasswordInput = document.getElementById("confirmPassword") as HTMLInputElement;
        const { name, value } = evt.target;
        setPasswordInfo((prev) => ({
            ...prev,
            [name]: value,
        }));
        if (passwordInfo.newPassword !== value) {
            confirmPasswordInput.setCustomValidity("Confirm password must match new password");
        } else {
            confirmPasswordInput.setCustomValidity("");
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (checks > 2) {
            dispatch(
                authActions.resetPassword({ password: passwordInfo.newPassword, code }, token)
            );
        }
    };
    const specialCharacterRegex = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/;
    const lowercaseRegex = /[a-z]/;
    const uppercaseRegex = /[A-Z]/;
    const isLowerCasePresent = lowercaseRegex.test(passwordInfo?.newPassword);
    const isUpperCasePresent = uppercaseRegex.test(passwordInfo?.newPassword);
    const isSpecialCharacterPresent = specialCharacterRegex.test(passwordInfo?.newPassword);

    useEffect(() => {
        let newChecks = 0;

        if (isLowerCasePresent) {
            newChecks++;
        }
        if (isUpperCasePresent) {
            newChecks++;
        }
        if (isSpecialCharacterPresent) {
            newChecks++;
        }
        if (passwordInfo?.newPassword.length > 8) {
            newChecks++;
        }

        if (newChecks !== checks) {
            setChecks(newChecks);
        }
    }, [
        isSpecialCharacterPresent,
        passwordInfo?.newPassword,
        isUpperCasePresent,
        isLowerCasePresent,
        checks,
    ]);

    return (
        <AuthFrame>
            <div className="w-full h-fit bg-white shadow-dropShadow-cookie p-[40px] rounded-[8px]">
                <div className="mb-[38px] flex flex-col items-center justify-center">
                    <img
                        src={
                            "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1715104205/SynthLogo_h118c2.svg"
                        }
                        alt="Logo Icon"
                        className="mb-2 "
                        height={26.4}
                        width={88}
                    />
                    <p className="text-[26px] font-flame font-normal text-center text-[#013D28]">
                        Change your password
                    </p>
                    <span className="text-sm text-center text-[#94A3B8]">Enter your new password</span>
                </div>
                <form onSubmit={handleSubmit} className="w-full">
                    <TextInput
                        id="newPassword"
                        value={passwordInfo.newPassword}
                        name={"newPassword"}
                        type={"password"}
                        placeholder={"Enter new password"}
                        onChange={handleChange}
                        required={true}
                        minLength={8}
                        pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\W).+$"
                        title="Password must contain at least one lowercase letter, one uppercase letter, and one special character."
                        containerClassname="mb-6"
                    />
                    <TextInput
                        id="confirmPassword"
                        value={passwordInfo.confirmPassword}
                        name={"confirmPassword"}
                        type={"password"}
                        onChange={handleChange}
                        placeholder={"Re-enter your new password"}
                        required={true}
                        minLength={8}
                        pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\W).+$"
                        title="Please fill this field"
                    />
                    <p className="text-[10px] leading-4 text-gm-35 mb-[10px]">
                        Should contain at least 1 uppercase, lowercase, and character (@, !, #,
                        etc.)
                    </p>
                    {passwordInfo?.newPassword?.length > 0 && (
                        <div className="mb-[10px] flex space-x-1 w-full">
                            <div className=" flex w-full items-center space-x-3">
                                <div
                                    className={`w-full border-[3px] rounded ${
                                        checks < 1 ? "border-n-40" : "border-[#FF0000]"
                                    } `}
                                ></div>
                                <div
                                    className={`w-full border-[3px] rounded  ${
                                        checks < 2 ? "border-n-40" : "border-[#FBBF24]"
                                    }`}
                                ></div>
                                <div
                                    className={`w-full border-[3px] rounded  ${
                                        checks < 3 ? "border-n-40" : "border-carribean-green"
                                    }`}
                                ></div>
                                <div
                                    className={`w-full border-[3px] rounded  ${
                                        checks < 4 ? "border-n-40" : "border-carribean-green"
                                    }`}
                                ></div>
                            </div>
                            <p className="text-xs text-[#FF0000]">{checks === 1 && "Weak"}</p>
                            <p className="text-xs text-[#FBBF24]">{checks === 2 && "Good"}</p>
                            <p className="text-xs text-carribean-green">
                                {checks === 3 && "Strong"}
                            </p>
                            <p className="text-xs text-carribean-green whitespace-nowrap">
                                {checks === 4 && "Very Strong"}
                            </p>
                        </div>
                    )}
                    <div className="w-full mt-8 ">
                        <Button
                            btnText={"Change password"}
                            isLoading={resettingPassword}
                            type={"submit"}
                        />
                        <p className="text-[13px] cursor-pointer font-interDisplay pt-3 leading-[18px] text-center" 
                        onClick={()=>navigate("/login")}
                        >Cancel</p>
                    </div>
                </form>
            </div>
        </AuthFrame>
    );
};

export default ResetPassword;
