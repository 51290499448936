import React, { useEffect, useState } from "react";
import ModalContainer from "./../../../common/ModalContainer";
import TextInput from "components/common/InputField/TextInput";
import TextareaInput from "components/common/InputField/TextareaInput";
import SelectInput from "components/common/InputField/SelectInput";
import { unitOfMeasurement } from "variables/unitOfMeasurement";
import Button from "components/common/Button";
import moment from "moment";

type stepsProps = {
    productionStepModal: boolean;
    setProductionStepModal: (data: boolean) => void;
    productionSteps: Array<Record<string, any>>;
    setProductionSteps: any;
    step: Record<string, any>;
    setStep: any;
    stepIdx: number;
    setStepIdx: (data: number) => void;
    productionId: string;
    handleSubmit?: any;
    isSaving?: boolean;
};
const ProductionSteps = ({
    productionStepModal,
    setProductionStepModal,
    productionSteps,
    setProductionSteps,
    step,
    setStep,
    stepIdx,
    setStepIdx,
    productionId,
    handleSubmit,
    isSaving,
}: stepsProps) => {
    const handleStepData = (e) => {
        const { name, value } = e.target;
        setStep((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    useEffect(() => {
        if (!productionStepModal) {
            setStep({});
            setStepIdx(-1);
        }
    }, [productionStepModal]);

    return (
        <div>
            <ModalContainer
                open={productionStepModal}
                modalClassName={"py-10 px-10"}
                tailwindClassName="w-[700px]"
                showCloseIcon={false}
                closeModal={() => {
                    !isSaving && setProductionStepModal(true);
                }}
            >
                <form
                    className="bg-white rounded-[8px] relative font-rocGroteskMedium"
                    onSubmit={(e) => {
                        e.preventDefault();
                        if (!productionId) {
                            if (stepIdx >= 0) {
                                let copyArr = [...productionSteps];
                                copyArr[stepIdx] = step;
                                setProductionSteps(copyArr);
                            } else {
                                setProductionSteps((prev) => [...prev, step]);
                            }

                            setProductionStepModal(false);
                            setStepIdx(-1);
                            setStep({});
                        } else {
                            handleSubmit();
                        }
                    }}
                >
                    <div className="mt-4 ">
                        <div className="px-6 font-rocGroteskMedium border-b border-b-slate-200 flex items-center justify-between py-3">
                            <p className="mt-1 text-base text-gm-50 ">
                                {stepIdx >= 0 ? "Edit" : "Add"} step for production schedule
                            </p>
                            <i
                                className={`${
                                    !isSaving && "cursor-pointer"
                                } ri-close-fill text-gm-50 `}
                                onClick={() => {
                                    !isSaving && setProductionStepModal(false);
                                    !isSaving && setStepIdx(-1);
                                }}
                            ></i>
                        </div>
                        <div className="px-6 pb-8 space-y-3 pt-3">
                            <TextInput
                                value={step.name}
                                name={"name"}
                                type={"text"}
                                onChange={(e) => handleStepData(e)}
                                placeholder={"Step name"}
                                required={true}
                                containerClassname=""
                            />

                            <TextareaInput
                                name={"description"}
                                value={step?.description}
                                onChange={handleStepData}
                                rows={5}
                                placeholder={"Description"}
                                required={true}
                            />
                            <div className="grid grid-cols-2 gap-3">
                                <TextInput
                                    value={step?.plannedStart}
                                    name={"plannedStart"}
                                    type={"datetime-local"}
                                    required={true}
                                    onChange={(e) => {
                                        handleStepData(e);
                                    }}
                                    inputPlaceholder={"Monday | 8:00am"}
                                    containerClassname=""
                                    min={moment()?.format("YYYY-MM-DDTHH:MM")}
                                />
                                <TextInput
                                    value={step?.plannedEnd}
                                    name={"plannedEnd"}
                                    type={"datetime-local"}
                                    required={true}
                                    onChange={(e) => {
                                        handleStepData(e);
                                    }}
                                    inputPlaceholder={"Monday | 8:00am"}
                                    containerClassname=""
                                    min={moment()?.format("YYYY-MM-DDTHH:MM")}
                                />
                            </div>

                            <TextInput
                                value={step.step}
                                name={"step"}
                                type={"number"}
                                onChange={(e) => handleStepData(e)}
                                placeholder={"Day"}
                                required={false}
                                containerClassname=""
                            />
                            <div className="flex gap-3">
                                <TextInput
                                    value={step?.amount}
                                    name={"amount"}
                                    type={"text"}
                                    onChange={(e) => handleStepData(e)}
                                    placeholder={"Quantity"}
                                    required={false}
                                    containerClassname=""
                                />
                                <SelectInput
                                    value={step?.unitOfMeasurement}
                                    name="unitOfMeasurement"
                                    placeholder={"UOM"}
                                    handleChange={(name, value) => {
                                        handleStepData({ target: { name, value } });
                                    }}
                                    isRequired={true}
                                    dropdownOptions={unitOfMeasurement}
                                    dropdownClassName="!max-h-24"
                                />
                            </div>
                        </div>
                        <div
                            className={`border-t border-t-slate-200 py-4 px-6 flex items-center w-full    ${
                                stepIdx >= 0 && !productionId ? "justify-between" : "justify-end"
                            } space-x-4`}
                        >
                            {stepIdx >= 0 && !productionId && (
                                <Button
                                    btnText={"Delete step"}
                                    btnClassname="!py-3 cursor-pointer !text-[14px] !bg-[rgba(228,40,29,0.1)] !text-r-50 !border border-[#E4281D] !font-rocGroteskMedium !px-4 !w-fit"
                                    onClick={() => {
                                        const copyArr = [...productionSteps];
                                        copyArr.splice(stepIdx, 1);
                                        setProductionSteps(copyArr);
                                        setProductionStepModal(false);
                                    }}
                                    icon={<i className="ri-delete-bin-6-fill"></i>}
                                />
                            )}
                            <div className="flex items-center  justify-end gap-4">
                                <Button
                                    btnText={"Cancel"}
                                    btnClassname="!py-3 cursor-pointer !text-[14px] !bg-n-20 !text-gm-50 !border border-n-20 !font-rocGroteskMedium !px-4 !w-fit"
                                    onClick={() => {
                                        setProductionStepModal(false);
                                        setStepIdx(-1);
                                    }}
                                    disabled={isSaving}
                                />
                                <Button
                                    type="submit"
                                    btnText={`${stepIdx >= 0 ? "Edit" : "Add production step"}`}
                                    isLoading={isSaving}
                                    disabled={isSaving}
                                    btnClassname="!py-3 cursor-pointer !text-[14px] !text-gm-50 !border border-n-20 !font-rocGroteskMedium !px-4 !w-fit"
                                    // onClick={() => {
                                    //     if (stepIdx >= 0) {
                                    //         let copyArr = [...productionSteps];
                                    //         copyArr[stepIdx] = step;
                                    //         setProductionSteps(copyArr);
                                    //     } else {
                                    //         setProductionSteps((prev) => [...prev, step]);
                                    //     }
                                    //     setProductionStepModal(false);
                                    //     setStepIdx(-1);
                                    //     setStep({});
                                    // }}
                                />
                            </div>
                        </div>
                    </div>
                </form>
            </ModalContainer>
        </div>
    );
};

export default ProductionSteps;
