import React, { useState } from "react";

const SuggestedActions = () => {
    const [show, setShow] = useState(false);
    return (
        <div  className="pb-4">
            <p className="text-base font-rocGroteskMedium text-g-75">
                Steps for managing potential overstocking
            </p>

            <div className="mt-4 w-[60%]">
                <div className="flex items-start space-x-4 py-4 border-b border-b-slate-200">
                    <div className="bg-[#FEF3C7] shrink-0 py-[1.5px] px-[6px] w-fit rounded border border-[#D97706]">
                        <p className="text-xs text-[#D97706] font-rocGroteskMedium">1</p>
                    </div>
                    <div>
                        <p className="text-sm font-rocGroteskMedium text-g-75">
                            Implement Promotional Strategies
                        </p>
                        <ul className="list-disc mt-0.5 ml-4">
                            <li className="text-slate-500 text-sm font-rocGroteskMedium">
                                Discounts and Offers: Create promotions or discounts to move excess
                                inventory quickly.
                            </li>
                            <li className="text-slate-500 text-sm font-rocGroteskMedium">
                                Bundling: Bundle overstocked products with high-demand items to
                                encourage sales.
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="flex items-start space-x-4 py-4 border-b border-b-slate-200">
                    <div className="bg-[#FEF3C7] shrink-0 py-[1.5px] px-[6px] w-fit rounded border border-[#D97706]">
                        <p className="text-xs text-[#D97706] font-rocGroteskMedium">2</p>
                    </div>
                    <div>
                        <p className="text-sm font-rocGroteskMedium text-g-75">
                            Leverage Marketing Campaigns
                        </p>
                        <p className="text-sm font-rocGroteskMedium text-slate-500">
                            Targeted Advertising: Use targeted advertising to promote overstocked
                            items. Email Marketing: Send email campaigns to notify customers about
                            discounts on overstocked products.
                        </p>
                    </div>
                </div>
                <div className="flex items-start space-x-4 py-4  border-b-slate-200">
                    <div className="bg-[#FEF3C7] shrink-0 py-[1.5px] px-[6px] w-fit rounded border border-[#D97706]">
                        <p className="text-xs text-[#D97706] font-rocGroteskMedium">3</p>
                    </div>
                    <div>
                        <p className="text-sm font-rocGroteskMedium text-g-75">
                            Customer Incentives
                        </p>
                        <p className="text-sm font-rocGroteskMedium text-slate-500">
                            Loyalty Programs: Offer additional loyalty points or rewards for
                            purchasing overstocked items. Referral Programs: Encourage customers to
                            refer friends or family for special discounts on overstocked items.
                        </p>
                    </div>
                </div>
                <div className="py-2 px-4 rounded border  gradient-border">
                    <div className="flex justify-between  ">
                        <div className="flex items-center space-x-3">
                            <i className="ri-sparkling-fill text-xl text-center inline-block text-transparent bg-clip-text bg-gradient-to-tr  from-[#62CC00] from-0% to-[#FFB902] to-100%"></i>
                            <p className="text-base font-rocGroteskMedium text-g-75">
                                Other suggestion by Synth SCM
                            </p>
                        </div>

                        <i
                            className={` ${show?"ri-arrow-up-s-line":"ri-arrow-down-s-line"}  cursor-pointer text-2xl`}
                            onClick={() => setShow(!show)}
                        ></i>
                    </div>
                   {show &&
                    <div>
                    <div className="flex items-start space-x-4 py-4 border-b border-b-slate-200">
                        <div className="bg-[#FEF3C7] shrink-0 py-[1.5px] px-[6px] w-fit rounded border border-[#D97706]">
                            <p className="text-xs text-[#D97706] font-rocGroteskMedium">4</p>
                        </div>
                        <div>
                            <p className="text-sm font-rocGroteskMedium text-g-75">
                                Analyze Current Inventory and Sales Data
                            </p>
                            <p className="text-sm font-rocGroteskMedium text-slate-500">
                                Inventory Levels: Review current inventory levels for all
                                overstocked products.
                                <br /> Sales Trends: Examine historical sales trends for each
                                product. <br />
                                Forecast Accuracy: Evaluate the accuracy of previous forecasts
                                for each product.
                            </p>
                        </div>
                    </div>
                    <div className="flex items-start space-x-4 py-4 border-b border-b-slate-200">
                        <div className="bg-[#FEF3C7] shrink-0 py-[1.5px] px-[6px] w-fit rounded border border-[#D97706]">
                            <p className="text-xs text-[#D97706] font-rocGroteskMedium">5</p>
                        </div>
                        <div>
                            <p className="text-sm font-rocGroteskMedium text-g-75">
                                Adjust Forecasting Parameters
                            </p>
                            <p className="text-sm font-rocGroteskMedium text-slate-500">
                                Reorder Points: Temporarily adjust reorder points and safety
                                stock levels for overstocked items. <br /> Demand Forecasting:
                                Update demand forecasting models to better reflect current sales
                                trends and reduce future overstocking.
                            </p>
                        </div>
                    </div>
                    <div className="flex items-start space-x-4 py-4 border-b border-b-slate-200">
                        <div className="bg-[#FEF3C7] shrink-0 py-[1.5px] px-[6px] w-fit rounded border border-[#D97706]">
                            <p className="text-xs text-[#D97706] font-rocGroteskMedium">6</p>
                        </div>
                        <div>
                            <p className="text-sm font-rocGroteskMedium text-g-75">
                                Redistribute Inventory
                            </p>
                            <p className="text-sm font-rocGroteskMedium text-slate-500">
                                Internal Transfers: Move excess inventory to locations where
                                demand is higher.
                                <br /> External Sales: Offer excess inventory to other
                                businesses, partners, or channels.
                            </p>
                        </div>
                    </div>
                    <div className="flex items-start space-x-4 py-4 border-b border-b-slate-200">
                        <div className="bg-[#FEF3C7] shrink-0 py-[1.5px] px-[6px] w-fit rounded border border-[#D97706]">
                            <p className="text-xs text-[#D97706] font-rocGroteskMedium">7</p>
                        </div>
                        <div>
                            <p className="text-sm font-rocGroteskMedium text-g-75">
                                Supplier Communication
                            </p>
                            <ul className="list-disc ml-4">
                                <li className="text-sm font-rocGroteskMedium text-slate-500">
                                    Order Adjustments: Contact suppliers to adjust future orders
                                    based on the new demand forecast.
                                </li>
                                <li className="text-sm font-rocGroteskMedium text-slate-500">
                                    Returns and Buybacks: Negotiate returns or buybacks for
                                    overstocked items if possible.
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="flex items-start space-x-4 py-4 border-b border-b-slate-200">
                        <div className="bg-[#FEF3C7] shrink-0 py-[1.5px] px-[6px] w-fit rounded border border-[#D97706]">
                            <p className="text-xs text-[#D97706] font-rocGroteskMedium">8</p>
                        </div>
                        <div>
                            <p className="text-sm font-rocGroteskMedium text-g-75">
                                Monitor and Review Regularly
                            </p>
                            <p className="text-sm font-rocGroteskMedium text-slate-500">
                                Regular Audits: Conduct regular audits of inventory levels and
                                forecasts.
                                <br />
                                Automated Alerts: Set up automated alerts in the software to
                                notify users of potential overstocking early.
                            </p>
                        </div>
                    </div>
                    <div className="flex items-start space-x-4 py-4 border-b border-b-slate-200">
                        <div className="bg-[#FEF3C7] shrink-0 py-[1.5px] px-[6px] w-fit rounded border border-[#D97706]">
                            <p className="text-xs text-[#D97706] font-rocGroteskMedium">9</p>
                        </div>
                        <div>
                            <p className="text-sm font-rocGroteskMedium text-g-75">
                                Improve Long-Term Planning
                            </p>
                            <p className="text-sm font-rocGroteskMedium text-slate-500">
                                Inventory Policies: Review and update inventory policies and
                                practices.
                                <br />
                                Continuous Improvement: Continuously improve forecasting models
                                with new data and insights.
                            </p>
                        </div>
                    </div>
                    <div className="flex items-start space-x-4 py-4 border-b border-b-slate-200">
                        <div className="bg-[#FEF3C7] shrink-0 py-[1.5px] px-[6px] w-fit rounded border border-[#D97706]">
                            <p className="text-xs text-[#D97706] font-rocGroteskMedium">10</p>
                        </div>
                        <div>
                            <p className="text-sm font-rocGroteskMedium text-g-75">
                                Review Sales Channels
                            </p>
                            <p className="text-sm font-rocGroteskMedium text-slate-500">
                                Expand Sales Channels: Explore additional sales channels or
                                marketplaces to move excess inventory.
                                <br />
                                Online vs. Offline: Compare online and offline sales performance
                                and adjust inventory accordingly.
                            </p>
                        </div>
                    </div>
                    <div className="flex items-start space-x-4 py-4 border-b border-b-slate-200">
                        <div className="bg-[#FEF3C7] shrink-0 py-[1.5px] px-[6px] w-fit rounded border border-[#D97706]">
                            <p className="text-xs text-[#D97706] font-rocGroteskMedium">11</p>
                        </div>
                        <div>
                            <p className="text-sm font-rocGroteskMedium text-g-75">
                                Offer Flash Sales
                            </p>
                            <p className="text-sm font-rocGroteskMedium text-slate-500">
                                Limited-Time Offers: Run flash sales to create urgency and clear
                                out overstocked items.
                                <br />
                                Special Discounts: Offer special discounts to loyal customers or
                                during off-peak seasons.
                            </p>
                        </div>
                    </div>
                    <div className="flex items-start space-x-4 py-4">
                        <div className="bg-[#FEF3C7] shrink-0 py-[1.5px] px-[6px] w-fit rounded border border-[#D97706]">
                            <p className="text-xs text-[#D97706] font-rocGroteskMedium">12</p>
                        </div>
                        <div>
                            <p className="text-sm font-rocGroteskMedium text-g-75">
                                Donation or Liquidation
                            </p>
                            <p className="text-sm font-rocGroteskMedium text-slate-500">
                                Donate Excess Inventory: Consider donating excess inventory to
                                charities or non-profits.
                                <br />
                                Liquidate Inventory: Use liquidation services to sell off
                                overstocked items quickly.
                            </p>
                        </div>
                    </div>
                </div>
                   }
                </div>
            </div>
        </div>
    );
};

export default SuggestedActions;
