import Button from "components/common/Button";
import SelectInput from "components/common/InputField/SelectInput";
import TextInput from "components/common/InputField/TextInput";
import Loader from "components/common/Loader";
import ModalContainer from "components/common/ModalContainer";
import {getFromStorage} from "helpers";
import React, {useEffect, useState} from "react";
import ReactCountryFlag from "react-country-flag";
import {useDispatch, useSelector} from "react-redux";
import {authActions} from "redux/Ldb/actions";
import {firebaseService} from "services/firebaseService";
import countries from "variables/countries";
import BusinessInfo from "./BusinessInfo";
import {useAppDispatch, useAppSelector} from "../../../../../../hooks/storeHooks";

const PersonalInfo = () => {
    const [openEditName, setOpenEditName] = useState(false);
    const [openEditPhone, setOpenEditPhone] = useState(false);
    const [openEditEmail, setOpenEditEmail] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [userProfile, setUserProfile] = useState(() => getFromStorage("ally-user"));

    const [profile, setProfile] = useState<{[key: string]: any}>({});

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState({
        ext: "",
        phoneNumber: "",
    });
    const {fetchUserProfileSuccess, updateUserProfileRequest, updateUserProfileSuccess} =
        useAppSelector((state) => state.auth);
    const [imageUrl, setImageUrl] = useState("");
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (profile && profile.avatar) {
            setImageUrl(profile.avatar);
        }
    }, [profile]);

    const handleUpload = (fileName, file) => {
        setUploading(true);
        firebaseService.uploadFile(
            `tradeally/${process.env.REACT_APP_STAGE}/personalLogo/${fileName}`,
            file,
            (url) => {
                setImageUrl(url);
                setUploading(false);
            }
        );
    };

    useEffect(() => {
        const body = {
            avatar: imageUrl,
        };
        if (imageUrl !== "") {
            dispatch(authActions.updateUserProfile(body, userProfile?.token));
        }
    }, [imageUrl]);

    useEffect(() => {
        if (Boolean(fetchUserProfileSuccess)) {
            setProfile(fetchUserProfileSuccess);
        }
    }, [fetchUserProfileSuccess]);

    useEffect(() => {
        setName(profile?.fullName);
        setEmail(profile?.email);
        setPhone((prev) => ({
            ...prev,
            phoneNumber: profile?.rawMobile,
            ext: profile?.mobileExtension,
        }));
    }, [profile]);

    const handleNameSubmit = () => {
        const body = {
            fullName: name,
        };
        dispatch(authActions.updateUserProfile(body, userProfile?.token));
    };

    const handleEmailSubmit = () => {
        const body = {
            email,
        };
        dispatch(authActions.updateUserProfile(body, userProfile?.token));
    };
    const handlePhoneSubmit = () => {
        const body = {
            rawMobile: phone.phoneNumber,
            mobileExtension: phone.ext,
            mobile: `${phone.ext}${phone.phoneNumber}`,
        };
        dispatch(authActions.updateUserProfile(body, userProfile?.token));
    };

    useEffect(() => {
        if (Boolean(updateUserProfileSuccess)) {
            dispatch(authActions.resetUserUpdate());
            dispatch(authActions.getLdbProfile(userProfile?.token));
            setOpenEditName(false);
            setOpenEditEmail(false);
            setOpenEditPhone(false);
            setName(profile?.fullName);
            setEmail(profile?.email);
            setPhone((prev) => ({
                ...prev,
                phoneNumber: profile?.rawMobile,
                ext: profile?.mobileExtension,
            }));
        }
    }, [updateUserProfileSuccess]);

    return (
        <div className="mt-[34px]">
            <div className="flex gap-[45px] flex-col md:flex-row ">
                <div className="rounded  w-full md:w-[45%] md:border md:border-slate-100 bg-white md:p-5 flex flex-col gap-[26px]">
                    <p className="text-base font-rocGroteskMedium">Personal Info</p>
                    <div className="flex items-center gap-[26px] flex-col md:flex-row">
                        <div className="w-[74px] h-[74px]  rounded-full flex items-center justify-center text-xl border ">
                            {!uploading ? (
                                <div className="">
                                    {imageUrl === "" ? (
                                        <img
                                            src={
                                                "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1694624630/Tradeally_2.0/undefined_-_Imgur_1_prpxel.jpg"
                                            }
                                            className="w-[74px] flex items-center justify-center h-[74px]  rounded-full"
                                            alt="image-placeholder-logo"
                                        />
                                    ) : (
                                        <img
                                            src={imageUrl}
                                            className="w-[74px] flex items-center justify-center h-[74px]  rounded-full"
                                            alt="business-logo"
                                        />
                                    )}
                                </div>
                            ) : (
                                <Loader />
                            )}
                        </div>
                        <div className="relative group w-[140px] mt-2 cursor-pointer flex justify-center items-center">
                            <input
                                className="absolute top-0 right-0 bottom-0 left-0 w-[inherit] z-[1] opacity-0 outline-none "
                                type="file"
                                accept="image/*"
                                name="bgfile"
                                id="bgfile"
                                onChange={(evt) => {
                                    handleUpload(evt.target.files[0].name, evt.target.files[0]);
                                }}
                            />
                            <div
                                onClick={() => {
                                    const elem = document.getElementById("bgfile");
                                    elem?.click();
                                }}
                                className="relative z-10 cursor-pointer md:border rounded px-4 py-2 bg-[#f4f5f7] md:bg-transparent"
                            >
                                <p className=" text-slate-700  font-rocGroteskMedium text-sm">
                                    Change photo
                                </p>
                            </div>
                        </div>
                    </div>
                    <div
                        className="flex items-end md:items-center font-rocGroteskMedium justify-between border-b border-b-slate-100 md:border-none
                    pb-[10px] md:pb-0"
                    >
                        <div className="w-[60%]">
                            <p className="text-sm">Full name</p>
                            <p className="text-sm text-slate-500 font-rocGroteskMedium md:font-rocGroteskRegular pt-[10px] ">
                                {profile?.fullName}
                            </p>
                        </div>
                        <div className="cursor-pointer" onClick={() => setOpenEditName(true)}>
                            <p className="text-sm font-rocGroteskMedium text-b-60 hidden md:block">
                                Edit name
                            </p>
                            <img
                                src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1703076259/edit_j8cs2p.svg"
                                className=" md:hidden"
                                alt="edit icon"
                            />
                        </div>
                    </div>
                    <div
                        className="flex items-end md:items-center font-rocGroteskMedium justify-between border-b border-b-slate-100 md:border-none
                    pb-[10px] md:pb-0"
                    >
                        <div className="w-[60%]">
                            <p className="text-sm">Email address</p>
                            <p className="text-sm text-slate-500 font-rocGroteskMedium md:font-rocGroteskRegular pt-[10px]">
                                {profile?.email}
                            </p>
                        </div>
                        <div className="cursor-pointer" onClick={() => setOpenEditEmail(true)}>
                            <p className="text-sm font-rocGroteskMedium text-b-60 text-right hidden md:block">
                                Edit email address
                            </p>
                            <img
                                src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1703076259/edit_j8cs2p.svg"
                                className=" md:hidden"
                                alt="edit icon"
                            />
                        </div>
                    </div>
                    <div
                        className="flex items-end md:items-center font-rocGroteskMedium justify-between border-b border-b-slate-100 md:border-none
                    pb-[10px] md:pb-0"
                    >
                        <div className="w-[60%]">
                            <p className="text-sm">Phone number</p>
                            <p className="text-sm text-slate-500 font-rocGroteskMedium md:font-rocGroteskRegular pt-[10px]">
                                {profile?.mobile}
                            </p>
                        </div>
                        <div className="cursor-pointer" onClick={() => setOpenEditPhone(true)}>
                            <p className="text-sm font-rocGroteskMedium text-b-60 text-right hidden md:block">
                                Edit phone number
                            </p>
                            <img
                                src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1703076259/edit_j8cs2p.svg"
                                className=" md:hidden"
                                alt="edit icon"
                            />
                        </div>
                    </div>
                    {/* <div className="flex items-center font-rocGroteskMedium justify-between">
                        <div className="w-[60%]">
                            <p className="text-sm">Email address</p>
                            <p className="text-sm text-slate-500 font-rocGroteskRegular">
                                {profile?.email}
                            </p>
                        </div>
                        <div className="cursor-pointer" onClick={() => setOpenEditEmail(true)}>
                            <p className="text-sm font-rocGroteskMedium text-b-60">
                                Edit email address
                            </p>
                        </div>
                    </div>
                    <div className="flex items-center font-rocGroteskMedium justify-between">
                        <div className="w-[60%]">
                            <p className="text-sm">Phone number</p>
                            <p className="text-sm text-slate-500 font-rocGroteskRegular">
                                {profile?.mobile}
                            </p>
                        </div>
                        <div className="cursor-pointer" onClick={() => setOpenEditPhone(true)}>
                            <p className="text-sm font-rocGroteskMedium text-b-60">
                                Edit phone number
                            </p>
                        </div>
                    </div> */}
                    {/* <div className="flex items-center font-rocGroteskMedium justify-between">
                <div className="w-[60%]">
                    <p className="text-sm">Role</p>
                    <p className="text-sm text-slate-500">Admin</p>
                </div>
                <p className="text-sm font-rocGroteskMedium text-b-60">Edit role</p>
            </div> */}
                    {/* <div className="flex items-center font-rocGroteskMedium justify-between">
                <div className="w-[60%]">
                    <p className="text-sm">Group</p>
                    <p className="text-sm text-slate-500">Admin</p>
                </div>
                <p className="text-sm font-rocGroteskMedium text-b-60">Edit group</p>
            </div> */}
                </div>

                {userProfile?.user?.role?.toLowerCase() === "owner" && (
                    <div className="w-full md:w-[45%]">
                        <BusinessInfo />
                    </div>
                )}
            </div>

            <ModalContainer
                open={openEditName}
                showCloseIcon={false}
                modalClassName={"p-4 md:p-10"}
                tailwindClassName="w-[553px]"
                closeModal={() => {
                    setOpenEditName(false);
                    setName("");
                }}
            >
                <div className="bg-white px-2  pt-3 pb-10 shadow-cardShadow relative rounded-[20px]">
                    <div className="px-4 flex justify-end ">
                        <i
                            onClick={() => {
                                setOpenEditName(false);
                                setName(profile?.fullName);
                            }}
                            className="ri-close-fill cursor-pointer  text-gm-45 text-[24px]"
                        ></i>
                    </div>
                    <div className="flex px-4 md:px-8 flex-col items-center ">
                        <div className="text-center w-full mb-[46px]">
                            <p className="mb-2.5 text-2xl font-rocGroteskMedium text-g-75">
                                Edit full name
                            </p>
                        </div>
                        <form
                            className="w-full"
                            onSubmit={(e) => {
                                e.preventDefault();
                                handleNameSubmit();
                            }}
                        >
                            <div className="mb-10">
                                <div className="flex flex-col gap-6 w-full">
                                    <TextInput
                                        name={"name"}
                                        type="text"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                        placeholder="Edit full name"
                                        required={true}
                                        containerClassname="mb-2"
                                    />
                                </div>
                            </div>
                            <div className="w-full ">
                                <Button
                                    btnText={"Save changes"}
                                    type={"submit"}
                                    isLoading={updateUserProfileRequest}
                                />
                                <Button
                                    btnText={"Close"}
                                    disabled={updateUserProfileRequest}
                                    onClick={() => setOpenEditName(false)}
                                    btnClassname={"!bg-transparent !text-gm-75 mt-2"}
                                />
                            </div>
                        </form>
                    </div>
                </div>
            </ModalContainer>
            <ModalContainer
                open={openEditEmail}
                showCloseIcon={false}
                modalClassName={"p-4 md:p-10"}
                tailwindClassName="w-[553px]"
                closeModal={() => {
                    setEmail(profile?.email);
                    setOpenEditEmail(false);
                }}
            >
                <div className="bg-white px-2  pt-3 pb-10 shadow-cardShadow relative rounded-[20px]">
                    <div className=" px-4 flex justify-end ">
                        <i
                            onClick={() => {
                                setEmail(profile?.email);
                                setOpenEditEmail(false);
                            }}
                            className="ri-close-fill cursor-pointer text-gm-45 text-[24px]"
                        ></i>
                    </div>
                    <div className="flex px-4 md:px-8 flex-col items-center ">
                        <div className="text-center w-full mb-[46px]">
                            <p className="mb-2.5 text-2xl font-rocGroteskMedium text-g-75">
                                Edit email
                            </p>
                        </div>
                        <form
                            className="w-full"
                            onSubmit={(e) => {
                                e.preventDefault();
                                handleEmailSubmit();
                            }}
                        >
                            <div className="mb-10">
                                <div className="flex flex-col gap-6 w-full">
                                    <TextInput
                                        name={"email"}
                                        type="text"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        placeholder="Edit email"
                                        required={true}
                                        containerClassname="mb-2"
                                    />
                                </div>
                            </div>
                            <div className="w-full ">
                                <Button
                                    btnText={"Save changes"}
                                    type={"submit"}
                                    isLoading={updateUserProfileRequest}
                                />
                                <Button
                                    btnText={"Close"}
                                    disabled={updateUserProfileRequest}
                                    onClick={() => setOpenEditEmail(false)}
                                    btnClassname={"!bg-transparent !text-gm-75 mt-2"}
                                />
                            </div>
                        </form>
                    </div>
                </div>
            </ModalContainer>
            <ModalContainer
                open={openEditPhone}
                showCloseIcon={false}
                modalClassName={"p-4 md:p-10"}
                tailwindClassName="w-[553px]"
                closeModal={() => {
                    setOpenEditPhone(false);
                    setPhone((prev) => ({
                        ...prev,
                        phoneNumber: profile?.rawMobile,
                        ext: profile?.mobileExtension,
                    }));
                }}
            >
                <div className="bg-white px-2  pt-3 pb-10 shadow-cardShadow relative rounded-[20px]">
                    <div className="px-4 flex justify-end ">
                        <i
                            onClick={() => {
                                setOpenEditPhone(false);
                                setPhone((prev) => ({
                                    ...prev,
                                    phoneNumber: profile?.rawMobile,
                                    ext: profile?.mobileExtension,
                                }));
                            }}
                            className="ri-close-fill cursor-pointer  text-gm-45 text-[24px]"
                        ></i>
                    </div>
                    <div className="flex px-4 md:px-8 flex-col items-center ">
                        <div className="text-center w-full mb-[46px]">
                            <p className="mb-2.5 text-2xl font-rocGroteskMedium text-g-75">
                                Edit phone number
                            </p>
                        </div>
                        <form
                            className="w-full"
                            onSubmit={(e) => {
                                e.preventDefault();
                                handlePhoneSubmit();
                            }}
                        >
                            <div className="mb-10">
                                <div className="w-full grid grid-cols-[115px_calc(100%-119px)] gap-1 mb-6">
                                    <SelectInput
                                        value={phone.ext || ""}
                                        name="phoneExt"
                                        placeholder={"Ext"}
                                        handleChange={(_, value) => {
                                            setPhone({
                                                ...phone,
                                                ext: value,
                                            });
                                        }}
                                        isRequired={true}
                                        inputClassName={
                                            "!placeholder:text-slate-400 !placeholder:font-rocGroteskMedium"
                                        }
                                        dropdownOptions={countries
                                            .filter((data) => Boolean(data?.codes[0]))
                                            .sort((p1, p2) =>
                                                p1.codes[0] > p2.codes[0]
                                                    ? 1
                                                    : p1.codes[0] < p2.codes[0]
                                                    ? -1
                                                    : 0
                                            )
                                            .map((item) => ({
                                                label: item.codes[0]?.replace(" ", ""),
                                                value: item.codes[0]?.replace(" ", ""),
                                                icon: (
                                                    <ReactCountryFlag
                                                        countryCode={item?.abbreviation}
                                                        svg
                                                        cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
                                                        cdnSuffix="svg"
                                                        style={{
                                                            width: "16px",
                                                            height: "16px",
                                                            borderRadius: "50%",
                                                            marginTop: "-4px",
                                                        }}
                                                    />
                                                ),
                                            }))}
                                    />
                                    <TextInput
                                        name={"phoneNo"}
                                        type={"number"}
                                        value={phone.phoneNumber}
                                        placeholder={"Phone number"}
                                        onChange={(e) =>
                                            setPhone({
                                                ...phone,
                                                phoneNumber: e.target.value,
                                            })
                                        }
                                        minLength={10}
                                        onKeyDown={(evt) =>
                                            ["e", "E", "+", "-", "ArrowUp", "ArrowDown"].includes(
                                                evt.key
                                            ) && evt.preventDefault()
                                        }
                                        required={true}
                                        pattern={"^[0-9]+$"}
                                        title={"must be digits"}
                                        inputClassName={
                                            "!placeholder:text-slate-400 !placeholder:font-rocGroteskMedium"
                                        }
                                    />
                                </div>
                            </div>
                            <div className="w-full ">
                                <Button
                                    btnText={"Save changes"}
                                    type={"submit"}
                                    isLoading={updateUserProfileRequest}
                                />
                                <Button
                                    btnText={"Close"}
                                    disabled={updateUserProfileRequest}
                                    onClick={() => setOpenEditPhone(false)}
                                    btnClassname={"!bg-transparent !text-gm-75 mt-2"}
                                />
                            </div>
                        </form>
                    </div>
                </div>
            </ModalContainer>
        </div>
    );
};

export default PersonalInfo;
