import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { inventoryActions } from "redux/Ldb/actions";
import { useAppDispatch, useAppSelector } from "../storeHooks";

const useGetBundleDetails = () => {
    const { bundleId } = useParams();
    const dispatch = useAppDispatch();
    const [bundleDetails, setBundleDetails] = useState<{ [key: string]: any }>({});

    const {
        fetchingBundleDetails,
        fetchedBundleDetailsSuccess,
        fetchedBundleDetailsFailure,
    } = useAppSelector((state) => state.inventory);

    useEffect(() => {
        if (Boolean(fetchedBundleDetailsSuccess)) {
            setBundleDetails(fetchedBundleDetailsSuccess?.bundle?.[0]);
        }
    }, [fetchedBundleDetailsSuccess]);

    useEffect(() => {
        if (bundleId) {
            dispatch(inventoryActions.fetchSingleBundle(bundleId));
        }
    }, [dispatch, bundleId]);
    return {
        isFetching: fetchingBundleDetails,
        error: fetchedBundleDetailsFailure,
        data: bundleDetails,
    };
};

export default useGetBundleDetails;
