export const taskTypes = {
    CREATE_TASK_REQUEST: "CREATE_TASK_REQUEST",
    CREATE_TASK_SUCCESS: "CREATE_TASK_SUCCESS",
    CREATE_TASK_FAILURE: "CREATE_TASK_FAILURE",
    UPDATE_TASK_REQUEST: "UPDATE_TASK_REQUEST",
    UPDATE_TASK_SUCCESS: "UPDATE_TASK_SUCCESS",
    UPDATE_TASK_FAILURE: "UPDATE_TASK_FAILURE",
    FETCH_TASK_LIST_REQUEST: "FETCH_TASK_LIST_REQUEST",
    FETCH_TASK_LIST_SUCCESS: "FETCH_TASK_LIST_SUCCESS",
    FETCH_TASK_LIST_FAILURE: "FETCH_TASK_LIST_FAILURE",
    FETCH_SINGLE_TASK_REQUEST: "FETCH_SINGLE_TASK_REQUEST",
    FETCH_SINGLE_TASK_SUCCESS: "FETCH_SINGLE_TASK_SUCCESS",
    FETCH_SINGLE_TASK_FAILURE: "FETCH_SINGLE_TASK_FAILURE",
    FETCH__PENDING_TASK_LIST_REQUEST: " FETCH__PENDING_TASK_LIST_REQUEST",
    FETCH__PENDING_TASK_LIST_SUCCESS: "FETCH__PENDING_TASK_LIST_SUCCESS",
    FETCH__PENDING_TASK_LIST_FAILURE: " FETCH__PENDING_TASK_LIST_FAILURE",
    FETCH__COMPLETED_TASK_LIST_REQUEST: " FETCH__COMPLETED_TASK_LIST_REQUEST",
    FETCH__COMPLETED_TASK_LIST_SUCCESS: "FETCH__COMPLETED_TASK_LIST_SUCCESS",
    FETCH__COMPLETED_TASK_LIST_FAILURE: " FETCH__COMPLETED_TASK_LIST_FAILURE",
    FETCH__OVERDUE_TASK_LIST_REQUEST: "FETCH__OVERDUE_TASK_LIST_REQUEST",
    FETCH__OVERDUE_TASK_LIST_SUCCESS: "FETCH__OVERDUE_TASK_LIST_SUCCESS",
    FETCH__OVERDUE_TASK_LIST_FAILURE: "FETCH__OVERDUE_TASK_LIST_FAILURE",
    FETCH__ACTIVE_TASK_LIST_REQUEST: "FETCH__ACTIVE_TASK_LIST_REQUEST",
    FETCH__ACTIVE_TASK_LIST_SUCCESS: "FETCH__ACTIVE_TASK_LIST_SUCCESS",
    FETCH__ACTIVE_TASK_LIST_FAILURE: "  FETCH__ACTIVE_TASK_LIST_FAILURE",
    FETCH__ALL_TASK_LIST_REQUEST: "FETCH__ALL_TASK_LIST_REQUEST",
    FETCH__ALL_TASK_LIST_SUCCESS: "FETCH__ALL_TASK_LIST_SUCCESS",
    FETCH__ALL_TASK_LIST_FAILURE: "  FETCH__ALL_TASK_LIST_FAILURE",
    FETCH__MY_TASK_LIST_REQUEST: "FETCH__MY_TASK_LIST_REQUEST",
    FETCH__MY_TASK_LIST_SUCCESS: "FETCH__MY_TASK_LIST_SUCCESS",
    FETCH__MY_TASK_LIST_FAILURE: "  FETCH__MY_TASK_LIST_FAILURE",
    FETCH__PENDING_TASK_TWO_LIST_REQUEST: " FETCH__PENDING_TASK_TWO_LIST_REQUEST",
    FETCH__PENDING_TASK_TWO_LIST_SUCCESS: "FETCH__PENDING_TASK_TWO_LIST_SUCCESS",
    FETCH__PENDING_TASK_TWO_LIST_FAILURE: " FETCH__PENDING_TASK_TWO_LIST_FAILURE",
    FETCH__COMPLETED_TASK_TWO_LIST_REQUEST: " FETCH__COMPLETED_TASK_TWO_LIST_REQUEST",
    FETCH__COMPLETED_TASK_TWO_LIST_SUCCESS: "FETCH__COMPLETED_TASK_TWO_LIST_SUCCESS",
    FETCH__COMPLETED_TASK_TWO_LIST_FAILURE: " FETCH__COMPLETED_TASK_TWO_LIST_FAILURE",
    FETCH__OVERDUE_TASK_TWO_LIST_REQUEST: "FETCH__OVERDUE_TASK_TWO_LIST_REQUEST",
    FETCH__OVERDUE_TASK_TWO_LIST_SUCCESS: "FETCH__OVERDUE_TASK_TWO_LIST_SUCCESS",
    FETCH__OVERDUE_TASK_TWO_LIST_FAILURE: "FETCH__OVERDUE_TASK_TWO_LIST_FAILURE",
    FETCH__ACTIVE_TASK_TWO_LIST_REQUEST: "FETCH__ACTIVE_TASK_TWO_LIST_REQUEST",
    FETCH__ACTIVE_TASK_TWO_LIST_SUCCESS: "FETCH__ACTIVE_TASK_TWO_LIST_SUCCESS",
    FETCH__ACTIVE_TASK_TWO_LIST_FAILURE: "  FETCH__ACTIVE_TASK_TWO_LIST_FAILURE",
    FETCH__ALL_TASK_TWO_LIST_REQUEST: "FETCH__ALL_TASK_TWO_LIST_REQUEST",
    FETCH__ALL_TASK_TWO_LIST_SUCCESS: "FETCH__ALL_TASK_TWO_LIST_SUCCESS",
    FETCH__ALL_TASK_TWO_LIST_FAILURE: "  FETCH__ALL_TASK_TWO_LIST_FAILURE",
    FETCH__MY_TASK_TWO_LIST_REQUEST: "FETCH__MY_TASK_TWO_LIST_REQUEST",
    FETCH__MY_TASK_TWO_LIST_SUCCESS: "FETCH__MY_TASK_TWO_LIST_SUCCESS",
    FETCH__MY_TASK_TWO_LIST_FAILURE: "  FETCH__MY_TASK_TWO_LIST_FAILURE",
};
