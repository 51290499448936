import React, { useEffect, useState } from "react";
import CustomTable from "components/common/CustomTable";
import EmptyStateComp from "components/common/EmptyStateComp";
import VendorAvatar from "components/common/VendorAvatar";
import { truncate } from "lodash";
import ChartView from "components/views/Synth2.0/Production/ProductionViewType/ChartView";
import ListView from "components/views/Synth2.0/Production/ProductionViewType/ListView";
import GridView from "components/views/Synth2.0/Production/ProductionViewType/GridView";
import moment from "moment";
import Dropdown from "components/common/Dropdown";
import useGetProductionPlanList from "hooks/ProductionHooks/useGetProductionPlanList";
import { useNavigate, useSearchParams } from "react-router-dom";

type productionProps = {
    search: string;
    setSearch: (data: string) => void;
    setStatus: (data: string) => void;
    setAssigneeId: (data: string) => void;
};

const ProductionPlan = ({ search, setSearch, setStatus, setAssigneeId }: productionProps) => {
    const navigate = useNavigate();
    const [param] = useSearchParams();
    const planTab = param.get("planTab");
    const [view, setView] = useState<string>("day");
    const [viewType, setViewType] = useState<string>("chart");
    const [gridDate, setGridDate] = useState<any>(new Date());

    const period = ["day", "week", "month"];

    const { data, assigneeId, status } = useGetProductionPlanList();

    const tableHeader = [
        { title: "Production ID", widthClass: "w-[165px]" },
        { title: "Product", widthClass: "w-[375px]" },
        { title: "Expected Qty", widthClass: "w-[130px]" },

        { title: "Start Date", widthClass: "w-[135px]" },
        { title: "End Date", widthClass: "w-[135px]" },
        { title: "Status", widthClass: "w-[200px]" },

        { title: "Production Manager", widthClass: "w-[183px]" },
        { title: "Production Location", widthClass: "w-[190px]" },
    ];

    const tableBody = [1, 1, 1, 1, 1, 1, 1, 1, 1, 1]?.map((product, idx) => [
        {
            content: (
                <div
                    key={idx}
                    className={`!py-2 !pr-4 font-rocGroteskMedium border-slate-200 text-sm text-gm-50 `}
                >
                    <p>PO-20240815-001</p>
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`!py-2 !pr-4 font-rocGroteskMedium border-slate-200 text-sm text-gm-50 `}
                >
                    <div className="flex items-center gap-2">
                        {!product ? (
                            <VendorAvatar
                                // imageSrc={
                                //     product?.productImageDetails?.productAvatar ||
                                //     product?.productImageDetails?.productImages?.[0]
                                // }
                                size={32}
                                // name={product?.productName}
                                containerClassname="!rounded-md"
                            />
                        ) : (
                            <div className="w-8 h-8 rounded shrink-0 flex items-center justify-center bg-slate-200 ">
                                <i className="ri-box-2-fill text-lg text-slate-500"></i>
                            </div>
                        )}

                        {/* <span>{truncate(item?.inventory?.productName, { length: 20 })}</span> */}
                        <div className="text-sm">
                            <p className="text-[#142837]"> Kōloa Kaua'i White Rum</p>
                            <p className="text-[#64748B]">KLR-WHT-001</p>
                        </div>
                    </div>
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`!py-2 !pr-4 font-rocGroteskMedium border-slate-200 text-sm text-gm-50 `}
                >
                    <p>1,200 cases</p>
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`!py-2 !pr-4 font-rocGroteskMedium border-slate-200 text-sm text-gm-50 `}
                >
                    <p>Aug 15, 2024</p>
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`!py-2 !pr-4 font-rocGroteskMedium border-slate-200 text-sm text-gm-50 `}
                >
                    <p>Aug 22, 2024</p>
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`!py-2 !pr-4 font-rocGroteskMedium border-slate-200 text-sm text-gm-50 `}
                >
                    <div className="flex items-center gap-2">
                        <div
                            className={`w-2 h-2 rounded-full shrink-0 flex items-center justify-center 
                                    ${
                                        idx === 0
                                            ? "bg-[#3B82F6]"
                                            : idx === 1
                                            ? "bg-[#14B8A6]"
                                            : idx === 2
                                            ? "bg-[#22C55E]"
                                            : idx === 3
                                            ? "bg-[#F97316]"
                                            : idx === 4
                                            ? "bg-[#EF4444]"
                                            : idx === 5
                                            ? "bg-[#EAB308]"
                                            : idx === 6
                                            ? "bg-[#8B5CF6]"
                                            : idx === 7
                                            ? "bg-[#6366F1]"
                                            : idx === 8
                                            ? "bg-[#6B7280]"
                                            : idx === 9 && "bg-[#0EA5E9]"
                                    }`}
                        ></div>

                        <span>
                            {" "}
                            {idx === 0
                                ? "In Progress"
                                : idx === 1
                                ? "Planned"
                                : idx === 2
                                ? "Completed"
                                : idx === 3
                                ? "Delayed"
                                : idx === 4
                                ? "Cancelled"
                                : idx === 5
                                ? "On Hold"
                                : idx === 6
                                ? "Rescheduled"
                                : idx === 7
                                ? "Pending Approval"
                                : idx === 8
                                ? "Not Started"
                                : idx === 9 && "Partially Completed"}
                        </span>
                    </div>
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`!py-2 !pr-4 font-rocGroteskMedium border-slate-200 text-sm text-gm-50 `}
                >
                    <div className="flex items-center gap-2">
                        {!product ? (
                            <VendorAvatar
                                // imageSrc={
                                //     product?.productImageDetails?.productAvatar ||
                                //     product?.productImageDetails?.productImages?.[0]
                                // }
                                size={32}
                                // name={product?.productName}
                                containerClassname="!rounded-md"
                            />
                        ) : (
                            <div className="w-5 h-5 rounded-full shrink-0 flex items-center justify-center bg-slate-100 ">
                                <i className="ri-user-follow-line text-sm text-gm-50"></i>
                            </div>
                        )}

                        <span>Kaleo Kealoha</span>
                    </div>
                </div>
            ),
        },
    ]);

    useEffect(() => {
        setSearch("");
        setStatus("");
        setAssigneeId("");
    }, [viewType]);

    useEffect(() => {
        if (planTab === "1") {
            setViewType("list");
        } else if (planTab === "2") {
            setViewType("grid");
        } else {
            setViewType("list");
        }
    }, []);
    return (
        <div className="font-rocGroteskMedium relative">
            {viewType !== "grid" && (
                <div className="flex items-center justify-between p-4 border-t border-x border-slate-200 rounded-t-lg">
                    {viewType === "grid" && (
                        <div className="flex items-center justify-between p-4">
                            <div className="flex gap-2 ">
                                <button className="bg-slate-100 rounded-md p-[6px]">
                                    <i className="ri-arrow-right-line text-gm-50 text-sm"></i>
                                </button>
                                <button className="">Next</button>
                            </div>
                            {viewType === "grid" && (
                                <p className="text-base text-g-75 ">
                                    {/* Sun Aug 25, 2024  */}
                                    {moment(gridDate).format("MMM Do, YY")}
                                </p>
                            )}
                        </div>
                    )}

                    <p className="text-[#000] text-base">
                        {viewType === "chart"
                            ? "Production Forecast"
                            : viewType === "list"
                            ? "All production plan"
                            : ""}
                    </p>

                    <div className="flex gap-2 items-center">
                        {viewType === "chart" && (
                            <div className="flex gap-2 items-center">
                                <p className="text-[13px] text-[#000]">Filter by</p>
                                <Dropdown
                                    handleChange={(name, value) => {}}
                                    dropdown={
                                        <div className="flex items-center justify-between rounded-[6px] py-1 px-3 !border !border-slate-200 gap-2">
                                            <div
                                                className={`text-gm-50 font-rocGroteskMedium text-[13px] inline-block `}
                                            >
                                                No of units
                                            </div>
                                            <i className="ri-arrow-down-s-line   text-[#142837]"></i>
                                        </div>
                                    }
                                    dropdownItemsClasses="!px-0 !py-0"
                                    dropdownClassName="!w-full !mb-10 !-mt-7"
                                    dropdownContainerClasses={`shadow-cardShadow-4 border border-slate-100 right-0 rounded-[6px] !max-h-fit !w-auto`}
                                    name="valueRange"
                                    dropdownOptions={[]}
                                />
                            </div>
                        )}
                        <div className="flex">
                            <button
                                className={`${
                                    viewType === "chart" && "bg-slate-200"
                                } py-[6px] px-3 text-gm-50 text-[13px] border-l  border border-slate-200 rounded-l`}
                                onClick={() => setViewType("chart")}
                            >
                                <i className="ri-line-chart-line"></i>
                            </button>
                            <div className="border-slate-200 border rounded">
                                <button
                                    className={`${
                                        viewType === "list" && "bg-slate-200"
                                    } py-[6px] px-3 text-gm-50 text-[13px] border-l`}
                                    onClick={() => setViewType("list")}
                                >
                                    <i className="ri-list-check"></i>
                                </button>
                                <button
                                    className={`${
                                        viewType === "grid" && "bg-slate-200"
                                    } py-[6px] px-3 text-gm-50 text-[13px] border-l border-slate-200`}
                                    onClick={() => setViewType("grid")}
                                >
                                    <i className="ri-layout-grid-line"></i>
                                </button>
                            </div>
                        </div>

                        {viewType !== "list" && (
                            <div className="border-slate-200 border rounded">
                                {period?.map((item, idx) => {
                                    return (
                                        <button
                                            key={idx}
                                            className={`${
                                                idx === 1 && "border-x border-x-slate-200"
                                            } ${
                                                view === item && "bg-slate-200"
                                            } py-[6px] px-3 text-gm-50 text-[13px] leading-normal`}
                                            onClick={() => setView(item)}
                                        >
                                            {item}
                                        </button>
                                    );
                                })}
                            </div>
                        )}
                    </div>
                </div>
            )}
            {viewType === "chart" ? (
                <ChartView />
            ) : viewType === "list" ? (
                <ListView search={search} setSearch={setSearch} />
            ) : (
                viewType === "grid" && (
                    <div className="">
                        <GridView
                            setGridDate={setGridDate}
                            gridDate={gridDate}
                            view={view}
                            setView={setView}
                            viewType={viewType}
                            setViewType={setViewType}
                        />

                        {(search || assigneeId || status) && data?.length === 0 ? (
                            <EmptyStateComp
                                title=""
                                description=" Sorry, no results match your search. Refine your criteria and
                                    try again."
                                icon={<i className="ri-building-3-line text text-[40px]"></i>}
                                contentContainerClass="flex flex-col items-center max-w-[537px] !bg-white"
                                descriptionClass="flex flex-col items-center justify-center"
                                className="absolute top-[10%] z-[1000]"
                            />
                        ) : (
                            data?.length === 0 && (
                                <div className="border-x border-b border-slate-200">
                                    <EmptyStateComp
                                        title="No production schedule plan found"
                                        description="It looks like you don't have any production schedule plans at the moment. To get started, create a new schedule plan now!"
                                        icon={
                                            <i className="ri-building-3-line text text-[40px]"></i>
                                        }
                                        hasButton={true}
                                        btnText="Create production plan"
                                        btnAction={() =>
                                            navigate("/dashboard/production/create-plan?planTab=2")
                                        }
                                        contentContainerClass="flex flex-col items-center max-w-[537px] !bg-white"
                                        descriptionClass="flex flex-col items-center"
                                        className="absolute top-[10%] z-[1000]"
                                    />
                                </div>
                            )
                        )}
                    </div>
                )
            )}
        </div>
    );
};

export default ProductionPlan;
