import React from "react";

type VendorAvatarProps = {
    size: number | string;
    containerClassname?: string;
    textClassname?: string;
    name?: string;
    imageSrc?: string;
};

const VendorAvatar = ({
    size,
    containerClassname,
    textClassname,
    name,
    imageSrc,
}: VendorAvatarProps) => {
    const nameArr = name ? name?.split(" ") : [];
    const initials = nameArr
        ?.map((user) => user[0]?.toUpperCase())
        ?.join("")
        ?.slice(0, 3);

    return imageSrc ? (
        <img
            src={imageSrc}
            width={size}
            height={size}
            alt="avatar"
            className={`rounded-full shrink-0 object-cover w-[${size}px] h-[${size}px] ${containerClassname}`}
            style={{width: `${size}px`, height: `${size}px`}}
        />
    ) : (
        <div
            className={`w-[${size}px] h-[${size}px] shrink-0 rounded-full bg-p-55 flex items-center justify-center ${containerClassname}`}
            style={{width: `${size}px`, height: `${size}px`}}
        >
            <p className={`text-xs uppercase text-white font-rocGroteskBold ${textClassname}`}>
                {initials || "V"}
            </p>
        </div>
       
    );
};

export default VendorAvatar;
