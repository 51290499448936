import React from "react";
import Loader from "../common/Loader";

type PageFrameProps = {
    isLoading?: boolean;
    children: Element | JSX.Element | JSX.Element[] | Element[];
    containerClassName?: string;
};

const PageFrame = ({isLoading, children, containerClassName}: PageFrameProps) => {
    if (isLoading) {
        return (
            <div
                className={`w-full min-h-screen  flex items-center  justify-center ${containerClassName}`}
            >
                <Loader color={"gm-50"} size={8} />
            </div>
        );
    }

    return children as JSX.Element;
};

export default PageFrame;
