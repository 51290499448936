import { useEffect, useState } from "react";
import { salesActions } from "redux/Ldb/actions";
import { useAppDispatch, useAppSelector } from "../storeHooks";
import { useParams } from "react-router-dom";
import { getCurrentStartAndEndDate } from "helpers/getCurrentStartAndEndDate";

const useGetSalesChannelStats = () => {
    const dispatch = useAppDispatch();
    const { fetchingSalesChannelStat, fetchSalesChannelStatSuccess, fetchSalesChannelStatFailure } =
        useAppSelector((state) => state.sales);

    const [salesChannelData, setSalesChannelData] = useState<{ [key: string]: any }>({});
    const [startEndDate, setStartEndDate] = useState<{ [key: string]: any }>(
        getCurrentStartAndEndDate("monthly")
    );

    const handleDurationChange = (value: string) => {
        const startAndEndDate = getCurrentStartAndEndDate(value);
        setStartEndDate(startAndEndDate);
    };

    useEffect(() => {
        dispatch(salesActions.getSalesChannelStat(startEndDate?.start, startEndDate?.end));

        return () => {
            dispatch(salesActions.resetGetSalesChannelStatSuccess());
        };
    }, [dispatch, startEndDate?.start, startEndDate?.end]);

    useEffect(() => {
        if (Boolean(fetchSalesChannelStatSuccess)) {
            setSalesChannelData(fetchSalesChannelStatSuccess);
        }
    }, [dispatch, fetchSalesChannelStatSuccess]);

    return {
        isFetching: fetchingSalesChannelStat,
        error: fetchSalesChannelStatFailure,
        data: salesChannelData,
        setSalesChannelData,
        handleDurationChange,
    };
};

export default useGetSalesChannelStats;
