import config from "config/config";
import { authService } from "./authService";

export const documentService = {
    validateDocument,
};

async function validateDocument(data: Record<string, any> | Array<Record<string, any>>) {
    const requestOptions = {
        method: "POST",
        body: JSON.stringify(data),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/ai/validate/document`,
        requestOptions
    );

    return res;
}
