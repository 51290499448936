import { useEffect, useState } from "react";
import { inventoryActions } from "redux/Ldb/actions";
import { useAppDispatch, useAppSelector } from "../storeHooks";
import { useParams } from "react-router-dom";

const useCloseInventoryIncident = () => {
    const dispatch = useAppDispatch();
    const { alertId } = useParams();

    const { closeInventoryIncident, closeInventoryIncidentSuccess, closeInventoryIncidentFailure } =
        useAppSelector((state) => state.inventory);
    const [closeSuccessful, setCloseSuccessful] = useState<{ [key: string]: any }>([]);
    const [close, setClose] = useState<boolean>(false);

    useEffect(() => {
        if (alertId && close) {
            dispatch(inventoryActions.closeInventoryIncident(alertId));
        }
    }, [dispatch, alertId, close]);

    useEffect(() => {
        if (Boolean(closeInventoryIncidentSuccess)) {
            setCloseSuccessful(closeInventoryIncidentSuccess?.incident);
            setClose(false);
        }
    }, [closeInventoryIncidentSuccess]);

    return {
        isFetching: closeInventoryIncident,
        error: closeInventoryIncidentFailure,
        data: closeSuccessful,
        setClose,
    };
};

export default useCloseInventoryIncident;
