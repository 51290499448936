import Button from "components/common/Button";
import CustomTable from "components/common/CustomTable";
import TextInput from "components/common/InputField/TextInput";
import { formatMoney, getCurrencyFromCurrencyCode } from "helpers";
import React, { useEffect, useState } from "react";

type costProps = {
    directCost: Array<{
        category: string;
        amount: string;
        currency: string;
    }>;
    inDirectCost: Array<{
        category: string;
        amount: string;
        currency: string;
    }>;
    productName: string;
};

const CostBreakdown = ({ directCost, inDirectCost, productName }: costProps) => {
    const [directCostSum, setDirectCostSum] = useState<number>();
    const [indirectCostSum, setIndirectCostSum] = useState<number>();

    useEffect(() => {
        const sum = Array.isArray(directCost)
            ? directCost.reduce((sum, cost) => sum + (Number(cost?.amount) || 0), 0)
            : 0;
        setDirectCostSum(sum);
    }, [directCost]);

    useEffect(() => {
        const sum = Array.isArray(directCost)
            ? inDirectCost?.reduce((sum, cost) => sum + (Number(cost?.amount) || 0), 0)
            : 0;
        setIndirectCostSum(sum);
    }, [inDirectCost]);

    const DirectCostTableHeader = [
        { title: "Category", widthClass: "w-[34%]!font-rocGroteskMedium !border-slate-200" },
        {
            title: "Total Estimated Cost",
            widthClass: "w-[33%] !font-rocGroteskMedium !border-slate-200",
            sortIcon: true,
        },
        { title: "Cost Type", widthClass: "w-[33%] !font-rocGroteskMedium !border-slate-200" },
    ];

    const DirectCostTableBody = directCost?.map((item, idx) => {
        return [
            {
                content: (
                    <div
                        key={idx}
                        className={`w-full border-slate-200 text-sm text-g-75 flex items-center px-2.5 font-rocGroteskMedium `}
                    >
                        <p className="py-3">{item?.category}</p>
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                widthClass: "!border-slate-200",
            },
            {
                content: (
                    <div
                        className={`w-full border-slate-200 text-sm text-g-75 flex items-center px-2.5 font-rocGroteskMedium`}
                    >
                        <p className="text-g-75">{item?.currency}</p>
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                widthClass: "!border-slate-200",
            },
            {
                content: (
                    <div
                        className={`w-full border-slate-200 text-[13px] text-g-75 flex items-center px-2.5 font-rocGroteskMedium `}
                    >
                        <p className="py-3">{formatMoney()?.format(item?.amount)}</p>
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                widthClass: "!border-slate-200",
            },
        ];
    });
    const DirectCostTableFooter = () => {
        return (
            <div className="flex rounded-b-md border-x border-b border-slate-200 bg-[#F8FAFC]">
                <div className="border-r border-slate-200 w-[34%]"></div>
                <div className="w-[33%]"></div>
                <div className="w-[33%] border-l border-slate-200">
                    <p className="text-g-75 text-md py-[14px] px-2.5">
                        {" "}
                        ${formatMoney()?.format(directCostSum)}
                    </p>
                </div>
            </div>
        );
    };

    const IndirectCostTableHeader = [
        { title: "Category", widthClass: "w-[34%]!font-rocGroteskMedium !border-slate-200" },
        {
            title: "Total Estimated Cost",
            widthClass: "w-[33%] !font-rocGroteskMedium !border-slate-200",
            sortIcon: true,
        },
        { title: "Cost Type", widthClass: "w-[33%] !font-rocGroteskMedium !border-slate-200" },
    ];

    const IndirectCostTableBody = inDirectCost?.map((item, idx) => {
        return [
            {
                content: (
                    <div
                        key={idx}
                        className={`w-full border-slate-200 text-sm text-g-75 flex items-center px-2.5 font-rocGroteskMedium `}
                    >
                        <p className="py-3">{item?.category}</p>
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                widthClass: "!border-slate-200",
            },
            {
                content: (
                    <div
                        className={`w-full border-slate-200 text-sm text-g-75 flex items-center px-2.5 font-rocGroteskMedium`}
                    >
                        <p className="text-g-75">{item?.currency}</p>
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                widthClass: "!border-slate-200",
            },
            {
                content: (
                    <div
                        className={`w-full border-slate-200 text-[13px] text-slate-500 flex items-center px-2.5 font-rocGroteskMedium `}
                    >
                        <p className="py-3">{formatMoney()?.format(item?.amount)}</p>
                    </div>
                ),
                tableDataDivClass: "!pl-0",
                widthClass: "!border-slate-200",
            },
        ];
    });
    const IndirectCostTableFooter = () => {
        return (
            <div className="flex rounded-b-md border-x border-b border-slate-200 bg-[#F8FAFC]">
                <div className="border-r border-slate-200 w-[34%]"></div>
                <div className="w-[33%]"></div>
                <div className="w-[33%] border-l border-slate-200">
                    <p className="text-g-75 text-md py-[14px] px-2.5">
                        {" "}
                        ${formatMoney()?.format(indirectCostSum)}
                    </p>
                </div>
            </div>
        );
    };

    return (
        <div>
            <div className="">
                <p className="text-gm-75 text-base pb-4">
                    Cost breakdown {productName && `for ${productName}`}
                </p>
                <div className="grid grid-cols-4 gap-2">
                    <div className="border border-slate-200 p-4 rounded-md">
                        <p className="text-slate-500 text-sm">Direct cost</p>

                        <div className={` text-lg text-g-75 py-2 flex items-center`}>
                            <p className=""> ${formatMoney()?.format(directCostSum)}</p>
                        </div>
                    </div>
                    <div className="border border-slate-200 p-4 rounded-md">
                        <p className="text-slate-500 text-sm">Indirect cost</p>

                        <div className={` text-lg text-g-75 py-2 flex items-center`}>
                            <p className=""> ${formatMoney()?.format(indirectCostSum)}</p>
                        </div>
                    </div>{" "}
                    {/* <div className="border border-slate-200 p-4 rounded-md">
                        <p className="text-slate-500 text-sm">Total COGS</p>

                        <div className={` text-lg text-g-75 py-2 flex items-center`}>
                            <p className="">$</p>
                        </div>
                    </div> */}
                    <div className="border border-slate-200 p-4 rounded-md">
                        <p className="text-slate-500 text-sm">Total production cost</p>

                        <div className={` text-lg text-g-75 py-2 flex items-center`}>
                            <p className="">
                                ${formatMoney()?.format(indirectCostSum + directCostSum)}
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="mt-8  ">
                <div className="grid grid-cols-2 gap-6">
                    <div className="">
                        <p className="text-gm-75 text-base p-4 border-x border-t  border-slate-200 rounded-t-md">
                            Direct costs
                        </p>
                        <CustomTable
                            tableBody={DirectCostTableBody}
                            tableHeader={DirectCostTableHeader}
                            isScrollable={false}
                            isCellBordered={true}
                            isCellSelectable={false}
                            isAllSelectable={false}
                            scrollWithin={false}
                            headerContainerClass="!bg-slate-50 !border-slate-200 "
                            headerItemClass="font-rocGroteskMedium !text-[#334155] "
                            tableClass="!border-slate-200"
                            bodyItemClass="hover:!bg-transparent !cursor-default !border-slate-200"
                        />
                        <DirectCostTableFooter />
                    </div>

                    <div className="">
                        <p className="text-gm-75 text-base p-4 border-x border-t  border-slate-200 rounded-t-md">
                            Indirect costs
                        </p>
                        <CustomTable
                            tableBody={IndirectCostTableBody}
                            tableHeader={IndirectCostTableHeader}
                            isScrollable={false}
                            isCellBordered={true}
                            isCellSelectable={false}
                            isAllSelectable={false}
                            scrollWithin={false}
                            headerContainerClass="!bg-slate-50 !border-slate-200 "
                            headerItemClass="font-rocGroteskMedium !text-[#334155] "
                            tableClass="!border-slate-200"
                            bodyItemClass="hover:!bg-transparent !cursor-default !border-slate-200"
                        />
                        <IndirectCostTableFooter />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CostBreakdown;
