import React from "react";
import { Link } from "react-router-dom";
import CookiePrompt from "./CookiePrompt";

const LandingFooter = () => {
    const year = new Date().getFullYear();
    return (
   
        <footer className="h-[450px] max-sm:h-[265px] flex items-center relative">
            <div className="flex flex-col items-center justify-center w-full">
                <img
                    src={
                        "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1715104205/SynthLogo_h118c2.svg"
                    }
                    alt="Logo Icon"
                    className="w-[580px] h-[174px] max-lg:w-[313px] max-lg:h-[96px] mb-6"
                />
                <div className="flex flex-col max-sm:flex-col-reverse items-center text-g-2">
                    <div className="flex items-center space-x-6 mb-6 max-sm:mb-0 max-sm:flex-col max-sm:space-y-6">
                        <div className="flex items-center space-x-6 ">
                            <p className="text-sm font-interDisplay">© {year} SynthAlly, Inc.</p>
                            <Link to="/policy" className="text-sm font-interDisplay ">
                                Privacy
                            </Link>
                            <Link to="/terms" className="text-sm font-interDisplay ">
                                Terms of Service
                            </Link>
                        </div>

                        <div className="flex items-center space-x-6 mb-[-4px]">
                            <a
                                href="https://twitter.com/getsynth"
                                target={"_blank"}
                                rel="noreferrer"
                            >
                                <i className="ri-twitter-x-fill text-2xl"></i>
                            </a>
                            <a
                                href="https://www.facebook.com/getsynth"
                                target={"_blank"}
                                rel="noreferrer"
                            >
                                <i className="ri-facebook-circle-fill text-2xl"></i>
                            </a>
                            <a
                                href="https://www.linkedin.com/company/heysynth/"
                                target={"_blank"}
                                rel="noreferrer"
                            >
                                <i className="ri-linkedin-box-fill text-2xl"></i>
                            </a>
                            <a
                                href="https://www.instagram.com/getsynth/"
                                target={"_blank"}
                                rel="noreferrer"
                            >
                                <i className="ri-instagram-line text-2xl"></i>
                            </a>
                        </div>
                    </div>
                    <div className="flex space-x-2 items-center max-sm:mb-6">
                        <img
                            src={
                                "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1698171595/Web%20App/US_xfqqu5.svg"
                            }
                            alt="us flag"
                            height={20}
                            width={20}
                        />
                        <p className="text-sm font-interDisplay">
                            <span className="max-sm:hidden">SynthAlly Inc</span>{" "}
                            <span className="max-sm:hidden">|</span>{" "}
                            <span>7000 North Mopac Expressway Austin, Texas</span>
                        </p>
                    </div>
                </div>
            </div>
            <div className="absolute w-full h-[8px] bottom-0 flex">
                <div className="h-full w-1/4 bg-green-2" />
                <div className="h-full w-1/4 bg-orange-1" />
                <div className="h-full w-1/4 bg-y-10" />
                <div className="h-full w-1/4 bg-[#E4281D]" />
                <div className="h-full w-1/4 bg-[#A8C10A]" />
            </div>
        </footer>
    
    );
};

export default LandingFooter;
