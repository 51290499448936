import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../storeHooks";
import { trackTraceActions } from "redux/Ldb/actions";

const useGetTrackTracePreviouslySearch = () => {
    const dispatch = useAppDispatch();
    const [previousSearch, setPreviousSearch] = useState<{ [key: string]: any }>([]);
    const {
        fetchingPreviouslySearched,
        fetchPreviouslySearchedSuccess,
        fetchPreviouslySearchedFailure,
    } = useAppSelector((state) => state.trackTrace);

    useEffect(() => {
        if (Boolean(fetchPreviouslySearchedSuccess)) {
            setPreviousSearch(fetchPreviouslySearchedSuccess?.results);
        }
    }, [fetchPreviouslySearchedSuccess]);

    useEffect(() => {
        dispatch(trackTraceActions.getPreviouslySearched());
    }, [dispatch]);

    return {
        data: previousSearch,
        isFetching: fetchingPreviouslySearched,
        error: fetchPreviouslySearchedFailure,
    };
};

export default useGetTrackTracePreviouslySearch;
