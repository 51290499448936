export const getFileTypeFromUrl = (url: string) => {
    // Split the URL into its parts.
    const urlParts = url?.split("/") || [];

    // Get the file extension from the last part of the URL.
    const fileExtension = urlParts?.[urlParts?.length - 1]?.split(".")?.[1]?.split("?")?.[0];

    // Use a MIME type lookup table to get the file type from the file extension.
    const mimeTypeLookupTable: {[key: string]: string} = {
        pdf: "pdf",
        jpg: "jpeg",
        png: "png",
        gif: "gif",
        mp4: "mp4",
        mov: "mov",
        docx: "docx",
        xlsx: "xlsx",
        pptx: "pptx",
    };

    // Return the file type, or 'unknown' if the file extension is not found in the lookup table.
    return mimeTypeLookupTable[fileExtension] || "unknown";
};
