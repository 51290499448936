/* eslint-disable react-hooks/exhaustive-deps */
import Button from "components/common/Button";
import Navbar from "components/common/Navbar/Navbar";
import PageFrame from "components/layout/PageFrame";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { authActions } from "redux/Ldb/actions";
import { useAppDispatch, useAppSelector } from "../../../hooks/storeHooks";

const AcctVerification = () => {
    const [isVerified, setIsVerified] = useState(true);
    const [userProfile, setUserProfile] = useState([]);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [param] = useSearchParams();
    const code = param.get("code");
    const token = param.get("token");

    const { verifyingUser, verifyUserSuccess, verifyUserFailure, resendingVerificationCode } =
        useAppSelector((state) => state.auth);

    const handleResendCode = () => {
        dispatch(authActions.resendVerifyCode(true, token));
    };

    const onBoard = () => {
        navigate(`/new-onboard`);
    };

    const Success = () => {
        return (
            <div className="text-center w-full mt-5">
                <div className="mb-[45px] mt-5 flex justify-center">
                    <img
                        src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1692880591/Tradeally_2.0/verificationSuccess_c54yoa.svg"
                        alt="verification success"
                        width={148}
                        height={145}
                    />
                </div>
                <p className="mb-[45px] text-[22px] font-rocGroteskBold text-g-75">
                    Congratulations! Your account has been created successfully.
                </p>

                <Button
                    btnClassname={" !font-rocGroteskMedium"}
                    onClick={onBoard}
                    btnText={"Set up your workspace"}
                    type={"button"}
                />
            </div>
        );
    };

    const Failure = () => {
        return (
            <div className="text-center w-full">
                <p className="mb-[45px] text-[22px] font-rocGroteskBold text-g-75">
                    Link has expired/invalid, kindly request for a new password reset link.
                </p>

                <Button
                    btnClassname={" !font-rocGroteskRegular"}
                    onClick={handleResendCode}
                    isLoading={resendingVerificationCode}
                    btnText={"Resend link"}
                    type={"button"}
                />
            </div>
        );
    };

    useEffect(() => {
        if (code && token) {
            dispatch(authActions.verifyByMail(code, token));
        }
    }, [dispatch]);

    useEffect(() => {
        if (Boolean(verifyUserSuccess)) {
            setIsVerified(true);
        }

        if (Boolean(verifyUserFailure)) {
            setIsVerified(false);
        }
    }, [verifyUserSuccess, verifyUserFailure]);

    return (
        <PageFrame isLoading={verifyingUser}>
            <div className="w-full h-screen bg-body-1">
                <Navbar />
                <div className="h-[calc(100vh-84px)] w-full flex items-center justify-center">
                    <div className="w-[27%] max-lg:w-[90%] bg-white p-10 flex flex-col items-center rounded-[8px] shadow-cardShadow-2">
                        {isVerified ? <Success /> : <Failure />}
                    </div>
                </div>
            </div>
        </PageFrame>
    );
};

export default AcctVerification;
