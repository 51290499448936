import Button from "components/common/Button";
import CustomToast from "components/common/CustomToast";
import TextInput from "components/common/InputField/TextInput";
import ToggleInput from "components/common/InputField/ToggleInput";
import ModalContainer from "components/common/ModalContainer";
import {getFromStorage} from "helpers";
import React, {useEffect, useState} from "react";
import {toast} from "react-hot-toast";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {authActions} from "redux/Ldb/actions";
import {useAppDispatch, useAppSelector} from "../../../../../../hooks/storeHooks";

const Preferences = () => {
    const [openChangePassword, setOpenChangePassword] = useState(false);
    const [deleteAccount, setDeleteAccount] = useState(false);
    const [copilotActive, setCopilotActive] = useState(false);
    const [deactivate, setDeactivate] = useState(false);
    const [activate, setActivate] = useState(false);
    const [notifications, setNotifications] = useState<{[key: string]: any}>({
        allNotification: localStorage.getItem("allNotification") === "true",
        rfqApproval: false,
        purchaseOrder: false,
        teamUpdate: false,
        mentions: false,
        shipmentUpdate: false,
        trackingUpdate: false,
        announcement: false,
    });
    const [userProfile, setUserProfile] = useState(() => getFromStorage("ally-user"));
    const [profile, setProfile] = useState([]);
    const [password, setPassword] = useState({
        currentPassword: "",
        newPassword: "",
        confirmNewPassword: "",
    });

    const {
        changingPassword,
        updateLdbProfileRequest,
        fetchUserProfileSuccess,
        changePasswordSuccess,
        deleteLdbProfile,
        deleteLdbProfileSuccess,
    } = useAppSelector((state) => state.auth);

    useEffect(() => {
        if (Boolean(fetchUserProfileSuccess)) {
            setProfile(fetchUserProfileSuccess);
            setNotifications({
                ...notifications,
                rfqApproval: fetchUserProfileSuccess?.notificationSettings?.rfq,
                purchaseOrder: fetchUserProfileSuccess?.notificationSettings?.purchaseOrder,
                teamUpdate: fetchUserProfileSuccess?.notificationSettings?.team,
                mentions: fetchUserProfileSuccess?.notificationSettings?.mentions,
                shipmentUpdate: fetchUserProfileSuccess?.notificationSettings?.shipmentUpdates,
                trackingUpdate: fetchUserProfileSuccess?.notificationSettings?.trackingUpdates,
                announcement: fetchUserProfileSuccess?.notificationSettings?.announcements,
            });
            setCopilotActive(fetchUserProfileSuccess?.profile?.coPilotActive);
        }
    }, [fetchUserProfileSuccess]);

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const handleChange = (evt) => {
        const {name, value} = evt.target;

        setPassword((prev) => {
            return {
                ...prev,
                [name]: value,
            };
        });
    };

    const handleSubmit = () => {
        if (password.newPassword !== password.confirmNewPassword) {
            toast.custom((t) => (
                <CustomToast t={t} message={"the new password does not match!"} type="error" />
            ));
        } else {
            const body = {
                currentPassword: password.currentPassword,
                newPassword: password.newPassword,
            };
            dispatch(authActions.changePassword(body, userProfile?.token));
        }
    };

    useEffect(() => {
        if (Boolean(changePasswordSuccess)) {
            setPassword({
                currentPassword: "",
                newPassword: "",
                confirmNewPassword: "",
            });
            dispatch(authActions.resetChangePasswordSuccess());
            setOpenChangePassword(false);
        }
    }, [changePasswordSuccess]);

    const handleToggleChange = (event) => {
        const {name, checked} = event.target;

        if (name === "allNotification") {
            const updatedNotifications = Object.keys(notifications).reduce(
                (acc, key) => ({
                    ...acc,
                    [key]: checked,
                }),
                {}
            );

            setNotifications(updatedNotifications);
        } else {
            setNotifications((prevNotifications) => ({
                ...prevNotifications,
                [name]: checked,
                allNotification: false,
            }));
        }
    };

    useEffect(() => {
        localStorage.setItem("allNotification", String(notifications.allNotification));

        const body = {
            notificationSettings: {
                inAppNotifications: true,
                shipmentUpdates: notifications.shipmentUpdate,
                trackingUpdates: notifications.trackingUpdate,
                announcements: notifications.announcement,
                team: notifications.teamUpdate,
                mentions: notifications.mentions,
                purchaseOrder: notifications.purchaseOrder,
                rfq: notifications.rfqApproval,
                promotions: true,
            },
        };

        dispatch(authActions.updateUserProfile(body, userProfile?.token));
    }, [notifications]);

    const handleCopilotToggleChange = () => {
        if (!copilotActive) {
            handleConfirmActivate();
        } else {
            setDeactivate(true);
        }
    };
    const handleConfirmDeactivate = () => {
        setCopilotActive(false);
        setDeactivate(false);
        const body = {
            coPilotActive: false,
        };

        dispatch(authActions.updateLdbProfile(body, userProfile?.token));
    };

    const handleConfirmActivate = () => {
        setCopilotActive(true);
        setActivate(false);
        const body = {
            coPilotActive: true,
        };

        dispatch(authActions.updateLdbProfile(body, userProfile?.token));
    };

    const onDeleteAccount = () => {
        dispatch(authActions.deleteLdbProfile(userProfile?.token));
    };

    useEffect(() => {
        if (Boolean(deleteLdbProfileSuccess)) {
            navigate("/login");
        }
    }, [deleteLdbProfileSuccess, navigate]);

    const deleteText = () => (
        <div className="mt-[30px] md:mt-0">
            <p className="text-sm font-rocGroteskBold mb-2">Delete your account</p>
            <p className="text-sm font-rocGroteskMedium text-slate-500 mb-4">
                By deleting your account on TradeAlly, kindly note that you will be losing all the
                information about your supply chain operations that have been uploaded on the
                platform. You would also be losing access to the Co-pilot.
            </p>
            <Button
                btnCustomContent={
                    <p className="flex items-center gap-[10px] py-2 px-4 text-r-55">
                        <i className="ri-delete-bin-5-fill"></i>
                        <span>Delete account</span>
                    </p>
                }
                onClick={() => setDeleteAccount(true)}
                btnClassname={"!w-fit !p-0 !bg-white rounded border border-slate-200"}
                isLoading={false}
            />
        </div>
    );
    return (
        <div className="mt-[34px] flex justify-between flex-col md:flex-row">
            <div className="w-full md:w-[45%]">
                <div className="rounded w-full md:border md:border-slate-100 bg-white md:p-5 flex flex-col gap-[26px] mb-[34px]">
                    <p className="text-base font-rocGroteskMedium">Account preferences</p>
                    {/* flex items-end md:items-center font-rocGroteskMedium justify-between border-b
                    border-b-slate-500 md:border-none */}
                    <div className="flex items-end md:items-center font-rocGroteskMedium justify-between border-b border-b-slate-100 md:border-none">
                        <div className="w-[60%]">
                            <p className="text-sm">Language preference</p>
                            <p className="text-sm text-slate-500 font-rocGroteskMedium md:font-rocGroteskRegular pt-[10px]">
                                English (US)
                            </p>
                        </div>
                        {/* <p className="text-sm font-rocGroteskMedium text-b-60">Edit</p> */}
                    </div>
                    {/* <div className="flex items-center font-rocGroteskMedium justify-between">
                        <div className="w-[60%]">
                            <p className="text-sm">Timezone</p>
                            <p className="text-sm text-slate-500">Central Daylight Time (GMT-5)</p>
                        </div>
                        <p className="text-sm font-rocGroteskMedium text-b-60">Edit</p>
                    </div> */}
                    {/* <div className="flex items-center font-rocGroteskMedium justify-between">
                        <div className="w-[60%]">
                            <p className="text-sm">Theme selection</p>
                            <p className="text-sm text-slate-500">Light mode</p>
                        </div>
                        <p className="text-sm font-rocGroteskMedium text-b-60">Edit</p>
                    </div> */}
                    <div className="flex items-end md:items-center font-rocGroteskMedium justify-between border-b border-b-slate-100 md:border-none pb-[10px] md:pb-0">
                        <div className="w-[60%]">
                            <p className="text-sm">Co-pilot capabilities</p>
                        </div>
                        <div className="flex items-center relative gap-3">
                            <span className="text-sm font-rocGroteskMedium">Activated</span>
                            <ToggleInput
                                checked={copilotActive}
                                name={"copilot"}
                                onChange={handleCopilotToggleChange}
                            />
                        </div>
                    </div>
                    <div className="flex items-center font-rocGroteskMedium justify-between">
                        <div className="w-[60%]">
                            <p className="text-sm">Password</p>
                            <p className="text-sm text-slate-500 font-rocGroteskMedium md:font-rocGroteskRegular pt-[10px]">
                                Activated
                            </p>
                        </div>
                        <div className="cursor-pointer" onClick={() => setOpenChangePassword(true)}>
                            <p className="text-sm font-rocGroteskMedium text-b-60">
                                Change password
                            </p>
                        </div>
                    </div>
                </div>
                <div className="hidden md:block">
                    {userProfile?.user?.role === "owner" && deleteText()}
                </div>
            </div>

            <div className="rounded w-full md:w-[51%] h-fit md:border md:border-slate-100 bg-white pt-[30px] md:p-5 flex flex-col gap-[10px]">
                <p className="text-base font-rocGroteskMedium mb-4">Notifications preferences</p>

                <div className="flex items-center font-rocGroteskMedium w-full py-3 justify-between border-b border-slate-100">
                    <div className="w-[60%]">
                        <p className="text-sm">All notifications</p>
                    </div>
                    <div className="flex items-center relative gap-3">
                        <ToggleInput
                            name={"allNotification"}
                            onChange={handleToggleChange}
                            checked={notifications.allNotification}
                        />
                    </div>
                </div>
                <div className="flex items-center font-rocGroteskMedium w-full py-3 justify-between border-b border-slate-100">
                    <div className="w-[60%]">
                        <p className="text-sm">RFQ approval</p>
                    </div>
                    <div className="flex items-center relative gap-3">
                        <ToggleInput
                            onChange={handleToggleChange}
                            name={"rfqApproval"}
                            checked={notifications.rfqApproval}
                        />
                    </div>
                </div>
                <div className="flex items-center font-rocGroteskMedium w-full py-3 justify-between border-b border-slate-100">
                    <div className="w-[60%]">
                        <p className="text-sm">Purchase order creation and approval</p>
                    </div>
                    <div className="flex items-center relative gap-3">
                        <ToggleInput
                            onChange={handleToggleChange}
                            name={"purchaseOrder"}
                            checked={notifications.purchaseOrder}
                        />
                    </div>
                </div>
                <div className="flex items-center font-rocGroteskMedium w-full py-3 justify-between border-b border-slate-100">
                    <div className="w-[60%]">
                        <p className="text-sm">Team updates</p>
                    </div>
                    <div className="flex items-center relative gap-3">
                        <ToggleInput
                            onChange={handleToggleChange}
                            name={"teamUpdate"}
                            checked={notifications.teamUpdate}
                        />
                    </div>
                </div>
                <div className="flex items-center font-rocGroteskMedium w-full py-3 justify-between border-b border-slate-100">
                    <div className="w-[60%]">
                        <p className="text-sm">Mentions</p>
                    </div>
                    <div className="flex items-center relative gap-3">
                        <ToggleInput
                            onChange={handleToggleChange}
                            name={"mentions"}
                            checked={notifications.mentions}
                        />
                    </div>
                </div>
                <div className="flex items-center font-rocGroteskMedium w-full py-3 justify-between border-b border-slate-100">
                    <div className="w-[60%]">
                        <p className="text-sm">Shipment updates</p>
                    </div>
                    <div className="flex items-center relative gap-3">
                        <ToggleInput
                            name={"shipmentUpdate"}
                            onChange={handleToggleChange}
                            checked={notifications.shipmentUpdate}
                        />
                    </div>
                </div>
                <div className="flex items-center font-rocGroteskMedium w-full py-3 justify-between border-b border-slate-100">
                    <div className="w-[60%]">
                        <p className="text-sm">Tracking updates</p>
                    </div>
                    <div className="flex items-center relative gap-3">
                        <ToggleInput
                            name={"trackingUpdate"}
                            onChange={handleToggleChange}
                            checked={notifications.trackingUpdate}
                        />
                    </div>
                </div>
                <div className="flex items-center font-rocGroteskMedium w-full py-3 justify-between border-b border-slate-100">
                    <div className="w-[60%]">
                        <p className="text-sm">Announcements</p>
                    </div>
                    <div className="flex items-center relative gap-3">
                        <ToggleInput
                            onChange={handleToggleChange}
                            name={"announcement"}
                            checked={notifications.announcement}
                        />
                    </div>
                </div>
            </div>

            <div className="md:hidden">{userProfile?.user?.role === "owner" && deleteText()}</div>

            <ModalContainer
                open={openChangePassword}
                showCloseIcon={false}
                modalClassName={"py-10 px-10"}
                tailwindClassName="w-[553px]"
                closeModal={() => {
                    setOpenChangePassword(false);
                }}
            >
                <div className="bg-white px-2  pt-3 pb-10 shadow-cardShadow relative rounded-[20px]">
                    <div className=" px-4 flex justify-end ">
                        <i
                            onClick={() => setOpenChangePassword(false)}
                            className="ri-close-fill cursor-pointer text-gm-45 text-[24px]"
                        ></i>
                    </div>
                    <div className="flex px-4 md:px-8 flex-col items-center ">
                        <div className="text-center w-full mb-[46px]">
                            <p className="mb-2.5 text-2xl font-rocGroteskMedium text-g-75">
                                Change password
                            </p>
                        </div>
                        <form
                            className="w-full"
                            onSubmit={(e) => {
                                e.preventDefault();
                                handleSubmit();
                            }}
                        >
                            <div className="mb-10">
                                <div className="flex flex-col gap-6 w-full">
                                    <TextInput
                                        name={"currentPassword"}
                                        type={"password"}
                                        value={password.currentPassword}
                                        onChange={handleChange}
                                        placeholder={"Current password"}
                                        required={true}
                                        minLength={8}
                                        pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\W).+$"
                                        title="Password must contain at least one lowercase letter, one uppercase letter, and one special character."
                                        containerClassname=""
                                        inputClassName={
                                            "!placeholder:text-slate-400 !placeholder:font-rocGroteskMedium"
                                        }
                                    />
                                    <TextInput
                                        name={"newPassword"}
                                        type={"password"}
                                        value={password.newPassword}
                                        onChange={handleChange}
                                        placeholder={"New password"}
                                        required={true}
                                        minLength={8}
                                        pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\W).+$"
                                        title="Password must contain at least one lowercase letter, one uppercase letter, and one special character."
                                        containerClassname=""
                                        inputClassName={
                                            "!placeholder:text-slate-400 !placeholder:font-rocGroteskMedium"
                                        }
                                    />
                                    <TextInput
                                        name={"confirmNewPassword"}
                                        type={"password"}
                                        value={password.confirmNewPassword}
                                        onChange={handleChange}
                                        placeholder={"Confirm new password"}
                                        required={true}
                                        minLength={8}
                                        pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\W).+$"
                                        title="Password must contain at least one lowercase letter, one uppercase letter, and one special character."
                                        containerClassname=""
                                        inputClassName={
                                            "!placeholder:text-slate-400 !placeholder:font-rocGroteskMedium"
                                        }
                                    />
                                </div>
                            </div>
                            <div className="w-full ">
                                <Button
                                    btnText={"Save changes"}
                                    type={"submit"}
                                    isLoading={changingPassword}
                                />
                                <Button
                                    btnText={"Close"}
                                    disabled={changingPassword}
                                    onClick={() => setOpenChangePassword(false)}
                                    btnClassname={"!bg-transparent !text-gm-75 mt-2"}
                                />
                            </div>
                        </form>
                    </div>
                </div>
            </ModalContainer>
            <ModalContainer
                open={deleteAccount}
                showCloseIcon={false}
                modalClassName={"py-10 px-10"}
                closeModal={() => setDeleteAccount(false)}
                tailwindClassName="h-[371px] w-[572px] "
            >
                <div className="bg-white flex flex-col rounded-[20px]  ">
                    <div className="mt-5 px-6 flex justify-end ">
                        <i
                            onClick={() => setDeleteAccount(false)}
                            className="ri-close-fill text-gm-45 text-[30px] cursor-pointer "
                        ></i>
                    </div>
                    <div className="px-8 sm:px-14 my-2 ">
                        <div>
                            <h6 className="text-center font-rocGroteskMedium text-[16px]">
                                Are you sure you want to delete the{" "}
                                <span className="font-rocGroteskBold">account ? </span>
                            </h6>
                        </div>
                        <div className="mt-5 mb-10 space-y-4">
                            <Button
                                btnText={"No, close"}
                                type={"button"}
                                btnClassname="!py-3 !bg-n-20  !text-dark-gray-2 !px-4"
                                onClick={() => setDeleteAccount(false)}
                                disabled={deleteLdbProfile}
                            />
                            <Button
                                btnText={"Yes, delete account"}
                                type={"button"}
                                btnClassname="!py-3 !bg-[#FFEFEC] border border-r-50 !text-dark-gray-2 !px-4"
                                onClick={onDeleteAccount}
                                isLoading={deleteLdbProfile}
                            />
                        </div>
                    </div>
                </div>
            </ModalContainer>
            <ModalContainer
                open={deactivate}
                showCloseIcon={false}
                modalClassName={"py-10 px-10"}
                closeModal={() => setDeactivate(false)}
                tailwindClassName="h-[371px] w-[572px] "
            >
                <div className="bg-white flex flex-col rounded-[20px]  ">
                    <div className=" mt-5 px-6 flex justify-end ">
                        <i
                            className="ri-close-fill cursor-pointer text-gm-45 text-[30px]"
                            onClick={() => setDeactivate(false)}
                        ></i>
                    </div>
                    <div className="sm:px-14 px-8 my-7 ">
                        <div className="mb-8">
                            <h6 className="text-center font-rocGroteskMedium text-[16px]">
                                Are you sure you want to de-activate the Co-pilot? The Co-pilot will
                                not be able to help you simplify your Supply-Chain operations until
                                you re-activate it
                            </h6>
                        </div>
                        <div className="mt-5 mb-4 space-y-4">
                            <Button
                                btnText={"No, close"}
                                type={"button"}
                                btnClassname="!py-3 !bg-n-20  !text-dark-gray-2 !px-4"
                                onClick={() => setDeactivate(false)}
                                disabled={updateLdbProfileRequest}
                            />
                            <Button
                                btnText={"Yes, deactivate"}
                                type={"button"}
                                btnClassname="!py-3 !bg-[#FFEFEC] border border-r-50 !text-dark-gray-2 !px-4"
                                onClick={handleConfirmDeactivate}
                                isLoading={updateLdbProfileRequest}
                            />
                        </div>
                    </div>
                </div>
            </ModalContainer>
        </div>
    );
};

export default Preferences;
