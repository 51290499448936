import React from "react";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

type CustomTableProps = {
    tableHeader: {
        title: React.ReactNode | string;
        widthClass?: string;
        itemClass?: string;
        isAllChecked?: boolean;
        onSelectAll?: () => void;
    }[];
    tbodyClass?: string;
    stickyHeader?: boolean;
};

const CustomTableLoader = ({ tableHeader, tbodyClass, stickyHeader }: CustomTableProps) => {
    return (
        <div className={`overflow-x-hidden w-full h-full contents relative`}>
            <table
                className={`table-fixed relative bg-white w-full  rounded-[6px] border border-slate-100
                }`}
            >
                <thead className={`${stickyHeader && "sticky top-0 left-0 right-0"}`}>
                    <tr className={`text-left bg-white `}>
                        {tableHeader?.map((header, idx) => (
                            <th
                                key={(header?.title as string) + idx}
                                className={`${header?.widthClass} border-r
                                 border-slate-100 bg-slate-50 sticky left-0 bg-inherit z-[1000] p-3 text-xs text-[#334155] font-rocGroteskMedium
                                     right-0 `}
                            >
                                {header.title}
                            </th>
                        ))}
                    </tr>
                </thead>

                <tbody className={`${tbodyClass} `}>
                    {[1, 2, 3, 4, 5, 6, 7, 8]?.map((item, index) => {
                        return (
                            <tr
                                key={index}
                                className={`text-left  border-t  border-slate-100 cursor-pointer ellipsis `}
                                onClick={(e) => item?.[0]?.cellClickAction?.(e)}
                            >
                                {tableHeader?.map((col, idx) => (
                                    <td key={idx} className="border-r h-[64px] border-slate-100 py-4 px-4">
                                    <Skeleton height={24} width={col?.widthClass} highlightColor="#E2E8F0" borderRadius={16} baseColor="#F1F5F9"    className="custom-skeleton" />
                                    </td>
                                ))}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
};

export default CustomTableLoader;
