import Dropdown from "components/common/Dropdown";
import React, { Dispatch, SetStateAction } from "react";
import TaskColumnContainer from "components/common/TaskColumnContainer";
import TaskCard from "components/common/TaskCard";
import { camelCaseToRegularCase } from "helpers/camelCaseToRegularCase";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { onScroll } from "helpers";
import Loader from "components/common/Loader";
import { useAppSelector } from "hooks/storeHooks";

type GridViewProps = {
    activeTasks: { [key: string]: any }[];
    pendingTasks: { [key: string]: any }[];
    completedTasks: { [key: string]: any }[];
    pagination?: { currentPage: string; noOfPages: string };
    setUpdatedPage?: Dispatch<SetStateAction<number>>;
    setLoadMore?: Dispatch<SetStateAction<boolean>>;
};

const GridView = ({
    activeTasks,
    pendingTasks,
    completedTasks,
    pagination,
    setUpdatedPage,
    setLoadMore,
}: GridViewProps) => {
    const navigate = useNavigate();
    const currentDay = moment();
    const { fetchingTaskList } = useAppSelector((state) => state.workforce);

    return (
        <div className="grid grid-cols-3 space-x-1.5">
            <TaskColumnContainer
                title="Active tasks"
                icon={<i className="ri-flashlight-line text-[#56B648] text-base"></i>}
                total={activeTasks?.length}
                borderColor="#56B648"
                pagination={pagination}
                setUpdatedPage={setUpdatedPage}
                setLoadMore={setLoadMore}
            >
                <>
                    {activeTasks?.map((task, idx) => {
                        const runningDurationinHrs = moment(task?.endDate || currentDay)?.diff(
                            task?.createdAt,
                            "hours"
                        );
                        const runningDurationinMins = moment(task?.endDate || currentDay)?.diff(
                            task?.createdAt,
                            "minutes"
                        );
                        return (
                            <TaskCard
                                key={task?._id}
                                title={`${task?.agent?.teamMateName} - ${task?.taId}`}
                                description={camelCaseToRegularCase(task?.taskType)}
                                time={
                                    runningDurationinHrs
                                        ? `${runningDurationinHrs} hour(s)`
                                        : `${runningDurationinMins} min(s)`
                                }
                                userAttentionRequired={task?.status
                                    ?.toLowerCase()
                                    ?.startsWith("attention")}
                                avatarUrl={task?.agent?.avatar}
                                onClick={() => navigate(`/dashboard/task/${task?._id}`)}
                            />
                        );
                    })}
                    {fetchingTaskList && (
                        <div className="flex justify-center my-4">
                            <Loader color="gm-25" size={4} />
                        </div>
                    )}
                    {pagination?.currentPage === pagination?.noOfPages && (
                        <div className="flex my-4 justify-center text-slate-500 text-sm font-rocGroteskMedium">
                            End of list
                        </div>
                    )}
                </>
            </TaskColumnContainer>

            <TaskColumnContainer
                title="Pending tasks"
                icon={<i className="ri-hourglass-line text-[#FF8A0099] text-base"></i>}
                total={pendingTasks?.length}
                borderColor="#FF8A00"
                pagination={pagination}
                setUpdatedPage={setUpdatedPage}
                setLoadMore={setLoadMore}
            >
                <>
                    {pendingTasks?.map((task, idx) => {
                        const runningDurationinHrs = moment(task?.endDate || currentDay)?.diff(
                            task?.createdAt,
                            "hours"
                        );
                        const runningDurationinMins = moment(task?.endDate || currentDay)?.diff(
                            task?.createdAt,
                            "minutes"
                        );
                        return (
                            <TaskCard
                                key={task?._id}
                                title={`${task?.agent?.teamMateName} - ${task?.taId}`}
                                description={camelCaseToRegularCase(task?.taskType)}
                                time={
                                    runningDurationinHrs
                                        ? `${runningDurationinHrs} hour(s)`
                                        : `${runningDurationinMins} min(s)`
                                }
                                userAttentionRequired={task?.status
                                    ?.toLowerCase()
                                    ?.startsWith("attention")}
                                avatarUrl={task?.agent?.avatar}
                                onClick={() => navigate(`/dashboard/task/${task?._id}`)}
                            />
                        );
                    })}
                    {fetchingTaskList && (
                        <div className="flex justify-center my-4">
                            <Loader color="gm-25" size={4} />
                        </div>
                    )}
                    {pagination?.currentPage === pagination?.noOfPages && (
                        <div className="flex my-4 justify-center text-slate-500 text-sm font-rocGroteskMedium">
                            End of list
                        </div>
                    )}
                </>
            </TaskColumnContainer>

            <TaskColumnContainer
                title="Completed tasks"
                icon={<i className="ri-checkbox-multiple-line text-[#117FFF] text-base"></i>}
                total={completedTasks?.length}
                borderColor="#117FFF"
                pagination={pagination}
                setUpdatedPage={setUpdatedPage}
                setLoadMore={setLoadMore}
            >
                <>
                    {completedTasks?.map((task, idx) => {
                        const runningDurationinHrs = moment(task?.endDate || currentDay)?.diff(
                            task?.createdAt,
                            "hours"
                        );
                        const runningDurationinMins = moment(task?.endDate || currentDay)?.diff(
                            task?.createdAt,
                            "minutes"
                        );
                        return (
                            <TaskCard
                                key={task?._id}
                                title={`${task?.agent?.teamMateName} - ${task?.taId}`}
                                description={camelCaseToRegularCase(task?.taskType)}
                                time={
                                    runningDurationinHrs
                                        ? `${runningDurationinHrs} hour(s)`
                                        : `${runningDurationinMins} min(s)`
                                }
                                userAttentionRequired={task?.status
                                    ?.toLowerCase()
                                    ?.startsWith("attention")}
                                avatarUrl={task?.agent?.avatar}
                                onClick={() => navigate(`/dashboard/task/${task?._id}`)}
                            />
                        );
                    })}
                    {fetchingTaskList && (
                        <div className="flex justify-center my-4">
                            <Loader color="gm-25" size={4} />
                        </div>
                    )}
                    {pagination?.currentPage === pagination?.noOfPages && (
                        <div className="flex my-4 justify-center text-slate-500 text-sm font-rocGroteskMedium">
                            End of list
                        </div>
                    )}
                </>
            </TaskColumnContainer>
        </div>
    );
};

export default GridView;
