const customFieldsOption = [
    {
        type: "Single text field",
        subText: "Allow people to enter a one-line text. no longer than 255 characters.",
        icon: "ri-file-2-line",
        value: "text",
    },
    {
        type: "Multi-line field",
        subText: "A multiline text field that can store longer lengths of text.",
        icon: "ri-list-check",
        value: "multiline",
    },
    {
        type: "Checkboxes",
        subText: "Checkboxes fields allow people to select multiple options from a list.",
        icon: "ri-checkbox-line",
        value: "checkbox",
    },
    {
        type: "Number",
        subText: "Number fields allow people to provide numerical information as text.",
        icon: "ri-hashtag",
        value: "number",
    },
    {
        type: "Currency",
        subText: "Currency allows people to enter their currency type and amount.",
        icon: "ri-money-dollar-circle-line",
        value: "currency",
    },
    {
        type: "Phone number",
        subText: "Phone number allows people to enter their phone numbers.",
        icon: "ri-phone-fill",
        value: "phone",
    },
    {
        type: "Dropdown",
        subText: "Use this for a long list of answer options, like selecting your type.",
        icon: "ri-expand-up-down-line",
        value: "dropDown",
    },
    {
        type: "Radio Select",
        subText: "Radio buttons allow people to select a single option from a list",
        icon: "ri-radio-button-line",
        value: "radio",
    },
    // {
    //     type: "User Picker",
    //     subText: "User picker allows people to select one user from the dropdown",
    //     icon: "ri-user-add-line",
    // },
    {
        type: "Date Picker",
        subText: "Date picker fields allow people to provide date info using a calendar",
        icon: "ri-calendar-line",
        value: "date",
    },
    {
        type: "File upload",
        subText: "File upload allows you to add documents from your device.",
        icon: "ri-file-2-line",
        value: "file",
    },
];

export default customFieldsOption;
