import { useEffect, useMemo, useState } from "react";
import { inventoryActions } from "redux/Ldb/actions";
import { useAppDispatch, useAppSelector } from "../storeHooks";
import { debounce } from "lodash";
import useInfiniteScroll from "../useInfinteScroll";
import { useParams } from "react-router-dom";

const useGetInventoryIncidentComments = () => {
    const [incidentId, setIncidentId] = useState<string>("");
    const [refetch, setRefetch] = useState<boolean>(false);
    const dispatch = useAppDispatch();
    const { alertId } = useParams();

    const {
        fetchingInventoryIncidentComment,
        fetchedInventoryIncidentCommentSuccess,
        fetchedInventoryIncidentCommentFailure,
    } = useAppSelector((state) => state.inventory);

    const [incidentComments, seIncidentComments] = useState<{ [key: string]: any }[]>([]);
    const [pagination, setPagination] = useState<{ currentPage: string; noOfPages: string }>({
        currentPage: "1",
        noOfPages: "",
    });

    const reFetchComments = () => {
        setRefetch(!refetch);
    };
    const handleRefectId = (inventoryIncidentId: string) => {
        setIncidentId(inventoryIncidentId);
    };

    const { updatedLimit, isLoadingMore, setLoadMore, updatedPage } = useInfiniteScroll(
        { current: pagination.currentPage, number_of_pages: pagination.noOfPages },
        10
    );

    useEffect(() => {
        if (incidentId) {
            dispatch(inventoryActions.fetchInventoryIncidentComments(incidentId, 10, updatedPage));

            return () => {
                dispatch(inventoryActions.resetFetchInventoryIncidentComments());
            };
        }
    }, [dispatch, incidentId, refetch, updatedPage]);

    useEffect(() => {
        if (alertId) {
            dispatch(inventoryActions.fetchInventoryIncidentComments(alertId, 10, updatedPage));

            return () => {
                dispatch(inventoryActions.resetFetchInventoryIncidentComments());
            };
        }
    }, [dispatch, alertId, refetch, updatedPage]);

    useEffect(() => {
        if (Boolean(fetchedInventoryIncidentCommentSuccess)) {
            seIncidentComments((prev) =>
                updatedPage === 1
                    ? [...fetchedInventoryIncidentCommentSuccess?.comments]
                    : [...prev, ...fetchedInventoryIncidentCommentSuccess?.comments]
            );
            setPagination({
                currentPage: fetchedInventoryIncidentCommentSuccess.pagination?.current,
                noOfPages: fetchedInventoryIncidentCommentSuccess.pagination?.number_of_pages,
            });
            setLoadMore(false);
        }
    }, [fetchedInventoryIncidentCommentSuccess, updatedPage]);

    return {
        isFetching: fetchingInventoryIncidentComment,
        error: fetchedInventoryIncidentCommentFailure,
        data: incidentComments,
        seIncidentComments,
        pagination,
        setPagination,
        reFetchComments,
        handleRefectId,
        isLoadingMore,
    };
};

export default useGetInventoryIncidentComments;
