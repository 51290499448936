import config from "config/config";
import { authService } from "./authService";
import { createUrlSearchParams } from "helpers";

export const customFieldService = {
    createCustomField,
    getCustomFields,
    updateCustomField,
    deleteCustomField,
};

async function createCustomField(data: Record<string, any> | Array<Record<string, any>>) {
    const requestOptions = {
        method: "POST",
        body: JSON.stringify(data),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/customField/create`,
        requestOptions
    );
    return res;
}

async function getCustomFields(fieldType: string) {
    const requestOptions = {
        method: "GET",
    };
    const urlParams = createUrlSearchParams({
        fieldType,
    });
    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/customField/list?${
            Boolean(urlParams?.toString()) ? "&" + urlParams?.toString() : ""
        }`,
        requestOptions
    );
    return res;
}

async function updateCustomField(
    id: string,
    data: Record<string, any> | Array<Record<string, any>>
) {
    const requestOptions = {
        method: "PUT",
        body: JSON.stringify(data),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/customField/${id}`,
        requestOptions
    );
    return res;
}

async function deleteCustomField(id: string) {
    const requestOptions = {
        method: "DELETE",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/customField/${id}`,
        requestOptions
    );
    return res;
}
