import ToggleInput from "components/common/InputField/ToggleInput";
import Loader from "components/common/Loader";
import ModalContainer from "components/common/ModalContainer";
import {getFromStorage, saveToStorage} from "helpers";
import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {useSelector} from "react-redux";
import {useSearchParams} from "react-router-dom";
import {vendorActions} from "redux/Ldb/actions";

const Integrations = () => {
    const [checkedGmailConnect, setCheckedGmailConnect] = useState(false);
    const [checkedMicrosoftConnect, setCheckedMicrosoftConnect] = useState(false);
    const {
        authenticateGmailSettingsSuccess,
        connectingGoogleWorkspaceSettings,
        connectGoogleWorkspaceSettingsSuccess,
        authenticateMicrosoftSettingsSuccess,
        connectingMicrosoftWorkspaceSettings,
        connectMicrosoftWorkspaceSettingsSuccess,
        fetchedAllChannelsSuccess,
        manageConnectionSuccess,
    } = useSelector((state) => state.vendor);
    const dispatch = useDispatch();
    const [param] = useSearchParams();
    const code = param.get("code");

    useEffect(() => {
        dispatch(vendorActions.getAllChannels());
    }, [dispatch]);

    const googleConnected = fetchedAllChannelsSuccess?.channel?.find(
        (item) => item?.channel?.toLowerCase() === "gmail"
    );
    const microsoftConnected = fetchedAllChannelsSuccess?.channel?.find(
        (item) => item?.channel?.toLowerCase() === "microsoft"
    );

    useEffect(() => {
        const source = getFromStorage("connectionSource");
        if (code && source && source === "gmail") {
            dispatch(vendorActions.connectGoogleWorkspaceSettings({code}));
        }
        if (code && source && source === "microsoft") {
            dispatch(vendorActions.connectMicrosoftWorkspaceSettings({code}));
        }
    }, [code, dispatch]);

    useEffect(() => {
        if (Boolean(connectGoogleWorkspaceSettingsSuccess)) {
            dispatch(vendorActions.resetConnectGoogleWorkspaceSettingsSuccess());
        }
    }, [connectGoogleWorkspaceSettingsSuccess]);

    useEffect(() => {
        if (Boolean(connectMicrosoftWorkspaceSettingsSuccess)) {
            dispatch(vendorActions.resetConnectMicrosoftWorkspaceSettingsSuccess());
        }
    }, [connectMicrosoftWorkspaceSettingsSuccess]);

    useEffect(() => {
        setCheckedGmailConnect(googleConnected?.active);
    }, [googleConnected]);

    useEffect(() => {
        setCheckedMicrosoftConnect(microsoftConnected?.active);
    }, [microsoftConnected]);
    
    const handleGmailActivate = () => {
        if (!googleConnected) {
            setCheckedGmailConnect(true);
            dispatch(vendorActions.authenticateGmailSettings());
        } else if (googleConnected && !googleConnected?.active) {
            setCheckedGmailConnect(true);
            const body = {
                activate: true,
                socialId: googleConnected?.socialId,
            };
            dispatch(vendorActions.manageConnection(body));
        }
    };

    const handleMicrosoftActivate = () => {
        if (!microsoftConnected) {
            setCheckedMicrosoftConnect(true);
            dispatch(vendorActions.authenticateMicrosoftAcctSettings());
        } else if (microsoftConnected && !microsoftConnected?.active) {
            setCheckedMicrosoftConnect(true);
            const body = {
                activate: true,
                socialId: microsoftConnected?.socialId,
            };
            dispatch(vendorActions.manageConnection(body));
        }
    };

    const handleGmailDeactivate = () => {
        if (checkedGmailConnect) {
            setCheckedGmailConnect(false);
            const body = {
                activate: false,
                socialId: googleConnected?.socialId,
            };
            dispatch(vendorActions.manageConnection(body));
        }
    };

    const handleMicrosoftDeactivate = () => {
        if (checkedMicrosoftConnect) {
            setCheckedMicrosoftConnect(false);
            const body = {
                activate: false,
                socialId: microsoftConnected?.socialId,
            };
            dispatch(vendorActions.manageConnection(body));
        }
    };
    const handleGmailToggleChange = () => {
        if (!checkedGmailConnect) {
            handleGmailActivate();
        } else {
            handleGmailDeactivate();
        }
    };
    const handleMicrososftToggleChange = () => {
        if (!checkedMicrosoftConnect) {
            handleMicrosoftActivate();
        } else {
            handleMicrosoftDeactivate();
        }
    };
    useEffect(() => {
        if (Boolean(authenticateGmailSettingsSuccess)) {
            saveToStorage("connectionSource", "gmail", true);
            window.open(authenticateGmailSettingsSuccess?.url, "_target");
            dispatch(vendorActions.resetAuthenticateGmailSettingsSuccess());
        }
    }, [dispatch, authenticateGmailSettingsSuccess]);

    useEffect(() => {
        if (Boolean(authenticateMicrosoftSettingsSuccess)) {
            saveToStorage("connectionSource", "microsoft", true);
            window.open(authenticateMicrosoftSettingsSuccess?.url, "_target");
            dispatch(vendorActions.resetAuthenticateMicrosoftSettingsSuccess());
        }
    }, [dispatch, authenticateMicrosoftSettingsSuccess]);

    useEffect(() => {
        if (Boolean(manageConnectionSuccess)) {
            dispatch(vendorActions.resetManageConnectionSuccess());
            dispatch(vendorActions.getAllChannels());
        }
    }, [dispatch, manageConnectionSuccess]);

    return (
        <div className="mt-6 sm:flex gap-[34px]">
            <div className="rounded-lg md:w-[28%] bg-white  border border-slate-100">
                <div className="w-full h-[115px] bg-cover bg-no-repeat bg-[url('https://res.cloudinary.com/dvxi7qcmd/image/upload/v1693472077/Tradeally_2.0/googleBanner_rhtk9j.svg')]" />

                <div className="px-3.5">
                    <div className="py-[18px] border-b border-slate-200">
                        <p className="text-sm font-rocGroteskMedium mb-3">
                            Connect your Google Suite email address
                        </p>
                        <p className="text-xs text-slate-500 font-rocGroteskRegular">
                            Connecting your Google email address allows TradeAlly to scan your email
                            for relevant information that can help in streamlining supply chain
                            operations by extracting relevant data seamlessly.
                        </p>
                    </div>
                    <div className="pt-4 pb-5 flex justify-end ">
                        <div className="flex items-center gap-3">
                            <ToggleInput
                                checked={checkedGmailConnect || false}
                                onChange={handleGmailToggleChange}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="rounded-lg md:w-[28%] bg-white  border border-slate-100">
                <div className="w-full h-[115px] bg-cover bg-no-repeat bg-[url('https://res.cloudinary.com/dvxi7qcmd/image/upload/v1693472077/Tradeally_2.0/microsoftBanner_lnxg0h.svg')]" />
                <div className="px-3.5">
                    <div className="py-[18px] border-b border-slate-200">
                        <p className="text-sm font-rocGroteskMedium mb-3">
                            Connect your Microsoft Suite email address
                        </p>
                        <p className="text-xs text-slate-500 font-rocGroteskRegular">
                            Connecting your Microsoft account to TradeAlly allows the platform's AI
                            to gain access to your emails, allowing it to scan and extract essential
                            information for your supply chain operations.
                        </p>
                    </div>
                    <div className="pt-4 pb-5 flex justify-end ">
                        <div className="flex items-center gap-3">
                            <ToggleInput
                                checked={checkedMicrosoftConnect || false}
                                onChange={handleMicrososftToggleChange}
                            />
                        </div>
                    </div>
                </div>
            </div>

           {/* <div className="rounded-lg md:w-[28%] bg-white  border border-slate-100">
                <div className="w-full h-[115px] bg-cover bg-no-repeat bg-[url('https://res.cloudinary.com/dvxi7qcmd/image/upload/v1693472077/Tradeally_2.0/whatsappBanner_fsplzy.svg')]" />

                <div className="px-3.5">
                    <div className="py-[18px] border-b border-slate-200">
                        <p className="text-sm font-rocGroteskMedium mb-3">
                            Connect your WhatsApp phone number
                        </p>
                        <p className="text-xs text-slate-500 font-rocGroteskRegular">
                            Connecting your WhatsApp number allows TradeAlly to scan your
                            conversations for relevant information that can help in streamlining
                            supply chain operations by extracting relevant data seamlessly.
                        </p>
                    </div>
                    <div className="pt-4 pb-5 flex justify-end ">
                        <div className="flex items-center gap-3">
                            <span className="text-sm text-slate-500 font-rocGroteskMedium">
                                Not connected
                            </span>
                            <ToggleInput />
                        </div>
                    </div>
                </div>
            </div>  */}

            <ModalContainer
                open={connectingGoogleWorkspaceSettings || connectingMicrosoftWorkspaceSettings}
                showCloseIcon={false}
                tailwindClassName="w-[36%] max-lg:w-1/2 max-sm:w-[95%]"
            >
                <div className="w-full py-8 bg-white flex flex-col items-center rounded-md">
                    <Loader size={6} color={"gm-50"} />
                    <p className="text-sm text-gm-50 font-rocGroteskMedium mt-2">
                        Connecting account...
                    </p>
                </div>
            </ModalContainer>
        </div>
    );
};

export default Integrations;
