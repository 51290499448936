import { useEffect, useState } from "react";
import { inventoryActions } from "redux/Ldb/actions";
import { useAppDispatch, useAppSelector } from "../storeHooks";

const useRecentInventoryIncident = () => {
    const dispatch = useAppDispatch();
    const {
        recentInventoryIncident,
        recentInventoryIncidentFailure,
        recentInventoryIncidentSuccess,
    } = useAppSelector((state) => state.inventory);

    const [incidentId, setIncidentId] = useState<string>("");
    const [recentIncidentData, setRecentIncidentData] = useState<any>([]);
  
    const handleIncidentId = (value: string) => {
        setIncidentId(value);
    };

    useEffect(() => {
        if(incidentId){
            dispatch(inventoryActions.recentInventoryIncident(incidentId));
        }
    }, [dispatch, incidentId]);

    useEffect(() => {
        if (Boolean(recentInventoryIncidentSuccess)) {
            setRecentIncidentData(recentInventoryIncidentSuccess?.incident);
            dispatch(inventoryActions.resetGetRecentIncident());
        }
    }, [recentInventoryIncidentSuccess]);

    return {
        isFetching: recentInventoryIncident,
        error: recentInventoryIncidentFailure,
        data: recentIncidentData,
        handleIncidentId
    };
};

export default useRecentInventoryIncident;
