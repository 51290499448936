export const COLORS = [
    "#5470c6",
    "#91cc75",
    "#fac858",
    "#ee6666",
    "#73c0de",
    "#3ba272",
    "#fc8452",
    "#9a60b4",
    "#ea7ccc",
    // =======
    "#9e6f3f",
    "#7eb00a",
    "#6f5553",
    "#c3ccda",
    "#b5ca92",
    "#f3d999",
    "#d3758f",
    "#d8d5d8",
];
