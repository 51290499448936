import moment from "moment";

export const getWeeksInMonth = (year, month) => {
    const startOfMonth = moment([year, month]);
    const endOfMonth = moment(startOfMonth).endOf("month");
    const firstWeek = startOfMonth.week();
    const lastWeek = endOfMonth.week();

    // Handle the edge case for December where the last week might be the first week of the next year
    if (lastWeek < firstWeek) {
        return moment([year, 11, 31]).week() - firstWeek + 1;
    }

    return lastWeek - firstWeek + 1;
};

export const getWeekDateRange = (year, month, weekOfMonth) => {
    const startOfMonth = moment(`${year}-${month}-01`, "YYYY-M-DD");
    const startOfWeek = startOfMonth.startOf("week").add((weekOfMonth - 1) * 7, "days");
    const endOfWeek = startOfWeek.clone().endOf("week");
    return {
        start: startOfWeek.format("MMM DD"),
        end: endOfWeek.format("MMM DD"),
    };
};
