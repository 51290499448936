import Loader from "components/common/Loader";
import { Tooltip } from "components/common/ToolTip";
import { formatMoney } from "helpers";
import { convertSeconds } from "helpers/convertSeconds";
import React, { ReactElement } from "react";

type CardProps = {
    className?: string;
    imgClassName?: string;
    title?: string;
    value?: number;
    iconOne?: any;
    iconTwo?: any;
    time?: any;
    increase?: boolean;
    increaseData?: boolean;
    percentageValue?: number;
    filterDuration?: string;
    loader: boolean;
    titleClassName?: string;
    showImg?: boolean;
    customDropdown?: ReactElement;
    innerClassName?: string;
    figure?: string;
    addComma?: boolean;
    description?: string;
    figureClassName?: string;
    descriptionClassName?: string;
    tooltipDesign?: ReactElement;
    tooltipClassName?: string;
    toolTipContent?: string | React.ReactNode;
    lastChecker?: boolean;
    hideDirection?: boolean;
    increaseClassName?: string;
    currency?: string;
    sign?: string;
    increaseContainerClass?: string;
    addText?: string;
    arrUpClass?: string;
    addTextTwo?: string;
    addTextTwoClass?: string;
    hasCustomValue?: boolean;
    customSubText?: string;
    customSubTextClass?: string;
    customFigure?: string | number;
    textAndSubtextContainer?: string;
};

export const AnalyticsCard = ({
    className,
    title,
    value,
    iconOne,
    iconTwo,
    time,
    increase,
    increaseData,
    percentageValue,
    filterDuration,
    loader,
    titleClassName,
    showImg,
    customDropdown,
    innerClassName,
    figure,
    addComma,
    imgClassName,
    description,
    figureClassName,
    descriptionClassName,
    tooltipDesign,
    lastChecker,
    toolTipContent,
    tooltipClassName,
    hideDirection,
    increaseClassName,
    currency,
    sign,
    increaseContainerClass,
    addTextTwo,
    arrUpClass,
    addText,
    addTextTwoClass,
    hasCustomValue,
    customSubTextClass,
    customSubText,
    customFigure,
    textAndSubtextContainer,
}: CardProps) => {
    const { hours, minutes, secondsRemaining } = convertSeconds(time);

    return (
        <div
            className={`bg-white  border-slate-200 rounded-lg shadow-boxShadow-2 md:p-[15px] w-full ${className}`}
        >
            <div className={`flex justify-between items-center ${innerClassName}`}>
                <div className="flex items-center space-x-[5px]">
                    {iconOne && (
                        <div
                            className={` p-1.5 rounded-full text-[#FCC34E] h-[29px] w-[29px] flex justify-center items-center bg-light-yellow-2 ${iconOne}`}
                        >
                            <i className="ri-time-fill  text-base"></i>
                        </div>
                    )}
                    <p className={`text-slate-600 font-rocGroteskMedium text-sm ${titleClassName}`}>
                        {title}
                    </p>

                    {iconTwo && (
                        <div>
                            <span className="relative group  bg-gm-50">
                                {toolTipContent && (
                                    <div
                                        className={` font-rocGroteskRegular pointer-events-none absolute -top-[25px] text-[13px] ${
                                            lastChecker
                                                ? "lg:left-[-120px] left-1/2  lg:before:left-[95%] before:left-1/2 w-[280px]"
                                                : "left-1/2  before:left-1/2 w-[256px]"
                                        } transform -translate-x-1/2 text-white opacity-0 transition before:absolute  before:bottom-full before:translate-x-1/2 before:border-4   before:border-transparent before:border-b-gm-50 rounded before:content-[''] group-hover:opacity-100 bg-gm-50 ${tooltipClassName}`}
                                    >
                                        {toolTipContent}
                                    </div>
                                )}
                                <div className=" cursor-pointer">
                                    <i className="ri-information-fill text-slate-300 text-base"></i>
                                </div>
                            </span>
                        </div>
                    )}
                </div>
                {customDropdown && <div className="max-sm:hidden">{customDropdown}</div>}
            </div>
            {loader ? (
                <Loader />
            ) : (
                <div className="">
                    <div
                        className={`flex items-center justify-between mb-1 ${textAndSubtextContainer}`}
                    >
                        {hasCustomValue && (
                            <div
                                className={`text-[36px] text-right leading-[51.8px] mr-6 pr-6 border-r border-slate-200 font-rocGroteskMedium text-p-70 mt-7 max-lg:mt-0  ${figureClassName}`}
                            >
                                {currency}
                                {customFigure}
                                {customSubText && (
                                    <p
                                        className={`text-[10px] font-rocGroteskMedium text-gm-35 ${customSubTextClass}`}
                                    >
                                        {customSubText}
                                    </p>
                                )}
                            </div>
                        )}
                        <div
                            className={`text-[36px] text-right  leading-[51.8px] font-rocGroteskMedium text-p-70 mt-7 max-lg:mt-0  ${figureClassName}`}
                        >
                            {value || value >= 0
                                ? addComma
                                    ? value.toLocaleString()
                                    : formatMoney().format(value)
                                : figure
                                ? `${currency ? currency : ""}${
                                      figure !== undefined || figure !== null ? figure : "---"
                                  }${sign ? sign : ""}`
                                : time === 0
                                ? 0
                                : "----"}
                            {addTextTwo && (
                                <p
                                    className={`text-[10px] font-rocGroteskMedium text-gm-35 ${addTextTwoClass}`}
                                >
                                    {addTextTwo}
                                </p>
                            )}
                        </div>

                        {showImg && increase ? (
                            <img
                                src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1712457603/Group_55890_k2xwoh.svg"
                                alt=""
                                className={`${imgClassName} max-sm:w-[47px] max-sm:h-[25px]`}
                            />
                        ) : (
                            showImg &&
                            !increase && (
                                <img
                                    src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1712575609/Vector_326_zgnljc.svg"
                                    alt=""
                                    className={`${imgClassName} max-sm:w-[47px] max-sm:h-[25px]`}
                                />
                            )
                        )}
                    </div>
                    <div className="flex items-center gap-1">
                        {increase && (
                            <div className={`flex items-center ${increaseContainerClass}`}>
                                {increase ? (
                                    <i className={`ri-arrow-up-line text-g-50 ${arrUpClass}`}></i>
                                ) : (
                                    <i className="ri-arrow-down-line text-r-50"></i>
                                )}
                                <p
                                    className={`text-gm-35 font-rocGroteskMedium text-xs leading-3 `}
                                >
                                    <span className={`${increaseClassName}`}>
                                        {percentageValue}% {` `}
                                    </span>
                                    <span className="font-rocGroteskMedium">
                                        {hideDirection ? "" : increase ? "up" : "down"}{" "}
                                    </span>
                                    <span className=""> {filterDuration}</span>
                                </p>
                            </div>
                        )}
                        {addText && (
                            <p className="text-[10px] font-rocGroteskMedium text-gm-35">
                                {addText}
                            </p>
                        )}
                    </div>

                    {description && (
                        <p
                            className={`text-slate-600 font-rocGroteskMedium text-xs ${descriptionClassName}`}
                        >
                            {description}
                        </p>
                    )}
                </div>
            )}
            {customDropdown && (
                <div className="max-sm:block hidden max-sm:mt-2">{customDropdown}</div>
            )}
        </div>
    );
};
