import React from "react";

type PageTitleSectionProps = {
    rightComp?: React.ReactNode;
    leftComp?: React.ReactNode;
    child?: React.ReactNode;
};

const PageTitleSection = ({ rightComp, leftComp, child }: PageTitleSectionProps) => {
    return (
        <div className={`bg-slate-50 rounded-[10px] ${child&&"text-[#94A3B8]"} border border-slate-100 px-8 py-4 flex items-center justify-between`}>
          <div className="flex items-center space-x-1">
          {leftComp}
          {child && 
            child
          }
          </div>

            {leftComp && rightComp}
        </div>
    );
};

export default PageTitleSection;
