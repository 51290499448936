import {teamTypes} from "redux/Ldb/types";

const initialState = {
    fetchingTeamMembers: false,
    fetchedTeamMembersSuccess: null,
    fetchedTeamMembersFailure: null,
};

export const team = (state = initialState, action: {type: string, payload: any}) => {
    switch (action.type) {
        case teamTypes.FETCH_TEAM_MEMBERS_REQUEST:
            return {
                ...state,
                fetchingTeamMembers: true,
                fetchedTeamMembersSuccess: null,
                fetchedTeamMembersFailure: null,
            };
        case teamTypes.FETCH_TEAM_MEMBERS_SUCCESS:
            return {
                ...state,
                fetchingTeamMembers: false,
                fetchedTeamMembersSuccess: action.payload,
                fetchedTeamMembersFailure: null,
            };
        case teamTypes.FETCH_TEAM_MEMBERS_FAILURE:
            return {
                ...state,
                fetchingTeamMembers: false,
                fetchedTeamMembersSuccess: null,
                fetchedTeamMembersFailure: action.payload,
            };

        default:
            return state;
    }
};
