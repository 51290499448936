import React from "react";
import CustomToast from "components/common/CustomToast";
import { productionTypes } from "redux//Ldb/types";
import { toast } from "react-hot-toast";
import { Dispatch } from "redux";
import { productionService } from "services/Ldb";

export const productionActions = {
    getBOMList,
    getSingleBOM,
    resetBOMList,
    createBOM,
    updateBOM,
    resetUpdateBOM,
    fetchProductWithoutBOM,
    getNonFinishedInventory,
    resetCreateBOM,
    getFinishedInventory,
    createProductionPlan,
    updateProductionPlan,
    fetchSingleProductionPlan,
    fetchProductionPlanTab,
    fetchProductionPlanList,
    updateProductionPlanSteps,
    createProductionPlanSteps,
    fetchProductionAnalytics,
    resetCreateProductionPlan,
    resetUpdateProductionPlan,
    resetFetchProductionPlanTab,
    resetUpdateProductionPlanSteps,
    resetCreateProductionPlanSteps,
};

function getBOMList(limit?: number, page?: number, search?: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(productionTypes.FETCH_BOM_LIST_REQUEST));

        productionService.getBOMList(limit, page, search).then(
            (res) => {
                dispatch(success(productionTypes.FETCH_BOM_LIST_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(productionTypes.FETCH_BOM_LIST_FAILURE, error?.message));
                }
            }
        );
    };
}
function getSingleBOM(inventoryId: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(productionTypes.FETCH_SINGLE_BOM_REQUEST));

        productionService.getSingleBOM(inventoryId).then(
            (res) => {
                dispatch(success(productionTypes.FETCH_SINGLE_BOM_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(productionTypes.FETCH_SINGLE_BOM_FAILURE, error?.message));
                }
            }
        );
    };
}

function createBOM(data: Record<string, any> | Array<Record<string, any>>, inventoryId: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(productionTypes.CREATE_BOM_REQUEST));

        productionService.createBOM(data, inventoryId).then(
            (res) => {
                dispatch(success(productionTypes.CREATE_BOM_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(productionTypes.CREATE_BOM_FAILURE, error?.message));
                }
            }
        );
    };
}
function updateBOM(data: Record<string, any> | Array<Record<string, any>>, inventoryId: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(productionTypes.UPDATE_BOM_REQUEST));

        productionService.editBOM(data, inventoryId).then(
            (res) => {
                dispatch(success(productionTypes.UPDATE_BOM_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(productionTypes.UPDATE_BOM_FAILURE, error?.message));
                }
            }
        );
    };
}
function fetchProductWithoutBOM() {
    return (dispatch: Dispatch) => {
        dispatch(request(productionTypes.FETCH_PRODUCT_WITHOUT_BOM_REQUEST));

        productionService.getProductWithoutBOM().then(
            (res) => {
                dispatch(success(productionTypes.FETCH_PRODUCT_WITHOUT_BOM_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(productionTypes.FETCH_PRODUCT_WITHOUT_BOM_FAILURE, error?.message)
                    );
                }
            }
        );
    };
}

function getNonFinishedInventory(limit?: number, page?: number, search?: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(productionTypes.FETCH_NON_FINISHED_INVENTORY_REQUEST));

        productionService.getNonFinishedInventory(limit, page, search).then(
            (res) => {
                dispatch(success(productionTypes.FETCH_NON_FINISHED_INVENTORY_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(
                            productionTypes.FETCH_NON_FINISHED_INVENTORY_FAILURE,
                            error?.message
                        )
                    );
                }
            }
        );
    };
}

function createProductionPlan(data: Record<string, any> | Array<Record<string, any>>) {
    return (dispatch: Dispatch) => {
        dispatch(request(productionTypes.CREATE_PRODUCTION_PLAN_REQUEST));

        productionService.createProductionPlan(data).then(
            (res) => {
                dispatch(success(productionTypes.CREATE_PRODUCTION_PLAN_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(productionTypes.CREATE_PRODUCTION_PLAN_FAILURE, error?.message)
                    );
                }
            }
        );
    };
}

function updateProductionPlan(
    data: Record<string, any> | Array<Record<string, any>>,
    planId: string
) {
    return (dispatch: Dispatch) => {
        dispatch(request(productionTypes.UPDATE_PRODUCTION_PLAN_REQUEST));

        productionService.updateProductionPlan(data, planId).then(
            (res) => {
                dispatch(success(productionTypes.UPDATE_PRODUCTION_PLAN_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(productionTypes.UPDATE_PRODUCTION_PLAN_FAILURE, error?.message)
                    );
                }
            }
        );
    };
}

function getFinishedInventory(limit?: number, page?: number, search?: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(productionTypes.FETCH_FINISHED_INVENTORY_REQUEST));

        productionService.getFinishedInventory(limit, page, search).then(
            (res) => {
                dispatch(success(productionTypes.FETCH_FINISHED_INVENTORY_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(productionTypes.FETCH_FINISHED_INVENTORY_FAILURE, error?.message)
                    );
                }
            }
        );
    };
}

function fetchProductionPlanList(
    limit?: number,
    page?: number,
    search?: string,
    status?: string,
    assigneeId?: string,
    startDate?: string,
    endDate?: string
) {
    return (dispatch: Dispatch) => {
        dispatch(request(productionTypes.FETCH_PRODUCTION_PLAN_LIST_REQUEST));

        productionService
            .getProductionPlanList(limit, page, search, status, assigneeId, startDate, endDate)
            .then(
                (res) => {
                    dispatch(
                        success(productionTypes.FETCH_PRODUCTION_PLAN_LIST_SUCCESS, res?.data)
                    );
                },
                (error) => {
                    if (error.message) {
                        toast.custom((t) => (
                            <CustomToast t={t} message={error?.message} type="error" />
                        ));
                        dispatch(
                            failure(
                                productionTypes.FETCH_PRODUCTION_PLAN_LIST_FAILURE,
                                error?.message
                            )
                        );
                    }
                }
            );
    };
}

function fetchSingleProductionPlan(planId: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(productionTypes.FETCH_SINGLE_PRODUCTION_PLAN_REQUEST));

        productionService.getSingleProductionPlan(planId).then(
            (res) => {
                dispatch(success(productionTypes.FETCH_SINGLE_PRODUCTION_PLAN_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(
                            productionTypes.FETCH_SINGLE_PRODUCTION_PLAN_FAILURE,
                            error?.message
                        )
                    );
                }
            }
        );
    };
}

function fetchProductionPlanTab(
    planId: string,
    query?: string,
    limit?: number,
    page?: number,
    search?: string
) {
    return (dispatch: Dispatch) => {
        dispatch(request(productionTypes.FETCH_PRODUCTION_PLAN_TAB_REQUEST));

        productionService.getProductionPlanTab(planId, query, limit, page, search).then(
            (res) => {
                dispatch(success(productionTypes.FETCH_PRODUCTION_PLAN_TAB_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(productionTypes.FETCH_PRODUCTION_PLAN_TAB_FAILURE, error?.message)
                    );
                }
            }
        );
    };
}

function updateProductionPlanSteps(
    data: Record<string, any> | Array<Record<string, any>>,
    stepId: string
) {
    return (dispatch: Dispatch) => {
        dispatch(request(productionTypes.UPDATE_PLAN_STEPS_REQUEST));

        productionService.updatePlanSteps(data, stepId).then(
            (res) => {
                dispatch(success(productionTypes.UPDATE_PLAN_STEPS_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(productionTypes.UPDATE_PLAN_STEPS_FAILURE, error?.message));
                }
            }
        );
    };
}

function fetchProductionAnalytics(startDate: string, endDate: string) {
    return (dispatch: Dispatch) => {
        dispatch(request(productionTypes.FETCH_PRODUCTION_ANALYTICS_REQUEST));

        productionService.fetchProductionAnalytics(startDate, endDate).then(
            (res) => {
                dispatch(success(productionTypes.FETCH_PRODUCTION_ANALYTICS_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(productionTypes.FETCH_PRODUCTION_ANALYTICS_FAILURE, error?.message)
                    );
                }
            }
        );
    };
}

function createProductionPlanSteps(
    data: Record<string, any> | Array<Record<string, any>>,
    planId: string
) {
    return (dispatch: Dispatch) => {
        dispatch(request(productionTypes.CREATE_PRODUCTION_STEPS_REQUEST));

        productionService.createPlanSteps(data, planId).then(
            (res) => {
                dispatch(success(productionTypes.CREATE_PRODUCTION_STEPS_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(productionTypes.CREATE_PRODUCTION_STEPS_FAILURE, error?.message)
                    );
                }
            }
        );
    };
}

function resetBOMList() {
    return (dispatch: Dispatch) => {
        dispatch(success(productionTypes.FETCH_BOM_LIST_SUCCESS, null));
    };
}

function resetCreateBOM() {
    return (dispatch: Dispatch) => {
        dispatch(success(productionTypes.CREATE_BOM_SUCCESS, null));
    };
}

function resetUpdateBOM() {
    return (dispatch: Dispatch) => {
        dispatch(success(productionTypes.UPDATE_BOM_SUCCESS, null));
    };
}

function resetCreateProductionPlan() {
    return (dispatch: Dispatch) => {
        dispatch(success(productionTypes.CREATE_PRODUCTION_PLAN_SUCCESS, null));
    };
}
function resetUpdateProductionPlan() {
    return (dispatch: Dispatch) => {
        dispatch(success(productionTypes.UPDATE_PRODUCTION_PLAN_SUCCESS, null));
    };
}

function resetFetchProductionPlanTab() {
    return (dispatch: Dispatch) => {
        dispatch(success(productionTypes.FETCH_PRODUCTION_PLAN_TAB_SUCCESS, null));
    };
}

function resetUpdateProductionPlanSteps() {
    return (dispatch: Dispatch) => {
        dispatch(success(productionTypes.UPDATE_PLAN_STEPS_SUCCESS, null));
    };
}

function resetCreateProductionPlanSteps() {
    return (dispatch: Dispatch) => {
        dispatch(success(productionTypes.CREATE_PRODUCTION_PLAN_SUCCESS, null));
    };
}

function request(type: string) {
    return { type: type };
}
function success(type: string, data?: any) {
    return { type: type, payload: data };
}
function failure(type: string, error?: any) {
    return { type: type, payload: error ?? "" };
}
