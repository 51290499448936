export const chatTypes = {
    CREATE_CONVERSATION_REQUEST: "CREATE_CONVERSATION_REQUEST",
    CREATE_CONVERSATION_SUCCESS: "CREATE_CONVERSATION_SUCCESS",
    CREATE_CONVERSATION_FAILURE: "CREATE_CONVERSATION_FAILURE",
    FETCH_CONVERSATION_REQUEST: "FETCH_CONVERSATION_REQUEST",
    FETCH_CONVERSATION_SUCCESS: "FETCH_CONVERSATION_SUCCESS",
    FETCH_CONVERSATION_FAILURE: "FETCH_CONVERSATION_FAILURE",
    FETCH_ALL_CONVERSATIONS_REQUEST: "FETCH_ALL_CONVERSATIONS_REQUEST",
    FETCH_ALL_CONVERSATIONS_SUCCESS: "FETCH_ALL_CONVERSATIONS_SUCCESS",
    FETCH_ALL_CONVERSATIONS_FAILURE: "FETCH_ALL_CONVERSATIONS_FAILURE",
    SEND_AI_CHAT_REQUEST: "SEND_AI_CHAT_REQUEST",
    SEND_AI_CHAT_SUCCESS: "SEND_AI_CHAT_SUCCESS",
    SEND_AI_CHAT_FAILURE: "SEND_AI_CHAT_FAILURE",
    UPDATE_CHAT_DATA_REQUEST: "UPDATE_CHAT_DATA_REQUEST",
    UPDATE_CHAT_DATA_SUCCESS: "UPDATE_CHAT_DATA_SUCCESS",
    UPDATE_CHAT_DATA_FAILURE: "UPDATE_CHAT_DATA_FAILURE",
};
