import config from "config/config";
import { authService } from "./authService";
import { createUrlSearchParams } from "helpers";

export const dashboardService = {
    fetchDashboardGMW,
    fetchSalesChannel,
    fetchDashboardAnalytics,
    fetchBusinessStats,
    fetchTopPerforming,
    fetchLeastPerforming,
    fetchTopOnHandProduct,
    fetchProductsProfitMargin,
    fetchTopOnHandProductList,
    fetchProductsProfitList,
    fetchSalesGMW,
    fetchDashboardConfigList,
    addForecastConfig,
};

async function fetchDashboardGMW(
    category?: string,
    product?: string,
    channel?: string,
    startDate?: string,
    endDate?: string,
    dateRange?: string
) {
    const requestOptions = {
        method: "GET",
    };

    const urlParams = createUrlSearchParams({
        category: category as string,
        channel: channel as string,
        startDate: startDate as string,
        endDate: endDate as string,
        // dateRange: dateRange as string,
    });

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/inventory/dashboard/growth-rate?${
            dateRange ? `dateRange=${dateRange}` : ""
        }${product ? `&inventoryId=${product}` : ""}${
            Boolean(urlParams?.toString()) ? "&" + urlParams?.toString() : ""
        }`,
        requestOptions
    );
    return res;
}
async function fetchSalesGMW(
    category?: string,
    inventoryId?: string,
    warehouse?: string,
    channel?: string,
    startDate?: string,
    endDate?: string,
    dateRange?: string
) {
    const requestOptions = {
        method: "GET",
    };

    const urlParams = createUrlSearchParams({
        category: category as string,
        // inventoryId: product as string,
        warehouseId: warehouse as string,
        channel: channel as string,
        startDate: startDate as string,
        endDate: endDate as string,
        // dateRange: dateRange as string,
    });

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/inventory/single/growth-rate?${
            inventoryId ? `inventoryId=${inventoryId}` : ""
        }${dateRange ? `&dateRange=${dateRange}` : ""}${
            Boolean(urlParams?.toString()) ? "&" + urlParams?.toString() : ""
        }`,
        requestOptions
    );
    return res;
}

async function fetchSalesChannel() {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/inventory/sales-channel`,
        requestOptions
    );
    return res;
}

async function fetchDashboardAnalytics(period?: string) {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/analytics/dashboard/sales?dateRange=${period}`,
        requestOptions
    );
    return res;
}

async function fetchBusinessStats() {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/analytics/business-stats`,
        requestOptions
    );
    return res;
}

async function fetchTopPerforming(
    limit?: number,
    page?: number,
    inventoryId?: string,
    warehouseId?: string,
    search?: string,
    channel?: string,
    dateRange?: string,
    sortParams?: { [key: string]: number }
) {
    const requestOptions = {
        method: "GET",
    };

    const urlParams = createUrlSearchParams({
        page,
        inventoryId: inventoryId as string,
        warehouseId: warehouseId as string,
        search: search as string,
        channel: channel as string,
        dateRange: dateRange as string,
    });

    const key = typeof sortParams === "object" && sortParams !== null && Object.keys(sortParams)[0];
    const value = key ? sortParams[key] : null;
    const sortParamsFormat = key && value !== undefined ? `${key}=${value}` : "";

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/inventory/list/top-performing-products?limit=${limit}${
            Boolean(urlParams?.toString()) ? "&" + urlParams?.toString() : ""
        }${key ? "&" + sortParamsFormat?.toString() : ""}`,
        requestOptions
    );
    return res;
}

async function fetchLeastPerforming(
    limit?: number,
    page?: number,
    inventoryId?: string,
    warehouseId?: string,
    search?: string,
    channel?: string,
    dateRange?: string,
    sortParams?: { [key: string]: number }
) {
    const requestOptions = {
        method: "GET",
    };

    const urlParams = createUrlSearchParams({
        page,
        inventoryId: inventoryId as string,
        warehouseId: warehouseId as string,
        search: search as string,
        channel: channel as string,
        dateRange: dateRange as string,
    });

    const key = typeof sortParams === "object" && sortParams !== null && Object.keys(sortParams)[0];
    const value = key ? sortParams[key] : null;
    const sortParamsFormat = key && value !== undefined ? `${key}=${value}` : "";

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/inventory/list/worse-performing-products?limit=${limit}${
            Boolean(urlParams?.toString()) ? "&" + urlParams?.toString() : ""
        }${key ? "&" + sortParamsFormat?.toString() : ""}`,
        requestOptions
    );
    return res;
}

async function fetchTopOnHandProduct() {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/inventory/get/top-onhand-products`,
        requestOptions
    );
    return res;
}

async function fetchProductsProfitMargin() {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/inventory/all/products-profit-margin-contribution`,
        requestOptions
    );
    return res;
}

async function fetchProductsProfitList(
    limit?: number,
    inventoryId?: string,
    channel?: string,
    dateRange?: string,
    search?: string,
    sortParams?: { [key: string]: number }
) {
    const requestOptions = {
        method: "GET",
    };

    const urlParams = createUrlSearchParams({
        inventoryId: inventoryId as string,
        search: search as string,
        channel: channel as string,
        dateRange: dateRange as string,
    });

    const key = typeof sortParams === "object" && sortParams !== null && Object.keys(sortParams)[0];
    const value = key ? sortParams[key] : null;
    const sortParamsFormat = key && value !== undefined ? `${key}=${value}` : "";

    const res = await authService.apiGate(
        `${
            config.API_URL
        }/api/v1/ldb/inventory/list/products-profit-margin-contribution-table?limit=${limit}${
            Boolean(urlParams?.toString()) ? "&" + urlParams?.toString() : ""
        }${key ? "&" + sortParamsFormat?.toString() : ""}`,
        requestOptions
    );
    return res;
}

async function fetchTopOnHandProductList(
    limit?: number,
    inventoryId?: string,
    channel?: string,
    dateRange?: string,
    search?: string,
    sortParams?: { [key: string]: number }
) {
    const requestOptions = {
        method: "GET",
    };

    const urlParams = createUrlSearchParams({
        inventoryId: inventoryId as string,
        search: search as string,
        channel: channel as string,
        dateRange: dateRange as string,
    });

    const key = typeof sortParams === "object" && sortParams !== null && Object.keys(sortParams)[0];
    const value = key ? sortParams[key] : null;
    const sortParamsFormat = key && value !== undefined ? `${key}=${value}` : "";

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/inventory/list/inventory-onhand-details?limit=${limit}${
            Boolean(urlParams?.toString()) ? "&" + urlParams?.toString() : ""
        }${key ? "&" + sortParamsFormat?.toString() : ""}`,
        requestOptions
    );
    return res;
}

async function fetchDashboardConfigList(
    page?: number,
    dateRange?: string,
    productId?: string,
    channel?: string,
    category?: string
) {
    const requestOptions = {
        method: "GET",
    };

    const urlParams = createUrlSearchParams({
        page: page as number,
        dateRange: dateRange as string,
        channel,
        category,
    });

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/inventory/dashboard/forecast-configs?${
            Boolean(urlParams?.toString()) ? "&" + urlParams?.toString() : ""
        }${productId ? `&inventoryId=${productId}` : ""}`,
        requestOptions
    );

    return res;
}

async function addForecastConfig(data: Record<string, any> | Array<Record<string, any>>) {
    const requestOptions = {
        method: "POST",
        body: JSON.stringify(data),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/inventory/add/forecast-config`,
        requestOptions
    );

    return res;
}
