import { productionTypes } from "../types/productionTypes";

const initialState = {
    fetchingBOMList: false,
    fetchedBOMListSuccess: null,
    fetchedBOMListFailure: null,
    fetchingSingleBOM: false,
    fetchedSingleBOMSuccess: null,
    fetchedSingleBOMFailure: null,
    creatingBOM: false,
    createBOMSuccess: null,
    createBOMFailure: null,
    updatingBOM: false,
    updateBOMSuccess: null,
    updateBOMFailure: null,
    fetchingProductWithoutBOM: false,
    fetchedProductWithoutBOMSuccess: null,
    fetchedProductWithoutBOMFailure: null,
    fetchingNonFinishedInventory: false,
    fetchedNonFinishedInventorySuccess: null,
    fetchedNonFinishedInventoryFailure: null,
    creatingProductionPlan: false,
    createProductionPlanSuccess: null,
    createProductionPlanFailure: null,
    updatingProductionPlan: false,
    updateProductionPlanSuccess: null,
    updateProductionPlanFailure: null,
    fetchingFinishedInventory: false,
    fetchedFinishedInventorySuccess: null,
    fetchedFinishedInventoryFailure: null,
    fetchingProductionPlanList: false,
    fetchedProductionPlanListSuccess: null,
    fetchedProductionPlanListFailure: null,
    fetchingSingleProductionPlan: false,
    fetchedSingleProductionPlanSuccess: null,
    fetchedSingleProductionPlanFailure: null,
    fetchingProductionPlanTab: false,
    fetchedProductionPlanTabSuccess: null,
    fetchedProductionPlanTabFailure: null,
    updatingPlanSteps: false,
    updatePlanStepsSuccess: null,
    updatePlanStepsFailure: null,
    creatingPlanSteps: false,
    createPlanStepsSuccess: null,
    createPlanStepsFailure: null,
    fetchingProductionAnalytics: false,
    fetchedProductionAnalyticsSuccess: null,
    fetchedProductionAnalyticsFailure: null,
};

export const production = (state = initialState, action: { type: string; payload: any }) => {
    switch (action.type) {
        case productionTypes.FETCH_BOM_LIST_REQUEST:
            return {
                ...state,
                fetchingBOMList: true,
                fetchedBOMListSuccess: null,
                fetchedBOMListFailure: null,
            };
        case productionTypes.FETCH_BOM_LIST_SUCCESS:
            return {
                ...state,
                fetchingBOMList: false,
                fetchedBOMListSuccess: action.payload,
                fetchedBOMListFailure: null,
            };
        case productionTypes.FETCH_BOM_LIST_FAILURE:
            return {
                ...state,
                fetchingBOMList: false,
                fetchedBOMListSuccess: null,
                fetchedBOMListFailure: action.payload,
            };
        case productionTypes.FETCH_SINGLE_BOM_REQUEST:
            return {
                ...state,
                fetchingSingleBOM: true,
                fetchedSingleBOMSuccess: null,
                fetchedSingleBOMFailure: null,
            };
        case productionTypes.FETCH_SINGLE_BOM_SUCCESS:
            return {
                ...state,
                fetchingSingleBOM: false,
                fetchedSingleBOMSuccess: action.payload,
                fetchedSingleBOMFailure: null,
            };
        case productionTypes.FETCH_SINGLE_BOM_FAILURE:
            return {
                ...state,
                fetchingSingleBOM: false,
                fetchedSingleBOMSuccess: null,
                fetchedSingleBOMFailure: action.payload,
            };
        case productionTypes.CREATE_BOM_REQUEST:
            return {
                ...state,
                creatingBOM: true,
                createBOMSuccess: null,
                createBOMFailure: null,
            };
        case productionTypes.CREATE_BOM_SUCCESS:
            return {
                ...state,
                creatingBOM: false,
                createBOMSuccess: action.payload,
                createBOMFailure: null,
            };
        case productionTypes.CREATE_BOM_FAILURE:
            return {
                ...state,
                creatingBOM: false,
                createBOMSuccess: null,
                createBOMFailure: action.payload,
            };
        case productionTypes.UPDATE_BOM_REQUEST:
            return {
                ...state,
                updatingBOM: true,
                updateBOMSuccess: null,
                updateBOMFailure: null,
            };
        case productionTypes.UPDATE_BOM_SUCCESS:
            return {
                ...state,
                updatingBOM: false,
                updateBOMSuccess: action.payload,
                updateBOMFailure: null,
            };
        case productionTypes.UPDATE_BOM_FAILURE:
            return {
                ...state,
                updatingBOM: false,
                updateBOMSuccess: null,
                updateBOMFailure: action.payload,
            };
        case productionTypes.FETCH_PRODUCT_WITHOUT_BOM_REQUEST:
            return {
                ...state,
                fetchingProductWithoutBOM: true,
                fetchedProductWithoutBOMSuccess: null,
                fetchedProductWithoutBOMFailure: null,
            };
        case productionTypes.FETCH_PRODUCT_WITHOUT_BOM_SUCCESS:
            return {
                ...state,
                fetchingProductWithoutBOM: false,
                fetchedProductWithoutBOMSuccess: action.payload,
                fetchedProductWithoutBOMFailure: null,
            };
        case productionTypes.FETCH_PRODUCT_WITHOUT_BOM_FAILURE:
            return {
                ...state,
                fetchingProductWithoutBOM: false,
                fetchedProductWithoutBOMSuccess: null,
                fetchedProductWithoutBOMFailure: action.payload,
            };
        case productionTypes.FETCH_NON_FINISHED_INVENTORY_REQUEST:
            return {
                ...state,
                fetchingNonFinishedInventory: true,
                fetchedNonFinishedInventorySuccess: null,
                fetchedNonFinishedInventoryFailure: null,
            };
        case productionTypes.FETCH_NON_FINISHED_INVENTORY_SUCCESS:
            return {
                ...state,
                fetchingNonFinishedInventory: false,
                fetchedNonFinishedInventorySuccess: action.payload,
                fetchedNonFinishedInventoryFailure: null,
            };
        case productionTypes.FETCH_NON_FINISHED_INVENTORY_FAILURE:
            return {
                ...state,
                fetchingNonFinishedInventory: false,
                fetchedNonFinishedInventorySuccess: null,
                fetchedNonFinishedInventoryFailure: action.payload,
            };
        case productionTypes.CREATE_PRODUCTION_PLAN_REQUEST:
            return {
                ...state,
                creatingProductionPlan: true,
                createProductionPlanSuccess: null,
                createProductionPlanFailure: null,
            };
        case productionTypes.CREATE_PRODUCTION_PLAN_SUCCESS:
            return {
                ...state,
                creatingProductionPlan: false,
                createProductionPlanSuccess: action.payload,
                createProductionPlanFailure: null,
            };
        case productionTypes.CREATE_PRODUCTION_PLAN_FAILURE:
            return {
                ...state,
                creatingProductionPlan: false,
                createProductionPlanSuccess: null,
                createProductionPlanFailure: action.payload,
            };
        case productionTypes.UPDATE_PRODUCTION_PLAN_REQUEST:
            return {
                ...state,
                updatingProductionPlan: true,
                updateProductionPlanSuccess: null,
                updateProductionPlanFailure: null,
            };
        case productionTypes.UPDATE_PRODUCTION_PLAN_SUCCESS:
            return {
                ...state,
                updatingProductionPlan: false,
                updateProductionPlanSuccess: action.payload,
                updateProductionPlanFailure: null,
            };
        case productionTypes.UPDATE_PRODUCTION_PLAN_FAILURE:
            return {
                ...state,
                updatingProductionPlan: false,
                updateProductionPlanSuccess: null,
                updateProductionPlanFailure: action.payload,
            };
        case productionTypes.FETCH_FINISHED_INVENTORY_REQUEST:
            return {
                ...state,
                fetchingFinishedInventory: true,
                fetchedFinishedInventorySuccess: null,
                fetchedFinishedInventoryFailure: null,
            };
        case productionTypes.FETCH_FINISHED_INVENTORY_SUCCESS:
            return {
                ...state,
                fetchingFinishedInventory: false,
                fetchedFinishedInventorySuccess: action.payload,
                fetchedFinishedInventoryFailure: null,
            };
        case productionTypes.FETCH_FINISHED_INVENTORY_FAILURE:
            return {
                ...state,
                fetchingFinishedInventory: false,
                fetchedFinishedInventorySuccess: null,
                fetchedFinishedInventoryFailure: action.payload,
            };
        case productionTypes.FETCH_PRODUCTION_PLAN_LIST_REQUEST:
            return {
                ...state,
                fetchingProductionPlanList: true,
                fetchedProductionPlanListSuccess: null,
                fetchedProductionPlanListFailure: null,
            };
        case productionTypes.FETCH_PRODUCTION_PLAN_LIST_SUCCESS:
            return {
                ...state,
                fetchingProductionPlanList: false,
                fetchedProductionPlanListSuccess: action.payload,
                fetchedProductionPlanListFailure: null,
            };
        case productionTypes.FETCH_PRODUCTION_PLAN_LIST_FAILURE:
            return {
                ...state,
                fetchingProductionPlanList: false,
                fetchedProductionPlanListSuccess: null,
                fetchedProductionPlanListFailure: action.payload,
            };
        case productionTypes.FETCH_SINGLE_PRODUCTION_PLAN_REQUEST:
            return {
                ...state,
                fetchingSingleProductionPlan: true,
                fetchedSingleProductionPlanSuccess: null,
                fetchedSingleProductionPlanFailure: null,
            };
        case productionTypes.FETCH_SINGLE_PRODUCTION_PLAN_SUCCESS:
            return {
                ...state,
                fetchingSingleProductionPlan: false,
                fetchedSingleProductionPlanSuccess: action.payload,
                fetchedSingleProductionPlanFailure: null,
            };
        case productionTypes.FETCH_SINGLE_PRODUCTION_PLAN_FAILURE:
            return {
                ...state,
                fetchingSingleProductionPlan: false,
                fetchedSingleProductionPlanSuccess: null,
                fetchedSingleProductionPlanFailure: action.payload,
            };
        case productionTypes.FETCH_PRODUCTION_PLAN_TAB_REQUEST:
            return {
                ...state,
                fetchingProductionPlanTab: true,
                fetchedProductionPlanTabSuccess: null,
                fetchedProductionPlanTabFailure: null,
            };
        case productionTypes.FETCH_PRODUCTION_PLAN_TAB_SUCCESS:
            return {
                ...state,
                fetchingProductionPlanTab: false,
                fetchedProductionPlanTabSuccess: action.payload,
                fetchedProductionPlanTabFailure: null,
            };
        case productionTypes.FETCH_PRODUCTION_PLAN_TAB_FAILURE:
            return {
                ...state,
                fetchingProductionPlanTab: false,
                fetchedProductionPlanTabSuccess: null,
                fetchedProductionPlanTabFailure: action.payload,
            };
        case productionTypes.UPDATE_PLAN_STEPS_REQUEST:
            return {
                ...state,
                updatingPlanSteps: true,
                updatePlanStepsSuccess: null,
                updatePlanStepsFailure: null,
            };
        case productionTypes.UPDATE_PLAN_STEPS_SUCCESS:
            return {
                ...state,
                updatingPlanSteps: false,
                updatePlanStepsSuccess: action.payload,
                updatePlanStepsFailure: null,
            };
        case productionTypes.UPDATE_PLAN_STEPS_FAILURE:
            return {
                ...state,
                updatingPlanSteps: false,
                updatePlanStepsSuccess: null,
                updatePlanStepsFailure: action.payload,
            };
        case productionTypes.FETCH_PRODUCTION_ANALYTICS_REQUEST:
            return {
                ...state,
                fetchingProductionAnalytics: true,
                fetchedProductionAnalyticsSuccess: null,
                fetchedProductionAnalyticsFailure: null,
            };
        case productionTypes.FETCH_PRODUCTION_ANALYTICS_SUCCESS:
            return {
                ...state,
                fetchingProductionAnalytics: false,
                fetchedProductionAnalyticsSuccess: action.payload,
                fetchedProductionAnalyticsFailure: null,
            };
        case productionTypes.FETCH_PRODUCTION_ANALYTICS_FAILURE:
            return {
                ...state,
                fetchingProductionAnalytics: false,
                fetchedProductionAnalyticsSuccess: null,
                fetchedProductionAnalyticsFailure: action.payload,
            };
        case productionTypes.CREATE_PRODUCTION_STEPS_REQUEST:
            return {
                ...state,
                creatingPlanSteps: true,
                createPlanStepsSuccess: null,
                createPlanStepsFailure: null,
            };
        case productionTypes.CREATE_PRODUCTION_STEPS_SUCCESS:
            return {
                ...state,
                creatingPlanSteps: false,
                createPlanStepsSuccess: action.payload,
                createPlanStepsFailure: null,
            };
        case productionTypes.CREATE_PRODUCTION_STEPS_FAILURE:
            return {
                ...state,
                creatingPlanSteps: false,
                createPlanStepsSuccess: null,
                createPlanStepsFailure: action.payload,
            };
        default:
            return state;
    }
};
