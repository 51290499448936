import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import { dashboardActions } from "redux/Ldb/actions";
import { debounce } from "lodash";
import useInfiniteScroll from "hooks/useInfinteScroll";

const useGetDashboardProductProfitList = (fetchData?: boolean) => {
    const dispatch = useAppDispatch();
    const [productProfitList, setProductProfitList] = useState<{ [key: string]: any }>([]);
    const [inventoryId, setInventoryId] = useState<string>("");
    const [dateRange, setDateRange] = useState<string>("");
    const [channel, setChannel] = useState<string>("");
    const [debouncedSearch, setDebouncedSearch] = useState<string>("");
    const [sortValues, setSortValues] = useState<any>("");
    const [pagination, setPagination] = useState<{ currentPage: string; noOfPages: string }>({
        currentPage: "1",
        noOfPages: "",
    });

    const {
        fetchingProductProfitList,
        fetchedProductProfitListSuccess,
        fetchedProductProfitListFailure,
    } = useAppSelector((state) => state.dashboard);
    const { updatedLimit, isLoadingMore, setLoadMore } = useInfiniteScroll(
        { current: pagination.currentPage, number_of_pages: pagination.noOfPages },
        10
    );

    const handleProductProfitDebouncedChange = debounce((e) => {
        setDebouncedSearch(e?.target?.value ?? "");
    }, 500);

    useEffect(() => {
        if (Boolean(fetchedProductProfitListSuccess)) {
            setProductProfitList(fetchedProductProfitListSuccess);
            setPagination({
                currentPage: fetchedProductProfitListSuccess?.pagination?.current,
                noOfPages: fetchedProductProfitListSuccess?.pagination?.number_of_pages,
            });
            setLoadMore(false);
        }
    }, [fetchedProductProfitListSuccess]);

    useEffect(() => {
        if (fetchData) {
            dispatch(
                dashboardActions?.getProductProfitList(
                    updatedLimit,
                    inventoryId,
                    channel,
                    dateRange,
                    debouncedSearch,
                    sortValues
                )
            );
        }
    }, [
        dispatch,
        fetchData,
        updatedLimit,
        inventoryId,
        channel,
        dateRange,
        debouncedSearch,
        sortValues,
    ]);

    return {
        handleProductProfitDebouncedChange,
        totalProfit: productProfitList?.totalProfit,
        data: productProfitList?.inventories,
        isFetching: fetchingProductProfitList,
        error: fetchedProductProfitListFailure,
        setProductInventoryId: setInventoryId,
        setProductChannel: setChannel,
        setProductDateRange: setDateRange,
        productProfitIsLoadingMore: isLoadingMore,
        productProfitPagination: pagination,
        productProfitSetSortValues: setSortValues,
    };
};

export default useGetDashboardProductProfitList;
