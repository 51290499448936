import React from "react";
import GoogleMapReact from "google-map-react";
import { useEffect, useState } from "react";
import Loader from "../Loader";

type MapProps = {
    lat: number;
    long: number;
    locationData: { lat: number; lng: number; name: string }[];
    onMapMarkerClick?: (marker: { lat: number; lng: number; name: string }) => void;
    onMapMarkerMovement?: (e: any, marker: { lat: number; lng: number; name: string }) => void;
    mapLoading?: boolean;
    setModalPosition?: any;
    showPin?: boolean;
    markerPosition?: { x: number; y: number };
    setSearchModal?: any;
};

type MarkerProps = {
    text?: string;
    onClick?: () => void;
    lat: number;
    lng: number;
    key?: number;
    onMouseMove?: (e) => void;
    pin?: boolean;
};

export const Map = ({
    lat,
    long,
    locationData,
    onMapMarkerClick,
    onMapMarkerMovement,
    mapLoading,
    setModalPosition,
    showPin,
    markerPosition,
    setSearchModal,
}: MapProps) => {
    const [defaultCenter, setDefaultCenter] = useState({
        lat: lat,
        lng: long,
    });
    const defaultProps = {
        center: {
            lat: locationData?.slice(-1)?.[0]?.lat || lat,
            lng: locationData?.slice(-1)?.[0]?.lng || long,
        },
        zoom: 5,
    };

    useEffect(() => {
        if (locationData?.slice(-1)?.[0]?.lat) {
            setDefaultCenter({
                lat: locationData?.slice(-1)?.[0]?.lat,
                lng: locationData?.slice(-1)?.[0]?.lng,
            });
        }
    }, [locationData?.slice(-1)?.[0]?.lat]);

    const handleMouseMove = (e) => {
        setModalPosition({ x: e.clientX, y: e.clientY });
        if (setSearchModal && e.clientX !== markerPosition?.x && e.clientY !== markerPosition?.y) {
            setSearchModal(false);
        }
    };

    if (locationData?.length === 0 || mapLoading) {
        return (
            <div
                style={{
                    height: "100%",
                    width: "100%",
                    borderRadius: "50%",
                    display: "flex",
                    alignItems: "center",
                }}
            >
                <Loader size={6} />
            </div>
        );
    }

    return (
        <div
            style={{ height: "100%", width: "100%", borderRadius: "50%" }}
            onMouseMove={handleMouseMove}
        >
            <GoogleMapReact
                defaultCenter={defaultCenter}
                defaultZoom={defaultProps.zoom}
                options={{
                    disableDefaultUI: true,
                    mapId: "da34cfe7a3f21d74",
                    zoomControl: true,
                    fullscreenControl: true,
                    zoomControlOptions: { position: 5 },
                }}
            >
                {locationData.map((marker, index) => (
                    <Marker
                        key={index}
                        onClick={() => onMapMarkerClick && onMapMarkerClick(marker)}
                        onMouseMove={(e) => onMapMarkerMovement && onMapMarkerMovement(e, marker)}
                        lat={marker.lat}
                        lng={marker.lng}
                        text={marker.name}
                        pin={showPin}
                    />
                ))}
            </GoogleMapReact>
        </div>
    );
};

const Marker = ({ text, onClick, onMouseMove, key, pin }: MarkerProps) => (
    <div
        onClick={onClick}
        onMouseMove={onMouseMove}
        key={key}
        className={`${
            !pin &&
            "h-[20px] w-[20px] cursor-pointer bg-btnColor bg-g-50 border-[#16C6A440] border-[3px] rounded-full"
        } `}
    >
        {pin && <i className="ri-map-pin-fill cursor-pointer text-2xl text-g-50"></i>}
    </div>
);
