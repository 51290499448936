import { useEffect, useState } from "react";
import { inventoryActions } from "redux/Ldb/actions";
import { useAppDispatch, useAppSelector } from "./storeHooks";
import { useParams } from "react-router-dom";
import useInfiniteScroll from "./useInfinteScroll";
import { uniqBy } from "lodash";

const useGetSalesOrders = (inventoryId?: string, warehouseId?: string) => {
    const dispatch = useAppDispatch();
    const { fetchingSalesOrders, fetchedSalesOrdersSuccess, fetchedSalesOrdersFailure } =
        useAppSelector((state) => state.inventory);

    const [salesOrderData, setSalesOrderData] = useState<{ [key: string]: any }[]>([]);
    const [pagination, setPagination] = useState<{ currentPage: string; noOfPages: string }>({
        currentPage: "1",
        noOfPages: "",
    });

    const { isLoadingMore, updatedLimit, setLoadMore, updatedPage, setUpdatedPage } =
        useInfiniteScroll(
            { current: pagination.currentPage, number_of_pages: pagination.noOfPages },
            12
        );

    useEffect(() => {
        dispatch(
            inventoryActions.fetchSalesOrders("all", 12, updatedPage, warehouseId, inventoryId)
        );

        return () => {
            dispatch(inventoryActions.resetFetchSalesOrders());
        };
    }, [dispatch, updatedPage, warehouseId, inventoryId]);

    useEffect(() => {
        if (Boolean(fetchedSalesOrdersSuccess)) {
            setSalesOrderData((prev) =>
                updatedPage === 1
                    ? uniqBy([...fetchedSalesOrdersSuccess?.sales], "_id")
                    : uniqBy([...prev, ...fetchedSalesOrdersSuccess?.sales], "_id")
            );
            setPagination({
                currentPage: fetchedSalesOrdersSuccess.pagination?.current,
                noOfPages: fetchedSalesOrdersSuccess.pagination?.number_of_pages,
            });
            setLoadMore(false);
        }
    }, [fetchedSalesOrdersSuccess, warehouseId, updatedPage]);

    return {
        isFetching: fetchingSalesOrders,
        error: fetchedSalesOrdersFailure,
        data: salesOrderData,
        pagination,
        isLoadingMore,
        setUpdatedPage,
    };
};

export default useGetSalesOrders;
