import BreadCrumb from "components/common/BreadCrumb";
import Button from "components/common/Button";
import Loader from "components/common/Loader";
import { Map } from "components/common/Map/Map";
import { MapLabels } from "components/common/Map/MapLabels";
import ShipmentActivityTab from "components/common/ShipmentActivityTab";
import TabsContainer from "components/common/TabsContainer";
import VendorAvatar from "components/common/VendorAvatar";
import PageFrame from "components/layout/PageFrame";
import {
    ShipmentCardDetails,
    ShipmentListCard,
} from "components/views/Ldb/Dashboard/Shipment/ShipmentListCard";
import ShipmentSubListDetails from "components/views/Ldb/Dashboard/Shipment/SubListShipmentDetails";
import DocumentsTab from "components/views/Ldb/Dashboard/Vendors/VendorDetails/Tabs/Document";
import { formatMoney, getCurrencyFromCurrencyCode, onScroll } from "helpers";
import { truncate } from "lodash";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { shipmentActions } from "redux/Ldb/actions/shipmentActions";
import { firebaseService } from "services/firebaseService";
import { useAppDispatch, useAppSelector } from "../../../hooks/storeHooks";

const ShipmentDetails = () => {
    const limit = 20;
    const [activeId, setActiveId] = useState("");
    const [mapLoading, setMapLoading] = useState(false);
    const [activeTab, setActiveTab] = useState(0);
    const [allShipments, setAllShipments] = useState([]);
    const [shipmentLimit, setShipmentLimit] = useState(limit);
    const [loadMoreShipments, setLoadMoreShipments] = useState(false);
    const [shipmentPagination, setShipmentPagination] = useState<{
        current: number | string;
        number_of_pages: number | string;
    }>({
        current: "",
        number_of_pages: "",
    });
    const [shipmentDetails, setShipmentDetails] = useState<{ [key: string]: any }>({});
    const [trackingData, setTrackingData] = useState([]);
    const [mapLabelData, setMapLabelData] = useState({
        lastUpdated: "",
        currLocation: "",
    });
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const params = useParams();
    const tabs = ["Details", "Shipment updates", "Documents"];

    const {
        fetchingShipments,
        fetchShipmentsSuccess,
        fetchingSingleShipment,
        fetchSingleShipmentSuccess,
    } = useAppSelector((state) => state.shipment);
    
    const displayActiveTab = () => {
        switch (activeTab) {
            case 0:
                return (
                    <ShipmentSubListDetails
                        shipmentId={shipmentDetails?._id}
                        customerName={
                            shipmentDetails?.shipmentType === "freight"
                                ? shipmentDetails?.freight?.customerDetails?.customerName
                                : shipmentDetails?.supplier?.customerAndSupplierDetails?.company
                        }
                        origin={shipmentDetails?.movementdetails?.origin?.address}
                        destination={shipmentDetails?.movementdetails?.destination?.address}
                        billOfLaden={shipmentDetails?.movementdetails?.billofLadin}
                        incoterms={shipmentDetails?.movementdetails?.incoterms}
                        POId={
                            shipmentDetails?.purchaseOrder?.taId
                                ? "PO-" + shipmentDetails?.purchaseOrder?.taId
                                : "N/A"
                        }
                        packageType={shipmentDetails?.shipmentDetails?.packageType}
                        // dimensions={"L: 40cm • W:30cm  • H:30cm dummy"}
                        packages={
                            shipmentDetails?.shipmentDetails?.packages &&
                            shipmentDetails?.shipmentDetails?.packages?.length > 0
                                ? shipmentDetails?.shipmentDetails?.packages
                                : shipmentDetails?.shipmentDetails?.containers
                        }
                        valueOfGoods={
                            shipmentDetails?.shipmentType === "freight"
                                ? getCurrencyFromCurrencyCode(
                                      shipmentDetails?.freight?.goodsDetails?.value?.currency
                                  ) +
                                  " " +
                                  formatMoney()?.format(
                                      shipmentDetails?.freight?.goodsDetails?.value?.amount
                                  )
                                : ""
                        }
                        goodsDescription={
                            shipmentDetails?.shipmentType === "freight"
                                ? shipmentDetails?.freight?.goodsDetails?.description
                                : shipmentDetails?.supplier?.ItemDetails?.description
                        }
                        product={shipmentDetails?.purchaseOrder?.inventory}
                    />
                );

            case 1:
                return (
                    <ShipmentActivityTab
                        showNoContent
                        activities={shipmentDetails?.shipmentUpates}
                        subContent="Live updates"
                        subContentClasName="!text-g-75 "
                        className="!px-0 !py-1"
                        contentClass={"max-h-[47vh]"}
                    />
                );

            case 2:
                return (
                    <div className="px-8 lg:px-0">
                        <div className="flex mt-6 w-full items-center gap-2 mb-2">
                            <p
                                className={`text-sm whitespace-nowrap  font-rocGroteskMedium text-g-75`}
                            >
                                Shipment documents
                            </p>
                            <hr className="w-[90%] text-slate-100 hidden lg:block" />
                        </div>
                        <DocumentsTab
                            dropDownClass={"!left-[-85px]"}
                            showNoContent
                            docs={shipmentDetails?.documents?.map((documentData) => {
                                return {
                                    name: documentData?.filename,
                                    path: documentData?.path,
                                };
                            })}
                        />
                    </div>
                );

            default:
                return (
                    <ShipmentSubListDetails
                        customerName={
                            shipmentDetails?.shipmentType === "freight"
                                ? shipmentDetails?.freight?.customerDetails?.customerName
                                : shipmentDetails?.supplier?.customerAndSupplierDetails?.company
                        }
                        origin={shipmentDetails?.movementdetails?.origin?.address}
                        destination={shipmentDetails?.movementdetails?.destination?.address}
                        billOfLaden={
                            shipmentDetails?.shipmentType === "freight"
                                ? shipmentDetails?.movementdetails?.billofLadin
                                : ""
                        }
                        incoterms={
                            shipmentDetails?.shipmentType === "freight"
                                ? shipmentDetails?.movementdetails?.incoterms
                                : ""
                        }
                        POId={"PO-" + shipmentDetails?.purchaseOrder?.taId}
                        packageType={
                            shipmentDetails?.shipmentType === "freight"
                                ? shipmentDetails?.shipmentDetails?.packageType
                                : ""
                        }
                        // dimensions={"L: 40cm • W:30cm  • H:30cm dummy"}
                        valueOfGoods={
                            shipmentDetails?.shipmentType === "freight"
                                ? getCurrencyFromCurrencyCode(
                                      shipmentDetails?.freight?.goodsDetails?.value?.currency
                                  ) +
                                  " " +
                                  formatMoney()?.format(
                                      shipmentDetails?.freight?.goodsDetails?.value?.amount
                                  )
                                : ""
                        }
                        goodsDescription={
                            shipmentDetails?.shipmentType === "freight"
                                ? shipmentDetails?.freight?.goodsDetails?.description
                                : shipmentDetails?.supplier?.ItemDetails?.description
                        }
                    />
                );
        }
    };

    const shipmentListContainer = document.getElementById("shipmentListContainer");

    const onShipmentSectionScroll = useCallback(
        () =>
            onScroll(
                shipmentListContainer,
                shipmentPagination?.current as number,
                shipmentPagination?.number_of_pages as number,
                () => {
                    setShipmentLimit(() => shipmentLimit + limit);
                    setLoadMoreShipments(true);
                }
            ),
        [shipmentListContainer, shipmentPagination]
    );

    const onClickShipListCard = (e) => {
        setActiveId(e);
        navigate(`/dashboard/shipment/${e}`, { replace: true });
    };

    const onMapMarkerClick = (markerData) => {
        setMapLabelData({
            currLocation: markerData?.textAddress,
            lastUpdated: moment(markerData?.timestamp).fromNow(),
        });
    };

    const updateLocation = (data: { [key: string]: any }) => {
        const locationsObjArr: { [key: string]: any }[] = Object.values(data || {})?.map(
            (values) => {
                return values;
            }
        );
        const locationsObjDataArr: { [key: string]: any }[][] = locationsObjArr?.map((obj) => {
            return Object.values(obj.locations)?.map((location) => location);
        });
        const locationValues = locationsObjDataArr?.map((locationValue) => locationValue[1]);

        setMapLabelData({
            currLocation: locationValues?.[0]?.textAddress,
            lastUpdated: locationValues?.[0]?.timestamp
                ? moment(locationValues?.[0]?.timestamp).fromNow()
                : "N/A",
        });
        setTrackingData(locationValues);
    };

    useEffect(() => {
        dispatch(shipmentActions.getShipments(shipmentLimit));
    }, [shipmentLimit]);

    useEffect(() => {
        if (Boolean(fetchShipmentsSuccess)) {
            setAllShipments(fetchShipmentsSuccess?.data);
            setShipmentPagination({
                current: fetchShipmentsSuccess?.pagination?.current,
                number_of_pages: fetchShipmentsSuccess?.pagination?.number_of_pages,
            });
        }
    }, [fetchShipmentsSuccess]);

    useEffect(() => {
        setActiveId(params?.shipmentId);
    }, [params?.shipmentId]);

    useEffect(() => {
        dispatch(shipmentActions.getSingleShipment(params?.shipmentId));
    }, [dispatch, params?.shipmentId]);

    useEffect(() => {
        if (Boolean(fetchSingleShipmentSuccess)) {
            setShipmentDetails(fetchSingleShipmentSuccess?.shipment);
        }
    }, [fetchSingleShipmentSuccess]);

    useEffect(() => {
        let unSubscribe;

        firebaseService.fetchRealtimeMvmLocations(
            params?.shipmentId,
            updateLocation,
            setMapLoading,
            (unSubFunc) => {
                unSubscribe = unSubFunc;
            }
        );

        return () => {
            if (unSubscribe) {
                unSubscribe();
            }
        };
    }, [params?.shipmentId]);

    useEffect(() => {
        shipmentListContainer?.addEventListener("scroll", onShipmentSectionScroll);
        return () => {
            shipmentListContainer?.removeEventListener("scroll", onShipmentSectionScroll);
        };
    }, [shipmentListContainer, onShipmentSectionScroll]);

    return (
        <PageFrame containerClassName={""}>
            <div className="  h-full  relative flex  lg:px-8 ">
                <div className="hidden lg:block lg:w-[22%] bg-white w-[22%] rounded-tr-[12px] rounded-br-[12px] border-l border-slate-100 z-[10] p-4 h-full fixed">
                    <div>
                        <BreadCrumb
                            parentCrumb={"Shipment management"}
                            size="large"
                            parentClassName="!text-slate-700"
                        />
                        {/* <div className="flex absolute pt-4 items-center gap-3">
                            <Dropdown
                                value={""}
                                dropdown={
                                    <div className="flex items-center cursor-pointer gap-2 py-1.5 px-3 bg-white rounded shadow-boxShadow-2 border border-n-20">
                                        <i className="ri-filter-3-fill"></i>
                                        <p className="text-[13px] font-rocGroteskMedium">Filter</p>
                                    </div>
                                }
                                dropdownContainerClasses={
                                    " !py-0 top-[49px] shadow-dropdownShadow rounded !border-0"
                                }
                                dropdownClassName={"!max-h-fit"}
                                name={"quickAction"}
                                dropdownOptions={[
                                    {
                                        customChild: (
                                            <div className="w-[318px]  relative  bg-white p-4 shadow-boxShadow-2 rounded border border-n-20">
                                                <div className="flex relative flex-col h-fit gap-2">
                                                    <SelectInput
                                                        value={""}
                                                        name="status"
                                                        placeholder={"Status"}
                                                        // handleChange={(name, value) =>
                                                        //     handleChange({ target: { name, value } })
                                                        // }
                                                        isRequired={false}
                                                        dropdownOptions={[
                                                            {
                                                                label: "Accepted",
                                                                value: "accepted",
                                                            },
                                                            {
                                                                label: "Rejected",
                                                                value: "rejected",
                                                            },
                                                            { label: "Pending", value: "pending" },
                                                        ].map((item) => ({
                                                            label: item.label,
                                                            value: item.value,
                                                        }))}
                                                    />
                                                    <TextInput
                                                        name={"amount"}
                                                        type={"text"}
                                                        // value={""}
                                                        placeholder={"Amount"}
                                                        // onChange={handleChange}
                                                        required={false}
                                                    />
                                                    <TextInput
                                                        name={"vendor"}
                                                        type={"text"}
                                                        // value={""}
                                                        placeholder={"Vendor"}
                                                        // onChange={handleChange}
                                                        required={false}
                                                    />
                                                </div>
                                            </div>
                                        ),
                                    },
                                ]}
                            />
                            <Dropdown
                                value={""}
                                dropdown={
                                    <div className="flex  items-center cursor-pointer gap-2 py-1.5 px-3 bg-white rounded shadow-boxShadow-2 border border-n-20">
                                        <i className="ri-filter-3-fill"></i>
                                        <p className="text-[13px] font-rocGroteskMedium">Sort</p>
                                    </div>
                                }
                                dropdownContainerClasses={
                                    "  !py-0 top-[49px] shadow-dropdownShadow rounded !border-0"
                                }
                                dropdownClassName={"!max-h-fit"}
                                name={"quickAction"}
                                dropdownOptions={[
                                    {
                                        customChild: (
                                            <div className="w-[318px] relative z-[100] bg-white p-4 shadow-boxShadow-2 rounded border border-n-20">
                                                <div className="flex relative flex-col h-fit gap-2">
                                                    <SelectInput
                                                        value={""}
                                                        name="status"
                                                        placeholder={"Status"}
                                                        // handleChange={(name, value) =>
                                                        //     handleChange({ target: { name, value } })
                                                        // }
                                                        isRequired={false}
                                                        dropdownOptions={[
                                                            {
                                                                label: "Accepted",
                                                                value: "accepted",
                                                            },
                                                            {
                                                                label: "Rejected",
                                                                value: "rejected",
                                                            },
                                                            { label: "Pending", value: "pending" },
                                                        ].map((item) => ({
                                                            label: item.label,
                                                            value: item.value,
                                                        }))}
                                                    />
                                                </div>
                                            </div>
                                        ),
                                    },
                                ]}
                            />
                        </div> */}

                        <div className="overflow-y-hidden mt-10 mb-5">
                            <div className="w-full  bg-slate-100 py-3 px-3">
                                <p className="text-slate-700 font-rocGroteskMedium text-[15px]">
                                    Shipment list
                                </p>
                            </div>
                        </div>
                        <div className="w-full overflow-hidden">
                            <div
                                id="shipmentListContainer"
                                className="space-y-3 overflow-y-auto  pb-24 max-h-[calc(100vh-220px)]"
                            >
                                {fetchingShipments && !loadMoreShipments ? (
                                    <Loader size={5} />
                                ) : (
                                    allShipments?.map((shipment) => (
                                        <ShipmentListCard
                                            key={shipment._id}
                                            id={shipment._id}
                                            shipTitle={truncate(
                                                shipment?.shipmentType?.toLowerCase() === "freight"
                                                    ? shipment?.freight?.goodsDetails?.description
                                                    : shipment?.supplier?.ItemDetails?.description,
                                                {
                                                    length: 28,
                                                }
                                            )}
                                            taId={shipment?.taId}
                                            origin={truncate(
                                                shipment?.movementdetails?.origin?.address,
                                                { length: 28 }
                                            )}
                                            destination={truncate(
                                                shipment?.movementdetails?.destination?.address,
                                                { length: 28 }
                                            )}
                                            status={shipment?.status}
                                            onClick={onClickShipListCard}
                                            activeId={activeId}
                                        />
                                    ))
                                )}
                                {fetchingShipments && loadMoreShipments && (
                                    <div className="flex justify-center my-4">
                                        <Loader color="gm-25" size={4} />
                                    </div>
                                )}
                                {shipmentPagination?.current ===
                                    shipmentPagination?.number_of_pages && (
                                    <div className="flex my-4 justify-center text-sm font-rocGroteskMedium">
                                        End of list
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="w-full lg:w-[65.8%] lg:shadow  lg:absolute bg-[white]  lg:rounded-tl-[12px] lg:rounded-bl-[12px] right-[28px] lg:pt-5 pb-4 lg:px-4 h-fit overflow-y-auto">
                    {fetchingSingleShipment ? (
                        <div className="h-[80vh] flex items-center justify-center">
                            <Loader size={8} />
                        </div>
                    ) : (
                        <div className="  w-full lg:pt-6 lg:px-6  h-full">
                            <div className="">
                                <ShipmentCardDetails
                                    onClose={() => navigate("/dashboard/shipment")}
                                    status={shipmentDetails?.status}
                                    description={
                                        shipmentDetails?.shipmentType === "freight"
                                            ? shipmentDetails?.freight?.goodsDetails?.description
                                            : shipmentDetails?.supplier?.ItemDetails?.description
                                    }
                                    origin={shipmentDetails?.movementdetails?.origin?.address}
                                    destination={
                                        shipmentDetails?.movementdetails?.destination?.address
                                    }
                                    etd={
                                        shipmentDetails?.movementdetails?.etd
                                            ? moment(shipmentDetails?.movementdetails?.etd)?.format(
                                                  "MMM DD, YYYY LT"
                                              )
                                            : ""
                                    }
                                    eta={
                                        shipmentDetails?.movementdetails?.eta
                                            ? moment(shipmentDetails?.movementdetails?.eta)?.format(
                                                  "MMM DD, YYYY LT"
                                              )
                                            : ""
                                    }
                                    mode={
                                        shipmentDetails?.shipmentType === "freight"
                                            ? shipmentDetails?.freight?.freightMode
                                            : ""
                                    }
                                />
                            </div>

                            <div
                                className="flex mb-6 mt-8 lg:items-center bg-slate-50 py-4 px-3 rounded-[8px] justify-between 
                            border-b border-b-[#F1F5F9] lg:border-b-none flex-col lg:flex-row "
                            >
                                <div className="flex space-x-4 mb-4 lg:mb-0">
                                    <div className="">
                                        <VendorAvatar
                                            size={40}
                                            imageSrc={shipmentDetails?.vendor?.profile?.avatar}
                                            name={shipmentDetails?.vendor?.profile?.businessName}
                                        />
                                    </div>
                                    <div className="font-rocGroteskMedium">
                                        <h6 className="text-sm text-slate-800  leading-4">
                                            Vendor: {shipmentDetails?.vendor?.profile?.businessName}
                                        </h6>
                                        <p className="text-blue-50 leading-6 text-[12px]">
                                            {shipmentDetails?.vendor?.email}
                                        </p>
                                    </div>
                                </div>

                                <a
                                    href={`mailto:${shipmentDetails?.vendor?.email}`}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <Button
                                        btnText={"Contact vendor"}
                                        type={"button"}
                                        btnClassname="w-full lg:!w-auto !py-3 lg:!py-2 !px-4"
                                    />
                                </a>
                            </div>
                            <div className="mt-5 lg:mb-6">
                                <div className="w-full hidden lg:flex items-center space-x-3">
                                    <div className="whitespace-nowrap py-2 font-rocGroteskMedium">
                                        <p className="text-g-75 leading-3">Live tracking</p>
                                    </div>
                                    <div className="border-b border-slate-100  w-full"></div>
                                </div>

                                <div className="relative">
                                    <div className="h-[480px] w-full border mt-2 map-design rounded-[8px]">
                                        <Map
                                            lat={shipmentDetails?.movementdetails?.origin?.lat}
                                            long={shipmentDetails?.movementdetails?.origin?.lng}
                                            mapLoading={mapLoading}
                                            locationData={trackingData?.map((data) => ({
                                                lat: data?.lat,
                                                lng: data?.lng,
                                                name: `lat: ${data?.lat}, lng: ${data?.lng}`,
                                                // textAddress: data?.textAddress,
                                                // timestamp: data?.timestamp,
                                            }))}
                                            onMapMarkerClick={onMapMarkerClick}
                                        />
                                    </div>
                                    <div className="absolute w-full bottom-4 px-3">
                                        <MapLabels
                                            lastUpdated={mapLabelData.lastUpdated}
                                            currentLocation={mapLabelData.currLocation}
                                        />
                                    </div>
                                    {trackingData && trackingData?.length === 0 && (
                                        <div className="h-[480px] rounded-[8px] w-full absolute bg-slate-700/30 top-0" />
                                    )}
                                </div>
                            </div>

                            <div className="mt-6 lg:mt-2">
                                <TabsContainer
                                    tabs={tabs}
                                    activeTab={activeTab}
                                    setActiveTab={setActiveTab}
                                    component={displayActiveTab()}
                                    className={"!px-6 lg:!px-0 !gap-x-3 lg:!gap-x-8 !w-full"}
                                />
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </PageFrame>
    );
};

export default ShipmentDetails;
