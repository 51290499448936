import React from "react";
import Button from "./../../../common/Button";
import ModalContainer from "./../../../common/ModalContainer";

type ModalProps = {
    showModal: boolean;
    setShowModal: (value: boolean) => void;
};
const DataSyncModal = ({ showModal, setShowModal }: ModalProps) => {
    return (
        <div>
            <ModalContainer
                open={showModal}
                modalClassName={"py-10 px-10"}
                tailwindClassName="w-[600px]"
                showCloseIcon={false}
                closeModal={() => {
                    setShowModal(false);
                }}
            >
                <div className="bg-white  pt-4 pb-7  rounded-[8px] relative">
                    <i
                        className="ri-close-fill text-xl text-gm-50 absolute top-4 right-6 cursor-pointer"
                        onClick={() => {
                            setShowModal(false);
                        }}
                    ></i>
                    <div className="mt-4 ">
                        <div className="text-center flex justify-center flex-col items-center px-8 my-14">
                            <div className="mb-[42px]">
                                <img
                                    src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1719391809/information-fill_ivzikm.svg"
                                    alt=""
                                    className=""
                                />
                            </div>
                            <div className=" font-rocGroteskMedium">
                                <p className="mt-1 text-base text-slate-700">
                                    Data synced from external platforms take about 24 hours to get
                                    trained properly. More accurate information about your inventory
                                    will show up on the dashboard in the next 24 hours, so do not
                                    worry if you currently have some missing information.
                                </p>
                            </div>
                        </div>
                        <div className="mt-5 border-t border-t-slate-200 pt-4 px-8">
                            <Button
                                btnText={"Got it"}
                                btnClassname="!py-3 cursor-pointer !text-[14px] !bg-n-20 !text-g-75 !border border-n-20 !font-rocGroteskMedium !px-4"
                                onClick={() => {
                                    setShowModal(false);
                                }}
                            />
                        </div>
                    </div>
                </div>
            </ModalContainer>
        </div>
    );
};

export default DataSyncModal;
