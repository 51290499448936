import React from "react";
import PotentialStockOuts from "components/views/Ldb/Dashboard/Inventory/Incidents/PotentialStockOuts";
import PotentialOverStocking from "components/views/Ldb/Dashboard/Inventory/Incidents/PotentialOverStocking";

const Incidents = () => {
    return (
        <div className="grid grid-cols-2 space-x-4">
            <PotentialStockOuts />
            <PotentialOverStocking />
        </div>
    );
};

export default Incidents;
