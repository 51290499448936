import React from "react";
import { PlacesType, Tooltip } from "react-tooltip";

type SynthTooltipProps = {
    divId: string;
    icon: JSX.Element | string;
    styling?: object;
    content: string;
    position?: PlacesType;
};

const SynthTooltip = ({ divId, icon, styling, content, position }: SynthTooltipProps) => {
    return (
        <div>
            <a id={divId}>{icon}</a>

            <Tooltip
                anchorSelect={`#${divId}`}
                place={position || "top"}
                content={content}
                style={{ width: "180px", zIndex: 9999, ...styling }}
            />
        </div>
    );
};

export default SynthTooltip;
