import React, {useEffect} from "react";
import Button from "./Button";
import {useDispatch, useSelector} from "react-redux";
// import { useSearchParams } from "react-router-dom";
import ModalContainer from "./ModalContainer";
import Loader from "./Loader";
import {saveToStorage} from "../../helpers";
import {vendorActions} from "../../redux/Ldb/actions/vendorActions";
import {useAppDispatch, useAppSelector} from "../../hooks/storeHooks";

type AddVendorMethodsProps = {
    addContactFunc?: () => void,
    containerClassname?: string,
};

const AddVendorMethods = ({addContactFunc, containerClassname}: AddVendorMethodsProps) => {
    const {
        authenticatingGmail,
        authenticateGmailSuccess,
        connectingGoogleWorkspace,
        authenticatingMicrosoft,
        authenticateMicrosoftSuccess,
        connectingMicrosoftWorkspace,
        fetchedAllChannelsSuccess,
        fetchingAllChannels,
    } = useAppSelector((state) => state.vendor);

    const dispatch = useAppDispatch();

    const googleConnected = fetchedAllChannelsSuccess?.channel?.find(
        (item: any) => item?.channel?.toLowerCase() === "gmail"
    );
    const microsoftConnected = fetchedAllChannelsSuccess?.channel?.find(
        (item: any) => item?.channel?.toLowerCase() === "microsoft"
    );

    const handleGoogleConnect = () => {
        dispatch(vendorActions.authenticateGmail());
    };

    const handleMicrosoftConnect = () => {
        dispatch(vendorActions.authenticateMicrosoftAcct());
    };

    useEffect(() => {
        if (Boolean(authenticateGmailSuccess)) {
            dispatch(vendorActions.resetAuthenticateGmailSuccess());
            saveToStorage("connectionSource", "gmail", true);
            window.open(authenticateGmailSuccess?.url, "_target");
        }
    }, [dispatch, authenticateGmailSuccess]);

    useEffect(() => {
        if (Boolean(authenticateMicrosoftSuccess)) {
            dispatch(vendorActions.resetAuthenticateMicrosoftSuccess());
            saveToStorage("connectionSource", "microsoft", true);
            window.open(authenticateMicrosoftSuccess?.url, "_target");
        }
    }, [dispatch, authenticateMicrosoftSuccess]);

    useEffect(() => {
        dispatch(vendorActions.getAllChannels());
    }, [dispatch]);

    return (
        <>
            <div
                className={`w-full mb-[57px] grid grid-cols-[1.2fr_0.2fr_1.2fr_0.2fr_1.2fr] max-lg:grid-cols-1 max-lg:grid-rows-[1.2fr_0.2fr_1.2fr_0.2fr_1.2fr] items-center ${containerClassname}`}
            >
                <div className=" h-full bg-white border border-slate-200 rounded-[6px]">
                    <div className="h-[160px] max-lg:hidden rounded-t-[6px] bg-cover bg-center bg-no-repeat bg-[url('https://res.cloudinary.com/dvxi7qcmd/image/upload/v1691999056/Tradeally_2.0/mailConnectBanner_kobrwo.svg')]" />
                    <div className="flex flex-col justify-between h-[calc(100%-160px)]">
                        <div className="pt-4 px-4 mb-6 border-t border-slate-200">
                            <div className="text-center">
                                <p className="text-lg font-rocGroteskMedium mb-1">
                                    Connect account
                                </p>
                                <p className="text-sm font-rocGroteskRegular text-slate-500">
                                    Automatically add all on your contact list to SynthAlly with
                                    ease
                                </p>
                            </div>
                        </div>
                        <div className="flex flex-col gap-2 pb-4 px-4">
                            <Button
                                btnClassname={
                                    "!bg-white border disabled:!bg-slate-50 border-n-20 !py-2 !text-gm-50 !px-4"
                                }
                                onClick={handleGoogleConnect}
                                isLoading={authenticatingGmail}
                                disabled={
                                    fetchingAllChannels || authenticatingGmail || googleConnected
                                }
                                btnCustomContent={
                                    <div className="flex items-center gap-2.5">
                                        <img
                                            src={
                                                "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1690905410/Web%20App/google_logo_u0iqig.svg"
                                            }
                                            width={16}
                                            height={16}
                                            alt="google logo"
                                        />
                                        <div className="text-[13px] font-rocGroteskMedium whitespace-nowrap text-gm-50">
                                            {googleConnected ? (
                                                <p className="flex items-center gap-2.5">
                                                    <span>Connected</span>
                                                    <i className="ri-check-fill text-sm text-g-50"></i>
                                                </p>
                                            ) : (
                                                <span>Connect Google account</span>
                                            )}
                                        </div>
                                    </div>
                                }
                                type={"button"}
                            />
                            <Button
                                btnClassname={
                                    "!bg-white border border-n-20 disabled:!bg-slate-50 !text-gm-50 !py-2 !px-4"
                                }
                                disabled={
                                    fetchingAllChannels || authenticatingGmail || microsoftConnected
                                }
                                onClick={handleMicrosoftConnect}
                                isLoading={authenticatingMicrosoft}
                                btnCustomContent={
                                    <div className="flex items-center gap-2.5">
                                        <img
                                            src={
                                                "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1691070380/Web%20App/outlook_logo_vrkwhd.svg"
                                            }
                                            width={16}
                                            height={16}
                                            alt="google logo"
                                        />
                                        <div className="text-[13px] font-rocGroteskMedium whitespace-nowrap text-gm-50">
                                            {microsoftConnected ? (
                                                <p className="flex items-center gap-2.5">
                                                    <span>Connected</span>
                                                    <i className="ri-check-fill text-sm text-g-50"></i>
                                                </p>
                                            ) : (
                                                <span>Connect Microsoft account</span>
                                            )}
                                        </div>
                                    </div>
                                }
                                type={"button"}
                            />
                            {/* <Button
                            btnClassname={"!bg-white border border-n-20 !text-gm-50 !py-2 !px-4"}
                            disabled={fetchingAllChannels || authenticatingGmail}
                            btnCustomContent={
                                <div className="flex items-center gap-2.5">
                                    <img
                                        src={
                                            "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1691070380/Web%20App/whatsapp_logo_depouw.svg"
                                        }
                                        width={16}
                                        height={16}
                                        alt="whatsapp logo"
                                    />
                                    <span className="text-[13px] whitespace-nowrap font-rocGroteskMedium text-gm-50">
                                        Connect WhatsApp account
                                    </span>
                                </div>
                            }
                            type={"button"}
                        /> */}
                        </div>
                    </div>
                </div>
                {/* <p className="text-[11px] text-center font-rocGroteskMedium text-slate-500">OR</p>
                <div className=" h-full bg-white border border-slate-200 rounded-[6px]">
                    <div className="h-[160px] rounded-t-[6px] bg-cover bg-center bg-no-repeat bg-[url('https://res.cloudinary.com/dvxi7qcmd/image/upload/v1691998379/Tradeally_2.0/uploadBanner_utddep.svg')]" />
                    <div className="flex flex-col h-[calc(422px-176px)] justify-between border-t pt-4 px-4 ">
                        <div className="text-center mb-3">
                            <p className="text-lg font-rocGroteskMedium mb-1">Upload CSV File</p>
                            <p className="text-sm font-rocGroteskRegular text-slate-500">
                                Automatically add all on your contact list to Tradeally with ease
                            </p>
                        </div>
                        <Button
                            btnClassname={"!bg-white border border-n-20 !py-2 !px-4"}
                            btnCustomContent={
                                <div className="flex items-center gap-2.5">
                                    <i className="ri-file-upload-line before:content-['\ed15'] text-gm-50"></i>
                                    <span className="text-[13px] font-rocGroteskRegular text-gm-50">
                                        Import file
                                    </span>
                                </div>
                            }
                            type={"button"}
                        />
                    </div>
                </div> */}
                <p className="text-[11px] text-center font-rocGroteskMedium text-slate-500">OR</p>
                <div className="h-full bg-white border border-slate-200 rounded-[6px]">
                    <div className="h-[160px] max-lg:hidden rounded-t-[6px] bg-cover bg-center bg-no-repeat bg-[url('https://res.cloudinary.com/dvxi7qcmd/image/upload/v1691998379/Tradeally_2.0/manualBanner_hnelk9.svg')]" />
                    <div className="flex flex-col h-[calc(422px-176px)] justify-between pt-4 px-4  border-slate-200">
                        <div className="text-center mb-3">
                            <p className="text-lg font-rocGroteskMedium mb-1">Add manually</p>
                            <p className="text-sm font-rocGroteskRegular text-slate-500">
                                Enter the details of your vendor one after the other
                            </p>
                        </div>
                        <Button
                            btnClassname={"!bg-white border border-n-20 mb-4 !py-2 !px-4"}
                            btnCustomContent={
                                <div className="flex items-center gap-2.5">
                                    <span className="text-[13px] font-rocGroteskRegular text-gm-50">
                                        Add contact
                                    </span>
                                </div>
                            }
                            onClick={() => addContactFunc?.()}
                            type={"button"}
                        />
                    </div>
                </div>
            </div>

            <ModalContainer
                open={connectingGoogleWorkspace || connectingMicrosoftWorkspace}
                showCloseIcon={false}
                tailwindClassName="w-[36%] max-lg:w-1/2 max-sm:w-[95%]"
            >
                <div className="w-full py-8 bg-white flex flex-col items-center rounded-md">
                    <Loader size={6} color={"gm-50"} />
                    <p className="text-sm text-gm-50 font-rocGroteskMedium mt-2">
                        Connecting account...
                    </p>
                </div>
            </ModalContainer>

            <ModalContainer
                open={false}
                showCloseIcon={false}
                tailwindClassName="w-[64%] max-lg:w-1/2 max-sm:w-[95%]"
                // closeModal={() => {
                //     setSourceVendor(false);
                // }}
            >
                <div className="bg-white rounded-lg shadow-cardShadow relative">
                    <div className="px-10 pt-3">
                        <div className="flex flex-col items-center text-center p-6">
                            <p className="text-2xl mb-1 font-rocGroteskMedium text-g-75">
                                Select vendors to add to your list
                            </p>
                            <p className="text-sm font-rocGroteskMedium text-gm-40">
                                Add existing vendors from your connected account to your vendor list
                                to help you get started
                            </p>
                        </div>
                        <div className="w-full">
                            <div className="flex items-center w-full py-4">
                                <div className="flex items-center gap-5 w-full">
                                    <div>
                                        <img
                                            src={
                                                "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1690905410/Web%20App/google_logo_u0iqig.svg"
                                            }
                                            width={56}
                                            height={56}
                                            alt="google logo"
                                        />
                                    </div>
                                    <div>
                                        <p className="text-base text-slate-700 font-rocGroteskMedium">
                                            Stripe
                                        </p>
                                        <p className="text-base text-slate-500 font-rocGroteskMedium">
                                            augustine@stripe.com
                                        </p>
                                    </div>
                                </div>
                                <div>
                                    <input
                                        type="checkbox"
                                        id="select"
                                        name="select"
                                        className="h-4 w-4"
                                    />
                                </div>
                            </div>
                            <div className="flex items-center w-full py-4">
                                <div className="flex items-center gap-5 w-full">
                                    <div>
                                        <img
                                            src={
                                                "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1690905410/Web%20App/google_logo_u0iqig.svg"
                                            }
                                            width={56}
                                            height={56}
                                            alt="google logo"
                                        />
                                    </div>
                                    <div>
                                        <p className="text-base text-slate-700 font-rocGroteskMedium">
                                            Stripe
                                        </p>
                                        <p className="text-base text-slate-500 font-rocGroteskMedium">
                                            augustine@stripe.com
                                        </p>
                                    </div>
                                </div>
                                <div>
                                    <input
                                        type="checkbox"
                                        id="select"
                                        name="select"
                                        className="h-4 w-4"
                                    />
                                </div>
                            </div>
                            <div className="flex items-center w-full py-4">
                                <div className="flex items-center gap-5 w-full">
                                    <div>
                                        <img
                                            src={
                                                "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1690905410/Web%20App/google_logo_u0iqig.svg"
                                            }
                                            width={56}
                                            height={56}
                                            alt="google logo"
                                        />
                                    </div>
                                    <div>
                                        <p className="text-base text-slate-700 font-rocGroteskMedium">
                                            Stripe
                                        </p>
                                        <p className="text-base text-slate-500 font-rocGroteskMedium">
                                            augustine@stripe.com
                                        </p>
                                    </div>
                                </div>
                                <div>
                                    <input
                                        type="checkbox"
                                        id="select"
                                        name="select"
                                        className="h-4 w-4"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="w-full py-6">
                            <Button btnText={"Add vendors"} type={"button"} />
                        </div>
                    </div>
                </div>
            </ModalContainer>
        </>
    );
};

export default AddVendorMethods;
