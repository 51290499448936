import React, { useEffect, useState } from "react";
import Button from "components/common/Button";
import CustomTable from "components/common/CustomTable";
import VendorAvatar from "components/common/VendorAvatar";
import { useNavigate, useParams } from "react-router-dom";
import { truncate } from "lodash";
import { getCurrencyFromCurrencyCode } from "helpers";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import ModalContainer from "components/common/ModalContainer";
import TextareaInput from "components/common/InputField/TextareaInput";
import TextInput from "components/common/InputField/TextInput";
import DateInput from "components/common/DateInput";
import SelectInput from "components/common/InputField/SelectInput";
import AddressInput from "components/common/InputField/AddressInput";
import { unitOfMeasurement } from "variables/unitOfMeasurement";
import moment from "moment";
import { orderActions, salesActions } from "redux/Ldb/actions";

interface OrderDetailsProps {
    onClickOne?: any;
    onClickTwo?: any;
    onApprove?: any;
    onReject?: any;
    title: string;
    channelImg: string | JSX.Element;
    channel: string;
    orderDate: string;
    customerPO: string;
    deliveryDate: string;
    currency: string;
    subTotal: string;
    discount: string;
    total: string;
    customerName: string;
    haulage: string;
    thirdParty: string;
    email: string;
    phone: string;
    billAddress: string;
    orderStatus?: string;
    products?: { [key: string]: any }[];
    salesId?: string;
}

const SalesOrderDetail = ({
    onClickOne,
    onClickTwo,
    title,
    channelImg,
    channel,
    orderDate,
    customerPO,
    deliveryDate,
    currency,
    subTotal,
    discount,
    total,
    customerName,
    haulage,
    email,
    thirdParty,
    phone,
    billAddress,
    orderStatus,
    onApprove,
    onReject,
    products,
    salesId,
}: OrderDetailsProps) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { orderId } = useParams();
    const { creatingSalesOrder, creatingSalesShipment, createSalesShipmentSuccess } =
        useAppSelector((state) => state.sales);
    const [shipmentModal, setShipmentModal] = useState<boolean>(false);
    const [shipmentData, setShipmentData] = useState<{ [key: string]: any }>({});
    const [productData, setProductData] = useState<{ [key: string]: any }[]>([{}]);
    const [origin, setOrigin] = useState<{ [key: string]: any }>({});
    const [destination, setDestination] = useState<{ [key: string]: any }>({});
    const [resetOrigin, setResetOrigin] = useState<boolean>(false);
    const [resetDestination, setResetDestination] = useState<boolean>(false);
    const [originDetails, setOriginDetails] = useState<{ [key: string]: any }>({});
    const [destinationDetails, setDestinationDetails] = useState<{ [key: string]: any }>({});
    const [trackingInfo, setTrackingInfo] = useState<boolean>(false);
    const [productDetails, setProductDetails] = useState<boolean>(false);

    const [unitMeasure, setUnitMeasure] = useState<
        {
            label: string | JSX.Element;
            value: string;
        }[]
    >(unitOfMeasurement);

    const [date, setDate] = useState<boolean>(false);

    const handleShipmentData = (e) => {
        const { name, value } = e.target;
        setShipmentData({
            ...shipmentData,
            [name]: value,
        });
    };

    const handleDateChange = (date) => {
        setShipmentData({
            ...shipmentData,
            shipmentDate: date,
        });
    };

    const handleProductData = (e, idx) => {
        const { name, value } = e.target;
        let copyArr = [...productData];
        copyArr[idx][name] = value;
        setProductData(copyArr);
    };

    useEffect(() => {
        if (destination?.destination?.address) {
            const cityCountry = destination?.destination?.address?.split(",");
            setDestinationDetails({
                city: cityCountry[cityCountry?.length - 2],
                country: cityCountry[cityCountry?.length - 1],
            });
        }
    }, [destination]);

    useEffect(() => {
        if (origin?.origin?.address) {
            const cityCountry = origin?.origin?.address?.split(",");
            setOriginDetails({
                city: cityCountry[cityCountry?.length - 2],
                country: cityCountry[cityCountry?.length - 1],
            });
        }
    }, [origin]);

    const handleDeleteProduct = (idx) => {
        const copyProductData = [...productData];
        copyProductData.splice(idx, 1);
        setProductData(copyProductData);
    };

    const handleAddProduct = () => {
        setProductData([...productData, {}]);
    };

    // const handleSave = () => {

    // }

    const handleShipmentTracking = () => {
        const body = {
            ...(shipmentData?.notes && { notes: shipmentData.notes }),
            trackingLink: shipmentData?.trackingLink,
            billOfLading: shipmentData?.billOfLading,
            origin: {
                lat: origin?.origin?.lat,
                lng: origin?.origin?.lng,
                address: origin?.origin?.address,
                country: originDetails?.country?.trim(),
                city: originDetails?.city?.trim() || originDetails?.country?.trim(),
            },
            destination: {
                lat: destination?.destination?.lat,
                lng: destination?.destination?.lng,
                address: destination?.destination?.address,
                country: destinationDetails?.country?.trim(),
                city: destinationDetails?.city?.trim() || destinationDetails?.country?.trim(),
            },
            shipmentId: shipmentData?.shipmentId,
            shipmentMode: shipmentData?.shipmentMode,
            shipmentDate: moment(shipmentData?.shipmentDate)?.format("YYYY-MM-DD"),
            saleId: salesId,
            productDetails: productData,
        };
        console.log(body);
        dispatch(salesActions?.createSalesShipment(body));
    };

    useEffect(() => {
        if (Boolean(createSalesShipmentSuccess)) {
            setShipmentModal(false);
            dispatch(salesActions?.resetCreateSalesShipmentSuccess());
            dispatch(salesActions.getSingleSalesOrder(orderId));
        }
    }, [createSalesShipmentSuccess]);

    const prodTableHeader = [
        { title: "Product name", widthClass: "w-[30%] !font-rocGroteskMedium" },
        { title: "Packing medium", widthClass: "w-[20%] !font-rocGroteskMedium" },
        { title: "Lading quantity", widthClass: "w-[15%] !font-rocGroteskMedium" },
        { title: "UOM", widthClass: "w-[15%] !font-rocGroteskMedium" },
        { title: "Weight", widthClass: "w-[15%] !font-rocGroteskMedium" },
        !trackingInfo &&
            !productDetails && { title: "", widthClass: "w-[5%] !font-rocGroteskMedium" },
    ];

    const prodTableBody = productData?.map((item, idx) => {
        return [
            {
                content: (
                    <div
                        className={`w-full  border-slate-100 text-sm text-slate-700 font-rocGroteskMedium`}
                    >
                        {!trackingInfo && !productDetails ? (
                            <SelectInput
                                value={item?.inventoryId ? item?.productName : ""}
                                name="inventoryId"
                                inputPlaceholder={"Select"}
                                handleChange={(name, value) => {
                                    handleProductData({ target: { name, value } }, idx);
                                }}
                                isRequired={true}
                                className="!border-0 !rounded-none"
                                inputContainerClassName="!mb-0"
                                clearValue={true}
                                dropdownOptions={products?.map((item) => ({
                                    label: item?.productName || item?.sku,
                                    value: item?.inventoryId,
                                }))}
                            />
                        ) : (
                            <p className="px-3 py-4"> Product Name</p>
                        )}
                    </div>
                ),
                tableDataDivClass: "!pl-0",
            },
            {
                content: (
                    <div className={`w-full  border-slate-100 text-sm text-slate-700 `}>
                        {!trackingInfo && !productDetails ? (
                            <SelectInput
                                value={item?.packingMedium || ""}
                                name="packingMedium"
                                inputPlaceholder={"Select"}
                                handleChange={(name, value) =>
                                    handleProductData({ target: { name, value } }, idx)
                                }
                                isRequired={true}
                                className="!border-0 !rounded-none"
                                inputContainerClassName="!mb-0"
                                clearValue={true}
                                dropdownOptions={[
                                    {
                                        label: "Carton (CTN)",
                                        value: "CTN",
                                    },
                                    {
                                        label: "Plastic",
                                        value: "Plastic",
                                    },
                                ]}
                            />
                        ) : (
                            <p className=""></p>
                        )}
                    </div>
                ),
                tableDataDivClass: "!pl-0",
            },
            {
                content: (
                    <div
                        className={`w-full border-slate-100 text-sm text-slate-700 flex items-center px-2.5`}
                    >
                        {!trackingInfo && !productDetails ? (
                            <TextInput
                                value={item?.ladingQuantity ?? ""}
                                name={"ladingQuantity"}
                                required={true}
                                onChange={(e) => handleProductData(e, idx)}
                                inputPlaceholder="Lading Quantity"
                                containerClassname=""
                                inputContainerClassname={"!border-none !rounded-none "}
                                inputClassName="w-full !px-0 !py-0"
                            />
                        ) : (
                            <p className=""></p>
                        )}
                    </div>
                ),
                tableDataDivClass: "!pl-0",
            },
            {
                content: (
                    <div
                        className={`w-full border-slate-100 text-sm text-slate-700 px-2.5 flex items-center`}
                    >
                        {!trackingInfo && !productDetails ? (
                            <SelectInput
                                value={item?.unitOfMeasurement || ""}
                                name="unitOfMeasurement"
                                inputPlaceholder={"UOM"}
                                handleChange={(name, value) =>
                                    handleProductData({ target: { name, value } }, idx)
                                }
                                isRequired={true}
                                className="!border-0 !rounded-none"
                                inputContainerClassName="!mb-0"
                                clearValue={true}
                                dropdownOptions={unitMeasure}
                            />
                        ) : (
                            <p className=""></p>
                        )}
                    </div>
                ),
                tableDataDivClass: "!pl-0",
            },
            {
                content: (
                    <div
                        className={`w-full border-slate-100 text-sm text-slate-700 flex items-center px-2.5`}
                    >
                        {!trackingInfo && !productDetails ? (
                            <>
                                <TextInput
                                    value={item?.weight ?? ""}
                                    name={"weight"}
                                    required={true}
                                    onChange={(e) => handleProductData(e, idx)}
                                    inputPlaceholder="Weight"
                                    containerClassname=""
                                    inputContainerClassname={"!border-none !rounded-none "}
                                    inputClassName="w-full !px-0 !py-0"
                                />
                                <p className="">{item?.unitOfMeasurement}</p>{" "}
                            </>
                        ) : (
                            <p className=""></p>
                        )}
                    </div>
                ),
                tableDataDivClass: "!pl-0",
            },
            !trackingInfo &&
                !productDetails && {
                    content: (
                        <div className={`w-full border-slate-100 text-sm text-slate-500 `}>
                            {productData?.length > 1 && (
                                <i
                                    className={`ri-delete-bin-line`}
                                    onClick={() => handleDeleteProduct(idx)}
                                ></i>
                            )}
                        </div>
                    ),
                },
        ];
    });

    const tableHeader = [
        { title: "Product SKU/Name", widthClass: "w-[47.%]" },
        { title: "Qty", widthClass: "w-[9.1%]" },
        { title: "Unit price", widthClass: "w-[10.7%]" },
        { title: "Total", widthClass: "w-[14.4%]" },
        { title: "Available stock level", widthClass: "w-[18%]" },
    ];

    const tableBody = products?.map((item, idx) => [
        {
            content: (
                <div
                    className={`py-4 pr-3 flex space-x-2 items-center h-full border-slate-100 text-sm font-rocGroteskMedium`}
                >
                    <p className="text-[#142837] font-rocGroteskMedium pr-3 border-r border-[#E2E8F0] ">
                        {item?.sku}
                    </p>
                    <VendorAvatar
                        name={item?.productName}
                        imageSrc={
                            item?.productImageDetails?.productAvatar ||
                            item?.productImageDetails?.productImages?.[0]
                        }
                        size={32}
                    />
                    <p className="text-sm text-[#142837] font-rocGroteskMedium">
                        {truncate(item?.productName, { length: 30 })}
                    </p>
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-4 pr-3 border-slate-100 text-sm text-gm-50 font-rocGroteskMedium `}
                >
                    {item?.qty}
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-4 pr-3 flex items-center h-full border-slate-100 text-sm font-rocGroteskMedium text-gm-50 `}
                >
                    {getCurrencyFromCurrencyCode(item?.unitPriceCurrency)}
                    {item?.unitPrice?.amount?.toFixed(2)}
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-4 pr-3 flex items-center h-full border-slate-100 text-sm font-rocGroteskMedium text-gm-50 `}
                >
                    {getCurrencyFromCurrencyCode(item?.unitPriceCurrency)}
                    {item?.total?.toFixed(2)}
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-5 pr-3  items-center h-full flex space-x-2 text-sm font-rocGroteskMedium text-slate-900 `}
                >
                    {item?.stockLevel}
                </div>
            ),
        },
    ]);

    return (
        <div>
            <div className="p-4 border border-slate-100 rounded-[8px] bg-white">
                <div
                    onClick={() => navigate(-1)}
                    className="cursor-pointer text-[#3B4C58] space-x-3 w-fit flex items-center py-4"
                >
                    <i className="ri-arrow-left-line text-sm "></i>
                    <p className="text-sm underline  font-rocGroteskMedium">Back</p>
                </div>
                <div className="mb-6 flex justify-between items-center py-4 border-b border-slate-200">
                    <p className="text-[22px] leading-[33px] text-gm-50 font-rocGroteskMedium">
                        {title}
                    </p>
                    {title?.toLowerCase() === "preview" && (
                        <div className="flex items-center space-x-3">
                            <Button
                                onClick={onClickOne}
                                btnClassname="!py-2.5 !border !bg-[#F4F5F7] !border-[#F4F5F7] !px-4 !w-fit !h-10"
                                btnText="Adjust sales order"
                            />
                            <Button
                                onClick={onClickTwo}
                                isLoading={creatingSalesOrder}
                                btnClassname="!py-2.5 !px-8 !w-fit !h-10"
                                btnText="Create sales order"
                            />
                        </div>
                    )}
                    {/* {title?.toLowerCase() !== "preview" && (
                    <div className="flex items-center space-x-3">
                        <Button
                            // onClick={onClickOne}
                            btnClassname="!py-2.5 !border !bg-transparent !border-slate-200 !px-4 !w-fit !h-10"
                            btnText="Edit order"
                        />
                        <Button
                            // onClick={onClickOne}
                            btnClassname="!py-2.5 !border !bg-[#F4F5F7] !border-[#F4F5F7] !px-4 !w-fit !h-10"
                            btnText="Reject order"
                        />
                        <Button
                            // onClick={onClickTwo}
                            btnClassname="!py-2.5 !px-8 !w-fit !h-10"
                            btnText="Process order"
                        />
                    </div>
                )} */}
                </div>

                <div className=" flex space-x-3 ">
                    <div className="w-[74%]  pb-4">
                        <div className="flex items-center justify-between mb-3">
                            <div>
                                <span className="block text-base font-rocGroteskMedium mb-1">
                                    Order details
                                </span>
                            </div>
                            <div className="flex  space-x-3 items-center">
                                <p className="border-r pr-2 border-[#E2E8F0] text-sm font-rocGroteskMedium text-[#64748B]">
                                    Status
                                </p>
                                {title?.toLowerCase() === "preview" ? (
                                    <div className=" space-x-1  flex items-center px-[10px] py-[5px]  bg-[#F1F5F9] w-fit">
                                        <div className="h-2 w-2 bg-[#64748B] rounded-full"></div>
                                        <p>---</p>
                                    </div>
                                ) : (
                                    <div
                                        className={`flex items-center space-x-2 px-4 py-2.5 rounded-md ${
                                            orderStatus?.toLowerCase() === "fulfilled" &&
                                            "bg-light-purple-bg"
                                        }  ${
                                            orderStatus?.toLowerCase() === "pending" &&
                                            "bg-light-yellow-bg"
                                        } ${
                                            orderStatus?.toLowerCase() === "approved" &&
                                            "bg-[#16C6A426]"
                                        }  w-fit h-10`}
                                    >
                                        <span
                                            className={`w-2 h-2 rounded-full ${
                                                orderStatus?.toLowerCase() === "fulfilled" &&
                                                "bg-[#6562E5]"
                                            }  ${
                                                orderStatus?.toLowerCase() === "pending" &&
                                                "bg-[#F59E0B]"
                                            } ${
                                                orderStatus?.toLowerCase() === "approved" &&
                                                "bg-carribean-green"
                                            }`}
                                        />
                                        <span
                                            className={`text-sm font-rocGroteskMedium text-gm-50`}
                                        >
                                            {orderStatus?.charAt(0).toUpperCase() +
                                                orderStatus?.slice(1)}
                                        </span>
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className="grid grid-cols-4 border px-4 rounded items-center justify-between py-6">
                            <div className="">
                                <span className="block text-sm mb-1 font-rocGroteskMedium text-slate-500">
                                    Channel
                                </span>
                                <div className="flex items-center space-x-1.5">
                                    {channelImg === "string" ? (
                                        <img
                                            src={channelImg}
                                            className="max-h-[20px] max-w-[20px]"
                                            alt=""
                                        />
                                    ) : (
                                        channelImg
                                    )}
                                    <span className="block text-base font-rocGroteskMedium">
                                        {channel?.toLowerCase()?.includes("synth")
                                            ? "Synth"
                                            : channel}
                                    </span>
                                </div>
                            </div>

                            <div className="">
                                <span className="block text-sm mb-1 font-rocGroteskMedium text-slate-500">
                                    Customer order number
                                </span>
                                <span className="block text-base font-rocGroteskMedium">
                                    {customerPO}
                                </span>
                            </div>

                            <div className="">
                                <span className="block text-sm mb-1 font-rocGroteskMedium text-slate-500">
                                    Order date
                                </span>
                                <span className="block text-base font-rocGroteskMedium">
                                    {orderDate}
                                </span>
                            </div>

                            <div className="">
                                <span className="block text-sm mb-1 font-rocGroteskMedium text-slate-500">
                                    Delivery date
                                </span>
                                <span className="block text-base font-rocGroteskMedium">
                                    {deliveryDate}
                                </span>
                            </div>
                        </div>

                        <div className="border-b border-dashed border-[#E0E0E0] my-8" />
                        <div className="">
                            <div className="flex items-center justify-between mb-4">
                                <span className="block text-base font-rocGroteskMedium ">
                                    Product details
                                </span>
                            </div>

                            <div className="mb-4">
                                <CustomTable
                                    tableBody={tableBody}
                                    tableHeader={tableHeader}
                                    isAllSelectable={false}
                                    isCellBordered={false}
                                    headerContainerClass="!bg-slate-50  !border-slate-100 "
                                    bodyItemClass="hover:border-slate-100 !border-b hover:bg-slate-50 "
                                    headerItemClass="font-rocGroteskMedium !text-slate-700"
                                    tableClass="!w-fill-available"
                                />
                            </div>
                            <div>
                                <p className="text-sm mb-4 font-rocGroteskMedium text-[#64748B]">
                                    Breakdown
                                </p>
                                <div className="grid grid-cols-3 bg-[#F8FAFC] p-4 rounded items-center justify-between">
                                    <div className="">
                                        <span className="block text-sm mb-1 font-rocGroteskMedium text-slate-500">
                                            Sub total
                                        </span>
                                        <div>
                                            <img
                                                src=""
                                                className="max-h-[20px] max-w-[20px]"
                                                alt=""
                                            />
                                            <span className="block text-base font-rocGroteskMedium">
                                                {subTotal ? Number(subTotal)?.toFixed(2) : ""}
                                            </span>
                                        </div>
                                    </div>

                                    <div className="">
                                        {/* <span className="block text-sm mb-1 font-rocGroteskMedium text-slate-500">
                                            Discount
                                        </span>
                                        <span className="block text-base font-rocGroteskMedium">
                                            {discount}
                                        </span> */}
                                    </div>

                                    <div className="">
                                        <span className="block text-sm mb-1 font-rocGroteskMedium text-slate-500">
                                            Total
                                        </span>
                                        <span className="block text-base font-rocGroteskMedium">
                                            {total ? Number(total)?.toFixed(2) : ""}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="border-b border-dashed border-[#E0E0E0] my-8" />
                        {salesId && channel === "sps-commerce" && (
                            <div>
                                <p className="text-sm mb-4 font-rocGroteskMedium text-g-75">
                                    Tracking information
                                </p>
                                {trackingInfo ? (
                                    <div className="space-y-2 font-rocGroteskMedium border border-[#E2E8F0] p-4 rounded-[4px]">
                                        <div className="flex items-start justify-between  text-base">
                                            <div className="space-y-[11px]">
                                                <div className="flex gap-[11px]">
                                                    <p className="text-[#64748B]">Shipment ID : </p>{" "}
                                                    <span className="text-[#142837]">
                                                        {" "}
                                                        SYN1234{" "}
                                                    </span>
                                                    <p className="text-[#64748B]">
                                                        Shipment ID :{" "}
                                                    </p>{" "}
                                                    <span className="text-[#142837]">
                                                        {" "}
                                                        SYN1234{" "}
                                                    </span>
                                                </div>
                                                <div className="flex gap-[11px]">
                                                    <p className="text-[#142837]">
                                                        123 Warehouse St, NY
                                                    </p>
                                                    <i className="ri-arrow-right-line text-[#64748B]"></i>
                                                    <p className="text-[#142837]">
                                                        456 Customer Rd, IL
                                                    </p>
                                                </div>
                                                <div className="flex gap-[11px]">
                                                    <p className="text-[#64748B]">Carrier :</p>{" "}
                                                    <span className="text-[#142837]"> FedEx </span>
                                                    <p className="text-[#64748B]">BoL :</p>{" "}
                                                    <span className="text-[#142837]">
                                                        {" "}
                                                        BOL5678{" "}
                                                    </span>
                                                </div>
                                                <div className="text-[#142837] flex gap-3 items-center">
                                                    <i className="ri-calendar-todo-line  text-base"></i>
                                                    <p className="">Feb 24, 2024</p>
                                                </div>
                                            </div>
                                            <div className="text-slate-900 text-sm space-y-[10px] text-center">
                                                <p className="">Status</p>
                                                <div className="px-[15px] py-[5px] rounded-[24px] bg-[rgba(249,223,179,0.6)] flex items-center gap-2">
                                                    <p className="w-2 h-2 rounded-full bg-[#F59E0B]"></p>
                                                    <p className="">In transit</p>
                                                </div>
                                            </div>
                                        </div>
                                        <Button
                                            btnText={`${
                                                productDetails
                                                    ? "Hide product details"
                                                    : "View product details"
                                            }`}
                                            btnTextClassName="underline"
                                            btnClassname="text-[13px] !px-0 !bg-transparent !w-fit !text-[#6562E5] lg:!text-[rgba(0,56,129,1)] !rounded-[4px] underline "
                                            onClick={() => setProductDetails(!productDetails)}
                                        />

                                        {productDetails && (
                                            <div className="transition-all ">
                                                <CustomTable
                                                    tableBody={prodTableBody}
                                                    tableHeader={prodTableHeader}
                                                    isScrollable={false}
                                                    isCellBordered={false}
                                                    isCellSelectable={false}
                                                    isAllSelectable={false}
                                                    scrollWithin={false}
                                                    headerContainerClass="!bg-slate-100"
                                                    headerItemClass="font-rocGroteskMedium "
                                                    tableClass=""
                                                    bodyItemClass="hover:bg-transparent"
                                                />
                                            </div>
                                        )}
                                    </div>
                                ) : (
                                    <div className="space-y-2 font-rocGroteskMedium border border-[#E2E8F0] p-4 flex items-center flex-col justify-center rounded-[4px]">
                                        <i className="ri-truck-line text-[#64748B] text-[32px]"></i>
                                        <p className="text-[#000] text-sm">
                                            No shipment has been created for this order
                                        </p>
                                        <p className="text-[#64748B] text-sm">
                                            To fulfill this SPS order, please create a shipment.
                                        </p>
                                        <Button
                                            btnText="Create Shipment"
                                            btnClassname="!px-4 !py-2.5 !w-fit"
                                            onClick={() => setShipmentModal(true)}
                                        />
                                    </div>
                                )}

                                <div className="border-b border-dashed border-[#E0E0E0] my-8" />
                            </div>
                        )}

                        {/* <div>
                        <span className="block text-base font-rocGroteskMedium mb-2">
                            Tracking information
                        </span>
                    </div>
                    <div className="grid grid-cols-3 border px-4 rounded items-center justify-between py-6">
                        <div className="">
                            <span className="block text-sm mb-1 font-rocGroteskMedium text-slate-500">
                                Shipment ID
                            </span>
                            <div>
                                <span className="block text-base font-rocGroteskMedium">---</span>
                            </div>
                        </div>

                        <div className="">
                            <span className="block text-sm mb-1 font-rocGroteskMedium text-slate-500">
                                Tracking number
                            </span>
                            <span className="block text-base font-rocGroteskMedium">---</span>
                        </div>

                        <div className="">
                            <span className="block text-sm mb-1 font-rocGroteskMedium text-slate-500">
                                BoL
                            </span>
                            <span className="block text-base font-rocGroteskMedium">---</span>
                        </div>
                    </div>
                    <div className="border-b border-dashed border-[#E0E0E0] my-8" />
                    <div>
                        <h6 className="text-base font-rocGroteskMedium mb-6">
                            Approval & Processing
                        </h6>
                        <div className="grid grid-cols-2 gap-3">
                            <div className="flex justify-between items-center border border-[#E2E8F0] rounded py-6 px-4">
                                <div>
                                    <p className="text-[16px] leading-[23px] font-rocGroteskMedium text-gm-50">
                                        FIFO Assignment
                                    </p>
                                    <p className="text-sm text-[#64748B] font-rocGroteskMedium mt-0.5">
                                        Assigns lots on a FIFO (First In, First Out) basis.
                                    </p>
                                </div>
                                <i className="ri-check-double-line text-2xl text-[#16C6A4]"></i>
                            </div>
                            <div className="flex justify-between items-center border border-[#E2E8F0] rounded py-6 px-4">
                                <div>
                                    <p className="text-[16px] leading-[23px] font-rocGroteskMedium text-gm-50">
                                        Inventory sync
                                    </p>
                                    <p className="text-sm text-[#64748B] font-rocGroteskMedium mt-0.5">
                                        Updates inventory levels based on the order.
                                    </p>
                                </div>
                                <i className="ri-check-double-line text-2xl text-[#16C6A4]"></i>
                            </div>
                        </div>
                    </div> */}

                        {/* <div>
                            <div>
                                <div className="flex items-center justify-between">
                                    <div className="flex items-center space-x-4">
                                        <div className="w-5 h-5 rounded-full bg-[#00DB8F] flex items-center justify-center">
                                            <i className="ri-checkbox-circle-fill text-white text-[10px]"></i>
                                        </div>
                                        <span className="text-base font-rocGroteskMedium text-g-75">
                                            Order detected by Synth via mail
                                        </span>
                                    </div>
                                    <div className="flex items-center space-x-1">
                                        <span className="text-sm font-rocGroteskMedium text-slate-500">
                                            2 Apr 2024 at 02:28pm UTC
                                        </span>
                                        <i className="ri-arrow-down-s-line text-lg text-gm-50"></i>
                                    </div>
                                </div>
                                <div
                                    className={`pt-3 pb-6 pl-[26px] ml-2.5 border-l border-slate-200`}
                                >
                                    <div className="border border-slate-200 rounded-[6px]   shadow-boxShadow-12">
                                        <div className=" rounded-b-[6px] border-slate-200 px-6 py-3 bg-slate-50 flex items-center space-x-2">
                                            <i className="ri-sparkling-2-fill text-base text-slate-500"></i>
                                            <span className="text-sm font-rocGroteskMedium text-slate-500">
                                                Synth has created a purchase request for you and
                                                sent it to the right team members for approval
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div>
                                <div className="flex items-center justify-between">
                                    <div className="flex items-center space-x-4">
                                        <div className="w-5 h-5 rounded-full bg-[#FEF3C7] flex items-center justify-center">
                                            <i className="ri-time-line text-[#D97706] text-[10px]"></i>
                                        </div>
                                        <span className="text-base font-rocGroteskMedium text-g-75">
                                            Sales order approval
                                        </span>
                                    </div>
                                    <div className="flex items-center space-x-1">
                                        <span className="text-sm font-rocGroteskMedium text-slate-500">
                                            2 Apr 2024 at 02:28pm UTC
                                        </span>
                                        <i className="ri-arrow-down-s-line text-lg text-gm-50"></i>
                                    </div>
                                </div>
                                <div
                                    className={`pt-3 pb-6 pl-[26px] ml-2.5 border-l border-slate-200`}
                                >
                                    <div className="border border-slate-200 rounded-[6px] font-rocGroteskMedium shadow-boxShadow-12 flex items-center justify-between px-6 py-4">
                                        <div className={``}>
                                            <span className="text-sm mb-1 block">
                                                Review and approve sales orders
                                            </span>
                                            <p className="text-slate-500 text-[13px] ">
                                                Once the sales order is created, it will be
                                                forwarded to the appropriate team member for
                                                approval.
                                            </p>
                                        </div>

                                        <span className="text-[13px] bg-[#FEF3C7] text-[#E4A500] px-3 py-2.5 h-10 rounded-[30px]">
                                            Awaiting approval
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div>
                                <div className="flex items-center justify-between">
                                    <div className="flex items-center space-x-4">
                                        <div className="w-5 h-5 rounded-full bg-[#FEF3C7] flex items-center justify-center">
                                            <i className="ri-time-line text-[#D97706] text-[10px]"></i>
                                        </div>
                                        <span className="text-base font-rocGroteskMedium text-g-75">
                                            Order fulfillment
                                        </span>
                                    </div>
                                    <div className="flex items-center space-x-1">
                                        <i className="ri-arrow-down-s-line text-lg text-gm-50"></i>
                                    </div>
                                </div>
                                <div
                                    className={`pt-3 pb-6 pl-[26px] ml-2.5 border-l border-slate-200`}
                                >
                                    <div className="border border-slate-200 rounded-[6px] font-rocGroteskMedium shadow-boxShadow-12 flex items-center justify-between px-6 py-4">
                                        <div className={``}>
                                            <span className="text-sm mb-1 block">
                                                We're expecting more details about the order’s
                                                status. Please update it on the order list page
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div>
                                <div className="flex items-center justify-between">
                                    <div className="flex items-center space-x-4">
                                        <div className="w-5 h-5 rounded-full bg-[#FEF3C7] flex items-center justify-center">
                                            <i className="ri-time-line text-[#D97706] text-[10px]"></i>
                                        </div>
                                        <span className="text-base font-rocGroteskMedium text-g-75">
                                            Inventory adjustment
                                        </span>
                                    </div>
                                    <div className="flex items-center space-x-1">
                                        <i className="ri-arrow-down-s-line text-lg text-gm-50"></i>
                                    </div>
                                </div>
                                <div
                                    className={`pt-3 pb-6 pl-[26px] ml-2.5 border-l border-slate-200`}
                                >
                                    <div className="border border-slate-200 rounded-[6px] font-rocGroteskMedium shadow-boxShadow-12 flex items-center justify-between px-6 py-4">
                                        <div className={``}>
                                            <span className="text-sm mb-1 block">
                                                After the order has been fulfilled, Synth will
                                                automatically adjust the products in your inventory
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>

                    <div className="w-[26%]  border border-[#E2E8F0] p-4">
                        {/* {title.toLowerCase() !== "preview" && orderStatus === "pending" && (
                            <div className="space-y-3">
                                <Button
                                    onClick={onApprove}
                                    btnClassname="!py-2.5 !px-8 !h-10"
                                    btnText="Approve quote"
                                />
                                <Button
                                    onClick={onReject}
                                    btnClassname="!py-2.5 !border !bg-[#F4F5F7] !border-[#F4F5F7] !px-4 !h-10"
                                    btnText="Reject quote"
                                />
                                <div className="border-b border-dashed border-[#E0E0E0] my-5" />
                            </div>
                        )} */}
                        {/* {title.toLowerCase() !== "preview" && (
                        <>
                            <div className="flex items-center mt-5 space-x-5">
                                <div className="border-r pr-8">
                                    <p className="text-sm text-[#64748B] font-rocGroteskMedium leading-[20.6px]">
                                        Profit margin
                                    </p>
                                    <p className="text-sm font-rocGroteskMedium text-gm-50">25%</p>
                                </div>
                                <div>
                                    <p className="text-sm text-[#64748B] font-rocGroteskMedium leading-[20.6px]">
                                        Currency
                                    </p>
                                    <p className="text-sm font-rocGroteskMedium text-gm-50">USD</p>
                                </div>
                            </div>
                            <div className="border-b border-dashed border-[#E0E0E0] my-5" />
                        </>
                    )} */}
                        {/* {title.toLowerCase() === "preview" && (
                        <>
                            <div className="">
                                <span className="text-sm block font-rocGroteskMedium text-slate-500 mb-1.5">
                                    Currency
                                </span>
                                <span className="text-[16px] leading-[23px]  text-gm-50 font-rocGroteskMedium">
                                    USD
                                </span>
                            </div>
                            <div className="border-b border-dashed border-[#E0E0E0] my-5" />
                        </>
                    )} */}

                        <div className="mb-4">
                            <span className="text-base text-gm-50 block font-rocGroteskMedium mb-3">
                                Customer details
                            </span>
                            <div className="space-y-2">
                                <div className="flex items-center space-x-3">
                                    <VendorAvatar name="avatar" size={24} />
                                    <span className="text-base font-rocGroteskMedium">
                                        {customerName}
                                    </span>
                                </div>

                                <div className="flex items-center space-x-3">
                                    <i className="ri-mail-line text-slate-400 text-lg"></i>
                                    <span className="text-base  text-gm-50 font-rocGroteskMedium">
                                        {email}
                                    </span>
                                </div>

                                <div className="flex items-center space-x-3">
                                    <i className="ri-phone-line text-slate-400 text-lg"></i>
                                    <span className="text-base text-gm-50 font-rocGroteskMedium">
                                        {phone}
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div className="">
                            <span className="text-sm block font-rocGroteskMedium text-slate-500 mb-1.5">
                                Shipping destination
                            </span>
                            <span className="text-[15px]  text-gm-50 font-rocGroteskMedium">
                                {billAddress}
                            </span>
                        </div>

                        <div className="border-b border-dashed border-[#E0E0E0] my-6" />
                        {/* <div>
                        <span className="text-base text-gm-50 block font-rocGroteskMedium mb-3">
                            Fulfilment & Shipping
                        </span>
                        <div className="mb-2">
                            <span className="text-sm block font-rocGroteskMedium text-slate-500 mb-1">
                                3PL
                            </span>
                            <p className="flex items-center space-x-2">
                                <i className="ri-add-fill text-2xl mt-[-2px]"></i>
                                <span className="text-[16px] leading-[23px] underline text-gm-50 font-rocGroteskMedium">
                                    Add 3PL
                                </span>
                            </p>
                        </div>
                        <div className="">
                            <span className="text-sm block font-rocGroteskMedium text-slate-500 mb-1">
                                In-Country Haulauge
                            </span>
                            <p className="flex items-center space-x-2">
                                <i className="ri-add-fill text-2xl mt-[-2px]"></i>
                                <span className="text-[16px] leading-[23px] underline text-gm-50 font-rocGroteskMedium">
                                    Add Haulage Partner
                                </span>
                            </p>
                        </div>
                    </div> */}
                        {/* <div className="border-b border-dashed border-[#E0E0E0] my-6" /> */}

                        {/* <div>
                        <span className="text-sm text-gm-50 block font-rocGroteskMedium mb-4">
                            Approval levels
                        </span>
                        <div className="space-y-4">
                            <div className="pb-4 border-b border-slate-100">
                                <div className="flex items-center space-x-2 mb-2">
                                    <VendorAvatar name="avatar" size={28} />
                                    <div className="font-rocGroteskMedium">
                                        <span className="block text-[13px] ">
                                            Garrett - Operations
                                        </span>
                                        <span className="block text-[13px] text-slate-500">
                                            April 17, 2023; 2:00pm
                                        </span>
                                    </div>
                                </div>

                                <span className="text-xs font-rocGroteskMedium px-3 py-1 bg-slate-100 rounded w-fit flex items-center justify-center">
                                    Level 1
                                </span>
                            </div>

                            <div className="pb-4 border-b border-slate-100">
                                <div className="flex items-center space-x-2 mb-2">
                                    <VendorAvatar name="avatar" size={28} />
                                    <div className="font-rocGroteskMedium">
                                        <span className="block text-[13px] ">
                                            Garrett - Operations
                                        </span>
                                        <span className="block text-[13px] text-slate-500">
                                            April 17, 2023; 2:00pm
                                        </span>
                                    </div>
                                </div>

                                <span className="text-xs font-rocGroteskMedium px-3 py-1 bg-slate-100 rounded w-fit flex items-center justify-center">
                                    Level 2
                                </span>
                            </div>
                        </div>
                    </div> */}
                    </div>
                </div>
            </div>

            <ModalContainer
                open={shipmentModal}
                showCloseIcon={false}
                modalClassName={"py-10 px-10"}
                tailwindClassName="w-[1017px] max-h-[90vh]"
                // closeModal={() => {
                //     setShipmentModal(false);
                // }}
            >
                <form
                    onSubmit={(e) => {
                        e.preventDefault();
                        handleShipmentTracking();
                    }}
                    className="bg-white !px-2 p-8  rounded-[8px] font-rocGroteskMedium"
                >
                    <div className="mt-4 px-6">
                        <div className="flex flex-col justify-center items-center mb-6">
                            <p className="text-[#042821] text-2xl">Add shipment for SPS</p>
                            <p className="text-[#62707A] text-sm">
                                Kindly add your shipment to the respective PO
                            </p>
                        </div>

                        <div className="space-y-4">
                            <p className="text-base text-[#62707A] mb-4">Shipment details</p>
                            <div className="grid grid-cols-2 gap-4">
                                <TextInput
                                    value={shipmentData?.shipmentId || ""}
                                    name={"shipmentId"}
                                    type={"text"}
                                    onChange={handleShipmentData}
                                    placeholder={"Shipment Id"}
                                    required={true}
                                />
                                <DateInput
                                    label="Date"
                                    value={shipmentData?.shipmentDate}
                                    onChange={(newDate) => handleDateChange(newDate)}
                                    showCalendar={date}
                                    onCalendarToggle={setDate}
                                    required={true}
                                    calendarClassname="!right-0 "
                                    borderClassname="!border-gm-25"
                                />

                                <AddressInput
                                    setData={setOrigin}
                                    savedAddress={origin?.origin ? origin?.origin?.address : ""}
                                    placeholder="Origin"
                                    name={"origin"}
                                    required={true}
                                />

                                <AddressInput
                                    setData={setDestination}
                                    savedAddress={
                                        destination?.destination
                                            ? destination?.destination?.address
                                            : ""
                                    }
                                    placeholder="Destination"
                                    name={"destination"}
                                    required={true}
                                />
                            </div>
                        </div>
                        <div className="border border-dashed border-[#E0E0E0] mt-4"></div>

                        <div className="space-y-4">
                            <p className="text-base text-[#62707A] my-4">Mode</p>

                            <SelectInput
                                value={shipmentData?.shipmentMode}
                                name="shipmentMode"
                                placeholder={"Select mode"}
                                handleChange={(name, value) =>
                                    handleShipmentData({ target: { name, value } })
                                }
                                isRequired={true}
                                optionItemContainerClassName={"hover:bg-slate-100"}
                                dropdownOptions={[
                                    {
                                        label: (
                                            <div className="font-rocGroteskMedium items-center -mt-2 flex space-x-1">
                                                <i className="ri-ship-line"></i>

                                                <p className="text-gm-50 text-xs  font-rocGroteskMedium">
                                                    Ocean
                                                </p>
                                            </div>
                                        ),
                                        value: "ocean",
                                    },
                                    {
                                        label: (
                                            <div className="font-rocGroteskMedium items-center -mt-2 flex space-x-1">
                                                <i className="ri-plane-fill"></i>

                                                <p className="text-gm-50 text-xs  font-rocGroteskMedium">
                                                    Air
                                                </p>
                                            </div>
                                        ),
                                        value: "air",
                                    },
                                    {
                                        label: (
                                            <div className="font-rocGroteskMedium items-center -mt-2 flex space-x-1">
                                                <i className="ri-truck-line"></i>

                                                <p className="text-gm-50 text-xs  font-rocGroteskMedium">
                                                    Land
                                                </p>
                                            </div>
                                        ),
                                        value: "land",
                                    },
                                ]}
                            />

                            <div className="grid grid-cols-2 gap-4">
                                <TextInput
                                    value={shipmentData?.trackingLink || ""}
                                    name={"trackingLink"}
                                    type={"text"}
                                    onChange={handleShipmentData}
                                    placeholder={"Tracking link"}
                                    required={true}
                                />
                                <TextInput
                                    value={shipmentData?.billOfLading || ""}
                                    name={"billOfLading"}
                                    type={"text"}
                                    onChange={handleShipmentData}
                                    required={true}
                                    placeholder={"Bill of lading"}
                                    // inputClassName={`${
                                    //     stockData?.batchId && "!border-g-60 !border-[0.5px] !border-b-[1px]"
                                    // }`}
                                />
                            </div>
                        </div>
                        <div className="border border-dashed border-[#E0E0E0] mt-4"></div>

                        <div className="space-y-4">
                            <p className="text-base text-[#000] mt-4">Product details</p>
                            <p className="text-sm text-[#64748B]">
                                Kindly include the products you wish to ship in your shipment
                            </p>

                            {productData?.length > 0 && (
                                <div className="">
                                    <div className="">
                                        <CustomTable
                                            tableBody={prodTableBody}
                                            tableHeader={prodTableHeader}
                                            isScrollable={false}
                                            isCellBordered={false}
                                            isCellSelectable={false}
                                            isAllSelectable={false}
                                            scrollWithin={false}
                                            headerContainerClass="!bg-slate-100"
                                            headerItemClass="font-rocGroteskMedium "
                                            tableClass=""
                                        />
                                    </div>
                                </div>
                            )}

                            <div className="flex items-center gap-3 mt-6">
                                <Button
                                    btnText="Add product"
                                    btnTextClassName="underline"
                                    btnClassname="text-[13px] !px-2.5 !bg-transparent !w-fit text-slate-500 lg:!text-[rgba(0,56,129,1)] !rounded-[4px] "
                                    onClick={handleAddProduct}
                                    icon={<i className="ri-add-circle-line text-slate-500"></i>}
                                />
                            </div>
                        </div>
                        <div className="border border-dashed border-[#E0E0E0] mt-4"></div>

                        <div className="mt-4">
                            <label className="text-base text-[#62707A] font-rocGroteskMedium">
                                Additional notes
                            </label>
                            <div className="mt-4">
                                <TextareaInput
                                    name={"notes"}
                                    value={shipmentData?.notes || ""}
                                    onChange={handleShipmentData}
                                    rows={3}
                                    placeholder={"Notes"}
                                    required={false}
                                    // inputContainerClassname={`${
                                    //     stockData?.notes &&
                                    //     "!border-g-60 !border-[0.5px] !border-b-[1px]"
                                    // }`}
                                />
                            </div>
                        </div>

                        <div className="mt-5 flex justify-end gap-3">
                            <Button
                                btnText={"Cancel"}
                                type={"button"}
                                btnClassname={"!bg-n-20 !text-gm-50 !w-fit"}
                                isLoading={false}
                                onClick={() => {
                                    setShipmentModal(false);
                                    setShipmentData({});
                                    setProductData([{}]);
                                    setOrigin({});
                                    setDestination({});
                                }}
                                disabled={creatingSalesShipment}
                            />
                            <Button
                                // onClick={() => handleSave()}
                                btnText={"Add Shipment"}
                                type={"submit"}
                                btnClassname={" !w-fit"}
                                isLoading={creatingSalesShipment}
                                disabled={creatingSalesShipment}
                            />
                        </div>
                    </div>
                </form>
            </ModalContainer>
        </div>
    );
};

export default SalesOrderDetail;
