import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../../../components/common/Button";
import TabsContainer from "../../../components/common/TabsContainer";
import TextInput from "../../../components/common/InputField/TextInput";
import CustomTable from "../../../components/common/CustomTable";
import BOM from "../../../components/views/Synth2.0/Production/ProductionPlanDetails/BOM";
import CostBreakdown from "../../../components/views/Synth2.0/Production/ProductionPlanDetails/CostBreakdown";
import AdditionalInfo from "../../../components/views/Synth2.0/Production/ProductionPlanDetails/AdditionalInfo";
import ApprovalWorkflow from "../../../components/views/Synth2.0/Production/ProductionPlanDetails/ApprovalWorkflow";
import LinkedPurchaseOrder from "../../../components/views/Synth2.0/Production/ProductionPlanDetails/LinkedPurchaseOrder";
import ProductionWorkflow from "../../../components/views/Synth2.0/Production/ProductionPlanDetails/ProductionWorkflow";
import ModalContainer from "components/common/ModalContainer";
import TextareaInput from "components/common/InputField/TextareaInput";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import PageFrame from "components/layout/PageFrame";
import { productionActions } from "redux/Ldb/actions";
import moment from "moment";
import useGetSingleProductionPlan from "hooks/ProductionHooks/useGetSingleProductionPLan";
import useGetProductionPlanTab from "hooks/ProductionHooks/useGetProductionPlanTab";

const ProductionPlanDetails = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { productionId } = useParams();

    const [rejectPlan, setRejectPlan] = useState<boolean>(false);
    const [approvePlan, setApprovePlan] = useState<boolean>(false);
    const [activeTab, setActiveTab] = useState(0);

    const { data: planDetails, isLoading } = useGetSingleProductionPlan();
    const {
        data: tabDetails,
        isLoading: tabLoading,
        setQuery,
        debouncedSearch,
        setDebouncedSearch,
        handleDebouncedSearch,
        search,
        setSearch,
        handleSearch,
        isLoadingMore,
        pagination,
    } = useGetProductionPlanTab();

    const tabs = [
        "Bill of Materials (BOM)",
        "Linked purchase orders",
        "Cost breakdown",
        "Production workflow",
        "Additional info",
        "Approval workflow",
    ];

    useEffect(() => {
        if (activeTab === 0) {
            setQuery("bom");
        }
        if (activeTab === 1) {
            setQuery("purchase_orders");
        } else if (activeTab === 3) {
            setQuery("steps");
        }
    }, [activeTab]);

    const displayActiveTab = () => {
        switch (activeTab) {
            case 0:
                return (
                    <BOM
                        bomDetails={tabDetails}
                        productName={planDetails?.inventory?.productName}
                        isLoading={tabLoading}
                        debouncedSearch={debouncedSearch}
                        handleDebouncedSearch={handleDebouncedSearch}
                        handleSearch={handleSearch}
                        search={search}
                        isLoadingMore={isLoadingMore}
                        pagination={pagination}
                    />
                );
            case 1:
                return (
                    <LinkedPurchaseOrder
                        poDetails={tabDetails}
                        productName={planDetails?.inventory?.productName}
                        isLoading={tabLoading}
                    />
                );
            case 2:
                return (
                    <CostBreakdown
                        directCost={planDetails?.directCosts}
                        inDirectCost={planDetails?.indirectCosts}
                        productName={planDetails?.inventory?.productName}
                    />
                );
            case 3:
                return (
                    <ProductionWorkflow
                        steps={tabDetails}
                        productName={planDetails?.inventory?.productName}
                        isLoading={tabLoading}
                    />
                );
            case 4:
                return <AdditionalInfo notes={planDetails?.notes} />;
            case 5:
                return <ApprovalWorkflow />;
            default:
                return (
                    <BOM
                        bomDetails={tabDetails}
                        productName={planDetails?.inventory?.productName}
                        isLoading={tabLoading}
                        debouncedSearch={debouncedSearch}
                        handleDebouncedSearch={handleDebouncedSearch}
                        handleSearch={handleSearch}
                        search={search}
                        isLoadingMore={isLoadingMore}
                        pagination={pagination}
                    />
                );
        }
    };

    return (
        <PageFrame isLoading={isLoading}>
            <div className="bg-white rounded-lg border border-slate-100 font-rocGroteskMedium pb-12">
                <div className="flex items-center justify-between px-8 py-6 border-b border-n-20">
                    <div
                        className="flex items-center space-x-1.5 w-fit cursor-pointer"
                        onClick={() => navigate(-1)}
                    >
                        <i className="ri-arrow-left-line text-[20px]"></i>
                        <p className="text-sm text-g-75 font-rocGroteskMedium underline">Back</p>
                    </div>

                    <div className="flex items-center  gap-3">
                        <div className="flex items-center gap-2">
                            <div
                                className={`w-2 h-2 rounded-full shrink-0 flex items-center justify-center captialize
                                   ${
                                       planDetails?.productionStatus === "pending"
                                           ? "bg-[#6366F1]"
                                           : planDetails?.productionStatus === "completed"
                                           ? "bg-[#F97316]"
                                           : planDetails?.productionStatus === "inProgress"
                                           ? "bg-[#3B82F6]"
                                           : planDetails?.productionStatus === "cancelled"
                                           ? "bg-[#EF4444]"
                                           : planDetails?.productionStatus === "onhold"
                                           ? "bg-[#EAB308]"
                                           : planDetails?.productionStatus === "rescheduled"
                                           ? "bg-[#8B5CF6]"
                                           : "bg-[#6B7280]"
                                   }
                                    `}
                            ></div>

                            <span className="capitalize">
                                {planDetails?.productionStatus === "inProgress"
                                    ? "In progress"
                                    : planDetails?.productionStatus === "onHold"
                                    ? "On hold"
                                    : planDetails?.productionStatus}
                            </span>
                        </div>
                        <Button
                            btnText="Close"
                            btnClassname="!px-4 !py-2 !bg-slate-100 !text-g-75 !w-fit text-[13px]"
                            // disabled={}
                            onClick={() => navigate(-1)}
                        />
                        <Button
                            btnText="Edit"
                            type="submit"
                            onClick={() =>
                                navigate(`/dashboard/production/edit-plan/${productionId}`)
                            }
                            btnClassname="!px-4 !py-2 !text-g-75 !w-fit text-[13px]"
                        />
                    </div>
                </div>

                <div className="px-6">
                    <div className="py-6 border-b border-slate-200">
                        <p className="text-gm-50 text-sm">Production Plan ID</p>
                        <h6 className="text-g-75 text-2xl">
                            {planDetails?.taId && `PP-${planDetails?.taId}`}
                        </h6>
                        {/* <div className="flex gap-2 mt-4">
                            <Button
                                btnText="Approve production plan"
                                btnClassname="!px-4 !py-2 !text-000 !w-fit text-[13px]"
                                // disabled={}
                                onClick={() => setApprovePlan(true)}
                            />
                            <Button
                                btnText="Reject production plan"
                                type="submit"
                                // disabled={}
                                onClick={() => setRejectPlan(true)}
                                btnClassname="!px-4 !py-2 !bg-[#FEF2F2] !text-[#FF3D1D] !w-fit text-[13px]"
                            />
                        </div> */}
                    </div>

                    <div className="">
                        <div className="grid grid-cols-5 py-6 border-b border-slate-200">
                            <div className="">
                                <p className="text-slate-500 text-sm">Product</p>
                                <p className="text-gm-50 text-sm">
                                    {planDetails?.inventory?.productName}
                                </p>
                            </div>
                            <div className="">
                                <p className="text-slate-500 text-sm">Production Quantity</p>
                                <p className="text-gm-50 text-sm">
                                    {planDetails?.quantity?.amount}
                                </p>
                            </div>
                            <div className="">
                                <p className="text-slate-500 text-sm">Production Start Date:</p>
                                <p className="text-gm-50 text-sm">
                                    {planDetails?.startDate
                                        ? moment(planDetails?.startDate)?.format("MMM DD, YYYY")
                                        : "N/A"}
                                </p>
                            </div>
                            <div className="">
                                <p className="text-slate-500 text-sm">Estimated Completion Date</p>
                                <p className="text-gm-50 text-sm">
                                    {" "}
                                    {planDetails?.endDate
                                        ? moment(planDetails?.endDate)?.format("MMM DD, YYYY")
                                        : "N/A"}
                                </p>
                            </div>
                            <div className="">
                                <p className="text-slate-500 text-sm">Quality Control Supervisor</p>
                                <p className="text-gm-50 text-sm">
                                    {planDetails?.qualityController?.email || "N/A"}
                                </p>
                            </div>
                        </div>
                        <div className="grid grid-cols-5 py-6 ">
                            <div className="">
                                <p className="text-slate-500 text-sm">Due Date</p>
                                <p className="text-gm-50 text-sm">
                                    {planDetails?.dueDate
                                        ? moment(planDetails?.dueDate)?.format("MMM DD, YYYY")
                                        : "N/A"}
                                </p>
                            </div>
                            <div className="">
                                <p className="text-slate-500 text-sm">Created by</p>
                                <p className="text-gm-50 text-sm">
                                    {planDetails?.createdBy?.fullName ||
                                        planDetails?.createdBy?.email}
                                </p>
                            </div>
                            <div className="">
                                <p className="text-slate-500 text-sm">Production Manager</p>
                                <p className="text-gm-50 text-sm">
                                    {planDetails?.manager?.email || planDetails?.manager?.name}
                                </p>
                            </div>
                            <div className="">
                                <p className="text-slate-500 text-sm">Batch Number</p>
                                <p className="text-gm-50 text-sm">{planDetails?.batchId}</p>
                            </div>
                        </div>
                    </div>

                    <TabsContainer
                        tabs={tabs}
                        activeTab={activeTab}
                        setActiveTab={(idx) => {
                            setActiveTab(idx);
                        }}
                        component={displayActiveTab()}
                        className="!px-0"
                        showButtonClassName
                        itemClassName="!pb-2 !whitespace-nowrap !border-y-10"
                        borderLineClase={"!text-slate-100 w-full mb-6"}
                    />
                </div>

                <ModalContainer
                    open={rejectPlan}
                    modalClassName={"py-10 px-10"}
                    tailwindClassName="w-[600px]"
                    showCloseIcon={false}
                    closeModal={() => {
                        setRejectPlan(true);
                    }}
                >
                    <div className="bg-white rounded-[8px] relative">
                        <i
                            className="ri-close-fill cursor-pointer text-gm-50 text-xl absolute  top-4 right-4"
                            onClick={() => {
                                setRejectPlan(false);
                            }}
                        ></i>
                        <div className="mt-4 ">
                            <div className="text-center flex justify-center flex-col items-center px-8 pt-10 mb-8">
                                <div className="mb-1">
                                    <i className="ri-chat-check-line text-gm-50 text-[40px]"></i>
                                </div>
                                <div className="px-4 font-rocGroteskMedium">
                                    <span className="block mb-2 text-center text-[22px] text-gm-50">
                                        Approve Product Plan?
                                    </span>
                                    <p className="mt-1 text-base text-slate-500 text-center ">
                                        Your approval will move this plan to the next level for
                                        review. The next approver will be notified.
                                    </p>
                                </div>
                            </div>

                            <div className="px-6 pb-6">
                                <TextareaInput
                                    name={"comment"}
                                    // value={}
                                    // onChange={handleProductData}
                                    rows={5}
                                    placeholder={"Comment"}
                                    required={true}
                                />
                            </div>
                            <div className="border-t border-t-slate-200 py-4 px-6 flex items-center space-x-4">
                                <Button
                                    btnText={"Cancel"}
                                    btnClassname="!py-3 cursor-pointer !text-[14px] !bg-n-20 !text-g-75 !border border-n-20 !font-rocGroteskMedium !px-4"
                                    onClick={() => {
                                        setRejectPlan(false);
                                    }}
                                />
                                <Button
                                    btnText={"Approve Production Plan"}
                                    btnClassname="!py-3 cursor-pointer !text-[14px] !text-g-75 !border border-n-20 !font-rocGroteskMedium !px-4"
                                    onClick={() => {}}
                                />
                            </div>
                        </div>
                    </div>
                </ModalContainer>
                <ModalContainer
                    open={approvePlan}
                    modalClassName={"py-10 px-10"}
                    tailwindClassName="w-[600px]"
                    showCloseIcon={false}
                    closeModal={() => {
                        setApprovePlan(true);
                    }}
                >
                    <div className="bg-white rounded-[8px] relative">
                        <i
                            className="ri-close-fill cursor-pointer text-gm-50 text-xl absolute  top-4 right-4"
                            onClick={() => {
                                setApprovePlan(false);
                            }}
                        ></i>
                        <div className="mt-4 ">
                            <div className="text-center flex justify-center flex-col items-center px-8 pt-10 mb-8">
                                <div className="mb-1">
                                    <i className="ri-feedback-line text-gm-50 text-[40px]"></i>
                                </div>
                                <div className="px-4 font-rocGroteskMedium">
                                    <span className="block mb-2 text-center text-[22px] text-gm-50">
                                        Production Plan Rejected
                                    </span>
                                    <p className="mt-1 text-base text-slate-500 text-center ">
                                        The production plan for Kōloa Rum has been rejected. Please
                                        review the feedback provided to understand the reasons.
                                    </p>
                                </div>
                            </div>

                            <div className="px-6 pb-6">
                                <TextareaInput
                                    name={"comment"}
                                    // value={}
                                    // onChange={handleProductData}
                                    rows={5}
                                    placeholder={"Comment"}
                                    required={true}
                                />
                            </div>
                            <div className="border-t border-t-slate-200 py-4 px-6 flex items-center space-x-4">
                                <Button
                                    btnText={"Cancel"}
                                    btnClassname="!py-3 cursor-pointer !text-[14px] !bg-n-20 !text-g-75 !border border-n-20 !font-rocGroteskMedium !px-4"
                                    onClick={() => {
                                        setApprovePlan(false);
                                    }}
                                />
                                <Button
                                    btnText={"Reject Production Plan"}
                                    btnClassname="!py-3 cursor-pointer !text-[14px] !text-white !border border-n-20 !font-rocGroteskMedium !px-4 !bg-[#FF3D1D]"
                                    onClick={() => {}}
                                />
                            </div>
                        </div>
                    </div>
                </ModalContainer>
            </div>
        </PageFrame>
    );
};

export default ProductionPlanDetails;
