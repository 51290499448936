import CustomTable from "components/common/CustomTable";
import VendorAvatar from "components/common/VendorAvatar";
import { truncate } from "lodash";
import React from "react";

const Purchase = () => {
    const tableHeader = [
        { title: "Product ID", widthClass: "w-[9.9%%]" },
        { title: "Supplier", widthClass: "w-[23.2%]" },
        { title: "Created by", widthClass: "w-[15.9%]" },
        { title: "Est. Date", widthClass: "w-[15.9%]" },
        { title: "Quantity", widthClass: "w-[9.9%]" },
        { title: "No. of shipments", widthClass: "w-[9.9%]" },
        { title: "Status", widthClass: "w-[15.2%]" },
    ];

    const tableBody = [1, 2, 3, 4, 5]?.map((salesData, idx) => [
        {
            content: (
                <div
                    className={`py-3 pr-3 flex font-rocGroteskMedium items-center h-full border-slate-100 text-sm text-slate-800 `}
                >
                    <span>P-0123</span>
                </div>
            ),
            // cellClickAction: () => {
            //     navigate(`/dashboard/inventory/products/${salesData?.products?.inventory?._id}`);
            // },
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-800 `}
                >
                    <div className="flex items-center space-x-3">
                        <VendorAvatar imageSrc={""} size={32} name={"Intercom"} />
                        <div>
                            <span className="block text-sm font-rocGroteskMedium text-slate-500">
                                {truncate("salesData?.products?.inventory?.productName", {
                                    length: 18,
                                })}
                            </span>
                        </div>
                    </div>
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-800 `}
                >
                    <div className="flex items-center space-x-3">
                        <VendorAvatar imageSrc={""} size={24} name={"Intercom"} />
                        <div>
                            <span className="block text-sm font-rocGroteskMedium text-slate-500">
                                {truncate("salesData?.products?.inventory?.productName", {
                                    length: 18,
                                })}
                            </span>
                        </div>
                    </div>
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-800 `}
                >
                    <span>Apr 17, 2024</span>
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-800 `}
                >
                    <span>50 lbs</span>
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-800 `}
                >
                    <span>5</span>
                </div>
            ),
        },
        {
            content: (
                <div
                    className={`py-3 pr-3 font-rocGroteskMedium border-slate-100 text-sm text-slate-800 `}
                >
                    <div className={` rounded !w-fit px-3 py-1 flex items-center space-x-2`}>
                        <span className={`h-2 w-2 bg-g-55 rounded-full`}></span>
                        <span className="font-rocGroteskMedium text-gm-50">Approved</span>
                    </div>
                </div>
            ),
        },
    ]);

    return (
        <div>
            <CustomTable
                tableBody={tableBody}
                tableHeader={tableHeader}
                isScrollable={false}
                isCellBordered={false}
                isCellSelectable={false}
                isAllSelectable={false}
                headerContainerClass="!bg-slate-50"
                headerItemClass="font-rocGroteskMedium "
                tableClass="mb-6"
            />
        </div>
    );
};

export default Purchase;
