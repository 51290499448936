import React, { useState } from "react";
import Checkbox from "./InputField/Checkbox";
import Skeleton from "react-loading-skeleton";

type CustomTableProps = {
    tableBody?: {
        content: React.ReactNode;
        widthClass?: string;
        tableDataDivClass?: string;
        cellClickAction?: (e?: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => void;
        isItemChecked?:
            | boolean
            | {
                  [key: string]: any;
              };
        checkItemIsDisabled?: boolean;
        onCheckBoxClick?: () => void;
    }[][];
    tableHeader: {
        title: React.ReactNode | string;
        titleClass?: string;
        widthClass?: string;
        itemClass?: string;
        isAllChecked?: boolean;
        checkAllIsDisabled?: boolean;
        onSelectAll?: () => void;
        sortIcon?: React.ReactNode;
    }[];
    tbodyClass?: string;
    isAllSelectable?: boolean;
    isCellSelectable?: boolean;
    isScrollable?: boolean;
    headerContainerClass?: string;
    headerItemClass?: string;
    bodyItemClass?: string;
    tableClass?: string;
    isCellBordered?: boolean;
    isScrolled?: boolean;
    inventoryHistoryTable?: boolean;
    leftFixedColumnCount?: number;
    rightFixedColumnCount?: number;
    leftFixedColumnClass?: string[];
    rightFixedColumnClass?: string[];
    noTableBody?: React.ReactElement | null;
    stickyHeader?: boolean;
    sort?: (idx: number, value: number) => void;
    scrollWithin?: boolean;
    isInifiniteScroll?: boolean;
    tableContainerClass?: string;
    isGrouped?: boolean;
    groupedData?: {
        groupContent: JSX.Element;
        cellClickAction?: () => void;
        groupContentClass?: string;
        associatedSKUs: any[];
    }[];
};

const CustomTable = ({
    tableBody,
    tableHeader,
    tbodyClass,
    isAllSelectable,
    isCellSelectable,
    isScrollable,
    headerContainerClass,
    headerItemClass,
    bodyItemClass,
    tableClass,
    isCellBordered,
    isScrolled,
    inventoryHistoryTable,
    leftFixedColumnCount,
    rightFixedColumnCount,
    leftFixedColumnClass,
    rightFixedColumnClass,
    noTableBody,
    stickyHeader,
    sort,
    scrollWithin = true,
    isInifiniteScroll = true,
    tableContainerClass,
    isGrouped,
    groupedData,
}: CustomTableProps) => {
    return (
        <div
            id={isInifiniteScroll ? "tableContent" : ""}
            className={`${
                isScrollable ? "overflow-x-scroll custom-x-scrollbar" : "overflow-x-hidden"
            } w-full h-full relative ${
                scrollWithin
                    ? `max-h-[600px] overflow-y-scroll custom-y-scrollbar  ${tableContainerClass}`
                    : "contents"
            }`}
        >
            <table
                className={`table-fixed  relative ${
                    isScrollable && "border-separate border-spacing-0"
                } bg-white w-full ${tableClass} rounded-[6px]  ${
                    inventoryHistoryTable ? "border-none" : "border border-slate-100"
                }`}
            >
                <thead className={`sticky top-0 left-0 right-0 z-[1100]`}>
                    <tr className={`text-left bg-white ${headerContainerClass} `}>
                        {tableHeader?.map((header, idx) => (
                            <th
                                key={(header?.title as string) + idx}
                                className={`${header?.widthClass} ${
                                    isCellBordered && "border-r border-b"
                                } border-slate-100 ${
                                    (leftFixedColumnCount
                                        ? idx <= leftFixedColumnCount - 1
                                        : idx === 0) &&
                                    isScrollable &&
                                    "sticky left-0 top-0 bg-inherit z-[1000]" +
                                        " " +
                                        (idx <= (leftFixedColumnCount as number) - 1 &&
                                            (leftFixedColumnClass as string[])?.length > 0 &&
                                            (leftFixedColumnClass as string[])[idx])
                                } ${
                                    (rightFixedColumnCount
                                        ? idx >= tableHeader?.length - rightFixedColumnCount
                                        : idx === 0) &&
                                    isScrollable &&
                                    "sticky right-0 top-0 bg-inherit z-[1000]" +
                                        " " +
                                        (idx >=
                                            tableHeader?.length -
                                                (rightFixedColumnCount as number) &&
                                            (rightFixedColumnClass as string[])?.length > 0 &&
                                            (rightFixedColumnClass as string[])[idx])
                                } ${
                                    (leftFixedColumnCount
                                        ? idx <= leftFixedColumnCount - 1
                                        : idx === 0) &&
                                    isScrollable &&
                                    isScrolled &&
                                    "shadow-[3px_0px_3px_0px_rgba(122,122,122,0.1)]"
                                }  `}
                            >
                                <div
                                    className={`p-3 text-xs text-slate-800 ${
                                        header?.title === "price" &&
                                        inventoryHistoryTable &&
                                        idx === 2 &&
                                        "justify-end"
                                    }  flex items-center gap-3 ${headerItemClass} ${
                                        header?.itemClass
                                    } `}
                                >
                                    {isAllSelectable && idx === 0 && (
                                        <div onClick={(e) => e.stopPropagation()}>
                                            <Checkbox
                                                name="selectAll"
                                                isChecked={tableHeader?.[0]?.isAllChecked}
                                                onChange={() => {
                                                    tableHeader?.[0]?.onSelectAll?.();
                                                }}
                                                disabled={tableHeader?.[0]?.checkAllIsDisabled}
                                                className={"!h-4 !w-4 !rounded-[4px] "}
                                            />
                                        </div>
                                    )}
                                    <div
                                        className={`flex justify-between w-full items-center ${header?.titleClass}`}
                                    >
                                        {header?.title}
                                        {header?.sortIcon && (
                                            <div className="flex flex-col">
                                                <i
                                                    className="ri-arrow-up-s-fill text-slate-700 cursor-pointer -mt-1"
                                                    onClick={() => sort(idx, 1)}
                                                ></i>
                                                <i
                                                    className="ri-arrow-down-s-fill text-slate-700 cursor-pointer -mt-1.5"
                                                    onClick={() => sort(idx, -1)}
                                                ></i>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </th>
                        ))}
                    </tr>
                </thead>
                {!noTableBody ? (
                    <tbody className={`${tbodyClass} `}>
                        {isGrouped
                            ? groupedData.map((group, index) =>
                                  group.associatedSKUs.map((sku, skuIndex) => (
                                      <tr
                                          key={skuIndex}
                                          className={`text-left bg-white border-t  border-slate-100 cursor-pointer ellipsis ${bodyItemClass}`}
                                          onClick={(e) => group?.cellClickAction?.()}
                                      >
                                          {skuIndex === 0 && (
                                              <td
                                                  rowSpan={group.associatedSKUs.length}
                                                  className={`${sku?.widthClass} ${
                                                      isCellBordered &&
                                                      skuIndex !==
                                                          group.associatedSKUs?.length - 1 &&
                                                      "border-r border-b"
                                                  } border-slate-100 `}
                                              >
                                                  <div
                                                      className={`flex pl-3 text-slate-800  items-center gap-3 ${group?.groupContentClass} `}
                                                  >
                                                      {group.groupContent}
                                                  </div>
                                              </td>
                                          )}
                                          {sku?.map((col, colIdx) => {
                                              return (
                                                  <td
                                                      key={colIdx}
                                                      className={`${col?.widthClass} ${
                                                          isCellBordered &&
                                                          colIdx !== sku?.length - 1 &&
                                                          "border-r border-b"
                                                      } border-slate-100 `}
                                                  >
                                                      <div
                                                          className={`flex pl-3 text-slate-800  items-center gap-3 ${col?.tableDataDivClass} `}
                                                      >
                                                          {col?.content}
                                                      </div>
                                                  </td>
                                              );
                                          })}
                                      </tr>
                                  ))
                              )
                            : tableBody?.map((item, index) => {
                                  return (
                                      <tr
                                          key={index}
                                          className={`text-left bg-white border-t  border-slate-100 cursor-pointer ellipsis hover:bg-slate-50 ${bodyItemClass}`}
                                          onClick={(e) => item?.[0]?.cellClickAction?.(e)}
                                      >
                                          {item?.map((col, idx) => (
                                              <td
                                                  key={index + "-" + idx}
                                                  className={`${col?.widthClass} ${
                                                      isCellBordered &&
                                                      idx !== item?.length - 1 &&
                                                      "border-r border-b"
                                                  } border-slate-100 ${
                                                      (leftFixedColumnCount
                                                          ? idx <= leftFixedColumnCount - 1
                                                          : idx === 0) &&
                                                      isScrollable &&
                                                      "sticky left-0 bg-inherit z-[1000]" +
                                                          " " +
                                                          (idx <=
                                                              (leftFixedColumnCount as number) -
                                                                  1 &&
                                                              (leftFixedColumnClass as string[])
                                                                  ?.length > 0 &&
                                                              (leftFixedColumnClass as string[])[
                                                                  idx
                                                              ])
                                                  } ${
                                                      (rightFixedColumnCount
                                                          ? idx >=
                                                            item?.length - rightFixedColumnCount
                                                          : idx === 0) &&
                                                      isScrollable &&
                                                      "sticky right-0 bg-inherit z-[1000]" +
                                                          " " +
                                                          (idx >=
                                                              tableHeader?.length -
                                                                  (rightFixedColumnCount as number) &&
                                                              (rightFixedColumnClass as string[])
                                                                  ?.length > 0 &&
                                                              (rightFixedColumnClass as string[])[
                                                                  idx
                                                              ])
                                                  } ${
                                                      (leftFixedColumnCount
                                                          ? idx <= leftFixedColumnCount - 1
                                                          : idx === 0) &&
                                                      isScrollable &&
                                                      isScrolled &&
                                                      "shadow-[3px_0px_3px_0px_rgba(122,122,122,0.1)]"
                                                  } ${inventoryHistoryTable && "border-b"}`}
                                              >
                                                  <div
                                                      className={`flex ${
                                                          item?.[2]?.content &&
                                                          idx === 2 &&
                                                          inventoryHistoryTable &&
                                                          "justify-end"
                                                      } pl-3 text-slate-800  items-center gap-3 ${
                                                          col?.tableDataDivClass
                                                      } `}
                                                  >
                                                      {idx === 0 &&
                                                          (isAllSelectable || isCellSelectable) && (
                                                              <div
                                                                  onClick={(e) =>
                                                                      e.stopPropagation()
                                                                  }
                                                              >
                                                                  <Checkbox
                                                                      name="select"
                                                                      isChecked={
                                                                          item?.[0]
                                                                              ?.isItemChecked as boolean
                                                                      }
                                                                      disabled={
                                                                          item?.[0]
                                                                              ?.checkItemIsDisabled
                                                                      }
                                                                      onChange={() => {
                                                                          item?.[0]?.onCheckBoxClick?.();
                                                                      }}
                                                                      className={
                                                                          "!h-4 !ml-3 !w-4 !rounded-[4px] "
                                                                      }
                                                                  />
                                                              </div>
                                                          )}
                                                      {col?.content}
                                                  </div>
                                              </td>
                                          ))}
                                      </tr>
                                  );
                              })}
                    </tbody>
                ) : (
                    <tbody className="w-[100%]">{noTableBody}</tbody>
                )}
            </table>
        </div>
    );
};

export default CustomTable;
