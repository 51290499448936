import Loader from "components/common/Loader";
import { useAppDispatch, useAppSelector } from "hooks/storeHooks";
import AuthFrame from "pages/Ldb/Auth/AuthFrame";
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { integrationActions, vendorActions } from "redux/Ldb/actions";

const ShopifyAuth = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useState({});
    const [param] = useSearchParams();

    useEffect(() => {
        for (const name of param.keys()) {
            setSearchParams((prev) => ({
                ...prev,
                [name]: param.get(name),
            }));
        }
    }, [param]);

    const { fetchShopifyData, fetchShopifyDataSuccess } = useAppSelector(
        (state) => state.integration
    );

    useEffect(() => {
        if (Object.keys(searchParams).length > 1) {
            dispatch(integrationActions.getShopifyData(searchParams));
        }
    }, [searchParams, dispatch]);

    useEffect(() => {
        if (Boolean(fetchShopifyDataSuccess)) {
            window.location.href = fetchShopifyDataSuccess?.loginUrl;
            // dispatch(integrationActions.resetShopifyDataSuccess());
        }
    }, [fetchShopifyDataSuccess, navigate]);

    return (
        <AuthFrame>
            <div className="w-full h-fit bg-white shadow-dropShadow-cookie p-[40px] rounded-[8px]">
                <div className="mb-[38px] flex flex-col items-center justify-center">
                    <img
                        src={
                            "https://res.cloudinary.com/dvxi7qcmd/image/upload/v1715104205/SynthLogo_h118c2.svg"
                        }
                        alt="Logo Icon"
                        className="mb-2 "
                        height={26.4}
                        width={88}
                    />
                    <p className="text-[26px] font-flame font-normal text-center  text-[#013D28]">
                        Connecting shopify...
                    </p>
                </div>
                <div className="w-full py-8 bg-white flex flex-col items-center rounded-md">
                    <Loader size={6} color={"gm-50"} />
                </div>
            </div>
        </AuthFrame>
    );
};

export default ShopifyAuth;
